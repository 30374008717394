import { DECISION_PERIOD_DURATION } from '../token-refresh-timer/refresh-timer-calculator';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const INACTIVITY_TIMEOUT_IN_MS = 60 * 60 * 1000; // One hour
const REQUEST_DELAY_IN_MS = 2 * 1000; // Two seconds

export const calculateInactivityTimeout = () /*: number*/ => {
  return INACTIVITY_TIMEOUT_IN_MS - REQUEST_DELAY_IN_MS - DECISION_PERIOD_DURATION;
};
