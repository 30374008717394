import { createAction } from 'redux-actions';

import { MODAL_CLOSE, MODAL_DESTROY, MODAL_OPEN, MODAL_REGISTER, MODAL_TOGGLE } from './modal.actions-types';

export const modalRegister = createAction(MODAL_REGISTER, payload => payload);

export const modalDestroy = createAction(MODAL_DESTROY, payload => payload);

export const modalOpen = createAction(MODAL_OPEN, payload => payload);

export const modalClose = createAction(MODAL_CLOSE, payload => payload);

export const modalToggle = createAction(MODAL_TOGGLE, payload => payload);
