// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { PageHeaderComponent } from 'app/core';
import { AlertComponent, ButtonComponent, ConnectedInputComponent, SeparatorComponent, Typography } from 'app/shared';
import { trimSpaces } from 'app/utils';
import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './coupons-charge.component.types';*/

export class CouponsChargeComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <PageHeaderComponent title="NAV.PAYMENT.SERVICE_COUPON_CHARGE" />

        {!!this.props.location.state && !!this.props.location.state.success && (
          <AlertComponent type="success">
            {this.props.location.state.success.type === 'COUPON_CHARGED' && (
              <FormattedMessage id="COUPONS.DETAILS.CHARGE.SUCCESS" />
            )}
          </AlertComponent>
        )}

        <CardComponent>
          <CardHeaderComponent title="COUPONS.CHARGE.TITLE" direction="column" alignItems="flex-start">
            <SeparatorComponent />
            <Typography type="bodyText" color="grey" id="COUPONS.CHARGE.DESC" />
          </CardHeaderComponent>
          <SeparatorComponent />
          <CardBodyComponent>
            <Field
              name="coupons"
              component={ConnectedInputComponent}
              label="COUPONS.CHARGE.CODE"
              placeholder="COUPONS.CHARGE.CODE"
              normalize={trimSpaces}
              required
            />
          </CardBodyComponent>
          <CardFooterComponent className="text-right">
            <ButtonComponent loading={this.props.submitting}>
              <FormattedMessage id="CORE.PROCEED" /> <i className="fi fi-right-arrow-forward" />
            </ButtonComponent>
          </CardFooterComponent>
        </CardComponent>
      </form>
    );
  }
}
