import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { ResultActionModalComponent } from 'app/shared/result-action-modal/result-action-modal.component';
import { actions } from './service-event-slice';
import { Modal } from 'app/shared/modal-new/modal.container';

interface ServiceEventSubmissionFailedModalProps {
  voucher: { id: number };
}

export const ServiceEventSubmissionFailedModal: FunctionComponent<ServiceEventSubmissionFailedModalProps> = ({
  voucher,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      component={ResultActionModalComponent}
      componentProps={{
        isLoading: false,
        isSuccess: false,
        onClose: () => {
          dispatch(actions.newServiceEventInputStarted());
        },
        messages: {
          failure: {
            title: {
              id: 'COUPONS.DETAILS.CHARGE.MODAL.FAILURE',
            },
          },
        },
      }}
      close={() => null}
      isOpen
    />
  );
};
