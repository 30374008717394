// @flow

/*:: import type { MessageDescriptor } from 'react-intl';*/

/*:: import type { Business } from 'app/types/business.types';*/

/*:: type BusinessType =
  | 'SP_COMPANY_ACCOUNT'
  | 'SP_VENUE_ACCOUNT'
  | 'MUNICIPALITY_MAIN_ACCOUNT'
  | 'MUNICIPALITY_SUB_ACCOUNT'
  | 'SYSTEM_ACCOUNT'
  | 'UNDEFINED';*/

/*:: type Item = {
  id: number,
  name: string,
  isReplacedByAnotherAccount: boolean,
};*/

/*:: type TranslationKeyMap = {
  [BusinessType]: string,
};*/

/*:: type Group = {
  disabled: boolean,
  name: string,
  showSeparator: ?boolean
};*/

/*:: type SidebarOption = Item | Group;*/

/*:: type BusinessGroupAccumulator = {
  items: SidebarOption[],
  previousType: ?BusinessType,
};*/

export function generateSidebarOptions(
  businesses /*: Business[]*/,
  businessNameFormatter /*: (business: Business) => string*/,
  formatMessage /*: (descriptor: MessageDescriptor, values: any) => string*/,
) {
  // console.log(businesses);
  const result = businesses.reduce(
    (acc /*: BusinessGroupAccumulator*/, business /*: Business*/) => {
      const { items, previousType } = acc;
      const currentType = getType(business);
      if (currentType !== previousType) {
        return {
          previousType: currentType,
          items: items.concat([
            generateGroup(
              currentType,
              formatMessage,
              // separator will be shown in UI when business list contains multiple main accounts
              !!previousType &&
                ['MUNICIPALITY_MAIN_ACCOUNT', 'SYSTEM_ACCOUNT', 'SP_COMPANY_ACCOUNT'].includes(currentType),
            ),
            generateItem(business, businessNameFormatter),
          ]),
        };
      } else {
        return {
          ...acc,
          items: items.concat(generateItem(business, businessNameFormatter)),
        };
      }
    },
    {
      items: [],
      previousType: undefined,
    },
  );

  return result.items;
}

const generateGroup = (
  businessType /*: BusinessType*/,
  formatMessage /*: (descriptor: MessageDescriptor) => string*/,
  showSeparator /*: boolean*/,
) /*: Group*/ => {
  return {
    name: formatMessage({ id: toTranslationKey[businessType] }),
    disabled: true,
    showSeparator,
  };
};

const generateItem = (business /*: Business*/, businessFormatter /*: any*/) /*: Item*/ => {
  return {
    id: business.id,
    name: businessFormatter(business),
    isReplacedByAnotherAccount: business.isReplacedByAnotherAccount,
  };
};

export function getType(business /*: Business*/) /*: BusinessType*/ {
  if (['TYPE_COMPANY', 'TYPE_SYSTEM'].includes(business.type)) {
    return 'SYSTEM_ACCOUNT';
  } else if ('TYPE_MUNICIPAL' === business.type && !business.parentId) {
    return 'MUNICIPALITY_MAIN_ACCOUNT';
  } else if ('TYPE_MUNICIPAL' === business.type && business.parentId) {
    return 'MUNICIPALITY_SUB_ACCOUNT';
  } else if ('TYPE_SERVICE_PROVIDER' === business.type) {
    return 'SP_COMPANY_ACCOUNT';
  } else if ('TYPE_VENUE' === business.type) {
    return 'SP_VENUE_ACCOUNT';
  } else return 'UNDEFINED';
}

export const toTranslationKey /*: TranslationKeyMap*/ = {
  SP_VENUE_ACCOUNT: 'NAV.VENUE.VENUE_LIST',
  SP_COMPANY_ACCOUNT: 'ACCOUNTS.MAIN_ACCOUNT',
  SYSTEM_ACCOUNT: 'ACCOUNTS.MAIN_ACCOUNT',
  MUNICIPALITY_MAIN_ACCOUNT: 'ACCOUNTS.MAIN_ACCOUNT',
  MUNICIPALITY_SUB_ACCOUNT: 'ACCOUNTS.ACCOUNTS',
};
