export const getFilename = headers => {
  try {
    const contentDisposition = headers.get('Content-Disposition');
    const regex = /filename[^;\n=]*="?((['"]).*?\2|[^;\n"]*)/g;
    return regex.exec(contentDisposition)[1];
  } catch (e) {
    console.warn('Filename is set to default because of problems with extraction filename from headers');

    return `vaana-file-${new Date().toISOString().slice(0, 10)}`;
  }
};

export const saveFile = async response => {
  const filename = getFilename(response.headers);
  const blob = await response.blob();

  //execute download for user
  const file = new Blob([blob], { type: response.headers.get('Content-Type') });
  const url = window.URL.createObjectURL(file);
  if (window.navigator.msSaveOrOpenBlob !== undefined) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.style = 'display:none';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
};
