// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedCheckboxesComponent } from 'app/shared';
import { Modal } from 'app/shared/modal-new/modal.container';
import { CardBodyComponent } from 'app/shared/card-new';

/*:: import type { BodyType, Props, Role } from './users-edit-roles.component.types';*/

// This works around a problem with this.props being empty for a user when reloading
// the user list (dropping the user) after removing the last role for the user
export const createOnSubmitCallback = (
  onSubmit /*: Function*/,
  propsRoles /*: Role[]*/,
  userDisplayName /*: string*/,
) => {
  return ({ roles } /*: BodyType*/) =>
    onSubmit(propsRoles, roles).then(() => {
      return userDisplayName;
    });
};

export class UsersEditRolesComponent extends Component /*:: <Props>*/ {
  render() {
    const { handleSubmit, submitting } = this.props;
    const confirmOptions = [
      {
        label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
        onClick: this.props.onClose,
        theme: 'primary-outline',
        arrowBack: true,
      },
      {
        label: this.props.intl.formatMessage({ id: 'CORE.SAVE' }),
        loading: submitting,
      },
    ];

    return (
      <Modal
        confirmOptions={confirmOptions}
        isOpen
        onSubmit={handleSubmit(
          createOnSubmitCallback(this.props.onSubmit, this.props.roles, this.props.userDisplayName),
        )}
        tag="form"
        title="VENUES.EDIT_ROLES"
        size="small"
      >
        <CardBodyComponent>
          <Field
            name="roles"
            theme="inline"
            options={this.props.roles}
            component={ConnectedCheckboxesComponent}
            disableEdit
            columns={2}
          />
        </CardBodyComponent>
      </Modal>
    );
  }
}
