// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import { updateConfirmation } from 'app/redux/view/view.actions';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Props } from './with-confirmation.hoc.types';*/

const mapDispatchToProps = dispatch => ({
  onConfirm: (nextLocation, unblock) =>
    dispatch(
      updateConfirmation({
        onConfirm: () => {
          unblock();
          return Promise.resolve(dispatch(push(nextLocation)));
        },
        title: 'CORE.PROMPT',
      }),
    ),
});

export const withConfirmation = (WrappedComponent /*: ComponentType<any>*/) =>
  compose(
    withRouter,
    connect(null, mapDispatchToProps),
  )(
    class Confirmation extends Component /*:: <Props>*/ {
      /*:: unblock: Function;*/

      componentDidMount() {
        this.unblock = this.props.history.block(nextLocation => {
          const pathIsDifferent = this.props.history.location.pathname !== nextLocation.pathname;

          if (
            nextLocation.pathname !== '/login' &&
            pathIsDifferent &&
            this.props.dirty &&
            !this.props.submitSucceeded &&
            !this.props.submitting
          ) {
            this.props.onConfirm(nextLocation, this.unblock);
            return false;
          }
          return true;
        });
      }

      componentWillUnmount() {
        this.unblock();
      }
      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
  );
