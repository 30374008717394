import { compose } from 'redux';
import { connect } from 'react-redux';

import { impersonateUserAndRefresh } from 'app/redux/users/users.actions';
import { ImpersonationModalComponent } from './impersonation-modal.component';

import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';

const mapDispatchToProps = dispatch => ({
  onSubmit: userId => withFormSubmissionErrorHandling(dispatch(impersonateUserAndRefresh(userId))),
});

export const ImpersonationModalContainer = compose(connect(null, mapDispatchToProps))(ImpersonationModalComponent);
