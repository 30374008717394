import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form';
import omit from 'lodash/omit';

import { VenuePriceListComponent } from 'app/register/shared/price/venue-price-list.component';

const mapStateToProps = (state, { formName, fieldName }) => {
  const { dictionaries } = state;
  const priceListFieldName = `${fieldName}.priceLists`;

  return {
    formServices: formValueSelector(formName)(state, `${priceListFieldName}[0].services`),
    priceListFieldName,
    services: dictionaries['municipal/services'].services.municipal.map(({ name, ...service }) => ({
      ...service,
      text: name,
    })),
  };
};

const mapDispatchToProps = (dispatch, { formName }) => ({
  pushService: (field, service) => dispatch(arrayPush(formName, field, service)),
  removeService: (field, index) => dispatch(arrayRemove(formName, field, index)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...omit(stateProps, 'formServices'),
  ...dispatchProps,
  pushService: service => dispatchProps.pushService(`${stateProps.priceListFieldName}[0].services`, service),
  removeService: service => {
    const index = stateProps.formServices.findIndex(s => s.id === service.id);

    return dispatchProps.removeService(`${stateProps.priceListFieldName}[0].services`, index);
  },
});

export const VenuePriceListContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(VenuePriceListComponent);
