import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostpayVoucherType } from 'v2/types/postpay';

export enum StateStatus {
  Uninitialised = 'uninitialised',
  Loaded = 'loaded',
  Loading = 'loading',
}

export type VoucherTypesState = {
  voucherTypes: PostpayVoucherType[];
  status: StateStatus;
};

export type VoucherTypesRequestCompletedPayload = {
  status: StateStatus.Loaded;
  voucherTypes: PostpayVoucherType[];
};

export const initialState: VoucherTypesState = {
  voucherTypes: [],
  status: StateStatus.Uninitialised,
};

export const sliceName = 'voucher-types';

export type StateSlice = {
  [key in typeof sliceName]: VoucherTypesState;
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    voucherTypesRequested(state) {
      return {
        ...state,
        voucherTypes: [],
        status: StateStatus.Loading,
      };
    },

    voucherTypesRequestCompleted(state, action: PayloadAction<VoucherTypesRequestCompletedPayload>) {
      switch (action.payload.status) {
        case StateStatus.Loaded:
          return {
            ...state,
            status: StateStatus.Loaded,
            voucherTypes: action.payload.voucherTypes,
          };
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [sliceName]: slice.reducer,
};
