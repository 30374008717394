import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { put, takeEvery } from 'redux-saga/effects';
import * as paymentTypes from '../payments/payments.action-types';

function* redirectCouponIfReplacementExists(action /*: any*/) {
  if (isEmpty(action.payload)) {
    yield put(push(`/coupons/${action.meta.coupons}`));
  } else {
    yield put(push(`/coupons/${action.payload}?redirectedFrom=${action.meta.coupons}`));
  }
}

export function* couponSaga() {
  yield takeEvery(paymentTypes.VALIDATE_COUPON_CHARGE_SUCCESS, redirectCouponIfReplacementExists);
}