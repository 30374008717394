//@flow
import React, { useMemo } from 'react';
import isArray from 'lodash/isArray';

import { combine } from 'redux-form-validators';

import { required } from 'app/utils';

/*:: import type { Props } from './field-wrapper.component.types';*/

export const FieldWrapper = (props /*: Props*/) => {
  // $FlowFixMe
  const { validate = [], field, ...otherProps } = props;
  const Field = field;

  const isRequired = props.required || false;
  const isDisabled = props.disabled || false;

  const fieldValidators = (isArray(validate) ? validate : [validate]).filter(v => v);
  const validators = useMemo(
    () => [required({ if: () => isRequired && !isDisabled, memoize: false }), ...fieldValidators],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRequired, isDisabled, fieldValidators.length],
  );

  const key = isDisabled ? 'disabled' : '';

  if (!Field) {
    throw new Error('field prop is required');
  }

  return <Field {...otherProps} validate={combine(...validators)} key={`${props.name}-${key}`} />;
};
