// @flow
import React from 'react';

import { ListComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { PricelistsListPlaceholder } from './placeholder/pricelists-list.placeholder';
import { PricelistsTableComponent } from './table/pricelists-table.component';
import { PricelistsTileComponent } from './tile/pricelists-tile.component';

/*:: import type { Props } from './pricelists-list.component.types';*/

export const PricelistsListComponent = (props /*: Props*/) => {
  if (props.loading) {
    return <PricelistsListPlaceholder />;
  }

  return (
    <CardComponent>
      <CardHeaderComponent title="PRICELISTS.MANAGE.LIST.TITLE" />
      <SeparatorComponent height={12} />
      <CardBodyComponent>
        <Typography color="grey" id="PRICELISTS.MANAGE.LIST.SUBTITLE" />
        <SeparatorComponent />
        <ListComponent list={props.venues} table={PricelistsTableComponent} tile={PricelistsTileComponent} />
      </CardBodyComponent>
    </CardComponent>
  );
};
