// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';

import {
  ButtonComponent,
  ConnectedDatePickerComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  PicField,
  TooltipComponent,
} from 'app/shared';

import { RequiredCustomerField } from './required-customer-field.component';
import { birthdayValidator, getUnitTranslationId } from 'app/utils';
import { birthdayBounds } from 'app/utils/dates';

/*:: import type { Props } from './coupons-create-form-customers.component.types';*/
import styles from './coupons-create-form-customers.module.scss';

function BirthDateField({ field, onCalendarToggle }) {
  return (
    <RequiredCustomerField
      name={`${field}.birthDate`}
      component={ConnectedDatePickerComponent}
      label="COUPONS.CREATE.LABEL.DATE_OF_BIRTH"
      onFocusChange={onCalendarToggle}
      bounds={birthdayBounds}
      validate={birthdayValidator}
    />
  );
}

function GenderField({ field, intl }) {
  return (
    <RequiredCustomerField
      name={`${field}.gender`}
      component={ConnectedSelectComponent}
      options={[
        { id: 'm', name: intl.formatMessage({ id: 'CORE.GENDER.MALE' }) },
        { id: 'f', name: intl.formatMessage({ id: 'CORE.GENDER.FEMALE' }) },
      ]}
      label="COUPONS.CREATE.LABEL.GENDER"
    />
  );
}

export class CouponsCreateFormCustomersComponent extends Component /*:: <Props>*/ {
  onClone = (index /*: number*/) => this.props.fields.push(this.props.fields.get(index));
  onRemove = (index /*: number*/) => this.props.fields.remove(index);

  render() {
    const columnClassNames = classNames('col-12', 'col-md-6', 'col-xl', 'pr-xl-0', {
      'col-lg-4': this.props.displayNameInput && this.props.sidebarCollapsed,
      'col-lg-3': !this.props.displayNameInput && this.props.sidebarCollapsed,
    });

    return (
      <>
        {this.props.fields.map((field, index) => (
          <div className={classNames(styles.container)} key={index}>
            {this.props.isPersonalIdentificationCodeEnabled && (
              <div className={columnClassNames}>
                <PicField
                  name={`${field}.personalIdentificationCode`}
                  label="COUPONS.CREATE.LABEL.PERSONAL_IDENTIFICATION_CODE"
                  required
                />
              </div>
            )}
            {!this.props.isPersonalIdentificationCodeEnabled && (
              <div className={columnClassNames}>
                <BirthDateField field={field} onCalendarToggle={this.props.onCalendarToggle} />
              </div>
            )}
            {this.props.displayNameInput && (
              <div className={classNames(columnClassNames, styles.name)}>
                <Field name={`${field}.name`} component={ConnectedInputComponent} label="COUPONS.CREATE.LABEL.NAME" />
              </div>
            )}
            {!this.props.isPersonalIdentificationCodeEnabled && (
              <div className={columnClassNames}>
                <GenderField field={field} intl={this.props.intl} />
              </div>
            )}
            <div className={columnClassNames}>
              <RequiredCustomerField
                name={`${field}.decisionId`}
                component={ConnectedInputComponent}
                label="COUPONS.CREATE.LABEL.DECISION_NUMBER"
              />
            </div>
            <div className={columnClassNames}>
              <Field
                name={`${field}.individualValue`}
                component={ConnectedInputComponent}
                label={this.props.intl.formatMessage({ id: getUnitTranslationId(this.props.priceUnit) })}
                icon="euro-symbol"
              />
            </div>
            <span className={styles.icons}>
              <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.CLONE' })} placement="top">
                <i onClick={() => this.onClone(index)} className={classNames(styles.clone, 'fi fi-copy')} />
              </TooltipComponent>
              {this.props.fields.length > 1 && (
                <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.REMOVE' })} placement="top">
                  <i onClick={() => this.onRemove(index)} className={classNames(styles.remove, 'fi fi-remove-bold')} />
                </TooltipComponent>
              )}
            </span>
          </div>
        ))}
        <div>
          <ButtonComponent theme="secondary-outline" className="mt-4" onClick={() => this.props.fields.push({})}>
            <FormattedMessage id="COUPONS.CREATE.ADD_ROW" />
          </ButtonComponent>
        </div>
      </>
    );
  }
}
