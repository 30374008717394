//@flow

import React from 'react';

/*:: import type { Props } from './multi-controls-base-group.types';*/
import styles from './multi-controls-base-group.module.scss';

export const MultiControlsBaseGroup = (props /*: Props*/) => {
  const { itemComponent: ItemComponent, labelKey, itemProps = {}, controlMeta, columns, columnAlign } = props;

  const {
    isChecked,
    onChange,
    // $FlowFixMe
    isDisabled = () => controlMeta.isDisabled,
    labelPosition = 'right',
    ...otherItemProps
  } = itemProps;

  const style = {
    ...(columns ? { columns } : {}),
  };

  const controlProps = option => ({
    ...otherItemProps,
    ...(isChecked ? { checked: isChecked(option) } : {}),
    ...(onChange ? { onChange: () => onChange(option) } : {}),
    ...(isDisabled ? { disabled: isDisabled(option) } : {}),
  });

  return (
    <div className={styles.wrapper} style={style}>
      {props.options.map((option, index) => (
        <ItemComponent
          {...controlProps(option)}
          columnAlign={columnAlign}
          className={styles.item}
          labelPosition={labelPosition}
          key={index}
          label={option[labelKey]}
          name={props.name}
          hideValidation
        />
      ))}
    </div>
  );
};
