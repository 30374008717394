// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

/*:: import type { Props } from './sidebar-general.component.types';*/

export class SidebarGeneralComponent extends Component /*:: <Props>*/ {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['venues', 'read'],
        ['venues', 'create'],
        ['venue', 'update'],
        ([
          ['venue', 'read'],
          ['venueUsers', 'read'],
        ] /*: [string, 'create' | 'read' | 'update' | 'delete'][]*/),
        ['venuePayment', 'create'],
        ([
          ['venuePayment', 'create'],
          ['venueServiceCoupons', 'read'],
        ] /*: [
                string,
                'create' | 'read' | 'update' | 'delete',
              ][]*/),
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.GENERAL.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {checkPermissions(this.props.permissions, ['venuePayment', 'create']) && (
            <SidebarItemContainer
              icon="charge"
              name={this.props.intl.formatMessage({ id: 'NAV.PAYMENT.SERVICE_COUPON_CHARGE' })}
              to="/coupons/charge"
            />
          )}

          {checkPermissions(
            this.props.permissions,
            ([
              ['venuePayment', 'create'],
              ['venueServiceCoupons', 'read'],
            ] /*: [
                      string,
                      'create' | 'read' | 'update' | 'delete',
                    ][]*/),
          ) && (
            <SidebarItemContainer
              icon="charge"
              name={this.props.intl.formatMessage({ id: 'NAV.PAYMENT.TARGETED_SERVICE_COUPONS' })}
              to="/coupons/mass-charge"
              badge={this.props.newCouponsCounter}
            />
          )}

          {checkPermissions(this.props.permissions, ['venues', 'create']) && (
            <SidebarItemContainer
              icon="business"
              name={this.props.intl.formatMessage({ id: 'NAV.VENUE.VENUE_LIST' })}
              to="/venues"
            />
          )}

          {checkPermissions(this.props.permissions, ['venue', 'update']) && (
            <SidebarItemContainer
              icon="business"
              name={this.props.intl.formatMessage({ id: 'NAV.VENUE.VENUE' })}
              to={`/venues/${this.props.business.id}/edit`}
            />
          )}

          {checkPermissions(
            this.props.permissions,
            ([
              ['venue', 'read'],
              ['venueUsers', 'read'],
            ] /*: [string, 'create' | 'read' | 'update' | 'delete'][]*/),
          ) && (
            <SidebarItemContainer
              icon="multiple-users-silhouette"
              name={this.props.intl.formatMessage({ id: 'NAV.VENUE.VENUE_USERS' })}
              to={`/venues/${this.props.business.id}/users`}
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
