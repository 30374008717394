// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';
import get from 'lodash/get';

import {
  AlertComponent,
  ConnectedDateRangeComponent,
  ConnectedSelectComponent,
  PicField,
  SeparatorComponent,
} from 'app/shared';

import { ButtonComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import {
  checkPermissions,
  handleAnalyticsEvent,
  isBusinessDifferent,
  LocalStorage,
  voucherValidityBounds,
} from 'app/utils';
import { columnsName } from '../virtualized/columns-names';
import { CouponsListAdvancedFiltersComponent } from './coupons-list-advanced-filters.component';
/*:: import type { Props, State } from './coupons-list-filters.component.types';*/
import styles from './coupons-list-filters.module.scss';

export class CouponsListFiltersComponent extends Component /*:: <Props, State>*/ {
  /*:: filtersState: LocalStorage;*/

  constructor(props /*: Props*/) {
    super(props);

    this.filtersState = new LocalStorage(`${props.form}-is-open`);

    const isAdvancedOpened = this.areFiltersOpen(props.initialValues);

    this.state = {
      isAdvancedOpened,
    };
  }

  areFiltersOpen = (initialValues /*: any*/) =>
    this.filtersState.get() === 'true' ||
    initialValues.codes ||
    initialValues.customers ||
    initialValues.decisionNumber ||
    initialValues.dateOfBirth ||
    initialValues.issuers ||
    get(initialValues, 'created.createdMin') ||
    get(initialValues, 'created.createdMax') ||
    initialValues.status ||
    get(initialValues, 'transaction.transactionMin') ||
    get(initialValues, 'transaction.transactionMin');

  fetchContractedVenues = () =>
    checkPermissions(this.props.permissions, ['contractedVenues', 'read']) && this.props.fetchContractedVenues();

  fetchResources = () => {
    this.fetchContractedVenues();
    this.props.fetchIssuers();
    this.props.fetchTypes();
  };

  componentDidMount() {
    // TODO delete condition
    // exist to don't crash old renew page until it is deleted
    this.props.fetch && this.props.fetch(); //TODO Waiting for removal on renew page usage
    this.props.fetchStatuses();

    this.fetchResources();
  }

  componentDidUpdate(prevProps /*: Props*/, prevState /*: State*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.fetchResources();
    }

    if (this.state.isAdvancedOpened !== prevState.isAdvancedOpened) {
      const state = this.state.isAdvancedOpened ? 'true' : 'false';
      this.filtersState.set(state);
    }
  }

  onFieldUsage = (label /*: string*/) => {
    handleAnalyticsEvent('vouchers', 'filterUsage', label);
  };

  onFiltersToggle = () => this.setState((prev /*: State*/) => ({ isAdvancedOpened: !prev.isAdvancedOpened }));

  toggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const searchFieldClassNames = this.state.isAdvancedOpened
      ? classNames('col-12', 'col-xl-6', {
          'col-lg-6': this.props.sidebarCollapsed,
        })
      : classNames('col-12', 'col-xl-4', {
          'col-lg-4': this.props.sidebarCollapsed,
        });

    const showPersonalIdentificationCodeFilter = this.props.columnsList.includes(
      columnsName.personalIdentificationCode,
    );
    const showVenueFilter = checkPermissions(this.props.permissions, ['contractedVenues', 'read']);
    const showDateOfBirthFilter = this.props.columnsList.includes(columnsName.dateOfBirth);
    const showReferenceFilter = this.props.columnsList.includes(columnsName.reference);

    return (
      <>
        {!!this.props.location.state && !!this.props.location.state.success && (
          <AlertComponent type="success">
            {this.props.location.state.success.type === 'COUPONS_IMPORTED' && (
              <FormattedMessage id="COUPONS.IMPORT.SUCCESS" />
            )}
          </AlertComponent>
        )}

        <CardComponent>
          <CardHeaderComponent title="COUPONS.LIST.TITLE">
            <ButtonComponent
              theme="secondary-outline"
              size="small"
              className="ml-4 ml-xl-0"
              onClick={this.props.onClear}
            >
              <FormattedMessage id="CORE.CLEAR_FILTERS" />
            </ButtonComponent>
          </CardHeaderComponent>
          <SeparatorComponent />
          <CardBodyComponent>
            <form>
              <div className="row">
                {showPersonalIdentificationCodeFilter && (
                  <div className={searchFieldClassNames}>
                    <PicField
                      name="personalIdentificationCode"
                      label="COUPONS.FILTERS.PERSONAL_IDENTIFICATION_CODE"
                      onFieldUsage={this.onFieldUsage}
                    />
                  </div>
                )}
                <div className={searchFieldClassNames}>
                  <Field
                    name="type"
                    component={ConnectedSelectComponent}
                    label="COUPONS.FILTERS.TYPE"
                    onFieldUsage={this.onFieldUsage}
                    options={this.props.types}
                    async
                    withClearOption
                  />
                </div>
                <div className={searchFieldClassNames}>
                  <ConnectedDateRangeComponent
                    field={Field}
                    name="transfer"
                    startDateKey="transferMin"
                    endDateKey="transferMax"
                    onFieldUsage={this.onFieldUsage}
                    onFocusChange={this.toggle}
                    label="COUPONS.FILTERS.ENDING_MONTH"
                    bounds={{
                      ...voucherValidityBounds,
                      minDate: null,
                    }}
                  />
                </div>
                {this.state.isAdvancedOpened ? (
                  <CouponsListAdvancedFiltersComponent
                    filterVisibility={{ showDateOfBirthFilter, showReferenceFilter, showVenueFilter }}
                    issuers={this.props.issuers}
                    onDateFieldFocusChange={this.toggle}
                    onFieldUsage={this.onFieldUsage}
                    searchFieldClassNames={searchFieldClassNames}
                    statuses={this.props.statuses}
                    venues={this.props.venues}
                  />
                ) : null}
              </div>
              <button className={styles.toggler} type="button" onClick={this.onFiltersToggle}>
                <i className={`fi fi-${this.state.isAdvancedOpened ? 'minus' : 'plus'}`} />
                <span>
                  {this.props.intl.formatMessage({
                    id: this.state.isAdvancedOpened ? 'CORE.HIDE_ADVANCED_SEARCH' : 'CORE.ADVANCED_SEARCH',
                  })}
                </span>
              </button>
              <div className={backdropClassNames} />
            </form>
          </CardBodyComponent>
        </CardComponent>
      </>
    );
  }
}
