// @flow
import React from 'react';
import classNames from 'classnames';

import { useIntl } from 'app/hooks';
import { businessName } from 'app/utils';

// $FlowFixMe
import { ReactComponent as Hourglass } from 'assets/images/hourglass.svg';

/*:: import type { Props } from './sidebar-user-business.component.types';*/
import styles from './sidebar-user-business.module.scss';

export const SidebarUserBusinessComponent = (props /*: Props*/) => {
  const intl = useIntl();

  const { business } = props;

  const businessClassNames = classNames(styles.business, {
    [styles.active]: business.id === props.selected,
  });

  return (
    <button className={businessClassNames} onClick={props.onSelect}>
      <span className={styles.name}>
        {business.isReplacedByAnotherAccount && <Hourglass className={styles.hourglassIcon} />}
        {businessName(business)}
      </span>
      <span className={styles.select}>
        {intl.formatMessage({
          id: business.id === props.selected ? 'SIDEBAR.ACTIVE' : 'SIDEBAR.SELECT',
        })}
      </span>
    </button>
  );
};
