// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './title.component.types';*/
import styles from './title.module.scss';

export class TitleComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    withBackOption: false,
  };

  render() {
    const titleClassNames = classNames(styles.title, {
      [styles.withBackOption]: this.props.withBackOption,
    });

    return <h1 className={titleClassNames}>{this.props.children}</h1>;
  }
}
