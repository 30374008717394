import { useMemo, useState } from 'react';
import { CardBodyComponent, CardComponent, CardFooterComponent } from 'app/shared/card-new';
import { CardHeaderComponent } from 'app/shared/card-new/header/card-header.component';
import { FormData } from 'v2/types/postpay';
import { ButtonComponent, Typography } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import styles from './service-event-form.module.scss';
import { SubmittablePostpayServiceVoucher } from 'v2/service-voucher/postpay/utils';
import { Embedded, getEmbedded } from 'v2/utils/links';
import { ServiceEventState, ServiceEventStatus } from './service-event-slice';
import { JsonForms } from 'v2/utils/jsonforms/jsonforms';
import { ErrorObject } from 'ajv';

export type ServiceEventFormComponentProps = {
  data: FormData | undefined;
  onChange: (data: FormData) => void;
  submissionState: ServiceEventState;
  onSubmit: () => void;
  voucher: SubmittablePostpayServiceVoucher;
};

const isLoading = (submissionState: ServiceEventState) => {
  switch (submissionState.status) {
    case ServiceEventStatus.Initializing:
    case ServiceEventStatus.Submitting:
      return true;
    default:
      return false;
  }
};

export function ServiceEventFormComponent({
  data,
  voucher,
  submissionState,
  onChange,
  onSubmit,
}: ServiceEventFormComponentProps) {
  const { schema, uiSchema } = getEmbedded(voucher, Embedded.PostpayServiceVoucher.ServiceEventTemplate).form;
  const [localValidationErrors, setLocalValidationErrors] = useState<ErrorObject[] | undefined>();
  const generalSubmissionErrors: string[] = useMemo(() => {
    switch (submissionState.status) {
      case ServiceEventStatus.SubmissionFailed:
        return submissionState.error.errors.generalErrors.map(error => error.message);
      case ServiceEventStatus.InitializationFailed:
        return [submissionState.error.message];
      default:
        return [];
    }
  }, [submissionState]);

  const externalValidationErrors = useMemo(() => {
    switch (submissionState.status) {
      case ServiceEventStatus.SubmissionFailed:
        return submissionState.error.errors.fieldErrors;
      default:
        return [];
    }
  }, [submissionState]);

  return (
    <CardComponent>
      <CardHeaderComponent title="SERVICE_EVENTS.POSTPAY.SUBMISSION.TITLE" />
      <CardBodyComponent className={styles.formContainer}>
        <JsonForms
          schema={schema}
          uischema={uiSchema}
          data={data}
          externalValidationErrors={externalValidationErrors}
          onChange={({ data, errors }) => {
            onChange(data);
            setLocalValidationErrors(errors);
          }}
          readonly={isLoading(submissionState)}
          isDynamic={false}
        />
      </CardBodyComponent>
      <CardFooterComponent justifyContent="end">
        {generalSubmissionErrors.length > 0 && (
          <Typography color="failure">{generalSubmissionErrors.join(', ')} </Typography>
        )}
        <ButtonComponent
          disabled={localValidationErrors && localValidationErrors.length > 0}
          loading={isLoading(submissionState)}
          onClick={onSubmit}
          type="submit"
          className="ml-4"
        >
          <FormattedMessage id="SERVICE_EVENTS.POSTPAY.SUBMISSION.SUBMIT" />
        </ButtonComponent>
      </CardFooterComponent>
    </CardComponent>
  );
}
