// @flow

import { Component } from 'react';

/*:: import type { Props } from './coupons-descriptive-service.component.types';*/

export class CouponsDescriptiveServicesComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  render() {
    if (this.props.loading) {
      return this.props.service;
    }

    const service /*: any*/ = this.props.descriptiveServices.find(service => service.id === this.props.service);
    return service ? service.name : this.props.service;
  }
}
