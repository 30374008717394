import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { RequestPasswordComponent } from './request-password.component';

import { requestNewPassword } from 'app/redux/users/users.actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: username => dispatch(requestNewPassword(username)),
});

export const RequestPasswordContainer = compose(
  injectIntl,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'request-password',
  }),
)(RequestPasswordComponent);
