//@flow
import React, { useEffect } from 'react';

import { NotificationComponent } from './notification/notification.component';
import { useDispatch, useSelector } from 'app/hooks';
import { notificationRemove } from 'app/redux/notifications/notifications.actions';

import styles from './notifications.module.scss';
/*:: import type { Props } from './notifications.component.types';*/
/*:: import type { Notification } from './notification/notification.component.types';*/

const notificationSelector = state => state.notifications;

export const Notifications = (props /*: Props*/) => {
  const { timeout = 5000, maxStack = 5 } = props;

  const notifications /*: Notification[]*/ = useSelector(notificationSelector);
  const dispatch = useDispatch();

  const remove = id => dispatch(notificationRemove(id));

  useEffect(
    () => {
      if (notifications.length > maxStack) {
        remove(notifications[0].id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications.length],
  );

  return (
    <div className={styles.wrapper}>
      {notifications.map(notification => (
        <NotificationComponent key={notification.id} timeout={timeout} {...notification} remove={remove} />
      ))}
    </div>
  );
};
