import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization, withPermissions } from 'app/common';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { download } from 'app/redux/downloads/downloads.actions';
import { fetchTransactions } from 'app/redux/transactions/transactions.actions';

import { TransactionsMunicipalComponent } from './transactions-municipal.component';

const mapStateToProps = ({ transactions: { municipal } }) => ({
  loading: !municipal || !municipal.meta,
  meta: municipal && municipal.meta,
  transactions: municipal && municipal.list,
  fetching: municipal && municipal.fetching,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchTransactions: params =>
    dispatch(singleConcurrentRequestAction('transactions-municipal', fetchTransactions('municipal', params))),
  onDownload: () =>
    dispatch(download({ path: 'transactions/municipal', locale, filters: 'filter-municipal-transactions' })),
});

export const TransactionsMunicipalContainer = compose(
  withPermissions,
  withAuthorization(['municipalTransactions', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
)(TransactionsMunicipalComponent);
