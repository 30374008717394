// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { ButtonComponent } from 'app/shared';
import { StatusComponent } from 'app/shared/status/status.component';

/*:: import type { VenueRow } from 'app/pricelists/pricelists.component.types';*/
/*:: import type { Props } from './pricelists-table.component.types';*/
/*:: import type { Element } from 'react';*/
import styles from './pricelists-table.module.scss';
import classNames from 'classnames';

export const headers = [
  { className: styles.status, content: 'PRICELISTS.MANAGE.LIST.STATUS_SHORT', colSpan: 2 },
  { content: 'PRICELISTS.MANAGE.LIST.VENUE_NAME' },
  { content: 'PRICELISTS.MANAGE.LIST.SERVICES' },
  {},
];

export const PricelistsTableComponent = (props /*: Props*/) => {
  return (
    <TableComponent className="table-striped">
      <TableHeadComponent headers={headers} />
      <TableBodyComponent>
        {props.list.map((venue /*: VenueRow*/) => (
          <TableRowComponent key={venue.id}>
            <TableCellComponent>
              <StatusComponent status={venue.status} />
            </TableCellComponent>
            <TableCellComponent className={classNames(styles.textCell, 'ml-0 pl-0')}>
              <FormattedMessage id={`PRICELIST.STATUS_VALUE.${venue.status}`} />
            </TableCellComponent>
            <TableCellComponent className={styles.textCell}>{venue.name}</TableCellComponent>
            <TableCellComponent className={styles.textCell}>{venue.services.map((service /*: string*/) => service).join(', ')}</TableCellComponent>
            <TableCellComponent className="d-flex justify-content-end">
              <ButtonComponent noWrap theme="secondary-outline" to={`/pricelists/${venue.id}`}>
                <i className="fi fi-open" />
                <FormattedMessage id="PRICELISTS.MANAGE.LIST.OPEN" />
              </ButtonComponent>
            </TableCellComponent>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
    </TableComponent>
  );
};
