//@flow
import React from 'react';
import classNames from 'classnames';

import { TooltipComponent } from 'app/shared';
import { translate } from 'app/utils';
import { useIntl } from 'app/hooks';

/*:: import type { Props } from './append-tooltip.component.types';*/
import styles from './append-tooltip.module.scss';

export const AppendTooltip = ({ append } /*: Props*/) => {
  const intl = useIntl();

  return (
    <TooltipComponent overlay={translate({ intl, translation: append })} placement="top">
      <i className={classNames('fi', 'fi-info-sign', styles.infoIcon)} />
    </TooltipComponent>
  );
};
