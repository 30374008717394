import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { withCoreContext } from 'app/context/core/core.context';

import { CouponsDetailsChargeServicesComponent } from './coupons-details-charge-services.component';

const mapStateToProps = ({ dictionaries }) => ({
  loading: !dictionaries['municipal/services'],
});

export const CouponsDetailsChargeServicesContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
)(CouponsDetailsChargeServicesComponent);
