//@flow
import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

/*:: import type { Props } from './router-matcher.component.types';*/

import { useWithRouter } from 'app/hooks';

export const RouterMatcherComponent = withRouter(
  ({ collection, path = ['location', 'state'], staticContext, ...props } /*: Props*/) => {
    const routerState = useWithRouter();

    const routerCollectionKey = get(routerState, path, collection.hasOwnProperty('default') && 'default');
    const MatchedComponent = routerCollectionKey && collection[routerCollectionKey];

    if (!!MatchedComponent) {
      return <MatchedComponent {...{ ...props, ...routerState }} />;
    }

    return null;
  },
);
