//@flow

import React from 'react';
import get from 'lodash/get';

import { Label } from 'app/shared/form/controls-elements';

import styles from './field-value-renderer.module.scss';
/*:: import type { Props } from './field-value-renderer.types';*/

export const FieldValueRenderer = (props /*: Props*/) => {
  const { wrapper: Wrapper = 'span', path = ['input', 'value'], label } = props;

  const value = get(props, path);

  return (
    <>
      {!!label && <Label label={label} />}
      <Wrapper className={styles.textBreak}>{value}</Wrapper>
    </>
  );
};
