// @flow
import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import { FooterContainer } from 'app/core';
import { DefaultThemeContainer } from './default-theme/defaultTheme.container';
import { SidebarlessThemeComponent } from './sidebarless-theme/sidebarlessTheme.component';

/*:: import type { Props } from './theme.component.types';*/
import styles from './theme.module.scss';
import { VaanaThemeContainer } from './vaana-theme/vaanaTheme.container';

const themes = {
  sidebarless: SidebarlessThemeComponent,
  vaana: VaanaThemeContainer,
  default: DefaultThemeContainer,
};

export const ThemeComponent = (props /*: Props*/) => {
  const { termsTo, theme = 'default' } = props;

  const Theme = get(themes, [theme]);

  return (
    <>
      <main className={classNames(styles.main, props.className)}>
        <Theme children={props.children} />
      </main>
      <FooterContainer termsTo={termsTo} />
    </>
  );
};
