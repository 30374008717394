// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getBusiness } from 'app/redux/users/users.selectors';
import { BusinessContext } from 'app/context/business/business.context';

/*:: import type { Props } from './business.provider.types';*/

const BusinessContextComponent = (props /*: Props*/) => {
  const { children, business } = props;
  const [state, setState] = useState(business);

  useEffect(
    () => {
      if (business) {
        setState(business);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [business.id],
  );

  return <BusinessContext.Provider value={state}>{children}</BusinessContext.Provider>;
};

export const BusinessContextProvider = connect(getBusiness)(BusinessContextComponent);
