import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withPermissions } from 'app/common';
import { redeemTransfer } from 'app/redux/coupons/coupons.actions';
import { updateConfirmation } from 'app/redux/view/view.actions';
import { CouponsDetailsTransfersComponent } from './coupons-details-transfers.component';

const mapStateToProps = (_, { coupon, loading }) => ({
  transfers: !loading ? coupon.transfers : [],
});

const mapDispatchToProps = (dispatch, { coupon, fetchCoupon }) => ({
  onRedeem: transfer =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(redeemTransfer(coupon.id, transfer)).then(() => fetchCoupon()),
        title: 'COUPONS.TRANSFERS.REDEEM_CONFIRM',
      }),
    ),
});

export const CouponsDetailsTransfersContainer = compose(
  injectIntl,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDetailsTransfersComponent);
