import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostpayAccountId, PostpayServiceVoucher } from 'v2/types/postpay';

export enum IssuedServiceVouchersStatus {
  Loading = 'loading',
  Ok = 'ok',
}

export type IssuedServiceVouchersState = {
  [accountId: PostpayAccountId]:
    | {
        status: IssuedServiceVouchersStatus.Loading;
      }
    | {
        status: IssuedServiceVouchersStatus.Ok;
        vouchers: PostpayServiceVoucher[];
      };
};

const sliceName = 'issued-service-vouchers';
export interface StateSlice {
  [sliceName]: IssuedServiceVouchersState;
}

export type IssuedServiceVouchersRequestedPayload = {
  accountId: PostpayAccountId;
};

type IssuedServiceVouchersRequestCompletedPayload = {
  accountId: PostpayAccountId;
  vouchers: PostpayServiceVoucher[];
};

const initialState: IssuedServiceVouchersState = {};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    issuedServiceVouchersRequested(state, action: PayloadAction<IssuedServiceVouchersRequestedPayload>) {
      const { accountId } = action.payload;
      state[accountId] = {
        status: IssuedServiceVouchersStatus.Loading,
      };
    },
    issuedServiceVouchersRequestCompleted(state, action: PayloadAction<IssuedServiceVouchersRequestCompletedPayload>) {
      const { accountId, vouchers } = action.payload;
      state[accountId] = {
        status: IssuedServiceVouchersStatus.Ok,
        vouchers,
      };
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
