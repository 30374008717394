//@flow

import React from 'react';

import { InputComponent } from '../input/input.component';

export const DecimalInputComponent = (props /*: {}*/) => {
  const mask = {
    mask: '999999999999',
    maskChar: '',
    formatChars: {
      '9': '[0-9,.]',
    },
  };

  return <InputComponent {...props} mask={mask} />;
};
