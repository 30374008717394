import { FunctionComponent } from 'react';
import { ConnectedDateRangeComponent } from 'app/shared/form/connected/date-range/connected-date-range.component';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { FormikAdapterField, FormikAdapterFieldProps, ValueTransformation } from './formik-adapter-field.component';
import { isEmpty } from 'lodash';
import moment from 'moment';

type InputValue = {
  // startDate and endDate can also be an empty string
  startDate: string | null;
  endDate: string | null;
  // ConnectedDateRangeComponent also passes an "validation" object which we will ignore
  validation: unknown;
};

export interface FieldValue {
  startDate?: Date;
  endDate?: Date;
}

// Properties of input field that ConnectedDateRangeComponent wraps inside of Field are not defined, thus any type here.
const FormikPeriodAdapterField = (props: FormikAdapterFieldProps<unknown, InputValue, FieldValue>) => {
  function fieldValueToInputValue(fieldValue: FieldValue): InputValue {
    const formatDate = (date: Date | undefined): string | null => {
      return date ? moment(date).format(DATE_VALUE_FORMAT) : null;
    };
    return {
      startDate: formatDate(fieldValue.startDate),
      endDate: formatDate(fieldValue.endDate),
      validation: {},
    };
  }

  function inputValueToFieldValue(inputValue: InputValue): FieldValue {
    if (inputValue.startDate || inputValue.endDate) {
      const isValidDateString = (dateString: string | null): dateString is string => {
        return !!dateString && !isEmpty(dateString);
      };
      return {
        startDate: isValidDateString(inputValue.startDate) ? new Date(inputValue.startDate) : undefined,
        endDate: isValidDateString(inputValue.endDate) ? new Date(inputValue.endDate) : undefined,
      };
    }
    return {};
  }

  const valueTransformation: ValueTransformation<InputValue, FieldValue> = {
    fieldValueToInputValue,
    inputValueToFieldValue,
  };
  return <FormikAdapterField {...props} valueTransformation={valueTransformation} />;
};

interface ServiceEventRowPeriodComponentProps {
  name: string;
  endDateDisabled: boolean;
  maxEndDate: Date;
}

export const PeriodComponent: FunctionComponent<ServiceEventRowPeriodComponentProps> = ({
  name,
  endDateDisabled,
  maxEndDate,
}) => {
  const bounds = {
    maxDate: maxEndDate,
  };
  return (
    <ConnectedDateRangeComponent
      label="COUPONS.MASS_CHARGE.LABEL.SERVICE_PRODUCED"
      field={FormikPeriodAdapterField}
      name={name}
      startDateDisabled={true}
      endDateDisabled={endDateDisabled}
      bounds={bounds}
    />
  );
};
