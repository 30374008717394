// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ListComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { checkPermissions } from 'app/utils';

import { UsersListTablePlaceholder } from './table/users-list-table.placeholder';
import { UsersListTilePlaceholder } from './tile/users-list-tile.placeholder';

/*:: import type { Props } from './users-list.placeholder.types';*/

export class UsersListPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <CardHeaderComponent>
          <PlaceholderComponent width={150} />
          {checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'create']) && (
            <ButtonComponent theme="secondary" className="ml-4 ml-xl-0" to={`${this.props.match.url}/add`}>
              <FormattedMessage id="USERS.ADD_NEW_USER" />
            </ButtonComponent>
          )}
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <ListComponent list={this.props.users} table={UsersListTablePlaceholder} tile={UsersListTilePlaceholder} />
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
