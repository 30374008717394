//@flow

import moment from 'moment';

/*:: import type Moment from 'moment';*/

import { DATE_VALUE_FORMAT } from 'app/constants';

export const calculateDateDiff = (
  startDate /*: Moment*/,
  endDate /*: Moment*/,
  { unit = 'days', dateFormat = DATE_VALUE_FORMAT } /*: any*/ = {},
) => {
  if (!startDate || !endDate) {
    throw new Error(`${!startDate ? 'Start date' : 'End date'} is null`);
  }

  const start = moment.isMoment(startDate) ? startDate : moment(startDate, dateFormat);
  const end = moment.isMoment(endDate) ? endDate : moment(endDate, dateFormat);

  return end.diff(start, unit);
};
