// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';

import { checkPermissions, currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-details-transfers-tile.component.types';*/

export class CouponsDetailsTransfersTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSFERS.HEADERS.ITEM_ISSUED" tagName="small" />
            {dateFormatter(this.props.tile.created)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSFERS.HEADERS.ITEM_ISSUER" tagName="small" />
            {this.props.tile.issuerName}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSFERS.HEADERS.VALID" tagName="small" />
            {dateFormatter(this.props.tile.starts)} - {dateFormatter(this.props.tile.expires)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSFERS.HEADERS.BALANCE_ISSUED" tagName="small" />
            {currencyFormatter.format(this.props.tile.originalBalance)}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSFERS.HEADERS.BALANCE_LEFT" tagName="small" />
            {currencyFormatter.format(this.props.tile.balance)}
          </div>
        </div>

        <div className="buttons">
          {checkPermissions(this.props.permissions, ['transfers', 'update']) && this.props.tile.refundable && (
            <ButtonComponent size="small" theme="failure" onClick={() => this.props.onRedeem(this.props.tile.id)}>
              <FormattedMessage id="COUPONS.TRANSFERS.REDEEM" />
            </ButtonComponent>
          )}
        </div>
      </TileComponent>
    );
  }
}
