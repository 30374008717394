import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { initialize, reduxForm } from 'redux-form';

import { withCoreContext } from 'app/context/core/core.context';

import { updateCurrentCustomer } from 'app/redux/customers/customers.actions';

import { CustomerInformationFormComponent } from './customer-information-form.component';

const mapStateToProps = ({ accounts: { isPostPaymentAccount } }, { customer }) => ({
  initialValues: customer,
  isPostPaymentAccount,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch(updateCurrentCustomer(body)).then(data =>
      dispatch(initialize('edit-customer-information', data.payload.data)),
    ),
  onSubmitFail: () => window.scrollTo(0, 0),
});

export const CustomerInformationFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'edit-customer-information',
  }),
)(CustomerInformationFormComponent);
