// @flow

import React, { PureComponent } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { SeparatorComponent } from 'app/shared';

import { CouponsDetailsTransactionsTablePlaceholder } from './table/coupons-details-transactions-table.placeholder';
import { CouponsDetailsTransactionsTilePlaceholder } from './tile/coupons-details-transactions-tile.placeholder';

/*:: import type { Props } from './coupons-details-transactions.placeholder.types';*/

export class CouponsDetailsTransactionsPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <>
        <SeparatorComponent />
        <ListComponent
          list={this.props.transactions}
          table={CouponsDetailsTransactionsTablePlaceholder}
          tile={CouponsDetailsTransactionsTilePlaceholder}
        />
      </>
    );
  }
}
