// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalComponent } from 'app/shared/modal/modal.component';
import { ButtonComponent } from 'app/shared/button-old/button.component';

import { handleAnalyticsEvent } from 'app/utils/handle-analytics-event/handle-analytics-event.util';

/*:: import type { Props } from './user-inactivity-timer.component.types';*/
import { DECISION_PERIOD_DURATION } from '../token-refresh-timer/refresh-timer-calculator';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const userLoggedIn = (prevProps, newProps) /*: boolean*/ =>
  !prevProps.isUserAuthenticated && newProps.isUserAuthenticated;
const userLoggedOut = (prevProps, newProps) /*: boolean*/ =>
  prevProps.isUserAuthenticated && !newProps.isUserAuthenticated;
const modalWasOpened = (prevState, newState) /*: boolean*/ => !prevState.isModalOpen && newState.isModalOpen;
const modalWasClosed = (prevState, newState) /*: boolean*/ => prevState.isModalOpen && !newState.isModalOpen;

/*:: type UserInactivityTimerState = {
  isModalOpen: boolean,
};*/

const userInteractionEvents = [
  'DOMMouseScroll',
  'keydown',
  'mousedown',
  'mousemove',
  'mouseWheel',
  'move',
  'MSPointerDown',
  'MSPointerMove',
  'touchmove',
  'touchstart',
  'wheel',
];

const attachListenerToUserInteractionEvents = listener => {
  userInteractionEvents.forEach(event => {
    window.addEventListener(event, listener);
  });
  return listener;
};

const detachListenerFromUserInteractionEvents = listener => {
  userInteractionEvents.forEach(event => {
    window.removeEventListener(event, listener);
  });
};

export class UserInactivityTimerComponent extends PureComponent /*:: <Props, UserInactivityTimerState>*/ {
  autoCancelTimeout /*: ?TimeoutID*/ = null;
  modalTimerIntervalId /*: ?IntervalID*/ = null;
  userInteractionEventListener /*: ?Function*/ = null;

  constructor(props /*: Props*/) {
    super(props);
    this.state = { isModalOpen: false };
  }

  componentDidMount() {
    if (this.props.isUserAuthenticated) {
      this.startModalTimer();
    }
  }

  componentWillUnmount() {
    this.stopModalTimer();
    this.stopAutoCancelTimer();
  }

  componentDidUpdate(prevProps /*: Props*/, prevState /*: UserInactivityTimerState*/) {
    if (modalWasOpened(prevState, this.state)) {
      this.stopModalTimer();
      this.startAutoCancelTimer();
    }

    if (modalWasClosed(prevState, this.state)) {
      this.stopAutoCancelTimer();
      this.startModalTimer();
    }

    if (userLoggedIn(prevProps, this.props)) {
      this.startModalTimer();
    }

    if (userLoggedOut(prevProps, this.props)) {
      this.stopModalTimer();
    }
  }

  startAutoCancelTimer = () => {
    this.stopAutoCancelTimer();
    this.autoCancelTimeout = setTimeout(this.onAutoLogout, DECISION_PERIOD_DURATION);
  };

  stopAutoCancelTimer = () => {
    if (this.autoCancelTimeout) {
      clearTimeout(this.autoCancelTimeout);
      this.autoCancelTimeout = null;
    }
  };

  startModalTimer = () => {
    this.stopModalTimer();

    let previousInteractionTime = Date.now();
    const handleUserInteractionEvent = () => {
      previousInteractionTime = Date.now();
    };
    this.userInteractionEventListener = attachListenerToUserInteractionEvents(handleUserInteractionEvent);

    const nextTimeToShowModal = () => {
      return previousInteractionTime + this.props.calculateTimeout();
    };

    this.modalTimerIntervalId = setInterval(() => {
      const callbackTime = Date.now();
      if (callbackTime >= nextTimeToShowModal()) {
        this.openModal();
      }
    }, 1000);
  };

  stopModalTimer = () => {
    if (this.modalTimerIntervalId) {
      clearInterval(this.modalTimerIntervalId);
      this.modalTimerIntervalId = null;
    }
    if (this.userInteractionEventListener) {
      detachListenerFromUserInteractionEvents(this.userInteractionEventListener);
      this.userInteractionEventListener = null;
    }
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
    handleAnalyticsEvent('userInactivity', 'showModal');
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  onConfirm = () => {
    this.closeModal();
    handleAnalyticsEvent('userInactivity', 'continueFromModal');
  };

  onCancel = () => {
    this.closeModal();
    handleAnalyticsEvent('userInactivity', 'logoutCancelingModal');
    this.props.logoutUser();
  };

  onClose = () => {
    this.closeModal();
    handleAnalyticsEvent('userInactivity', 'logoutClosingModal');
    this.props.logoutUser();
  };

  onAutoLogout = () => {
    this.closeModal();
    handleAnalyticsEvent('userInactivity', 'autoLogout');
    this.props.logoutUser();
  };

  render() {
    if (this.state.isModalOpen) {
      return (
        <ModalComponent
          onClose={this.onClose}
          small
          title={<FormattedMessage id="AUTH.CONTINUE_SESSION" />}
          submit={
            <ButtonComponent theme="secondary" size="medium" onClick={this.onConfirm}>
              <FormattedMessage id="CORE.YES" />
            </ButtonComponent>
          }
          cancel={
            <ButtonComponent theme="link" onClick={this.onCancel} size="medium">
              <FormattedMessage id="CORE.NO" />
            </ButtonComponent>
          }
        />
      );
    }

    return null;
  }
}
