// @flow

import React, { Component } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';

import { CouponsDetailsTransfersPlaceholder } from './placeholder/coupons-details-transfers.placeholder';
import { CouponsDetailsTransfersTableComponent } from './table/coupons-details-transfers-table.component';
import { CouponsDetailsTransfersTileComponent } from './tile/coupons-details-transfers-tile.component';

/*:: import type { Props } from './coupons-details-transfers.component.types';*/

export class CouponsDetailsTransfersComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <>
        <CardHeaderComponent title="COUPONS.TRANSFERS.TITLE" />
        <CardBodyComponent>
          {this.props.loading && <CouponsDetailsTransfersPlaceholder />}
          {this.props.loading || (
            <ListComponent
              isCollapsed={false}
              limit={5}
              list={this.props.coupon.transfers}
              table={CouponsDetailsTransfersTableComponent}
              tile={CouponsDetailsTransfersTileComponent}
              collapsible
              props={{
                coupon: this.props.coupon,
                onRedeem: this.props.onRedeem,
                permissions: this.props.permissions,
              }}
            />
          )}
        </CardBodyComponent>
      </>
    );
  }
}
