export const LOGIN_REQUEST = 'users/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'users/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'users/LOGIN_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'users/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'users/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'users/REFRESH_TOKEN_FAILURE';

export const LOGOUT_REQUEST = 'users/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'users/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'users/LOGOUT_FAILURE';

export const LOGIN_WITH_TOKEN_REQUEST = 'users/LOGIN_WITH_TOKEN_REQUEST';
export const LOGIN_WITH_TOKEN_SUCCESS = 'users/LOGIN_WITH_TOKEN_SUCCESS';
export const LOGIN_WITH_TOKEN_FAILURE = 'users/LOGIN_WITH_TOKEN_FAILURE';

export const FETCH_CURRENT_USER_REQUEST = 'users/FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'users/FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'users/FETCH_CURRENT_USER_FAILURE';

export const REQUEST_NEW_PASSWORD_REQUEST = 'users/REQUEST_NEW_PASSWORD_REQUEST';
export const REQUEST_NEW_PASSWORD_SUCCESS = 'users/REQUEST_NEW_PASSWORD_SUCCESS';
export const REQUEST_NEW_PASSWORD_FAILURE = 'users/REQUEST_NEW_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'users/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'users/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'users/RESET_PASSWORD_FAILURE';

export const GENERATE_MAGIC_LINK_REQUEST = 'users/GENERATE_MAGIC_LINK_REQUEST';
export const GENERATE_MAGIC_LINK_SUCCESS = 'users/GENERATE_MAGIC_LINK_SUCCESS';
export const GENERATE_MAGIC_LINK_FAILURE = 'users/GENERATE_MAGIC_LINK_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'users/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'users/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'users/UPDATE_PROFILE_FAILURE';

export const CHANGE_BUSINESS = 'users/CHANGE_BUSINESS';
export const CHANGE_SUB_BUSINESS = 'users/CHANGE_SUB_BUSINESS';

export const EDIT_COLUMNS_SETTINGS_REQUEST = 'users/EDIT_COLUMNS_SETTINGS_REQUEST';
export const EDIT_COLUMNS_SETTINGS_SUCCESS = 'users/EDIT_COLUMNS_SETTINGS_SUCCESS';
export const EDIT_COLUMNS_SETTINGS_FAILURE = 'users/EDIT_COLUMNS_SETTINGS_FAILURE';

export const APPROVE_TERMS_REQUEST = 'users/APPROVE_TERMS_REQUEST';
export const APPROVE_TERMS_SUCCESS = 'users/APPROVE_TERMS_SUCCESS';
export const APPROVE_TERMS_FAILURE = 'users/APPROVE_TERMS_FAILURE';

export const FETCH_USERS_REQUEST = 'users/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'users/FETCH_USERS_FAILURE';

export const ADD_USER_REQUEST = 'users/ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'users/ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'users/ADD_USER_FAILURE';

export const EDIT_USER_ROLES_REQUEST = 'users/EDIT_USER_ROLES_REQUEST';
export const EDIT_USER_ROLES_SUCCESS = 'users/EDIT_USER_ROLES_SUCCESS';
export const EDIT_USER_ROLES_FAILURE = 'users/EDIT_USER_ROLES_FAILURE';

export const START_IMPERSONATION_SESSION_REQUEST = 'users/START_IMPERSONATION_SESSION_REQUEST';
export const START_IMPERSONATION_SESSION_SUCCESS = 'users/START_IMPERSONATION_SESSION_SUCCESS';
export const START_IMPERSONATION_SESSION_FAILURE = 'users/START_IMPERSONATION_SESSION_FAILURE';

export const STOP_IMPERSONATE_USER_REQUEST = 'users/STOP_IMPERSONATE_USER_REQUEST';
export const STOP_IMPERSONATE_USER_SUCCESS = 'users/STOP_IMPERSONATE_USER_SUCCESS';
export const STOP_IMPERSONATE_USER_FAILURE = 'users/STOP_IMPERSONATE_USER_FAILURE';

export const SESSION_EXPIRED = 'users/session/EXPIRED';
