// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedRadiosComponent, Flag, SeparatorComponent, Typography } from 'app/shared';
import { Modal } from 'app/shared/modal-new/modal.container';
import { CardBodyComponent } from 'app/shared/card-new';
import flagFi from 'assets/images/flags/flag_finnish.svg';
import flagSv from 'assets/images/flags/flag_swedish.svg';
import flagEn from 'assets/images/flags/flag_english.svg';

/*:: import type { Props } from './language-switcher.component.types';*/

const flags = {
  fi: flagFi,
  sv: flagSv,
  en: flagEn,
};

const langOptions = Object.keys(flags).map((lang /*: string*/) => ({
  id: lang,
  name: <Flag flag={flags[lang]} alt={lang} />,
}));

export class LanguageSwitcherComponent extends Component /*:: <Props>*/ {
  onSubmit = ({ language } /*: { language: 'en' | 'fi' | 'sv' }*/) => {
    this.props.onClose();
    this.props.updateLocale(language);
  };

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'SIDEBAR.CHANGE_LANGUAGE' })}
        confirmOptions={[
          {
            label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
            onClick: this.props.onClose,
            theme: 'primary-outline',
            arrowBack: true,
          },
          {
            label: this.props.intl.formatMessage({ id: 'CORE.SAVE' }),
          },
        ]}
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        size="small"
        tag="form"
        isOpen
      >
        <CardBodyComponent>
          <Typography type="subtitle" color="grey" id="SIDEBAR.CHANGE_LANGUAGE_DESC" />
          <SeparatorComponent />

          <Field
            name="language"
            component={ConnectedRadiosComponent}
            options={langOptions}
            itemProps={{ labelPosition: 'bottom-center' }}
            columns={3}
            columnAlign="center"
          />
        </CardBodyComponent>
      </Modal>
    );
  }
}
