import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubmittablePostpayServiceVoucher } from 'v2/service-voucher/postpay/utils';
import { ErrorResponse, FormData, FormValidationErrorResponse, ServiceEvent } from 'v2/types/postpay';

const SLICE_NAME = 'postpay-service-event';

export enum ServiceEventStatus {
  NotInitialized = 'not-initialized',
  Initializing = 'initializing',
  InitializationFailed = 'initialization-failed',
  Submitting = 'submitting',
  SubmissionFailed = 'submission-failed',
  Submitted = 'submitted',
}

export type ServiceEventState =
  | { status: ServiceEventStatus.NotInitialized }
  | { status: ServiceEventStatus.Initializing }
  | { status: ServiceEventStatus.InitializationFailed; error: ErrorResponse }
  | { status: ServiceEventStatus.SubmissionFailed; error: FormValidationErrorResponse; serviceEvent: ServiceEvent }
  | {
      status: ServiceEventStatus.Submitting | ServiceEventStatus.Submitted;
      serviceEvent: ServiceEvent;
    };

export interface StateSlice {
  [SLICE_NAME]: ServiceEventState;
}

export type ServiceEventSubmissionRequest = {
  voucher: SubmittablePostpayServiceVoucher;
  data: FormData;
};

export type ServiceEventSubmissionRequestCompletion =
  | {
      status: ServiceEventStatus.Submitted;
      serviceEvent: ServiceEvent;
      voucherId: string;
    }
  | {
      status: ServiceEventStatus.SubmissionFailed;
      serviceEvent: ServiceEvent;
      voucherId: string;
      error: FormValidationErrorResponse;
    }
  | {
      status: ServiceEventStatus.InitializationFailed;
      error: ErrorResponse;
    };

const initialState = { status: ServiceEventStatus.NotInitialized } as ServiceEventState;
const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    serviceEventSubmissionRequested(state, _action: PayloadAction<ServiceEventSubmissionRequest>) {
      switch (state.status) {
        case ServiceEventStatus.NotInitialized:
        case ServiceEventStatus.InitializationFailed:
          return {
            status: ServiceEventStatus.Initializing,
          };
        case ServiceEventStatus.SubmissionFailed:
          return {
            serviceEvent: state.serviceEvent,
            status: ServiceEventStatus.Submitting,
          };
        default:
          return state;
      }
    },
    serviceEventSubmissionCompleted(state, action: PayloadAction<ServiceEventSubmissionRequestCompletion>) {
      // process action only if state is appropriate for this action
      switch (state.status) {
        case ServiceEventStatus.Initializing:
        case ServiceEventStatus.Submitting:
          break;
        default:
          return state;
      }

      switch (action.payload.status) {
        case ServiceEventStatus.InitializationFailed:
          return {
            status: ServiceEventStatus.InitializationFailed,
            error: action.payload.error,
          };
        case ServiceEventStatus.SubmissionFailed:
          return {
            status: ServiceEventStatus.SubmissionFailed,
            serviceEvent: action.payload.serviceEvent,
            error: action.payload.error,
          };
        case ServiceEventStatus.Submitted:
          return {
            status: ServiceEventStatus.Submitted,
            serviceEvent: action.payload.serviceEvent,
          };
        default:
          return state;
      }
    },
    newServiceEventInputStarted(state) {
      switch (state.status) {
        default:
          return {
            status: ServiceEventStatus.NotInitialized,
          };
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
