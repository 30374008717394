// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { SplashComponent, ThemeComponent } from 'app/core';
import { RegisterSuccessComponent } from 'app/register/shared';

import { RegisterFormContainer } from './form/register-form.container';
import { RegisterSummaryContainer } from './summary/register-summary.container';

/*:: import type { BodyType, Props, State } from './register.component.types';*/
import styles from './register.module.scss';

export class RegisterComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  componentDidMount() {
    this.props.fetchVenuesTypes();
    this.props.fetchServiceProviderMunicipalDictionary();
  }

  changeStep = (step /*: string*/) => this.setState({ step }, () => window.scrollTo(0, 0));

  onSubmit = (body /*: BodyType*/) => this.props.onValidate(body).then(() => this.changeStep('SUMMARY'));

  onConfirm = (body /*: BodyType*/) =>
    this.props
      .onSubmit(body)
      .then(() => this.changeStep('SUCCESS'))
      .then(() => this.props.reset());

  render() {
    if (this.props.loading) {
      return <SplashComponent />;
    }

    return (
      <ThemeComponent className={styles.main} theme="sidebarless">
        {this.state.step === 'FORM' && (
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <RegisterFormContainer submitting={this.props.submitting} />
          </form>
        )}
        {this.state.step === 'SUMMARY' && (
          <form onSubmit={this.props.handleSubmit(this.onConfirm)}>
            <RegisterSummaryContainer submitting={this.props.submitting} changeStep={this.changeStep} />
          </form>
        )}
        {this.state.step === 'SUCCESS' && (
          <RegisterSuccessComponent title="REGISTER.SUCCESS_COUPON_TITLE">
            <FormattedMessage id="REGISTER.SUCCESS_COUPON_DESCRIPTION" />
          </RegisterSuccessComponent>
        )}
      </ThemeComponent>
    );
  }
}
