//@flow

import React from 'react';

/*:: import type { Props } from './separator.component.types.js.flow';*/

const prepareProperty = property => {
  if (!property) {
    return property;
  }

  if (typeof property === 'string') {
    return property;
  }

  return `${property}px`;
};

export const SeparatorComponent = (props /*: Props*/) => {
  const { height = 24, margin = 0 } = props;

  const styles = {
    height: prepareProperty(height),
    margin: prepareProperty(margin),
  };

  return <div style={styles} />;
};
