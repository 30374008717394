// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { GroupComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './customer-information.placeholder.types';*/

export class CustomerInformationPlaceholder extends Component /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <CardHeaderComponent title="CUSTOMERS.GENERAL_INFORMATION" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.NAME' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.BUSINESS_ID' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.PHONE' })}>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>
        <SeparatorComponent />

        <CardHeaderComponent title="CUSTOMERS.OFFICIAL_ADDRESS" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.ZIP_CODE' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'CUSTOMERS.CITY' })} required>
                <PlaceholderComponent height={42} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>

        <CardFooterComponent justifyContent="flex-end">
          <PlaceholderComponent radius={8} color="primary" width={60} height={35} />
        </CardFooterComponent>
      </>
    );
  }
}
