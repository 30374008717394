//@flow

import React from 'react';

import { getMessageObject } from 'app/utils';

/*:: import type { Props } from './connect-control.component.types';*/

export const ConnectControl = (props /*: Props*/) => {
  const { input, values, component: Component, onFieldUsage, ...otherProps } = props;
  //$FlowFixMe
  const { meta: inputMeta } = otherProps;

  const messageObject = getMessageObject(inputMeta, values);

  //overwritten for handle usage
  const newInputObject = {
    ...input,
    onChange: e => {
      const onChange = props.onChange || input.onChange;
      onChange(e);

      if (!!onFieldUsage && typeof onFieldUsage === 'function') {
        onFieldUsage(input.name);
      }
    },
  };

  const onFocus = () => {
    input.onFocus();

    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    input.onBlur();

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const componentProps = {
    ...otherProps,
    ...newInputObject,
    input: newInputObject,
    error: messageObject,
    onFocus,
    onBlur,
  };

  return <Component {...componentProps} />;
};
