// @flow

import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';

import { Modal } from 'app/shared/modal-new/modal.container';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';
import { SeparatorComponent } from 'app/shared';

/*:: import type { Props, State } from './confirm.component.types';*/

class Confirm extends PureComponent /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  onConfirm = () => {
    this.setState({ loading: true }, () => {
      Promise.resolve(this.props.onConfirm())
        .then(() => {
          this.setState({ loading: false }, this.onClose);
        })
        .catch(() => {
          this.setState({ loading: false }, this.onClose);
        });
    });
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.onClose();
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { size, intl, title, children, show } = this.props;

    return (
      <Modal
        size={size}
        confirmOptions={[
          {
            label: intl.formatMessage({ id: 'CORE.BACK' }),
            onClick: this.onCancel,
            theme: 'primary-outline',
            arrowBack: true,
          },
          {
            label: intl.formatMessage({ id: 'CORE.CONFIRM' }),
            onClick: this.onConfirm,
            loading: this.state.loading,
          },
        ]}
        isOpen={show}
      >
        <CardHeaderComponent title={title} typographyProps={{ color: 'greyDark' }} />

        {children && (
          <>
            {title && <SeparatorComponent />}
            <CardBodyComponent>{children}</CardBodyComponent>
          </>
        )}
      </Modal>
    );
  }
}

export const ConfirmComponent = injectIntl/*:: <Props> */(Confirm);
