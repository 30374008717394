import { createAction } from 'redux-actions';

import {
  SIDEBAR_CLOSE,
  SIDEBAR_DESTROY,
  SIDEBAR_OPEN,
  SIDEBAR_REGISTER,
  SIDEBAR_TOGGLE,
} from './sidebar.actions-types';

export const sidebarRegister = createAction(SIDEBAR_REGISTER, payload => payload);

export const sidebarDestroy = createAction(SIDEBAR_DESTROY, payload => payload);

export const sidebarOpen = createAction(SIDEBAR_OPEN, payload => payload);

export const sidebarClose = createAction(SIDEBAR_CLOSE, payload => payload);

export const sidebarToggle = createAction(SIDEBAR_TOGGLE, payload => payload);
