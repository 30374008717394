import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import once from 'lodash/once';
import { ROUTES } from 'app/constants';
import { withCoreContext } from 'app/context/core/core.context';

import { editCouponType } from 'app/redux/types/types.actions';
import { lockBusinessChange, unlockBusinessChange } from 'app/redux/view/view.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';

import { CouponsTypeFormComponent } from '../form/coupons-type-form.component';

const mapStateToProps = (
  {
    customers: {
      entities: { current },
    },
    dictionaries,
  },
  { couponType: initialValues },
) => ({
  current,
  initialValues,
  priceUnitOptions: dictionaries['price-units'],
  services: dictionaries['municipal/services'].services.municipal,
  descriptiveServices: dictionaries['municipal/services'].descriptiveServices,
});

const mapDispatchToProps = (dispatch, { couponType: { id } }) => ({
  onChange: once(() => dispatch(lockBusinessChange())),
  onSubmit: (body, successHandler, failureHandler) => {
    return withFormSubmissionErrorHandling(dispatch(editCouponType(id, body)), successHandler, failureHandler);
  },
  onSubmitSuccess: () =>
    dispatch(
      push({
        pathname: ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT,
        state: {
          success: 'COUPON_TYPE_EDITED',
        },
      }),
    ),
  onUnmount: () => dispatch(unlockBusinessChange()),
});

export const CouponsTypeFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsTypeFormComponent);
