import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import validators, { numericality } from 'redux-form-validators';
import { injectIntl } from 'react-intl';

import { handleAnalyticsEvent } from 'app/utils';
import { CouponsDownloadPdfFileComponent } from './coupons-downloads-pdf-file.component';

import { download } from 'app/redux/downloads/downloads.actions';

const form = 'coupons-download-pdf-file';
const selector = formValueSelector(form);

const mapsStateToProps = (state, { transfers }) => {
  const {
    users: { business },
  } = state;

  return {
    lang: selector(state, 'lang'),
    type: selector(state, 'type'),
    business,

    initialValues: {
      lang: 'fi',
      pages: 1,
      ...(transfers ? { transferId: transfers[0].id } : {}),
    },

    validate: ({ pages }) => {
      validators.formatMessage = ({ id, values: { count } = {} }) => {
        const msg = { id };

        return { ...msg, values: { value: count } };
      };

      // redux-form calls validate function without initialValues applied in componentWillMount
      // and componentDidMount life-cycle hooks. We are not able to validate the form if pages is undefined
      // thus bailing out here.
      if (!pages) return {};

      const message = numericality({
        '>=': 1,
        '<=': 50,
        msg: {
          greaterThanOrEqualTo: { id: 'COUPONS.SHARE.WITH_PAGES.MIN_PAGES' },
          lessThanOrEqualTo: { id: 'COUPONS.SHARE.WITH_PAGES.MAX_PAGES' },
        },
      })(+pages);

      return { _error: message };
    },
  };
};

const mapDispatchToProps = (dispatch, { coupons }) => ({
  onSubmit: ({ type, lang, pages, transferId }) => {
    const params = {
      coupons: transferId ? coupons.map(coupon => `${coupon}:${transferId}`) : coupons,
      ...(type === 'voucher' ? {} : { pages }),
    };

    return dispatch(download({ path: `service-coupons/pdf/${type}`, locale: lang, params }));
  },
  changeDownloadType: type => dispatch(change(form, 'type', type)),
  onProvidersDownload: (businessId, locale, coupon) => {
    handleAnalyticsEvent('vouchers', 'download', 'service providers');

    return dispatch(download({ path: 'service-coupons/venues/pdf', locale, params: { business: businessId, coupon } }));
  },
});

export const CouponsDownloadPdfFileContainer = compose(
  injectIntl,
  connect(mapsStateToProps, mapDispatchToProps),
  reduxForm({ form, enableReinitialize: true }),
)(CouponsDownloadPdfFileComponent);
