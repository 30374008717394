// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

/*:: import type { Props } from '../table/vat-archive-table.placeholder.types';*/
import styles from '../../table/vat-archive-list-table.module.scss';

export class VatArchiveTablePlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent>
          <TableRowComponent>
            <TableCellComponent variant="header" className={styles.checkboxCol} />
            <TableCellComponent variant="header" content="VAT_ARCHIVE.LIST.ACCOUNT" />
            <TableCellComponent variant="header" content="VAT_ARCHIVE.LIST.SUM" />
            <TableCellComponent variant="header" content="VAT_ARCHIVE.LIST.BILLS" />
            <TableCellComponent variant="header" className={styles.buttonCol}>
              <ButtonComponent theme="primary" className={styles.button} disabled={true}>
                <i className="fi fi-printer" />
                <FormattedMessage id="VAT_ARCHIVE.LIST.PRINT_SELECTED" />
              </ButtonComponent>
            </TableCellComponent>
          </TableRowComponent>
        </TableHeadComponent>
        <TableBodyComponent>
          {this.props.list.map((_, index) => (
            <TableRowComponent key={index}>
              <TableCellComponent>
                <PlaceholderComponent />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={300} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent color="purple" />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
