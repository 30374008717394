// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { isBusinessDifferent } from 'app/utils';
import { ROUTES } from 'app/constants';
import { CouponsTypeFormContainer } from './coupons-type-form.container';
import { CouponsTypeFormPlaceholderContainer } from '../form/placeholder/coupons-type-form.placeholder.container';

/*:: import type { Props } from './coupons-type-edit.component.types';*/

export class CouponsTypeEditComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchCouponType();
    this.props.fetchCurrentCustomer();
    this.props.fetchDictionaries();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.onBusinessChange();
    }
  }

  render() {
    return (
      <>
        <PageHeaderComponent title="COUPONS.TYPES.FORM_TITLE.EDIT" backLink={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT} />
        {this.props.loading && <CouponsTypeFormPlaceholderContainer edit />}
        {this.props.loading || <CouponsTypeFormContainer couponType={this.props.couponType} />}
      </>
    );
  }
}
