// @flow

import React, { createContext } from 'react';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Locale } from './app.component.types';*/

/*:: type AppContextType = {
  update: Function,
  value: 'en' | 'fi' | 'sv',
};*/

export const AppContext = createContext/*:: <AppContextType> */({ value: 'fi', update: () => {} });

export const withAppContext = (WrappedComponent /*: ComponentType<any>*/) => (props /*: any*/) =>
  (
    <AppContext.Consumer>
      {(locale /*: Locale*/) => <WrappedComponent {...props} locale={locale.value} updateLocale={locale.update} />}
    </AppContext.Consumer>
  );

export const withLocale = (WrappedComponent /*: ComponentType<any>*/) => {
  const WithLocale = (props /*: any*/) => (
    <AppContext.Consumer>
      {(locale /*: Locale*/) => <WrappedComponent {...props} locale={locale.value} />}
    </AppContext.Consumer>
  );

  WithLocale.displayName = `withLocale(${WrappedComponent.displayName || WrappedComponent.name || 'Component'}`;
  return WithLocale;
};
