// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { MassEditComponent } from './mass-edit.component';

/*:: import type { FormPayment } from '../coupons-mass-charge-form.component.types';*/

const mapStateToProps = ({ dictionaries }) => ({
  vatRatesDictionary: get(dictionaries, ['municipal/services', 'vatRates'], []),
});

const mapDispatchToProps = (dispatch, { form: { getState, change }, coupons }) => ({
  fetchDictionaries: () => dispatch(fetchDictionary('municipal/services', true)),
  onSubmit: body => {
    const {
      values: { payments },
    } = getState();

    return change(
      'payments',
      payments.map((payment /*: FormPayment*/, index /*: number*/) /*: FormPayment*/ => {
        if (payment.active) {
          return {
            ...payment,
            ...omit(omitBy(body, isNil), coupons[index].priceUnit ? '' : 'numberOfPriceUnits'),
            serviceProduced: { ...payment.serviceProduced, ...omitBy(body.serviceProduced, isNil) },
            vatRate: payment.vatRate ? (!!body.vatRate ? body.vatRate : payment.vatRate) : undefined,
          };
        }

        return payment;
      }),
    );
  },
});

export const MassEditContainer = compose(connect(mapStateToProps, mapDispatchToProps))(MassEditComponent);
