//@flow
import moment from 'moment';

/*:: import type { BoundsDateRangeType } from 'app/flow-types';*/

export const birthdayBounds /*: BoundsDateRangeType*/ = {
  minDate: moment().subtract(120, 'year').startOf('year'),
  maxDate: moment(),
};

export const voucherValidityBounds /*: BoundsDateRangeType*/ = {
  minDate: moment().subtract(1, 'year').startOf('year'),
  maxDate: moment().add(3, 'year').endOf('year'),
};

export const voucherChargeBounds /*: BoundsDateRangeType*/ = {
  minDate: null,
  maxDate: moment().endOf('day'),
};
