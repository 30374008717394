// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { PlaceholderComponent } from 'app/shared';

import { VenueFormPlaceholderContainer } from 'app/venues/form/placeholder/venue-form.placeholder.container';

export class EditVenuePlaceholder extends Component /*:: <any>*/ {
  render() {
    return (
      <>
        <PageHeaderComponent backLink="/venues">
          <PlaceholderComponent width={200} height={31} color="purple" />
        </PageHeaderComponent>

        <VenueFormPlaceholderContainer />
      </>
    );
  }
}
