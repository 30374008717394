import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchCurrentCustomer } from 'app/redux/customers/customers.actions';

import { CouponsTypeAddComponent } from './coupons-type-add.component';

const mapStateToProps = ({
  customers: {
    entities: { current },
  },
  dictionaries,
  users: { business },
}) => ({
  business,
  loading: !dictionaries['municipal/services'] || !dictionaries['price-units'] || !current,
});

const mapDispatchToProps = dispatch => ({
  fetchDictionaries: () =>
    Promise.all([dispatch(fetchDictionary('municipal/services', true)), dispatch(fetchDictionary('price-units'))]),
  fetchCurrentCustomer: () => dispatch(fetchCurrentCustomer()),
});

export const CouponsTypeAddContainer = compose(
  withAuthorization(['serviceCouponTypes', 'create']),
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsTypeAddComponent);
