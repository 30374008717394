// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new';
import { Modal } from 'app/shared/modal-new/modal.container';
import { getDownloadLabel } from 'app/vat-archive/utils/get-download-label.util';
import { handleAnalyticsEvent } from 'app/utils';
import { PDF, SUMMARY } from 'app/vat-archive/vat-archive.dictionary';

/*:: import type { Props } from './vat-archive-modal.component.types';*/
import styles from './vat-archive-modal.module.scss';

export class VatArchiveModalComponent extends Component /*:: <Props>*/ {
  onClick = (path /*::?: string*/) => {
    this.onDownloadUsage(path);

    return this.props.onDownload(path);
  };

  onDownloadUsage = (path /*::?: string*/) => {
    const label = getDownloadLabel(path);

    handleAnalyticsEvent('vatArchive', 'download', label);
  };

  render() {
    const { title, toggleModal, isOpen } = this.props;

    return (
      <Modal
        onClose={toggleModal}
        title={title}
        size="small"
        outsideClick={toggleModal}
        isOpen={isOpen}
        confirmOptions={[
          {
            label: <FormattedMessage id="CORE.CLOSE" />,
            onClick: toggleModal,
            theme: 'primary-outline',
            className: 'ml-auto',
          },
        ]}
      >
        <CardBodyComponent>
          <ButtonComponent onClick={() => this.onClick()} className={styles.button}>
            <i className="fi fi-download-excel" />
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.DOWNLOAD_XLSX" />
          </ButtonComponent>
          <ButtonComponent onClick={() => this.onClick(PDF)} className={styles.button}>
            <i className="fi fi-pdf-booklet" />
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.DOWNLOAD_PDF" />
          </ButtonComponent>
          <ButtonComponent onClick={() => this.onClick(SUMMARY)} className={styles.button}>
            <i className="fi fi-pdf" />
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.DOWNLOAD_SUMMARY" />
          </ButtonComponent>
        </CardBodyComponent>
      </Modal>
    );
  }
}
