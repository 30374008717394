import { V2RootState } from 'v2/v2.reducer';
import { name as serviceVouchersSliceName } from './service-vouchers-slice';
import { name as printServiceVouchersSliceName } from './print-service-vouchers-slice';
import { name as issuedServiceVouchersSliceName } from './search/issued-service-vouchers-slice';
import { PostpayAccountId } from 'v2/types/postpay';

export const voucherStateSelector = (voucherId: string) => (state: V2RootState) =>
  state[serviceVouchersSliceName].vouchers[voucherId];

export const voucherIssuingStateSelector = (voucherId: string) => (state: V2RootState) =>
  state[serviceVouchersSliceName].issuing[voucherId];

export const voucherPrintStateSelector = (voucherId: string) => (state: V2RootState) =>
  state[printServiceVouchersSliceName][voucherId];

export const issuedVouchersSelector = (accountId: PostpayAccountId) => (state: V2RootState) =>
  state[issuedServiceVouchersSliceName][accountId];
