// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './tile.component.types';*/
import styles from './tile.module.scss';

export class TileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return <div className={classNames(styles.tile, this.props.className)}>{this.props.children}</div>;
  }
}
