// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedInputComponent, ConnectedTextareaComponent } from 'app/shared';
import { length, required } from 'app/utils';

/*:: import type { Props } from './orders-create-invoicing-options.component.types';*/

export class OrdersCreateInvoicingOptionsComponent extends Component /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <div>
        <div className="row">
          <div className={columnClassNames}>
            <Field
              name="invoiceName"
              component={ConnectedInputComponent}
              label="ORDERS.OFFICIAL_NAME"
              disabled
              required
              validate={required()}
            />
          </div>
          <div className={columnClassNames}>
            <Field
              name="invoiceEmail"
              component={ConnectedInputComponent}
              label="ORDERS.EMAIL"
              required
              validate={required()}
            />
          </div>
        </div>
        {this.props.invoicingMethod === 'EINVOICE' && (
          <div className="row">
            <div className={columnClassNames}>
              <Field
                name="eInvoiceIdentifier"
                component={ConnectedInputComponent}
                label="ORDERS.DELIVERY_IDENTIFIER"
                append="ORDERS.DELIVERY_IDENTIFIER_INFO"
                required
                validate={required()}
              />
            </div>
            <div className={columnClassNames}>
              <Field
                name="eInvoiceAddress"
                component={ConnectedInputComponent}
                label="ORDERS.DELIVERY_ADDRESS"
                append="ORDERS.DELIVERY_ADDRESS_INFO"
                required
                validate={required()}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className={columnClassNames}>
            <Field
              name="invoiceReference"
              component={ConnectedTextareaComponent}
              label="ORDERS.ORDER_REFERENCE"
              maxLength={30}
              minRows={1}
              validate={length({ max: 30 })}
            />
          </div>
        </div>
      </div>
    );
  }
}
