// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { ButtonComponent, ConnectedInputComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { required } from 'app/utils';

import { OrdersCreateInvoicingContainer } from './invoicing/orders-create-invoicing.container';
import { OrdersCreatePlaceholderContainer } from './placeholder/orders-create.placeholder.container';

/*:: import type { Props } from './orders-create-form.component.types';*/

export class OrdersCreateFormComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <>
        {this.props.loading && <OrdersCreatePlaceholderContainer type={this.props.type} />}

        {this.props.loading || (
          <CardComponent>
            <CardHeaderComponent title="ORDERS.ORDER_EMONEY_SUBTITLE" />
            <SeparatorComponent />
            <CardBodyComponent>
              <div className="row">
                <div className="col-lg-6 col-xl-4">
                  <Field
                    name="amount"
                    component={ConnectedInputComponent}
                    label="ORDERS.SERVICE_COUPON_EMONEY"
                    icon="euro-symbol"
                    required
                    validate={required()}
                    mask={{
                      mask: '999999999999',
                      maskChar: '',
                      formatChars: {
                        '9': '[0-9,.]',
                      },
                    }}
                  />
                </div>
              </div>
            </CardBodyComponent>
            <CardBodyComponent>
              <OrdersCreateInvoicingContainer form="create-order" type={`${this.props.type}Orders`} />
            </CardBodyComponent>

            <CardFooterComponent>
              <ButtonComponent theme="primary" loading={this.props.submitting} className="ml-auto">
                <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" />
              </ButtonComponent>
            </CardFooterComponent>
          </CardComponent>
        )}
      </>
    );
  }
}
