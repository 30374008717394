//@flow

import React from 'react';
import { Field } from 'redux-form';

import { TableCellComponent } from 'app/shared/table-new';
import { translate } from 'app/utils/translations';
import { useIntl } from 'app/hooks';
import { PriceListTableSubHeader } from '../sub-header/price-list-table-sub-header.component';
import styles from './price-list-table-header.module.scss';

/*:: import type { HeaderComponentProps, PriceListHeaderComponentProps } from './price-list-table-header.component.types';*/

const HeaderComponent /*: React$ComponentType<HeaderComponentProps>*/ = (props /*: HeaderComponentProps*/) => {
  const intl = useIntl();

  const { index, input, editMode, editable, hasSubtitles, showDiff, meta } = props;

  const {
    value: { title, subtitle },
    name,
  } = input;

  const isFirstColumn = index === 0;
  const subtitleComponent = hasSubtitles ? (
    isFirstColumn ? (
      <div className={styles.emptyCell}>&nbsp;</div>
    ) : (
      <PriceListTableSubHeader editMode={editMode} name={name} form={meta.form} subtitle={subtitle} editable={editable} showDiff={showDiff} />
    )
  ) : null;

  return (
    <TableCellComponent variant="header" align="center" className="">
      <div>{translate({ intl, translation: title })}</div>
      {subtitleComponent}
    </TableCellComponent>
  );
};

export const PriceListTableHeaderComponent = (props /*: PriceListHeaderComponentProps*/) => (
  <Field key={props.name} {...props} component={HeaderComponent} />
);
