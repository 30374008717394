// @flow

import React, { Component } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

import { headers } from '../../table/coupons-types-list-table.component';

/*:: import type { Props } from './coupons-types-list-table.placeholder.types';*/

export class CouponsTypesListTablePlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map((_, type) => (
            <TableRowComponent key={type}>
              <TableCellComponent>
                <PlaceholderComponent width={18} height={18} radius="50%" />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={type % 2 === 0 ? 100 : 150} />
              </TableCellComponent>
              <TableCellComponent>{type > 2 && <PlaceholderComponent width={200} />}</TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={120} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={80} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={70} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={80} />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
