//@flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';

import {
  ConnectedDateRangeComponent,
  ConnectedDecimalInputComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  TooltipComponent,
} from 'app/shared';
import { CouponsBalanceComponent, CouponsCalculatorContainer } from 'app/coupons/shared';
import { length } from 'app/utils';
import { voucherChargeBounds } from 'app/utils/dates';

/*:: import type { Option } from 'app/shared/form/connected/select/connected-select.component.types';*/
import { combine, format } from 'redux-form-validators';
import { numberOfPriceUnitsValidator } from 'app/coupons/paymentsValidation';
/*:: import type { Props, VatRate } from './services-row.types';*/
/*:: import type { Transfer } from 'app/coupons/details/transfers/coupons-details-transfers.component.types';*/
import styles from './services-row.component.module.scss';

export class ServicesRowComponent extends PureComponent /*:: <Props>*/ {
  components = {
    serviceProducedField: ({ field } /*: { field: string }*/) => (
      <ConnectedDateRangeComponent
        field={Field}
        name={`${field}.serviceProduced`}
        label="COUPONS.MASS_CHARGE.LABEL.SERVICE_PRODUCED"
        onFocusChange={this.props.toggle}
        required
        daysCounter
        autofillEndDate
        daysClassName={styles.datesWrapper}
        bounds={voucherChargeBounds}
      />
    ),
    couponsBalanceField: ({ transfers } /*: { transfers: Transfer[] }*/) => (
      <CouponsBalanceComponent
        serviceProducedStart={this.props.serviceProduced.startDate}
        serviceProducedEnd={this.props.serviceProduced.endDate}
        transfers={transfers}
        equalFormHeights
      />
    ),
    // $FlowFixMe
    numberOfPriceUnitsField: ({ field, priceUnit } /*: { field: string, priceUnit: string }*/) => (
      <ConnectedDecimalInputComponent
        field={Field}
        name={`${field}.numberOfPriceUnits`}
        placeholder={`COUPONS.TYPES.UNITS.${priceUnit}`}
        label="COUPONS.MASS_CHARGE.LABEL.NUMBER_OF_PRICE_UNITS"
        validate={[numberOfPriceUnitsValidator]}
        required
      />
    ),
    amountField: ({ field, maxTransaction } /*: { field: string, maxTransaction?: number }*/) => (
      <CouponsCalculatorContainer
        value={{
          name: `${field}.amount`,
          label: this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.CHARGE.AMOUNT' }),
          required: true,
          values: {
            maxPrice: maxTransaction,
          },
        }}
        calculations={`${field}.amountCalculations`}
        form="charge-service-coupon"
      />
    ),
    vatRateField: ({ field } /*: { field: string }*/) => (
      <Field
        name={`${field}.vatRate`}
        component={ConnectedSelectComponent}
        options={this.vatRatesOptions()}
        label="COUPONS.CHARGE.VAT_RATES.LABEL"
        required
      />
    ),
    descriptiveServiceField: ({ field } /*: { field: string }*/) => (
      <Field
        name={`${field}.descriptiveService`}
        component={ConnectedSelectComponent}
        options={this.serviceOptions()}
        label="TRANSACTIONS.PRODUCED_SERVICES"
        valueRenderer={value => value.name}
        required
      />
    ),
    referenceField: ({ field } /*: { field: string }*/) => (
      <Field
        name={`${field}.reference`}
        component={ConnectedInputComponent}
        label="TRANSACTIONS.REFERENCE"
        validate={combine(format({ without: /\s/i, message: 'VALIDATION.SPACES_NOT_ALLOWED' }), length({ max: 255 }))}
      />
    ),
  };

  vatRatesOptions = () => {
    const {
      dictionary: { vatRates: vatRatesDict },
    } = this.props;

    // prettier-ignore
    return vatRatesDict.map/*:: <Option>*/((vatRate/*: VatRate*/) => ({
      id: vatRate.rate.toString(),
      name: this.props.intl.formatMessage({ id: `COUPONS.CHARGE.VAT_RATES.${vatRate.name}` }),
    }));
  };

  serviceOptions = () => {
    const {
      coupon: { descriptiveServices = [] },
      dictionary: {
        // $FlowFixMe
        descriptiveServices: descriptiveServicesDict,
      },
      intl,
    } = this.props;

    // $FlowFixMe
    return descriptiveServices.map(service => {
      const descriptiveService = (descriptiveServicesDict || []).find(
        descriptiveService => descriptiveService.id === service,
      );

      let name = service;
      if (descriptiveService && descriptiveService.custom === false) {
        name = intl.formatMessage({ id: `COUPONS.DESCRIPTIVE_SERVICES.${descriptiveService.id}` });
      }

      return {
        id: {
          id: service,
          name: name,
        },
        name: name,
      };
    });
  };

  renderExtendedColumns = () => {
    const {
      field,
      index,
      coupon: { descriptiveServices = [], maxTransaction, transfers, vatRatesEnabled, priceUnit },
      onClone,
      canRemove,
      onRemove,
      sidebarCollapsed,
    } = this.props;

    const wideColumnClassNames = classNames('col-lg-8', 'col-xl-4', {
      'col-12 col-md-7': sidebarCollapsed,
    });

    const balanceClassNames = classNames('col-lg-4', 'col-xl-auto', {
      'col-12 col-md-5 col-lg-3': sidebarCollapsed,
    });

    const columnClassNames = classNames('col-lg-4', 'col-xl-3', 'col-xxl-2', {
      'col-12 col-md-4': sidebarCollapsed,
    });

    const priceClassNames = classNames('col-lg-4', 'col-xl-3', 'col-xxl-2', {
      'col-12 col-md-5': sidebarCollapsed,
    });

    return (
      <>
        <div className={classNames('row', styles.row)}>
          <div className={wideColumnClassNames}>{this.components.serviceProducedField({ field })}</div>
          <div className={balanceClassNames}>{this.components.couponsBalanceField({ transfers })}</div>

          <span className={styles.iconsExtended}>
            <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.CLONE' })} placement="top">
              <i onClick={() => onClone(index)} className={classNames(styles.clone, 'fi fi-copy')} />
            </TooltipComponent>
            {canRemove && (
              <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.REMOVE' })} placement="top">
                <i onClick={() => onRemove(index)} className={classNames(styles.remove, 'fi fi-remove-bold')} />
              </TooltipComponent>
            )}
          </span>
        </div>

        <div className={classNames('row', styles.row)}>
          {!!descriptiveServices.length && (
            <div className={wideColumnClassNames}>{this.components.descriptiveServiceField({ field })}</div>
          )}

          {!!priceUnit && (
            <div className={priceClassNames}>{this.components.numberOfPriceUnitsField({ field, priceUnit })}</div>
          )}

          <div className={columnClassNames}>{this.components.amountField({ field, maxTransaction })}</div>

          {vatRatesEnabled && <div className={columnClassNames}>{this.components.vatRateField({ field })}</div>}

          <div className={columnClassNames}>{this.components.referenceField({ field })}</div>
        </div>
      </>
    );
  };

  render() {
    const {
      field,
      index,
      coupon: { descriptiveServices = [], maxTransaction, transfers, vatRatesEnabled, priceUnit },
      sidebarCollapsed,
      onClone,
      canRemove,
      onRemove,
    } = this.props;

    const columnClassNames = classNames('col-12', 'col-md-6', 'col-lg-4', 'col-xl-3', ' col-xxl-2', {
      'col-lg-3': sidebarCollapsed,
    });

    const balanceClassNames = classNames('col-12', 'col-6', 'col-lg-4', 'col-xl-auto', {
      'col-lg-3': sidebarCollapsed,
    });

    const datesClassNames = classNames('col-12', 'col-lg-6', 'col-xl-3', styles.dates, {
      'col-lg-3': sidebarCollapsed,
    });

    const wrapperClassName = index % 2 === 0 ? styles.wrapper : styles.wrapperOdd;

    return (
      <>
        <div className={wrapperClassName}>
          {!!descriptiveServices.length || vatRatesEnabled ? (
            this.renderExtendedColumns()
          ) : (
            <div className={classNames('row', styles.row)}>
              <div className={datesClassNames}>{this.components.serviceProducedField({ field })}</div>
              <div className={balanceClassNames}>{this.components.couponsBalanceField({ transfers })}</div>

              {!!priceUnit && (
                <div className={columnClassNames}>{this.components.numberOfPriceUnitsField({ field, priceUnit })}</div>
              )}

              <div className={columnClassNames}>{this.components.amountField({ field, maxTransaction })}</div>

              <div className={columnClassNames}>{this.components.referenceField({ field })}</div>

              <span className={styles.icons}>
                <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.CLONE' })} placement="top">
                  <i onClick={() => onClone(index)} className={classNames(styles.clone, 'fi fi-copy')} />
                </TooltipComponent>
                {canRemove && (
                  <TooltipComponent overlay={this.props.intl.formatMessage({ id: 'CORE.REMOVE' })} placement="top">
                    <i onClick={() => onRemove(index)} className={classNames(styles.remove, 'fi fi-remove-bold')} />
                  </TooltipComponent>
                )}
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
}
