import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/context/core/core.context';

import { CouponsCreatePlaceholderComponent } from './coupons-create-placeholder.component';

export const CouponsCreatePlaceholderContainer = compose(
  injectIntl,
  withCoreContext,
)(CouponsCreatePlaceholderComponent);
