import { FormattedMessage } from 'app/translations/formatted-message.component';
import { ModalComponent } from 'v2/utils/modal/modal.component';

export const CancelIssuingWarningModal: React.FC<{ isOpened: boolean; onCancel: () => void; onConfirm: () => void }> =
  ({ isOpened, onCancel, onConfirm }) => {
    return (
      <ModalComponent
        opened={isOpened}
        cancelButton={{
          label: 'SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CANCEL_MODAL.CANCEL_BUTTON',
          onClick: onCancel,
        }}
        confirmButton={{
          label: 'SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CANCEL_MODAL.CONFIRM_BUTTON',
          onClick: onConfirm,
        }}
      >
        <strong>
          <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CANCEL_MODAL.TEXT" />
        </strong>
      </ModalComponent>
    );
  };
