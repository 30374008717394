//@flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSpy } from 'react-final-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  ConnectedCheckboxComponent,
  ConnectedDateRangeComponent,
  ConnectedDecimalInputComponent,
  ConnectedSelectComponent,
  SummaryFieldComponent,
} from 'app/shared';
import { CouponsCalculatorFinalContainer } from 'app/coupons/shared';
import { currencyFormatter, getUnitTranslationId, voucherChargeBounds } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import {
  amountFieldValidator,
  numberOfPriceUnitsValidator,
  serviceProducedValidator,
} from 'app/coupons/paymentsValidation';

/*:: import type { Props } from './array-row-component.types.js.flow';*/
import styles from './array-row.module.scss';

const labelClassNames = classNames('col-6', 'col-xl-2');
const genericFieldClassNames = classNames('col-12', 'col-xl-2');
const datesClassNames = classNames('col-12', 'col-xl-6');
const amountClassNames = classNames('col-12', 'col-xl-2');
const infoClassNames = classNames('col-12', 'col-xl-4');

const commonSubscriptions = {
  value: true,
  pristine: true,
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  submitFailed: true,
  touched: true,
  active: true,
};

const RowDetails = ({ coupon, location }) => {
  const totalBalance = coupon.transfers
    ? coupon.transfers.reduce((balanceSum, { balance }) => balanceSum + balance, 0)
    : 0;

  return (
    <div className={classNames('row', styles.row)}>
      <div className={labelClassNames}>
        <SummaryFieldComponent className={styles.summary}>
          <FormattedMessage id="COUPONS.MASS_CHARGE.LABEL.SERVICE_COUPON_CODE" tagName="strong" />
          <Link
            to={{
              pathname: `/coupons/${coupon.id}`,
              state: { previousPath: location.pathname },
            }}
            className={styles.coupon}
          >
            {coupon.id}
          </Link>
        </SummaryFieldComponent>
      </div>
      <div className={labelClassNames}>
        <SummaryFieldComponent className={styles.summary}>
          <FormattedMessage id="COUPONS.DETAILS.LABEL.TOTAL" tagName="strong" />
          <div className={styles.balance}>{coupon.transfers ? currencyFormatter.format(totalBalance) : '-'}</div>
        </SummaryFieldComponent>
      </div>
      <div className={labelClassNames}>
        <SummaryFieldComponent className={styles.summary}>
          <FormattedMessage id={getUnitTranslationId(coupon.priceUnit)} tagName="strong" />
          <div className={styles.description}>{currencyFormatter.format(coupon.unitCost)}</div>
        </SummaryFieldComponent>
      </div>
      <div className={labelClassNames}>
        <SummaryFieldComponent className={styles.summary}>
          <FormattedMessage id="COUPONS.MASS_CHARGE.LABEL.PRICE_UNIT" tagName="strong" />
          <div className={styles.description}>
            {coupon.priceUnit ? <FormattedMessage id={`COUPONS.TYPES.UNITS.${coupon.priceUnit}`} /> : '-'}
          </div>
        </SummaryFieldComponent>
      </div>
      <div className={infoClassNames}>
        <SummaryFieldComponent className={styles.summary}>
          <FormattedMessage id="COUPONS.MASS_CHARGE.LABEL.ADDITIONAL_INFO" tagName="strong" />
          <div className={styles.description}>{coupon.additionalInfo ? coupon.additionalInfo : '-'}</div>
        </SummaryFieldComponent>
      </div>
    </div>
  );
};

export const ArrayRowComponent = (props /*: Props*/) => {
  const { field, vatRatesOptions, location, coupon } = props;

  const wrapperStyles = classNames(styles.wrapper, {
    [styles.isNew]: props.isNew,
    [styles.invalidated]: !!coupon.invalidated,
  });

  const couponHasDescriptiveServices = coupon.descriptiveServices && coupon.descriptiveServices.length > 0;

  return (
    <div className={wrapperStyles}>
      <div className={classNames('row', styles.row)}>
        <div className="col-12">
          <SummaryFieldComponent className={styles.beneficiary}>
            {props.isNew && (
              <span className={styles.tag}>
                <FormattedMessage id="COUPONS.MASS_CHARGE.LABEL.NEW" />
              </span>
            )}
            {coupon.gender === 'm' && <i className={classNames('fi fi-male', styles.male)} />}
            {coupon.gender === 'f' && <i className={classNames('fi fi-female primary', styles.female)} />}
            {coupon.reference && <span className={styles.name}>{coupon.reference}</span>}
            <span className={styles.birthday}>
              <i className="fi fi-cake mr-1" />
              <span className={styles.date}>{dateFormatter(coupon.dateOfBirth)}</span>
            </span>
          </SummaryFieldComponent>
        </div>
        {!couponHasDescriptiveServices && (
          <div className={classNames('d-none d-md-block', styles.select)}>
            <Field
              name={`${field}.active`}
              component={ConnectedCheckboxComponent}
              subscription={{
                value: true,
              }}
            />
          </div>
        )}
      </div>
      <div className={classNames('row', styles.row)}>
        <div className="col-12">
          {!couponHasDescriptiveServices && (
            <Field name={`${field}.active`} subscription={{ value: true }}>
              {({ input: { value: active } }) => {
                return (
                  <div className={classNames('row d-none d-md-flex', styles.row)}>
                    <div className={datesClassNames}>
                      <ConnectedDateRangeComponent
                        field={Field}
                        name={`${field}.serviceProduced`}
                        label="COUPONS.MASS_CHARGE.LABEL.SERVICE_PRODUCED"
                        required
                        daysCounter
                        autofillEndDate
                        disabled={!active}
                        subscription={commonSubscriptions}
                        bounds={voucherChargeBounds}
                        validate={[active && serviceProducedValidator({ coupon, fixExpiryDate: true })]}
                      />
                    </div>
                    <div className={genericFieldClassNames}>
                      {!!coupon.priceUnit && (
                        <ConnectedDecimalInputComponent
                          field={Field}
                          name={`${field}.numberOfPriceUnits`}
                          label="COUPONS.MASS_CHARGE.LABEL.NUMBER_OF_PRICE_UNITS"
                          required
                          disabled={!active}
                          subscription={commonSubscriptions}
                          validate={[active && numberOfPriceUnitsValidator]}
                        />
                      )}
                    </div>
                    <div className={amountClassNames}>
                      <FormSpy
                        subscription={{
                          submitting: true,
                        }}
                      >
                        {({ form }) => (
                          <CouponsCalculatorFinalContainer
                            value={{
                              name: `${field}.amount`,
                              label: 'COUPONS.MASS_CHARGE.LABEL.AMOUNT',
                              required: true,
                              disabled: !active,
                              values: {
                                maxPrice: coupon.maxTransaction,
                              },
                              validate: [active && amountFieldValidator(coupon, field, true)],
                            }}
                            calculations={`${field}.amountCalculations`}
                            form={form}
                          />
                        )}
                      </FormSpy>
                    </div>
                    {coupon.vatRatesEnabled && (
                      <div className={genericFieldClassNames}>
                        <Field
                          name={`${field}.vatRate`}
                          component={ConnectedSelectComponent}
                          options={vatRatesOptions}
                          label={'COUPONS.CHARGE.VAT_RATES.LABEL'}
                          required
                          disabled={!active}
                          subscription={commonSubscriptions}
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            </Field>
          )}
          {couponHasDescriptiveServices && (
            <Field name={`${field}.active`} subscription={{ value: true }}>
              {({ input: { value: active } }) => {
                return (
                  <div className={classNames('row d-none d-md-flex', styles.row)}>
                    <span className={styles.beneficiary}>
                      <FormattedMessage id="COUPONS.MASS_CHARGE.LABEL.UNMASSDEBITABLE" />
                    </span>
                  </div>
                );
              }}
            </Field>
          )}
          <RowDetails coupon={coupon} location={location} />
        </div>
      </div>
    </div>
  );
};
