/*:: import { Dispatch } from 'redux'; */
import { RSAA } from 'redux-api-middleware';
import { createAction } from 'redux-actions';

import { StatusEnum } from 'app/constants';

import * as types from './users.action-types';

export const loginUser = ({ username, password }) => ({
  [RSAA]: {
    types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
    endpoint: '/login',
    method: 'POST',
    isPublic: true,
    body: {
      username,
      password,
    },
  },
});

export const refreshToken = () => ({
  [RSAA]: {
    types: [
      types.REFRESH_TOKEN_REQUEST,
      types.REFRESH_TOKEN_SUCCESS,
      {
        type: types.REFRESH_TOKEN_FAILURE,
        meta: {
          noValidation: true,
        },
      },
    ],
    endpoint: '/refresh',
    method: 'POST',
  },
});

export const logoutUser = (redirect = true, cause) => ({
  [RSAA]: {
    types: [
      types.LOGOUT_REQUEST,
      {
        type: types.LOGOUT_SUCCESS,
        meta: {
          redirect,
          state: {
            cause,
          },
        },
      },
      types.LOGOUT_FAILURE,
    ],
    endpoint: '/logout',
    method: 'POST',
  },
});

const fetchCurrentUserRSAA = isInitialFetchUserRequest => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_CURRENT_USER_REQUEST,
        meta: { isInitialFetchUserRequest },
      },
      types.FETCH_CURRENT_USER_SUCCESS,
      {
        type: types.FETCH_CURRENT_USER_FAILURE,
        meta: { isInitialFetchUserRequest },
      },
    ],
    endpoint: '/users/me',
    method: 'GET',
  },
});

const shouldFetchCurrentUser = ({ users }) => !('isAuthenticated' in users);

export const fetchCurrentUser =
  (initial = false) =>
  (dispatch, getState) => {
    if (!initial || shouldFetchCurrentUser(getState())) {
      return dispatch(fetchCurrentUserRSAA(initial));
    } else {
      return Promise.resolve();
    }
  };

export const requestNewPassword = username => ({
  [RSAA]: {
    types: [types.REQUEST_NEW_PASSWORD_REQUEST, types.REQUEST_NEW_PASSWORD_SUCCESS, types.REQUEST_NEW_PASSWORD_FAILURE],
    endpoint: '/users/restore-password',
    method: 'POST',
    isPublic: true,
    body: {
      username,
    },
  },
});

export const resetPassword = (passwordToken, body) => ({
  [RSAA]: {
    types: [types.RESET_PASSWORD_REQUEST, types.RESET_PASSWORD_SUCCESS, types.RESET_PASSWORD_FAILURE],
    endpoint: `/users/restore-password/${passwordToken}`,
    method: 'POST',
    isPublic: true,
    body,
  },
});

export const updateProfile = (section, body) => ({
  [RSAA]: {
    types: [types.UPDATE_PROFILE_REQUEST, types.UPDATE_PROFILE_SUCCESS, types.UPDATE_PROFILE_FAILURE],
    endpoint: `/users/me/${section}`,
    method: 'PATCH',
    body,
  },
});

export const changeBusiness = business => (dispatch, getState) => {
  const { permissions } = getState();

  if (permissions[business.id]) {
    return dispatch({ type: types.CHANGE_BUSINESS, payload: business });
  } else {
    return Promise.resolve();
  }
};

export const editColumnsSettings = (list, body) => ({
  [RSAA]: {
    types: [
      {
        type: types.EDIT_COLUMNS_SETTINGS_REQUEST,
        meta: { body, list },
      },
      types.EDIT_COLUMNS_SETTINGS_SUCCESS,
      types.EDIT_COLUMNS_SETTINGS_FAILURE,
    ],
    endpoint: `/users/columns-metadata/${list}`,
    method: 'PUT',
    body,
  },
});

export const approveTerms = key => ({
  [RSAA]: {
    types: [
      types.APPROVE_TERMS_REQUEST,
      {
        type: types.APPROVE_TERMS_SUCCESS,
        meta: { key },
      },
      types.APPROVE_TERMS_FAILURE,
    ],
    endpoint: `/users/me/terms/${key}/status`,
    method: 'PUT',
    body: {
      status: StatusEnum.Approved,
    },
  },
});

export const fetchUsers = (customer, context, clearUsers = true) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_USERS_REQUEST,
        meta: { customer, clearUsers },
      },
      {
        type: types.FETCH_USERS_SUCCESS,
        meta: { customer, clearUsers },
      },
      types.FETCH_USERS_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users`,
    method: 'GET',
  },
});

export const addUser = (customer, context, body) => ({
  [RSAA]: {
    types: [
      types.ADD_USER_REQUEST,
      {
        type: types.ADD_USER_SUCCESS,
        meta: {
          user: body,
          customer,
        },
      },
      types.ADD_USER_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users`,
    method: 'POST',
    body,
  },
});

export const editUserRoles = (customer, context, userId, body, roles) => ({
  [RSAA]: {
    types: [
      types.EDIT_USER_ROLES_REQUEST,
      {
        type: types.EDIT_USER_ROLES_SUCCESS,
        meta: {
          customer,
          userId,
          roles,
        },
      },
      types.EDIT_USER_ROLES_FAILURE,
    ],
    endpoint: `/customers/${customer}/${context}/users/${userId}/roles`,
    method: 'POST',
    body,
  },
});

export const impersonateUserAndRefresh = userId => async (dispatch /*: Dispatch*/) => {
  const impersonationResponse = await dispatch(impersonateUser(userId));

  window.location.href = '/';
  return impersonationResponse;
};

export const impersonateUser = userId => ({
  [RSAA]: {
    types: [
      {
        type: types.START_IMPERSONATION_SESSION_REQUEST,
        meta: { userId },
      },
      {
        type: types.START_IMPERSONATION_SESSION_SUCCESS,
        meta: { userId },
      },
      types.START_IMPERSONATION_SESSION_FAILURE,
    ],
    endpoint: `/impersonation-sessions`,
    method: 'POST',
    body: {
      userId,
    },
  },
});

export const stopImpersonateUserAndRefresh = async (dispatch /*: Dispatch*/) => {
  const impersonationResponse = await dispatch(stopImpersonateUser());
  window.location.href = '/';
  return impersonationResponse;
};

export const stopImpersonateUser = () => ({
  [RSAA]: {
    types: [
      types.STOP_IMPERSONATE_USER_REQUEST,
      types.STOP_IMPERSONATE_USER_SUCCESS,
      types.STOP_IMPERSONATE_USER_FAILURE,
    ],
    endpoint: `/impersonation-sessions/current`,
    method: 'DELETE',
  },
});

export const sessionExpired = (redirectToLoginPage = true) =>
  createAction(
    types.SESSION_EXPIRED,
    payload => payload,
    () => ({
      redirectToLoginPage,
    }),
  )();
