// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { SummaryBoxPlaceholder } from './placeholder/summary-box.placeholder';

/*:: import type { DataRow, Props } from 'app/shared/summary-box/summary-box.component.types';*/
import styles from './summary-box.module.scss';

export class SummaryBox extends PureComponent /*:: <Props>*/ {
  getCell = (value /*: React$Element<any> | string*/, key /*: number*/) => {
    if (typeof value === 'string') {
      return <span key={key}>{value}</span>;
    }

    return <React.Fragment key={key}>{value}</React.Fragment>;
  };

  getRow = (dataRow /*: DataRow*/, key /*: number*/) => (
    <div key={key} className={classNames(styles.row, dataRow.classes ? dataRow.classes : '')}>
      {dataRow.values.map(this.getCell)}
    </div>
  );

  render() {
    const { sections } = this.props;

    if (!sections) {
      return <SummaryBoxPlaceholder />;
    }

    return (
      <div className={styles.wrapper}>
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            <p className={styles.title}>{section.title}</p>
            <div className={styles.container}>{section.data.map(this.getRow)}</div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
