// @flow

import React, { Component } from 'react';
import { Field } from 'react-final-form';

import { ConnectedCheckboxesFormComponent } from 'app/shared';
import { handleAnalyticsEvent } from 'app/utils';

/*:: import type { Props, State } from './coupons-list-customize.component.types';*/
/*:: import type { FormApi } from 'final-form';*/
import styles from './coupons-list-customize.module.scss';

export class CouponsListCustomizeComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = { isOpened: localStorage.getItem('sharedServiceCouponsFilters') !== 'false' };
  }

  onChange =
    ({ getState } /*: FormApi*/) =>
    () => {
      const { values } = getState();
      const { listName } = this.props;
      const { columns = [] } = values || {};

      handleAnalyticsEvent('vouchers', 'displayFilterUsage');

      this.props.onChange({
        columns: columns
          .filter(({ checked }) => checked)
          .map(({ id }) => id)
          //filter names with Input on the end
          .filter(column => !/\w*Input\b/.test(column)),
        list: listName,
      });
    };

  onOpen = () =>
    this.setState(
      () => ({ isOpened: true }),
      () => localStorage.setItem('sharedServiceCouponsFilters', 'true'),
    );

  onClose = () =>
    this.setState(
      () => ({ isOpened: false }),
      () => localStorage.setItem('sharedServiceCouponsFilters', 'false'),
    );

  render() {
    return (
      <div>
        <div className={styles.container}>
          <Field
            name="columns"
            theme="columns"
            component={ConnectedCheckboxesFormComponent}
            onChange={this.onChange(this.props.form)}
          />
        </div>
      </div>
    );
  }
}
