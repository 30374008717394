import { put, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { DOWNLOAD_FILE_FAILURE } from 'app/redux/downloads/downloads.action-types';
import { LOGOUT_SUCCESS } from 'app/redux/users/users.action-types';

import { notificationPush, notificationsClear } from './notifications.actions';
import { notificationTypes } from 'dictionaries/notifications.dictionary';

export function* handleDownloadFileFailure(action) {
  const { payload: exception } = action;

  yield put(
    notificationPush({
      title: 'NOTIFICATION.DOWNLOAD_FILE_FAILED.TITLE',
      message: {
        id: 'NOTIFICATION.DOWNLOAD_FILE_FAILED.MESSAGE',
      },
      type: notificationTypes.FAILURE,
    }),
  );

  if (exception) {
    Sentry.captureException(exception);
  }
}

export function* clearNotifications() {
  yield put(notificationsClear());
}

export function* notificationsSaga() {
  yield takeEvery(DOWNLOAD_FILE_FAILURE, handleDownloadFileFailure);
  yield takeEvery(LOGOUT_SUCCESS, clearNotifications);
}
