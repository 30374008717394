// @flow
// $FlowFixMe
import { useEffect, useRef } from 'react';

import { useBusinessContext } from 'app/context';
/*:: import type { Business } from 'app/types/business.types';*/
/*:: import type { Config } from './useOnBusinessChange.types';*/

export const useOnBusinessChange = ({ onChange, skipOnMount = false } /*: Config*/) => {
  const business /*: Business*/ = useBusinessContext();
  const skip = useRef(skipOnMount);

  useEffect(
    () => {
      if (skip.current) {
        skip.current = false;
      } else {
        onChange(business);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [business.id],
  );
};
