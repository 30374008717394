//@flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import get from 'lodash/get';

import { CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent, SeparatorComponent, Typography } from 'app/shared';

import { FormattedMessage } from 'react-intl';

/*:: import type { Props } from './modal.component.types';*/
import styles from './modal.component.module.scss';

export class ModalComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    adjustToContent: false,
    element: document.getElementById('modal-root'),
    size: 'medium',
  };

  titleTypographyProps = {
    color: 'primary',
    tag: 'h4',
    type: 'largeText',
  };

  componentDidMount() {
    if (this.props.name) {
      this.props.register();
    }

    this.handleModalStatus();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    const { isModalOpen } = this.props;

    if (isModalOpen !== prevProps.isModalOpen) {
      this.handleModalStatus();
    }
  }

  componentWillUnmount() {
    if (this.props.name) {
      this.props.destroy();
    }

    this.handleModalStatus(false);
  }

  handleModalStatus = (isOpen /*::?: boolean*/) => {
    const isModalOpen = isOpen === undefined ? this.props.isModalOpen : isOpen;

    if (isModalOpen) {
      // $FlowFixMe
      document.body.classList.add('modal-open');
    } else {
      setTimeout(() => {
        if (this.props.element) {
          const modals = this.props.element.children.length;

          if (modals === 0) {
            // $FlowFixMe
            document.body.classList.remove('modal-open');
          }
        }
      }, 1);
    }
  };

  renderBody = () => {
    const { component: Component, componentProps = {}, children } = this.props;

    const modalActions = {
      modalClose: this.props.close,
    };

    const modalContentComponentProps = {
      ...componentProps,
      ...modalActions,
    };

    //child as render function or render children
    if (children) {
      return children && typeof children === 'function' ? children(modalContentComponentProps) : children;
    }

    //render component from props
    if (Component) {
      return <Component {...modalContentComponentProps} />;
    }

    throw Error('You forgot about render element');
  };

  renderFooter() {
    const { footer, footerProps, cardFooterProps, confirmOptions } = this.props;

    if (!footer && !confirmOptions) {
      return null;
    }

    const modalActions = {
      modalClose: this.props.close,
    };

    const modalFooterComponentProps = {
      ...footerProps,
      ...modalActions,
    };

    const renderFooterContent = () => {
      //render footer from Component
      if (get(footer, ['prototype', 'render'], false)) {
        return <footer {...modalFooterComponentProps} />;
      }

      //render footer as function or stateless component
      if (typeof footer === 'function') {
        return footer(modalFooterComponentProps);
      }

      //render footer as array buttons
      if (confirmOptions) {
        return confirmOptions.map(({ label, arrowBack, ...buttonProps }, index) => (
          <ButtonComponent {...buttonProps} key={index}>
            {arrowBack && <i className="fi fi-arrow-left" />}
            {label}
          </ButtonComponent>
        ));
      }

      if (React.isValidElement(footer)) {
        return footer;
      }
    };

    return (
      <>
        <SeparatorComponent />
        <CardFooterComponent {...(cardFooterProps || {})}>{renderFooterContent()}</CardFooterComponent>
      </>
    );
  }

  renderModal() {
    const {
      adjustToContent,
      close,
      outsideClick,
      onSubmit,
      size,
      tag: Tag = 'div',
      title,
      subtitle,
      titleTypographyProps,
    } = this.props;

    const tagProps = {
      ...(Tag === 'form' ? { onSubmit } : {}),
      className: styles.modalBackdrop,
    };

    const cardStyle = {
      ...(adjustToContent ? { width: 'auto' } : {}),
    };

    const cardClassNames = classNames(styles.content, this.props.cardClassNames, {
      [styles.fitToContentWidth]: adjustToContent,
      [styles[size]]: !adjustToContent,
    });

    return (
      <Tag
        {...tagProps}
        onClick={e => {
          const shouldClose = e.target.classList.contains(styles.modalBackdrop);

          if (shouldClose) {
            outsideClick ? outsideClick() : close();
          }
        }}
      >
        <CardComponent className={cardClassNames} style={cardStyle}>
          {title && (
            <>
              <CardHeaderComponent
                title={title}
                typographyProps={{ ...this.titleTypographyProps, ...titleTypographyProps }}
              >
                {subtitle && (
                  <Typography color="grey" type="subtitle">
                    <FormattedMessage id={subtitle} />
                  </Typography>
                )}
              </CardHeaderComponent>

              <SeparatorComponent />
            </>
          )}

          <div className={styles.body}>{this.renderBody()}</div>
          {this.renderFooter()}
        </CardComponent>
      </Tag>
    );
  }

  render() {
    if (!this.props.isModalOpen) {
      return null;
    }

    return this.props.element ? ReactDOM.createPortal(this.renderModal(), this.props.element) : this.renderModal();
  }
}
