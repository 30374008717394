import { RSAA } from 'redux-api-middleware';

import * as types from './dictionaries.action-types';
import _ from 'lodash';

const getPath = path => {
  const index = path.indexOf('?');
  if (index !== -1) return path.substring(0, index);

  return path;
};

const fetchDictionaryRSAA = (path, isPublic) => ({
  [RSAA]: {
    types: [
      types.FETCH_DICTIONARY_REQUEST,
      {
        type: types.FETCH_DICTIONARY_SUCCESS,
        meta: { path: getPath(path) },
      },
      {
        type: types.FETCH_DICTIONARY_FAILURE,
        meta: { path: getPath(path) },
      },
    ],
    endpoint: isPublic ? `/public/dictionaries/${path}` : `/dictionaries/${path}`,
    method: 'GET',
    ...(isPublic ? { isPublic } : {}),
  },
});

const shouldFetchDictionary = ({ users, dictionaries }, dictionary) => {
  const businessBasedDictionaries = ['invoicing-options'];
  if (businessBasedDictionaries.includes(dictionary)) {
    const business = _.get(users, 'business.id');
    return !dictionaries[dictionary] || !_.get(dictionaries, `[${dictionary}][${business}]`);
  }
  return !dictionaries[dictionary] || !Object.keys(dictionaries[dictionary]).length;
};

export const fetchDictionary =
  (dictionary, isPublic, force = false) =>
  (dispatch, getState) => {
    if (force || shouldFetchDictionary(getState(), dictionary)) {
      return dispatch(fetchDictionaryRSAA(dictionary, isPublic));
    } else {
      return Promise.resolve();
    }
  };
