import React, { useState } from 'react';
import classNames from 'classnames';
import { Typography } from 'app/shared';

import styles from './accordion.module.scss';

type Props = {
  text: string;
  className?: string;
  maxCollapsedLength: number;
};

export const AccordionComponent: React.FunctionComponent<Props> = ({ text, className, maxCollapsedLength }) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  return (
    <div className={classNames(className, 'd-flex mb-1')}>
      <Typography type="bodyText" color="greyDark" className={styles.text}>
        {isOpen ? text : `${text.substring(0, maxCollapsedLength)}...`}
      </Typography>
      <Typography type="bodyText" color="greyDark">
        <i className={classNames(isOpen ? "fi fi-arrow-up" : "fi fi-arrow-down", "pl-3 link")} onClick={() => setOpen(!isOpen)} />
      </Typography>
    </div>
  );
};
