// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

import { TitleComponent } from 'app/auth/title/title.component';
import { ConnectedInputComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';

/*:: import type { Props } from './login.component.types';*/
import styles from './login.module.scss';

export class LoginComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <section className={styles.container}>
        {this.props.invalid && (
          <div className={classNames(styles.alert, styles.error)}>
            <FormattedMessage id="AUTH.LOGIN_PAGE_ERROR" />
          </div>
        )}

        {!!this.props.location.state && !!this.props.location.state.cause && (
          <div className={classNames(styles.alert, styles.success, 'text-center')}>
            {this.props.location.state.cause === 'INACTIVITY' && <FormattedMessage id="AUTH.LOGOUT_INACTIVE" />}
          </div>
        )}

        <TitleComponent>
          <FormattedMessage id="AUTH.LOGIN" />
        </TitleComponent>

        <form onSubmit={this.props.handleSubmit} noValidate className={styles.form}>
          <Field
            name="username"
            type="email"
            label="AUTH.EMAIL"
            placeholder={this.props.intl.formatMessage({ id: 'AUTH.EMAIL_PLACEHOLDER' })}
            component={ConnectedInputComponent}
          />

          <Field
            name="password"
            type="password"
            label="AUTH.PASSWORD"
            placeholder={this.props.intl.formatMessage({ id: 'AUTH.PASSWORD_PLACEHOLDER' })}
            component={ConnectedInputComponent}
          />

          <Link className={styles.link} to="/request-password">
            {this.props.intl.formatMessage({ id: 'AUTH.REQUEST_PASSWORD' })}
          </Link>

          <div className="d-flex">
            <ButtonComponent
              theme="secondary"
              size="large"
              loading={this.props.submitting || this.props.submitSucceeded}
              className={styles.submit}
            >
              {this.props.intl.formatMessage({ id: 'AUTH.LOGIN' })}
            </ButtonComponent>
          </div>
        </form>
      </section>
    );
  }
}
