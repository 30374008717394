//@flow

import React, { PureComponent } from 'react';
import { Field, Fields } from 'redux-form';
import get from 'lodash/get';
import classNames from 'classnames';

import { ConnectedDecimalInputComponent, ConnectedMoneyInputComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';

import { currencyFormatter } from 'app/utils';

/*:: import type { Props, RenderFieldsProps } from './calculations-row.component.types';*/

//There are 2 calculators, so at least make them use same SCSS files
import styles from 'app/coupons/shared/final-calculator/calculations/calculations-row/calculations-row.module.scss';

const getFieldValue = (props, name) => {
  const fieldValue = get(props, `${name}.input.value`, 0);

  return +`${fieldValue}`.replace(/,/g, '.');
};

const isFieldEmpty = (props, name) => {
  const fieldValue = get(props, `${name}.input.value`);
  return fieldValue === undefined || `${fieldValue}`.trim() === '';
};

const hasFieldChanged = (prevProps, props, name) => {
  return (
    isFieldEmpty(prevProps, name) !== isFieldEmpty(props, name) ||
    getFieldValue(prevProps, name) !== getFieldValue(props, name)
  );
};

class RenderFields extends PureComponent /*:: <RenderFieldsProps>*/ {
  componentDidUpdate(prevProps) {
    const [amountFieldName, priceFieldName] = this.props.names;
    if (
      hasFieldChanged(prevProps, this.props, amountFieldName) ||
      hasFieldChanged(prevProps, this.props, priceFieldName)
    ) {
      const amount = getFieldValue(this.props, amountFieldName);
      const price = getFieldValue(this.props, priceFieldName);

      if (!!this.props.change && amount >= 0 && price >= 0) this.props.change();
    }
  }

  render() {
    const amount = getFieldValue(this.props, this.props.names[0]);
    const price = getFieldValue(this.props, this.props.names[1]);

    return currencyFormatter.format(amount * price);
  }
}

export class CalculationsRowComponent extends PureComponent /*:: <Props>*/ {
  render() {
    const { field, onChange, onRemove, disabled } = this.props;

    return (
      <div className="row">
        <div className={styles.removeRow}>
          <ButtonComponent
            className={styles.removeRowButton}
            disabled={disabled}
            theme="transparent"
            size="small"
            onClick={onRemove}
          >
            <div className="fi fi-remove" />
          </ButtonComponent>
        </div>
        <div className={classNames('col-12 col-md', styles.input)}>
          <ConnectedDecimalInputComponent
            field={Field}
            name={`${field}.amount`}
            label={this.props.intl.formatMessage({ id: 'CALCULATOR.AMOUNT_OF_UNITS' })}
            icon="stack"
          />
        </div>
        <div className={classNames('col-12 col-md', styles.input)}>
          <ConnectedMoneyInputComponent field={Field} name={`${field}.price`} label="CALCULATOR.PRICE_PER_UNIT" />
        </div>
        <div className={styles.total}>
          = <Fields names={[`${field}.amount`, `${field}.price`]} component={RenderFields} change={onChange} />
        </div>
      </div>
    );
  }
}
