//@flow

import moment from 'moment/moment';

import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { birthdayBounds } from 'app/utils/dates';

/*:: import type { Moment } from 'moment/moment';*/
/*:: import type { Params } from 'app/utils/form/validators/date-picker/date-picker.validator.types';*/

export const dateValidator =
  (params /*: Params*/ = {}) =>
  (value /*: ?Moment*/) => {
    if (!value) {
      return undefined;
    }

    const maxDate = params.maxDate || null;
    const minDate = params.minDate || null;

    const date = moment(value);

    if (maxDate && date.isAfter(maxDate)) {
      return { id: 'VALIDATION.SINGLE_DATE_MUST_BE_BEFORE', values: { maxDate: dateFormatter(maxDate) } };
    }

    if (minDate && date.isBefore(minDate)) {
      return { id: 'VALIDATION.SINGLE_DATE_MUST_BE_AFTER', values: { minDate: dateFormatter(minDate) } };
    }
  };

export const birthdayValidator = dateValidator(birthdayBounds);
