import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/context/core/core.context';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { CouponsDetailsChargeComponent } from './coupons-details-charge.component';
import { getCouponDetails } from 'app/redux/coupons/coupons.selectors';

const mapStateToProps = ({ dictionaries, ...state }) => {
  const { isLoading: couponLoading, coupon, transactions } = getCouponDetails(state);

  return {
    coupon,
    transactions: transactions?.transactions,
    loading: !dictionaries['municipal/services'] || couponLoading || !coupon,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
});

export const CouponsDetailsChargeContainer = compose(
  withCoreContext,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDetailsChargeComponent);
