// @flow

/**
 * take priceUnit
 * returns correct intl id
 * @param priceUnit
 */
const defaultString = 'COUPONS.DETAILS.LABEL.UNIT_COST.DEFAULT';

export const getUnitTranslationId = (priceUnit /*: ?string*/) /*: string*/ => {
  return priceUnit ? `COUPONS.DETAILS.LABEL.UNIT_COST.${priceUnit}` : defaultString;
};
