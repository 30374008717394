import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { initialize, reduxForm } from 'redux-form';

import { withCoreContext } from 'app/context/core/core.context';
import { withPermissions } from 'app/common';

import { updateProfile } from 'app/redux/users/users.actions';

import { PersonalComponent } from './personal.component';

const mapStateToProps = ({
  users: {
    current: { personal: initialValues },
  },
}) => ({
  initialValues,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch(updateProfile('personal-info', body)).then(data =>
      dispatch(initialize('personal-information', data.payload.data.personal)),
    ),
});

export const PersonalContainer = compose(
  injectIntl,
  withPermissions,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'personal-information',
  }),
)(PersonalComponent);
