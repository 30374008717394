// @flow

import React, { Component, Fragment } from 'react';

import { SidebarGroupComponent, SidebarItemContainer } from 'app/core/sidebar/shared';
import { SelectComponent, Typography } from 'app/shared';
import { Modal } from 'app/shared/modal-new/modal.container';
import { businessName } from 'app/utils';
import { CardBodyComponent } from 'app/shared/card-new';
import { StatusEnum } from 'app/constants';

// $FlowFixMe
import { ReactComponent as Hourglass } from 'assets/images/hourglass.svg';

/*:: import type { Business } from 'app/types/business.types';*/
import { generateSidebarOptions } from './sidebar-options-generator/sidebar-options-generator';
import { SidebarUserBusinessComponent } from './business/sidebar-user-business.component';
import styles from './sidebar-user.module.scss';
/*:: import type { Props, State, BusinessOption } from './sidebar-user.component.types';*/

export class SidebarUserComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    try {
      localStorage.setItem('currentBusiness', String(this.props.business.id));
    } catch (err) {}
  };

  onBusinessChange = (value /*: number*/, isModal /*::?: boolean*/) => {
    const business = this.props.businesses.find(business => business.id === value);
    const businessChangeCallback = () => (isModal ? this.setState({ isSwitcherOpened: false }) : null);

    if (business) {
      try {
        localStorage.setItem('currentBusiness', String(value));
      } catch (err) {}

      this.props.onBusinessChange(business, this.props.businessChangeLocked, businessChangeCallback);
    }
  };

  displayBusinessName = (business /*: Business*/) =>
    business.status === StatusEnum.Deleted
      ? businessName(business) + ' - ' + this.props.intl.formatMessage({ id: 'SIDEBAR.INACTIVE' })
      : businessName(business);

  businessLabelRenderer = (option /*: BusinessOption*/, showSeparatorStyle /*: ?boolean*/) => {
    const { isReplacedByAnotherAccount } = this.props.businesses.find(business => business.id === option.value) || {};
    return (
      <div className={option.showSeparator && showSeparatorStyle ? styles.separator : ''}>
        {isReplacedByAnotherAccount && <Hourglass className={styles.hourglassIcon} />}
        {option.label}
      </div>
    );
  };

  render() {
    const sidebarOptions = generateSidebarOptions(
      this.props.businesses,
      this.displayBusinessName,
      this.props.intl.formatMessage,
    );
    return (
      <>
        <div className={styles.header}>
          <div className={styles.user}>
            <div className={styles.name}>
              <p className={styles.title}>{this.props.intl.formatMessage({ id: 'SIDEBAR.USER' })}</p>
              <p>
                {this.props.firstName} {this.props.lastName}
              </p>
            </div>
            {this.props.isApproved && (
              <ul className={styles.profile}>
                <SidebarItemContainer
                  name={this.props.intl.formatMessage({ id: 'SIDEBAR.PROFILE' })}
                  icon="profile"
                  to="/profile"
                  isCollapsed
                />
              </ul>
            )}
          </div>

          {this.props.isApproved && (
            <>
              <div className={styles.desktopTitle}>
                {this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_COMPANY' })}
              </div>
              <div className={styles.icons}>
                <SidebarGroupComponent>
                  <SidebarItemContainer
                    name={this.props.intl.formatMessage({ id: 'SIDEBAR.SWITCH_COMPANY' })}
                    onClick={() => this.setState({ isSwitcherOpened: true })}
                    icon="switch-company"
                  />
                </SidebarGroupComponent>
              </div>
              <div className={styles.buttons}>
                <SelectComponent
                  name="company"
                  value={this.props.business.id}
                  options={sidebarOptions}
                  optionRenderer={(option, i) =>
                    this.businessLabelRenderer({ ...option, showSeparator: sidebarOptions[i].showSeparator }, true)
                  }
                  valueRenderer={option => this.businessLabelRenderer(option)}
                  onChange={({ value }) => this.onBusinessChange(value)}
                  searchable
                  noResultsText={this.props.intl.formatMessage({ id: 'CORE.NO_RESULTS' })}
                />
              </div>
            </>
          )}
        </div>

        {this.state.isSwitcherOpened && (
          <Modal
            title={'SIDEBAR.SWITCH_COMPANY'}
            subtitle={'SIDEBAR.SWITCH_COMPANY_DESC'}
            isOpen
            confirmOptions={[
              {
                label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
                onClick: () => this.setState({ isSwitcherOpened: false }),
                theme: 'primary-outline',
                arrowBack: true,
              },
            ]}
          >
            <CardBodyComponent className={styles.businessModalContent}>
              {sidebarOptions.map((option, i) => (
                <Fragment key={`sidebar-option-${i}`}>
                  {!option.id ? (
                    <Typography type="bodyText" color="grey" className={styles.businessLabel} id={option.name} />
                  ) : (
                    <SidebarUserBusinessComponent
                      business={option}
                      selected={this.props.business.id}
                      onSelect={() => option.id && this.onBusinessChange(Number(option.id), true)}
                    />
                  )}
                </Fragment>
              ))}
            </CardBodyComponent>
          </Modal>
        )}
      </>
    );
  }
}
