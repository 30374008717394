//@flow
import React from 'react';
import classNames from 'classnames';

/*:: import type { StatelessFunctionalComponent } from 'react';*/
/*:: import type { Props } from './wrapper.component.types';*/

export const Wrapper /*: StatelessFunctionalComponent<Props>*/ = (props /*: Props*/) => {
  const { className, setWrapperRef, name, children, controlMeta, styles } = props;
  const { isFocused, isInvalid, isDisabled, withIcon } = controlMeta;

  const wrapperClassNames = classNames(className, {
    [styles.isFocused]: isFocused,
    [styles.isInvalid]: isInvalid,
    [styles.isDisabled]: isDisabled,
    [styles.withIcon]: withIcon,
  });

  return (
    <div className={wrapperClassNames} ref={setWrapperRef} data-name={name}>
      {children}
    </div>
  );
};
