// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonComponent } from '../shared';

/*:: import type { ImpersonationSessionBannerProps } from './impersonation-session-banner.types';*/
import styles from './impersonation-session-banner.module.scss';

// $FlowFixMe
import { ReactComponent as AdminWithCogwheels } from 'assets/images/admin-with-cogwheels.svg';

export const ImpersonationSessionBanner = (props /*: ImpersonationSessionBannerProps*/) => {
  return (
    <div className={styles.banner}>
      <div className={styles.adminIconWrapper}>
        <AdminWithCogwheels className={styles.adminIcon} />
      </div>
      <div className={styles.labelWrapper}>
        <div className={styles.adminLabel}>Admin</div>
        <div className={styles.displayUserName}>
          <FormattedMessage id="IMPERSONATION.IMPERSONATING_USER" />:<div>{props.userDisplayName}</div>
        </div>
      </div>
      <ButtonComponent
        theme="secondary-outline"
        size="regular"
        className={styles.stopButton}
        onClick={props.onStopImpersonation}
      >
        <FormattedMessage id="IMPERSONATION.STOP" />
      </ButtonComponent>
    </div>
  );
};
