// @flow

import React from 'react';

/*:: import type { Props } from './field-array-renderer.types';*/

export const FieldArrayRenderer = (props /*: Props*/) => {
  const { fields, fieldComponent: FieldComponent, ...rest } = props;

  return fields.map((field, index) => (
    <FieldComponent name={field} key={field} {...rest} index={index} isLast={fields.length - 1 === index} />
  ));
};
