// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import {
  ButtonComponent,
  ConnectedCheckboxComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ConnectedTextareaComponent,
  ConnectedToggleComponent,
  SeparatorComponent,
} from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { Modal } from 'app/shared/modal-new/modal.container';
import { required } from 'app/utils';
import { ResultActionModalComponent } from 'app/shared/result-action-modal/result-action-modal.component';
import { CouponsTypeFormModalContainer } from './modal/coupons-type-form-modal.container';
import { ROUTES } from 'app/constants';
/*:: import type { CouponsTypeFormValues, Props, State } from './coupons-type-form.component.types';*/
/*:: import type { FormApi } from 'final-form';*/
import get from 'lodash/get';

export class CouponsTypeFormComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      customDescriptiveServices: props.couponType
        ? props.couponType.descriptiveServices
            .filter(service => !props.descriptiveServices.some(predefined => predefined.id === service))
            .map(service => ({ id: service, name: service }))
        : [],
      showSubmitStatusModal: false,
      showConfirmationModal: false,
    };
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  toggleConfirmModal = () => {
    this.setState(prevState => ({
      showConfirmationModal: !prevState.showConfirmationModal,
    }));
  };

  toggleSubmitStatusModal = () => {
    this.setState(prevState => ({
      showSubmitStatusModal: !prevState.showSubmitStatusModal,
    }));
  };

  handleSubmitFail = () => {
    window.scrollTo(0, 0);
  };

  handleOnSubmit = (values /*: CouponsTypeFormValues*/) /*: Promise<?Object>*/ =>
    this.props.onSubmit(values, this.props.onSubmitSuccess, this.handleSubmitFail);

  renderModal = (form /*: FormApi*/, values /*: any*/, submitting /*: ?boolean*/, submitSucceeded /*: ?boolean*/) => {
    if (this.state.showSubmitStatusModal) {
      return (
        <Modal
          component={ResultActionModalComponent}
          componentProps={{
            isLoading: submitting,
            isSuccess: submitSucceeded,
            onClose: () => {
              this.toggleSubmitStatusModal();
              submitSucceeded && form.reset();
            },
            messages: {
              success: {
                title: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.MODAL.SUCCESS_TITLE' }),
                body: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.MODAL.SUCCESS_BODY' }),
              },
              failure: {
                title: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.MODAL.FAILURE_TITLE' }),
                body: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.MODAL.FAILURE_BODY' }),
              },
            },
          }}
          isOpen
        />
      );
    }

    return (
      <Modal
        component={CouponsTypeFormModalContainer}
        confirmOptions={[
          {
            label: this.props.intl.formatMessage({ id: 'CORE.BACK' }),
            onClick: this.toggleConfirmModal,
            theme: 'primary-outline',
            arrowBack: true,
          },
          {
            label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
            onClick: () => {
              this.toggleConfirmModal();
              this.toggleSubmitStatusModal();
              form.submit();
            },
          },
        ]}
        componentProps={{
          values,
        }}
        isOpen
      />
    );
  };

  confirmSubmit = (form /*: FormApi*/, errors /*: ?{ [key: string]: string }*/) /*: void*/ => {
    if (!isEmpty(errors)) {
      Object.keys(errors).forEach(fieldName => {
        form.mutators.setFieldTouched(fieldName, true);
      });

      this.handleSubmitFail();
      return;
    }

    this.toggleConfirmModal();
  };

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const showArrow = !this.props.isInitialRoute && get(this.props.location, ['state', 'showArrow'], true);

    const priceUnitOptions = this.props.priceUnitOptions.map(priceUnitOption => ({
      id: priceUnitOption,
      name: this.props.intl.formatMessage({ id: `COUPONS.TYPES.UNITS.${priceUnitOption}` }),
    }));

    const descriptiveServices = uniqBy(
      [
        ...this.props.descriptiveServices.map(service => ({
          id: service.id,
          name: service.custom
            ? service.id
            : this.props.intl.formatMessage({ id: `COUPONS.DESCRIPTIVE_SERVICES.${service.id}` }),
        })),
        ...this.state.customDescriptiveServices,
      ],
      'id',
    );

    return (
      <CardComponent>
        <Form initialValues={this.props.couponType || {}} mutators={{ setFieldTouched }} onSubmit={this.handleOnSubmit}>
          {({ handleSubmit, submitting, values, submitSucceeded, form, errors }) => (
            <form onSubmit={handleSubmit}>
              <CardHeaderComponent title="COUPONS.TYPES.TYPE" />
              <SeparatorComponent />
              <CardBodyComponent>
                <div className="row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={ConnectedTextareaComponent}
                      label="COUPONS.TYPES.LABEL.TITLE"
                      required
                      validate={required()}
                      maxLength={255}
                      minRows={1}
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      name="description"
                      component={ConnectedTextareaComponent}
                      label="COUPONS.TYPES.LABEL.DESCRIPTION"
                      maxLength={1000}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="defaultValue"
                      icon="euro-symbol"
                      component={ConnectedInputComponent}
                      label="COUPONS.TYPES.LABEL.VALUE"
                      mask={{
                        mask: '999999999999',
                        maskChar: '',
                        formatChars: { '9': '[0-9,.]' },
                      }}
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="priceUnit"
                      component={ConnectedSelectComponent}
                      options={priceUnitOptions}
                      label="COUPONS.TYPES.LABEL.PRICE_UNIT"
                      withClearOption
                    />
                  </div>
                  <div className={classNames('col-lg-12', 'mb-4')}>
                    <Field
                      name="transactionMaxPriceEnabled"
                      component={ConnectedToggleComponent}
                      label="COUPONS.TYPES.LABEL.SET_MAX_TRANSACTION"
                      type="checkbox"
                    />
                  </div>
                  <div className={columnClassNames}>
                    {(values || {}).transactionMaxPriceEnabled && (
                      <Field
                        name="defaultTransactionMaxPrice"
                        label="COUPONS.TYPES.LABEL.MAX_TRANSACTION"
                        component={ConnectedInputComponent}
                        icon="euro-symbol"
                        required
                        mask={{
                          mask: '999999999999',
                          maskChar: '',
                          formatChars: {
                            '9': '[0-9,.]',
                          },
                        }}
                        validate={required()}
                      />
                    )}
                  </div>
                  <div className="col-lg-12">
                    <Field
                      name="additionalInfo"
                      component={ConnectedTextareaComponent}
                      label="COUPONS.TYPES.LABEL.ADDITIONAL"
                      maxLength={250}
                    />
                  </div>
                  {!!this.props.couponType && (
                    <div className={columnClassNames}>
                      <Field
                        name="active"
                        component={ConnectedSelectComponent}
                        label="COUPONS.TYPES.LABEL.STATUS"
                        required
                        options={[
                          {
                            id: true,
                            name: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.ACTIVE' }),
                          },
                          {
                            id: false,
                            name: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.PASSIVE' }),
                          },
                        ]}
                        validate={required()}
                      />
                    </div>
                  )}
                  <div className="col-lg-12">
                    <Field
                      name="services"
                      component={ConnectedSelectComponent}
                      label="COUPONS.TYPES.LABEL.SERVICE_TYPE"
                      options={this.props.services}
                      required
                      multi
                      validate={required()}
                    />
                  </div>
                  {this.props.current.displayDescriptiveServices && (
                    <div className="col-lg-12">
                      <Field
                        name="descriptiveServices"
                        component={ConnectedSelectComponent}
                        label="COUPONS.TYPES.LABEL.DESCRIPTIVE_SERVICES"
                        options={descriptiveServices}
                        onNewOptionClick={option => {
                          if (!this.state.customDescriptiveServices.some(service => service.id === option.value)) {
                            this.setState((prev /*: State*/) => ({
                              customDescriptiveServices: [
                                ...prev.customDescriptiveServices,
                                {
                                  id: option.value,
                                  name: option.label,
                                },
                              ],
                            }));
                          }
                        }}
                        promptTextCreator={label =>
                          this.props.intl.formatMessage({ id: 'COUPONS.TYPES.CREATE_NEW_DESCRIPTIVE' }, { label })
                        }
                        creatable
                        multi
                      />
                    </div>
                  )}
                  <div className="col-lg-12">
                    <Field
                      name="vatRatesEnabled"
                      label="COUPONS.TYPES.LABEL.VAT_RATES_ENABLED"
                      component={ConnectedCheckboxComponent}
                      type="checkbox"
                    />
                  </div>
                </div>
              </CardBodyComponent>
              <CardFooterComponent>
                {showArrow && (
                  <ButtonComponent theme="primary-outline" to={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT}>
                    <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.CANCEL" />
                  </ButtonComponent>
                )}

                <ButtonComponent
                  onClick={() => this.confirmSubmit(form, errors)}
                  loading={submitting}
                  className="ml-auto"
                >
                  <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" /> <i className="fi fi-right-arrow-forward" />
                </ButtonComponent>
              </CardFooterComponent>
              {(this.state.showSubmitStatusModal || this.state.showConfirmationModal) &&
                this.renderModal(form, values, submitting, submitSucceeded)}
            </form>
          )}
        </Form>
      </CardComponent>
    );
  }
}
