import { handleActions } from 'redux-actions';

import {
  CANCEL_ORDER_FAILURE,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_SUCCESS,
} from './orders.action-types';

const defaultState = {
  entities: {},
};

export const ordersReducer = handleActions(
  {
    [CREATE_ORDER_REQUEST]: state => ({
      ...state,
      newOrderId: undefined,
    }),
    [CREATE_ORDER_SUCCESS]: (
      state,
      {
        payload: {
          data: { id },
        },
      },
    ) => ({
      ...state,
      newOrderId: id,
    }),
    [CREATE_ORDER_FAILURE]: state => ({
      ...state,
      newOrderId: undefined,
    }),
    [CANCEL_ORDER_REQUEST]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: true,
        },
      },
    }),
    [CANCEL_ORDER_SUCCESS]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: false,
        },
      },
      list: state.list ? state.list.filter(e => e.id !== order) : undefined,
    }),
    [CANCEL_ORDER_FAILURE]: (state, { meta: { order } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [order]: {
          ...state.entities[order],
          canceling: false,
        },
      },
    }),
    [FETCH_ORDER_HISTORY_REQUEST]: state => {
      return {
        ...state,
        orderHistory: undefined,
      };
    },
    [FETCH_ORDER_HISTORY_SUCCESS]: (state, { payload: { data } }) => {
      return {
        ...state,
        orderHistory: data.reverse(),
      };
    },
  },
  defaultState,
);
