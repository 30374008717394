// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './summary-field.component.types';*/
import styles from './summary-field.module.scss';

export class SummaryFieldComponent extends Component /*:: <Props>*/ {
  render() {
    return <div className={classNames(styles.summaryField, this.props.className)}>{this.props.children}</div>;
  }
}
