import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { fetchOrderHistory } from 'app/redux/orders/orders.actions';

import { OrdersHistoryComponent } from './orders-history.component';

const mapStateToProps = ({
  customers: { entities: customers },
  dictionaries,
  users: { business },
  orders: { orderHistory },
}) => ({
  loading: !customers[business.id] || !dictionaries['invoicing-options'] || !orderHistory,
  orderHistory,
  business,
});

const mapDispatchToProps = dispatch => ({
  fetchOrderHistory: () => dispatch(fetchOrderHistory()),
});

export const OrdersHistoryContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(OrdersHistoryComponent);
