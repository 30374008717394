// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import difference from 'lodash/difference';

import { ButtonComponent, CheckboxComponent } from 'app/shared';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { businessName, currencyFormatter } from 'app/utils';
import { SortableTableHeader } from 'app/shared/list/sortable-header/sortable-table-header.component';

/*:: import type { Props } from './vat-archive-list-table.component.types';*/
import styles from './vat-archive-list-table.module.scss';

export class VatArchiveListTable extends Component /*:: <Props>*/ {
  areAllSelected = () => {
    const activeItems = this.props.list.filter(item => item.active);
    return (
      !!activeItems.length &&
      !difference(
        activeItems.map(item => item.id),
        this.props.selectedItems,
      ).length
    );
  };

  render() {
    const { onSelect, disablePrint, selectedItems, onSelectAll, orderBy, currentOrder } = this.props;

    return (
      <div className="table-responsive">
        <TableComponent className="table-striped">
          <TableHeadComponent>
            <TableRowComponent>
              {this.props.selectable && (
                <TableCellComponent variant="header" className={styles.checkboxCol}>
                  <CheckboxComponent checked={this.areAllSelected()} onChange={onSelectAll} />
                </TableCellComponent>
              )}
              <SortableTableHeader
                column="id"
                translationId="VAT_ARCHIVE.LIST.ACCOUNT"
                orderBy={orderBy}
                currentOrder={currentOrder}
              />
              <SortableTableHeader
                align="right"
                column="sum"
                translationId="VAT_ARCHIVE.LIST.SUM"
                orderBy={orderBy}
                currentOrder={currentOrder}
              />
              <SortableTableHeader
                align="right"
                column="totalNumber"
                translationId="VAT_ARCHIVE.LIST.BILLS"
                orderBy={orderBy}
                currentOrder={currentOrder}
              />
              <TableCellComponent variant="header" className={styles.buttonCol}>
                <ButtonComponent
                  theme="primary"
                  disabled={disablePrint}
                  className={styles.button}
                  onClick={this.props.showModal}
                >
                  <i className="fi fi-printer" />
                  <FormattedMessage id="VAT_ARCHIVE.LIST.PRINT_SELECTED" />
                </ButtonComponent>
              </TableCellComponent>
            </TableRowComponent>
          </TableHeadComponent>
          <TableBodyComponent>
            {this.props.list.map(({ active, id, name, nameExtra, sum, totalNumber }, index) => (
              <TableRowComponent className={styles.row} key={index}>
                {this.props.selectable && (
                  <TableCellComponent className={styles.checkboxCol}>
                    {active && (
                      <CheckboxComponent
                        checked={selectedItems.includes(id)}
                        onChange={({ target: { checked } }) => onSelect(id, checked)}
                      />
                    )}
                  </TableCellComponent>
                )}
                <TableCellComponent>
                  {active ? (
                    <Link to={`/vat-archive/${id}/${this.props.year}/${this.props.month}`}>
                      {businessName(this.props.list[index])}
                    </Link>
                  ) : (
                    businessName(this.props.list[index])
                  )}
                </TableCellComponent>
                <TableCellComponent align="right"> {currencyFormatter.format(sum)}</TableCellComponent>
                <TableCellComponent align="right">
                  <FormattedMessage
                    id="VAT_ARCHIVE.DETAILS.SUMMARY.PAYMENTS"
                    values={{
                      number: totalNumber,
                    }}
                  />
                </TableCellComponent>
                <TableCellComponent />
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TableComponent>
      </div>
    );
  }
}
