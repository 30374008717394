// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { debounce } from 'lodash';

import { Modal } from 'app/shared/modal-new/modal.container';
import { SidebarContainer } from 'app/shared/sidebar/sidebar.container';
import { TransactionDetailsModalContainer } from '../details/transaction-details-modal.container';
import { TransactionsMunicipalFiltersContainer } from './filters/transactions-municipal-filters.container';
import { TransactionsMunicipalPlaceholder } from './placeholder/transactions-municipal.placeholder';
import { TransactionsMunicipalTableComponent } from './table/transactions-municipal-table.component';
import { TransactionsMunicipalTileComponent } from './tile/transactions-municipal-tile.component';

import { PageHeaderComponent } from 'app/core';
import { LoadMoreComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ListComponent } from 'app/shared/list/list.component';

/*:: import type { Props } from './transactions-municipal.component.types';*/

export class TransactionsMunicipalComponent extends Component /*:: <Props>*/ {
  render() {
    const noResults = this.props.loading || !this.props.transactions.length;

    return (
      <>
        <PageHeaderComponent title="TRANSACTIONS.MUNICIPAL_TRANSACTIONS" />

        <TransactionsMunicipalFiltersContainer onChange={debounce(this.props.onFiltersChange, 500)} />
        <SeparatorComponent />
        {this.props.loading && <TransactionsMunicipalPlaceholder />}

        {this.props.loading || (
          <CardComponent>
            <CardHeaderComponent
              title={{
                id: 'TRANSACTIONS.COUNTER',
                values: { count: this.props.transactions.length, total: this.props.meta.total },
              }}
            />
            <SeparatorComponent />
            <CardBodyComponent>
              <ListComponent
                list={this.props.transactions}
                table={TransactionsMunicipalTableComponent}
                tile={TransactionsMunicipalTileComponent}
                props={{
                  permissions: this.props.permissions,
                }}
              />

              {this.props.transactions.length < this.props.meta.total && (
                <>
                  <SeparatorComponent />
                  <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
                </>
              )}
            </CardBodyComponent>
          </CardComponent>
        )}

        <SidebarContainer
          name="transactions-municipal"
          tabs={[
            {
              label: <FormattedMessage id="COUPONS.SIDEBAR.EXCEL.TITLE" />,
              icon: 'fi-download-excel',
              onClick: () => this.props.onDownload(),
              disabled: noResults,
            },
          ]}
        />

        <Switch>
          <Route
            path={`${this.props.match.url}/:account/:transaction`}
            render={props => (
              <Modal
                component={TransactionDetailsModalContainer}
                isOpen={true}
                componentProps={{ ...props, pathname: this.props.match.url }}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}
