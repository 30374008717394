import { RSAA } from 'redux-api-middleware';

import * as types from './holidays.action-types';

export const fetchHolidays = () => ({
  [RSAA]: {
    types: [types.FETCH_HOLIDAYS_REQUEST, types.FETCH_HOLIDAYS_SUCCESS, types.FETCH_HOLIDAYS_FAILURE],
    endpoint: '/dictionaries/holidays',
    method: 'GET',
  },
});
