import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

import { getDescriptiveService } from 'app/utils';
import { CouponsDetailsTransactionsComponent } from './coupons-details-transactions.component';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

const mapStateToProps = ({ dictionaries }, { intl, transactions }) => {
  const descriptiveServices = get(dictionaries, ['municipal/services', 'descriptiveServices'], []);
  const getDescriptiveServiceValue = service => getDescriptiveService({ intl, descriptiveServices }, service);

  return {
    isDictLoaded: !!dictionaries['municipal/services'],
    transactions: transactions.map(transaction => ({
      ...transaction,
      descriptiveService: getDescriptiveServiceValue(transaction.descriptiveService),
    })),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
});

export const CouponsDetailsTransactionsContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDetailsTransactionsComponent);
