// @flow

import React, { useEffect } from 'react';

import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent } from 'app/shared';

import { CouponsMassChargeFormContainer } from './form/coupons-mass-charge-form.container';
import { CouponsListFiltersContainer } from './filters/coupons-list-filters.container';

/*:: import type { Props } from './coupons-mass-charge.component.types';*/

export const CouponsMassChargeComponent = (props /*: Props*/) => {
  useEffect(
    () => {
      props.fetchDictionary();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <PageHeaderComponent title="NAV.PAYMENT.TARGETED_SERVICE_COUPONS" />
      <CouponsListFiltersContainer fetch={props.fetchVenueCoupons} />
      <SeparatorComponent />
      <CouponsMassChargeFormContainer />
    </>
  );
};
