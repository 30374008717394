import moment from 'moment';
import { RSAA } from 'redux-api-middleware';
import { createAction } from 'redux-actions';

import { DATE_VALUE_FORMAT } from 'app/constants';
import * as types from './pricelists.action-types';
import { ReviewAction } from 'app/types/contract';

export const filterVenueContracts = createAction(types.FILTER_VENUE_CONTRACTS);

export const fetchContractedVenues = () => ({
  [RSAA]: {
    types: [
      types.FETCH_CONTRACTED_VENUES_REQUEST,
      types.FETCH_CONTRACTED_VENUES_SUCCESS,
      types.FETCH_CONTRACTED_VENUES_FAILURE,
    ],
    endpoint: '/municipality-contracted-venues',
    method: 'GET',
  },
});

export const fetchVenueContracts = (venueId: number) => ({
  [RSAA]: {
    types: [
      types.FETCH_VENUE_CONTRACTS_REQUEST,
      types.FETCH_VENUE_CONTRACTS_SUCCESS,
      // TODO: check if this action is correct. Looks like it should be FETCH_VENUE_CONTRACTS_FAILURE
      types.FETCH_VENUE_CONTRACTS_SUCCESS,
    ],
    endpoint: `/municipality-contracted-venues/${venueId}`,
    method: 'GET',
  },
});

// The difference between this and the fetchVenueContracts action is that REFRESH_VENUE_CONTRACTS_REQUEST will not
// change the state of the redux store to set the loading state to true or reset the contracts list, meaning that the mounted
// component will not be unmounted and we will just be updating the contracts list.
export const refreshVenueContracts = (venueId: number) => ({
  [RSAA]: {
    types: [
      types.REFRESH_VENUE_CONTRACTS_REQUEST,
      types.FETCH_VENUE_CONTRACTS_SUCCESS,
      types.FETCH_VENUE_CONTRACTS_FAILURE,
    ],
    endpoint: `/municipality-contracted-venues/${venueId}`,
    method: 'GET',
  },
});

export type ReviewContractBody =
  | {
      contractStartDate: Date;
    }
  | {
      message: string;
    };

export const reviewContract = (contractId: number, action: ReviewAction, reviewContractBody?: ReviewContractBody) => {
  let body = undefined;
  if (reviewContractBody) {
    body =
      'contractStartDate' in reviewContractBody
        ? { startDate: moment(reviewContractBody.contractStartDate).format(DATE_VALUE_FORMAT) }
        : { message: reviewContractBody.message };
  }
  return {
    [RSAA]: {
      types: [
        { type: types.REVIEW_CONTRACT_REQUEST, meta: { action } },
        types.REVIEW_CONTRACT_SUCCESS,
        {
          type: types.REVIEW_CONTRACT_FAILURE,
          meta: {
            noValidation: true,
          },
        },
      ],
      endpoint: `/contracts/${contractId}/${action}`,
      method: 'POST',
      body,
    },
  };
};
