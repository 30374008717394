//@flow
import isArray from 'lodash/isArray';

export const fieldsArrayNamesExtractor = (errors /*: any*/ = {}) =>
  Object.keys(errors).reduce(
    (prev, field) => [
      ...prev,
      ...(!isArray(errors[field])
        ? [field]
        : Object.keys(errors[field]).reduce(
            (prev, value, index) => [
              ...prev,
              ...Object.keys(errors[field][value]).map(key => `${field}[${index}].${key}`),
            ],
            [],
          )),
    ],
    [],
  );
