// @flow

import React, { Component } from 'react';

import { PlaceholderComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new';

export class MunicipalityContractPlaceholder extends Component /*:: <any>*/ {
  render() {
    return (
      <CardBodyComponent>
        <Typography type="subtitle" color="greyDark" id="PRICELISTS.MANAGE.LIST.STATUS" />
        <PlaceholderComponent width={160} height={14} color="light" />
        <SeparatorComponent height={18} />
        <Typography type="subtitle" color="greyDark" id="PRICELIST.VALIDITY_PERIOD" />
        <Typography type="bodyText" color="grey">
          <PlaceholderComponent width={160} height={14} color="light" />
        </Typography>
        <SeparatorComponent />
        <Typography color="greyDark" id="PRICELIST.PRICES_HEADER" type="subtitle" />
        <SeparatorComponent />
        <PlaceholderComponent height={250} color="light" />
      </CardBodyComponent>
    );
  }
}
