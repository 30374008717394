// @flow

import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withPermissions } from 'app/common';

import { SidebarCustomerServiceToolsComponent } from './sidebar-customer-service-tools.component';

export const SidebarCustomerServiceToolsContainer = compose(
  injectIntl,
  withPermissions,
)(SidebarCustomerServiceToolsComponent);
