import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { GetAvailableUsecasesResponse, PostpayApiClient } from 'v2/api/postpay-api-client';
import {
  actions,
  AvailableUsecasesFetched,
  AvailableUsecasesState,
  AvailableUsecasesStatus,
} from './available-usecases-slice';
import { isEmpty } from 'lodash';
import { CHANGE_BUSINESS, FETCH_CURRENT_USER_SUCCESS } from 'app/redux/users/users.action-types';
import { StatusCodes } from 'http-status-codes';
import { OrganizationalUnitUseCases } from 'v2/types/postpay';
import { push } from 'connected-react-router';
import { REDIRECTS } from 'app/constants';
import { Business } from 'v2/types/business';
import { V2RootState } from 'v2/v2.reducer';
import { getDefaultPostpayUrl } from 'v2/utils/redirects';

function* fetchAvailableUseCases(apiClient: PostpayApiClient, organizationalUnitId: string) {
  const response: GetAvailableUsecasesResponse = yield call(apiClient.getAvailableUsecases, organizationalUnitId);
  return response.status === StatusCodes.OK ? response.data : {};
}

export const getCurrentBusinessFromState = (state: { users: { business: Business } }): Business => state.users.business;
export const getIsApprovedFromState = (state: { users: { isApproved: boolean } }): boolean => state.users.isApproved;

const getAvailableUseCasesFromState = (state: V2RootState): AvailableUsecasesState =>
  state['organizational-unit-available-usecases'];

export function* fetchUseCasesAndRedirectAfterUserFetch(apiClient: PostpayApiClient) {
  yield call(getAvailableUsecasesForUserOrganization, apiClient);

  const locationsToRedirect = ['/', '/login'];
  if (locationsToRedirect.includes(window.location.pathname)) {
    yield call(redirectToPostPaymentOrDefault, apiClient);
  }
}

export function* fetchUseCasesAndRedirectAfterBusinessChange(apiClient: PostpayApiClient) {
  yield call(getAvailableUsecasesForUserOrganization, apiClient);
  yield call(redirectToPostPaymentOrDefault, apiClient);
}

function* getAvailableUsecasesForUserOrganization(apiClient: PostpayApiClient) {
  const currentBusiness: Business = yield select(getCurrentBusinessFromState);

  const availableUseCases: AvailableUsecasesState = yield select(getAvailableUseCasesFromState);
  let availableUseCasesForCurrentBusiness = availableUseCases ? availableUseCases[currentBusiness.id] : undefined;

  if (availableUseCasesForCurrentBusiness?.status !== AvailableUsecasesStatus.Found) {
    availableUseCasesForCurrentBusiness = yield call(availableUseCasesFetchRequest, apiClient, currentBusiness.id);
  }
  return availableUseCasesForCurrentBusiness;
}

function* redirectToPostPaymentOrDefault(apiClient: PostpayApiClient) {
  const isApproved: boolean = yield select(getIsApprovedFromState);
  // If the terms are not approved, we don't redirect (another redirect will handle that case)
  if (!isApproved) {
    return;
  }

  const currentBusiness: Business = yield select(getCurrentBusinessFromState);

  const currentBusinessRole = currentBusiness.defaultRole;
  const redirects: { [key: string]: string } = REDIRECTS;

  const availableUseCases: AvailableUsecasesState = yield select(getAvailableUseCasesFromState);
  const availableUseCasesForCurrentBusiness = availableUseCases ? availableUseCases[currentBusiness.id] : undefined;

  if (availableUseCasesForCurrentBusiness?.status === AvailableUsecasesStatus.Found) {
    const postpayRedirectUrl = getDefaultPostpayUrl(availableUseCasesForCurrentBusiness.availableUsecases);
    if (postpayRedirectUrl) {
      yield put(push(postpayRedirectUrl));
      return;
    }
  }

  // prepayment account redirection logic
  if (redirects[currentBusinessRole]) {
    yield put(push(redirects[currentBusinessRole]));
  } else {
    yield put(push('/profile'));
  }
}

function* availableUseCasesFetchRequest(apiClient: PostpayApiClient, organizationalUnitId: string) {
  if (organizationalUnitId === undefined) {
    return;
  }

  yield put(actions.organizationalUnitAvailableUsecasesRequested({ organizationalUnitId }));

  const availableUsecases: OrganizationalUnitUseCases = yield fetchAvailableUseCases(apiClient, organizationalUnitId);
  const availableUsecasesFetched: AvailableUsecasesFetched = {
    organizationalUnitId,
    ...(isEmpty(availableUsecases)
      ? {
          status: AvailableUsecasesStatus.NotFound,
        }
      : {
          status: AvailableUsecasesStatus.Found,
          availableUsecases,
        }),
  };
  yield put(actions.organizationalUnitAvailableUsecasesFetched(availableUsecasesFetched));
  return availableUsecasesFetched;
}

export function* availableUseCasesForOrganizationalUnitSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  yield takeEvery([FETCH_CURRENT_USER_SUCCESS], fetchUseCasesAndRedirectAfterUserFetch, apiClient);
  yield takeEvery([CHANGE_BUSINESS], fetchUseCasesAndRedirectAfterBusinessChange, apiClient);
}
