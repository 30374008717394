import { getJSON, RSAA } from 'redux-api-middleware';

import * as types from './venues.action-types';

export const fetchVenues = () => ({
  [RSAA]: {
    types: [types.FETCH_VENUES_REQUEST, types.FETCH_VENUES_SUCCESS, types.FETCH_VENUES_FAILURE],
    endpoint: '/venues',
    method: 'GET',
  },
});

export const fetchVenue = id => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_VENUE_REQUEST,
        meta: {
          venueId: id,
        },
      },
      types.FETCH_VENUE_SUCCESS,
      types.FETCH_VENUE_FAILURE,
    ],
    endpoint: `/venues/${id}`,
    method: 'GET',
  },
});

export const addVenue = body => ({
  [RSAA]: {
    types: [types.ADD_VENUE_REQUEST, types.ADD_VENUE_SUCCESS, types.ADD_VENUE_FAILURE],
    endpoint: '/venues',
    method: 'POST',
    body,
  },
});

export const editVenue = (type, venue, body) => ({
  [RSAA]: {
    types: [
      types.EDIT_VENUE_REQUEST,
      {
        type: types.EDIT_VENUE_SUCCESS,
        meta: {
          venue: body,
          id: venue,
        },
      },
      types.EDIT_VENUE_FAILURE,
    ],
    endpoint: `/venues/${type}/${venue}`,
    method: 'PATCH',
    body,
  },
});

export const fetchMunicipalities = (id, persistNotification) => ({
  [RSAA]: {
    types: [
      { type: types.FETCH_MUNICIPALITIES_REQUEST, meta: { persistNotification } },
      types.FETCH_MUNICIPALITIES_SUCCESS,
      types.FETCH_MUNICIPALITIES_FAILURE,
    ],
    endpoint: `/venues/${id}/contracts`,
    method: 'GET',
  },
});

export const updateMunicipalitiesContracts = (body, venueID) => ({
  [RSAA]: {
    types: [
      types.UPDATE_MUNICIPALITIES_CONTRACTS_REQUEST,
      {
        type: types.UPDATE_MUNICIPALITIES_CONTRACTS_SUCCESS,
        payload: (action, state, res) => getJSON(res),
        meta: { contractOldStatus: body.status },
      },
      {
        type: types.UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE,
        meta: { contract: body },
      },
    ],
    endpoint: `/venues/${venueID}/contract/${body.id}`,
    method: 'PUT',
    body,
  },
});
