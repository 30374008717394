// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new/index';
import { ButtonComponent } from 'app/shared';

import { PlaceholderComponent } from 'app/shared/index';
import { headers } from '../../table/pricelists-table.component';

export const PricelistsTablePlaceholder = (props /*: { list: any[] }*/) => {
  return (
    <TableComponent className="table-striped">
      <TableHeadComponent headers={headers} />
      <TableBodyComponent>
        {props.list.map((_, index) => (
          <TableRowComponent key={index}>
            <TableCellComponent>
              <PlaceholderComponent />
            </TableCellComponent>
            <TableCellComponent>
              <PlaceholderComponent />
            </TableCellComponent>
            <TableCellComponent>
              <PlaceholderComponent />
            </TableCellComponent>
            <TableCellComponent className="d-flex justify-content-end">
              <ButtonComponent theme="secondary-outline" disabled>
                <i className="fi fi-open" />
                <FormattedMessage id="PRICELISTS.MANAGE.LIST.OPEN" />
              </ButtonComponent>
            </TableCellComponent>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
    </TableComponent>
  );
};
