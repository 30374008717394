// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class UsersListTilePlaceholder extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={200} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="USERS.EMAIL" tagName="small" />
            <PlaceholderComponent width={250} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="USERS.ACTIVE_ROLES" tagName="small" />
            <PlaceholderComponent width={80} height={18} />
          </div>
        </div>

        <div className="buttons">
          <PlaceholderComponent width={120} height={32} color="purple" />
        </div>
      </TileComponent>
    );
  }
}
