// @flow

import React, { Component } from 'react';

import { VatArchiveDetailsTablePlaceholder } from './table/vat-archive-details-table.placeholder';
import { VatArchiveDetailsTilePlaceholder } from './tile/vat-archive-details-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

/*:: import type { Props } from './vat-archive-details.placeholder.types';*/

export class VatArchiveDetailsPlaceholder extends Component /*:: <Props>*/ {
  static defaultProps = {
    archive: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent
        list={this.props.archive}
        table={VatArchiveDetailsTablePlaceholder}
        tile={VatArchiveDetailsTilePlaceholder}
        props={{
          type: this.props.type,
        }}
      />
    );
  }
}
