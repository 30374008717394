import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import React from 'react';
import styles from './distributer-information.component.module.scss';

type DistributerInformationComponentProps = {
  distributerInformation: string;
};

export const DistributerInformationComponent: React.FC<DistributerInformationComponentProps> = ({
  distributerInformation,
}) => {
  if (distributerInformation === '') return null;

  return (
    <CardComponent>
      <CardHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.DISTRIBUTER_INFORMATION_TITLE" />
      <CardBodyComponent className={styles.cardBody}>{distributerInformation}</CardBodyComponent>
    </CardComponent>
  );
};
