// @flow

import React from 'react';
import { FieldArray } from 'redux-form';
import { SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { CouponsCreateFormCustomersContainer } from './customers/coupons-create-form-customers.container';

/*:: import type { CustomersCardComponentProps } from './customers-card.component.types';*/

export function CustomersCardComponent(props /*: CustomersCardComponentProps*/) {
  return (
    <CardComponent>
      <CardHeaderComponent title="COUPONS.CREATE.CUSTOMERS" />
      <SeparatorComponent />
      <CardBodyComponent>
        <FieldArray name="customerDecisions" component={CouponsCreateFormCustomersContainer} {...props} />
      </CardBodyComponent>
    </CardComponent>
  );
}
