import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';

import { CouponsDetailsChargeModalComponent } from './coupons-details-charge-modal.component';

const mapStateToProps = ({ form }) => ({
  payments: getFormValues('charge-service-coupon')({ form }).payments,
});

export const CouponsDetailsChargeModalContainer = compose(
  injectIntl,
  connect(mapStateToProps, null),
)(CouponsDetailsChargeModalComponent);
