// @flow

import React, { useMemo, useState } from 'react';
import { Field } from 'redux-form';
import { personalIdentificationCodeValidator } from '../../utils/form/validators';
import { ConnectedInputComponent } from '../form/connected';
import { required } from 'app/utils';

/*:: import type { Props } from './pic-input.field.types';*/

const PIC_LENGTH = 11; // PICs are always 11 chars long

function validationWithFocus(value) {
  return value.length >= PIC_LENGTH ? personalIdentificationCodeValidator(value) : undefined;
}

function validationWithoutFocus(value) {
  return personalIdentificationCodeValidator(value);
}

const picFieldValidator = (hasFocus /*: boolean*/) => value => {
  if (value && hasFocus) return validationWithFocus(value);
  if (value && !hasFocus) return validationWithoutFocus(value);
};

export const PicField = (props /*: Props*/) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleOnFocus = () => {
    setHasFocus(true);
  };
  const handleOnBlur = () => {
    setHasFocus(false);
  };
  const memoizedValidation = useMemo(() => picFieldValidator(hasFocus), [hasFocus]);

  const fieldProps = {
    validate: props.required ? [required(), memoizedValidation] : [memoizedValidation],
    component: ConnectedInputComponent,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
  };

  return <Field {...props} {...fieldProps} />;
};
