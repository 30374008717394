import { connect } from 'react-redux';
import { compose } from 'redux';

import { ModalComponent } from './modal.component';

import { modalClose, modalDestroy, modalRegister } from 'app/redux/modal/modal.actions';

const mapStateToProps = ({ modal }, { name, isOpen }) => {
  const isModalOpen = name ? modal.isOpen : isOpen;

  return {
    modal: name ? modal[name] : {},
    isModalOpen,
  };
};

const mapDispatchToProps = (dispatch, { name, isOpen }) => ({
  register: () => dispatch(modalRegister({ name, isOpen })),
  destroy: () => dispatch(modalDestroy({ name })),
  close: () => dispatch(modalClose({ name })),
});

export const Modal = compose(connect(mapStateToProps, mapDispatchToProps))(ModalComponent);
