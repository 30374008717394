// @flow

import React, { PureComponent } from 'react';

import { PlaceholderComponent, TableComponent } from 'app/shared';

/*:: import type { Props } from './coupons-list-table.placeholder.types';*/

export class CouponsListTablePlaceholder extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <tbody>
          {this.props.list.map((_, coupon) => (
            <tr key={coupon}>
              <td width="80">
                <PlaceholderComponent width={60} />
              </td>
              <td>
                <PlaceholderComponent width={coupon % 2 === 0 ? 100 : 140} />
              </td>
              <td className="text-right">
                <PlaceholderComponent width={80} />
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponent>
    );
  }
}
