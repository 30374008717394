// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent } from 'app/shared';

import { useOnBusinessChange } from 'app/hooks';
import { VenueMunicipalitiesPlaceholder } from './placeholder/venue-municipalities.placeholder';
import { PricelistsFiltersComponent } from 'app/pricelists/approve/filters/pricelists-filters.component';
import { VenueMunicipalityComponent } from './list/municipality/venue-municipality.component';
import { useServiceTypeGroups } from 'app/pricelists/approve/useServiceTypeGroups.hook';
import { Filters } from 'app/pricelists/approve/filters/pricelists-filters.component';
import { NO_SERVICE_TYPE_GROUP_ID } from 'app/pricelists/approve/constants';
/*:: import type { Props } from './venue-municipalities.component.types';*/

export const VenueMunicipalitiesComponent = (props /*: Props*/) => {
  const { venue, fetchMunicipalities, fetchVenue, loading, municipalities, updatingContract } = props;
  const [filters, setFilters] /*: [ Filters, Function ]*/ = useState < Filters > ({});

  useEffect(
    () => {
      fetchVenue();
      fetchMunicipalities();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setFilters(f => ({
      ...f,
      organizerId: municipalities?.length || updatingContract ? f.organizerId || municipalities[0].id : null,
    }));
  }, [municipalities, updatingContract, setFilters]);

  useEffect(() => {
    setFilters(f => ({ ...f, serviceTypeGroupId: undefined }));
  }, [filters.organizerId]);

  const filterContracts = useCallback((contracts, serviceTypeGroupId) => {
    const filteredArr = contracts
      ? contracts.filter(c =>
        serviceTypeGroupId === NO_SERVICE_TYPE_GROUP_ID
          ? !c.serviceTypeGroup
          : c.serviceTypeGroup?.id === serviceTypeGroupId,
      )
      : [];
    return filteredArr;
  }, []);

  const selectedMunicipality = useMemo(() => {
    if (!filters.organizerId || !municipalities?.length) {
      return null;
    }
    return municipalities.find(m => m.id === filters.organizerId);
  }, [filters.organizerId, municipalities]);

  const filteredContracts = useMemo(() => {
    if (!selectedMunicipality?.contracts) {
      return [];
    }
    const filteredArr = filterContracts(selectedMunicipality?.contracts, filters.serviceTypeGroupId);
    return filteredArr;
  }, [filters.serviceTypeGroupId, selectedMunicipality?.contracts, filterContracts]);

  const { serviceTypeGroups } = useServiceTypeGroups({
    context: 'provider',
    contracts: selectedMunicipality?.contracts,
    onFiltersChanged: useCallback(
      ({ serviceTypeGroupId }) =>
        setFilters(f => ({
          serviceTypeGroupId: filters.serviceTypeGroupId || serviceTypeGroupId,
          organizerId: f.organizerId,
        })),
      [filters.serviceTypeGroupId],
    ),
  });

  useOnBusinessChange({
    onChange: () => {
      props.redirectToVenuesPage();
    },
    skipOnMount: true,
  });

  return (
    <>
      <PageHeaderComponent title={{ id: 'VENUES.PRICES_TITLE', values: { name: venue.name } }} backLink="/venues" />
      {loading ? (
        <VenueMunicipalitiesPlaceholder />
      ) : (
        <>
          <PricelistsFiltersComponent
            onFiltersChanged={setFilters}
            filters={filters}
            organizers={municipalities}
            serviceTypeGroups={serviceTypeGroups}
            context="provider"
          />
          <SeparatorComponent />
          {selectedMunicipality && (
            <VenueMunicipalityComponent
              {...selectedMunicipality}
              filteredContracts={filteredContracts}
              key={selectedMunicipality.id}
            />
          )}
        </>
      )}
    </>
  );
};
