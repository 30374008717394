// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, GroupComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './coupons-type-form.placeholder.types';*/

export class CouponsTypeFormPlaceholder extends Component /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <CardHeaderComponent title="COUPONS.TYPES.TYPE" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className="col-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.TITLE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>

            <div className="col-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.DESCRIPTION' })}>
                <PlaceholderComponent height={100} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>

          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.VALUE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.PRICE_UNIT' })}>
                <PlaceholderComponent height={40} radius={8} color="purple" />
              </GroupComponent>
            </div>
            <div className="col-6 my-4">
              <PlaceholderComponent height={40} radius={8} color="light" />
            </div>
            <div className="col-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.ADDITIONAL' })}>
                <PlaceholderComponent height={100} radius={8} color="light" />
              </GroupComponent>
            </div>
            {this.props.edit && (
              <div className={columnClassNames}>
                <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.STATUS' })} required>
                  <PlaceholderComponent height={40} radius={8} color="purple" />
                </GroupComponent>
              </div>
            )}
            <div className="col-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.SERVICE_TYPE' })}>
                <PlaceholderComponent height={40} radius={8} color="purple" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>
        <CardFooterComponent>
          <ButtonComponent disabled className="ml-auto">
            <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" /> <i className="fi fi-right-arrow-forward" />
          </ButtonComponent>
        </CardFooterComponent>
      </CardComponent>
    );
  }
}
