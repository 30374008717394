// @flow
import React from 'react';
import classNames from 'classnames';

import { SidebarContainer } from 'app/core/sidebar/sidebar.container';
import { ImpersonationSessionBannerContainer } from '../../../impersonation/impersonation-session-banner.container';

import styles from './defaultTheme.module.scss';
import variables from 'assets/styles/_variables.scss';

/*:: import type { DefaultThemeProps } from './defaultTheme.types';*/

export const DefaultThemeComponent = ({ impersonating, children } /*: DefaultThemeProps*/) => {
  return (
    <>
      {impersonating && <ImpersonationSessionBannerContainer />}
      <div className="container-fluid">
        <div className={classNames('row', styles.row)}>
          <SidebarContainer topDistance={impersonating ? variables.topBannerHeight : 0} />
          <div className={classNames('col', styles.container)}>
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
