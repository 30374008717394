// @flow

/*:: import { Action, Reducer } from 'redux'; */
import get from 'lodash/get';
import { LOCATION_CHANGE /*:: , RouterState */ } from 'connected-react-router';

/**
 * This wrapper function enriches connected react router reducer and stores prevLocation and isInitialRoute
 * parameters in the router store slice.
 *
 * This should be removed in the future. In general, its a bad idea to store custom parameters in the router
 * store slice which is supposed to be property of connected react router. Also the application design should
 * evolve towards not storing react router data in redux store at all. Storing the router data in redux store is
 * something react router documentation advices against: https://reactrouter.com/web/guides/deep-redux-integration
 *
 * TODO VAEX-2512: Add reference to ADR
 */
export function withEnrichedRouterSlice(
  reducerFunction /*: (state: any, action: Action) => RouterState*/,
) /*: Reducer*/ {
  return (state /*: any*/, action /*: Action*/) => {
    const reducedState = reducerFunction(state, action);
    switch (action.type) {
      case LOCATION_CHANGE:
        return {
          ...reducedState,
          prevLocation: get(action, ['payload', 'prevLocation']),
          isInitialRoute: get(action, ['payload', 'isInitialRoute']),
        };
      default:
        return reducedState;
    }
  };
}
