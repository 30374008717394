import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Typography } from 'app/shared';
import { TranslationKey } from 'v2/types/translations';

import styles from './card-content-row.module.scss';

type Props = PropsWithChildren<{
  title: TranslationKey;
  className?: string;
}>;

export const CardContentRowComponent: React.FunctionComponent<Props> = ({ children, title, className }) => {
  return (
    <div className={classNames(className, 'd-flex mb-1')}>
      <Typography className={styles.title} type="bodyText" color="greyDark" id={title}>
        :
      </Typography>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
