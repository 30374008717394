import { and, isLayout, rankWith, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { HorizontalLayout } from '@jsonforms/vanilla-renderers';
import styles from './horizontal-layout.renderer.module.scss';
import cn from 'classnames';

export const HorizontalLayoutRenderer = withJsonFormsLayoutProps(props => {
  return (
    <div className={cn('container', styles.container, styles[`${props.uischema.options?.size}Size`])}>
      <HorizontalLayout {...props} />
    </div>
  );
});

export const horizontalLayoutTester = rankWith(1000, and(isLayout, uiTypeIs('HorizontalLayout')));
