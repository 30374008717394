import { V2RootState } from 'v2/v2.reducer';
import _, { isNil } from 'lodash';
import { deepDifference, deepMerge, DeepPartial } from './utils';
import { CaseReducer, current, Draft, PayloadAction } from '@reduxjs/toolkit';

export type FormState<TFormData> = {
  serverState: TFormData;
  localSentStateDiff: DeepPartial<TFormData>;
  localUnsentStateDiff: DeepPartial<TFormData>;
};

export function combineFormState<T>(formState: FormState<T>): T {
  return deepMerge(deepMerge(formState.serverState, formState.localSentStateDiff), formState.localUnsentStateDiff);
}

export function combinedFormStateSelector<TFormState>(
  selector: (state: V2RootState) => FormState<TFormState> | undefined,
) {
  return (state: V2RootState): TFormState | undefined => {
    const formState = selector(state);
    return formState ? combineFormState(formState) : undefined;
  };
}

export function isFormSynchronizedWithServerState(formState: FormState<unknown>): boolean {
  return _.isEmpty(formState.localSentStateDiff) && _.isEmpty(formState.localUnsentStateDiff);
}

export function isFormSynchronizedWithServerStateSelector(
  selector: (state: V2RootState) => FormState<unknown> | undefined,
) {
  return (state: V2RootState): boolean => {
    const formState = selector(state);
    return isNil(formState) || isFormSynchronizedWithServerState(formState);
  };
}

export function getFormStateDiff<T extends Record<string, unknown>>(
  formState: Pick<FormState<T>, 'serverState' | 'localSentStateDiff'>,
  localState: T,
): DeepPartial<T> {
  return deepDifference(deepMerge(formState.serverState, formState.localSentStateDiff), localState);
}

type FormUpdateReducers<
  TName extends string,
  TReduxState,
  TFormUpdatedPayload,
  TFormUpdateSubmittedPayload,
  TFormUpdateCompletedPayload,
> = {
  [key in `${TName}FormUpdated`]: CaseReducer<TReduxState, PayloadAction<TFormUpdatedPayload>>;
} & {
  [key in `${TName}FormUpdateSubmitted`]: CaseReducer<TReduxState, PayloadAction<TFormUpdateSubmittedPayload>>;
} & {
  [key in `${TName}FormUpdateCompleted`]: CaseReducer<TReduxState, PayloadAction<TFormUpdateCompletedPayload>>;
};

export function createFormUpdateReducers<
  TName extends string,
  TReduxState,
  TFormState extends Record<string, unknown>,
  TKey,
  TFormUpdatedPayload extends { formState: TFormState },
  TFormSubmittedPayload,
  TFormUpdateCompletedPayload,
  TFormUpdateCompletedSuccessPayload extends TFormUpdateCompletedPayload,
>(
  name: TName,
  props: {
    extractKey: (
      action: PayloadAction<TFormUpdatedPayload | TFormSubmittedPayload | TFormUpdateCompletedPayload>,
    ) => TKey;
    formStateSelector: (state: TReduxState, key: TKey) => FormState<TFormState> | undefined;
    serverStateSelector: (state: TReduxState, key: TKey) => TFormState | undefined;
    setFormState: (state: Draft<TReduxState>, key: TKey, formState: FormState<TFormState> | undefined) => void;
    onSubmitted: (state: Draft<TReduxState>, key: TKey) => void;
    wasUpdateSuccess: (
      state: Draft<TReduxState>,
      updateCompletedPayload: TFormUpdateCompletedPayload,
    ) => updateCompletedPayload is TFormUpdateCompletedSuccessPayload;
    serverStateFromUpdateSuccessPayloadSelector: (
      payload: TFormUpdateCompletedSuccessPayload,
    ) => TFormState | undefined;
    onSubmitSuccess: (
      state: Draft<TReduxState>,
      key: TKey,
      updateCompletedPayload: TFormUpdateCompletedSuccessPayload,
    ) => void;
    onSubmitFailure: (
      state: Draft<TReduxState>,
      key: TKey,
      updateCompletedPayload: Exclude<TFormUpdateCompletedPayload, TFormUpdateCompletedSuccessPayload>,
    ) => void;
  },
): FormUpdateReducers<TName, TReduxState, TFormUpdatedPayload, TFormSubmittedPayload, TFormUpdateCompletedPayload> {
  const {
    extractKey,
    formStateSelector,
    serverStateSelector,
    setFormState,
    onSubmitted,
    wasUpdateSuccess,
    serverStateFromUpdateSuccessPayloadSelector,
    onSubmitSuccess,
    onSubmitFailure,
  } = props;
  const reducers = {
    [`${name}FormUpdated` as const]: (state: Draft<TReduxState>, action: PayloadAction<TFormUpdatedPayload>) => {
      const key = extractKey(action);
      const { formState: localFormState } = action.payload;
      const reduxFormState = formStateSelector(current(state) as TReduxState, key);
      const reduxServerState = serverStateSelector(current(state) as TReduxState, key);

      const { serverState, localSentStateDiff } = (() => {
        if (reduxFormState) {
          return {
            serverState: reduxFormState.serverState,
            localSentStateDiff: reduxFormState.localSentStateDiff,
          };
        } else if (reduxServerState) {
          return {
            serverState: reduxServerState,
            localSentStateDiff: {},
          };
        }

        return { serverState: undefined, localSentStateDiff: undefined };
      })();

      // no issuing state nor voucher state for the voucher - ignore action
      if (!serverState || !localSentStateDiff) {
        return state;
      }

      setFormState(state, key, {
        serverState,
        localSentStateDiff,
        localUnsentStateDiff: getFormStateDiff({ serverState, localSentStateDiff }, localFormState),
      });

      return state;
    },
    [`${name}FormUpdateSubmitted` as const]: (
      state: Draft<TReduxState>,
      action: PayloadAction<TFormSubmittedPayload>,
    ) => {
      const key = extractKey(action);
      const formState = formStateSelector(current(state) as TReduxState, key);
      if (formState) {
        setFormState(state, key, {
          ...formState,
          localSentStateDiff: formState.localUnsentStateDiff,
          localUnsentStateDiff: {},
        });
      }

      onSubmitted(state, key);
      return state;
    },
    [`${name}FormUpdateCompleted` as const]: (
      state: Draft<TReduxState>,
      action: PayloadAction<TFormUpdateCompletedPayload>,
    ) => {
      const key = extractKey(action);
      const reduxFormState = formStateSelector(current(state) as TReduxState, key);
      if (wasUpdateSuccess(state, action.payload)) {
        const actionServerState = serverStateFromUpdateSuccessPayloadSelector(action.payload);
        if (actionServerState) {
          setFormState(state, key, {
            serverState: actionServerState,
            localSentStateDiff: {},
            localUnsentStateDiff: reduxFormState?.localUnsentStateDiff ?? {},
          });
        }

        onSubmitSuccess(state, key, action.payload);
      } else {
        if (reduxFormState) {
          setFormState(state, key, {
            ...reduxFormState,
            localSentStateDiff: {},
          });
        }

        onSubmitFailure(
          state,
          key,
          action.payload as Exclude<TFormUpdateCompletedPayload, TFormUpdateCompletedSuccessPayload>,
        );
      }
    },
  };

  // todo: unsafe cast, remove
  return reducers as FormUpdateReducers<
    TName,
    TReduxState,
    TFormUpdatedPayload,
    TFormSubmittedPayload,
    TFormUpdateCompletedPayload
  >;
}
