// @flow
import { SM_BREAKPOINT } from 'app/constants';
import { BusinessContext, CoreContext } from 'app/context';
/*:: import type { BoundsDateRangeType } from 'app/flow-types';*/
import { useIntl } from 'app/hooks';
import { InputBaseControl } from 'app/shared';
import { generateYearRange, translate } from 'app/utils';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
/*:: import type Moment from 'moment';*/
import moment from 'moment';
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import MediaQuery from 'react-responsive';
import { CustomDateInput } from './custom-date-input.component';
import { DatePickerHeader } from './datepicker-header/datepicker-header';
import { convertDate } from './datepicker-helpers';
/*:: import type { Props } from './datepicker.component.types';*/
import styles from './datepicker.module.scss';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';

const DATE_DISPLAY_FORMAT /*: string*/ = 'dd.MM.yyyy';
const DATE_DISPLAY_FORMAT_MOMENT /*: string*/ = 'DD.MM.YYYY';
// TODO: Write tests to verify that date picker works on supported locales
registerLocale('fi', fi);
registerLocale('sv', sv);

const momentToDate = (momentValue /*: ?Moment*/) /*: ?Date*/ => {
  if (momentValue) {
    return momentValue.toDate();
  }
  return momentValue;
};

const dateToMoment = (dateValue /*: ?Date*/) /*: ?Moment*/ => {
  if (dateValue) {
    // TODO: Convert date object to moment using string as an intermediate format
    //   in order to comply to the contract this component offered before.
    //   We should be able to use a simpler conversion here such as
    //   moment(dateValue) but this would break tests and we would have to verify
    //   that all usages of this component work as expected.
    //   Thus for moment this makes sure that we communicate selected date out of the
    //   date picker precisely as tests require.
    const dateString = moment(dateValue).format(DATE_DISPLAY_FORMAT_MOMENT);
    return moment(dateString, DATE_DISPLAY_FORMAT_MOMENT, 'en', true);
  }
  return dateValue;
};

const CustomDateInputWithRef = forwardRef((props, ref) => {
  return <CustomDateInput {...props} inputRef={ref} />;
});

export const DatePicker = (props /*: Props*/) => {
  const intl = useIntl();

  const { holidays } = useContext(CoreContext);
  const { createdAt } = useContext(BusinessContext);
  const [activeValidationBounds, setActiveValidationBounds] = useState(null);
  const [datePickerRef, setDatePickerRef] = useState({ current: null });
  const [shouldCalendarMove, setShouldCalendarMove] = useState(false);
  const [selectedDateObject, setSelectedDate] = useState(null);
  const [isCalendarOpen, toggleCalendarVisiblity] = useState(false);

  // set ref and re-render datepicker to calculate if should reverse
  const handleDatepickerRef = ref => {
    const newInputId = get(ref, ['input', 'id']);
    const prevInputId = get(datePickerRef, ['current', 'input', 'id']);

    if (newInputId && prevInputId !== newInputId) {
      setDatePickerRef({ current: ref });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldCalendarReverse = useCallback(() => {
    const input = get(datePickerRef, ['current', 'input']);
    const calendar = get(datePickerRef, ['current', 'calendar', 'componentNode']);

    if (!isCalendarOpen || !input || !calendar) {
      return shouldCalendarMove;
    }
    const viewportOffsets = input.getBoundingClientRect();
    return window.innerWidth - viewportOffsets.left - 40 < calendar.getBoundingClientRect().width;
  });

  // event listener function for watching resizing the screen
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const watchViewportForCalendarReverse = useCallback(() => {
    const shouldCalendarMoveNext = shouldCalendarReverse();
    if (shouldCalendarMoveNext !== shouldCalendarMove) {
      setShouldCalendarMove(shouldCalendarMoveNext);
    }
  });

  // provides bounds for validation; by default validation bounds are locked to initial bounds value
  const getValidationBounds = (
    pickerBounds /*: BoundsDateRangeType*/,
    validationBounds /*: BoundsDateRangeType*/,
    dynamicValidationBounds /*: boolean*/,
  ) => {
    if (dynamicValidationBounds) {
      return {
        ...pickerBounds,
        ...validationBounds,
      };
    }

    if (!activeValidationBounds) {
      setActiveValidationBounds({
        ...pickerBounds,
        ...validationBounds,
      });
    }

    return activeValidationBounds;
  };

  // component did mount and unmount
  useEffect(
    () => {
      window.addEventListener('resize', debounce(watchViewportForCalendarReverse.bind(this), 50));

      return () => window.removeEventListener('resize', debounce(watchViewportForCalendarReverse.bind(this), 50));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const isCalendarShown = get(datePickerRef, ['current', 'state', 'open'], false);
    toggleCalendarVisiblity(isCalendarShown);
  });

  // check if calculation is needed after open
  useEffect(
    () => {
      watchViewportForCalendarReverse();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCalendarOpen],
  );

  const {
    customHeader,
    selectedDate,
    dynamicValidationBounds,
    onChange,
    placeholder,
    preventOverFlow,
    highlightDates,
    bounds = {},
    validationBounds = {},
    value, // fix for connected datepicker
    ...otherProps
  } = props;

  const popperModifiers = {
    preventOverflow: {
      enabled: preventOverFlow ? true : false,
    },
    flip: {
      enabled: false,
    },
    hide: {
      enabled: false,
    },
  };

  // DatePicker bounds:
  //  minDate -> bounds.minDate -(if undefined)-> createdAt -(if undefined)-> start of the current year
  //  maxDate -> bounds.maxDate -(if undefined)-> end of the current year
  const pickerBounds = useMemo(
    () => ({
      maxDate: bounds.maxDate ? convertDate(bounds.maxDate) : moment().endOf('year'),
      minDate: bounds.minDate
        ? convertDate(bounds.minDate)
        : createdAt
        ? convertDate(createdAt)
        : moment().startOf('year'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bounds],
  );

  // allows overriding bounds for validation
  const explicitValidationBounds = {
    ...(validationBounds.maxDate ? { maxDate: convertDate(validationBounds.maxDate) } : {}),
    ...(validationBounds.minDate ? { minDate: convertDate(validationBounds.minDate) } : {}),
  };

  const pickerValidationBounds = getValidationBounds(
    pickerBounds,
    explicitValidationBounds,
    dynamicValidationBounds || false,
  );

  const years = useMemo(
    () =>
      generateYearRange({
        max: moment(pickerBounds.maxDate).endOf('year'),
        min: moment(pickerBounds.minDate).startOf('year'),
      }),
    [pickerBounds],
  );

  const onDatepickerChange = date => {
    setAndAnnounceSelectedDate(convertDate(dateToMoment(date)));
  };

  const renderCustomHeader = data => {
    const date = dateToMoment(data.date);
    return <DatePickerHeader {...data} date={date} years={years} />;
  };

  const datepickerProps = {
    ...otherProps,
    startDate: momentToDate(props.startDate),
    endDate: momentToDate(props.endDate),
    maxDate: momentToDate(pickerBounds.maxDate),
    minDate: momentToDate(pickerBounds.minDate),
    className: classNames(props.className),
    dateFormat: DATE_DISPLAY_FORMAT,
    ref: handleDatepickerRef,
    selected: momentToDate(convertDate(selectedDateObject)),
    locale: intl.locale,
    placeholderText: translate({ intl, translation: placeholder }),
    ...(customHeader ? { renderCustomHeader } : {}),
    onChange: onDatepickerChange,
    onFocus: () => {
      props.onFocus && props.onFocus();
    },
    onBlur: () => {
      props.onBlur && props.onBlur();
    },
    onCalendarClose: () => {
      // TODO: There is a bug in date picker that its not signaling onBlur
      //   when calendar closes. This overcomes that.
      //   For more information see: https://github.com/Hacker0x01/react-datepicker/issues/2028
      //   Remove this once the bug is fixed.
      props.onBlur && props.onBlur();
    },
    years,
    highlightDates: highlightDates || holidays,
  };

  useEffect(() => {
    setSelectedDate(convertDate(selectedDate));
  }, [selectedDate]);

  const setAndAnnounceSelectedDate = (selectedDate /*: Moment*/) => {
    setSelectedDate(selectedDate);
    onChange && onChange({ date: selectedDate, validationBounds: pickerValidationBounds });
  };

  const popperClassNames = classNames(styles.calendar, {
    [styles['reverse-popper']]: shouldCalendarMove,
  });

  const popperPlacement = shouldCalendarMove ? 'bottom-end' : 'bottom-start';

  return (
    <MediaQuery maxWidth={SM_BREAKPOINT}>
      {widthIsSmallerThanSmallBreakpoint => (
        <ReactDatePicker
          {...datepickerProps}
          popperClassName={popperClassNames}
          popperPlacement={popperPlacement}
          popperModifiers={popperModifiers}
          customInput={<CustomDateInputWithRef />}
          // Use strict parsing so that date picker doesn't try to guesswork selected date
          // and drop into using en-US as the locale. For more information see: https://github.com/Hacker0x01/react-datepicker/issues/2501
          // We can consider dropping this once the issue has been fixed.
          strictParsing
          // The withPortal will render calendar component in a portal when in mobile.
          // This is not using the OmaVaana Modal component.
          withPortal={widthIsSmallerThanSmallBreakpoint}
        />
      )}
    </MediaQuery>
  );
};

DatePicker.defaultProps = {
  customHeader: true,
  placeholder: 'CORE.DATE',
};

export const DatePickerComponent = (props /*: Props*/) => (
  <InputBaseControl icon="time" component={DatePicker} {...props} />
);
