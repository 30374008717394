import { connect } from 'react-redux';
import { compose } from 'redux';

import { SidebarComponent } from './sidebar.component';

import {
  sidebarClose,
  sidebarDestroy,
  sidebarOpen,
  sidebarRegister,
  sidebarToggle,
} from 'app/redux/sidebar/sidebar.actions';

const mapStateToProps = ({ sidebars }, { name }) => ({
  sidebars,
  sidebar: sidebars[name] || {},
});

const mapsDispatchToProps = (dispatch, { name }) => ({
  register: isOpen => dispatch(sidebarRegister({ name, isOpen })),
  destroy: () => dispatch(sidebarDestroy({ name })),
  sidebarOpen: () => dispatch(sidebarOpen({ name })),
  sidebarClose: () => dispatch(sidebarClose({ name })),
  sidebarToggle: () => dispatch(sidebarToggle({ name })),
});

export const SidebarContainer = compose(connect(mapStateToProps, mapsDispatchToProps))(SidebarComponent);
