import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';

import { VenueMunicipalitiesComponent } from './venue-municipalities.component';
import { withAuthorization } from 'app/common';

import { fetchMunicipalities, fetchVenue } from 'app/redux/venues/venues.actions';

const mapStateToProps = (
  { venues: { municipalities, entities: venues, updatingContract } },
  {
    match: {
      params: { id },
    },
  },
) => ({
  updatingContract,
  municipalities,
  loading: !municipalities || !venues[id],
  venue: get(venues, [id, 'venue'], {}),
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  fetchMunicipalities: () => dispatch(fetchMunicipalities(id)),
  fetchVenue: () => dispatch(fetchVenue(id)),
  redirectToVenuesPage: () => dispatch(push(`/venues`)),
});

export const VenueMunicipalitiesContainer = compose(
  withAuthorization(['venues', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
)(VenueMunicipalitiesComponent);
