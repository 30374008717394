//@flow

import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './append.component.types';*/

export const Append = (props /*: Props*/) => {
  const { appendComponent: AppendComponent, append, className } = props;

  if (append) {
    return (
      <span className={classNames('input-group-append', className)}>
        <span className="input-group-text">
          <AppendComponent append={append} />
        </span>
      </span>
    );
  }

  return null;
};
