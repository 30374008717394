import { OrganizationalUnitUseCases } from 'v2/types/postpay';
import { Links } from './links';

// earlier redirects have priority over later ones
const postpayRedirects: { usecase: keyof OrganizationalUnitUseCases; redirectUrl: string }[] = [
  {
    usecase: Links.OrganizationalUnit.IssueVoucherFromAvailableVoucherTypes,
    redirectUrl: '/service-vouchers/types',
  },
];

export function getDefaultPostpayUrl(availableUsecases: OrganizationalUnitUseCases) {
  const hasUsecase = (usecase: keyof OrganizationalUnitUseCases) => Boolean(availableUsecases[usecase]);

  const postpayRedirect = postpayRedirects.find(redirect => hasUsecase(redirect.usecase));
  if (postpayRedirect) {
    return postpayRedirect.redirectUrl;
  }
  return undefined;
}
