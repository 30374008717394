import { handleActions } from 'redux-actions';
import { omit } from 'lodash';
import { FETCH_DICTIONARY_FAILURE, FETCH_DICTIONARY_SUCCESS } from './dictionaries.action-types';

const defaultState = {};

const handleDictionaryData = (path /*: string*/, dictionary /*: any*/) => {
  if (path === 'products/voucher') {
    dictionary = handleProductsDictionary(dictionary);
  }

  return dictionary;
};

const handleProductsDictionary = dictionary => ({
  ...dictionary,
  multipliers: dictionary.products.reduce((prev, next) => ({ ...prev, [next.id]: next.amount }), {}),
});

export const dictionariesReducer = handleActions(
  {
    [FETCH_DICTIONARY_SUCCESS]: (state, { meta: { path }, payload: { data: dictionary } }) => ({
      ...state,
      [path]: handleDictionaryData(path, dictionary),
    }),
    [FETCH_DICTIONARY_FAILURE]: (state, { meta: { path } }) => omit(state, [path]),
  },
  defaultState,
);
