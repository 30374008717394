import { PageHeaderComponent } from 'app/core';
import { ButtonComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardFooterComponent } from 'app/shared/card-new';
import { CardHeaderComponent } from 'app/shared/card-new/header/card-header.component';
import { LoadingComponent } from 'app/shared/loading/loading.component';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { isNotNil } from 'v2/utils/is-nil';
import { hasLink } from 'v2/utils/links';
import { useV2Selector } from 'v2/utils/redux-utils';
import styles from './print-service-voucher.module.scss';
import { voucherPrintStateSelector, voucherStateSelector } from './service-voucher-selectors';
import { ServiceVoucherStatus } from './service-vouchers-slice';
import { isServiceVoucherLoaded } from './utils/is-service-voucher-loaded';
import { actions as serviceVouchersActions } from './service-vouchers-slice';
import { actions as printServiceVouchersActions, ServiceVoucherPdfStatus } from './print-service-vouchers-slice';
import { useDispatch } from 'react-redux';

type PrintServiceVoucherPageProps = RouteComponentProps<{ id: string }>;

export const PrintServiceVoucherPage: React.FC<PrintServiceVoucherPageProps> = ({
  match: {
    params: { id: voucherId },
  },
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const defaultVoucherResult = useMemo(() => ({ status: ServiceVoucherStatus.Loading } as const), []);
  const voucherResult = useV2Selector(voucherStateSelector(voucherId)) ?? defaultVoucherResult;

  useEffect(() => {
    dispatch(serviceVouchersActions.voucherRequested({ voucherId }));
  }, [dispatch, voucherId]);

  const printVoucher = useCallback(() => {
    if (isServiceVoucherLoaded(voucherResult)) {
      const { voucher } = voucherResult;
      dispatch(printServiceVouchersActions.printVoucherPdfRequested({ voucher }));
    }
  }, [voucherResult, dispatch]);

  const printVoucherApprovedServiceProviders = useCallback(() => {
    if (isServiceVoucherLoaded(voucherResult)) {
      const { voucher } = voucherResult;
      dispatch(printServiceVouchersActions.printVoucherApprovedServiceProvidersPdfRequested({ voucher }));
    }
  }, [voucherResult, dispatch]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const voucherPrintState = useV2Selector(voucherPrintStateSelector(voucherId));
  const Content: React.ReactNode = (() => {
    if (!isServiceVoucherLoaded(voucherResult)) {
      return <LoadingComponent isLoading size="large" />;
    }

    const { voucher } = voucherResult;
    const actions: Action[] = [
      hasLink(voucher, 'usecase:print-documents:action:get-voucher-document')
        ? ({
            title: 'SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.PRINT_VOUCHER',
            action: printVoucher,
            isLoading: voucherPrintState?.voucherPdfStatus === ServiceVoucherPdfStatus.Loading,
          } as const)
        : undefined,
      hasLink(voucher, 'usecase:print-documents:action:get-approved-service-providers-document')
        ? ({
            title: 'SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.PRINT_APPROVED_SERVICE_PROVIDERS',
            action: printVoucherApprovedServiceProviders,
            isLoading: voucherPrintState?.voucherApprovedServiceProvidersPdfStatus === ServiceVoucherPdfStatus.Loading,
          } as const)
        : undefined,
    ].filter(isNotNil);

    // TODO: what to do if no actions available?

    return (
      <CardComponent>
        <CardHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.PRINT_DOCUMENTS_CARD_TITLE" />
        <CardBodyComponent>
          <ActionList actions={actions} />
        </CardBodyComponent>
        <CardFooterComponent>
          <ButtonComponent onClick={goBack}>
            <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.BACK" />
          </ButtonComponent>
        </CardFooterComponent>
      </CardComponent>
    );
  })();

  return (
    <>
      <PageHeaderComponent
        title="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.PAGE_TITLE"
        onBack={goBack}
        separatorHeight={40}
      />
      {Content}
    </>
  );
};

type Action = {
  title: TranslationKey;
  action: () => void;
  isLoading: boolean;
};

export function ActionList(props: { actions: Action[] }) {
  return (
    <div className={styles.actionList}>
      {props.actions.map(action => (
        <div key={action.title} className={styles.action}>
          <FormattedMessage id={action.title} />
          <ButtonComponent onClick={action.action} disabled={action.isLoading}>
            <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_PRINT_DOCUMENTS.SELECT_ACTION" />
          </ButtonComponent>
        </div>
      ))}
    </div>
  );
}
