import { handleActions } from 'redux-actions';

import { FETCH_VENUE_TYPES_SUCCESS } from './register.action-types';

const defaultState = {};

export const registerReducer = handleActions(
  {
    [FETCH_VENUE_TYPES_SUCCESS]: (state, { payload: { data: venuesTypes } }) => ({
      ...state,
      venuesTypes,
    }),
  },
  defaultState,
);
