// @flow

import React, { Component } from 'react';
import { times } from 'lodash';

import { PlaceholderComponent } from 'app/shared';

/*:: import type { Props } from './transaction-details-modal.placeholder.types';*/

export class TransactionDetailsModalPlaceholder extends Component /*:: <Props>*/ {
  modalEntry = (index /*: number*/) => (
    <div key={index} className={this.props.styles.entry}>
      <div className={this.props.styles.label}>
        <PlaceholderComponent width={150} height={16} />
      </div>
      <div>
        <PlaceholderComponent width={250} height={19} />
      </div>
    </div>
  );

  render() {
    return (
      <div className={this.props.styles.container}>
        <div className={this.props.styles.header}>
          <PlaceholderComponent width={200} height={32} color="purple" />
        </div>
        <div className={this.props.styles.date}>
          <PlaceholderComponent width={120} height={18} />
        </div>

        <div className="row">
          <div className="col">{times(5, this.modalEntry)}</div>
          <div className="col">{times(5, this.modalEntry)}</div>
        </div>
        <div className="row">
          <div className="col" />
          <div className="col">
            <div className="text-right">
              <PlaceholderComponent className="text-right" width={50} height={18} />
            </div>
            <div className="text-right">
              <PlaceholderComponent className="text-right" width={60} height={18} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
