import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { fillValues } from 'app/redux/register/register.actions';

import { VenuesListComponent } from './venues-list.component';

const mapDispatchToProps = dispatch => ({
  fillValues: (from, to) =>
    dispatch(
      fillValues({
        form: 'register',
        from,
        to,
      }),
    ),
});

export const VenuesListContainer = compose(injectIntl, connect(null, mapDispatchToProps))(VenuesListComponent);
