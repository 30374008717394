// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { change } from 'redux-form';
import onClickOutside from 'react-onclickoutside';

import { updateCalculatedValue } from 'app/redux/coupons/coupons.actions';

import { CouponsCalculatorComponent } from './coupons-calculator.component';

const mapDispatchToProps = (dispatch, { calculations, form, value: { name } }) => ({
  onClear: () => dispatch(change(form, calculations, [{ amount: 1 }])),
  onChange: () => dispatch(updateCalculatedValue(form, name, calculations)),
});

export const CouponsCalculatorContainer = compose(
  injectIntl,
  connect(null, mapDispatchToProps),
  onClickOutside,
)(CouponsCalculatorComponent);
