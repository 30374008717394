// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { TableCellComponent } from 'app/shared/table-new';

/*:: import type { Props } from './sortable-table-header.component.types';*/
import styles from './sortable-table-header.module.scss';

export const SortableTableHeader = ({ align, column, currentOrder, translationId, orderBy } /*: Props*/) => {
  const isActive = currentOrder.column === column;
  const iconClassName = classNames(styles.icon, `fi fi-sort-${currentOrder.direction}`);
  const tagName = isActive ? 'strong' : 'span';

  return (
    <TableCellComponent align={align} variant="header" className={styles.header}>
      <span className={styles.wrapper} onClick={() => orderBy(column)}>
        <FormattedMessage id={translationId} tagName={tagName} />
        {isActive && <i className={iconClassName} />}
      </span>
    </TableCellComponent>
  );
};
