//@flow

/*:: import type { Config } from './decimal.validator.types';*/

export const decimalValidator =
  ({ message = 'VALIDATION.INVALID_FORMAT', expectedFormat = 'undefined' } /*: Config*/ = {}) =>
  (value /*: ?string*/) => {
    if (!value) {
      return undefined;
    }

    const normalizedValue = String(value).trim();
    const decimalRegex = /^(0|[1-9][0-9]*)([,.]\d{0,2})?$/;

    if (!normalizedValue.match(decimalRegex)) {
      return { id: message, values: { expectedFormat } };
    }
  };
