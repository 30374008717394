import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { compose } from 'redux';

import { withPermissions } from 'app/common';
import { invalidateCoupon } from 'app/redux/coupons/coupons.actions';
import { updateConfirmation } from 'app/redux/view/view.actions';
import { getCouponDetails } from 'app/redux/coupons/coupons.selectors';
import { CouponsDetailsInfoComponent } from './coupons-details-info.component';
import { fetchCoupon } from '../../../redux/coupon/coupon.actions';
import { getSearch } from 'connected-react-router';

const mapStateToProps = ({
  users: {
    current: {
      personal: { firstName, lastName },
    },
  },
  ...state
}) => {
  const { coupon, isLoading: loading } = getCouponDetails(state);

  const redirectedFrom = (new URLSearchParams(getSearch(state))).get('redirectedFrom');

  return {
    username: `${firstName} ${lastName}`,
    coupon,
    loading,
    redirectedFrom,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  onInvalidate: () =>
    dispatch(
      updateConfirmation({
        onConfirm: () => dispatch(invalidateCoupon(id)).then(() => dispatch(fetchCoupon(id))),
        title: 'COUPONS.DETAILS.CONFIRM_INVALIDATE',
      }),
    ),
});

export const CouponsDetailsInfoContainer = compose(
  injectIntl,
  withRouter,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDetailsInfoComponent);
