// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent } from 'app/shared';

/*:: import type { Props } from './summary-buttons.component.types';*/
import styles from './summary-buttons.module.scss';

export class SummaryButtonsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <div className="row">
        <div className={classNames('col-lg-12', styles.buttons)}>
          {this.props.success ? (
            <ButtonComponent theme="primary-outline" to="/">
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="REGISTER.BACK_TO_HOMEPAGE" />
            </ButtonComponent>
          ) : (
            <>
              <ButtonComponent onClick={() => this.props.changeStep('FORM')} theme="primary-outline">
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.BACK" />
              </ButtonComponent>

              <ButtonComponent disabled={this.props.disabled} loading={this.props.submitting} theme="primary">
                <FormattedMessage id="REGISTER.TITLE" /> <i className="fi fi-right-arrow-forward" />
              </ButtonComponent>
            </>
          )}
        </div>
      </div>
    );
  }
}
