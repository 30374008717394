//@flow

import isArray from 'lodash/isArray';

const getFirstExistElement = elements => {
  if (!isArray(elements)) {
    return document.querySelector(elements);
  }

  return document.querySelector(elements.find(element => document.querySelector(element)));
};

export const scrollToElement = (elements /*: string | string[]*/, margin /*: number*/ = 10) => {
  const node = getFirstExistElement(elements);

  if (node) {
    const offset = node.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo(0, offset - margin);
  } else {
    window.scrollTo(0, 0);
  }
};
