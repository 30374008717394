import moment from 'moment';
import React from 'react';
import { Input } from '../input/input.component';

/*:: export type MaskInputState = {
  selection: {
    end?: number,
    start?: number,
  },
  value: string,
};*/

function extractDayPart(value /*: string*/) {
  const day = value.substr(0, 2);
  const after = value.substr(2);
  return {
    after,
    before: '',
    day,
  };
}

function extractMonthPart(value /*: string*/) {
  const before = value.substr(0, 3);
  const month = value.substr(3, 2);
  const after = value.substr(5);
  return {
    after,
    before,
    month,
  };
}

function extractYearPart(value /*: string*/) {
  const before = value.substr(0, 6);
  const year = value.substr(6, 4);
  const after = '';
  return {
    after,
    before,
    year,
  };
}

function isDayDefined(value /*: string*/) /*: boolean*/ {
  const matcher = /\d{2}/;
  return matcher.test(extractDayPart(value).day);
}

function isMonthDefined(value /*: string*/) /*: boolean*/ {
  const matcher = /\d{2}/;
  return matcher.test(extractMonthPart(value).month);
}

function isYearDefined(value /*: string*/) /*: boolean*/ {
  const matcher = /\d{4}/;
  return matcher.test(extractYearPart(value).year);
}

function isInvalidDay(value /*: string*/) /*: boolean*/ {
  const MIN_DAY = 1;
  const MAX_DAY = 31;
  const day /*: number*/ = parseInt(extractDayPart(value).day, 10);
  return day < MIN_DAY || day > MAX_DAY;
}

function isInvalidMonth(value /*: string*/) /*: boolean*/ {
  const MIN_MONTH = 1;
  const MAX_MONTH = 12;
  const month /*: number*/ = parseInt(extractMonthPart(value).day, 10);
  return month < MIN_MONTH || month > MAX_MONTH;
}

function isInvalidDate(value /*: string*/, defaultYear /*: string*/) /*: boolean*/ {
  const year = isYearDefined(value) ? extractYearPart(value).year : defaultYear;
  const dateString = `${extractYearPart(value).before}${year}`;
  return !moment(dateString, 'DD.MM.YYYY', true).isValid();
}

// Expands '1.' form to '01'
function expandDelimitedForm(form /*: string*/) /*: string*/ {
  const matcher = /(\d)\./; // Matches digit and delimiter ('1.')
  const inputMatch = form.match(matcher);
  return inputMatch ? `0${inputMatch[1]}` : form;
}

function transformDay(value /*: string*/) /*: string*/ {
  const { day, after } = extractDayPart(value);
  const expanded = expandDelimitedForm(day);
  return `${expanded}${after}`;
}

function transformMonth(value /*: string*/) /*: string*/ {
  const { before, month, after } = extractMonthPart(value);
  const expanded = expandDelimitedForm(month);
  return `${before}${expanded}${after}`;
}

export function coerceInput(newState /*: MaskInputState*/, oldState /*: MaskInputState*/) /*: MaskInputState*/ {
  const LEAP_YEAR = '2020';
  const coercedValue = transformMonth(transformDay(newState.value));
  if (isDayDefined(coercedValue) && isInvalidDay(coercedValue)) {
    return oldState;
  }
  if (isMonthDefined(coercedValue) && isInvalidMonth(coercedValue)) {
    return oldState;
  }
  if (isDayDefined(coercedValue) && isMonthDefined(coercedValue) && isInvalidDate(coercedValue, LEAP_YEAR)) {
    return oldState;
  }
  return {
    ...newState,
    value: coercedValue,
  };
}

function generateReactInputMaskProperties() {
  const mask = {
    // In order to make dotted day or month ('1.') part of newState
    // parameter passed to coerceInput function we are going to allow
    // dot as second character of day and month part of input.
    mask: '9d.9d.9999',
    maskChar: ' ',
    formatChars: {
      9: '[0-9]',
      d: '[0-9.]',
    },
  };
  return {
    mask,
    beforeMaskedValueChange: coerceInput,
  };
}

/*
CustomDateInput
This component supports entering dates only in format 'DD.MM.YYYY'.
It will coerce entered date if it can with following rules:
1. One-digit version of day or month will be coerced to its two-digit version: "4." => "04.", etc.
2. Partial dates and months are coerced to their two-digit versions: "4.1.2020" => "04.01.2020", "04.1.2020" => "04.01.2020", etc.
3. Days need to be <= 31
4. Months need to be <= 12
5. Date needs to be valid according to moment.js
 */
export const CustomDateInput = props => {
  return <Input {...props} {...generateReactInputMaskProperties()} autoComplete="off" inputMode="numeric" />;
};
