// @flow

import React, { PureComponent } from 'react';
import { CalculationsRowComponent } from './calculations-row/calculations-row.component';

import { decimalFormatter } from 'app/utils';

/*:: import type { Props } from './coupons-calculator-calculations.component.types';*/
import styles from './coupons-calculator-calculations.module.scss';

export class CouponsCalculatorCalculationsComponent extends PureComponent /*:: <Props>*/ {
  componentDidUpdate(prevProps /*: Props*/) {
    if (this.props.fields.length < prevProps.fields.length) {
      this.updateMainField();
    }
  }

  onAdd = () => this.props.fields.push({ amount: 1, value: 1, total: 0 });

  updateMainField = () => {
    const {
      fields: { name },
      fieldNameToUpdate,
      reactFinalForm: { change, getFieldState },
    } = this.props;

    const fieldArrayValues = (getFieldState(name) || {}).value;
    const sum = fieldArrayValues.reduce((sum, { total }) => sum + +total, 0);

    change(fieldNameToUpdate, decimalFormatter({ value: sum, grouping: false, min: 0 }));
  };

  render() {
    const { fields, reactFinalForm } = this.props;

    return (
      <>
        {fields.map((field, index) => {
          const props = {
            field,
            index,
            key: index,
            disabled: fields.length === 1,
            onRemove: () => {
              fields.remove(index);
            },
            form: reactFinalForm,
            updateMainField: this.updateMainField,
          };

          return <CalculationsRowComponent {...props} />;
        })}
        <button className={styles.button} type="button" onClick={this.onAdd}>
          {this.props.intl.formatMessage({ id: 'CALCULATOR.ADD_ROW' })}
        </button>
      </>
    );
  }
}
