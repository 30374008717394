//@flow
import React, { useCallback, useMemo, useState } from 'react';
import uniqueId from 'lodash/uniqueId';

import { isFieldInvalid } from 'app/utils';

/*:: import type { Props } from './control-base.component.types';*/

export const ControlBase = (props /*: Props*/) => {
  const [labelRef, setLabelRef] = useState();
  const [wrapperRef, setWrapperRef] = useState();
  const [errorRef, setErrorRef] = useState();
  const id = useMemo(() => uniqueId(`input_`), []);

  const {
    appendClassName,
    appendComponent,
    component,
    control: Control,
    error,
    focused,
    hideValidation,
    icon,
    iconClassName,
    input,
    invalid,
    labelClassName,
    labelPosition,
    meta: inputMeta = {},
    styles = {},
    label,
    variant,
    columnAlign,
    ...otherProps
  } = props;

  //focus closest input
  const setInputFocus = useCallback(() => {
    if (wrapperRef && label) {
      const input = wrapperRef.querySelector('input');
      input && input.focus();
    }
  }, [wrapperRef, label]);

  const isDisabled = !!props.disabled;
  const isFocused = !!inputMeta.active || !!focused;
  const isInvalid = isFieldInvalid(inputMeta) || !!invalid;
  const isRequired = !!props.required;
  const withAppend = !!props.append;
  const withIcon = !!props.icon;

  //control meta
  const controlMeta = { isFocused, isInvalid, isDisabled, withIcon, isRequired, withAppend };

  //common controls component props
  const componentProps = {
    ...otherProps,
    controlMeta,
    errorRef,
    id,
    labelRef,
    wrapperRef,
  };

  //common label props
  const labelProps = {
    controlMeta,
    id,
    label,
    labelClassName,
    setInputFocus,
    setLabelRef,
  };

  //common error props
  const errorProps = {
    controlMeta,
    error,
    hideValidation,
    label,
    setErrorRef,
  };

  //Set default props for wrapper
  const wrapperProps = {
    variant,
    setWrapperRef,
    controlMeta,
    styles: {
      isFocused: styles.isFocused || '',
      isInvalid: styles.isInvalid || '',
      isDisabled: styles.isDisabled || '',
      withIcon: styles.withIcon || '',
    },
    ...(!!input && input.name ? { name: input.name } : {}),
  };

  const iconProps = {
    onClick: setInputFocus,
    className: iconClassName,
    icon,
  };

  const controlProps = {
    ...otherProps,
    appendClassName,
    appendComponent,
    columnAlign,
    component,
    componentProps,
    controlMeta,
    errorProps,
    errorRef,
    iconProps,
    id,
    label,
    labelPosition,
    labelProps,
    labelRef,
    setInputFocus,
    variant,
    wrapperProps,
    wrapperRef,
  };

  return <Control {...controlProps} />;
};

ControlBase.defaultProps = {
  variant: 'regular',
};
