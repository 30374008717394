import uniqueId from 'lodash/uniqueId';

import {
  NOTIFICATION_POP,
  NOTIFICATION_PUSH,
  NOTIFICATION_REMOVE,
  NOTIFICATIONS_CLEAR,
} from './notifications.action-types';

export const notificationsReducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFICATION_PUSH:
      return [...state, { ...action.payload, id: uniqueId('notification_') }];
    case NOTIFICATION_POP: {
      return state.slice(0, state.length - 1);
    }
    case NOTIFICATION_REMOVE: {
      return state.filter(({ id }) => id !== action.payload);
    }
    case NOTIFICATIONS_CLEAR: {
      return [];
    }
    default:
      return state;
  }
};
