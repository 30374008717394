// @flow

import { normalize } from 'app/utils';

/*:: import type { Configuration } from './currency-formatter.util.types';*/
/*:: import type { Value } from 'app/utils/normalizer/normalizer.util.types';*/

const formatter = new Intl.NumberFormat('fi-FI', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const isValueUndefined = value => {
  return value === undefined;
};

export const replaceNoValueToEmptyString = (value /*: Value*/) /*: Value*/ => (value ? value : 0);
export const replaceComma = (value /*: Value*/) /*: string*/ => value.toString().replace(',', '.');

export const currencyFormatter = {
  format: (value /*: ?Value*/, configuration /*::?: Configuration*/) /*: string*/ => {
    const { normalizers = [], placeholderObject = {} } = configuration || {};
    const { placeholder = '-', condition = isValueUndefined } = placeholderObject;
    const defaultNormalizers = [replaceNoValueToEmptyString, replaceComma];
    // $FlowFixMe
    const normalizedValue /*: Value*/ = normalize(value, [...defaultNormalizers, ...normalizers]);

    if (condition(value)) {
      return placeholder;
    }

    return formatter.format(normalizedValue);
  },
  formatWithSign: (value /*: ?Value*/, configuration /*::?: Configuration*/) /*: string*/ => {
    const formattedValue = currencyFormatter.format(value, configuration);
    const firstChar = Number(formattedValue[0]);

    // If first char is a validNumber not minus sign '−'
    // and it is not zero '0'
    // we should ADD plus sign '+'
    if (!isNaN(firstChar) && firstChar !== 0) {
      return `+${formattedValue}`;
    }

    return formattedValue;
  },
};
