//@flow

import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

const defaultArrayLimit = 10;
const defaultStringLimit = 150;
const defaultStringTerminator = '...';

export const limit = (value /*: string*/, limit /*::?: number*/, terminator /*::?: string*/) => {
  if (isArray(value)) {
    const arrayLimit = limit || defaultArrayLimit;

    return value.length > arrayLimit ? value.slice(0, arrayLimit) : value;
  }

  if (isString(value)) {
    const stringLimit = limit || defaultStringLimit;
    const stringTerminator = terminator || defaultStringTerminator;

    if (value.length > stringLimit) {
      return value.substring(0, stringLimit) + stringTerminator;
    } else {
      return value;
    }
  }

  throw new Error('value should be array or string');
};
