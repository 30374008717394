// @flow
import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent } from 'app/shared';
import { PricelistsFiltersComponent } from './filters/pricelists-filters.component';
import { PricelistsListComponent } from './list/pricelists-list.component';

/*:: import type { Props } from './pricelists-manage.component.types';*/

import { applyFilters, useVenueFilter } from './pricelists-manage.filters';

export const PricelistsManageComponent = (props /*: Props*/) => {
  const { isLoading, venues, fetchContractedVenues } = props;
  const [filters, setFilters] = useVenueFilter();
  const [showSubStatusFilters, setShowSubStatusFilters] = useState([]);

  useEffect(() => {
    const uniqueVenueStatuses = venues ? _.uniq(venues.map(venue => venue.status)) : []
    setShowSubStatusFilters(uniqueVenueStatuses);
  }, [venues]);

  useEffect(
    () => {
      fetchContractedVenues();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <PageHeaderComponent title="PRICELISTS.MANAGE.TITLE" />
      <PricelistsFiltersComponent
        setFilters={setFilters}
        filters={filters}
        showSubStatusFilters={showSubStatusFilters}
      />
      <SeparatorComponent />
      <PricelistsListComponent loading={isLoading} venues={applyFilters(venues, filters)} />
    </>
  );
};
