import isFunction from 'lodash/isFunction';
import { isValidRSAA, RSAA } from 'redux-api-middleware';

const createIntercept = (requestLimitingKey, dispatch) => payload => {
  const rsaa = payload[RSAA];

  if (!isValidRSAA(payload)) {
    throw new Error('singleConcurrentRequestAction should be called only with RSAA action');
  }

  return dispatch({
    [RSAA]: { requestLimitingKey, ...rsaa },
  });
};

export const singleConcurrentRequestAction = (requestLimitingKey, action) => (dispatch, getState) => {
  const dispatchIntercept = createIntercept(requestLimitingKey, dispatch);
  if (isFunction(action)) {
    return action(dispatchIntercept, getState);
  } else {
    return dispatchIntercept(action);
  }
};
