//@flow
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import { IntlContext } from './intl.context';

export const IntlContextProvider = injectIntl(({ children, intl }) => {
  const [state, setState] = useState({ intl });

  useEffect(
    () => {
      if (intl) {
        setState({ intl });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.locale],
  );

  return <IntlContext.Provider value={state}>{children}</IntlContext.Provider>;
});
