// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AsideComponent } from 'app/core';
import { ButtonComponent, SeparatorComponent, Typography } from 'app/shared';

import { useIntl } from 'app/hooks';

import styles from './error.module.scss';

export const ErrorComponent = () => {
  const intl = useIntl();

  return (
    <div className="container-fluid">
      <div className="row">
        <AsideComponent />
        <div className={classNames('col-12', styles.column)}>
          <div className={styles.section}>
            <Typography color="greyDark" id="ERROR.TITLE" tag="h1" />
            <FormattedMessage
              id="ERROR.DESCRIPTION"
              values={{
                mail: (
                  <a href={`mailto:${intl.formatMessage({ id: 'ERROR.EMAIL' })}`}>
                    <FormattedMessage id="ERROR.EMAIL" />
                  </a>
                ),
              }}
            />
            <SeparatorComponent />
            <ButtonComponent to={{ pathname: '/', state: { crashed: false } }}>
              <FormattedMessage id="ERROR.GO_BACK_TO_HOME" />
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
