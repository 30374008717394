//@flow

import { length as lengthValidator, required as requiredValidator } from 'redux-form-validators';

export const required = (config /*::?: { if?: Function, message?: string }*/) => {
  return requiredValidator({
    message: 'VALIDATION.REQUIRED',
    ...config,
  });
};

export const length = (config /*::?: { max?: number, message?: any, min?: number }*/) => {
  return lengthValidator({
    message: 'VALIDATION.TOO_LONG',
    ...config,
  });
};
