// @flow
import React, { useCallback, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Sticky from 'react-stickynode';
import classNames from 'classnames';

import { checkPermissions } from 'app/utils';
import { ButtonComponent, CheckboxComponent, Typography } from 'app/shared';

import { CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { allSelected, onSelectAll } from '../virtualized/coupons-list.helpers';

/*:: import type { Props } from './coupons-list-header.component.types';*/
import styles from './coupons-list-header.module.scss';
import { CouponsListDisplayedVoucherDataFilter } from '../displayed-voucher-data-filter/coupons-list-displayed-voucher-data-filter';
// $FlowFixMe
import { ReactComponent as KebabMenu } from 'assets/images/kebab-menu.svg';
import { RenewHeaderComponent } from './renewHeader/renew-header.component';

export const CouponsListHeader = (props /*: Props*/) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const displayContinueToMassRenewButton = useCallback(
    () => (
      <div className="ml-md-3 ml-lg-4">
        <ButtonComponent className="mt-1" onClick={props.changeMode}>
          <i className="fi fi-renew" />
          <FormattedMessage id="COUPONS.RENEW.TITLE" />
        </ButtonComponent>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function getKebabStyle() {
    if (menuVisible) {
      return classNames(styles.kebabMenu, styles.kebabMenuSelected);
    }
    return styles.kebabMenu;
  }

  function toggleMenu() {
    setMenuVisible(!menuVisible);
  }

  const displayMassRenewButtons = useCallback(
    (onSubmit /*: () => void*/, selectedItemsLength /*: number*/, isFormValid /*: boolean*/) => (
      <div className={'md-flex justify-content-between'}>
        <ButtonComponent theme="primary-outline" className="mt-1 mx-md-4" onClick={props.changeMode}>
          <i className="fi fi-arrow-left" />
          <FormattedMessage id="CORE.BACK" />
        </ButtonComponent>
        <ButtonComponent
          data-name="renew-button"
          className="mt-1"
          onClick={onSubmit}
          disabled={!selectedItemsLength || !isFormValid}
        >
          <FormattedMessage id="COUPONS.RENEW.SUBMIT" values={{ total: selectedItemsLength }} />
        </ButtonComponent>
      </div>
    ),
    [props.changeMode],
  );

  const displayCreditVoucherButtons = useCallback(
    (selectedItemsLength /*: number*/, isFormValid /*: boolean*/) => {
      if (props.renewMode) {
        return displayMassRenewButtons(props.handleSubmit, selectedItemsLength, isFormValid);
      }

      return displayContinueToMassRenewButton();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.renewMode, props.handleSubmit],
  );

  const { noResults, renewMode } = props;

  return (
    <Sticky innerZ="11">
      <FormSpy
        subscription={{
          values: true,
          submitting: true,
          hasValidationErrors: true,
          hasSubmitErrors: true,
          dirtySinceLastSubmit: true,
        }}
      >
        {({ form, values = {}, submitting, hasValidationErrors, hasSubmitErrors, dirtySinceLastSubmit }) => {
          const { change } = form;
          const allItems = values.coupons.filter(coupon => (renewMode ? !coupon.invalidated : coupon));
          const selectedItems = allItems.filter(coupon => coupon.active).map(coupon => coupon.id);
          const isFormValid = !(!!hasValidationErrors || (!!hasSubmitErrors && !dirtySinceLastSubmit));
          const isAllChecked = allItems.length === selectedItems.length;

          return (
            <>
              <CardComponent
                wrapperRef={props.stickyHeaderRef}
                className={classNames('pb-2', styles.stickyHeader)}
                withShadow={props.sticky}
              >
                <CardHeaderComponent className={styles.headerInnerWrapper}>
                  <FormSpy subscription={{ values: true }}>
                    {({ values: { coupons } = {}, form: { change } }) => (
                      <CheckboxComponent
                        checked={allSelected(coupons, renewMode)}
                        onChange={onSelectAll(coupons, change)}
                        label={{ id: 'CORE.SELECT_ALL', additionalValues: { checked: isAllChecked } }}
                        className={styles.selectAllCheckbox}
                        labelPosition="right"
                      />
                    )}
                  </FormSpy>
                  <div className={styles.headerTittleWrapper}>
                    <Typography tag="h4" color="secondary">
                      <FormattedMessage
                        id="COUPONS.LIST.RENEW_LIST_TITLE"
                        values={{ selected: selectedItems.length, total: allItems.length }}
                      />
                    </Typography>
                  </div>
                  <div className={styles.headerButtonWrapper}>
                    {checkPermissions(props.permissions, ['serviceCoupons', 'update']) &&
                      !noResults &&
                      displayCreditVoucherButtons(selectedItems.length, isFormValid)}
                    <ButtonComponent
                      size="small"
                      theme="kebab-menu"
                      ariaLabel={'LOCALIZED_NAME_HERE'}
                      onClick={toggleMenu}
                    >
                      <KebabMenu className={getKebabStyle()} aria-label="SELECT_DISPLAYED_VOUCHER_DATA" />
                    </ButtonComponent>
                  </div>
                  {renewMode && (
                    <RenewHeaderComponent
                      change={change}
                      coupons={values.coupons}
                      intl={props.intl}
                      submitting={!!submitting}
                    />
                  )}
                </CardHeaderComponent>
              </CardComponent>
              {menuVisible && (
                <CouponsListDisplayedVoucherDataFilter
                  clickCallback={() => setMenuVisible(false)}
                  form={form}
                  change={change}
                  values={values}
                  submitting={submitting}
                  renewMode={renewMode}
                  showOnlySelectedToggle={props.showOnlySelectedToggle}
                  intl={props.intl}
                />
              )}
            </>
          );
        }}
      </FormSpy>
    </Sticky>
  );
};
