// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { ButtonComponent, ConnectedSelectComponent, SeparatorComponent, Typography } from 'app/shared';

import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';
import { VenuePriceListContainer } from 'app/register/shared';

/*:: import type { Props } from './contract.component.types';*/
import styles from './contract.module.scss';

export class ContractComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <>
        {this.props.showHeader && (
          <>
            <CardHeaderComponent justifyContent="space-between" className={styles.header}>
              <Typography tag="h4" color="secondary" type="largeText">
                {`#${this.props.index + 1} `}
                <FormattedMessage id="REGISTER.SERVICES" />
              </Typography>
              {this.props.isMoreThanOneContract && (
                <ButtonComponent
                  onClick={() => this.props.remove(this.props.index)}
                  theme="secondary-outline"
                  className="ml-4"
                >
                  <FormattedMessage id="REGISTER.CONTRACT.REMOVE" />
                </ButtonComponent>
              )}
            </CardHeaderComponent>

            <SeparatorComponent height={8} />
          </>
        )}

        <CardBodyComponent>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name={`${this.props.field}.venueId`}
                label="REGISTER.VENUE.SELECT"
                labelKey="nameExtra"
                component={ConnectedSelectComponent}
                options={this.props.venuesTypes}
                required
              />
            </div>
          </div>
        </CardBodyComponent>
        {this.props.venueId && <VenuePriceListContainer formName={this.props.form} fieldName={this.props.field} />}
      </>
    );
  }
}
