import { PostpayServiceVoucher } from 'v2/types/postpay';
import { Embedded, Links } from 'v2/utils/links';

export type SubmittablePostpayServiceVoucher = PostpayServiceVoucher & {
  _links: {
    [Links.PostpayServiceVoucher.InitializeServiceEvent]: NonNullable<
      PostpayServiceVoucher['_links'][typeof Links.PostpayServiceVoucher.InitializeServiceEvent]
    >;
  };
  _embedded: {
    [Embedded.PostpayServiceVoucher.ServiceEventTemplate]: NonNullable<
      PostpayServiceVoucher['_embedded'][typeof Embedded.PostpayServiceVoucher.ServiceEventTemplate]
    >;
  };
};

export const canSubmitServiceEvent = (voucher: PostpayServiceVoucher): voucher is SubmittablePostpayServiceVoucher =>
  Links.PostpayServiceVoucher.InitializeServiceEvent in voucher._links &&
  Embedded.PostpayServiceVoucher.ServiceEventTemplate in voucher._embedded;
