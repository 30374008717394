import { RSAA } from 'redux-api-middleware';
import { getFormValues } from 'redux-form';

import * as types from './types.action-types';

export const fetchCouponTypes = params => (dispatch, getState) => {
  const filters = getFormValues('filter-coupon-types')(getState());

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_COUPON_TYPES_REQUEST,
          meta: {
            params: {
              size: 10,
              ...filters,
              ...params,
            },
          },
        },
        {
          type: types.FETCH_COUPON_TYPES_SUCCESS,
        },
        types.FETCH_COUPON_TYPES_FAILURE,
      ],
      endpoint: '/service-coupon-types',
      method: 'GET',
    },
  });
};

export const fetchCouponType = type => ({
  [RSAA]: {
    types: [
      { type: types.FETCH_COUPON_TYPE_REQUEST, meta: { type } },
      { type: types.FETCH_COUPON_TYPE_SUCCESS, meta: { type } },
      types.FETCH_COUPON_TYPE_FAILURE,
    ],
    endpoint: `/service-coupon-types/${type}`,
    method: 'GET',
  },
});

export const addCouponType = body => ({
  [RSAA]: {
    types: [types.ADD_COUPON_TYPE_REQUEST, types.ADD_COUPON_TYPE_SUCCESS, types.ADD_COUPON_TYPE_FAILURE],
    endpoint: '/service-coupon-types',
    method: 'POST',
    body,
  },
});

export const editCouponType = (id, body) => ({
  [RSAA]: {
    types: [
      types.EDIT_COUPON_TYPE_REQUEST,
      { type: types.EDIT_COUPON_TYPE_SUCCESS, meta: { id } },
      types.EDIT_COUPON_TYPE_FAILURE,
    ],
    endpoint: `/service-coupon-types/${id}`,
    method: 'PATCH',
    body,
  },
});
