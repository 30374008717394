import { and, composePaths, ControlElement, isControl, rankWith, UISchemaElement } from '@jsonforms/core';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { DateRangeComponent } from 'app/shared';
import moment, { Moment } from 'moment';
import styles from 'v2/utils/jsonforms/date-range.module.scss';
import { vaanaJsonFormsControl } from './vaana-json-forms-control';

export const DateRangeRenderer = vaanaJsonFormsControl(
  ({ data, path, uischema, handleChange, enabled, label, required, errors }) => {
    const { startDatePropertyName, endDatePropertyName } = uischema.options || {};

    const startDate: string = data?.[startDatePropertyName];
    const endDate: string = data?.[endDatePropertyName];

    const updateStartDate = (date: Moment) =>
      handleChange(composePaths(path, startDatePropertyName), date.format(DATE_VALUE_FORMAT));

    const updateEndDate = (date: Moment) =>
      handleChange(composePaths(path, endDatePropertyName), date.format(DATE_VALUE_FORMAT));

    return (
      <DateRangeComponent
        disabled={!enabled}
        label={label}
        required={required}
        startDate={startDate}
        onStartDateChange={({ date }: { date: Moment }) => {
          updateStartDate(date);
          if (!endDate) {
            updateEndDate(date);
          } else {
            // keep same duration when changing start date
            const originalDuration = moment.duration(moment(endDate, DATE_VALUE_FORMAT).diff(startDate)).asDays();
            updateEndDate(date.add(originalDuration, 'days'));
          }
        }}
        endDate={endDate}
        onEndDateChange={({ date }: { date: Moment }) => {
          updateEndDate(date);
          if (!startDate) {
            updateStartDate(date);
          }
        }}
        invalid={!!errors}
        error={errors}
        className={styles.dateRange}
      />
    );
  },
  (_schema, uischema) => {
    const { startDatePropertyName, endDatePropertyName } = uischema.options as DateRangeControl['options'];
    return {
      relativeErrorPaths: ['', startDatePropertyName, endDatePropertyName],
    };
  },
);

export const dateRangeTester = rankWith(1001, and(isControl, isDateRangeControl));

type DateRangeControl = ControlElement & {
  options: ControlElement['options'] & {
    controlType: 'date-range';
    startDatePropertyName: string;
    endDatePropertyName: string;
  };
};
function isDateRangeControl(uischema: UISchemaElement): uischema is DateRangeControl {
  return (
    uischema.options?.controlType === 'date-range' &&
    !!uischema.options.startDatePropertyName &&
    !!uischema.options.endDatePropertyName
  );
}
