import { RSAA } from 'redux-api-middleware';
import { createAction } from 'redux-actions';

import * as types from './register.action-types';

export const register = (body, type) => ({
  [RSAA]: {
    types: [types.REGISTER_REQUEST, types.REGISTER_SUCCESS, types.REGISTER_FAILURE],
    endpoint: `/register/${type}`,
    method: 'POST',
    isPublic: true,
    body,
  },
});

export const registerValidate = (body, type) => ({
  [RSAA]: {
    types: [types.VALIDATE_REQUEST, types.VALIDATE_SUCCESS, types.VALIDATE_FAILURE],
    endpoint: `/register/${type}/validate`,
    method: 'POST',
    isPublic: true,
    body,
  },
});

export const fetchVenuesTypes = () => ({
  [RSAA]: {
    types: [types.FETCH_VENUE_TYPES_REQUEST, types.FETCH_VENUE_TYPES_SUCCESS, types.FETCH_VENUE_TYPES_FAILURE],
    endpoint: '/public/venues/municipal',
    method: 'GET',
    isPublic: true,
  },
});

export const fillValues = createAction(types.FILL_VALUES);
