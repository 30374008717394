// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';

import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './settlements-list-tile.component.types';*/

export class SettlementsListTileComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <h3>{this.props.tile.settlementId || '-'}</h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.DATE" tagName="small" />
            {this.props.tile.processedAt ? dateFormatter(this.props.tile.processedAt) : '-'}
          </div>
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.GROSS_AMOUNT" tagName="small" />
            {currencyFormatter.format(this.props.tile.amount)}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormattedMessage id="SETTLEMENTS.LIST.HEADERS.INTERVAL" tagName="small" />
            {this.props.tile.startDate && this.props.tile.endDate
              ? `${dateFormatter(this.props.tile.startDate)} - ${dateFormatter(this.props.tile.endDate)}`
              : '-'}
          </div>
        </div>
        <div className="buttons">
          <ButtonComponent onClick={() => this.props.onDownload(this.props.tile.id)} size="small">
            <FormattedMessage id="SETTLEMENTS.BREAKDOWN" />
          </ButtonComponent>
        </div>
      </TileComponent>
    );
  }
}
