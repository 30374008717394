import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization, withPermissions } from 'app/common';

import { fetchVenues } from 'app/redux/venues/venues.actions';

import { VenuesListComponent } from './venues-list.component';

const mapStateToProps = ({ venues: { list: venues } }) => ({
  venues,
  loading: !venues,
});

const mapDispatchToProps = dispatch => ({
  fetchVenues: () => dispatch(fetchVenues()),
});

export const VenuesListContainer = compose(
  withAuthorization(['venues', 'read']),
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
)(VenuesListComponent);
