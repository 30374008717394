import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withPermissions } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';

import { SidebarComponent } from './sidebar.component';

export const SidebarContainer = compose(withRouter, withCoreContext, injectIntl, withPermissions)(SidebarComponent);
