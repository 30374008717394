import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, touch } from 'redux-form';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import { withConfirmation } from 'app/common';
import { fieldsArrayNamesExtractor, scrollToElement } from 'app/utils';
import { debitAndRefreshVoucher } from 'app/redux/payments/payments.actions';
import { amountValidator, serviceProducedValidator } from 'app/coupons/paymentsValidation';

import { CouponsDetailsChargeFormComponent } from './coupons-details-charge-form.component';

const validatePayment =
  coupon =>
  ({ active, amount, descriptiveService, serviceProduced }) => {
    const paymentErrors = {};

    if (!active) {
      return paymentErrors;
    }

    if (!serviceProduced.startDate) {
      paymentErrors.serviceProduced = {
        startDate: 'VALIDATION.NOT_BLANK',
      };
    } else {
      const serviceProducedErrors = serviceProducedValidator({ coupon, fixExpiryDate: false })(serviceProduced);

      if (serviceProducedErrors) {
        paymentErrors.serviceProduced = serviceProducedErrors;
      }
    }

    if (coupon.descriptiveServices && !descriptiveService) {
      paymentErrors.descriptiveService = 'VALIDATION.NOT_BLANK';
    }

    const amountError = amountValidator(amount, {
      coupon,
      serviceProduced: serviceProduced,
      fixExpiryDate: false,
    });
    if (amountError) {
      paymentErrors.amount = amountError;
    }

    return paymentErrors;
  };

const validate = ({ payments, coupon }) => {
  if (payments) {
    return {
      payments: payments.map(validatePayment(coupon)),
    };
  }
  return {};
};

const getInitialValues = (coupon, dictionaries) => {
  return {
    payments: [
      {
        accountNumber: coupon['id'],
        active: true,
        amount: coupon['maxTransaction'],
        amountCalculations: [{ amount: 1 }],
        vatRate: get(dictionaries, ['municipal/services', 'vatRates', 0, 'rate']),
        serviceProduced: {},
      },
    ],
    coupon,
  };
};

const mapStateToProps = ({ dictionaries }, { coupon }) => ({
  coupon,
  initialValues: getInitialValues(coupon, dictionaries),
  validate,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body =>
    dispatch(
      debitAndRefreshVoucher({
        ...body,
        payments: body.payments.map(payment => ({
          ...payment,
          descriptiveService: get(payment.descriptiveService, ['id'], ''),
        })),
      }),
    ),
  onSubmitFail: (errors, dispatch, submitError, props) => {
    dispatch(touch(props.form, ...fieldsArrayNamesExtractor(errors)));
    scrollToElement(['.alert.alert-danger', 'form']);
  },
});

export const CouponsDetailsChargeFormContainer = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'charge-service-coupon',
  }),
  withConfirmation,
)(CouponsDetailsChargeFormComponent);
