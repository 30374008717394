//@flow
import React, { useContext } from 'react';
import get from 'lodash/get';

import { ConnectControl, DateRangeComponent } from 'app/shared';
import { dateRangeValidator, requiredDateRange } from 'app/utils';
import { CoreContext } from 'app/context';
import { useIntl } from 'app/hooks';
import { FieldWrapper } from '../utils/field-wrapper/field-wrapper.component';
import { onDateChange } from './helpers/date-change/connected-date-range-date-change';

/*:: import type { Props, WrapperProps } from './connected-date-range.component.types';*/

export const ConnectedDateRange = (props /*: Props*/) => {
  const { holidays } = useContext(CoreContext);
  const intl = useIntl();

  const { onFocusChange, input } = props;

  const startDateKey = props.startDateKey || DateRangeComponent.defaultProps.startDateKey;
  const endDateKey = props.endDateKey || DateRangeComponent.defaultProps.endDateKey;

  const handleOnBlur = () => {
    if (onFocusChange) {
      onFocusChange(false);
    }

    input.onBlur();
  };

  const handleOnFocus = () => {
    if (onFocusChange) {
      onFocusChange(true);
    }

    input.onFocus();
  };

  const startDate = get(input.value, startDateKey, null);
  const endDate = get(input.value, endDateKey, null);

  const commonProps = {
    onBlur: handleOnBlur,
    onFocus: handleOnFocus,
  };

  const startDateProps = {
    ...commonProps,
    disabled: props.startDateDisabled,
    selectedDate: startDate,
    onChange: ({ date, validationBounds }) =>
      onDateChange(startDateKey, date, { ...props, validationBounds, startDateKey, endDateKey }),
  };

  const endDateProps = {
    ...commonProps,
    disabled: props.endDateDisabled,
    selectedDate: endDate,
    onChange: ({ date, validationBounds }) =>
      onDateChange(endDateKey, date, { ...props, validationBounds, startDateKey, endDateKey }),
  };

  const datepickerProps = {
    ...props,
    holidays,
    intl,
    startDateProps,
    endDateProps,
    startDateKey,
    endDateKey,
  };

  return <ConnectControl component={DateRangeComponent} {...datepickerProps} />;
};

// $FlowFixMe
export const ConnectedDateRangeComponent = React.memo((props /*: WrapperProps*/) => {
  const validatorConfig = {
    startDateKey: props.startDateKey || DateRangeComponent.defaultProps.startDateKey,
    endDateKey: props.endDateKey || DateRangeComponent.defaultProps.endDateKey,
    if: () => props.required && !props.disabled,
  };

  const { validate = [] } = props;

  const validators = [dateRangeValidator(validatorConfig), requiredDateRange(validatorConfig), ...validate];

  return <FieldWrapper {...props} component={ConnectedDateRange} validate={validators} />;
});
