import {
  and,
  composePaths,
  ControlElement,
  createDefaultValue,
  isControl,
  Layout,
  rankWith,
  UISchemaElement,
} from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsArrayControlProps } from '@jsonforms/react';
import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { times } from 'lodash';
import { useEffect, useMemo } from 'react';
import styles from 'v2/utils/jsonforms/list-control.module.scss';

export const ListControlRenderer = withJsonFormsArrayControlProps(
  ({ addItem, path, schema, data, enabled, renderers, removeItems = () => () => null, uischema }) => {
    const addRow = useMemo(() => addItem(path, createDefaultValue(schema)), [addItem, path, schema]);
    const minItems = uischema.options?.minItems;

    useEffect(() => {
      if (!Array.isArray(data) || data.length < minItems) {
        times(minItems - (data || []).length, addRow);
      }
    }, [addRow, data, minItems]);

    return (
      <>
        {data &&
          Array.isArray(data) &&
          data.length > 0 &&
          data.map((_, index) => {
            const childPath = composePaths(path, index.toString());
            const rowsUiSchema = uischema.options?.detail as Layout;

            return (
              <div className={styles.item}>
                {rowsUiSchema.elements.map((childUiSchema, index) => {
                  return (
                    <ResolvedJsonFormsDispatch
                      renderers={renderers}
                      uischema={childUiSchema}
                      schema={schema}
                      path={childPath}
                      key={childPath + index}
                    />
                  );
                })}

                <div className={styles.removeItem}>
                  {data.length > minItems && (
                    <ButtonComponent
                      disabled={!enabled}
                      theme="primary-outline"
                      size="regular"
                      onClick={removeItems(path, [index])}
                    >
                      <FormattedMessage id="SERVICE_EVENTS.POSTPAY.SUBMISSION.REMOVE_ROW" />
                    </ButtonComponent>
                  )}
                </div>
              </div>
            );
          })}

        <ButtonComponent
          disabled={!enabled}
          className={styles.addRow}
          theme="primary-outline"
          size="regular"
          onClick={addRow}
        >
          <FormattedMessage id="SERVICE_EVENTS.POSTPAY.SUBMISSION.ADD_ROW" />
        </ButtonComponent>
      </>
    );
  },
);

export const listControlTester = rankWith(10001, and(isControl, isListControl));

type ListControl = ControlElement & {
  options: ControlElement['options'] & {
    controlType: 'list';
    minItems?: number;
  };
};
function isListControl(uischema: UISchemaElement): uischema is ListControl {
  return uischema.options?.controlType === 'list';
}
