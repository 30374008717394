import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import once from 'lodash/once';

import { withCoreContext } from 'app/context/core/core.context';
import { withRouter } from 'app/common/with-router/with-router.hoc';

import { addCouponType } from 'app/redux/types/types.actions';
import { lockBusinessChange, unlockBusinessChange } from 'app/redux/view/view.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { CouponsTypeFormComponent } from './coupons-type-form.component';

const mapStateToProps = ({
  customers: {
    entities: { current },
  },
  dictionaries,
}) => ({
  current,
  priceUnitOptions: dictionaries['price-units'],
  services: dictionaries['municipal/services'].services.municipal,
  descriptiveServices: dictionaries['municipal/services'].descriptiveServices,
});

const mapDispatchToProps = dispatch => ({
  onChange: once(() => dispatch(lockBusinessChange())),
  onSubmit: ({ descriptiveServices, ...body }, successHandler, failureHandler) => {
    return withFormSubmissionErrorHandling(
      dispatch(
        addCouponType({
          ...body,
          descriptiveServices: !!(descriptiveServices || []).length ? descriptiveServices : null,
        }),
      ),
      res => {
        successHandler && successHandler(res);
        dispatch(fetchDictionary('municipal/services', true, true));
      },
      failureHandler,
    );
  },
  onUnmount: () => dispatch(unlockBusinessChange()),
});

export const CouponsTypeFormContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  //withConfirmation, TODO
)(CouponsTypeFormComponent);
