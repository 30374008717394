// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new';
import { currencyFormatter } from 'app/utils';

import styles from './orders-confirmation-modal.module.scss';
/*:: import type { Props } from './orders-confirmation-modal.types';*/

export class OrdersConfirmationModalComponent extends PureComponent /*:: <Props>*/ {
  render() {
    const { amount, invoicing } = this.props.values;

    const commonTypographyProps = {
      type: 'smallText',
      color: 'grey',
    };

    return (
      <CardBodyComponent>
        <Typography type="largeText" tag="h4" id="ORDERS.MODAL.HEADER" />
        <SeparatorComponent />

        <div className="row">
          <div className="col-6">
            <Typography {...commonTypographyProps} id="ORDERS.INVOICING_METHOD" />
            <FormattedMessage id={`INVOICING_METHODS.${invoicing.invoicingMethod}`} />
            <SeparatorComponent />
          </div>
          <div className={classNames('col-6', styles.reference)}>
            <Typography {...commonTypographyProps} id="ORDERS.ORDER_REFERENCE" />
            {invoicing.invoiceReference}
            <SeparatorComponent />
          </div>

          <div className="col-6">
            <Typography {...commonTypographyProps} id="ORDERS.OFFICIAL_NAME" />
            {invoicing.invoiceName}
            <SeparatorComponent />
          </div>
          <div className="col-6">
            <Typography {...commonTypographyProps} id="ORDERS.EMAIL" />
            {invoicing.invoiceEmail}
            <SeparatorComponent />
          </div>

          {invoicing.invoicingMethod === 'EINVOICE' && (
            <>
              <div className="col-6">
                <Typography {...commonTypographyProps} id="ORDERS.DELIVERY_IDENTIFIER" />
                {invoicing.eInvoiceIdentifier}
                <SeparatorComponent />
              </div>
              <div className="col-6">
                <Typography {...commonTypographyProps} id="ORDERS.DELIVERY_ADDRESS" />
                {invoicing.eInvoiceAddress}
                <SeparatorComponent />
              </div>
            </>
          )}

          <div className="offset-6 col-6">
            <Typography {...commonTypographyProps} id="ORDERS.SERVICE_COUPON_EMONEY" />
            <Typography color="success" type="largeTextBold">
              {currencyFormatter.format(amount)}
            </Typography>
            <SeparatorComponent />
          </div>
        </div>
      </CardBodyComponent>
    );
  }
}
