// @flow

import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { SelectionControlBase } from 'app/shared';
import { INPUT_PROPS_OMIT_KEYS } from 'app/constants';

/*:: import type { RadioComponentProps, RadioProps } from './radio.component.types';*/
import styles from './radio.module.scss';

export const Radio = (props /*: RadioProps*/) => {
  const { className, ...otherProps } = props;

  const inputProps = {
    ...omit(otherProps, INPUT_PROPS_OMIT_KEYS),
    className: classNames(styles.input, className),
    type: 'radio',
  };

  return (
    <>
      <input {...inputProps} />
      <span className={classNames(styles.radio)}>
        <span className={styles.checked} />
      </span>
    </>
  );
};

export const RadioComponent = (props /*: RadioComponentProps*/) => (
  <SelectionControlBase component={Radio} {...props} />
);
