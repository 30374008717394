import { VenueId } from 'v2/types/venue';
import { FieldValue as PeriodComponentFieldValue } from './period.component';
import { ReactElement } from 'react';
import { HousingServicesVoucher } from './types/housing-services-voucher';
import { HousingServicesProducedService } from './service-types';
import { FormikProps } from 'formik';
import { Transaction } from 'v2/types/transaction';

export interface ServiceEventRow {
  producedService: HousingServicesProducedService;
  transactionReference: string;
  period: PeriodComponentFieldValue;
}

export type SelectedMonthFieldValue = {
  month: number;
  year: number;
};

export interface ServiceEventFormValues {
  selectedMonth: SelectedMonthFieldValue;
  serviceEventRows: ServiceEventRow[];
}

export type ValidateServiceEventVoucher = Pick<
  HousingServicesVoucher,
  'unitCost' | 'priceDetails' | 'balance' | 'firstAllowedDateOfService' | 'lastAllowedDateOfService' | 'serviceArea'
>;

export type ServiceEventValidator = (
  values: ServiceEventFormValues,
  voucher: ValidateServiceEventVoucher,
  venueId: VenueId,
  transactions?: Transaction[],
) => Promise<ServiceEventValidationErrors | void>;

// General validation errors are not field-specific but errors on the service event in general
// f.ex. target period has not been exhaustively covered with provided services
// or available funds on service voucher cannot cover the service event.
export enum GeneralValidationError {
  TargetPeriodNotCovered = 'target-period-not-covered',
  InsufficientBalance = 'insufficient-balance',
}

export enum SelectedMonthError {
  OutsideVoucherValidityPeriod = 'outside-voucher-validity-period',
  MonthAlreadyDebited = 'month-already-debited',
}

export enum TransactionReferenceError {
  InvalidTransactionReference = 'invalid-transaction-reference',
}

export enum EndDateError {
  UndefinedValue = 'undefined-value',
  OutsideServiceValidityPeriod = 'outside-voucher-validity-period',
}

export enum ProducedServiceValidationError {
  NoConsecutiveRowsWithSameService = 'no-consecutive-rows-with-same-service',
  ExceededMaxDaysOfPaidAbsence = 'exceeded-max-days-of-paid-absence',
}

export interface ServiceEventRowValidationErrors {
  transactionReference?: TransactionReferenceError[];
  period?: {
    endDate: EndDateError[];
  };
  producedService?: ProducedServiceValidationError[];
}

export interface ServiceEventValidationErrors {
  general?: GeneralValidationError[];
  selectedMonth?: SelectedMonthError[];
  serviceEventRows?: ServiceEventRowValidationErrors[];
}

export interface ServiceEventSubmissionCardComponentProps {
  voucher: HousingServicesVoucher;
  venueId: VenueId;
  cardHeaderButton?: ReactElement;
  transactions?: Transaction[];
}

export declare type ServiceEventSubmissionComponentProps = FormikProps<ServiceEventFormValues> & {
  voucher: HousingServicesVoucher;
  venueId: VenueId;
};
