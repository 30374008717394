import { useDispatch } from 'react-redux';
import { PostpayVoucherType } from 'v2/types/postpay';
import { ActionButton } from 'v2/utils/postpay/action-button';
import { TabularItemListComponent } from 'v2/utils/postpay/tabular-item-list';
import { actions as serviceVoucherActions } from 'v2/service-voucher/postpay/service-vouchers-slice';

type VoucherTypeListComponentProps = {
  voucherTypes: PostpayVoucherType[];
};

export const VoucherTypeListComponent: React.FC<VoucherTypeListComponentProps> = ({ voucherTypes }) => {
  const dispatch = useDispatch();
  const createVoucherFromType = (voucherTypeId: string) => {
    dispatch(serviceVoucherActions.voucherCreationRequested({ voucherTypeId }));
  };

  const SelectVoucherTypeButton = (item: PostpayVoucherType) => (
    <ActionButton action={() => createVoucherFromType(item.id)}></ActionButton>
  );

  return (
    <TabularItemListComponent
      items={voucherTypes}
      getItemProperties={item => item.properties}
      renderActions={SelectVoucherTypeButton}
    />
  );
};
