import { SelectedMonthFieldValue, ServiceEventFormValues, ServiceEventRow } from './types';
import moment from 'moment';
import { DATE_VALUE_FORMAT } from 'app/constants';
import {
  calculateLastAllowedServiceEventRowEndDate,
  getPeriodsOfPaidAbences,
  validateSelectedMonth,
} from './backend-services/validate-service-event';
import { head, last } from 'lodash';
import {
  getHousingServiceProducedServiceRule,
  housingServicesPaidAbsenceService,
  HousingServicesProducedService,
  ProducedServiceDuration,
  ServiceArea,
} from './service-types';
import { FieldValue as PeriodComponentFieldValue } from './period.component';
import { ServiceEventRowVoucher } from './service-event-row.component';

function computeInitialMonth(voucher: {
  firstAllowedDateOfService: string;
  lastAllowedDateOfService: string;
}): SelectedMonthFieldValue {
  const firstAllowedDateOfService = moment(voucher.firstAllowedDateOfService, DATE_VALUE_FORMAT);
  const previousMonth = moment().subtract(1, 'month');
  const firstValidMonth = moment(firstAllowedDateOfService);
  const selection = validateSelectedMonth({ month: previousMonth.month(), year: previousMonth.year() }, voucher)
    ? firstValidMonth
    : previousMonth;
  return {
    month: selection.month(),
    year: selection.year(),
  };
}

function computeStartDate(
  selectedMonth: { month: number; year: number },
  voucher: { firstAllowedDateOfService: string; lastAllowedDateOfService: string },
): Date {
  const firstDayOfMonth = moment().month(selectedMonth.month).year(selectedMonth.year).date(1);
  const validityStartDate = moment(voucher.firstAllowedDateOfService, DATE_VALUE_FORMAT);
  const startDate = validityStartDate.isAfter(firstDayOfMonth) ? validityStartDate : firstDayOfMonth;
  return new Date(startDate.format(DATE_VALUE_FORMAT));
}

function computeInitialRowStartDate(
  selectedMonth: SelectedMonthFieldValue,
  voucher: { firstAllowedDateOfService: string; lastAllowedDateOfService: string },
): Date | undefined {
  return !validateSelectedMonth(selectedMonth, voucher) ? computeStartDate(selectedMonth, voucher) : undefined;
}

export function isServiceEndDateFixed(
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService | '',
): boolean {
  if (!producedService) return false;

  const producedServiceRule = getHousingServiceProducedServiceRule(serviceArea, producedService);
  if (producedServiceRule.serviceDuration === ProducedServiceDuration.SingleDay) {
    return true;
  } else {
    return false;
  }
}

export function computeRowEndDate(
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService | '',
  period: PeriodComponentFieldValue,
): Date | undefined {
  if (!period.startDate || !producedService) {
    return undefined;
  }

  const producedServiceRule = getHousingServiceProducedServiceRule(serviceArea, producedService);
  if (!producedServiceRule) {
    return undefined;
  }

  switch (producedServiceRule.serviceDuration) {
    case ProducedServiceDuration.SingleDay:
      return period.startDate;

    case ProducedServiceDuration.MaxAmountOfDays:
      const maxEndDate = moment(period.startDate).add(producedServiceRule.maxAmountOfDays - 1, 'days');
      if (period.endDate && moment(period.endDate).isSameOrAfter(maxEndDate)) {
        return maxEndDate.toDate();
      } else {
        return period.endDate;
      }

    default:
      return undefined;
  }
}

function computeRowStartDateFromPreviousRow(previousRow: ServiceEventRow): Date | undefined {
  const endDateOfPreviousRow: Date | undefined = previousRow.period.endDate;
  return endDateOfPreviousRow && moment(endDateOfPreviousRow).add(1, 'day').toDate();
}

export function computeNextServiceEventRow(
  existingRows: ServiceEventRow[],
  selectedMonth: SelectedMonthFieldValue,
  voucher: {
    firstAllowedDateOfService: string;
    lastAllowedDateOfService: string;
    descriptiveServices: HousingServicesProducedService[];
    serviceArea: ServiceArea;
  },
): ServiceEventRow {
  const previousRow: ServiceEventRow | undefined = last(existingRows);
  const startDate = previousRow
    ? computeRowStartDateFromPreviousRow(previousRow)
    : computeInitialRowStartDate(selectedMonth, voucher);
  const producedService = head(voucher.descriptiveServices);

  if (!producedService) {
    throw new Error('No descriptive services were provided');
  }

  return {
    producedService,
    transactionReference: previousRow ? previousRow.transactionReference : '',
    period: {
      startDate,
      endDate: computeRowEndDate(voucher.serviceArea, producedService, { startDate }),
    },
  };
}

export function computeInitialValues(
  voucher: {
    firstAllowedDateOfService: string;
    lastAllowedDateOfService: string;
    descriptiveServices: HousingServicesProducedService[];
    serviceArea: ServiceArea;
  },
  selectedMonth?: SelectedMonthFieldValue,
): ServiceEventFormValues {
  const initialMonth = selectedMonth ? selectedMonth : computeInitialMonth(voucher);
  return {
    serviceEventRows: [computeNextServiceEventRow([], initialMonth, voucher)],
    selectedMonth: initialMonth,
  };
}

export function createCalculateMaxEndDateForRow(index: number, rows: ServiceEventRow[]) {
  return (
    startDate: Date | undefined,
    voucher: ServiceEventRowVoucher,
    producedService?: HousingServicesProducedService,
  ): Date => {
    const maxEndDate = calculateLastAllowedServiceEventRowEndDate(startDate, voucher, producedService);
    if (producedService && housingServicesPaidAbsenceService.includes(producedService)) {
      const currentPaidAbsencePeriodDuration = last(getPeriodsOfPaidAbences(rows.slice(0, -1)))?.duration || 0;
      return moment(maxEndDate).subtract(currentPaidAbsencePeriodDuration, 'days').toDate();
    }
    return maxEndDate;
  };
}
