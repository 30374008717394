// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CouponsDownloadPdfFileContainer } from 'app/coupons/downloads/pdf-file/coupons-downloads-pdf-file.container';
import { SidebarContainer } from 'app/shared/sidebar/sidebar.container';

/*:: import type { Props } from './coupon-details-sidebar.types';*/

export const CouponDetailsSidebarComponent = (props /*: Props*/) => {
  return (
    <SidebarContainer
      name="coupons-downloads"
      closeLabel={<FormattedMessage id="COUPONS.SIDEBAR.BACK" />}
      tabs={[
        {
          label: <FormattedMessage id="COUPONS.DETAILS.SIDEBAR.PRINT" />,
          icon: 'fi-printer pl-1',
          component: CouponsDownloadPdfFileContainer,
          disabled: props.loading,
          props: !props.loading && {
            coupons: [props.coupon.id],
            transfers: props.coupon.transfers,
          },
        },
      ]}
      animation
    />
  );
};
