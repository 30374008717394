//@flow
import React from 'react';

import { businessName } from 'app/utils';
import { NoResultsContainer } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { VenueMunicipalityContractContainer } from './municipality-contract/venue-municipality-contract.container';

/*:: import type { Props } from './venue-municipality.component.types';*/

export const VenueMunicipalityComponent = (props /*: Props*/) => {
  const { name, nameExtra, filteredContracts, id } = props;

  const municipalityName = businessName({ name, nameExtra });

  if (!filteredContracts.length) {
    return (
      <CardComponent>
        <CardBodyComponent>
          <NoResultsContainer desc="VENUES.NO_PRICES" />
        </CardBodyComponent>
      </CardComponent>
    );
  }

  // prettier-ignore
  return filteredContracts.map/*:: <React$Node>*/((contract) => {
    const key = `municipalities-${id}-contracts-${contract.id}`;

    return (
      <VenueMunicipalityContractContainer
        key={key}
        form={key}
        municipalityName={municipalityName}
        contract={contract}
      />
    );
    });
};
