// @flow

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import validators from 'redux-form-validators';

import { configuration } from 'configuration';
import { PageHeaderComponent, ThemeComponent } from 'app/core';
import { AlertComponent, ButtonComponent, CardComponent, ConnectedInputComponent } from 'app/shared';
import { SUPPORTED_LOCALES } from 'app/constants';
import { composeValidators, currencyFormatter, length, required } from 'app/utils';
import { CouponsCheckBalanceTransactionsComponent } from './transactions/coupons-check-balance-transactions.component';
import { CouponsCheckBalanceVenueSearchNavigation } from './venuesearch-navigation/coupons-check-balance-venuesearch-navigation.component';

/*:: import type { Props } from './coupons-check-balance.component.types';*/

validators.formatMessage = ({ id, values: { count } = {} }) => {
  const msg = { id };
  if (count) {
    return { ...msg, values: { number: count } };
  }
  return msg;
};

const isSupportedLocale = (locale /*: ?string*/) /*: boolean %checks*/ => {
  return typeof locale === 'string' && SUPPORTED_LOCALES.includes(locale);
};

export const CouponsCheckBalanceComponent = (props /*: Props*/) => {
  const { checked, onSubmit, queryLocale, locale, updateLocale } = props;

  useEffect(
    () => {
      if (isSupportedLocale(queryLocale) && queryLocale !== locale) {
        updateLocale(queryLocale);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryLocale],
  );

  return (
    <ThemeComponent theme="vaana" termsTo="/coupons/terms">
      <section className="container">
        <PageHeaderComponent title="MODULES.CPS.CHECK_BALANCE.TITLE" showBusinessName={false}>
          <FormattedMessage id="MODULES.CPS.CHECK_BALANCE.NOTE" tagName="p" />
        </PageHeaderComponent>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, submitSucceeded, submitFailed, submitError }) => (
            <form onSubmit={handleSubmit}>
              <CardComponent>
                {submitFailed && submitError && (
                  <div className="card-header">
                    <AlertComponent type="failure">
                      <FormattedMessage id={submitError} />
                    </AlertComponent>
                  </div>
                )}
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field
                        name="decisionNumber"
                        component={ConnectedInputComponent}
                        label="MODULES.CPS.CHECK_BALANCE.DECISION_NUMBER"
                        placeholder="MODULES.CPS.CHECK_BALANCE.DECISION_NUMBER"
                        required
                        validate={required()}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="code"
                        component={ConnectedInputComponent}
                        mask={{
                          mask: '999999999999',
                          maskChar: '',
                        }}
                        label="MODULES.CPS.CHECK_BALANCE.CODE"
                        placeholder="MODULES.CPS.CHECK_BALANCE.CODE"
                        required
                        validate={composeValidators(
                          required(),
                          length({
                            min: 10,
                            message: { id: 'VALIDATION.DIGIT_LENGTH', values: { number: 10 } },
                          }),
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <ButtonComponent theme="primary" loading={submitting}>
                    <FormattedMessage id="MODULES.CPS.CHECK_BALANCE.SUBMIT" />
                  </ButtonComponent>
                </div>
              </CardComponent>

              {submitSucceeded && checked && (
                <>
                  <CardComponent>
                    <div className="card-header card-header--between">
                      <AlertComponent type="success">
                        <FormattedMessage
                          id="MODULES.CPS.CHECK_BALANCE.BALANCE"
                          values={{ balance: <strong>{currencyFormatter.format(checked.balance)}</strong> }}
                        />
                      </AlertComponent>
                    </div>
                  </CardComponent>
                  <CardComponent>
                    <div className="col text-right">
                      <CouponsCheckBalanceVenueSearchNavigation
                        serviceVoucherId={checked.serviceVoucherId}
                        locale={locale}
                        venueSearchHost={configuration.venueSearchHost}
                      />
                    </div>
                  </CardComponent>
                  <CouponsCheckBalanceTransactionsComponent transactions={checked.transactions} />
                </>
              )}
            </form>
          )}
        </Form>
      </section>
    </ThemeComponent>
  );
};
