import { and, isControl, isDateControl, rankWith } from '@jsonforms/core';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { DatePickerComponent } from 'app/shared';
import moment, { Moment } from 'moment';
import { vaanaJsonFormsControl } from './vaana-json-forms-control';

export const DateControlRenderer = vaanaJsonFormsControl(
  ({ data, label, required, handleChange, path, enabled, errors }) => {
    return (
      <DatePickerComponent
        disabled={!enabled}
        label={label}
        required={required}
        selectedDate={moment(data)}
        onChange={({ date }: { date: Moment }) => {
          handleChange(path, date.format(DATE_VALUE_FORMAT));
        }}
        invalid={!!errors}
        error={errors}
      />
    );
  },
);

export const dateControlTester = rankWith(1001, and(isControl, isDateControl));
