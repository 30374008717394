import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { CouponsTypeFormModalComponent } from './coupons-type-form-modal.component';

const mapStateToProps = ({ form, dictionaries }) => ({
  services: dictionaries['municipal/services'].services.municipal,
  descriptiveServices: dictionaries['municipal/services'].descriptiveServices,
});

export const CouponsTypeFormModalContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(CouponsTypeFormModalComponent);
