import { PayloadAction } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { GetVouchersResponse, PostpayApiClient } from 'v2/api/postpay-api-client';
import { actions, IssuedServiceVouchersRequestedPayload } from './issued-service-vouchers-slice';

export function* issuedServiceVouchersSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function* issuedServiceVouchersRequested(action: PayloadAction<IssuedServiceVouchersRequestedPayload>) {
    const { accountId } = action.payload;
    const response: GetVouchersResponse = yield call(apiClient.getVouchers, accountId);
    switch (response.status) {
      case StatusCodes.OK:
        const { data: vouchers } = response;
        yield put(
          actions.issuedServiceVouchersRequestCompleted({
            accountId,
            vouchers,
          }),
        );
    }
  }

  yield takeLatest(actions.issuedServiceVouchersRequested, issuedServiceVouchersRequested);
}
