//@flow

import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { useSelector } from 'app/hooks/redux';

import { ConnectedInputComponent, ConnectedSelectComponent, FieldValueRenderer } from 'app/shared/form';
import { generateTimeRange } from 'app/utils/dates';

import styles from './price-list-table-sub-header.module.scss';
import { PriceListTableCellComponent } from '../price-list-table-cell.component';
/*:: import type { Props } from './price-list-table-sub-header.component.types';*/

const options = generateTimeRange({ step: 30 });

export const PriceListTableSubHeader = (props /*: Props*/) => {
  const { editMode, subtitle, editable, name, showDiff, form } = props;

  const tableCellField = formValueSelector(form)(
    useSelector(state => state),
    name,
  );

  if (subtitle instanceof Object) {
    return (
      <div className={styles.hoursWrapper}>
        <Field
          component={editable ? ConnectedSelectComponent : FieldValueRenderer}
          name={`${name}.subtitle.from`}
          options={options}
          disabled={!editMode}
        />
        -
        <Field
          component={editable ? ConnectedSelectComponent : FieldValueRenderer}
          name={`${name}.subtitle.to`}
          options={options}
          disabled={!editMode}
        />
      </div>
    );
  }

  return (
    <Field
      name={`${name}.subtitle`}
      component={editable ? ConnectedInputComponent : FieldValueRenderer}
      disabled={!editMode}
      {...(!editable
        ? {
            wrapper: () => (
              <PriceListTableCellComponent
                tableCellField={{ ...tableCellField, value: subtitle }}
                showDiff={showDiff}
              />
            ),
          }
        : {})}
    />
  );
};
