import { VenueId } from '../../types/venue';
import { FunctionComponent } from 'react';
import { useField } from 'formik';
import { ServiceEventRow } from './types';
import { computeServicePriceForRow, ServicePriceNotComputable } from './backend-services/compute-service-price';
import { Label } from 'app/shared/form/controls-elements/label/label.component';
import styles from './amount.component.module.scss';
import { CompensationLevel } from './service-types';
import { HousingServicesVoucher } from './types/housing-services-voucher';

interface AmountComponentProps {
  voucher: AmountComponentVoucher;
  venueId: VenueId;
  name: string;
}

type AmountComponentVoucher = Pick<HousingServicesVoucher, 'unitCost' | 'priceDetails' | 'serviceArea'>;

interface AmountComponentProps {
  voucher: AmountComponentVoucher;
  venueId: VenueId;
  name: string;
}

export const Amount: FunctionComponent<AmountComponentProps> = props => {
  const [field] = useField<ServiceEventRow>(props.name);

  const venueId: string = props.venueId;
  const serviceEventRow: ServiceEventRow = field.value;

  const renderFormula = (
    serviceEventRow: ServiceEventRow,
    voucher: AmountComponentVoucher,
    venueId: string,
  ): [string, string] => {
    const computedServicePrice = computeServicePriceForRow(serviceEventRow, voucher, venueId);

    if (computedServicePrice === ServicePriceNotComputable) {
      return ['', ''];
    }

    const numberOfDays = computedServicePrice.numberOfDays;

    const costInBrackets: string | undefined =
      computedServicePrice.compensationLevel === CompensationLevel.FullCompensation
        ? `${voucher.unitCost}€`
        : computedServicePrice.compensationLevel === CompensationLevel.CompensationWithoutMeal
        ? computedServicePrice.mealPriceInEurCent
          ? `(${voucher.unitCost}€ - ${computedServicePrice.mealPriceInEurCent / 100}€)`
          : undefined
        : undefined;

    return costInBrackets
      ? [`${numberOfDays} x ${costInBrackets} = `, `${computedServicePrice.amount}€`]
      : [`${numberOfDays} x 0€ = `, `${computedServicePrice.amount}€`];
  };

  const [formula, result] = renderFormula(serviceEventRow, props.voucher, venueId);

  return (
    <>
      <Label label="COUPONS.DETAILS.CHARGE.AMOUNT" />
      <div className={styles.wrapper}>
        <span title="Amount" className={styles.value} role="heading" aria-level={4}>
          {formula}
          <b>{result}</b>
        </span>
      </div>
    </>
  );
};
