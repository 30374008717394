//@flow
import { isValidElement } from 'react';
import isObject from 'lodash/isObject';

/*:: import type { Config } from './translate.util.types';*/

// TODO need to create polymorphic typing for translate function
/*:: type ReturnedType = any*/
//{ children: any } | string | { id: string, values?: { [string]: any } };

export const translate = ({ intl, translation } /*: Config*/, asChildren /*: boolean*/ = false) /*: ReturnedType*/ => {
  if (intl && intl.formatMessage && typeof intl.formatMessage !== 'function') {
    throw new Error('Intl object is incorrect!');
  }

  if (isValidElement(translation)) {
    return asChildren ? { children: translation } : translation;
  }

  if (isObject(translation) && translation.id) {
    // $FlowFixMe
    const { id } = translation;
    // $FlowFixMe
    const values = { ...translation.values, ...translation.additionalValues };
    // $FlowFixMe
    return intl ? intl.formatMessage({ id }, { values }) : { id, ...(Object.keys(values).length ? { values } : {}) };
  }

  if (typeof translation === 'string') {
    if (/^([A-Z0-9_]+\.)([A-Z0-9_]+\.?)+[^.]$/.test(translation)) {
      return intl ? intl.formatMessage({ id: translation }) : { id: translation };
    } else {
      return asChildren ? { children: translation } : translation;
    }
  }

  throw new Error('Translation util fail!');
};
