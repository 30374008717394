//@flow

import React from 'react';
import classNames from 'classnames';

import { InputBaseControl, PortalComponent } from 'app/shared';
import { DatePicker } from 'app/shared/form/controls';
import { DateRangeDaysCounter } from './days-counter/date-range-days-counter.component';

/*:: import type { Props } from './date-range.component.types';*/
import styles from './date-range.module.scss';
import { convertDate } from 'app/shared/form/controls/datepicker/datepicker-helpers';

export const DateRange = (props /*: Props*/) => {
  const { startDateKey, endDateKey, disabled, id, startDateProps = {}, endDateProps = {}, bounds = {} } = props;

  const startDate = props.startDate || startDateProps.selectedDate || null;
  const endDate = props.endDate || endDateProps.selectedDate || null;
  const onStartDateChange = props.onStartDateChange || startDateProps.onChange;
  const onEndDateChange = props.onEndDateChange || endDateProps.onChange;

  const startDateInputId = `${props.id}`;
  const endDateInputId = `${props.id}_end`;

  const inputProps = {
    disabled,
    startDate: convertDate(startDate), // used for highlighting of selected date range
    endDate: convertDate(endDate), // used for highlighting of selected date range
  };

  const startPickerProps = {
    ...inputProps,
    bounds,
    className: classNames(styles.startInput, `${id}-${startDateKey}`),
    selectedDate: startDate,
    id: startDateInputId,
    placeholder: 'CORE.START_DATE',
    ...startDateProps,
    onChange: onStartDateChange,
  };

  const endPickerProps = {
    ...inputProps,
    bounds: {
      ...bounds,
      ...(startDate || bounds.minDate ? { minDate: startDate || bounds.minDate } : {}),
    },
    validationBounds: {
      // override validation bounds, to have correct validation when default start date is specified
      ...(bounds.minDate ? { minDate: bounds.minDate } : {}),
    },
    className: classNames(styles.endInput, `${id}-${endDateKey}`),
    selectedDate: endDate,
    id: endDateInputId,
    placeholder: 'CORE.END_DATE',
    ...endDateProps,
    onChange: onEndDateChange,
  };

  return (
    <>
      <PortalComponent component={props.labelRef}>
        <DateRangeDaysCounter {...props} />
      </PortalComponent>
      <DatePicker selectsStart {...startPickerProps} />
      <i className={classNames('fi fi-right-arrow-forward', styles.inputSeparator)} />
      <DatePicker selectsEnd {...endPickerProps} />
    </>
  );
};

export const DateRangeComponent = (props /*: Props*/) => (
  <InputBaseControl icon="time" {...props} component={DateRange} />
);

DateRangeComponent.defaultProps = {
  endDateKey: 'endDate',
  selectableMonthAndYear: true,
  startDateKey: 'startDate',
};
