import {
  reducer as availableUsecasesReducer,
  StateSlice as AvailableUsecasesStateSlice,
} from './organizational-unit/available-usecases-slice';
import {
  reducer as housingServiceEventReducer,
  StateSlice as HousingServicesEventStateSlice,
} from './service-events/housing-service/service-event-slice';
import {
  reducer as postpayServiceEventReducer,
  StateSlice as PostpayServicesEventStateSlice,
} from './service-events/postpay/service-event-slice';
import {
  reducer as serviceVoucherServiceEventsReducer,
  StateSlice as ServiceVoucherServiceEventsStateSlice,
} from './service-voucher/postpay/service-voucher-service-events-slice';
import {
  reducer as serviceVouchersReducer,
  StateSlice as ServiceVouchersStateSlice,
} from './service-voucher/postpay/service-vouchers-slice';
import {
  reducer as voucherTypeReducer,
  StateSlice as VoucherTypeStateSlice,
} from './voucher-type/postpay/voucher-type-slice';
import {
  reducer as voucherTypesReducer,
  StateSlice as VoucherTypesStateSlice,
} from './voucher-type/postpay/voucher-types-slice';
import {
  reducer as printServiceVouchersReducer,
  StateSlice as PrintServiceVouchersStateSlice,
} from './service-voucher/postpay/print-service-vouchers-slice';

import {
  reducer as issuedServiceVouchersReducer,
  StateSlice as IssuedServiceVouchersStateSlice,
} from './service-voucher/postpay/search/issued-service-vouchers-slice';

export type V2RootState = ServiceVouchersStateSlice &
  HousingServicesEventStateSlice &
  PostpayServicesEventStateSlice &
  ServiceVoucherServiceEventsStateSlice &
  VoucherTypesStateSlice &
  AvailableUsecasesStateSlice &
  VoucherTypeStateSlice &
  PrintServiceVouchersStateSlice &
  IssuedServiceVouchersStateSlice;

export const v2Reducers = {
  ...serviceVouchersReducer,
  ...housingServiceEventReducer,
  ...postpayServiceEventReducer,
  ...serviceVoucherServiceEventsReducer,
  ...voucherTypesReducer,
  ...availableUsecasesReducer,
  ...voucherTypeReducer,
  ...printServiceVouchersReducer,
  ...issuedServiceVouchersReducer,
};
