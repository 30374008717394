import { ServiceVoucherLoaded, ServiceVoucherResult, ServiceVoucherStatus } from '../service-vouchers-slice';

export function isServiceVoucherLoaded(
  serviceVoucherResult: ServiceVoucherResult,
): serviceVoucherResult is ServiceVoucherLoaded {
  return (
    serviceVoucherResult &&
    (serviceVoucherResult.status === ServiceVoucherStatus.Ok ||
      serviceVoucherResult.status === ServiceVoucherStatus.Refreshing)
  );
}
