// @flow

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import detectHover from 'detect-hover';
import classNames from 'classnames';

import { TooltipComponent } from 'app/shared';

/*:: import type { Props } from './sidebar-item.component.types';*/
import styles from './sidebar-item.module.scss';
import { ExternalLink } from '../../../../shared';

export class SidebarItemComponent extends Component /*:: <Props>*/ {
  render() {
    const itemClassNames = classNames(styles.item, this.props.className, {
      [styles.collapsed]: this.props.sidebarCollapsed || this.props.isCollapsed,
    });

    const anchorClassNames = classNames(styles.link, {
      [styles.active]: this.props.isActive instanceof Function && this.props.isActive(),
    });

    const badgeClassNames = classNames(styles.badge, {
      [styles.right]: this.props.badgeRight,
    });

    const item = (
      <li className={itemClassNames} ref={this.props.liRef}>
        {!!this.props.to && (
          <NavLink to={this.props.to} className={styles.link} activeClassName={styles.active} exact={this.props.exact}>
            <i className={`fi fi-${this.props.icon}`} />
            <span>{this.props.name}</span>
            {!!this.props.badge && <div className={badgeClassNames}>{this.props.badge}</div>}
          </NavLink>
        )}

        {!!this.props.extTo && !this.props.to && (
          <ExternalLink href={this.props.extTo} className={styles.link}>
            <i className={`fi fi-${this.props.icon}`} />
            <span>{this.props.name}</span>
            {!!this.props.badge && <div className={badgeClassNames}>{this.props.badge}</div>}
          </ExternalLink>
        )}

        {!!this.props.onClick && (
          <button onClick={this.props.onClick} className={anchorClassNames}>
            <i className={`fi fi-${this.props.icon}`} />
            <span>{this.props.name}</span>
            {!!this.props.badge && <div className={badgeClassNames}>{this.props.badge}</div>}
          </button>
        )}
      </li>
    );

    if (detectHover.anyHover && (this.props.sidebarCollapsed || this.props.isCollapsed)) {
      return (
        <TooltipComponent
          overlay={this.props.name}
          trigger={['hover']}
          placement="right"
          getTooltipContainer={this.props.container ? () => this.props.container : undefined}
        >
          {item}
        </TooltipComponent>
      );
    }

    return item;
  }
}
