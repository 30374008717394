import { RSAA } from 'redux-api-middleware';

import * as types from './orders.action-types';

export const createOrder = body => ({
  [RSAA]: {
    types: [types.CREATE_ORDER_REQUEST, types.CREATE_ORDER_SUCCESS, types.CREATE_ORDER_FAILURE],
    endpoint: '/orders/municipal',
    method: 'POST',
    body,
  },
});

export const confirmOrder = (id, context, lang) => ({
  [RSAA]: {
    types: [
      {
        type: types.CONFIRM_ORDER_REQUEST,
        meta: {
          params: {
            context,
            lang,
          },
        },
      },
      types.CONFIRM_ORDER_SUCCESS,
      types.CONFIRM_ORDER_FAILURE,
    ],
    endpoint: `/orders/${id}/confirm`,
    method: 'POST',
  },
});

export const cancelOrder = id => ({
  [RSAA]: {
    types: [
      {
        type: types.CANCEL_ORDER_REQUEST,
        meta: { order: Number(id) },
      },
      {
        type: types.CANCEL_ORDER_SUCCESS,
        meta: { order: Number(id) },
      },
      {
        type: types.CANCEL_ORDER_FAILURE,
        meta: { order: Number(id) },
      },
    ],
    endpoint: `/orders/${id}`,
    method: 'DELETE',
  },
});

export const fetchOrderHistory = () => ({
  [RSAA]: {
    types: [types.FETCH_ORDER_HISTORY_REQUEST, types.FETCH_ORDER_HISTORY_SUCCESS, types.FETCH_ORDER_HISTORY_FAILURE],
    endpoint: `/orders/history`,
    method: 'GET',
  },
});
