// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent } from 'app/shared';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

import { checkPermissions, currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-details-transfers-table.component.types';*/
import styles from './coupons-details-transfers-table.module.scss';

export const headers = [
  { content: 'COUPONS.TRANSFERS.HEADERS.ITEM_ISSUED' },
  { content: 'COUPONS.TRANSFERS.HEADERS.ITEM_ISSUER' },
  { content: 'COUPONS.TRANSFERS.HEADERS.VALID' },
  { content: 'COUPONS.TRANSFERS.HEADERS.BALANCE_ISSUED', numerical: true },
  { content: 'COUPONS.TRANSFERS.HEADERS.BALANCE_LEFT', numerical: true },
  {},
];

export class CouponsDetailsTransfersTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(transfer => (
            <TableRowComponent className={styles.row} key={transfer.id}>
              <TableCellComponent>{dateFormatter(transfer.created)}</TableCellComponent>
              <TableCellComponent>{transfer.issuerName}</TableCellComponent>
              <TableCellComponent>
                {dateFormatter(transfer.starts)} - {dateFormatter(transfer.expires)}
              </TableCellComponent>
              <TableCellComponent align="right">
                {currencyFormatter.format(transfer.originalBalance)}
              </TableCellComponent>
              <TableCellComponent align="right">{currencyFormatter.format(transfer.balance)}</TableCellComponent>
              <TableCellComponent>
                {checkPermissions(this.props.permissions, ['transfers', 'update']) && transfer.refundable && (
                  <ButtonComponent
                    size="small"
                    theme="failure"
                    className="ml-2"
                    onClick={() => this.props.onRedeem(transfer.id)}
                  >
                    <FormattedMessage id="COUPONS.TRANSFERS.REDEEM" />
                  </ButtonComponent>
                )}
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
