import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { withCoreContext } from 'app/context/core/core.context';
import { isPersonalIdentificationCodeEnabled } from 'app/features';

import { CouponsCreateFormComponent } from './coupons-create-form.component';

const mapStateToProps = (
  {
    customers: {
      entities: { current },
    },
    dictionaries,
    types: { entities: types },
    features,
  },
  {
    match: {
      params: { type },
    },
  },
) => ({
  current,
  contractsOptions: dictionaries['contracted-venues'],
  type: types[type],
  isPersonalIdentificationCodeEnabled: isPersonalIdentificationCodeEnabled(features[current.id]),
});

export const CouponsCreateFormContainer = compose(
  injectIntl,
  withRouter,
  withCoreContext,
  connect(mapStateToProps),
)(CouponsCreateFormComponent);
