//@flow
import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './icon.component.types';*/
import styles from './icon.module.scss';

export const Icon = (props /*: Props*/) => {
  if (props.icon) {
    return (
      <span className={classNames('input-group-prepend', props.className)} onClick={props.onClick}>
        <span className={classNames('input-group-text', styles.icon)}>
          <i className={`fi fi-${props.icon}`} />
        </span>
      </span>
    );
  }

  return null;
};
