//@flow
// $FlowFixMe
import { useEffect, useMemo, useRef, useState } from 'react';

/*:: import type { Config } from './useIsMounted.hook.types';*/

export const useIsMounted = ({ onMount, onUnmount } /*: Config*/ = {}) => {
  const isMounted = useRef(false);
  const [, setIsMountedBuffer] = useState(isMounted.current);

  useEffect(
    () => {
      isMounted.current = true;
      setIsMountedBuffer(isMounted.current);

      if (onMount) {
        onMount();
      }

      return () => {
        isMounted.current = false;

        if (onUnmount) {
          onUnmount();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useMemo(
    () => isMounted.current,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted.current],
  );
};
