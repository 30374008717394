// @flow
import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { ConnectControl } from 'app/shared';

import { CheckboxesComponent } from 'app/shared/form/controls';

/*:: import type { Option, Props } from './connected-checkboxes-form.component.types';*/

export const ConnectedCheckboxesFormComponent = (props /*: Props*/) => {
  const {
    input: { value },
  } = props;

  const isChecked = useCallback(option => option.checked, []);
  const isDisabled = useCallback(option => option.disabled, []);

  const onChange = useCallback(
    (changedOption /*: Option*/) => {
      if (!changedOption.disabled) {
        const newValue = props.input.value.map(option => {
          return isEqual(option, changedOption) ? { ...option, checked: !option.checked } : option;
        });

        props.input.onChange(newValue);

        if (props.onChange) {
          props.onChange();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  const options = useMemo(() => (value || []).filter(({ hidden = false }) => !hidden), [value]);

  return (
    <ConnectControl
      {...props}
      component={CheckboxesComponent}
      itemProps={{ ...props.itemProps, isChecked, onChange, isDisabled }}
      options={options}
      labelKey="text"
    />
  );
};
