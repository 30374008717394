// @flow
import React from 'react';

import { TileComponent } from 'app/shared';
import { FormattedMessage } from 'react-intl';
import { ButtonComponent } from 'app/shared';

/*:: import type { Props } from './pricelists-tile.component.types';*/

export const PricelistsTileComponent = (props /*: Props*/) => {
  const { tile: venue } = props;

  return (
    <TileComponent>
      <div className="row">
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.STATUS" tagName="small" />
          <FormattedMessage id={`PRICELIST.STATUS_VALUE.${venue.status}`} />
        </div>
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.VENUE_NAME" tagName="small" />
          {venue.name}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.SERVICES" tagName="small" />
          {venue.services.map((service /*: string*/) => service).join(', ')}
        </div>
        <div className="col-auto">
          <ButtonComponent theme="secondary-outline" to={`/pricelists/${venue.id}`}>
            <i className="fi fi-open" />
            <FormattedMessage id="PRICELISTS.MANAGE.LIST.OPEN" />
          </ButtonComponent>
        </div>
      </div>
    </TileComponent>
  );
};
