import { handleActions } from 'redux-actions';
import { FETCH_COUPON_FAILURE, FETCH_COUPON_REQUEST, FETCH_COUPON_SUCCESS } from './coupon.action-types';

const defaultState = {
  details: {},
  isLoading: true,
};

export const couponReducer = handleActions(
  {
    [FETCH_COUPON_REQUEST]: state => ({
      ...state,
      details: {
        ...state.details,
      },
      isLoading: true,
    }),
    [FETCH_COUPON_SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      details: { ...data },
      isLoading: false,
    }),
    [FETCH_COUPON_FAILURE]: state => ({
      ...defaultState,
      isLoading: false,
    }),
  },
  defaultState,
);
