//@flow

import React, { PureComponent } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { ConnectedInputComponent, ConnectedMoneyInputComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';

import { currencyFormatter } from 'app/utils';

/*:: import type { Props } from './calculations-row.component.types';*/
import styles from './calculations-row.module.scss';

export class CalculationsRowComponent extends PureComponent /*:: <Props>*/ {
  handleFieldChange = (field /*: string*/) => () => {
    const { change } = this.props.form;
    const amount = this.getFieldValue(field, 'amount');
    const price = this.getFieldValue(field, 'price');
    const total = amount * price || 0;

    change(`${field}.total`, total);
    this.props.updateMainField();
  };

  getFieldValue = (field /*: string*/, fieldName /*: string*/) => {
    const { getFieldState } = this.props.form;
    const fieldValue = getFieldState(`${field}.${fieldName}`).value;

    return +`${fieldValue}`.replace(/,/g, '.');
  };

  render() {
    const { field, onRemove, disabled } = this.props;

    return (
      <div className={classNames(styles.row, 'row')}>
        <div className={styles.removeRow}>
          <ButtonComponent
            className={styles.removeRowButton}
            disabled={disabled}
            theme="transparent"
            size="small"
            onClick={onRemove}
          >
            <div className="fi fi-remove" />
          </ButtonComponent>
        </div>
        <div className={classNames('col', styles.input)}>
          <Field
            name={`${field}.amount`}
            component={ConnectedInputComponent}
            label="CALCULATOR.AMOUNT_OF_UNITS"
            icon="stack"
            mask={{
              mask: '999999999999',
              maskChar: '',
              formatChars: {
                '9': '[0-9,.]',
              },
            }}
            onChange={this.handleFieldChange(field)}
          />
        </div>
        <div className={classNames('col-12 col-md', styles.input)}>
          <ConnectedMoneyInputComponent
            field={Field}
            name={`${field}.price`}
            label="CALCULATOR.PRICE_PER_UNIT"
            onChange={this.handleFieldChange(field)}
          />
        </div>
        <div className={styles.total}>
          = <Field name={`${field}.total`} render={({ input }) => currencyFormatter.format(input.value)} />
        </div>
      </div>
    );
  }
}
