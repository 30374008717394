// @flow

import React, { Component } from 'react';

import { SettlementsListTablePlaceholder } from './table/settlements-list-table.placeholder';
import { SettlementsListTilePlaceholder } from './tile/settlements-list-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';

/*:: import type { Props } from './settlements-list.placeholder.types';*/

export class SettlementsListPlaceholder extends Component /*:: <Props>*/ {
  static defaultProps = {
    settlements: [...new Array(5)],
  };

  render() {
    return (
      <ListComponent
        list={this.props.settlements}
        table={SettlementsListTablePlaceholder}
        tile={SettlementsListTilePlaceholder}
      />
    );
  }
}
