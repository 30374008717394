// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';
import { StatusComponent } from 'app/shared/status/status.component';

import { checkPermissions, currencyFormatter } from 'app/utils';
import { StatusEnum } from 'app/constants';

/*:: import type { Props } from './coupons-types-list-tile.component.types';*/
import styles from './coupons-types-list-tile.module.scss';

export class CouponsTypesListTileComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className={styles.heading}>
          <StatusComponent status={this.props.tile.active ? StatusEnum.Approved : StatusEnum.Deleted} />
          <h3>{this.props.tile.name}</h3>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TYPES.LABEL.DESCRIPTION" tagName="small" />
            {this.props.tile.description}
          </div>
          <div className="col">
            <FormattedMessage id="COUPONS.TYPES.LABEL.MAX_TRANSACTION" tagName="small" />
            {this.props.tile.transactionMaxPriceEnabled
              ? currencyFormatter.format(this.props.tile.defaultTransactionMaxPrice)
              : '-'}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TYPES.LABEL.VALUE" tagName="small" />
            {currencyFormatter.format(this.props.tile.defaultValue, {
              placeholderObject: { condition: () => !this.props.tile.defaultValue },
            })}
          </div>
          <div className="col">
            <FormattedMessage id="COUPONS.TYPES.LABEL.PRICE_UNIT" tagName="small" />
            {this.props.tile.priceUnit ? (
              <FormattedMessage id={`COUPONS.TYPES.UNITS.${this.props.tile.priceUnit}`} />
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="buttons">
          {checkPermissions(this.props.permissions, ['serviceCouponTypes', 'update']) && (
            <ButtonComponent theme="primary-outline" size="small" to={`/coupons/types/${this.props.tile.id}/edit`}>
              <FormattedMessage id="CORE.EDIT" />
            </ButtonComponent>
          )}

          {checkPermissions(this.props.permissions, ['serviceCoupons', 'create']) && this.props.tile.active && (
            <ButtonComponent size="small" to={`/coupons/${this.props.tile.id}/create`}>
              <FormattedMessage id="CORE.CREATE" />
            </ButtonComponent>
          )}
        </div>
      </TileComponent>
    );
  }
}
