import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';

import { TransactionsMunicipalBeneficiaryFiltersComponent } from './transactions-municipal-beneficiary-filters.component';

const mapStateToProps = ({ users: { business } }) => ({
  business,
});

export const TransactionsMunicipalBeneficiaryFiltersContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps),
  reduxForm({
    form: 'filter-municipal-beneficiary-transactions',
  }),
  withClear,
)(TransactionsMunicipalBeneficiaryFiltersComponent);
