import { PricelistTypeEnum } from 'app/constants';
import { FormattedPriceList, InputContractWithPricelists, InputPriceList } from 'app/types/pricelist';

export const getFormattedContractPricelists = (currentContract: InputContractWithPricelists): FormattedPriceList[] => {
  return currentContract.priceLists.map((pricelist): FormattedPriceList => {
    switch (pricelist.type) {
      case PricelistTypeEnum.Table:
        const servicesList = pricelist.services.map(service => ({
          ...service,
          prices: service.prices.map(price => ({
            value: price,
          })),
        }));
        return { ...pricelist, services: servicesList };
      default:
        return pricelist;
    }
  });
};

type FormattedContractWithPriceLists = { priceLists: FormattedPriceList[] };

export const getOriginalContractPricelists = (currentContract: FormattedContractWithPriceLists): InputPriceList[] => {
  return currentContract.priceLists.map((pricelist): InputPriceList => {
    switch (pricelist.type) {
      case PricelistTypeEnum.Table:
        const servicesList = pricelist.services.map(service => ({
          ...service,
          prices: service.prices.map(price => price.value),
        }));
        return { ...pricelist, services: servicesList };
      default:
        return pricelist;
    }
  });
};
