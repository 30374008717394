// @flow

import React, { Component } from 'react';
import get from 'lodash/get';

import { PageHeaderComponent } from 'app/core';
import { isBusinessDifferent } from 'app/utils';
import { SeparatorComponent } from 'app/shared';
import { ErrorKeys } from 'app/common/error-keys';

import { OrdersCreateFormContainer } from './form/orders-create-form.container';
import { OrdersConfirmationModalContainer } from '../confirmation/orders-confirmation-modal.container';
import { OrdersHistoryContainer } from './../history/orders-history.container';
import { Modal } from 'app/shared/modal-new/modal.container';
import { ResultActionModalComponent } from 'app/shared/result-action-modal/result-action-modal.component';

/*:: import type { BodyProps, Props, State } from './orders-create.component.types';*/

export class OrdersCreateComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      emailNotSent: false,
      isConfirmationModalOpen: false,
      isConfirmationSuccess: false,
      isConfirming: false,
      isResultModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.fetchCustomer(this.props.business.id);
    this.props.fetchInvoicingOptions();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchCustomer(this.props.business.id);
      this.props.fetchInvoicingOptions();
    }

    if (this.props.orderId && prevProps.orderId !== this.props.orderId) {
      this.toggleConfirmationModal();
    }
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  handleSubmit = (body /*: BodyProps*/) /*: Promise<any>*/ => this.props.onSubmit(body);

  toggleConfirmationModal = () => {
    this.setState(prevState => ({
      isConfirmationModalOpen: !prevState.isConfirmationModalOpen,
    }));
  };

  toggleResultModal = () => {
    this.setState(prevState => ({
      isResultModalOpen: !prevState.isResultModalOpen,
    }));
  };

  onConfirm = () => {
    this.setState({ isConfirming: true, isResultModalOpen: true, isConfirmationModalOpen: false }, () => {
      this.props
        .onConfirm(this.props.orderId)
        .then(() => {
          this.setState({ isConfirming: false, isConfirmationSuccess: true, emailNotSent: false });
        })
        // TODO: Handle backend 503 error here or in a utility used by this component. The catch - clause
        //       relies on existence of the validation.middleware.js and exceptions it throws. The logic
        //       can be made explicit by just creating a wrapper function.
        .catch(error => {
          if (ErrorKeys.CANNOT_SEND_ORDER_CONFIRMATION_EMAIL === get(error, ['errors', 'errorKey'])) {
            this.setState({ isConfirming: false, emailNotSent: true, isConfirmationSuccess: true });
          } else {
            this.setState({ isConfirming: false, isConfirmationSuccess: false });
          }
        });
    });
  };

  onCancel = () => {
    this.setState({ isCanceling: true }, () => {
      this.props
        .onCancel(this.props.orderId)
        .then(() => this.setState({ isCanceling: false }, this.toggleConfirmationModal));
    });
  };

  getModalContents = () => {
    const successTitle = this.state.emailNotSent
      ? 'ORDERS.MODAL.SUCCESS_WITHOUT_MAIL_TITLE'
      : 'ORDERS.MODAL.SUCCESS_TITLE';
    const successBody = this.state.emailNotSent
      ? 'ORDERS.MODAL.SUCCESS_WITHOUT_MAIL_BODY'
      : 'ORDERS.MODAL.SUCCESS_BODY';

    return {
      success: {
        title: this.props.intl.formatMessage({ id: successTitle }),
        body: this.props.intl.formatMessage({ id: successBody }),
      },
      failure: {
        title: this.props.intl.formatMessage({ id: 'ORDERS.MODAL.FAILURE_TITLE' }),
        body: this.props.intl.formatMessage({ id: 'ORDERS.MODAL.FAILURE_BODY' }),
      },
    };
  };

  render() {
    return (
      <>
        <PageHeaderComponent title="ORDERS.CREATE_EMONEY_ORDER_TITLE" />

        <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <OrdersCreateFormContainer submitting={this.props.submitting} />
        </form>

        {this.state.isConfirmationModalOpen && (
          <Modal
            component={OrdersConfirmationModalContainer}
            confirmOptions={[
              {
                label: this.props.intl.formatMessage({ id: 'CORE.BACK' }),
                onClick: this.onCancel,
                theme: 'primary-outline',
                loading: this.state.isCanceling,
                arrowBack: true,
              },
              {
                label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
                onClick: this.onConfirm,
              },
            ]}
            isOpen
          />
        )}

        <SeparatorComponent />
        <OrdersHistoryContainer />

        <Modal
          isOpen={this.state.isResultModalOpen}
          component={ResultActionModalComponent}
          size="small"
          componentProps={{
            isLoading: this.state.isConfirming,
            isSuccess: this.state.isConfirmationSuccess,
            onClose: () => {
              this.toggleResultModal();
              this.state.isConfirmationSuccess && this.props.reset();
            },
            messages: this.getModalContents(),
          }}
        />
      </>
    );
  }
}
