import { FunctionComponent } from 'react';
import { CardHeaderComponent } from 'app/shared/card-new';
import { dateFormatter } from 'app/utils';
import { ContractStatus } from 'app/constants';
import { TranslationKey } from 'v2/types/translations';
import { Contract } from 'app/types/contract';

export interface ContractCardHeaderComponentProps {
  contract: Pick<Contract, 'submittedAt' | 'validFrom' | 'validTo' | 'status'>;
}

const BaseContractCardHeader: FunctionComponent<{ title: { id: TranslationKey; values: Record<string, unknown> } }> = ({
  title,
}) => <CardHeaderComponent title={title} typographyProps={{ color: 'secondary' }} />;

const ApprovedContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({ contract }) => {
  const validTo: string = contract.validTo ? dateFormatter(contract.validTo) : '';
  const validity = `${contract.validFrom ? dateFormatter(contract.validFrom) : ''} – ${validTo}`;
  return <BaseContractCardHeader title={{ id: 'PRICELIST.HEADER', values: { validity } }} />;
};

const PendingContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({ contract }) => (
  <BaseContractCardHeader
    title={{
      id: 'PRICELIST.PENDING_HEADER',
      values: { submitted: contract.submittedAt && dateFormatter(contract.submittedAt) },
    }}
  />
);

const ChangesRequestedContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({
  contract,
}) => (
  <BaseContractCardHeader
    title={{
      id: 'PRICELIST.CHANGES_REQUESTED_HEADER',
      values: { submitted: contract.submittedAt && dateFormatter(contract.submittedAt) },
    }}
  />
);

const NewContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({ contract }) => (
  <BaseContractCardHeader
    title={{
      id: 'PRICELIST.NEW_HEADER',
      values: {},
    }}
  />
);

const LockedContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({ contract }) => (
  <BaseContractCardHeader
    title={{
      id: 'PRICELIST.LOCKED_HEADER',
      values: {},
    }}
  />
);

export const ContractCardHeaderComponent: FunctionComponent<ContractCardHeaderComponentProps> = ({ contract }) => {
  switch (contract.status) {
    case ContractStatus.Approved:
      return <ApprovedContractCardHeaderComponent contract={contract} />;
    case ContractStatus.Deleted:
    case ContractStatus.Pending:
      return <PendingContractCardHeaderComponent contract={contract} />;
    case ContractStatus.ChangesRequested:
      return <ChangesRequestedContractCardHeaderComponent contract={contract} />;
    case ContractStatus.New:
      return <NewContractCardHeaderComponent contract={contract} />;
    case ContractStatus.Locked:
      return <LockedContractCardHeaderComponent contract={contract} />;
    default:
      return null;
  }
};
