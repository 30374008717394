//@flow

import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './loading.component.types';*/
import styles from './loading.module.scss';

export const LoadingComponent = (props /*: Props*/) => {
  const { isLoading, theme = 'primary', size = 'small', overflow = false } = props;

  const loadingClassNames = classNames(styles.loading, styles[theme], styles[size], {
    [styles.oveflow]: overflow,
  });

  if (isLoading) {
    return <div className={loadingClassNames} role="progressbar" />;
  }

  return null;
};
