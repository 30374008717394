// @flow

import React from 'react';
import { Field } from 'redux-form';
import {
  ConnectedDatePickerComponent,
  ConnectedDateRangeComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
} from 'app/shared';
import { birthdayBounds, birthdayValidator } from 'app/utils';

/*:: import type { Props } from './coupons-list-advanced-filters.component.types';*/

export const CouponsListAdvancedFiltersComponent = (props /*: Props*/) => {
  return (
    <>
      {props.filterVisibility.showVenueFilter && (
        <div className={props.searchFieldClassNames}>
          <Field
            name="venueId"
            component={ConnectedSelectComponent}
            label="COUPONS.FILTERS.VENUE"
            onFieldUsage={props.onFieldUsage}
            options={props.venues}
            async
            withClearOption
          />
        </div>
      )}
      <div className={props.searchFieldClassNames}>
        <Field
          name="codes"
          component={ConnectedInputComponent}
          label="COUPONS.FILTERS.CODE"
          onFieldUsage={props.onFieldUsage}
        />
      </div>
      <div className={props.searchFieldClassNames}>
        <ConnectedDateRangeComponent
          field={Field}
          name="created"
          startDateKey="createdMin"
          endDateKey="createdMax"
          onFieldUsage={props.onFieldUsage}
          onFocusChange={props.onDateFieldFocusChange}
          label="COUPONS.FILTERS.CREATED"
        />
      </div>
      <div className={props.searchFieldClassNames}>
        <ConnectedDateRangeComponent
          field={Field}
          name="transaction"
          startDateKey="transactionMin"
          endDateKey="transactionMax"
          onFieldUsage={props.onFieldUsage}
          onFocusChange={props.onDateFieldFocusChange}
          label="COUPONS.FILTERS.TRANSACTION_CREATED"
        />
      </div>
      <div className={props.searchFieldClassNames}>
        <Field
          name="issuers"
          component={ConnectedSelectComponent}
          label="COUPONS.FILTERS.ISSUER_NAME"
          onFieldUsage={props.onFieldUsage}
          options={props.issuers}
          multi
          async
        />
      </div>
      <div className={props.searchFieldClassNames}>
        <Field
          name="status"
          component={ConnectedSelectComponent}
          label="COUPONS.FILTERS.STATUS"
          onFieldUsage={props.onFieldUsage}
          options={props.statuses}
          async
          withClearOption
        />
      </div>
      {props.filterVisibility.showDateOfBirthFilter && (
        <div className={props.searchFieldClassNames}>
          <Field
            name="dateOfBirth"
            component={ConnectedDatePickerComponent}
            onFieldUsage={props.onFieldUsage}
            onFocusChange={props.onDateFieldFocusChange}
            label="COUPONS.FILTERS.DATE_OF_BIRTH"
            validate={birthdayValidator}
            bounds={birthdayBounds}
          />
        </div>
      )}
      {props.filterVisibility.showReferenceFilter && (
        <div className={props.searchFieldClassNames}>
          <Field
            name="customers"
            component={ConnectedInputComponent}
            label="COUPONS.FILTERS.CUSTOMERS_NAME"
            onFieldUsage={props.onFieldUsage}
          />
        </div>
      )}
      <div className={props.searchFieldClassNames}>
        <Field
          name="decisionNumber"
          component={ConnectedInputComponent}
          label="COUPONS.FILTERS.DECISION_NUMBER"
          onFieldUsage={props.onFieldUsage}
        />
      </div>
    </>
  );
};
