import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push, replace } from 'connected-react-router';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/context/core/core.context';

import { editVenue } from 'app/redux/venues/venues.actions';

import { VenueFormComponent } from 'app/venues/form/venue-form.component';

const mapStateToProps = ({ dictionaries }, { venue: initialValues = { venue: {} } }) => ({
  initialValues,
  dictionary: dictionaries.services,
  benefit: initialValues.venue.benefit || dictionaries.services.industries[0].id,
  type: initialValues.venue.type,
});

const mapDispatchToProps = (dispatch, { module, venue }) => ({
  onSubmit: body => dispatch(editVenue(venue.venue.type, venue.venue.id, body)).then(() => body.venue.name),
  onSubmitFail: () => window.scrollTo(0, 0),
  onSubmitSuccess: name =>
    module === 'venues'
      ? dispatch(
          push({
            pathname: '/venues',
            state: {
              success: {
                type: 'EDIT_VENUE',
                payload: name,
              },
            },
          }),
        )
      : dispatch(
          replace({
            pathname: `/venues/${venue.venue.id}/edit`,
            state: {
              success: {
                type: 'EDIT_VENUE',
                payload: name,
              },
            },
          }),
        ),
});

export const VenueFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'edit-venue',
    enableReinitialize: true,
  }),
)(VenueFormComponent);
