import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormValues } from 'redux-form';

import { RegisterSummaryComponent } from './register-summary.component';

const mapStateToProps = state => ({
  ...getFormValues('register')(state),
  venuesTypes: state.register.venuesTypes,
  services: state.dictionaries['municipal/services'].services.municipal,
});

export const RegisterSummaryContainer = compose(injectIntl, connect(mapStateToProps))(RegisterSummaryComponent);
