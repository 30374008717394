import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import get from 'lodash/get';

import { withCoreContext } from 'app/context/core/core.context';
import { withSidebarContext } from 'app/core/sidebar/sidebar.context';

import { SidebarBalanceComponent } from './sidebar-balance.component';

const mapStateToProps = ({ accounts }) => ({
  balance: get(accounts, ['list', 'balance']),
  loading: accounts.loading,
});

export const SidebarBalanceContainer = compose(
  injectIntl,
  withSidebarContext,
  withCoreContext,
  connect(mapStateToProps),
)(SidebarBalanceComponent);
