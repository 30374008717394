import { EDIT_COLUMNS_SETTINGS_REQUEST } from 'app/redux/users/users.action-types';
import { handleActions } from 'redux-actions';
import {
  APPLY_COUPONS_DRAFT,
  CHECK_BALANCE_FAILURE,
  CHECK_BALANCE_SUCCESS,
  CLEAR_COUPONS_DRAFT_SUCCESS,
  CLEAR_COUPONS_METADATA,
  CREATE_COUPON_FAILURE,
  CREATE_COUPON_SUCCESS,
  FETCH_COUPONS_DRAFT_REQUEST,
  FETCH_COUPONS_DRAFT_SUCCESS,
  FETCH_COUPONS_METADATA_SUCCESS,
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_SUCCESS,
  SET_SCROLL_TO_ROW,
  VALIDATE_COUPON_REQUEST
} from './coupons.action-types';

const defaultState = {
  list: undefined,
  meta: {},
  couponCreateError: false
};

export const couponsReducer = handleActions(
  {
    [CREATE_COUPON_FAILURE]: state => ({
      ...state,
      couponCreateError: true
    }),
    [CREATE_COUPON_SUCCESS]: state => ({
      ...state,
      couponCreateError: false
    }),
    [VALIDATE_COUPON_REQUEST]: state => ({
      ...state,
      couponCreateError: false
    }),
    [FETCH_COUPONS_REQUEST]: state => ({
      ...state,
      list: undefined,
      meta: {
        // We keep the columns meta-data between reloads of the distributed voucher list
        // to avoid re-rendering and focus loss of the filter input fields
        columns: state.meta.columns,
      },
    }),
    [FETCH_COUPONS_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta: {
        ...state.meta,
        ...meta,
      },
    }),
    [CHECK_BALANCE_FAILURE]: state => ({
      ...state,
      checked: undefined,
    }),
    [CHECK_BALANCE_SUCCESS]: (state, { meta, payload: { data: checked } }) => ({
      ...state,
      checked: {
        ...checked,
        serviceVoucherId: meta.serviceVoucherId,
      },
    }),
    [FETCH_COUPONS_DRAFT_REQUEST]: state => ({
      ...state,
      draft: undefined,
    }),
    [FETCH_COUPONS_DRAFT_SUCCESS]: (state, { payload }) => ({
      ...state,
      draft: payload && payload.data,
    }),
    [APPLY_COUPONS_DRAFT]: state => ({
      ...state,
      draft: { ...state.draft, apply: true },
    }),
    [CLEAR_COUPONS_DRAFT_SUCCESS]: state => ({
      ...state,
      draft: undefined,
    }),
    [FETCH_COUPONS_METADATA_SUCCESS]: (state, { payload: { newServiceCouponsCount } }) => ({
      ...state,
      meta: {
        ...state.meta,
        newCouponsCounter: newServiceCouponsCount,
      },
    }),
    [CLEAR_COUPONS_METADATA]: ({ meta: { newCouponsCounter, ...meta }, ...state }) => ({
      ...state,
      meta,
    }),
    // FIXME:
    [EDIT_COLUMNS_SETTINGS_REQUEST]: (
      state,
      {
        meta: {
          list,
          body: { columns: active },
        },
      },
    ) =>
      list === 'sharedServiceCoupons'
        ? {
            ...state,
            meta: {
              ...state.meta,
              columns: {
                ...state.meta.columns,
                active,
              },
            },
          }
        : state,
    [SET_SCROLL_TO_ROW]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  defaultState,
);
