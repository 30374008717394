//@flow

import React, { Component } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './card-body.component.types';*/
import styles from './card-body.module.scss';

export class CardBodyComponent extends Component /*:: <Props>*/ {
  render() {
    const wrapperClassNames = classNames(styles.content, this.props.className);

    return <div className={wrapperClassNames}>{this.props.children}</div>;
  }
}
