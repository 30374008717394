import { LOCATION_CHANGE } from 'connected-react-router';

export const watchRouteChange = store => next => action => {
  if (action && action.type === LOCATION_CHANGE) {
    const { location } = store.getState().router;
    const nextRoute = action.payload;
    const isInitialRoute = location.pathname === nextRoute.location.pathname;

    return next({
      ...action,
      payload: {
        ...nextRoute,
        prevLocation: location,
        isInitialRoute,
      },
    });
  }

  return next(action);
};
