// @flow

import React, { PureComponent } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { SeparatorComponent } from 'app/shared';

import { CouponsDetailsTransfersTablePlaceholder } from './table/coupons-details-transfers-table.placeholder';
import { CouponsDetailsTransfersTilePlaceholder } from './tile/coupons-details-transfers-tile.placeholder';

/*:: import type { Props } from './coupons-details-transfers.placeholder.types';*/

export class CouponsDetailsTransfersPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    transfers: [...new Array(5)],
  };

  render() {
    return (
      <>
        <SeparatorComponent />
        <ListComponent
          list={this.props.transfers}
          table={CouponsDetailsTransfersTablePlaceholder}
          tile={CouponsDetailsTransfersTilePlaceholder}
        />
      </>
    );
  }
}
