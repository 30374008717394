// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import vaanaLogo from 'assets/images/logo-white.svg';
import styles from './footer.module.scss';

import { ExternalLink } from '../../shared';

/*:: import type { Props } from './footer.component.types';*/

const FooterLinksComponent = ({ intl, termsTo }) => (
  <>
    {termsTo && (
      <p className={classNames(styles.linkwrapper)}>
        <NavLink to={termsTo} className={classNames(styles.link)}>
          <FormattedMessage id="FOOTER.TERMS" tagName="span" />
        </NavLink>
      </p>
    )}
    <p className={classNames(styles.linkwrapper)}>
      <ExternalLink href={intl.formatMessage({ id: 'FOOTER.ACCESSIBILITY_STATEMENT_ROUTE' })} className={styles.link}>
        <FormattedMessage id="FOOTER.ACCESSIBILITY_STATEMENT" tagName="span" />
      </ExternalLink>
    </p>
  </>
);

export const FooterComponent = ({ intl, termsTo } /*: Props*/) => (
  <footer className={styles.footer}>
    <div className="col-sm-12 col-md-12 col-lg-12 container-fluid">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-12 col-xl-10">
          <div className="row">
            <div
              className={classNames(
                'col-sm-12 col-md-4 d-flex flex-column justify-content-top align-items-left',
                styles.logowrapper,
              )}
            >
              <a href={intl.formatMessage({ id: 'FOOTER.VAANAFI_ROUTE' })}>
                <img
                  src={vaanaLogo}
                  alt={intl.formatMessage({ id: 'FOOTER.NAVIGATION_TO_VAANAFI' })}
                  className={styles.logo}
                />
              </a>
              <FormattedMessage id="FOOTER.STREET_ADDRESS" tagName="p" />
              <FormattedMessage id="FOOTER.ZIP_CODE_CITY" tagName="p" />
            </div>
            <div
              className={classNames('col-sm-12 col-md-8 col-xl-4 d-flex flex-column align-items-left', styles.service)}
            >
              <div>
                <p>{intl.formatMessage({ id: 'FOOTER.CUSTOMER_SERVICE_TITLE' })}:</p>
                <p>
                  <FormattedMessage id="FOOTER.EMAIL" tagName="span" />
                </p>
              </div>
              <div className={classNames('d-xl-none')}>
                <FooterLinksComponent intl={intl} termsTo={termsTo} />
              </div>
            </div>

            <div className={classNames('d-xl-flex col-xl-4 d-none flex-column align-items-left', styles.service)}>
              <div>
                <FooterLinksComponent intl={intl} termsTo={termsTo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
