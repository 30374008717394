// @flow

import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

export const convertParams = (rawParams /*: any*/ = {}) =>
  // $FlowFixMe
  Object.entries(rawParams).reduce((prev, [key, value] /*: [string, Object | string] */) => {
    if (!value) {
      return prev;
    }

    return isPlainObject(value)
      ? { ...prev, ...omitBy(omit(value, 'validation'), isEmpty) }
      : { ...prev, [key]: value };
  }, {});
