import { handleActions } from 'redux-actions';
import * as types from './archive.action-types';

const defaultState = {
  entities: {},
};

export const archiveReducer = handleActions(
  {
    [types.FETCH_VAT_ARCHIVES_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_VAT_ARCHIVES_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
    }),
    [types.FETCH_VAT_ARCHIVE_REQUEST]: (state, { meta: { month, year } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [`${month}/${year}`]: undefined,
      },
    }),
    [types.FETCH_VAT_ARCHIVE_SUCCESS]: (state, { meta: { month, year }, payload: { data: list, meta } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [`${month}/${year}`]: {
          list,
          meta,
        },
      },
    }),
    [types.FETCH_VAT_ARCHIVES_MONTHS_REQUEST]: state => ({
      ...state,
      months: undefined,
      newVatArchiveReport: false,
    }),
    [types.FETCH_VAT_ARCHIVES_MONTHS_SUCCESS]: (state, { payload: { data: months } }) => ({
      ...state,
      months,
    }),
    [types.FETCH_VAT_ARCHIVES_REPORT_STATUS_REQUEST]: state => ({
      ...state,
      newVatArchiveReport: false,
    }),
    [types.FETCH_VAT_ARCHIVES_REPORT_STATUS_SUCCESS]: (
      state,
      {
        payload: {
          data: { newVatArchiveReport },
        },
      },
    ) => ({
      ...state,
      newVatArchiveReport,
    }),
  },
  defaultState,
);
