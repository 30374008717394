// @flow

import React from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { PricelistsTablePlaceholder } from './table/pricelists-table.placeholder';
import { PricelistsTilePlaceholder } from './tile/pricelists-tile.placeholder';
import { SeparatorComponent, Typography } from 'app/shared';

export const PricelistsListPlaceholder = () => {
  return (
    <CardComponent>
      <CardHeaderComponent title="PRICELISTS.MANAGE.LIST.TITLE" />
      <SeparatorComponent height={12} />
      <CardBodyComponent>
        <Typography color="grey" id="PRICELISTS.MANAGE.LIST.SUBTITLE" />
        <SeparatorComponent />
        <ListComponent
          list={Array.from({ length: 5 })}
          table={PricelistsTablePlaceholder}
          tile={PricelistsTilePlaceholder}
        />
      </CardBodyComponent>
    </CardComponent>
  );
};
