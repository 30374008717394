// @flow

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import noop from 'lodash/noop';

import { ThemeComponent } from 'app/core';
import { ButtonComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import styles from './terms.module.scss';
/*:: import type { Props } from './terms.types';*/
/*:: import type { Node } from 'react';*/

const Placeholder = () /*: Node*/ => (
  <>
    <PlaceholderComponent color="primary" width={200} height={28} />
    <SeparatorComponent />
    <PlaceholderComponent />
    <PlaceholderComponent width={300} />
  </>
);

export const TermsComponent = ({ content, fetchTerms = noop } /*: Props*/) => {
  useEffect(
    () => {
      fetchTerms();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ThemeComponent theme="vaana">
      <CardComponent withShadow={false}>
        <CardHeaderComponent className="mb-4">
          <ButtonComponent to="/coupons/check-balance">
            <i className="fi fi-arrow-left" />
            <FormattedMessage id="CORE.BACK" />
          </ButtonComponent>
        </CardHeaderComponent>
        <CardBodyComponent>
          <section className="container">
            {content ? <ReactMarkdown source={content} className={styles.body} /> : <Placeholder />}
          </section>
        </CardBodyComponent>
      </CardComponent>
    </ThemeComponent>
  );
};
