import { put, select, takeEvery } from 'redux-saga/effects';
import { change } from 'redux-form';

import { FILL_VALUES } from './register.action-types';

export const formValueSelector = (state, form, from) => state.form[form].values[from];

export function* onFillValuesClick({ payload: { form: formName, from, to } }) {
  const values = yield select(formValueSelector, formName, from);
  yield put(change(formName, to, values));
}

export function* registerSaga() {
  yield takeEvery(FILL_VALUES, onFillValuesClick);
}
