import { FunctionComponent } from 'react';
import {
  ServiceEventFormValues,
  ServiceEventSubmissionCardComponentProps,
  ServiceEventValidationErrors,
} from './types';
import { computeInitialValues } from './service-event-values';
import { validateServiceEvent } from './backend-services/validate-service-event';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { CardHeaderComponent } from 'app/shared/card-new/header/card-header.component';
import { SeparatorComponent } from 'app/shared';
import { Formik, FormikProps } from 'formik';
import { ServiceEventSubmissionComponent } from './service-event-submission.component';

export const ServiceEventSubmissionCardComponent: FunctionComponent<ServiceEventSubmissionCardComponentProps> =
  props => {
    const initialValues = computeInitialValues(props.voucher, undefined);

    const validate: (values: ServiceEventFormValues) => Promise<ServiceEventValidationErrors | void> = async values => {
      return await validateServiceEvent(values, props.voucher, props.venueId, props.transactions);
    };

    const noop = () => {
      // Submission flow is handled explicitly by dispatching serviceEventSubmissionRequested
      // Redux action and observing the status field of the ServiceEventState in Redux.
    };

    return (
      <CardComponent>
        <CardHeaderComponent title="SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.TITLE">
          {props.cardHeaderButton}
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <Formik initialValues={initialValues} onSubmit={noop} validate={validate} validateOnMount={true}>
            {(formikProps: FormikProps<ServiceEventFormValues>) => (
              <ServiceEventSubmissionComponent voucher={props.voucher} venueId={props.venueId} {...formikProps} />
            )}
          </Formik>
        </CardBodyComponent>
      </CardComponent>
    );
  };
