// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import classNames from 'classnames';

import { ButtonComponent, ConnectedInputComponent, ConnectedToggleComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { checkPermissions } from 'app/utils';

/*:: import type { BodyType, Props, State } from './personal.component.types';*/
import styles from './personal.module.scss';

export class PersonalComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = (body /*: BodyType*/) =>
    this.props
      .onSubmit(body)
      .then(() => this.setState({ submitted: true }))
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <CardHeaderComponent title="PROFILE.PERSONAL_INFORMATION" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name="firstName"
                  component={ConnectedInputComponent}
                  label="PROFILE.FIRST_NAME"
                  append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="lastName"
                  component={ConnectedInputComponent}
                  label="PROFILE.LAST_NAME"
                  append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                  disabled
                />
              </div>
              <div className={columnClassNames}>
                <Field name="email" component={ConnectedInputComponent} label="PROFILE.EMAIL" required />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="mobilePhone"
                  component={ConnectedInputComponent}
                  label="PROFILE.MOBILE_PHONE"
                  append="PROFILE.PHONE_NUMBER_FORMAT"
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="workPhone"
                  component={ConnectedInputComponent}
                  label="PROFILE.WORK_PHONE"
                  append="PROFILE.PHONE_NUMBER_FORMAT"
                />
              </div>
            </div>
            <div className={styles.marketing}>
              <Field
                name="allowMarketing"
                component={ConnectedToggleComponent}
                label={
                  <>
                    <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}</div>
                    <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}</div>
                  </>
                }
              />
            </div>
          </CardBodyComponent>

          {checkPermissions(this.props.permissions, ['beneficiaryAddress', 'update']) && (
            <FormSection name="address">
              <SeparatorComponent />
              <CardHeaderComponent title="PROFILE.DELIVERY_ADDRESS" />
              <SeparatorComponent />
              <CardBodyComponent>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="address" component={ConnectedInputComponent} label="PROFILE.ADDRESS" />
                  </div>
                  <div className={columnClassNames}>
                    <Field name="zipCode" component={ConnectedInputComponent} label="PROFILE.ZIP_CODE" />
                  </div>
                </div>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="city" component={ConnectedInputComponent} label="PROFILE.CITY" />
                  </div>
                </div>
              </CardBodyComponent>
            </FormSection>
          )}

          <SeparatorComponent />
          <CardFooterComponent justifyContent="flex-end">
            {this.state.submitted && (
              <>
                <span className={classNames('fi fi-check', styles.check)} />
                <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
              </>
            )}

            <ButtonComponent loading={this.props.submitting}>
              {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
            </ButtonComponent>
          </CardFooterComponent>
        </form>
      </CardComponent>
    );
  }
}
