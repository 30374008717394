import { PriceObject } from 'app/types/pricelist';

import styles from '../price-list.common.module.scss';

interface Props {
  tableCellField: PriceObject;
  showDiff: boolean;
}

const hasValueChanged = (value: string, oldValue?: string) => (value || '').trim() !== (oldValue || '').trim();

export const PriceListTableCellComponent = ({ tableCellField, showDiff }: Props) => {
  if (!tableCellField) {
    return null;
  }
  const { value, oldValue } = tableCellField;
  if (!showDiff || !hasValueChanged(value, oldValue)) {
    return value ?? null;
  }

  return (
    <>
      {value && (
        <>
          <span className={styles.textAdded}>{value}</span>
          <br />
        </>
      )}
      {oldValue && <span className={styles.textRemoved}>{oldValue}</span>}
    </>
  );
};
