// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent, CheckboxComponent, InputComponent, SeparatorComponent, Typography } from 'app/shared';

import { initialFilters } from 'app/pricelists/manage/pricelists-manage.filters';
import { ContractStatus, ContractSubStatus } from 'app/constants';

/*:: import type { VenuesListFilters } from 'app/pricelists/manage/pricelists-manage.component.types';*/
/*:: import type { Props } from './pricelists-filters.component.types';*/
/*:: type StatusFilter = 'showApproved' | 'showPending' | 'showChangesRequested' | 'showPendingAfterChangesRequested' | 'showChangesNotMadeInTime';*/

export const PricelistsFiltersComponent = (props /*: Props*/) => {
  const toggleFilter = (key /*: StatusFilter*/) => () /*: void*/ => {
    const keyAsString /*: string*/ = key;

    props.setFilters((prevFilters /*: VenuesListFilters*/) => ({
      ...prevFilters,
      [keyAsString]: !prevFilters[key],
    }));
  };

  const setFilter =
    (key /*: string*/) =>
      ({ target: { value: name } } /*: SyntheticInputEvent<HTMLInputElement>*/) /*: void*/ => {
        props.setFilters((prevFilters /*: VenuesListFilters*/) => ({
          ...prevFilters,
          [key]: name,
        }));
      };

  return (
    <CardComponent>
      <CardHeaderComponent title="PRICELISTS.MANAGE.FILTERS.TITLE">
        <ButtonComponent theme="secondary-outline" className="ml-auto" onClick={() => props.setFilters(initialFilters)}>
          <FormattedMessage id="CORE.CLEAR_FILTERS" />
        </ButtonComponent>
      </CardHeaderComponent>
      <SeparatorComponent />
      <CardBodyComponent>
        <div className="row">
          <div className="col-12 col-lg-6">
            <InputComponent
              value={props.filters.name}
              label="PRICELISTS.MANAGE.FILTERS.VENUE_NAME"
              onChange={setFilter('name')}
            />
          </div>
        </div>
        <Typography type="smallText" id="PRICELISTS.MANAGE.FILTERS.SHOW_STATUS" color="grey" />
        <div className="row">
          <div className="col-12 d-flex flex-column flex-sm-row">
            <CheckboxComponent
              checked={props.filters.showApproved}
              onChange={toggleFilter('showApproved')}
              label="PRICELIST.STATUS_VALUE.APPROVED"
              className="mr-2"
            />
            <CheckboxComponent
              checked={props.filters.showPending}
              onChange={toggleFilter('showPending')}
              label="PRICELIST.STATUS_VALUE.PENDING"
              className="mr-2"
            />
            {props.showSubStatusFilters && props.showSubStatusFilters.includes(ContractStatus.ChangesRequested) && (
              <CheckboxComponent
                checked={props.filters.showChangesRequested}
                onChange={toggleFilter('showChangesRequested')}
                label="PRICELIST.STATUS_VALUE.CHANGES_REQUESTED"
                className="mr-2"
              />
            )}
            {props.showSubStatusFilters && props.showSubStatusFilters.includes(ContractSubStatus.PendingAfterChangesRequested) && (
              <CheckboxComponent
                checked={props.filters.showPendingAfterChangesRequested}
                onChange={toggleFilter('showPendingAfterChangesRequested')}
                label="PRICELIST.STATUS_VALUE.PENDING_AFTER_CHANGES_REQUESTED"
                className="mr-2"
              />
            )}
            {props.showSubStatusFilters && props.showSubStatusFilters.includes(ContractSubStatus.ChangesNotMadeInTime) && (
              <CheckboxComponent
                checked={props.filters.showChangesNotMadeInTime}
                onChange={toggleFilter('showChangesNotMadeInTime')}
                label="PRICELIST.STATUS_VALUE.CHANGES_NOT_MADE_IN_TIME"
                className="mr-2"
              />
            )}
          </div>
        </div>
      </CardBodyComponent>
    </CardComponent>
  );
};
