// @flow

import React, { Component } from 'react';
import { get, isEqual } from 'lodash';

import { PageHeaderComponent } from 'app/core';
import { checkPermissions, isBusinessDifferent } from 'app/utils';
import { SeparatorComponent } from 'app/shared';
import { CardComponent } from 'app/shared/card-new';

import { CouponsDetailsChargeContainer } from './charge/coupons-details-charge.container';
import { CouponsDetailsInfoContainer } from './info/coupons-details-info.container';
import { CouponDetailsSidebarComponent } from './sidebar/coupon-details-sidebar.component';
import { CouponsDetailsTransactionsContainer } from './transactions/coupons-details-transactions.container';
import { CouponsDetailsTransfersContainer } from './transfers/coupons-details-transfers.container';

/*:: import type { Props } from './coupons-details.types';*/

export class CouponsDetailsComponent extends Component /*:: <Props>*/ {
  componentDidMount() /*: void*/ {
    this.props.fetchCoupon();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props) || !isEqual(prevProps.match.params.id, this.props.match.params.id)) {
      this.props.fetchCoupon();
    }
  }

  render() {
    const { loading, coupon, transactions } = this.props;

    const isLoading = loading || !coupon;

    const canVenueCharge = [0, this.props.business.id].includes(get(this.props.coupon, ['venueId'], 0));
    const canCharge = checkPermissions(this.props.permissions, ['venuePayment', 'create']) && canVenueCharge;
    const canPrint = checkPermissions(this.props.permissions, ['serviceCouponPrint', 'read']);
    const isInvalidated = get(this.props.coupon, 'invalidated', false);

    return (
      <>
        <PageHeaderComponent title="COUPONS.DETAILS.TITLE" onBack={this.props.onBack} />
        <CouponsDetailsInfoContainer />
        {canCharge && (
          <>
            <SeparatorComponent />
            <CouponsDetailsChargeContainer />
          </>
        )}
        <SeparatorComponent />

        <CardComponent>
          <CouponsDetailsTransfersContainer loading={isLoading} coupon={coupon} />
          <SeparatorComponent />
          <CouponsDetailsTransactionsContainer loading={isLoading} transactions={transactions} />
        </CardComponent>

        {canPrint && isInvalidated === false && <CouponDetailsSidebarComponent loading={isLoading} coupon={coupon} />}
      </>
    );
  }
}
