// @flow
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { stopImpersonateUserAndRefresh } from '../redux/users/users.actions';
import { ImpersonationSessionBanner } from './impersonation-session-banner.component';

/*:: import type {
  ImpersonationSessionBannerDispatchProps,
  ImpersonationSessionBannerStateProps,
} from './impersonation-session-banner.types';*/

export const mapStateToProps = (state /*: any*/) /*: ImpersonationSessionBannerStateProps*/ => {
  const firstName = get(state, 'users.current.personal.firstName');
  const lastName = get(state, 'users.current.personal.lastName');

  return {
    userDisplayName: [firstName, lastName].join(' ').trim(),
  };
};

const mapDispatchToProps = (dispatch) /*: ImpersonationSessionBannerDispatchProps*/ => {
  return {
    onStopImpersonation: () => dispatch(stopImpersonateUserAndRefresh),
  };
};

export const ImpersonationSessionBannerContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  ImpersonationSessionBanner,
);
