//@flow
import React, { forwardRef } from 'react';

export const HtmlForm = forwardRef/*:: <*, *> */(
  (props, ref) => {
    const noValidate = Object.hasOwnProperty('noValidate') ? props.noValidate : true;

    return <form {...props} ref={ref} noValidate={noValidate} />;
  },
);
