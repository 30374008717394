// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter as withReactRouter } from 'react-router-dom';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Props } from './with-router.hoc.types';*/

const mapStateToProps = ({ router: { isInitialRoute, prevLocation } }) => ({
  isInitialRoute,
  prevLocation,
});

export const withRouter = (WrappedComponent /*: ComponentType<any>*/) =>
  compose(
    withReactRouter,
    connect(mapStateToProps),
  )(
    class Router extends Component /*:: <Props>*/ {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
  );
