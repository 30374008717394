import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';

import { withCoreContext } from 'app/context/core/core.context';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { scrollToElement } from 'app/utils';

import { renewCoupons } from 'app/redux/coupons/coupons.actions';

import { CouponsSingleRenewFormComponent } from './coupons-single-renew-form.component';

const mapStateToProps = (_, { coupon }) => ({
  coupon,
  initialValues: {
    coupons: [
      {
        active: true,
        maxTransaction: coupon.maxTransaction,
        defaultValue: coupon.lastDeposit,
        defaultValueCalculations: [{ amount: 1 }],
        id: coupon.id,
        valid: {
          from: coupon.validFrom,
          to: coupon.validTo,
        },
      },
    ],
  },
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (body, successHandler, failureHandler) => {
    successHandler = () => {
      dispatch(push(`/coupons/share/${body.coupons[0].id}`));
      scrollToElement('body');
    };

    return withFormSubmissionErrorHandling(dispatch(renewCoupons(body)), successHandler, failureHandler);
  },
});

export const CouponsSingleRenewFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  //withConfirmation, TODO
)(CouponsSingleRenewFormComponent);
