// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { AlertComponent, ButtonComponent, ConnectedInputComponent, Typography } from 'app/shared';
import { required } from 'app/utils';

/*:: import type { Props } from './coupons-share-sms.component.types';*/
import styles from './coupons-share-sms.component.module.scss';

const AppendComponent = ({ append }) => append;

export class CouponsShareSmsComponent extends Component /*:: <Props>*/ {
  render() {
    const { coupon } = this.props;

    return (
      <>
        <Typography color="grey" type="subtitle" id="COUPONS.SIDEBAR.PDF.SHARE_SMS_TITLE" disabled={!coupon} />

        <form onSubmit={this.props.handleSubmit} className={classNames(styles.form, this.props.className)}>
          <Field
            disabled={!coupon}
            name="phone"
            component={ConnectedInputComponent}
            placeholder={this.props.intl.formatMessage({ id: 'COUPONS.SHARE.SMS.PHONE' })}
            label="COUPONS.SIDEBAR.PDF.SHARE_SMS_SUBTITLE"
            append={
              <ButtonComponent
                className={styles.button}
                disabled={!coupon}
                theme="primary"
                size="small"
                loading={this.props.submitting}
              >
                <FormattedMessage id="COUPONS.SIDEBAR.PDF.SHARE_SMS_SEND" />
              </ButtonComponent>
            }
            appendComponent={AppendComponent}
            type="tel"
            required
            validate={required({ message: 'COUPONS.SHARE.SMS.PHONE_REQUIRED' })}
            mask={{
              mask: 'a9999999999999999',
              maskChar: '',
              formatChars: {
                '9': '[0-9-]',
                a: '[0-9+]',
              },
            }}
          />
        </form>

        {!coupon && (
          <div className={styles.errorMessage}>
            <FormattedMessage id="COUPONS.SIDEBAR.PDF.SHARE_SMS_MULTIPLE" />
          </div>
        )}

        {this.props.submitSucceeded && (
          <AlertComponent type="success">
            <FormattedMessage id="COUPONS.SHARE.SMS.SUCCESS" />
          </AlertComponent>
        )}

        {this.props.error && (
          <AlertComponent type="failure">
            <FormattedMessage id={this.props.error} />
          </AlertComponent>
        )}
      </>
    );
  }
}
