import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { RSAAAction } from 'redux-api-middleware';
import { get } from 'lodash';

import { reviewContract } from 'app/redux/pricelists/pricelists.actions';
import { REVIEW_CONTRACT_SUCCESS } from 'app/redux/pricelists/pricelists.action-types';

import { DispatchProps, ReviewSubmitData } from './pricelists.component.types';
import { PricelistsComponent } from './pricelists.component';

const mapStateToProps = ({ pricelists: { isLoading } }: { pricelists: { isLoading: boolean } }) => ({
  isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: async ({ contractId, action, reviewContractBody }: ReviewSubmitData): Promise<boolean> => {
    const res = await dispatch(reviewContract(contractId, action, reviewContractBody) as RSAAAction);
    return get(res, ['type']) === REVIEW_CONTRACT_SUCCESS;
  },
});

export const PricelistsContainer = compose(connect(mapStateToProps, mapDispatchToProps))(PricelistsComponent);
