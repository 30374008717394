//@flow
// $FlowFixMe
import { useContext } from 'react';

import { IntlContext } from 'app/context';

/*:: import type { Intl } from './useIntl.types';*/

export const useIntl = () /*: Intl*/ => {
  const { intl } = useContext(IntlContext);

  return intl;
};
