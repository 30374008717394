/*
This middleware guarantees a single concurrent in-flight request based on a key provided by the caller.
Multiple requests can be bundled together using the `requestLimitingKey` provided in the RSAA-object of the action.
When a request with a `requestLimitingKey` is made then a currently on going request with the same key is cancelled
before the new request is triggered.
 */
import { RSAA } from 'redux-api-middleware';

const storage = {};

const cancelRequest = lastRequest => {
  const controller = lastRequest.controller;

  controller.abort();
};

const createStorageValue = (controller, requestKey) => ({
  controller,
  key: requestKey,
});

const isLimited = action => !!action.requestLimitingKey;

export const singleConcurrentRequestMiddleware = () => next => action => {
  const requestAction = action[RSAA];

  if (requestAction && isLimited(requestAction)) {
    const { requestLimitingKey, ...rest } = requestAction;
    const controller = new AbortController();
    const signal = controller.signal;
    const nextAction = {
      ...action,
      [RSAA]: {
        ...rest,
        options: {
          ...rest.options,
          signal,
        },
        fetch: async (...args) => {
          const data = await fetch(...args);

          delete storage[requestLimitingKey];
          return data;
        },
      },
    };

    if (storage[requestLimitingKey]) {
      cancelRequest(storage[requestLimitingKey]);
    }

    storage[requestLimitingKey] = createStorageValue(controller, requestLimitingKey);

    return next(nextAction);
  }

  return next(action);
};
