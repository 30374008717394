// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import classNames from 'classnames';

import { ConnectedCheckboxComponent, ConnectedInputComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';

/*:: import type { Props, State } from './customer-information-form.component.types';*/
import styles from 'app/profile/personal/personal.module.scss';

export class CustomerInformationFormComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = (body /*: Object*/) =>
    this.props
      .onSubmit(body)
      .then(() => this.setState({ submitted: true }))
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <CardHeaderComponent title="CUSTOMERS.GENERAL_INFORMATION" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className={columnClassNames}>
              <Field
                name="name"
                component={ConnectedInputComponent}
                label="CUSTOMERS.NAME"
                append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                disabled
              />
            </div>
            {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_MUNICIPAL') && (
              <div className={columnClassNames}>
                <Field
                  name="nameExtra"
                  component={ConnectedInputComponent}
                  label="CUSTOMERS.NAME_2"
                  append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                  disabled
                />
              </div>
            )}
            <div className={columnClassNames}>
              <Field
                name="businessId"
                component={ConnectedInputComponent}
                label="CUSTOMERS.BUSINESS_ID"
                append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                disabled
              />
            </div>
            <div className={columnClassNames}>
              <Field
                name="phone"
                component={ConnectedInputComponent}
                label="CUSTOMERS.PHONE"
                append="PROFILE.PHONE_NUMBER_FORMAT"
              />
            </div>
          </div>
        </CardBodyComponent>

        {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_SERVICE_PROVIDER') && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.OFFICIAL_ADDRESS" />
            <SeparatorComponent />
            <CardBodyComponent>
              <FormSection name="addresses.post">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="address"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.STREET_ADDRESS"
                      required
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field name="zipCode" component={ConnectedInputComponent} label="CUSTOMERS.ZIP_CODE" required />
                  </div>
                </div>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="city" component={ConnectedInputComponent} label="CUSTOMERS.CITY" required />
                  </div>
                </div>
              </FormSection>
            </CardBodyComponent>
          </>
        )}

        {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_MUNICIPAL') && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.BILLING_ADDRESS" />
            <SeparatorComponent />
            <CardBodyComponent>
              <FormSection name="addresses.billing">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="addressee" component={ConnectedInputComponent} label="CUSTOMERS.ADDRESSEE" />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="address"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.BILLING_ADDRESS"
                      required
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="addressExtra"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.BILLING_ADDRESS_2"
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field name="zipCode" component={ConnectedInputComponent} label="CUSTOMERS.ZIP_CODE" required />
                  </div>
                  <div className={columnClassNames}>
                    <Field name="city" component={ConnectedInputComponent} label="CUSTOMERS.CITY" required />
                  </div>
                </div>
              </FormSection>
            </CardBodyComponent>
          </>
        )}

        {this.props.customer.type === 'TYPE_COMPANY' && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.DELIVERY_ADDRESS" />
            <SeparatorComponent />
            <CardBodyComponent>
              <FormSection name="addresses.delivery">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="address" component={ConnectedInputComponent} label="CUSTOMERS.DELIVERY_ADDRESS" />
                  </div>
                  <div className={columnClassNames}>
                    <Field name="zipCode" component={ConnectedInputComponent} label="CUSTOMERS.ZIP_CODE" />
                  </div>
                </div>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field name="city" component={ConnectedInputComponent} label="CUSTOMERS.CITY" />
                  </div>
                </div>
              </FormSection>
            </CardBodyComponent>
          </>
        )}

        {this.props.customer.type === 'TYPE_SERVICE_PROVIDER' && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.BANKING_INFORMATION" />
            <SeparatorComponent />
            <CardBodyComponent>
              <FormSection name="bankingInformation">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="iban"
                      component={ConnectedInputComponent}
                      label="REGISTER.ACCOUNT_NUMBER"
                      append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                      required
                      disabled
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="swift"
                      component={ConnectedInputComponent}
                      label="REGISTER.SWIFT"
                      append="PROFILE.CONTACT_CUSTOMER_SERVICE"
                      required
                      disabled
                    />
                  </div>
                </div>
              </FormSection>
            </CardBodyComponent>
          </>
        )}

        {(this.props.customer.type === 'TYPE_COMPANY' || this.props.customer.type === 'TYPE_MUNICIPAL') && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.BILLING_INFORMATION" />
            <SeparatorComponent />
            <CardBodyComponent>
              <FormSection name="invoicing">
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="eInvoiceDeliveryIdentifier"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.INVOICE_IDENTIFIER"
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="eInvoiceAddress"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.INVOICE_ADDRESS"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="defaultInvoicingReference"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.CUSTOMER_REFERENCE"
                    />
                  </div>
                </div>
              </FormSection>
            </CardBodyComponent>
          </>
        )}

        {this.props.customer.type === 'TYPE_MUNICIPAL' && (
          <>
            <SeparatorComponent />
            <CardHeaderComponent title="CUSTOMERS.ADDITIONAL_FEATURES" />
            <SeparatorComponent />
            <CardBodyComponent>
              {!this.props.isPostPaymentAccount && (
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="accountBalanceAlertLimit"
                      component={ConnectedInputComponent}
                      label="CUSTOMERS.BALANCE_ALERT"
                      append="CUSTOMERS.BALANCE_ALERT_ADDON"
                      icon="euro-symbol"
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="notifyExpiringVouchers"
                    component={ConnectedCheckboxComponent}
                    label="CUSTOMERS.NOTIFY_EXPIRING"
                    append="CUSTOMERS.NOTIFY_EXPIRING_ADDON"
                  />
                </div>
              </div>
            </CardBodyComponent>
          </>
        )}

        <CardFooterComponent justifyContent="flex-end">
          {this.state.submitted && (
            <>
              <span className={classNames('fi fi-check', styles.check)} />
              <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
            </>
          )}
          <ButtonComponent theme="primary" loading={this.props.submitting}>
            {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
          </ButtonComponent>
        </CardFooterComponent>
      </form>
    );
  }
}
