// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';

import { SummaryFieldComponent } from 'app/shared';
import { currencyFormatter, getBalance } from 'app/utils';

/*:: import type { Props } from './coupons-balance.types';*/

import styles from './coupons-balance.module.scss';

export class CouponsBalanceComponent extends PureComponent /*:: <Props>*/ {
  calculate = () => {
    const startedOn = this.props.serviceProducedStart;
    const endedOn = this.props.serviceProducedEnd;

    if (startedOn && endedOn) {
      const serviceProducedStart = moment(startedOn);
      const serviceProducedEnd = moment(endedOn);

      return getBalance(this.props.transfers, serviceProducedStart, serviceProducedEnd);
    }

    return null;
  };

  render() {
    const balance = this.calculate();

    return (
      <SummaryFieldComponent
        className={classNames(styles.summary, this.props.className)}
        equalFormHeights={this.props.equalFormHeights}
      >
        <FormattedMessage id="COUPONS.DETAILS.CHARGE.BALANCE_LEFT" tagName="strong" />
        <div className={styles.balance}>{currencyFormatter.format(balance)}</div>
      </SummaryFieldComponent>
    );
  }
}
