// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';
import { checkPermissions } from 'app/utils';

import { VenuesListTablePlaceholder } from './table/venues-list-table.placeholder';
import { VenuesListTilePlaceholder } from './tile/venues-list-tile.placeholder';

/*:: import type { Props } from './venues-list.placeholder.types';*/

export class VenuesListPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    venues: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <CardHeaderComponent title={{ id: 'VENUES.VENUES', values: { venues: undefined } }}>
          {checkPermissions(this.props.permissions, ['venues', 'create']) && (
            <ButtonComponent theme="primary">
              <FormattedMessage id="VENUES.ADD_NEW_VENUE" />
            </ButtonComponent>
          )}
        </CardHeaderComponent>
        <CardBodyComponent>
          <ListComponent list={this.props.venues} table={VenuesListTablePlaceholder} tile={VenuesListTilePlaceholder} />
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
