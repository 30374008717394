import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { withAuthorization } from 'app/common';
import { ROUTES } from 'app/constants';
import { fetchCurrentCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchCouponType } from 'app/redux/types/types.actions';

import { CouponsTypeEditComponent } from './coupons-type-edit.component';

const mapStateToProps = (
  {
    customers: {
      entities: { current },
    },
    dictionaries,
    types: { entities: types },
    users: { business },
  },
  {
    match: {
      params: { couponType },
    },
  },
) => ({
  business,
  current,
  couponType: types[couponType],
  loading: !dictionaries['municipal/services'] || !dictionaries['price-units'] || !types[couponType] || !current,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { couponType },
    },
  },
) => ({
  fetchCouponType: () => dispatch(fetchCouponType(couponType)),
  fetchCurrentCustomer: () => dispatch(fetchCurrentCustomer()),
  fetchDictionaries: () =>
    Promise.all([dispatch(fetchDictionary('municipal/services', true)), dispatch(fetchDictionary('price-units'))]),
  onBusinessChange: () => dispatch(push(ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT)),
});

export const CouponsTypeEditContainer = compose(
  withAuthorization(['serviceCouponTypes', 'update']),
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsTypeEditComponent);
