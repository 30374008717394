// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { shouldFieldArrayUpdate } from 'app/utils';

import { CardFooterComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';
import { DEFAULT_EMPTY_CONTRACT } from 'app/utils';

import { ContractContainer } from './contract/contract.container';

/*:: import type { Props } from './contracts-lists.component.types';*/

export class ContractsListsComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    showAddButton: true,
    showHeader: true,
  };

  shouldComponentUpdate = shouldFieldArrayUpdate;

  render() {
    return (
      <>
        {this.props.fields.map((field, index) => (
          <ContractContainer
            key={index}
            field={field}
            index={index}
            remove={this.props.fields.remove}
            isMoreThanOneContract={this.props.fields.length > 1}
            form={this.props.meta.form}
            showHeader={this.props.showHeader}
          />
        ))}
        {this.props.showAddButton && (
          <CardFooterComponent>
            <ButtonComponent onClick={() => this.props.fields.push(DEFAULT_EMPTY_CONTRACT)} theme="secondary-outline">
              <FormattedMessage id="REGISTER.CONTRACT.ADD" />
            </ButtonComponent>
          </CardFooterComponent>
        )}
      </>
    );
  }
}
