import { SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import React, { FunctionComponent } from 'react';
import { PostpayServiceVoucher } from 'v2/types/postpay';
import { TranslationKey } from 'v2/types/translations';
import { DetailsField } from './details-field.component';

import styles from './service-voucher-details.module.scss';

export type ServiceVoucherDetailsComponentProperties = {
  voucher: PostpayServiceVoucher;
  title: TranslationKey;
  actionButtonsLeft?: React.ReactNode[];
  actionButtonsRight?: React.ReactNode[];
};

export const ServiceVoucherDetailsComponent: FunctionComponent<ServiceVoucherDetailsComponentProperties> = ({
  voucher,
  title,
  actionButtonsLeft,
  actionButtonsRight,
}) => {
  return (
    <CardComponent>
      <CardHeaderComponent title={title} />
      <CardBodyComponent>
        {voucher.groupedProperties.map(({ groupLabel, properties }) => (
          <React.Fragment key={groupLabel}>
            <SeparatorComponent />
            {properties.map(({ label, type, value }, index) => (
              <DetailsField key={`${type}-${label}-${value}`} label={{ value: label }} highlight={index === 0}>
                {value}
              </DetailsField>
            ))}
          </React.Fragment>
        ))}
      </CardBodyComponent>
      {(actionButtonsLeft || actionButtonsRight) && (
        <>
          <SeparatorComponent height={40} />
          <CardFooterComponent>
            <div className={styles.actionButtonGroup}>
              {actionButtonsLeft && actionButtonsLeft.length > 0
                ? actionButtonsLeft.map((button, index) => (
                    <div key={index} className={styles.actionButton}>
                      {button}
                    </div>
                  ))
                : undefined}
            </div>
            <div className={styles.actionButtonGroup}>
              {actionButtonsRight && actionButtonsRight.length > 0
                ? actionButtonsRight.map((button, index) => (
                    <div key={index} className={styles.actionButton}>
                      {button}
                    </div>
                  ))
                : undefined}
            </div>
          </CardFooterComponent>
        </>
      )}
    </CardComponent>
  );
};
