import { FunctionComponent } from 'react';
import { Typography } from 'app/shared/typography/typography.component';
import { useIntl } from 'app/hooks/useIntl/useIntl';
import { TranslationKey } from 'v2/types/translations';

export interface ErrorsComponentProps {
  errors: { id: TranslationKey }[];
}

export const ErrorsComponent: FunctionComponent<ErrorsComponentProps> = ({ errors }) => {
  const intl = useIntl();

  const composedMessage = errors.map(error => intl.formatMessage(error)).join(', ');
  return <Typography color="failure">{composedMessage}</Typography>;
};
