// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray, FormSection } from 'redux-form';

import { PageHeaderComponent } from 'app/core';
import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  SeparatorComponent,
  Typography,
} from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { PersonalSectionContainer, VenuesListContainer } from 'app/register/shared';
import { removeWhiteSpaces } from './iban-sanitizer';

/*:: import type { Props } from './register-form.component.types';*/

export class RegisterFormComponent extends Component /*:: <Props>*/ {
  render() {
    const options = Object.entries(this.props.swiftCodes).map(([id, name]) => ({ id, name }));

    return (
      <>
        <PageHeaderComponent showBusinessName={false} title="REGISTER.TITLE" />

        <CardComponent>
          <CardHeaderComponent
            direction="column"
            alignItems="flex-start"
            title="REGISTER.INSTRUCTION_BOX.GENERAL_COMPANY_DETAILS"
          >
            <SeparatorComponent />
            <Typography tag="h4" color="secondary" type="largeText">
              <FormattedMessage id="REGISTER.SECTION_TITLES.GENERAL_COMPANY" />
            </Typography>
          </CardHeaderComponent>
          <SeparatorComponent />
          <CardBodyComponent>
            <FormSection name="company">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="name"
                    component={ConnectedInputComponent}
                    label="REGISTER.OFFICIAL_NAME"
                    placeholder="REGISTER.OFFICIAL_NAME"
                    maxLength={50}
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="businessId"
                    required
                    component={ConnectedInputComponent}
                    label="REGISTER.BUSINESS_ID"
                    placeholder="REGISTER.BUSINESS_ID"
                  />
                </div>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="address"
                      component={ConnectedInputComponent}
                      label="REGISTER.STREET_ADDRESS"
                      placeholder="REGISTER.STREET_ADDRESS"
                      required
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="zipCode"
                      component={ConnectedInputComponent}
                      label="REGISTER.ZIP_CODE"
                      placeholder="REGISTER.ZIP_CODE"
                      required
                    />
                  </div>
                </FormSection>
                <FormSection name="address">
                  <div className="col-lg-6">
                    <Field
                      name="city"
                      component={ConnectedInputComponent}
                      label="REGISTER.CITY"
                      placeholder="REGISTER.CITY"
                      required
                    />
                  </div>
                </FormSection>
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    required
                    component={ConnectedInputComponent}
                    label="REGISTER.PHONE"
                    placeholder="REGISTER.PHONE"
                    append="REGISTER.PHONE_FORMAT"
                  />
                </div>
              </div>
            </FormSection>
          </CardBodyComponent>
          <CardHeaderComponent title="REGISTER.SECTION_TITLES.BANK_ACCOUNT" />
          <SeparatorComponent />
          <CardBodyComponent>
            <FormSection name="bankAccount">
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="iban"
                    component={ConnectedInputComponent}
                    label="REGISTER.ACCOUNT_NUMBER"
                    placeholder="REGISTER.ACCOUNT_NUMBER"
                    normalize={removeWhiteSpaces}
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="swift"
                    required
                    component={ConnectedSelectComponent}
                    options={options}
                    label="REGISTER.SWIFT"
                  />
                </div>
              </div>
            </FormSection>
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />

        <CardComponent>
          <PersonalSectionContainer name="responsiblePerson">
            <Typography type="subtitle" color="grey">
              <FormattedMessage id="REGISTER.INSTRUCTION_BOX.PERSON_RESPONSIBLE_INFO" />
            </Typography>
            <SeparatorComponent />
            <Typography tag="h4" color="secondary" type="largeText">
              <FormattedMessage id="REGISTER.SECTION_TITLES.PERSON_RESPONSIBLE" />
            </Typography>
          </PersonalSectionContainer>
        </CardComponent>
        <SeparatorComponent />
        <FieldArray name="venues" component={VenuesListContainer} locale={this.props.intl.locale} />

        <CardFooterComponent justifyContent="flex-end">
          <ButtonComponent theme="primary" loading={this.props.submitting}>
            <FormattedMessage id="CORE.NEXT" /> <i className="fi fi-right-arrow-forward" />
          </ButtonComponent>
        </CardFooterComponent>
        <SeparatorComponent />
      </>
    );
  }
}
