// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, SeparatorComponent, TileComponent, Typography } from 'app/shared';
import { checkPermissions, currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { CouponsDescriptiveServiceContainer } from 'app/coupons/shared';

/*:: import type { Props } from './transactions-municipal-beneficiary-tile.component.types';*/
import styles from 'app/transactions/municipal/table/transactions-municipal-table.module.scss';
import { createVoucherTransactionPath } from 'app/transactions/utils/create-transaction-path/create-transaction-path.util';

export class TransactionsMunicipalBeneficiaryTileComponent extends Component /*:: <Props>*/ {
  render() {
    const tileClassNames =
      !!this.props.tile.refundedBy || !!this.props.tile.refunds ? { className: 'filled-tile' } : {};

    return (
      <TileComponent {...tileClassNames}>
        <Typography type="subtitle" color="greyDark">
          {dateFormatter(this.props.tile.date, true)}
        </Typography>

        <div className="row">
          <div className="col-md-8">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.SERVICE_COUPON" />

            <Typography type="bodyText" tag="span" color="greyDark">
              {this.props.tile.decision}
            </Typography>
            <br />
            <Typography type="bodyText" tag="span" color="greyDark">
              {this.props.tile.couponType}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.AMOUNT" />

            <Typography type="bodyText" tag="strong" color={this.props.tile.amount > 0 ? 'success' : 'failure'}>
              {currencyFormatter.format(this.props.tile.amount)}
            </Typography>
          </div>
          <div className="col-md-8">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.DEBTOR_CREDITOR" />
            <Typography type="bodyText" tag="span" color="greyDark">
              {this.props.tile.creditor}
            </Typography>
          </div>
          <div className="col-md-4">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.TRANSACTION_TYPE" />
            <Typography type="bodyText" tag="span" color="greyDark" id={`TRANSACTION_TYPE.${this.props.tile.type}`} />
            {!!this.props.tile.refundedBy && (
              <>
                <Typography type="bodyText" tag="span" color="greyDark">{` - `}</Typography>
                <Typography
                  className={classNames(styles.returnedLabel)}
                  color="greyDark"
                  id={
                    this.props.tile.type === 'TRANSACTION_TYPE_PAYMENT'
                      ? 'TRANSACTIONS.REFUNDED_LABEL'
                      : 'TRANSACTIONS.RETURNED_LABEL'
                  }
                  tag="span"
                  type="bodyText"
                />
              </>
            )}
          </div>
          {!!this.props.tile.descriptiveService && (
            <div className="col-md-6">
              <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.PRODUCED_SERVICES" />

              <Typography type="bodyText" tag="span" color="greyDark">
                <CouponsDescriptiveServiceContainer service={this.props.tile.descriptiveService} />
              </Typography>
            </div>
          )}
        </div>
        {checkPermissions(this.props.permissions, ['transactionDetails', 'read']) && (
          <div className="row">
            <div className="col-12 mt-0">
              <SeparatorComponent />
              <ButtonComponent
                theme="secondary-outline"
                to={{
                  pathname: `/transactions/municipal-beneficiary/${createVoucherTransactionPath(this.props.tile)}`,
                  state: {
                    keepScrollPosition: true,
                  },
                }}
              >
                <i className="fi fi-open" />
                <FormattedMessage id="TRANSACTIONS.OPEN" />
              </ButtonComponent>
            </div>
          </div>
        )}
      </TileComponent>
    );
  }
}
