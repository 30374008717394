import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';

import { download } from 'app/redux/downloads/downloads.actions';
import { fetchSettlements } from 'app/redux/settlements/settlements.actions';

import { SettlementsListComponent } from './settlements-list.component';

const mapStateToProps = ({ settlements: { list: settlements }, users: { business } }) => ({
  business,
  settlements,
  loading: !settlements,
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchSettlements: () => dispatch(fetchSettlements()),
  onDownload: settlement => dispatch(download({ path: `settlements/${settlement}`, locale })),
});

export const SettlementsListContainer = compose(
  withAuthorization(['settlements', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SettlementsListComponent);
