//@flow
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { useIntl, useIsMounted } from 'app/hooks';
import { translate } from 'app/utils';
import { SeparatorComponent } from 'app/shared';
import { notificationTypes } from 'dictionaries/notifications.dictionary';

import styles from './notification.module.scss';
/*:: import type { Props } from './notification.component.types';*/

export const NotificationComponent = (props /*: Props*/) => {
  const { message, title, type, id, timeout = null, remove } = props;
  const intl = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const timeoutRef = useRef();

  useIsMounted({ onMount: () => setIsMounted(true) });

  const handleClose = () => {
    setIsMounted(false);
  };

  const handleAutoClose = () => {
    if (type !== notificationTypes.FAILURE && timeout !== null) {
      timeoutRef.current = setTimeout(handleClose, timeout);
    }
  };

  useEffect(() => {
    clearTimeout(timeoutRef.current);
  }, []);

  const cardClassNames = classNames(styles.notification, {
    [styles.visible]: isMounted,
    [styles[type]]: !!type,
  });

  return (
    <Transition in={isMounted} timeout={300} onExited={() => remove(id)} onEntered={handleAutoClose}>
      {() => {
        return (
          <CardComponent className={cardClassNames}>
            <CardHeaderComponent>
              {translate({ intl, translation: title })}
              <i onClick={handleClose} className={classNames('fi fi-remove', styles.close)} />
            </CardHeaderComponent>
            <SeparatorComponent height={8} />
            {!!message && (
              <CardBodyComponent className={styles.message}>
                {translate({ intl, translation: message })}
              </CardBodyComponent>
            )}
          </CardComponent>
        );
      }}
    </Transition>
  );
};
