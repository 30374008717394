import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormData, FormValidationErrors, PostpayServiceVoucher } from 'v2/types/postpay';
import { createFormUpdateReducers, FormState } from 'v2/utils/forms';
import { Embedded, getEmbedded } from 'v2/utils/links';
import { isServiceVoucherLoaded } from './utils/is-service-voucher-loaded';

export enum ServiceVoucherStatus {
  Ok = 'ok',
  Refreshing = 'refreshing',
  Loading = 'loading',
  NotFound = 'not-found',
}

export enum ServiceVoucherIssuingStatus {
  NotSubmitted = 'not-submitted',
  Submitting = 'submitting',
  SubmitSuccess = 'submit-success',
  // SubmitFailed = 'submit-failed', // failed state is not handled gracefully
}

export type ServiceVoucherLoaded = {
  status: ServiceVoucherStatus.Ok | ServiceVoucherStatus.Refreshing;
  voucher: PostpayServiceVoucher;
};

export type ServiceVoucherResult =
  | { status: ServiceVoucherStatus.Loading | ServiceVoucherStatus.NotFound }
  | ServiceVoucherLoaded;

export type IssueVoucherFormState = {
  voucherDetailsFormData: FormData;
  citizenDetailsFormData: FormData;
};

type PostpayVoucherId = string;
export type ServiceVouchersState = {
  vouchers: Record<PostpayVoucherId, ServiceVoucherResult | undefined>;
  issuing: Record<
    PostpayVoucherId,
    | {
        status: ServiceVoucherIssuingStatus.NotSubmitted;
        formState: FormState<IssueVoucherFormState>;
        voucherDetailsFormValidationErrors?: FormValidationErrors;
        citizenDetailsFormValidationErrors?: FormValidationErrors;
      }
    | {
        status: Exclude<ServiceVoucherIssuingStatus, ServiceVoucherIssuingStatus.NotSubmitted>;
      }
    | undefined
  >;
};

export type VoucherRequestedPayload = { voucherId: string };
export type VoucherRefreshRequestedPayload = { voucherId: string };
export type VoucherRequestCompletion =
  | {
      id: string;
      status: ServiceVoucherStatus.NotFound;
    }
  | {
      id: string;
      status: ServiceVoucherStatus.Ok;
      voucher: PostpayServiceVoucher;
    };

export type VoucherCreationRequestedPayload = { voucherTypeId: string };
export type VoucherCreationResponsePayload = {
  id: string;
  status: ServiceVoucherStatus;
  voucher: PostpayServiceVoucher;
};

export type DraftVoucherFormUpdatedPayload = {
  voucherId: string;
  formState: IssueVoucherFormState;
};

type DraftVoucherUpdateCompletedSuccessPayload = {
  status: ServiceVoucherStatus.Ok;
  voucher: PostpayServiceVoucher;
  voucherDetailsFormValidationErrors?: FormValidationErrors;
  citizenDetailsFormValidationErrors?: FormValidationErrors;
};

type DraftVoucherUpdateCompletedFailurePayload = {
  status: ServiceVoucherStatus.NotFound;
  voucherId: PostpayVoucherId;
};

export type DraftVoucherUpdateCompletedPayload =
  | DraftVoucherUpdateCompletedSuccessPayload
  | DraftVoucherUpdateCompletedFailurePayload;

export type DraftVoucherIssuingRequestedPayload = {
  voucherId: PostpayVoucherId;
};

export type DraftVoucherIssuingCompletedPayload = {
  voucher: PostpayServiceVoucher;
};

export type DraftVoucherFormUpdateSubmittedPayload = {
  voucherId: string;
};

export interface StateSlice {
  'service-vouchers': ServiceVouchersState;
}

const initialState: ServiceVouchersState = { vouchers: {}, issuing: {} };

function issueVoucherFormStateFromVoucher(voucher: PostpayServiceVoucher): IssueVoucherFormState | undefined {
  const voucherTemplate = getEmbedded(voucher, Embedded.PostpayServiceVoucher.IssueVoucherTemplate);
  const {
    voucherDetailsForm: { data: serverVoucherDetailsFormData },
    citizenDetailsForm: { data: serverCitizenDetailsFormData },
  } = voucherTemplate || { voucherDetailsForm: {}, citizenDetailsForm: {} };

  if (serverVoucherDetailsFormData && serverCitizenDetailsFormData) {
    return {
      voucherDetailsFormData: serverVoucherDetailsFormData,
      citizenDetailsFormData: serverCitizenDetailsFormData,
    };
  }
  return undefined;
}

const slice = createSlice({
  name: 'service-vouchers',
  initialState,
  reducers: {
    voucherRequested(state, action: PayloadAction<VoucherRequestedPayload>) {
      const { voucherId } = action.payload;
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          [voucherId]: { status: ServiceVoucherStatus.Loading },
        },
      };
    },
    voucherRefreshRequested(state, action: PayloadAction<VoucherRefreshRequestedPayload>) {
      const { voucherId } = action.payload;
      const voucherState = state.vouchers[voucherId];
      switch (voucherState?.status) {
        case ServiceVoucherStatus.Ok:
          return {
            ...state,
            vouchers: {
              ...state.vouchers,
              [voucherId]: {
                status: ServiceVoucherStatus.Refreshing,
                voucher: voucherState.voucher,
              },
            },
          };

        default:
          return state;
      }
    },
    voucherRequestCompleted(state, action: PayloadAction<VoucherRequestCompletion>) {
      const voucherId = action.payload.id;
      switch (state.vouchers[voucherId]?.status) {
        case ServiceVoucherStatus.Loading:
        case ServiceVoucherStatus.Refreshing:
          const { id, ...value } = action.payload;
          return {
            ...state,
            vouchers: {
              ...state.vouchers,
              [voucherId]: value,
            },
          };
        default:
          return state;
      }
    },

    voucherCreationRequested(state, _action: PayloadAction<VoucherCreationRequestedPayload>) {
      return state;
    },
    voucherCreationCompleted(state, action: PayloadAction<VoucherCreationResponsePayload>) {
      const voucherId = action.payload.id;
      switch (action.payload.status) {
        case ServiceVoucherStatus.Ok:
          const { id, ...value } = action.payload;

          return {
            ...state,
            vouchers: {
              ...state.vouchers,
              [voucherId]: value,
            },
          };
        default:
          return state;
      }
    },

    draftVoucherIssuingRequested(state, action: PayloadAction<DraftVoucherIssuingRequestedPayload>) {
      const { voucherId } = action.payload;
      state.issuing[voucherId] = {
        status: ServiceVoucherIssuingStatus.Submitting,
      };
    },
    draftVoucherIssuingCompleted(state, action: PayloadAction<DraftVoucherIssuingCompletedPayload>) {
      const { voucher } = action.payload;
      const issuingState = state.issuing[voucher.id];
      if (issuingState && issuingState.status === ServiceVoucherIssuingStatus.Submitting) {
        issuingState.status = ServiceVoucherIssuingStatus.SubmitSuccess;
      }

      state.vouchers[voucher.id] = {
        status: ServiceVoucherStatus.Ok,
        voucher,
      };
    },

    ...createFormUpdateReducers<
      'draftVoucher',
      ServiceVouchersState,
      IssueVoucherFormState,
      PostpayVoucherId,
      DraftVoucherFormUpdatedPayload,
      DraftVoucherFormUpdateSubmittedPayload,
      DraftVoucherUpdateCompletedPayload,
      DraftVoucherUpdateCompletedSuccessPayload
    >('draftVoucher', {
      extractKey: action => ('voucherId' in action.payload ? action.payload.voucherId : action.payload.voucher.id),
      formStateSelector: (state, voucherId) => {
        const issuingState = state.issuing[voucherId];
        return issuingState && issuingState.status === ServiceVoucherIssuingStatus.NotSubmitted
          ? issuingState.formState
          : undefined;
      },
      serverStateSelector: (state, voucherId) => {
        const voucherState = state.vouchers[voucherId];
        if (voucherState?.status === ServiceVoucherStatus.Ok) {
          const { voucher } = voucherState;
          return issueVoucherFormStateFromVoucher(voucher);
        }

        return undefined;
      },
      serverStateFromUpdateSuccessPayloadSelector: payload => {
        const formTemplate = payload.voucher._embedded[Embedded.PostpayServiceVoucher.IssueVoucherTemplate];
        if (formTemplate) {
          return {
            voucherDetailsFormData: formTemplate.voucherDetailsForm.data,
            citizenDetailsFormData: formTemplate.citizenDetailsForm.data,
          };
        }
      },
      wasUpdateSuccess: (_state, payload): payload is DraftVoucherUpdateCompletedSuccessPayload =>
        payload.status === ServiceVoucherStatus.Ok,
      setFormState: (state, voucherId, formState) => {
        state.issuing[voucherId] = formState
          ? {
              ...state.issuing[voucherId],
              formState,
              status: ServiceVoucherIssuingStatus.NotSubmitted,
            }
          : undefined;
      },
      onSubmitted: (state, voucherId) => {
        const voucherState = state.vouchers[voucherId];
        state.vouchers[voucherId] =
          voucherState && isServiceVoucherLoaded(voucherState)
            ? {
                status: ServiceVoucherStatus.Refreshing,
                voucher: voucherState.voucher,
              }
            : { status: ServiceVoucherStatus.Loading };
      },
      onSubmitSuccess: (
        state,
        voucherId,
        { voucher, voucherDetailsFormValidationErrors, citizenDetailsFormValidationErrors },
      ) => {
        state.vouchers[voucherId] = {
          voucher,
          status: ServiceVoucherStatus.Ok,
        };

        const issuingState = state.issuing[voucherId];
        if (issuingState && issuingState.status === ServiceVoucherIssuingStatus.NotSubmitted) {
          issuingState.voucherDetailsFormValidationErrors = voucherDetailsFormValidationErrors;
          issuingState.citizenDetailsFormValidationErrors = citizenDetailsFormValidationErrors;
        }
      },
      onSubmitFailure: (state, voucherId) => {
        state.vouchers[voucherId] = {
          status: ServiceVoucherStatus.NotFound,
        };

        delete state.issuing[voucherId];
      },
    }),
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
