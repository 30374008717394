import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostpayVoucherType, PostpayVoucherTypeId } from 'v2/types/postpay';

export const sliceName = 'voucher-type';

export enum VoucherTypeStatus {
  Loading = 'loading',
  Ok = 'ok',
}

export type VoucherTypeLoaded = {
  status: VoucherTypeStatus.Ok;
  voucherType: PostpayVoucherType;
};

export type VoucherTypeResult = { status: VoucherTypeStatus.Loading } | VoucherTypeLoaded;

export type VoucherTypeState = {
  [voucherTypeId: PostpayVoucherTypeId]: VoucherTypeResult;
};

export const initialState: VoucherTypeState = {};

export type VoucherTypeRequestedPayload = {
  voucherTypeId: PostpayVoucherTypeId;
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    voucherTypeRequested(state, action: PayloadAction<VoucherTypeRequestedPayload>) {
      const { voucherTypeId } = action.payload;
      return {
        ...state,
        [voucherTypeId]: { status: VoucherTypeStatus.Loading },
      };
    },
    voucherTypeRequestCompleted(state, action: PayloadAction<PostpayVoucherType>) {
      const { id: voucherTypeId } = action.payload;
      return {
        ...state,
        [voucherTypeId]: { status: VoucherTypeStatus.Ok, voucherType: action.payload },
      };
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [sliceName]: slice.reducer,
};

export type StateSlice = {
  [sliceName]: VoucherTypeState;
};
