// @flow
import React, { useMemo } from 'react';
import { Field, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import { combine, url } from 'redux-form-validators';

import { useSelector } from 'app/hooks/redux';
import { ConnectedInputComponent, ExternalLink, FieldValueRenderer } from 'app/shared';
import { length } from 'app/utils';
import { PriceListTableCellComponent } from '../price-list-table/price-list-table-cell.component';

/*:: import type { FieldProps } from 'redux-form/es';*/
/*:: import type { Props } from './price-list-link.component.type.js.flow';*/

const MAX_URL_LENGTH = 1000;

const LinkValueRenderer = (props /*: FieldProps*/) /*: React$Element<typeof ExternalLink>*/ => {
  const link = get(props, ['input', 'value']);

  return <ExternalLink href={link}>{link}</ExternalLink>;
};

const urlValidator = url({ message: { id: 'VALIDATION.INVALID_URL' }, allowBlank: true });
const lengthValidator = length({ max: MAX_URL_LENGTH, allowBlank: true });

export const PriceListLinkComponent = (props /*: Props*/) => {
  const {
    name,
    editMode,
    editable,
    meta: { form },
    showDiff,
  } = props;

  const selector = useMemo(() => formValueSelector(form), [form]);
  const { label, link, oldValue } = useSelector(state => selector(state, `${name}`)) || {};

  // Empty links should be hidden in no edit mode
  if (link === '' && !editable && !showDiff) {
    return null;
  }

  return (
    <div className="row">
      <div className="col col-lg-6">
        <Field
          component={editable ? ConnectedInputComponent : showDiff ? FieldValueRenderer : LinkValueRenderer}
          name={`${name}.link`}
          disabled={!editMode}
          label={!showDiff && label}
          validate={editMode ? combine(urlValidator, lengthValidator) : undefined}
          {...(!editable && showDiff
            ? {
                wrapper: () => (
                  <PriceListTableCellComponent tableCellField={{ value: link, oldValue }} showDiff={showDiff} />
                ),
              }
            : {})}
        />
      </div>
    </div>
  );
};
