export const FETCH_COUPON_TYPES_REQUEST = 'types/FETCH_COUPON_TYPES_REQUEST';
export const FETCH_COUPON_TYPES_SUCCESS = 'types/FETCH_COUPON_TYPES_SUCCESS';
export const FETCH_COUPON_TYPES_FAILURE = 'types/FETCH_COUPON_TYPES_FAILURE';

export const FETCH_COUPON_TYPE_REQUEST = 'types/FETCH_COUPON_TYPE_REQUEST';
export const FETCH_COUPON_TYPE_SUCCESS = 'types/FETCH_COUPON_TYPE_SUCCESS';
export const FETCH_COUPON_TYPE_FAILURE = 'types/FETCH_COUPON_TYPE_FAILURE';

export const ADD_COUPON_TYPE_REQUEST = 'types/ADD_COUPON_TYPE_REQUEST';
export const ADD_COUPON_TYPE_SUCCESS = 'types/ADD_COUPON_TYPE_SUCCESS';
export const ADD_COUPON_TYPE_FAILURE = 'types/ADD_COUPON_TYPE_FAILURE';

export const EDIT_COUPON_TYPE_REQUEST = 'types/EDIT_COUPON_TYPE_REQUEST';
export const EDIT_COUPON_TYPE_SUCCESS = 'types/EDIT_COUPON_TYPE_SUCCESS';
export const EDIT_COUPON_TYPE_FAILURE = 'types/EDIT_COUPON_TYPE_FAILURE';
