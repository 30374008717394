//@flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  ConnectedInputComponent,
  ConnectedRadiosComponent,
  ConnectedSelectComponent,
  Flag,
  SeparatorComponent,
  Typography,
} from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';

import { currencyFormatter, handleAnalyticsEvent } from 'app/utils';

import { CouponsShareSmsContainer } from './sms/coupons-share-sms.container';
import flagFi from 'assets/images/flags/flag_finnish.svg';
import flagSv from 'assets/images/flags/flag_swedish.svg';

/*:: import type { Props, State } from './coupons-downloads-pdf-file.component.types';*/
import styles from './coupons-downloads-pdf-file.component.module.scss';

const flags /*: { [string]: string }*/ = {
  fi: flagFi,
  sv: flagSv,
};

const VOUCHER = 'voucher';
const TABLE = 'table';

export class CouponsDownloadPdfFileComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      transfers: (props.transfers || []).map(({ starts, expires, balance, id }) => ({
        name: `${starts.replace(/-/g, '.')} - ${expires.replace(/-/g, '.')} | ${currencyFormatter.format(balance)}`,
        id,
      })),
    };
  }

  handleOnClick = (type /*: string*/) => {
    this.handleTypeChange(type);

    handleAnalyticsEvent('vouchers', 'print', type);
  };

  handleTypeChange = (type /*: string*/) => {
    const { changeDownloadType, submit } = this.props;

    new Promise(resolve => resolve(changeDownloadType(type))).then(() => submit());
  };

  render() {
    const { buttonsClassName, coupons, lang, sectionClassName, transfers, handleSubmit, submitting, type } = this.props;

    const couponForProviderDownload = coupons.length === 1 ? coupons[0] : null;

    const flagOptions = Object.keys(flags).map((lang /*: string*/) => ({
      id: lang,
      name: <Flag flag={flags[lang]} alt={lang} />,
    }));

    const separatorHeight = 15;
    const sectionClassNames = classNames(styles.button, sectionClassName);
    const buttonsClassNames = classNames(styles.buttons, buttonsClassName);
    const commonTypographyProps = {
      type: 'subtitle',
      color: 'grey',
    };

    const isVoucherPrintDisabled = !coupons.length || (submitting && type === TABLE);
    const isTablePrintDisabled = !coupons.length || this.props.invalid || (submitting && type === VOUCHER);

    return (
      <>
        <CardComponent>
          <CardHeaderComponent title={{ id: 'COUPONS.SIDEBAR.PDF.TITLE', values: { coupons: coupons.length } }} />
          <SeparatorComponent height={separatorHeight} />
          <CardBodyComponent>
            <form onSubmit={handleSubmit} className={styles.form}>
              {transfers && (
                <>
                  <Typography {...commonTypographyProps} id="COUPONS.SIDEBAR.PDF.SELECT_TRANSFER" />
                  <Field name="transferId" component={ConnectedSelectComponent} options={this.state.transfers} />
                  <SeparatorComponent height={separatorHeight} />
                </>
              )}
              <Typography {...commonTypographyProps} id="COUPONS.SIDEBAR.PDF.CARD_TITLE" />
              <SeparatorComponent height={8} />
              <Field
                name="lang"
                component={ConnectedRadiosComponent}
                options={flagOptions}
                itemProps={{ labelPosition: 'bottom-center' }}
                columns={2}
                columnAlign="center"
                required
              />
              <SeparatorComponent height={8} />

              <Typography {...commonTypographyProps} className="mb-2" id="COUPONS.SIDEBAR.PDF.PRINT_TITLE" />
              <div className={buttonsClassNames}>
                <ButtonComponent
                  disabled={isVoucherPrintDisabled}
                  theme="primary-outline"
                  className={sectionClassNames}
                  onClick={() => this.handleOnClick(VOUCHER)}
                  loading={submitting && type === VOUCHER}
                >
                  <i className="fi fi-download-voucher" />
                  <FormattedMessage id="COUPONS.SIDEBAR.PDF.PRINT" values={{ coupons: coupons.length }} />
                </ButtonComponent>
                <SeparatorComponent height={separatorHeight} />
                <ButtonComponent
                  className={classNames(sectionClassNames, styles.downloadTableButton)}
                  onClick={() => this.handleOnClick(TABLE)}
                  disabled={isTablePrintDisabled}
                  theme="primary-outline"
                  loading={submitting && type === TABLE}
                >
                  <i className="fi fi-download-voucher-table" />
                  <FormattedMessage id="COUPONS.SIDEBAR.PDF.PRINT_TABLE" values={{ coupons: coupons.length }} />
                  <Typography
                    className="ml-auto px-2 flex-grow-1 text-right"
                    tag="span"
                    type="smallText"
                    color="grey"
                    id="COUPONS.SIDEBAR.PDF.NUMBER_OF_PAGES"
                  />
                  <Field
                    component={ConnectedInputComponent}
                    name="pages"
                    type="tel"
                    min={1}
                    max={50}
                    onClick={e => e.stopPropagation()}
                    mask={{
                      mask: '99',
                      maskChar: '',
                    }}
                    className={styles.tableInput}
                  />
                </ButtonComponent>
              </div>
            </form>
            {this.props.error ? (
              <Typography className="text-right" type="smallText" color="red" {...this.props.error} />
            ) : (
              <SeparatorComponent height={18} />
            )}
            <CouponsShareSmsContainer
              coupon={coupons.length === 1 ? coupons[0] : undefined}
              lang={lang}
              className={sectionClassName}
            />
          </CardBodyComponent>
        </CardComponent>

        {!!couponForProviderDownload && (
          <>
            <SeparatorComponent height={separatorHeight} />
            <CardComponent>
              <CardHeaderComponent title="COUPONS.SIDEBAR.PDF.PROVIDERS.CARD_TITLE" />
              <CardBodyComponent>
                <Typography color="grey" id="COUPONS.SIDEBAR.PDF.PROVIDERS.CARD_SUBTITLE" />
                <ButtonComponent
                  onClick={() => this.props.onProvidersDownload(this.props.business.id, lang, couponForProviderDownload)}
                  theme="primary-outline"
                  className={classNames(sectionClassNames, 'my-2')}
                >
                  <i className="fi fi-download-list" />
                  <FormattedMessage id="COUPONS.SIDEBAR.PDF.PROVIDERS.DOWNLOAD" />
                </ButtonComponent>
              </CardBodyComponent>
            </CardComponent>
          </>
        )}
      </>
    );
  }
}
