// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent } from 'app/shared';
import { PersonalContainer } from 'app/profile/personal/personal.container';
import { PasswordContainer } from 'app/profile/password/password.container';

export class ProfileComponent extends Component /*:: <any>*/ {
  render() {
    return (
      <>
        <PageHeaderComponent showBusinessName={false} title="PROFILE.TITLE" subtitle="PROFILE.DESCRIPTION" />

        <PersonalContainer />
        <SeparatorComponent />
        <PasswordContainer />
      </>
    );
  }
}
