//@flow
import React, { Component, isValidElement } from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

/*:: import type { Props } from './virtualized-list-row.component.types';*/

export const DELAY = 15;

export class VirtualizedListRowComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    measurementTriggers: [],
  };

  /*:: timeout: TimeoutID;*/

  constructor(props /*: Props*/) {
    super(props);

    window.addEventListener('resize', this.measure, true);
  }

  measure = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.props.measure(this), DELAY);
  };

  componentDidMount() {
    this.measure();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (!!this.props.measurementTriggers.length) {
      const prevPropsNext = pick(prevProps, this.props.measurementTriggers);
      const currentPropsNext = pick(this.props, this.props.measurementTriggers);

      if (!isEqual(prevPropsNext, currentPropsNext)) {
        this.measure();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    window.removeEventListener('resize', this.measure, true);
  }

  render() {
    return (
      <div style={this.props.style}>
        {
          //$FlowFixMe
          isValidElement(this.props.children)
            ? React.cloneElement(this.props.children, { measure: this.measure })
            : this.props.children
        }
      </div>
    );
  }
}
