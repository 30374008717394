// @flow

import React, { PureComponent } from 'react';

import { SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';
import { currencyFormatter } from 'app/utils';

/*:: import type { Props } from './coupons-type-form-modal.types';*/

export class CouponsTypeFormModalComponent extends PureComponent /*:: <Props>*/ {
  getDescriptiveServiceName = (descriptiveService /*: string*/) /*: string*/ => {
    const haveTranslation = this.props.descriptiveServices.find(service => {
      return service.id === descriptiveService && !service.custom;
    });

    if (haveTranslation) {
      return this.props.intl.formatMessage({
        id: `COUPONS.DESCRIPTIVE_SERVICES.${descriptiveService}`,
      });
    }

    return descriptiveService;
  };

  render() {
    const {
      name,
      description,
      defaultValue,
      priceUnit,
      transactionMaxPriceEnabled,
      defaultTransactionMaxPrice,
      additionalInfo,
      services,
      descriptiveServices,
    } = this.props.values;

    const commonTypographyProps = {
      type: 'smallText',
      color: 'grey',
    };

    return (
      <>
        <CardHeaderComponent title="COUPONS.TYPES.MODAL.TITLE" typographyProps={{ color: 'primary' }} />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className="col-12">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.TITLE" />
              <Typography color="grey">{name}</Typography>
              <SeparatorComponent />
            </div>
            <div className="col-12">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.DESCRIPTION" />
              <Typography color="grey">{description || '-'}</Typography>
              <SeparatorComponent />
            </div>
            <div className="col-6">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.VALUE" />
              <Typography color="grey">{currencyFormatter.format(defaultValue)}</Typography>
              <SeparatorComponent />
            </div>
            <div className="col-6">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.PRICE_UNIT" />
              <Typography color="grey">
                {priceUnit ? this.props.intl.formatMessage({ id: `COUPONS.TYPES.UNITS.${priceUnit}` }) : '-'}
              </Typography>
              <SeparatorComponent />
            </div>
            {transactionMaxPriceEnabled && (
              <div className="col-12">
                <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.MAX_TRANSACTION" />
                <Typography color="grey">{currencyFormatter.format(defaultTransactionMaxPrice)}</Typography>
                <SeparatorComponent />
              </div>
            )}
            <div className="col-12">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.ADDITIONAL" />
              <Typography color="grey">{additionalInfo || '-'}</Typography>
              <SeparatorComponent />
            </div>
            <div className="col-12">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.SERVICE_TYPE" />
              <Typography color="grey">
                {(services || []).map((serviceId, index) => {
                  const serviceName = (this.props.services.find(service => service.id === serviceId) || {}).name;
                  const comma = services.length - 1 !== index ? ', ' : '';

                  return serviceName + comma;
                })}
              </Typography>
              <SeparatorComponent />
            </div>
            <div className="col-12">
              <Typography {...commonTypographyProps} id="COUPONS.TYPES.LABEL.DESCRIPTIVE_SERVICES" />
              <Typography color="grey">
                {descriptiveServices && descriptiveServices.length
                  ? descriptiveServices.map((descriptiveService, index) => {
                      const descriptiveServiceName = this.getDescriptiveServiceName(descriptiveService);

                      const comma = descriptiveServices.length - 1 !== index ? ', ' : '';

                      return descriptiveServiceName + comma;
                    })
                  : '-'}
              </Typography>
              <SeparatorComponent />
            </div>
          </div>
        </CardBodyComponent>
      </>
    );
  }
}
