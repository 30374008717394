export const REGISTER_REQUEST = 'register/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'register/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'register/REGISTER_FAILURE';

export const VALIDATE_REQUEST = 'register/VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'register/VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'register/VALIDATE_FAILURE';

export const FETCH_VENUE_TYPES_REQUEST = 'register/FETCH_VENUE_TYPES_REQUEST';
export const FETCH_VENUE_TYPES_SUCCESS = 'register/FETCH_VENUE_TYPES_SUCCESS';
export const FETCH_VENUE_TYPES_FAILURE = 'register/FETCH_VENUE_TYPES_FAILURE';

export const FILL_VALUES = 'register/FILL_VALUES';
