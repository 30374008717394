import { StatusCodes } from 'http-status-codes';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { GetVoucherTypesResponse, PostpayApiClient } from 'v2/api/postpay-api-client';
import { actions, StateStatus } from './voucher-types-slice';

export function* voucherTypesSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function* voucherTypesRequested() {
    const response: GetVoucherTypesResponse = yield call(apiClient.getVoucherTypes);
    switch (response.status) {
      case StatusCodes.OK:
        yield put(actions.voucherTypesRequestCompleted({ status: StateStatus.Loaded, voucherTypes: response.data }));
        break;
    }
  }

  yield takeEvery(actions.voucherTypesRequested.match, voucherTypesRequested);
}
