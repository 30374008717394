// @flow

import React, { createContext } from 'react';
/*:: import type { ComponentType } from 'react';*/

// $FlowFixMe
export const SidebarContext = createContext();

export const withSidebarContext = (WrappedComponent /*: ComponentType<any>*/) => (props /*: any*/) =>
  (
    <SidebarContext.Consumer>
      {container => <WrappedComponent {...props} container={container} />}
    </SidebarContext.Consumer>
  );
