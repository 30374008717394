import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';

import { editColumnsSettings } from 'app/redux/users/users.actions';

import { CouponsListCustomizeComponent } from './coupons-list-customize.component';

const sidebarColumnsKey = 'sidebarSelectedColumnsCache';

const mapStateToProps = ({
  coupons: {
    meta: {
      columns: { name },
    },
  },
}) => ({ listName: name });

const mapDispatchToProps = dispatch => ({
  onChange: ({ list, ...body }) => {
    localStorage.removeItem(sidebarColumnsKey);
    return withFormSubmissionErrorHandling(dispatch(editColumnsSettings(list, body)));
  },
});

export const CouponsListCustomizeContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsListCustomizeComponent);
