// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { VenuesListPlaceholder } from './placeholder/venues-list.placeholder';

import { PageHeaderComponent } from 'app/core';
import { AlertComponent, ButtonComponent, ListComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { checkPermissions, isBusinessDifferent } from 'app/utils';

import { VenuesListTableContainer } from './table/venues-list-table.container';
import { VenuesListTileContainer } from './tile/venues-list-tile.container';

/*:: import type { Props } from './venues-list.component.types';*/

export class VenuesListComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchVenues();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchVenues();
    }
  }

  render() {
    // Prevent the refinement from invalidating
    const venues = this.props.loading || this.props.venues;

    return (
      <>
        <PageHeaderComponent title="VENUES.VENUES_LIST" />

        {this.props.loading && <VenuesListPlaceholder permissions={this.props.permissions} />}

        {this.props.loading || (
          <>
            {!!this.props.location.state && !!this.props.location.state.success && (
              <AlertComponent type="success">
                {this.props.location.state.success.type === 'ADD_NEW_VENUE' && (
                  <FormattedMessage
                    id="VENUES.ADD_NEW_VENUE_SUCCESS"
                    values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                  />
                )}
                {this.props.location.state.success.type === 'EDIT_VENUE' && (
                  <FormattedMessage
                    id="VENUES.EDIT_VENUE_SUCCESS"
                    values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
                  />
                )}
              </AlertComponent>
            )}
            <CardComponent>
              <CardHeaderComponent title={{ id: 'VENUES.VENUES', values: { venues: this.props.venues.length } }}>
                {checkPermissions(this.props.permissions, ['venues', 'create']) && (
                  <ButtonComponent theme="primary" className="ml-auto" to={`${this.props.match.url}/add`}>
                    <FormattedMessage id="VENUES.ADD_NEW_VENUE" />
                  </ButtonComponent>
                )}
              </CardHeaderComponent>
              <CardBodyComponent>
                <Typography type="bodyText" tag="small" color="grey" id="VENUES.ACTION" className="pt-3 pb-2" />
                <ListComponent
                  list={venues}
                  table={VenuesListTableContainer}
                  tile={VenuesListTileContainer}
                  noResultsProps={{ showDesc: false }}
                />
              </CardBodyComponent>
            </CardComponent>
          </>
        )}
      </>
    );
  }
}
