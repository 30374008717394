// @flow

import React, { PureComponent } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';

import { CouponsDetailsTransactionsPlaceholder } from './placeholder/coupons-details-transactions.placeholder';
import { CouponsDetailsTransactionsTableComponent } from './table/coupons-details-transactions-table.component';
import { CouponsDetailsTransactionsTileComponent } from './tile/coupons-details-transactions-tile.component';

/*:: import type { Props } from './coupons-details-transactions.component.types';*/

export class CouponsDetailsTransactionsComponent extends PureComponent /*:: <Props>*/ {
  componentDidMount() {
    if (!this.props.isDictLoaded) {
      this.props.fetchDictionary();
    }
  }

  render() {
    return (
      <>
        <CardHeaderComponent title="COUPONS.TRANSACTIONS.TITLE" />
        <CardBodyComponent>
          {this.props.loading && <CouponsDetailsTransactionsPlaceholder />}
          {this.props.loading || (
            <ListComponent
              isCollapsed={false}
              limit={4}
              list={this.props.transactions}
              table={CouponsDetailsTransactionsTableComponent}
              tile={CouponsDetailsTransactionsTileComponent}
              collapsible
              noResultsProps={{ showDesc: false }}
            />
          )}
        </CardBodyComponent>
      </>
    );
  }
}
