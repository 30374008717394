// @flow

import React from 'react';

import { ConnectedDecimalInputComponent } from 'app/shared';

/*:: import type { Props } from './connected-money-input.component.types';*/

export const ConnectedMoneyInputComponent = (props /*: Props*/) => {
  const decimalValidatorConfig = { message: 'VALIDATION.INVALID_MONEY_FORMAT' };

  return (
    <ConnectedDecimalInputComponent icon="euro-symbol" decimalValidatorConfig={decimalValidatorConfig} {...props} />
  );
};
