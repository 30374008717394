// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { PageHeaderComponent } from 'app/core';
import { AlertComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';

import { checkPermissions, currencyFormatter, isBusinessDifferent, scrollToElement, shouldBlock } from 'app/utils';
import { LeaveConfirmationContainer } from 'app/core/confirm/leave/leave.confirmation.container';

import { CouponsListFiltersContainer } from './filters/coupons-list-filters.container';
import { CouponsListHeader } from './header/coupons-list-header.component';
import { CouponsListPlaceholder } from './placeholder/coupons-list.placeholder';
import { SidebarContainer } from 'app/shared/sidebar/sidebar.container';
import { CouponsVirtualizedListComponent } from './virtualized/coupons-list-virtualized.component';
import { Modal } from 'app/shared/modal-new/modal.container';
import { columnsName } from './virtualized/columns-names';

import { CouponsDownloadExcelFileContainer } from '../downloads/excel-file/coupons-downloads-excel-file.container';
import { CouponsDownloadPdfFileContainer } from '../downloads/pdf-file/coupons-downloads-pdf-file.container';

/*:: import type { Coupon, CouponsListForm, Props, State } from './coupons-list.component.types';*/
/*:: import type { FormRenderProps } from 'react-final-form';*/
/*:: import type { FormApi } from 'final-form';*/

export class CouponsListComponent extends Component /*:: <Props, State>*/ {
  renewModeColumns /*: string[]*/ = [columnsName.oneTimeLimitationInput, columnsName.valueAmountInput];

  stickyHeaderRef = React
    .createRef /*:: <any>*/
    ();

  constructor() {
    super();

    this.state = {
      showOnlySelected: false,
      renewMode: false,
      stickyHeaderEnabled: false,
      isRenewConfirmationOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      if (this.props.location.search) {
        this.props.history.replace({
          search: null,
        });
      } else {
        this.props.fetch();
      }

      this.props.resetFilters();
    }

    if (!this.props.loading && prevProps.loading) {
      this.setState({
        renewMode: false,
      });
    }
  }

  onScroll = () => {
    if (!this.stickyHeaderRef || !this.stickyHeaderRef.current) {
      return;
    }

    const dimensions = this.stickyHeaderRef.current.getBoundingClientRect();

    if (this.state.stickyHeaderEnabled && dimensions.top > 0) {
      this.setState({
        stickyHeaderEnabled: false,
      });
      return;
    }

    if (!this.state.stickyHeaderEnabled && dimensions.top <= 0) {
      this.setState({
        stickyHeaderEnabled: true,
      });
    }
  };

  deactivateInvalidatedCoupons = (values /*: CouponsListForm*/) /*: Coupon[]*/ =>
    values.coupons.map(coupon => (coupon.invalidated ? { ...coupon, active: false } : coupon));

  changeMode = () => {
    this.setState(prevState => ({
      renewMode: !prevState.renewMode,
    }));
  };

  showOnlySelectedToggle = () => {
    if (
      !this.state.showOnlySelected &&
      this.stickyHeaderRef &&
      this.stickyHeaderRef.current &&
      this.stickyHeaderRef.current.getBoundingClientRect().top < 10
    ) {
      scrollToElement('.list-wrapper');
    }

    this.setState(prev => ({ showOnlySelected: !prev.showOnlySelected }));
  };

  renderSubmitError = (submitErrors /*: ?{ coupons: { _error: string } }*/) => {
    let errorCode /*: string*/ = '';
    if (submitErrors && submitErrors.coupons && submitErrors.coupons._error) {
      errorCode = submitErrors.coupons._error;
    }

    return (
      <AlertComponent type="failure">
        {!!errorCode ? <FormattedMessage id={errorCode} /> : <FormattedMessage id="MODULES.CPS.RENEW.FAILURE" />}
      </AlertComponent>
    );
  };

  handleOnSubmit = async (values /*: { coupons: Coupon[] }*/) => {
    const formattedValues = {
      ...values,
      coupons: this.deactivateInvalidatedCoupons(values),
    };

    return await this.props.onSubmit(formattedValues, null, this.toggleConfirmationModal);
  };

  toggleConfirmationModal = () => this.setState({ isRenewConfirmationOpen: !this.state.isRenewConfirmationOpen });

  getColumns = ({ getState, change } /*: FormApi*/) => {
    const { renewMode } = this.state;
    const columns = (getState().values || {}).columns || [];
    const filteredColumns = columns.filter(({ checked }) => checked).map(({ id }) => id);

    change(
      'columns',
      columns.map(column => ({
        ...column,
        ...(column.id === columnsName.maxTransaction
          ? { checked: renewMode ? true : column.checked, disabled: renewMode }
          : {}),
      })),
    );

    if (renewMode) {
      return [...filteredColumns, ...this.renewModeColumns];
    }

    return filteredColumns;
  };

  render() {
    const noResults = this.props.loading || !this.props.coupons.length;
    const { totalCount, limit } = this.props.meta;
    const canDownloadCoupons = checkPermissions(this.props.permissions, ['serviceCouponPrint', 'read']);

    return (
      <>
        <PageHeaderComponent title="NAV.COUPONS.COUPONS" />

        <CouponsListFiltersContainer form="filter-coupons" fetch={this.props.fetch} />

        <SeparatorComponent />
        {this.props.loading && (
          <CardComponent>
            <CouponsListPlaceholder />
          </CardComponent>
        )}

        {this.props.loading || (
          <Form
            onSubmit={this.handleOnSubmit}
            subscription={{
              submitting: true,
              submitErrors: true,
              submitFailed: true,
              submitSucceeded: true,
              dirtySinceLastSubmit: true,
              dirty: true,
            }}
            mutators={arrayMutators}
            initialValues={this.props.initialValues}
          >
            {({ form, handleSubmit, submitErrors, submitFailed } /*: FormRenderProps*/) => (
              <>
                {submitFailed && this.renderSubmitError(submitErrors)}

                <LeaveConfirmationContainer block={this.state.renewMode && shouldBlock(form.getState())} />

                {totalCount > limit && (
                  <AlertComponent type="failure">
                    <span className="text-danger">
                      {this.props.intl.formatMessage({ id: 'COUPONS.LIST.LIMIT_MESSAGE.RED_TEXT' }, { limit })}
                    </span>
                    &nbsp;
                    {this.props.intl.formatMessage({ id: 'COUPONS.LIST.LIMIT_MESSAGE.TEXT' })}
                  </AlertComponent>
                )}

                <CardComponent className="list-wrapper pt-0">
                  <>
                    <CouponsListHeader
                      changeMode={this.changeMode}
                      handleSubmit={this.toggleConfirmationModal}
                      sticky={this.state.stickyHeaderEnabled}
                      stickyHeaderRef={this.stickyHeaderRef}
                      renewMode={this.state.renewMode}
                      noResults={noResults}
                      intl={this.props.intl}
                      showOnlySelectedToggle={this.showOnlySelectedToggle}
                      permissions={this.props.permissions}
                    />
                    <SeparatorComponent />
                    <CardBodyComponent className="position-relative">
                      <form onSubmit={handleSubmit}>
                        <CouponsVirtualizedListComponent
                          columns={this.getColumns(form)}
                          permissions={this.props.permissions}
                          renewMode={this.state.renewMode}
                          selectable
                          showOnlySelected={this.state.showOnlySelected}
                        />
                      </form>
                    </CardBodyComponent>
                  </>
                  <FormSpy
                    subscribtion={{
                      values: true,
                      submitting: true,
                      submitSucceeded: true,
                    }}
                  >
                    {({ values = {}, submitting = false, submitSucceeded = false }) => {
                      const selectedItems = values.coupons
                        .filter(coupon => coupon.active)
                        .filter(coupon => (this.state.renewMode ? !coupon.invalidated : coupon));

                      const allItemsIds = selectedItems.map(coupon => coupon.id);
                      const invalidatedItemsIds = selectedItems.reduce((acc, { invalidated, id }) => {
                        if (!!invalidated) {
                          return [...acc, id];
                        }

                        return acc;
                      }, []);
                      const activeItemsIds = allItemsIds.filter(id => !invalidatedItemsIds.includes(id));

                      const confirmOptions = [
                        {
                          label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
                          onClick: this.toggleConfirmationModal,
                          theme: 'secondary-outline',
                          arrowBack: true,
                        },
                        {
                          label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
                          loading: submitting || submitSucceeded,
                        },
                      ];

                      const downloadExcelFileTab = {
                        label: <FormattedMessage id="COUPONS.SIDEBAR.EXCEL.TITLE" />,
                        icon: 'fi-download-excel',
                        component: CouponsDownloadExcelFileContainer,
                        props: {
                          columns: this.props.meta.columns,
                          coupons: allItemsIds,
                        },
                        disabled: this.props.loading || !allItemsIds.length,
                      };

                      const downloadPdfFileTab = {
                        label: (
                          <FormattedMessage id="COUPONS.SIDEBAR.PDF.TITLE" values={{ coupons: selectedItems.length }} />
                        ),
                        icon: 'fi-printer pl-1',
                        component: CouponsDownloadPdfFileContainer,
                        props: {
                          coupons: activeItemsIds,
                        },
                        disabled: this.props.loading || !activeItemsIds.length,
                      };

                      const sidebarTabs = [downloadExcelFileTab, ...(canDownloadCoupons ? [downloadPdfFileTab] : [])];

                      return (
                        <>
                          {this.state.isRenewConfirmationOpen && (
                            <Modal
                              confirmOptions={confirmOptions}
                              isOpen
                              tag="form"
                              onSubmit={handleSubmit}
                              title="COUPONS.RENEW.CONFIRM"
                              size="small"
                            >
                              <CardBodyComponent>
                                <FormattedMessage
                                  id="COUPONS.RENEW.CONFIRM_BODY.MESSAGE"
                                  tagName="span"
                                  values={{
                                    vouchers: (
                                      <Typography tag="strong" color="primary">
                                        {this.props.intl.formatMessage(
                                          { id: 'COUPONS.RENEW.CONFIRM_BODY.SERVICE_VOUCHERS' },
                                          { amount: selectedItems.length },
                                        )}
                                      </Typography>
                                    ),
                                    sum: (
                                      <Typography tag="strong" color="primary">
                                        {currencyFormatter.format(
                                          selectedItems.reduce((prev, { defaultValue }) => {
                                            if (typeof defaultValue === 'string') {
                                              return prev + Number(defaultValue.replace(',', '.'));
                                            } else {
                                              return prev + (defaultValue || 0);
                                            }
                                          }, 0),
                                        )}
                                      </Typography>
                                    ),
                                  }}
                                />
                              </CardBodyComponent>
                            </Modal>
                          )}
                          <SidebarContainer
                            name="coupons-downloads"
                            closeLabel={<FormattedMessage id="COUPONS.SIDEBAR.BACK" />}
                            tabs={sidebarTabs}
                            animation
                          />
                        </>
                      );
                    }}
                  </FormSpy>
                </CardComponent>
              </>
            )}
          </Form>
        )}
      </>
    );
  }
}
