// @flow

import React, { Component } from 'react';

import { SummaryBox } from '../summary-box.component';
import { PlaceholderComponent } from 'app/shared/placeholder/placeholder.component';

/*:: import type { Props } from './summary-box.placeholder.types';*/

export class SummaryBoxPlaceholder extends Component /*:: <Props>*/ {
  render() {
    const { sections = [1] } = this.props;

    const sectionsPlaceholders = sections.map(section => ({
      title: <PlaceholderComponent width={100} />,
      data: new Array(section).fill({
        values: [<PlaceholderComponent height={25} />],
      }),
    }));

    return <SummaryBox sections={sectionsPlaceholders} />;
  }
}
