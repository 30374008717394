import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { StatusCodes } from 'http-status-codes';
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import {
  CreateVoucherResponse,
  GetVoucherResponse,
  PostpayApiClient,
  UpdateVoucherResponse,
  UpdateVoucherStatusResponse,
} from 'v2/api/postpay-api-client';
import {
  actions as serviceEventsActions,
  ServiceEventStatus,
  ServiceEventSubmissionRequestCompletion,
} from 'v2/service-events/postpay/service-event-slice';
import { PostpayServiceVoucher } from 'v2/types/postpay';
import { combineFormState, FormState } from 'v2/utils/forms';
import { V2RootState } from 'v2/v2.reducer';
import { voucherIssuingStateSelector } from './service-voucher-selectors';
import {
  actions,
  DraftVoucherFormUpdatedPayload,
  DraftVoucherIssuingRequestedPayload,
  IssueVoucherFormState,
  ServiceVoucherIssuingStatus,
  ServiceVoucherStatus,
  VoucherCreationRequestedPayload,
  VoucherRefreshRequestedPayload,
  VoucherRequestCompletion,
  VoucherRequestedPayload,
} from './service-vouchers-slice';
import { NonEmptyArray } from 'v2/utils/utils';
import { takeBatching } from 'v2/utils/redux-utils';

export function* serviceVouchersSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function voucherIsPresent(voucher: PostpayServiceVoucher) {
    const result: VoucherRequestCompletion = {
      id: voucher.id,
      voucher,
      status: ServiceVoucherStatus.Ok,
    };
    return actions.voucherRequestCompleted(result);
  }

  function voucherNotFound(voucherId: string) {
    const result: VoucherRequestCompletion = {
      id: voucherId,
      status: ServiceVoucherStatus.NotFound,
    };
    return actions.voucherRequestCompleted(result);
  }

  function voucherCreated(voucher: PostpayServiceVoucher) {
    const result = {
      id: voucher.id,
      voucher: voucher,
      status: ServiceVoucherStatus.Ok,
    };
    return actions.voucherCreationCompleted(result);
  }

  function* fetchAndUpdateVoucher(voucherId: string) {
    const response: GetVoucherResponse = yield call(apiClient.getVoucher, voucherId);
    switch (response.status) {
      case StatusCodes.OK:
        yield put(voucherIsPresent(response.data));
        break;
      case StatusCodes.NOT_FOUND:
      case StatusCodes.FORBIDDEN:
        yield put(voucherNotFound(voucherId));
        break;
    }
  }

  function* createVoucher(voucherTypeId: string) {
    const response: CreateVoucherResponse = yield call(apiClient.createVoucher, voucherTypeId);
    switch (response.status) {
      case StatusCodes.CREATED:
        yield put(voucherCreated(response.data));
        yield put(push('/service-vouchers/' + response.data.id + '/create'));
        break;
      case StatusCodes.FORBIDDEN:
      case StatusCodes.BAD_REQUEST:
        // TODO: How do we handle errors here?
        break;
    }
  }

  function* voucherRequested(action: PayloadAction<VoucherRequestedPayload>) {
    const { voucherId } = action.payload;
    yield call(fetchAndUpdateVoucher, voucherId);
  }

  function* voucherRefreshRequested(action: PayloadAction<VoucherRefreshRequestedPayload>) {
    const { voucherId } = action.payload;
    yield call(fetchAndUpdateVoucher, voucherId);
  }

  function* serviceEventSubmissionCompleted(action: PayloadAction<ServiceEventSubmissionRequestCompletion>) {
    const { payload } = action;
    if (payload.status === ServiceEventStatus.Submitted) {
      yield put(actions.voucherRefreshRequested({ voucherId: payload.voucherId }));
    }
  }

  function* voucherCreationRequested(action: PayloadAction<VoucherCreationRequestedPayload>) {
    const { voucherTypeId } = action.payload;
    yield call(createVoucher, voucherTypeId);
  }

  const issueVoucherFormStateSelector = (voucherId: string) => (state: V2RootState) => {
    const issuingState = voucherIssuingStateSelector(voucherId)(state);
    return issuingState?.status === ServiceVoucherIssuingStatus.NotSubmitted ? issuingState.formState : undefined;
  };

  function* draftVoucherFormUpdated(batchedActions: NonEmptyArray<PayloadAction<DraftVoucherFormUpdatedPayload>>) {
    // Takes only the last action in the batch, ignores rest
    const { voucherId } = batchedActions[batchedActions.length - 1].payload;

    const formState: FormState<IssueVoucherFormState> | undefined = yield select(
      issueVoucherFormStateSelector(voucherId),
    );

    const hasPendingChanges = formState && !_.isEmpty(formState.localUnsentStateDiff);
    if (!hasPendingChanges) {
      return;
    }

    yield put(actions.draftVoucherFormUpdateSubmitted({ voucherId }));
    const response: UpdateVoucherResponse = yield call(apiClient.updateVoucher, voucherId, combineFormState(formState));

    switch (response.status) {
      case StatusCodes.OK:
        const { voucher, voucherDetailsValidationErrors, citizenDetailsValidationErrors } = response.data;

        yield put(
          actions.draftVoucherFormUpdateCompleted({
            status: ServiceVoucherStatus.Ok,
            voucher,
            voucherDetailsFormValidationErrors: voucherDetailsValidationErrors,
            citizenDetailsFormValidationErrors: citizenDetailsValidationErrors,
          }),
        );
        break;
      case StatusCodes.NOT_FOUND:
      case StatusCodes.FORBIDDEN:
      case StatusCodes.BAD_REQUEST:
        yield put(
          actions.draftVoucherFormUpdateCompleted({
            status: ServiceVoucherStatus.NotFound,
            voucherId,
          }),
        );
        break;
    }
  }

  function* draftVoucherIssuingRequested(action: PayloadAction<DraftVoucherIssuingRequestedPayload>) {
    const response: UpdateVoucherStatusResponse = yield call(
      apiClient.updateVoucherStatus,
      action.payload.voucherId,
      'issued',
    );

    switch (response.status) {
      case StatusCodes.OK:
        const { data: voucher } = response;
        yield put(
          actions.draftVoucherIssuingCompleted({
            voucher,
          }),
        );
        break;
    }
  }

  yield takeEvery(actions.voucherCreationRequested.match, voucherCreationRequested);
  yield takeEvery(actions.voucherRequested.match, voucherRequested);
  yield takeEvery(actions.voucherRefreshRequested.match, voucherRefreshRequested);
  yield takeEvery(serviceEventsActions.serviceEventSubmissionCompleted.match, serviceEventSubmissionCompleted);
  yield takeEvery(actions.draftVoucherIssuingRequested, draftVoucherIssuingRequested);

  // important to use takeBatching, to ensure that only one saga is active at any given time.
  yield takeBatching(actions.draftVoucherFormUpdated.match, draftVoucherFormUpdated);
}
