import { compose } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { CoreComponent } from './core.component';
import { withPermissions } from 'app/common';

import { fetchAccounts } from 'app/redux/accounts/accounts.actions';
import { fetchPermissions } from 'app/redux/permissions/permissions.actions';
import { fetchHolidays } from 'app/redux/holidays/holidays.actions';

const mapStateToProps = ({
  permissions,
  users: { business, isAuthenticated: authenticated },
  'organizational-unit-available-usecases': useCases,
}) => ({
  authenticated,
  business,
  loading: !permissions || !business || isEmpty(business),
  useCases,
});

const mapDispatchToProps = dispatch => ({
  fetchAccounts: () => dispatch(fetchAccounts('municipal')),
  fetchPermissions: () => dispatch(fetchPermissions()),
  fetchHolidays: () => dispatch(fetchHolidays()),
});

export const CoreContainer = compose(connect(mapStateToProps, mapDispatchToProps), withPermissions)(CoreComponent);
