import { handleActions } from 'redux-actions';
import omit from 'lodash/omit';

import {
  SIDEBAR_CLOSE,
  SIDEBAR_DESTROY,
  SIDEBAR_OPEN,
  SIDEBAR_REGISTER,
  SIDEBAR_TOGGLE,
} from './sidebar.actions-types';

const defaultState = {};

export const sidebarReducer = handleActions(
  {
    [SIDEBAR_REGISTER]: (state, { payload }) => ({
      ...state,
      [payload.name]: { isOpen: payload.isOpen || false },
    }),
    [SIDEBAR_DESTROY]: (state, { payload }) => ({
      ...omit(state, payload.name),
    }),
    [SIDEBAR_OPEN]: (state, { payload }) => ({
      ...state,
      [payload.name]: {
        ...state[payload.name],
        isOpen: true,
      },
    }),
    [SIDEBAR_CLOSE]: (state, { payload }) => ({
      ...state,
      [payload.name]: {
        ...state[payload.name],
        isOpen: false,
      },
    }),
    [SIDEBAR_TOGGLE]: (state, { payload }) => {
      const isOpen = state[payload.name].isOpen;

      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isOpen: !isOpen,
        },
      };
    },
  },
  defaultState,
);
