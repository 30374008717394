// @flow

import React from 'react';

import { ConnectControl, GroupComponent, InputComponent } from 'app/shared';

/*:: import type { Props } from './connected-input.component.types';*/

export const ConnectedInputComponent /*: React$StatelessFunctionalComponent<Props>*/ = (props /*: Props*/) => {
  const onChange = (event /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    const value = event.target.value;

    props.input.onChange(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return <ConnectControl {...props} component={InputComponent} inputRef={props.inputRef} onChange={onChange} />;
};

export const ConnectedInputGroupComponent = (props /*: Props*/) => {
  return (
    <GroupComponent {...props}>
      <ConnectedInputComponent {...props} />
    </GroupComponent>
  );
};
