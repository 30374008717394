import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationalUnitUseCases } from 'v2/types/postpay';

const SLICE_NAME = 'organizational-unit-available-usecases';

export enum AvailableUsecasesStatus {
  Loading = 'loading',
  Reloading = 'reloading',
  Found = 'found',
  NotFound = 'not-found',
}

type OrganizationalUnitId = string;
type AvailableUsecasesRequested = { organizationalUnitId: OrganizationalUnitId };
export type AvailableUsecasesFetched =
  | {
      organizationalUnitId: OrganizationalUnitId;
      status: AvailableUsecasesStatus.Found;
      availableUsecases: OrganizationalUnitUseCases;
    }
  | {
      organizationalUnitId: OrganizationalUnitId;
      status: AvailableUsecasesStatus.NotFound;
    };

export type AvailableUsecasesState = Record<
  OrganizationalUnitId,
  | { status: AvailableUsecasesStatus.Loading }
  | { status: AvailableUsecasesStatus.NotFound }
  | {
      status: AvailableUsecasesStatus.Found;
      availableUsecases: OrganizationalUnitUseCases;
    }
  | ({ status: AvailableUsecasesStatus.Reloading } & (
      | { originalStatus: AvailableUsecasesStatus.Found; availableUsecases: OrganizationalUnitUseCases }
      | { originalStatus: AvailableUsecasesStatus.NotFound }
    ))
  | undefined
>;

export interface StateSlice {
  [SLICE_NAME]: AvailableUsecasesState;
}

const initialState: AvailableUsecasesState = {};
const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    organizationalUnitAvailableUsecasesRequested(
      state: AvailableUsecasesState,
      action: PayloadAction<AvailableUsecasesRequested>,
    ) {
      const { organizationalUnitId } = action.payload;
      const currentState = state[organizationalUnitId];
      switch (currentState?.status) {
        case AvailableUsecasesStatus.Loading:
          return state;
        case AvailableUsecasesStatus.Found:
          return {
            ...state,
            [organizationalUnitId]: {
              status: AvailableUsecasesStatus.Reloading,
              originalStatus: AvailableUsecasesStatus.Found,
              availableUsecases: currentState.availableUsecases,
            },
          };

        case AvailableUsecasesStatus.NotFound:
          return {
            ...state,
            [organizationalUnitId]: {
              status: AvailableUsecasesStatus.Reloading,
              originalStatus: AvailableUsecasesStatus.NotFound,
            },
          };
        case undefined:
          return {
            ...state,
            [organizationalUnitId]: { status: AvailableUsecasesStatus.Loading },
          };

        default:
          return state;
      }
    },
    organizationalUnitAvailableUsecasesFetched(state, action: PayloadAction<AvailableUsecasesFetched>) {
      const { organizationalUnitId, ...actionState } = action.payload;
      const currentState = state[organizationalUnitId];
      switch (currentState?.status) {
        case AvailableUsecasesStatus.Loading:
        case AvailableUsecasesStatus.Reloading:
          return { ...state, [organizationalUnitId]: actionState };
        default:
          return state;
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
