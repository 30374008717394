import { RSAA } from 'redux-api-middleware';

import * as types from './settlements.action-types';

export const fetchSettlements = () => ({
  [RSAA]: {
    types: [types.FETCH_SETTLEMENTS_REQUEST, types.FETCH_SETTLEMENTS_SUCCESS, types.FETCH_SETTLEMENTS_FAILURE],
    endpoint: '/settlements',
    method: 'GET',
  },
});
