// @flow

/*:: import type { Coupon } from 'app/coupons/details/coupons-details.types';*/

/*:: import type { CouponsMeta } from 'app/coupons/mass-charge/coupons-mass-charge.component.types';*/
/*:: import type { Transaction } from 'app/coupons/details/coupons-details.types';*/

/*:: type GetCoupons = {
  coupons: { list: Coupon[] },
};*/

/*:: type GetCouponsMeta = {
  coupons: { meta: CouponsMeta },
};*/

/*:: type CouponDetails = {
  coupon: Coupon,
  isLoading: boolean,
  transactions: Transaction[],
};*/

/*:: type GetCouponDetails = {
  coupon: {
    details: { coupon: Coupon, transactions: Transaction[] },
    isLoading: boolean,
  },
};*/

export const getCoupons = ({ coupons: { list: coupons } } /*: GetCoupons*/) /*: Coupon[]*/ => coupons;

export const getCouponsMeta = ({ coupons: { meta } } /*: GetCouponsMeta*/) /*: CouponsMeta*/ => meta;

export const getCouponDetails = (
  {
    coupon: {
      details: { coupon, transactions },
      isLoading,
    },
  } /*: GetCouponDetails*/,
) /*: CouponDetails*/ => {
  return { coupon, isLoading, transactions };
};
