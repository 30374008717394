// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';
import { ROUTES } from '../../../constants';

/*:: import type { Props } from './sidebar-coupons.component.types';*/

export class SidebarCouponsComponent extends Component /*:: <Props>*/ {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['serviceCoupons', 'read'],
        ['serviceCoupons', 'update'],
        ['serviceCouponTypes', 'create'],
        ['serviceCouponTypes', 'read'],
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.COUPONS.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {checkPermissions(this.props.permissions, ['serviceCouponTypes', 'read']) && (
            <SidebarItemContainer
              icon="shareservicecoupon"
              name={this.props.intl.formatMessage({ id: 'NAV.COUPONS.TYPES' })}
              to={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT}
              exact
            />
          )}

          {checkPermissions(this.props.permissions, ['serviceCoupons', 'read']) && (
            <SidebarItemContainer
              icon="sharedservicecoupon"
              name={this.props.intl.formatMessage({ id: 'NAV.COUPONS.COUPONS' })}
              to="/coupons"
              exact
            />
          )}

          {checkPermissions(this.props.permissions, ['serviceCouponTypes', 'create']) && (
            <SidebarItemContainer
              icon="addservicecoupontype"
              name={this.props.intl.formatMessage({ id: 'NAV.COUPONS.ADD_NEW_TYPE' })}
              to={{ pathname: '/coupons/types/add', state: { showArrow: false } }}
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
