// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { AutoSizer, Table, WindowScroller } from 'react-virtualized';

/*:: import type { Props } from './virtualized-table.component.types';*/
import styles from './virtualized-table.module.scss';

export class VirtualizedTableComponent extends PureComponent /*:: <Props>*/ {
  tableRef /*: any*/ = React.createRef();

  static defaultProps = {
    headerHeight: 50,
    rowHeight: 112,
    overscanRowCount: 2,
    scrollableHorizontal: true,
    hideScrollbar: false,
  };

  componentDidMount = () => {
    if (this.tableRef && this.tableRef.current) {
      const tableNode = this.tableRef.current;

      if (this.props.scrollLeft) {
        tableNode.scrollLeft = this.props.scrollLeft;
      }

      if (this.props.onVerticalScroll) {
        /* This call cause a Warning:
         * "Warning: Cannot update during an existing state transition" during it's first call
         *
         * Most probably reason:
         * This could probably be solve by merging onVerticalScroll with Table onScroll param
         * But  currently onScroll implementation on Table don't invoke with vertical scrolling
         *
         * TODO
         * */

        tableNode.addEventListener('scroll', () => {
          this.props.onVerticalScroll({
            scrollLeft: tableNode.scrollLeft,
          });
        });
      }
    }
  };

  componentDidUpdate = (prevProps /*: Props*/) => {
    if (prevProps.scrollLeft !== this.props.scrollLeft && this.tableRef) {
      this.tableRef.current.scrollLeft = this.props.scrollLeft;
    }
  };

  componentWillUnmount = () => {
    this.tableRef.current.removeEventListener('scroll', () => {
      this.props.onVerticalScroll({
        scrollLeft: this.tableRef.current.scrollLeft,
      });
    });
  };

  getWidth = (width /*: number*/) /*: number*/ => {
    if (this.props.width) {
      if (this.props.width instanceof Function) {
        return this.props.width(width);
      }

      return this.props.width;
    }

    return width;
  };

  addStripedRowClassName = ({ index } /*: { index: number }*/) =>
    classNames(this.props.rowClassName, {
      [styles.striped]: index % 2 === 0,
    });

  render() {
    return (
      <div
        ref={this.tableRef}
        className={classNames('virtualized-table', {
          [styles.wrapper]: this.props.scrollableHorizontal,
          [styles.hideScroll]: this.props.hideScrollbar,
          'compress-padding': this.props.compressPadding,
        })}
      >
        <WindowScroller>
          {({ height, onChildScroll, isScrolling, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  width={this.getWidth(width)}
                  height={height}
                  headerHeight={this.props.headerHeight}
                  rowCount={this.props.rowCount}
                  rowGetter={this.props.rowGetter}
                  rowHeight={this.props.rowHeight}
                  scrollToAlignment="start"
                  overscanRowCount={this.props.overscanRowCount}
                  autoHeight
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowClassName={this.props.striped ? this.addStripedRowClassName : this.props.rowClassName}
                  {...this.props.table}
                >
                  {this.props.children}
                </Table>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      </div>
    );
  }
}
