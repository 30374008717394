import _ from 'lodash';

export type NonEmptyArray<T> = [T, ...T[]];
export type DeepPartial<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

// see tests for expected behavior
export function deepDifference<T extends Record<string | number | symbol, unknown>>(
  base: T,
  object: DeepPartial<T>,
): DeepPartial<T> {
  return _.transform(object, (result: Record<string | number | symbol, unknown>, value: unknown, key) => {
    if (!_.isEqual(value, base[key])) {
      if (_.isObject(value) && _.isObject(base[key])) {
        result[key] = deepDifference(base[key] as Record<string, unknown>, value);
      } else {
        result[key] = value;
      }
    }
  }) as DeepPartial<T>;
}

// see tests for expected behavior
export function deepMerge<T1, T2>(a: T1, b: T2): T1 & T2 {
  const res = _.mergeWith({}, a, b, (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      // do not try to merge arrays
      return srcValue;
    }
  });
  return res;
}
