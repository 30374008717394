// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, Typography } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { checkPermissions, currencyFormatter, getAmountColor } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { CouponsDescriptiveServiceContainer } from 'app/coupons/shared';

/*:: import type { Props } from './transactions-municipal-beneficiary-table.component.types';*/
import styles from './transactions-municipal-beneficiary-table.module.scss';
import { createVoucherTransactionPath } from 'app/transactions/utils/create-transaction-path/create-transaction-path.util';

export class TransactionsMunicipalBeneficiaryTableComponent extends Component /*:: <Props>*/ {
  render() {
    const hasPermissionsForDetails = checkPermissions(this.props.permissions, ['transactionDetails', 'read']);

    const headers = [
      { content: 'TRANSACTIONS.DATE' },
      { content: 'TRANSACTIONS.TRANSACTION_TYPE' },
      { content: 'TRANSACTIONS.SERVICE_COUPON' },
      { content: 'TRANSACTIONS.PRODUCED_SERVICES' },
      { content: 'TRANSACTIONS.DEBTOR_CREDITOR' },
      { content: 'TRANSACTIONS.AMOUNT', numerical: true },
      !!hasPermissionsForDetails && {},
    ];

    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(transaction => (
            <TableRowComponent
              key={transaction.id}
              className={classNames({ 'filled-row': !!transaction.refundedBy || !!transaction.refunds })}
            >
              <TableCellComponent>{dateFormatter(transaction.date)}</TableCellComponent>
              <TableCellComponent content={`TRANSACTION_TYPE.${transaction.type}`}>
                {!!transaction.refundedBy && (
                  <>
                    <Typography type="bodyText" tag="span" color="greyDark">{` - `}</Typography>
                    <Typography
                      className={classNames(styles.returnedLabel)}
                      color="greyDark"
                      id={
                        transaction.type === 'TRANSACTION_TYPE_PAYMENT'
                          ? 'TRANSACTIONS.REFUNDED_LABEL'
                          : 'TRANSACTIONS.RETURNED_LABEL'
                      }
                      tag="span"
                      type="bodyText"
                    />
                  </>
                )}
              </TableCellComponent>
              <TableCellComponent>
                <Typography type="bodyText" color="greyDark" tag="span">
                  {transaction.decision}
                </Typography>
                <br />
                <Typography type="bodyText" color="greyDark" tag="span">
                  {transaction.couponType}
                </Typography>
              </TableCellComponent>
              <TableCellComponent className={styles.service}>
                {!!transaction.descriptiveService && (
                  <Typography type="bodyText" tag="span" color="greyDark">
                    <CouponsDescriptiveServiceContainer service={transaction.descriptiveService} />
                  </Typography>
                )}
              </TableCellComponent>
              <TableCellComponent>{transaction.creditor}</TableCellComponent>
              <TableCellComponent align="right" typographyProps={getAmountColor(transaction.amount)}>
                {currencyFormatter.format(transaction.amount)}
              </TableCellComponent>

              {hasPermissionsForDetails && (
                <TableCellComponent>
                  <ButtonComponent
                    noWrap
                    theme="secondary-outline"
                    to={{
                      pathname: `/transactions/municipal-beneficiary/${createVoucherTransactionPath(transaction)}`,
                      state: {
                        keepScrollPosition: true,
                      },
                    }}
                  >
                    <i className="fi fi-open" />
                    <FormattedMessage id="TRANSACTIONS.OPEN" />
                  </ButtonComponent>
                </TableCellComponent>
              )}
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
