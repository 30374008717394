//@flow

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

/*:: import type { InputFormMetaType, IntlValuesType } from 'app/flow-types';*/

const getMessage = error => {
  if (isArray(error)) {
    return error[0];
  }

  if (isObject(error) && !error.id) {
    return getMessage(Object.values(error || {}));
  }

  return error;
};

export const getMessageId = (meta /*: InputFormMetaType*/) => {
  const error = meta.error || meta.submitError;

  if (!error) {
    return undefined;
  }

  const message = getMessage(error);

  //$FlowFixMe
  return message.id || message;
};

export const getMessageValues = (meta /*: InputFormMetaType*/, additionalValues /*::?: IntlValuesType*/) => {
  const error = meta.error || meta.submitError;

  if (!error) {
    return undefined;
  }

  if (error && error.hasOwnProperty('values')) {
    return { ...error.values, ...(additionalValues ? additionalValues : {}) };
  }

  return additionalValues;
};

export const getMessageObject = (meta /*: InputFormMetaType*/, additionalValues /*::?: IntlValuesType*/) => {
  const id = getMessageId(meta);
  const values = getMessageValues(meta, additionalValues);

  if (!id) {
    return undefined;
  }

  return {
    id,
    ...(values ? { values } : {}),
  };
};
