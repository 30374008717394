import { PriceListComponent } from 'app/shared/price-list/price-list.component';

type Props = { input: { name: string }; isLast: boolean; name: string; showDiff?: boolean };

export const PriceListComponentWrapper = (props: Props) => {
  const {
    input: { name },
    isLast,
    showDiff,
  } = props;

  return <PriceListComponent isLast={isLast} name={name} showDiff={showDiff} />;
};
