// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { TileComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

/*:: import type { Props } from './vat-archive-tile.component.types';*/

export class VatArchiveTileComponent extends Component /*:: <Props>*/ {
  render() {
    const { tile: row, year, month } = this.props;
    const name = !!row.nameExtra ? `${row.name} - ${row.nameExtra}` : row.name;

    return (
      <TileComponent>
        <h3>{row.active ? <Link to={`/vat-archive/${row.id}/${year}/${month}`}>{name}</Link> : name}</h3>
        <div className="row">
          <div className="col-md-8">
            <FormattedMessage id="VAT_ARCHIVE.LIST.SUM" tagName="small" />
            {currencyFormatter.format(row.sum)}
          </div>

          <div className="col-md-4">
            <FormattedMessage id="VAT_ARCHIVE.LIST.BILLS" tagName="small" />
            <FormattedMessage
              id="VAT_ARCHIVE.DETAILS.SUMMARY.PAYMENTS"
              values={{
                number: row.totalNumber,
              }}
            />
          </div>
        </div>
      </TileComponent>
    );
  }
}
