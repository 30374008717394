// @flow

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withPermissions } from 'app/common';

import { UsersListComponent } from './users-list.component';

export const UsersListContainer = compose(withRouter, withPermissions)(UsersListComponent);
