// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ButtonComponent, ConnectedDateRangeComponent, ConnectedInputComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { isBusinessDifferent } from 'app/utils';

/*:: import type { Props, State } from './transactions-municipal-filters.component.types';*/
import styles from './transactions-municipal-filters.module.scss';

export class TransactionsMunicipalFiltersComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.reset();
    }
  }

  toggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const rowClassNames = classNames('row', styles.row);

    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <CardHeaderComponent title="TRANSACTIONS.SEARCH_RELATED">
          <ButtonComponent size="small" theme="secondary-outline" onClick={this.props.onClear}>
            <FormattedMessage id="CORE.CLEAR_FILTERS" />
          </ButtonComponent>
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <form onSubmit={this.props.handleSubmit}>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <ConnectedDateRangeComponent
                  field={Field}
                  name="period"
                  startDateKey="minDate"
                  endDateKey="maxDate"
                  onFocusChange={this.toggle}
                  label="TRANSACTIONS.TRANSACTION_DATE"
                />
              </div>
              <div className={columnClassNames}>
                <ConnectedDateRangeComponent
                  field={Field}
                  name="service"
                  startDateKey="serviceMin"
                  endDateKey="serviceMax"
                  onFocusChange={this.toggle}
                  label="TRANSACTIONS.SERVICE_DATE"
                />
              </div>
              <div className={columnClassNames}>
                <ConnectedDateRangeComponent
                  field={Field}
                  name="settlement"
                  startDateKey="settlementMin"
                  endDateKey="settlementMax"
                  onFocusChange={this.toggle}
                  label="TRANSACTIONS.SETTLEMENT_DATE"
                />
              </div>
            </div>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <Field name="referenceId" component={ConnectedInputComponent} label="TRANSACTIONS.REFERENCE" />
              </div>
              <div className={columnClassNames}>
                <Field name="couponId" component={ConnectedInputComponent} label="TRANSACTIONS.SERVICE_COUPON_CODE" />
              </div>
              <div className={columnClassNames}>
                <Field name="couponName" component={ConnectedInputComponent} label="TRANSACTIONS.SERVICE_COUPON_TYPE" />
              </div>
              <div className={columnClassNames}>
                <Field name="issuer" component={ConnectedInputComponent} label="TRANSACTIONS.SERVICE_COUPON_ISSUER" />
              </div>
            </div>
            <div className={backdropClassNames} />
          </form>
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
