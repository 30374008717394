import { RSAA } from 'redux-api-middleware';

import { get, isArray } from 'lodash';

const prepareEndpoint = ({ endpoint, types }) => {
  const params = get(types, '0.meta.params');

  if (params) {
    const searchParams = new URLSearchParams();

    Object.keys(params)
      .filter(key => !!params[key])
      .forEach(key => {
        if (isArray(params[key])) {
          params[key].forEach(inner => searchParams.append(`${key}[]`, inner));
        } else {
          searchParams.append(key, params[key]);
        }
      });

    return endpoint + '?' + searchParams.toString();
  } else {
    return endpoint;
  }
};

export const paramsMiddleware = () => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  return next({
    [RSAA]: {
      ...apiMiddleware,
      endpoint: prepareEndpoint(apiMiddleware),
    },
  });
};
