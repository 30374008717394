import { handleActions } from 'redux-actions';

import {
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_BALANCE_FAILURE,
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
} from './accounts.action-types';

const defaultState = {
  entities: {},
  balance: {},
  isPostPaymentAccount: false,
  creditCap: null,
};

export const accountsReducer = handleActions(
  {
    [FETCH_ACCOUNTS_REQUEST]: state => ({
      ...state,
      list: undefined,
      loading: true,
    }),
    [FETCH_ACCOUNTS_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
      loading: false,
      isPostPaymentAccount: !!list.isPostPay,
      creditCap: list.creditCap,
    }),
    [FETCH_ACCOUNTS_FAILURE]: state => ({
      ...state,
      loading: false,
    }),
    [FETCH_TRANSACTIONS_REQUEST]: (state, { meta: { accountNumber, shouldReset } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          ...state.entities[accountNumber],
          ...(shouldReset
            ? { transactions: undefined }
            : { transactions: { ...state.entities[accountNumber].transactions, fetching: true } }),
        },
      },
    }),
    [FETCH_TRANSACTIONS_SUCCESS]: (state, { payload: { data: list, meta }, meta: { accountNumber, shouldMerge } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [accountNumber]: {
          ...state.entities[accountNumber],
          transactions: {
            meta,
            fetching: false,
            list: shouldMerge ? [...state.entities[accountNumber].transactions.list, ...list] : list,
          },
        },
      },
    }),
    [FETCH_BALANCE_REQUEST]: state => ({
      ...state,
      balance: {
        loading: true,
      },
    }),
    [FETCH_BALANCE_SUCCESS]: (state, { payload: { data: balance } }) => ({
      ...state,
      balance,
    }),
    [FETCH_BALANCE_FAILURE]: state => ({
      ...state,
      balance: {
        error: true,
      },
    }),
  },
  defaultState,
);
