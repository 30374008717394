// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';

import { ConnectedInputComponent, ConnectedToggleComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './personal-section.component.types';*/
import styles from './personal-section.module.scss';

export class PersonalSectionComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <FormSection name={this.props.name}>
        {this.props.children && (
          <>
            <CardHeaderComponent direction="column" alignItems="flex-start">
              {this.props.children}
            </CardHeaderComponent>
            <SeparatorComponent />
          </>
        )}
        <CardBodyComponent>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="firstName"
                component={ConnectedInputComponent}
                label="PROFILE.FIRST_NAME"
                placeholder="PROFILE.FIRST_NAME"
                required
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="lastName"
                component={ConnectedInputComponent}
                label="PROFILE.LAST_NAME"
                placeholder="PROFILE.LAST_NAME"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="email"
                type="email"
                component={ConnectedInputComponent}
                label="PROFILE.EMAIL"
                placeholder="PROFILE.EMAIL"
                required
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="mobilePhone"
                component={ConnectedInputComponent}
                label="PROFILE.MOBILE_PHONE"
                placeholder="PROFILE.MOBILE_PHONE"
                append="PROFILE.PHONE_NUMBER_FORMAT"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="workPhone"
                component={ConnectedInputComponent}
                label="PROFILE.WORK_PHONE"
                placeholder="PROFILE.WORK_PHONE"
                append="PROFILE.PHONE_NUMBER_FORMAT"
              />
            </div>
          </div>
          <div className={styles.marketing}>
            <Field
              name="allowMarketing"
              component={ConnectedToggleComponent}
              label={
                <>
                  <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}</div>
                  <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}</div>
                </>
              }
            />
          </div>
        </CardBodyComponent>
      </FormSection>
    );
  }
}
