import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { LeaveConfirmationComponent } from './leave.confirmation.component';

const mapDispatchToProps = dispatch => ({
  onConfirm: nextLocation => dispatch(push(nextLocation)),
});

export const LeaveConfirmationContainer = compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(LeaveConfirmationComponent);
