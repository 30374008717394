// @flow

import React, { Component } from 'react';

import { ListComponent } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';

import { VatArchiveTablePlaceholder } from './table/vat-archive-table.placeholder';
import { VatArchiveTilePlaceholder } from './tile/vat-archive-tile.placeholder';

export class VatArchiveListPlaceholder extends Component /*:: <any>*/ {
  static defaultProps = {
    list: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <CardBodyComponent>
          <ListComponent list={this.props.list} table={VatArchiveTablePlaceholder} tile={VatArchiveTilePlaceholder} />
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
