import { get } from 'lodash';

export function v1AuthenticationHeaders(
  accessToken: string | null,
  id: number | undefined,
  parentId: number | undefined,
) {
  const X_AUTH_BUSINESS = parentId ? parentId : id;
  const X_AUTH_SUB_BUSINESS = parentId && id;

  return {
    ...(accessToken ? { 'X-AUTH-TOKEN': accessToken } : {}),
    ...(X_AUTH_BUSINESS ? { 'X-AUTH-BUSINESS': X_AUTH_BUSINESS } : {}),
    ...(X_AUTH_SUB_BUSINESS ? { 'X-AUTH-SUB-BUSINESS': X_AUTH_SUB_BUSINESS } : {}),
  };
}

export interface AuthenticationDataProvider {
  getToken: () => string | null;
  getCurrentBusiness: () =>
    | {
        parentId?: number;
        id: number;
      }
    | undefined;
}

export function getAuthenticationHeaders(
  authentication: AuthenticationDataProvider,
  opts: { includeBusinessHeaders: boolean } = { includeBusinessHeaders: false },
) {
  const { includeBusinessHeaders } = opts;

  const business = authentication.getCurrentBusiness();
  return v1AuthenticationHeaders(
    authentication.getToken(),
    includeBusinessHeaders ? get(business, ['id']) : undefined,
    includeBusinessHeaders ? get(business, ['parentId']) : undefined,
  );
}
