import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';

import { withAuthorization, withPermissions } from 'app/common';
import { fetchCoupons, renewCoupons } from 'app/redux/coupons/coupons.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { scrollToElement } from 'app/utils';
import { CouponsListComponent } from './coupons-list.component';
import { singleConcurrentRequestAction } from 'app/middlewares';

let initialCouponsValues = null;
const COUPONS_LIMIT = 2000;

const createInitialValues = ({
  list: coupons,
  meta: {
    columns: { list: columnsList, alwaysEnabled: alwaysEnabledColumns, active: activeColumns },
  },
}) => ({
  coupons: coupons.map(({ validFrom, validTo, ...coupon }) => ({
    ...coupon,
    active: true,
    defaultValue: coupon.lastDeposit,
    defaultValueCalculations: [{ amount: 1 }],
    id: coupon.id,
    maxTransaction: coupon.maxTransaction,
    valid: {
      from: validFrom,
      to: validTo,
    },
  })),
  columns: columnsList.map(column => ({
    id: column,
    text: `COUPONS.LIST.HEADERS.${column.replace(/([A-Z])/g, x => `_${x}`).toUpperCase()}`,
    hidden: (alwaysEnabledColumns || []).includes(column),
    checked: (activeColumns || []).includes(column),
  })),
});

const mapStateToProps = ({ coupons }) => {
  if (!initialCouponsValues && coupons.list) {
    initialCouponsValues = createInitialValues(coupons);
  } else if (!coupons.list) {
    initialCouponsValues = null;
  }

  return {
    coupons: coupons.list,
    loading: !coupons.list,
    meta: coupons.meta,
    initialValues: initialCouponsValues,
  };
};

const mapDispatchToProps = dispatch => ({
  fetch: () =>
    dispatch(singleConcurrentRequestAction('coupons-list', fetchCoupons({ size: COUPONS_LIMIT }, 'filter-coupons'))),
  resetFilters: () => dispatch(reset('filter-coupons')),
  onSubmit: (body, successHandler, failureHandler) => {
    successHandler = () => {
      dispatch(
        push(
          `/coupons/share/${body.coupons
            .filter(coupon => coupon.active)
            .map(coupon => coupon.id)
            .join(';')}`,
        ),
      );
      scrollToElement('body');
    };

    return withFormSubmissionErrorHandling(dispatch(renewCoupons(body)), successHandler, failureHandler);
  },
});

export const CouponsListContainer = compose(
  withAuthorization(['serviceCoupons', 'read']),
  injectIntl,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CouponsListComponent);
