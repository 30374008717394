import { all, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { getLocation, goBack, push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import get from 'lodash/get';
import { calculateNextSessionExpirationTime } from '../../core/token-refresh-timer/refresh-timer-calculator';

import {
  FETCH_CURRENT_USER_FAILURE,
  FETCH_CURRENT_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  SESSION_EXPIRED,
} from './users.action-types';
import { fetchCurrentUser } from './users.actions';
import { clearSessionState } from '../session/session.actions';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

export const clearStorageItems = keys => keys.map(key => localStorage.removeItem(key));

export function* afterUserLogin(action) {
  try {
    yield localStorage.setItem('accessToken', action.payload.data.accessToken);
    yield localStorage.setItem(
      'nextSessionExpirationTime',
      calculateNextSessionExpirationTime(action.payload.data.expiresIn),
    );
  } catch (err) {}

  yield put(fetchCurrentUser());

  const { success, failure } = yield race({
    success: take(FETCH_CURRENT_USER_SUCCESS),
    failure: take(FETCH_CURRENT_USER_FAILURE),
  });

  if (success) {
    const {
      payload: {
        data: { terms = [] },
      },
    } = success;

    const unapproved = terms.find(term => !term.approved && term.required);

    if (!unapproved) {
      const location = yield select(getLocation);

      if (get(location, 'state.back')) {
        yield put(goBack());
      }
    }
  } else if (failure) {
    yield put(
      stopSubmit('login', {
        username: 'VALIDATION.INVALID_USERNAME_PASSWORD_COMBINATION',
        password: 'VALIDATION.INVALID_USERNAME_PASSWORD_COMBINATION',
      }),
    );
  }
}

export function* afterSessionEnd(action) {
  try {
    const { redirectToLoginPage = true, state = {} } = action.meta || {};
    yield all(clearStorageItems(['accessToken', 'sidebarCollapsed', 'nextSessionExpirationTime']));

    if (redirectToLoginPage) {
      yield put(
        push({
          state,
          pathname: '/login',
        }),
      );
    }

    yield put(clearSessionState());
  } catch (err) {}
}

export function* afterTokenRefreshSuccess(action) {
  try {
    yield localStorage.setItem('accessToken', action.payload.data.accessToken);
    yield localStorage.setItem(
      'nextSessionExpirationTime',
      calculateNextSessionExpirationTime(action.payload.data.expiresIn),
    );
  } catch (err) {}
}

export function* afterTokenRefreshFailure() {
  try {
    yield all(clearStorageItems(['accessToken', 'sidebarCollapsed', 'nextSessionExpirationTime']));

    yield put(
      push({
        pathname: '/login',
      }),
    );
  } catch (err) {}
}

export function* afterLogoutSessionRequest(action) {
  // RSAA doesn't send FAILURE action when there is a network error (e.g. UNREACHABLE host)
  // but sends a REQUEST with error = true
  if (action.error) {
    yield all(clearStorageItems(['accessToken', 'sidebarCollapsed', 'nextSessionExpirationTime']));
  }
}

export function* usersSaga() {
  yield takeEvery([LOGIN_SUCCESS, LOGIN_WITH_TOKEN_SUCCESS], afterUserLogin);
  yield takeEvery(REFRESH_TOKEN_SUCCESS, afterTokenRefreshSuccess);
  yield takeEvery(REFRESH_TOKEN_FAILURE, afterTokenRefreshFailure);
  yield takeEvery([LOGOUT_SUCCESS, LOGOUT_FAILURE, SESSION_EXPIRED], afterSessionEnd);
  yield takeEvery([LOGOUT_REQUEST], afterLogoutSessionRequest);
}
