// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AccountTransactionsContainer } from './transactions/account-transactions.container';
import { AccountsBalanceContainer } from './balance/accounts-balance.container';

/*:: import type { Props } from './accounts.component.types';*/

export class AccountsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/municipal`} component={AccountTransactionsContainer} />
        <Route path={`${this.props.match.url}/balance`} component={AccountsBalanceContainer} />
      </Switch>
    );
  }
}
