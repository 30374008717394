//@flow

import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { SelectionControlBase } from 'app/shared';
import { INPUT_PROPS_OMIT_KEYS } from 'app/constants';

/*:: import type { ToggleComponentProps, ToggleProps } from './toggle.component.types';*/
import styles from './toggle.module.scss';

export const Toggle = (props /*: ToggleProps*/) => {
  const { className, ...otherProps } = props;

  const toggleClassNames = classNames(styles.toggle, props.className);

  const inputProps = {
    ...omit(otherProps, INPUT_PROPS_OMIT_KEYS),
    className: classNames(styles.input, className),
    type: 'checkbox',
  };

  return (
    <div className={toggleClassNames}>
      <input {...inputProps} />
      <span className={styles.slider} />
    </div>
  );
};

export const ToggleComponent = (props /*: ToggleComponentProps*/) => (
  <SelectionControlBase component={Toggle} {...props} />
);
