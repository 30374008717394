// @flow

import React from 'react';

import { Field, Form } from 'react-final-form';
import { Modal } from 'app/shared/modal-new/modal.container';
/*:: import type { Props } from './impersonation-modal.component.types';*/
import { CardBodyComponent, CardFooterComponent } from 'app/shared/card-new';
import { useIntl } from 'app/hooks';
import { ButtonComponent, ConnectedInputComponent } from 'app/shared';
import { FormattedMessage } from 'react-intl';
import { numericality } from 'redux-form-validators';

export const ImpersonationModalComponent = (props /*: Props*/) => {
  const { onSubmit, isOpen } = props;

  const intl = useIntl();

  const submit = async (form, errors) => {
    const { userId } = form;

    return onSubmit(userId);
  };

  return (
    <Modal title={intl.formatMessage({ id: 'IMPERSONATION.TITLE' })} size="small" isOpen={isOpen}>
      <Form
        initialValues={{}}
        onSubmit={submit}
        render={({ handleSubmit, submitting, values, submitSucceeded, form, errors, submitFailed, submitError }) => (
          <form onSubmit={handleSubmit}>
            <CardBodyComponent>
              <div className="row">
                <div className="col-lg-12">
                  <Field
                    name="userId"
                    component={ConnectedInputComponent}
                    label="IMPERSONATION.CORE_ID"
                    validate={numericality({ int: true, message: 'VALIDATION.IMPERSONATION.INVALID_CORE_ID' })}
                  />
                </div>
              </div>
            </CardBodyComponent>
            <CardFooterComponent>
              <ButtonComponent theme="primary-outline" type="button" onClick={props.onCancel}>
                <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.CANCEL" />
              </ButtonComponent>

              <ButtonComponent type="submit" disabled={submitting} loading={submitting} className="ml-auto">
                <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" /> <i className="fi fi-right-arrow-forward" />
              </ButtonComponent>
            </CardFooterComponent>
          </form>
        )}
      />
    </Modal>
  );
};
