import { FunctionComponent } from 'react';
import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';

interface Properties {
  disabled: boolean;
  onClick: () => void;
}

export const SubmitButton: FunctionComponent<Properties> = ({ disabled, onClick }) => {
  return (
    <ButtonComponent theme="primary" loading={false} className="ml-auto" disabled={disabled} onClick={onClick}>
      <FormattedMessage id="SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.SUBMIT_SERVICE_EVENT" />
    </ButtonComponent>
  );
};
