// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

/*:: import type { Props } from './vat-archive-details.tile.placeholder.types';*/

export class VatArchiveDetailsTilePlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={120} height={27} />
        </h3>

        <div className="row">
          <div className="col-md-4">
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.DATE" tagName="small" />
            <PlaceholderComponent width={100} />
          </div>
          <div className="col-md-8">
            {this.props.type === 'TYPE_MUNICIPAL' && (
              <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.SELLER_NAME" tagName="small" />
            )}
            {this.props.type === 'TYPE_VENUE' && (
              <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.BUYER_NAME" tagName="small" />
            )}
            <PlaceholderComponent width={200} />
          </div>
          <div className="col-md-6">
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.AMOUNT" tagName="small" />
            <PlaceholderComponent width={80} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
