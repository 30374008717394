import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchVenueCoupons } from 'app/redux/coupons/coupons.actions';

import { CouponsMassChargeComponent } from './coupons-mass-charge.component';

const mapStateToProps = state => ({
  loading: !state.dictionaries['municipal/services'],
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
  fetchVenueCoupons: (...params) =>
    dispatch(singleConcurrentRequestAction('coupons-mass-charge', fetchVenueCoupons(...params))),
});

export const CouponsMassChargeContainer = compose(
  withAuthorization(['venuePayment', 'create'], ['venueServiceCoupons', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsMassChargeComponent);
