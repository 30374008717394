// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, SeparatorComponent, TileComponent, Typography } from 'app/shared';

import { checkPermissions, currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { createTransactionPath } from 'app/transactions/utils/create-transaction-path/create-transaction-path.util';

/*:: import type { Props } from './transactions-municipal-tile.component.types';*/

export class TransactionsMunicipalTileComponent extends Component /*:: <Props>*/ {
  render() {
    const transaction = this.props.tile;

    return (
      <TileComponent>
        <h3>{transaction.type === 'TRANSACTION_TYPE_VENUE_RETURN' ? transaction.to : transaction.from}</h3>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.ISSUER" />
            <Typography type="bodyText" tag="span" color="greyDark">
              {transaction.owner}
            </Typography>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.SERVICE_COUPON_TYPE" />
            <Typography type="bodyText" tag="span" color="greyDark">
              {transaction.couponName}
            </Typography>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.SERVICE_PRODUCED" />

            <Typography className="small" type="bodyText" tag="span" color="greyDark">
              {transaction.serviceStartDate &&
                transaction.serviceEndDate &&
                dateFormatter(transaction.serviceStartDate) + ' - ' + dateFormatter(transaction.serviceEndDate)}
            </Typography>
          </div>

          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.DATE" />

            <Typography className="small" type="bodyText" tag="span" color="greyDark">
              {dateFormatter(transaction.date)}
            </Typography>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.AMOUNT" />

            <Typography type="bodyText" tag="strong" color={transaction.amount > 0 ? 'success' : 'failure'}>
              {currencyFormatter.format(transaction.amount)}
            </Typography>
          </div>
        </div>

        {checkPermissions(this.props.permissions, ['transactionDetails', 'read']) && (
          <div className="row">
            <div className="col-12 mt-0">
              <SeparatorComponent />
              <ButtonComponent
                theme="secondary-outline"
                to={{
                  pathname: `/transactions/municipal/${createTransactionPath(transaction)}`,
                  state: {
                    keepScrollPosition: true,
                  },
                }}
              >
                <i className="fi fi-open" />
                <FormattedMessage id="TRANSACTIONS.OPEN" />
              </ButtonComponent>
            </div>
          </div>
        )}
      </TileComponent>
    );
  }
}
