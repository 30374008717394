import { call, ForkEffect, put, select, takeLeading } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions, ServiceEventState, ServiceEventStatus, ServiceEventSubmissionRequest } from './service-event-slice';
import { StatusCodes } from 'http-status-codes';
import { name as serviceEventSliceName } from './service-event-slice';
import {
  InitializeServiceEventResponse,
  PostpayApiClient,
  SubmitServiceEventResponse,
} from 'v2/api/postpay-api-client';
import { ServiceEvent } from 'v2/types/postpay';
import { V2RootState } from 'v2/v2.reducer';
import { SubmittablePostpayServiceVoucher } from 'v2/service-voucher/postpay/utils';

export const getServiceEventStateSelector = (state: V2RootState) => state[serviceEventSliceName];

function* getOrInitializeServiceEvent(apiClient: PostpayApiClient, voucher: SubmittablePostpayServiceVoucher) {
  const state: ServiceEventState = yield select(getServiceEventStateSelector);
  if (state.status !== ServiceEventStatus.Submitting && state.status !== ServiceEventStatus.Initializing) {
    return undefined;
  }

  switch (state.status) {
    case ServiceEventStatus.Initializing:
      // service event is not initialized - initialize it.
      // at some point, we might want to do this before submission
      // to allow eg. server-side validation before submission
      const initializationResponse: InitializeServiceEventResponse = yield call(
        apiClient.initializeServiceEvent,
        voucher,
      );

      switch (initializationResponse.status) {
        case StatusCodes.CREATED:
          return initializationResponse.data;
      }
      break;

    default:
      // service event is initialized - use service event id from state
      return state.serviceEvent;
  }
}

export function* postpayServiceEventSubmissionSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function* serviceEventSubmissionRequest(action: PayloadAction<ServiceEventSubmissionRequest>) {
    const { voucher, data } = action.payload;
    const serviceEvent: ServiceEvent | undefined = yield call(getOrInitializeServiceEvent, apiClient, voucher);

    if (!serviceEvent) {
      // service event was unable to be initialized - abort
      return;
    }

    const submitResponse: SubmitServiceEventResponse = yield call(apiClient.submitServiceEvent, serviceEvent, data);

    switch (submitResponse.status) {
      case StatusCodes.OK:
        yield put(
          actions.serviceEventSubmissionCompleted({
            status: ServiceEventStatus.Submitted,
            serviceEvent,
            voucherId: voucher.id,
          }),
        );
        yield put(actions.newServiceEventInputStarted());
        break;
      case StatusCodes.BAD_REQUEST:
        yield put(
          actions.serviceEventSubmissionCompleted({
            status: ServiceEventStatus.SubmissionFailed,
            serviceEvent,
            voucherId: voucher.id,
            error: submitResponse.data,
          }),
        );
        break;
    }
  }

  yield takeLeading(actions.serviceEventSubmissionRequested.match, serviceEventSubmissionRequest);
}
