export const FETCH_VENUES_REQUEST = 'venues/FETCH_VENUES_REQUEST';
export const FETCH_VENUES_SUCCESS = 'venues/FETCH_VENUES_SUCCESS';
export const FETCH_VENUES_FAILURE = 'venues/FETCH_VENUES_FAILURE';

export const FETCH_VENUE_REQUEST = 'venues/FETCH_VENUE_REQUEST';
export const FETCH_VENUE_SUCCESS = 'venues/FETCH_VENUE_SUCCESS';
export const FETCH_VENUE_FAILURE = 'venues/FETCH_VENUE_FAILURE';

export const ADD_VENUE_REQUEST = 'venues/ADD_VENUE_REQUEST';
export const ADD_VENUE_SUCCESS = 'venues/ADD_VENUE_SUCCESS';
export const ADD_VENUE_FAILURE = 'venues/ADD_VENUE_FAILURE';

export const EDIT_VENUE_REQUEST = 'venues/EDIT_VENUE_REQUEST';
export const EDIT_VENUE_SUCCESS = 'venues/EDIT_VENUE_SUCCESS';
export const EDIT_VENUE_FAILURE = 'venues/EDIT_VENUE_FAILURE';

export const FETCH_MUNICIPALITIES_REQUEST = 'venues/FETCH_MUNICIPALITIES_REQUEST';
export const FETCH_MUNICIPALITIES_SUCCESS = 'venues/FETCH_MUNICIPALITIES_SUCCESS';
export const FETCH_MUNICIPALITIES_FAILURE = 'venues/FETCH_MUNICIPALITIES_FAILURE';

export const UPDATE_MUNICIPALITIES_CONTRACTS_REQUEST = 'venues/UPDATE_MUNICIPALITIES_CONTRACTS_REQUEST';
export const UPDATE_MUNICIPALITIES_CONTRACTS_SUCCESS = 'venues/UPDATE_MUNICIPALITIES_CONTRACTS_SUCCESS';
export const UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE = 'venues/UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE';
