// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { CouponsDownloadPdfFileContainer } from '../downloads/pdf-file/coupons-downloads-pdf-file.container';

/*:: import type { Props } from './coupons-share.component.types';*/
import styles from './coupons-share.module.scss';

export class CouponsShareComponent extends Component /*:: <Props>*/ {
  render() {
    const coupons = this.props.match.params.coupons.split(';');

    return (
      <>
        <PageHeaderComponent title="COUPONS.SHARE.HEADER" />
        <CouponsDownloadPdfFileContainer
          coupons={coupons}
          sectionClassName={styles.section}
          buttonsClassName={styles.buttons}
        />
      </>
    );
  }
}
