// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent } from 'app/shared';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { checkPermissions, currencyFormatter, getAmountColor } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { createTransactionPath } from 'app/transactions/utils/create-transaction-path/create-transaction-path.util';

/*:: import type { Props } from './transactions-municipal-table.component.types';*/
import styles from './transactions-municipal-table.module.scss';

export class TransactionsMunicipalTableComponent extends Component /*:: <Props>*/ {
  render() {
    const hasPermissionsForDetails = checkPermissions(this.props.permissions, ['transactionDetails', 'read']);

    const headers = [
      { content: 'TRANSACTIONS.SERVICE_COUPON_CODE' },
      { content: 'TRANSACTIONS.ISSUER', className: styles.issuerColumn },
      { content: 'TRANSACTIONS.SERVICE_COUPON_TYPE' },
      { content: 'TRANSACTIONS.SERVICE_PRODUCED', className: styles.serviceProducedColumn },
      { content: 'TRANSACTIONS.DATE' },
      { content: 'TRANSACTIONS.AMOUNT', numerical: true },
      !!hasPermissionsForDetails && {},
    ];

    return (
      <TableComponent scrollable className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(transaction => (
            <TableRowComponent key={transaction.id}>
              <TableCellComponent className={styles.code}>
                {transaction.type === 'TRANSACTION_TYPE_VENUE_RETURN' ? transaction.to : transaction.from}
              </TableCellComponent>
              <TableCellComponent>{transaction.owner}</TableCellComponent>
              <TableCellComponent>{transaction.couponName}</TableCellComponent>
              <TableCellComponent>
                {transaction.serviceStartDate &&
                  transaction.serviceEndDate &&
                  dateFormatter(transaction.serviceStartDate) + ' - ' + dateFormatter(transaction.serviceEndDate)}
              </TableCellComponent>
              <TableCellComponent>{dateFormatter(transaction.date)}</TableCellComponent>
              <TableCellComponent align="right" typographyProps={getAmountColor(transaction.amount)}>
                {currencyFormatter.format(transaction.amount)}
              </TableCellComponent>

              {hasPermissionsForDetails && (
                <TableCellComponent>
                  <ButtonComponent
                    theme="secondary-outline"
                    noWrap
                    to={{
                      pathname: `/transactions/municipal/${createTransactionPath(transaction)}`,
                      state: {
                        keepScrollPosition: true,
                      },
                    }}
                  >
                    <i className="fi fi-open" />
                    <FormattedMessage id="COUPONS.TYPES.LABEL.OPEN" />
                  </ButtonComponent>
                </TableCellComponent>
              )}
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
