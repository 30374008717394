import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { register, registerValidate } from 'app/redux/register/register.actions';
import { fetchVenuesTypes } from 'app/redux/register/register.actions';
import { DEFAULT_EMPTY_CONTRACT } from '../utils';

import { RegisterComponent } from './register.component';

const mapStateToProps = ({ dictionaries, register: { venuesTypes } }) => ({
  initialValues: {
    venues: [
      {
        contracts: [DEFAULT_EMPTY_CONTRACT],
        admins: [{}],
      },
    ],
  },
  loading: !dictionaries['municipal/services'] || !venuesTypes,
});

const mapDispatchToProps = dispatch => ({
  onValidate: body => dispatch(registerValidate(body, 'service-provider/municipal')),
  onSubmit: body => dispatch(register(body, 'service-provider/municipal')),
  onSubmitFail: () => window.scrollTo(0, 0),
  fetchVenuesTypes: () => dispatch(fetchVenuesTypes()),
  fetchServiceProviderMunicipalDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
});

export const RegisterContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'register',
  }),
)(RegisterComponent);
