// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedInputComponent, ConnectedRadiosComponent, ConnectedToggleComponent } from 'app/shared';
import { Modal } from 'app/shared/modal-new/modal.container';
import { CardBodyComponent } from 'app/shared/card-new';

/*:: import type { Props } from './users-add.component.types';*/

export class UsersAddComponent extends Component /*:: <Props>*/ {
  confirmOptions = [
    {
      label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
      onClick: this.props.onClose,
      theme: 'primary-outline',
      arrowBack: true,
    },
    {
      label: this.props.intl.formatMessage({ id: 'CORE.SAVE' }),
      loading: this.props.submitting,
    },
  ];

  render() {
    return (
      <Modal
        title="VENUES.ADD_NEW_USER"
        onSubmit={this.props.handleSubmit}
        tag="form"
        isOpen
        size="medium"
        confirmOptions={this.confirmOptions}
        outsideClick={this.props.onClose}
      >
        <CardBodyComponent>
          <div className="form-row">
            <Field
              name="role"
              component={ConnectedRadiosComponent}
              label="VENUES.ROLE"
              options={this.props.roles}
              required
              columns={2}
            />
          </div>
          <div className="form-row">
            <div className="col-lg-6">
              <Field name="firstName" component={ConnectedInputComponent} label="PROFILE.FIRST_NAME" required />
            </div>
            <div className="col-lg-6">
              <Field name="lastName" component={ConnectedInputComponent} label="PROFILE.LAST_NAME" required />
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-6">
              <Field name="email" component={ConnectedInputComponent} label="PROFILE.EMAIL" required />
            </div>
            <div className="col-lg-6">
              <Field name="mobilePhone" component={ConnectedInputComponent} label="PROFILE.MOBILE_PHONE" required />
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-6">
              <Field name="workPhone" component={ConnectedInputComponent} label="PROFILE.WORK_PHONE" />
            </div>
          </div>
          <div>
            <Field
              name="allowMarketing"
              component={ConnectedToggleComponent}
              label={
                <>
                  <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING' })}</div>
                  <div>{this.props.intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}</div>
                </>
              }
            />
          </div>
        </CardBodyComponent>
      </Modal>
    );
  }
}
