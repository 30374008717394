export const FETCH_CONTRACTED_VENUES_REQUEST = 'pricelists/FETCH_CONTRACTED_VENUES_REQUEST';
export const FETCH_CONTRACTED_VENUES_SUCCESS = 'pricelists/FETCH_CONTRACTED_VENUES_SUCCESS';
export const FETCH_CONTRACTED_VENUES_FAILURE = 'pricelists/FETCH_CONTRACTED_VENUES_FAILURE';

export const FETCH_VENUE_CONTRACTS_REQUEST = 'pricelists/FETCH_VENUE_CONTRACTS_REQUEST';
export const FETCH_VENUE_CONTRACTS_SUCCESS = 'pricelists/FETCH_VENUE_CONTRACTS_SUCCESS';
export const FETCH_VENUE_CONTRACTS_FAILURE = 'pricelists/FETCH_VENUE_CONTRACTS_FAILURE';

export const REFRESH_VENUE_CONTRACTS_REQUEST = 'pricelists/REFRESH_VENUE_CONTRACTS_REQUEST';

export const REVIEW_CONTRACT_REQUEST = 'pricelists/REVIEW_CONTRACT_REQUEST';
export const REVIEW_CONTRACT_SUCCESS = 'pricelists/REVIEW_CONTRACT_SUCCESS';
export const REVIEW_CONTRACT_FAILURE = 'pricelists/REVIEW_CONTRACT_FAILURE';

export const SET_CONTRACT_DELETED = 'pricelists/SET_CONTRACT_DELETED';

export const FILTER_VENUE_CONTRACTS = 'pricelists/FILTER_VENUE_CONTRACTS';
