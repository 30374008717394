import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchVenuesTypes } from 'app/redux/register/register.actions';

import { AddVenueComponent } from './add-venue.component';

const mapStateToProps = ({ dictionaries }) => ({
  loading: !dictionaries['municipal/services'],
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
  fetchVenuesTypes: () => dispatch(fetchVenuesTypes()),
});

export const AddVenueContainer = compose(
  withAuthorization(['venues', 'create']),
  connect(mapStateToProps, mapDispatchToProps),
)(AddVenueComponent);
