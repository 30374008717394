// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { ButtonComponent } from 'app/shared';

import { checkPermissions } from 'app/utils';

/*:: import type { Props } from './users-list-table.component.types';*/
import styles from './users-list-table.module.scss';

export const headers = [
  { content: 'USERS.FULL_NAME' },
  { content: 'USERS.EMAIL' },
  { content: 'USERS.ACTIVE_ROLES' },
  {},
];

export class UsersListTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map((user, index) => (
            <TableRowComponent key={index} className={classNames({ [styles.pending]: !user.active })}>
              <TableCellComponent>
                {user.firstName} {user.lastName}
              </TableCellComponent>
              <TableCellComponent>{user.email}</TableCellComponent>
              <TableCellComponent>
                {user.roles.map(role => this.props.intl.formatMessage({ id: `ROLES.${role}` })).join(', ')}
              </TableCellComponent>
              <TableCellComponent align="right">
                {user.active || <FormattedMessage id="USERS.PENDING" />}
                {user.active && checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'update']) && (
                  <ButtonComponent
                    theme="secondary-outline"
                    to={`${this.props.match.url}/${user.id}/roles`}
                    size="small"
                  >
                    <FormattedMessage id="USERS.EDIT_ROLES" />
                  </ButtonComponent>
                )}
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
