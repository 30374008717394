import { PageHeaderComponent } from 'app/core';
import { ButtonComponent, Typography } from 'app/shared';
import { LoadingComponent } from 'app/shared/loading/loading.component';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  actions as serviceVoucherActions,
  ServiceVoucherIssuingStatus,
  ServiceVoucherStatus,
} from 'v2/service-voucher/postpay/service-vouchers-slice';
import { hasLink } from 'v2/utils/links';
import { ModalComponent } from 'v2/utils/modal/modal.component';
import { useV2Selector } from 'v2/utils/redux-utils';
import { voucherIssuingStateSelector, voucherStateSelector } from '../service-voucher-selectors';
import { isServiceVoucherLoaded } from '../utils/is-service-voucher-loaded';
import { ServiceVoucherDetailsComponent } from '../voucher-details/service-voucher-details.component';
import { CancelIssuingWarningModal } from './cancel-issuing-warning-modal';

type VoucherPreviewPageProps = RouteComponentProps<{ id: string }>;

export const PreviewVoucherPage: React.FC<VoucherPreviewPageProps> = ({
  match: {
    params: { id: voucherId },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(serviceVoucherActions.voucherRequested({ voucherId }));
  }, [dispatch, voucherId]);
  const serviceVoucherResult = useV2Selector(
    state => voucherStateSelector(voucherId)(state) ?? ({ status: ServiceVoucherStatus.Loading } as const),
  );
  const voucherIssuingState = useV2Selector(voucherIssuingStateSelector(voucherId));
  const submissionStatus = voucherIssuingState?.status ?? ServiceVoucherIssuingStatus.NotSubmitted;

  const onConfirm = useCallback(() => {
    dispatch(serviceVoucherActions.draftVoucherIssuingRequested({ voucherId }));
  }, [voucherId, dispatch]);

  const redirectToPrintingView = useCallback(() => {
    // redirect to printing view but also put voucher view in the history stack
    history.push(`/service-vouchers/${voucherId}`);
    history.push(`/service-vouchers/${voucherId}/print`);
  }, [voucherId, history]);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  useEffect(() => {
    if (submissionStatus === ServiceVoucherIssuingStatus.SubmitSuccess) {
      setShowSuccessModal(true);
    }
  }, [submissionStatus]);

  if (!isServiceVoucherLoaded(serviceVoucherResult)) {
    return (
      <Wrapper voucherId={voucherId}>
        <LoadingComponent isLoading size="large" />
      </Wrapper>
    );
  } else {
    if (!showSuccessModal && !hasLink(serviceVoucherResult.voucher, 'usecase:issue-voucher:action:submit-voucher')) {
      return (
        <Wrapper voucherId={voucherId}>
          <Typography color="greyDark" id="VAANA_SYSTEM.ERRORS.VOUCHER_ALREADY_EXISTS" />
        </Wrapper>
      );
    }
    return (
      <Wrapper voucherId={voucherId}>
        <ServiceVoucherDetailsComponent
          title="SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.VOUCHER_DETAILS_TITLE"
          voucher={serviceVoucherResult.voucher}
          actionButtonsLeft={[
            <ButtonComponent theme="outline-primary" onClick={() => setShowCancelModal(true)}>
              <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CANCEL" />
            </ButtonComponent>,
            <ButtonComponent theme="outline-primary" onClick={() => history.goBack()}>
              <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.EDIT" />
            </ButtonComponent>,
          ]}
          actionButtonsRight={[
            <ButtonComponent
              theme="primary"
              disabled={submissionStatus === ServiceVoucherIssuingStatus.Submitting}
              onClick={onConfirm}
            >
              <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.CONFIRM" />
            </ButtonComponent>,
          ]}
        />
        {showSuccessModal && (
          <ModalComponent
            opened={showSuccessModal}
            confirmButton={{
              label: 'SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.PRINTING_PROMPT_CONFIRM',
              onClick: redirectToPrintingView,
            }}
          >
            <strong>
              <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.PRINTING_PROMPT_BODY_TEXT" />
            </strong>
          </ModalComponent>
        )}
        {showCancelModal && (
          <CancelIssuingWarningModal
            isOpened={true}
            onCancel={() => setShowCancelModal(false)}
            onConfirm={() => history.push('/service-vouchers/types')}
          />
        )}
      </Wrapper>
    );
  }
};

const Wrapper: React.FC<{ voucherId: string }> = ({ voucherId, children }) => {
  const history = useHistory();
  return (
    <>
      <PageHeaderComponent
        title="SERVICE_VOUCHERS.POSTPAY.PREVIEW_SERVICE_VOUCHER.PAGE_TITLE"
        onBack={() => history.goBack()}
        separatorHeight={40}
      />
      {children}
    </>
  );
};
