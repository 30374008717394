// @flow
import React from 'react';
import classNames from 'classnames';
import { Field, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import {
  ButtonComponent,
  ConnectedCheckboxComponent,
  ConnectedDateRangeComponent,
  ConnectedMoneyInputComponent,
  SeparatorComponent,
  TileComponent,
  Typography,
} from 'app/shared';
import { useIntl } from 'app/hooks';
import { voucherValidityBounds } from 'app/utils/dates';

import { CouponsCalculatorFinalContainer } from 'app/coupons/shared';
import { cellDataGetter, cellMessageIdGetter } from '../coupons-list.helpers';
import { columnsName } from '../columns-names';

/*:: import type { CellProps, Props, RowHeaderProps, RowRenewProps } from './coupons-list-row.types';*/
import styles from './coupons-list-row.module.scss';

const Cell = (props /*: CellProps*/) => {
  const { columnName, coupon } = props;
  const cellContent = cellDataGetter({ dataKey: columnName, rowData: coupon });

  return (
    <div className="col-6 col-md-3 col-lg-4">
      <FormattedMessage id={cellMessageIdGetter(columnName)} tagName="small" />
      <div>{cellContent}</div>
    </div>
  );
};

const Birthdate = ({ dateOfBirth }) => (
  <span className={classNames(styles.birthday)}>
    <i className="fi fi-cake mr-1" />
    <span className={styles.date}>{dateOfBirth}</span>
  </span>
);

const PersonalIdentificationCode = ({ personalIdentificationCode }) => <div>{personalIdentificationCode}</div>;

const PersonalIdentificationCodeOrBirthdate = ({
  personalIdentificationCode,
  dateOfBirth,
  displayPersonalIdentificationCode,
}) =>
  !!personalIdentificationCode && displayPersonalIdentificationCode ? (
    <PersonalIdentificationCode personalIdentificationCode={personalIdentificationCode} />
  ) : (
    <Birthdate dateOfBirth={dateOfBirth} />
  );

const RowHeader = ({ displayPersonalIdentificationCode, ...props } /*: RowHeaderProps*/) => (
  <>
    <div className={classNames('row justify-content-start', styles.wrapper)}>
      <div className={classNames('col-auto mw-100 mt-0', styles.header)}>
        {props.reference && (
          <Typography className="mr-4" tag="span" color="grey" type="largeTextBold">
            {props.reference}
          </Typography>
        )}
        <PersonalIdentificationCodeOrBirthdate
          dateOfBirth={props.dateOfBirth}
          personalIdentificationCode={props.personalIdentificationCode}
          displayPersonalIdentificationCode={displayPersonalIdentificationCode}
        />
      </div>
    </div>
    <SeparatorComponent />
  </>
);

const commonSubscriptions = {
  value: true,
  dirtySinceLastSubmit: true,
  error: true,
  submitError: true,
  touched: true,
  active: true,
};

const RowRenew = (props /*: RowRenewProps*/) => {
  const intl = useIntl();
  const { rowData } = props;

  return (
    <div className="row">
      <div className="col-12 col-xl">
        <ConnectedDateRangeComponent
          field={Field}
          name={`coupons[${rowData.formIndex}].valid`}
          label="COUPONS.RENEW.LABEL.VALID"
          startDateKey="from"
          endDateKey="to"
          required
          daysCounter
          subscription={commonSubscriptions}
          disabled={!rowData.active}
          bounds={voucherValidityBounds}
        />
      </div>
      <div className="col-6 col-xl">
        <ConnectedMoneyInputComponent
          field={Field}
          name={`coupons[${rowData.formIndex}].maxTransaction`}
          label="COUPONS.RENEW.LABEL.MAX_TRANSACTION"
          subscription={{
            ...commonSubscriptions,
            dirty: true,
          }}
          disabled={!rowData.active}
        />
      </div>
      <div className="col-6 col-xl">
        <FormSpy
          subscription={{
            submitting: true,
          }}
        >
          {({ form }) => (
            <CouponsCalculatorFinalContainer
              value={{
                name: `coupons[${rowData.formIndex}].defaultValue`,
                label: intl.formatMessage({ id: 'COUPONS.RENEW.LABEL.VALUE_AMOUNT' }),
                required: true,
                disabled: !rowData.active,
                values: {
                  maxPrice: rowData.maxTransaction,
                },
              }}
              form={form}
              calculations={`coupons[${rowData.formIndex}].defaultValueCalculations`}
            />
          )}
        </FormSpy>
      </div>
    </div>
  );
};

function getRowHeaderProps(coupon, displayPersonalIdentificationCode /*: boolean*/) /*: RowHeaderProps*/ {
  return {
    dateOfBirth: coupon.dateOfBirth ? cellDataGetter({ dataKey: columnsName.dateOfBirth, rowData: coupon }) : undefined,
    personalIdentificationCode: cellDataGetter({ dataKey: columnsName.personalIdentificationCode, rowData: coupon }),
    reference: cellDataGetter({ dataKey: columnsName.reference, rowData: coupon }),
    displayPersonalIdentificationCode,
  };
}

export const CouponsListRowComponent = (props /*: Props*/) => {
  const { coupon, renewMode, index, length } = props;
  const displayPersonalIdentificationCode = props.columns.includes(columnsName.personalIdentificationCode);

  const showHeader = props.columns.includes(columnsName.reference) || displayPersonalIdentificationCode;

  const isColumnExcluded = columnName => {
    const excludedColumns = [columnsName.reference, columnsName.dateOfBirth, columnsName.personalIdentificationCode];
    return excludedColumns.includes(columnName);
  };

  const rowHeaderProps /*: RowHeaderProps*/ = getRowHeaderProps(coupon, displayPersonalIdentificationCode);

  return (
    <TileComponent
      className={classNames(styles.tile, {
        [styles.even]: index % 2 === 0,
        [styles.first]: index === 0,
        [styles.last]: index + 1 === length,
      })}
    >
      <div className={styles.controlsRow}>
        <Field
          className={styles.checkbox}
          name={`coupons[${coupon.formIndex}].active`}
          component={ConnectedCheckboxComponent}
          disabled={coupon.invalidated && renewMode}
          subscription={{
            value: true,
          }}
        />
        <ButtonComponent theme="secondary-outline" to={`/coupons/${coupon.id}`}>
          <i className="fi fi-details" />
          <FormattedMessage id="COUPONS.TYPES.LABEL.OPEN" />
        </ButtonComponent>
      </div>
      {showHeader && <RowHeader {...rowHeaderProps} />}
      {renewMode && <RowRenew rowData={coupon} />}
      {coupon.invalidated && (
        <span className={styles.badge}>
          <FormattedMessage id="COUPONS.DETAILS.LABEL.INVALIDATED" />
        </span>
      )}

      <div className="row">
        {props.columns
          .filter(columnName => !isColumnExcluded(columnName))
          // filter out names with Input at the end
          .filter(columnName => !/\w*Input\b/.test(columnName))
          .map(columnName => {
            return <Cell columnName={columnName} coupon={coupon.initialValue} key={columnName} />;
          })}
      </div>
    </TileComponent>
  );
};
