// @flow

import { RSAA } from 'redux-api-middleware';
/*:: import type { Dispatch } from 'redux';*/

import { fetchCoupon } from 'app/redux/coupon/coupon.actions';

import * as types from './payments.action-types';
/*:: import type { PaymentsRequest } from 'app/coupons/mass-charge/coupons-mass-charge.component.types';*/

// TODO introduce body type
export const validateCouponCharge = (body /*: any*/) => ({
  [RSAA]: {
    types: [
      types.VALIDATE_COUPON_CHARGE_REQUEST,
      {
        type: types.VALIDATE_COUPON_CHARGE_SUCCESS,
        meta: body,
      },
      types.VALIDATE_COUPON_CHARGE_FAILURE,
    ],
    endpoint: `/payments/venue/validate`,
    method: 'POST',
    body,
  },
});

export const chargeCoupon = (body /*: PaymentsRequest*/) => ({
  [RSAA]: {
    types: [types.COUPON_CHARGE_REQUEST, types.COUPON_CHARGE_SUCCESS, types.COUPON_CHARGE_FAILURE],
    endpoint: `/payments/venue`,
    method: 'POST',
    body,
  },
});

// TODO: Define type of the body. In order to have correct type see how this function is used.
//
// This function is meant for debiting a single voucher and then reloading the voucher.
// There can be multiple payments but they should all target the same voucher.
//
// The backend request and the corresponding PaymentsRequest type support debiting many vouchers at the same time.
// Thus the accountNumber is defined per payment.
//
// For this use case the accountNumber (voucher id) should naturally be same for all payments, but this is not checked here.
//
// Furthermore the actual content of the body differs from the PaymentsRequest type specifies.
// In addition to the 'payments' property (which is an array of Payment objects as defined in the PaymentsRequest type),
// a `coupon` property with the full details of the voucher is passed (see example below) and sent to the backend.
// The backend ignores the `coupon` property. See below for an example value.

// Example value for "body" passed by coupons-details-charge-form.container.js:
//{
//  "payments": [
//    {
//      "accountNumber": 11720613345,
//      "active": true,
//      "amount": "1",
//      "amountCalculations": [
//        {
//          "amount": 1
//        }
//      ],
//      "vatRate": "NONE",
//      "serviceProduced": {
//        "startDate": "2021-08-18",
//        "endDate": "2021-08-18",
//        "validation": {
//          "minDate": "2017-02-28",
//          "maxDate": "2021-08-20"
//        }
//      },
//      "descriptiveService": "ASSISTIVE_DEVICES",
//      "reference": "first"
//    },
//    {
//      "accountNumber": 11720613345,
//      "active": true,
//      "amountCalculations": [
//        {
//          "amount": 1
//        }
//      ],
//      "serviceProduced": {
//        "startDate": "2021-08-19",
//        "endDate": "2021-08-19",
//        "validation": {
//          "minDate": "2017-02-28",
//          "maxDate": "2021-08-20"
//        }
//      },
//      "descriptiveService": "FOOD_SERVICE_WITHOUT_DELIVERY",
//      "amount": "2",
//      "reference": "second"
//    }
//  ],
//  "coupon": {
//    "id": 11720613345,
//    "type": "Venue 179664 - Tenkanen-Uronen = Specific",
//    "reference": "Descriptive Service Test",
//    "name": "Municipal 175740 - That Municipality With Long Nam",
//    "nameExtra": "Honkanen-Koivuniemi-Kutvonen-Karttapallo-Matkaradi",
//    "balance": 978,
//    "decisionNumber": "1",
//    "lastDeposit": 1000,
//    "totalDeposit": 1100,
//    "issuer": "Test-first-name-eSGFVKJLEH Test-last-name-dHDGEXFGGW",
//    "venue": "Venue 179664",
//    "venueId": 179664,
//    "descriptiveServices": [
//      "ASSISTIVE_DEVICES",
//      "FOOD_SERVICE_WITHOUT_DELIVERY"
//    ],
//    "vatRatesEnabled": false,
//    "gender": "f",
//    "validTo": "2022-02-28",
//    "validFrom": "2021-02-01",
//    "createdAt": "2021-02-17",
//    "transfers": [
//      {
//        "id": 2045434,
//        "issuerName": "Test-first-name-fFYYTKIJDN Test-last-name-wVIOYRMLIT",
//        "balance": 978,
//        "originalBalance": 1000,
//        "refundable": false,
//        "created": "2021-04-01",
//        "starts": "2021-02-01",
//        "expires": "2022-02-28"
//      },
//      {
//        "id": 2045414,
//        "issuerName": "Test-first-name-eSGFVKJLEH Test-last-name-dHDGEXFGGW",
//        "balance": 0,
//        "originalBalance": 100,
//        "refundable": false,
//        "created": "2021-02-17",
//        "starts": "2021-02-01",
//        "expires": "2021-02-28"
//      }
//    ],
//    "dateOfBirth": "2021-02-01"
//  }
//}

export const debitAndRefreshVoucher = (body /*: any*/) => async (dispatch /*: Dispatch*/) => {
  const debitResponse = await dispatch({
    [RSAA]: {
      types: [types.COUPON_CHARGE_REQUEST, types.COUPON_CHARGE_SUCCESS, types.COUPON_CHARGE_FAILURE],
      endpoint: `/payments/venue`,
      method: 'POST',
      body,
    },
  });

  if (debitResponse.type === types.COUPON_CHARGE_SUCCESS) {
    await dispatch(fetchCoupon(body.coupon.id));
  }

  return debitResponse;
};
