// @flow
import React from 'react';

/*:: import type { Intl } from 'app/hooks/useIntl/useIntl.types';*/

/*:: type IntlContextType = {
  intl: Intl,
};*/

// prettier-ignore
export const IntlContext = React.createContext/*:: <IntlContextType>*/({});
