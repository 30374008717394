// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { AlertComponent } from 'app/shared';
import { SummaryButtonsComponent } from 'app/register/shared';

/*:: import type { Props } from './register-success.component.types';*/

export class RegisterSuccessComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <>
        <PageHeaderComponent showBusinessName={false} title={this.props.title} />
        <AlertComponent type="success">{this.props.children}</AlertComponent>
        <SummaryButtonsComponent success />
      </>
    );
  }
}
