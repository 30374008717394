//@flow

import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './card-footer.module.scss';
/*:: import type { Props } from './card-footer.component.types';*/

export class CardFooterComponent extends Component /*:: <Props>*/ {
  static defaultProps = { direction: 'row', justifyContent: 'space-between', alignItems: 'center' };

  render() {
    const { className, direction, justifyContent, alignItems } = this.props;

    const style = { flexDirection: direction, justifyContent, alignItems };

    const footerClassNames = classNames(styles.footer, className);

    return (
      <div className={footerClassNames} style={style}>
        {this.props.children}
      </div>
    );
  }
}
