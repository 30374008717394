//@flow
import React from 'react';
import classNames from 'classnames';

import { useIntl } from 'app/hooks';
import { isTranslationKey } from './is-translation-key';

/*:: import type { Props } from './typography.component.types';*/
import styles from './typography.module.scss';

export const Typography = (props /*: Props*/) => {
  const intl = useIntl();
  const { tag: Tag, type, color, className, disabled, id, values, ...other } = props;

  const tagClassNames = classNames(styles.typography, styles[type], styles[color], className, {
    [styles.disabled]: disabled,
  });

  if (id) {
    return (
      <Tag className={tagClassNames} {...other}>
        {isTranslationKey(id) ? intl.formatMessage({ id }, !!values && values) : id}
        {props.children}
      </Tag>
    );
  }

  return <Tag className={tagClassNames}>{props.children}</Tag>;
};

Typography.defaultProps = {
  color: 'primary',
  tag: 'div',
  type: 'bodyText',
};
