import { handleActions } from 'redux-actions';
import omit from 'lodash/omit';

import { MODAL_CLOSE, MODAL_DESTROY, MODAL_OPEN, MODAL_REGISTER, MODAL_TOGGLE } from './modal.actions-types';

const defaultState = {};

export const modalReducer = handleActions(
  {
    [MODAL_REGISTER]: (state, { payload }) => ({
      ...state,
      [payload.name]: { isOpen: payload.isOpen || false },
    }),
    [MODAL_DESTROY]: (state, { payload }) => ({
      ...omit(state, payload.name),
    }),
    [MODAL_OPEN]: (state, { payload }) => ({
      ...state,
      [payload.name]: {
        ...state[payload.name],
        isOpen: true,
      },
    }),
    [MODAL_CLOSE]: (state, { payload }) => ({
      ...state,
      [payload.name]: {
        ...state[payload.name],
        isOpen: false,
      },
    }),
    [MODAL_TOGGLE]: (state, { payload }) => {
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          isOpen: !state[payload.name].isOpen,
        },
      };
    },
  },
  defaultState,
);
