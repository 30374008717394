import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { withAuthorization } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchUsers } from 'app/redux/users/users.actions';

import { UsersComponent } from './users.component';

const mapStateToProps = ({ dictionaries, users: { business, entities } }) => ({
  business,
  loading: !entities[business.id] || !dictionaries['user-roles/customer'],
  users: get(entities, [business.id, 'list']),
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('user-roles/customer')),
  clearAndFetchUsers: business => dispatch(fetchUsers(business, 'customer', true)),
  reloadUsers: business => dispatch(fetchUsers(business, 'customer', false)),
});

export const UsersContainer = compose(
  withAuthorization(['customerUsers', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
)(UsersComponent);
