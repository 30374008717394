//@flow

import React from 'react';
import classNames from 'classnames';

import { Typography } from 'app/shared';

/*:: import type { Props } from './info-section.component.types';*/
import styles from '../coupons-details-info.module.scss';

export const InfoSectionComponent = ({ children, label, className, childrenTypography } /*: Props*/) => {
  return (
    <div className={classNames(styles.equal, className)}>
      {!!label && <Typography color="greyDark" id={label} />}
      {childrenTypography ? <Typography {...childrenTypography}>{children}</Typography> : children}
    </div>
  );
};
