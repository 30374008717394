// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { concat, isString, some } from 'lodash';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { currencyFormatter, getUnitTranslationId } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-create-confirm-table.component.types';*/

function PersonalIdentificationCodeCell({ personalIdentificationCode }) {
  return personalIdentificationCode ? (
    <TableCellComponent>{personalIdentificationCode}</TableCellComponent>
  ) : (
    <TableCellComponent />
  );
}

function BirthDateCell({ birthDate }) {
  return birthDate ? <TableCellComponent>{dateFormatter(birthDate)}</TableCellComponent> : <TableCellComponent />;
}

function GenderCell({ gender }) {
  return isString(gender) ? (
    <TableCellComponent>
      {gender === 'm' && <FormattedMessage id="CORE.GENDER.MALE" />}
      {gender === 'f' && <FormattedMessage id="CORE.GENDER.FEMALE" />}
      {gender === '' && '-'}
    </TableCellComponent>
  ) : (
    <TableCellComponent />
  );
}

export class CouponsCreateConfirmTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    const isBirthDatePresent = some(this.props.list, decision => decision.birthDate);
    const isGenderPresent = some(this.props.list, decision => isString(decision.gender));
    const isPersonalIdentificationCodePresent = some(this.props.list, decision => decision.personalIdentificationCode);

    const personalIdentificationCodeHeader = isPersonalIdentificationCodePresent
      ? [{ content: 'COUPONS.CREATE.LABEL.PERSONAL_IDENTIFICATION_CODE' }]
      : [];
    const birthDateHeader = isBirthDatePresent ? [{ content: 'COUPONS.CREATE.LABEL.DATE_OF_BIRTH' }] : [];
    const nameHeader = this.props.displayNameInput ? [{ content: 'COUPONS.CREATE.LABEL.NAME' }] : [];
    const genderHeader = isGenderPresent ? [{ content: 'COUPONS.CREATE.LABEL.GENDER' }] : [];
    const decisionNumberHeader = [{ content: 'COUPONS.CREATE.LABEL.DECISION_NUMBER' }];
    const priceUnitHeader = [{ content: getUnitTranslationId(this.props.priceUnit) }];

    const headers = concat(
      [],
      personalIdentificationCodeHeader,
      birthDateHeader,
      nameHeader,
      genderHeader,
      decisionNumberHeader,
      priceUnitHeader,
    );

    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map((decision, index) => (
            <TableRowComponent key={index}>
              {isPersonalIdentificationCodePresent && (
                <PersonalIdentificationCodeCell personalIdentificationCode={decision.personalIdentificationCode} />
              )}
              {isBirthDatePresent && <BirthDateCell birthDate={decision.birthDate} />}
              {this.props.displayNameInput && <TableCellComponent>{decision.name}</TableCellComponent>}
              {isGenderPresent && <GenderCell gender={decision.gender} />}
              <TableCellComponent>{decision.decisionId}</TableCellComponent>
              <TableCellComponent>{currencyFormatter.format(decision.individualValue)}</TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
