// @flow

import React from 'react';

import { ConnectControl } from 'app/shared';
import { DatePickerComponent } from 'app/shared/form/controls';
import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type { Props } from './connected-datepicker.component.types';*/

export const ConnectedDatePickerComponent = (props /*: Props*/) => {
  const { input, label } = props;

  const onDateChange = ({ date }) => {
    const formattedDate = date ? date.format(DATE_VALUE_FORMAT) : null;
    input.onChange(formattedDate);

    if (props.onChange) {
      props.onChange(formattedDate, input.name);
    }
  };

  const handleOnBlur = () => {
    if (props.onFocusChange) {
      props.onFocusChange(false);
    }

    // $FlowFixMe
    input.onBlur();
  };

  const handleOnFocus = () => {
    if (props.onFocusChange) {
      props.onFocusChange(true);
    }

    // $FlowFixMe
    input.onFocus();
  };

  const datePickerProps = {
    selectedDate: input.value,
    label,
    onBlur: handleOnBlur,
    onChange: onDateChange,
    onFocus: handleOnFocus,
  };

  return <ConnectControl {...props} component={DatePickerComponent} {...datePickerProps} />;
};
