import { stringify } from 'qs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonComponent } from '../../../shared';

/*:: import type { Props } from './coupons-check-balance-venuesearch-navigation.component.types';*/

const buildVenueSearchLink = (
  serviceVoucherId /*: string*/,
  locale /*: string*/,
  venueSearchHost /*: string*/,
) /*: string*/ => {
  const pathByLocale = {
    fi: '/fi/palvelusetelit/kayttokohdehaku',
    sv: '/sv/social-and-healthcare/venue-search',
    en: '/en/social-and-healthcare/venue-search',
  };
  const path = pathByLocale[locale] || pathByLocale['fi'];

  const queryString = stringify(
    {
      service_type: 'servicecoupon',
      service_coupon_code: serviceVoucherId,
    },
    { addQueryPrefix: true },
  );

  return `${venueSearchHost}${path}${queryString}`;
};

export const CouponsCheckBalanceVenueSearchNavigation = (props /*: Props*/) => {
  const { serviceVoucherId, locale, venueSearchHost } = props;

  const navigateToVenueSearch = () /*: void*/ => {
    window.location.href = buildVenueSearchLink(serviceVoucherId, locale, venueSearchHost);
  };

  return (
    <ButtonComponent theme="primary" onClick={navigateToVenueSearch}>
      <FormattedMessage id="MODULES.CPS.SEARCH_VENUES.TITLE" />
    </ButtonComponent>
  );
};
