// @flow

import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import orderBy from 'lodash/orderBy';

import { ButtonComponent, NoResultsContainer, SeparatorComponent } from 'app/shared';
import { XL_BREAKPOINT } from 'app/constants';

/*:: import type { Props, State } from './list.component.types';*/
import styles from './list.module.scss';

export const ASC = 'asc';
export const DESC = 'desc';

export class ListComponent extends Component /*:: <Props, State>*/ {
  static defaultProps = {
    isCollapsed: true,
    limit: null,
    tileHeader: Fragment,
  };

  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isCollapsed: props.isCollapsed,
      list: props.list,
      direction: ASC,
    };
  }

  static getDerivedStateFromProps(props /*: Props*/, state /*: State*/) {
    const list /*: any[]*/ = props.list;
    const column /*: ?string*/ = state.column;
    const direction /*: string*/ = state.direction;

    return {
      list: orderBy/*:: <any[]> */(list, column, direction),
    };
  }

  inverseDirection = () => (this.state.direction === ASC ? DESC : ASC);

  orderBy = (column /*: string*/) => {
    const { column: stateColumn } = this.state;
    const direction = column === stateColumn ? this.inverseDirection() : ASC;

    this.setState((prev /*: State*/) => ({
      list: orderBy/*:: <any[]> */(prev.list, [column], [direction]),
      column,
      direction,
    }));
  };

  toggle = () => this.setState(prev => ({ isCollapsed: !prev.isCollapsed }));

  renderList = (list /*: any*/) => {
    const { column, direction } = this.state;
    const { table: Table, tile: Tile, tileHeader: TileHeader } = this.props;

    const currentOrder = {
      column,
      direction,
    };

    return (
      <MediaQuery minWidth={XL_BREAKPOINT}>
        {matches =>
          matches ? (
            <>
              <Table list={list} {...this.props.props} orderBy={this.orderBy} currentOrder={currentOrder} />
              {!list.length && <NoResultsContainer {...this.props.noResultsProps} withBackground={true} />}
            </>
          ) : list.length ? (
            <>
              <TileHeader />
              <div className={styles.tiles}>
                {list.map((tile, index) => (
                  <div className={styles.tile} key={index}>
                    <Tile tile={tile} {...this.props.props} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoResultsContainer {...this.props.noResultsProps} />
          )
        }
      </MediaQuery>
    );
  };

  renderCollapsed = () => (
    <div className={styles.collapsed}>
      <FormattedMessage id="CORE.COLLAPSE.RECORDS" values={{ records: this.props.list.length }} />
    </div>
  );

  sliceList = () /*: any[]*/ => {
    const { isCollapsed, list: rawList } = this.state;
    const { limit } = this.props;
    return !!limit && !isCollapsed ? rawList.slice(0, limit) : rawList;
  };

  render() {
    const { isCollapsed } = this.state;
    const { list: rawList, limit } = this.props;

    const list = this.sliceList();

    return (
      <>
        {this.props.collapsible && (
          <>
            <div className={styles.header}>
              {!!this.props.limit && rawList.length > limit && (
                <>
                  <span className={styles.displayed}>
                    <FormattedMessage
                      id="CORE.COLLAPSE.DISPLAYED"
                      values={{ displayed: list.length, count: rawList.length }}
                    />
                  </span>
                  <ButtonComponent
                    size="small"
                    theme="secondary-outline"
                    onClick={this.toggle}
                    disabled={rawList.length <= limit}
                  >
                    {isCollapsed ? (
                      <FormattedMessage id="CORE.COLLAPSE.SHOW_LESS" />
                    ) : (
                      <FormattedMessage id="CORE.COLLAPSE.SHOW_MORE" />
                    )}
                  </ButtonComponent>
                </>
              )}
            </div>
            <SeparatorComponent />
          </>
        )}

        {!!limit || isCollapsed ? this.renderList(list) : this.renderCollapsed()}
      </>
    );
  }
}
