// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withAuthorization } from 'app/common';
import { fetchContractedVenues } from 'app/redux/pricelists/pricelists.actions';
import { PricelistsManageComponent } from './pricelists-manage.component';

/*:: import type { mapDispatchProps, mapStateProps } from './pricelists-manage.component.types';*/

const mapStateToProps = ({ pricelists: { venues, isLoading } }) /*: mapStateProps*/ => ({
  venues,
  isLoading,
});

const mapDispatchToProps = (dispatch) /*: mapDispatchProps*/ => ({
  fetchContractedVenues: () => dispatch(fetchContractedVenues()),
});

export const PricelistsManageContainer /*: React$ComponentType<{}>*/ = compose(
  withAuthorization(['contracts', 'update']),
  connect(mapStateToProps, mapDispatchToProps),
)(PricelistsManageComponent);
