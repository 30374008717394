import { ContractStatus, ContractSubStatus } from 'app/constants';
import { ContractWithPricelists, InputContractWithPricelists } from 'app/types/pricelist';

// InputXXX = what backend returns
// XXX = formatted for frontend use

export interface Contract extends BaseContract, ContractWithPricelists {}

export interface InputContract extends BaseContract, InputContractWithPricelists {}

type BaseContract = {
  id: number;
  status: ContractStatus;
  subStatus?: ContractSubStatus;

  // TODO: these Dates are probably wrong, they are strings?
  changesRequestedAt?: Date;
  lastEditedByServiceProviderAt?: Date;
  changeRequestDeadlineAt?: Date;

  serviceTypeGroup?: ServiceTypeGroup;
  services: Service[];
  message?: string;
  validFrom: string;
  validTo?: string;

  previousContracts?: InputContract[];
  submittedAt?: string;
  allowedActions?: ContractAllowedAction[];

  // TODO: fields are missing, add as needed
};

export type ServiceTypeGroup = {
  id: number | string;
  name: string;
};

export type Service = {
  name: string;
};

export enum ReviewAction {
  Approve = 'approve',
  Reject = 'reject',
  RequestChanges = 'request_changes',
}

export enum ContractAllowedAction {
  Approve = 'APPROVE',
  Reject = 'REJECT',
  RequestChanges = 'REQUEST_CHANGES',
}

export type Venue = {
  name?: string;
  contracts?: Contract[];
  filteredContracts?: Contract[];
};

export type InputVenue = {
  name?: string;
  contracts?: InputContract[];
  filteredContracts?: InputContract[];
};
