//@flow

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { withRouter } from 'app/common/with-router/with-router.hoc';
import { ButtonComponent } from 'app/shared';

import { reportMessage } from 'app/sentry/sentry';

import styles from './not-found.module.scss';
/*:: import type { Props } from './not-found.component.types';*/

const NotFoundPageComponent = (props /*: Props*/) => {
  useEffect(
    () => {
      const {
        location: { state = {} },
        isInitialRoute,
      } = props;

      if (!state.hasOwnProperty('fromMiddleware') || state.fromMiddleware === false) {
        reportMessage('Redirection to 404 page', { requestedLocation: props.prevLocation.pathname, isInitialRoute });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className={classNames('container-fluid d-flex flex-column', styles.container)}>
      <aside className={styles.image}>
        <div className={styles.polygon} />
      </aside>

      <div className="row">
        <div className="col-2 offset-2">
          <Logo />
        </div>
      </div>

      <div className="row">
        <div className="col-9 offset-2">
          <p className={styles.title}>
            <FormattedMessage id="NOT_FOUND.TITLE" />
          </p>
          <p className={styles.code}>404</p>
          <FormattedMessage id="NOT_FOUND.DESCRIPTION" />

          <ButtonComponent onClick={() => window.location.replace('/')} className={styles.button}>
            <FormattedMessage id="NOT_FOUND.GO_BACK_TO_HOME" />
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export const NotFoundComponent = withRouter(NotFoundPageComponent);
