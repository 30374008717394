//@flow
/*:: import type { Config } from './generate-time-range.util.types';*/

const supportedModes = [24];

const getTimeString = (hour, minute) /*: string*/ => {
  const hourAsString = String('0' + hour).slice(-2);
  const minuteAsString = String('0' + minute).slice(-2);

  return `${hourAsString}:${minuteAsString}`;
};

export const generateTimeRange = (config /*: Config*/) /*: string[]*/ => {
  const { mode = 24, step = 10 } = config || {};

  if (supportedModes.includes(mode)) {
    //24h*60min = 1440min

    const steps = (24 * 60) / step;
    const timeRange = [];

    new Array(steps).fill(null).reduce(acc => {
      const hour = ~~(acc / 60);
      const minute = acc % 60;

      timeRange.push(getTimeString(hour, minute));

      return acc + step;
    }, 0);

    return timeRange;
  } else {
    throw Error(`Mode ${mode}H is not supported`);
  }
};
