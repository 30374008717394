//@flow

import React from 'react';

import { Typography } from 'app/shared';

/*:: import type { Props } from './counter.component.types';*/
import styles from '../textarea.module.scss';

export const CharCounter = (props /*: Props*/) => {
  const { showCounter, value } = props;

  const charCounter = (value || '').length;

  return (
    showCounter && (
      <div className={styles.charCounter}>
        <Typography type="smallText" color="grey">
          {`${charCounter}/${props.maxLength}`}
        </Typography>
      </div>
    )
  );
};
