import React from 'react';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { SelectComponent, SeparatorComponent, StatusComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import styles from './pricelists-filters.module.scss';
import { ContractStatus, ContractSubStatus } from 'app/constants';
import { ServiceTypeGroup } from 'app/types/contract';

export type Filters = {
  organizerId?: number;
  serviceTypeGroupId?: number | string;
};

export type ServiceTypeGroupWithStatus = ServiceTypeGroup & {
  status: ContractStatus | ContractSubStatus;
};

type Organizer = {
  id: number;
  name: string;
};

type ComponentContext = 'provider' | 'organizer';

export type ComponentProps = {
  onFiltersChanged: (f: Filters) => void;
  filters: Filters;
  serviceTypeGroups: ServiceTypeGroup[];
  organizers?: Organizer[];
  context: ComponentContext;
};

type Option = {
  label: string;
  status: ServiceTypeGroupWithStatus['status'];
};

const renderOptionLabel = (option: Option, context: ComponentContext, isOption = false) => (
  <>
    {option.status && (
      <div className={isOption ? styles.optionlabel : undefined}>
        {option.label}
        <StatusComponent size="small" status={option.status} iconSet={context} className="float-right" />
      </div>
    )}
    {!option.status && option.label}
  </>
);

export const PricelistsFiltersComponent: React.FunctionComponent<ComponentProps> = ({
  filters,
  onFiltersChanged,
  serviceTypeGroups,
  context,
  organizers,
}: ComponentProps) => {
  const onSelectChange = (value: number | string, key: keyof Filters) => {
    if (key === 'organizerId') {
      onFiltersChanged({ serviceTypeGroupId: undefined, organizerId: value as number });
    } else {
      onFiltersChanged({ ...filters, [key]: value });
    }
  };

  return (
    <CardComponent>
      <CardHeaderComponent title="PRICELISTS.DETAILS.FILTERS.TITLE" />
      <SeparatorComponent />
      <CardBodyComponent>
        <div className="row">
          {organizers && (
            <div className={`col-12 ${styles.select}`}>
              <SelectComponent
                options={organizers}
                value={filters.organizerId}
                onChange={({ value }: HTMLInputElement) => onSelectChange(value, 'organizerId')}
                label={<FormattedMessage id="PRICELISTS.DETAILS.FILTERS.ORGANIZER" />}
                searchable={true}
                autoBlur={false}
                labelKey="name"
                valueKey="id"
              />
            </div>
          )}
          {!!serviceTypeGroups.length && (
            <div className={`col-12 ${styles.select}`}>
              <SelectComponent
                options={serviceTypeGroups}
                value={filters.serviceTypeGroupId}
                onChange={({ value }: HTMLInputElement) => onSelectChange(value, 'serviceTypeGroupId')}
                label={<FormattedMessage id="PRICELISTS.DETAILS.FILTERS.SERVICE_TYPE_GROUP" />}
                searchable={true}
                autoBlur={false}
                optionRenderer={(option: Option) => renderOptionLabel(option, context, true)}
                valueRenderer={(option: Option) => renderOptionLabel(option, context)}
                labelKey="name"
                valueKey="id"
              />
            </div>
          )}
        </div>
      </CardBodyComponent>
    </CardComponent>
  );
};
