import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardFooterComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';
import { LoadingComponent } from 'app/shared/loading/loading.component';

import { FieldArray, FieldArrayRenderProps, Form, FormikProps } from 'formik';
import { ServiceEventRowComponent, ServiceEventRowVoucher } from './service-event-row.component';
import { FormattedMessage } from 'app/translations/formatted-message.component';

import {
  GeneralValidationError,
  SelectedMonthFieldValue,
  ServiceEventFormValues,
  ServiceEventRow,
  ServiceEventSubmissionComponentProps,
  ServiceEventValidationErrors,
} from './types';
import { ServiceEventSubmissionConfirmationModal } from './service-event-submission-confirmation-modal.component';
import { ServiceEventSubmissionFailedModal } from './service-event-submission-failed-modal.component';
import { SubmitButton } from './submit.button';
import { isEqual, last } from 'lodash';
import { actions, name as serviceEventSliceName, ServiceEventStatus } from './service-event-slice';
import { V2RootState } from '../../v2.reducer';
import {
  OnMonthChangedCallback,
  ResetToPreviouslySelectedMonthCallback,
  SelectedMonthComponent,
} from './selected-month.component';
import {
  computeInitialValues,
  computeNextServiceEventRow,
  createCalculateMaxEndDateForRow,
} from './service-event-values';
import { Modal } from 'app/shared/modal-new/modal.container';
import styles from './service-event-submission.component.module.scss';
import {
  getHousingServiceProducedServiceRule,
  housingServicesPaidAbsenceService,
  MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES,
  ServiceArea,
} from './service-types';
import { errorCodeToTranslationKey } from './errors';
import { ErrorsComponent } from './errors.component';
import { HousingServicesVoucher } from './types/housing-services-voucher';
import { getPeriodsOfPaidAbences } from './backend-services/validate-service-event';

declare type ServiceEventRowsProps = FieldArrayRenderProps & {
  voucher: HousingServicesVoucher;
  venueId: string;
};

const isLastRowTerminatingService = (serviceArea: ServiceArea, rows: ServiceEventRow[]) => {
  const lastRow = last(rows);
  if (!lastRow) {
    return false; // no rows, so not terminating
  }

  return getHousingServiceProducedServiceRule(serviceArea, lastRow.producedService).isTerminatingService;
};

const ServiceEventRows: FunctionComponent<ServiceEventRowsProps> = props => {
  const rows: ServiceEventRow[] = props.form.values.serviceEventRows;
  const isAnyRowInvalid = !!props.form.errors.serviceEventRows;

  const isForbiddenToAddNewRows = isAnyRowInvalid || isLastRowTerminatingService(props.voucher.serviceArea, rows);

  function isLastRow(index: number) {
    return index === rows.length - 1;
  }

  function addServiceEventRow() {
    props.push(computeNextServiceEventRow(rows, props.form.values.selectedMonth, props.voucher));
  }

  function isServiceEventRowLocked(index: number) {
    return !isLastRow(index);
  }

  function serviceEventRowRemover(index: number) {
    return () => {
      props.remove(index);
    };
  }

  function isRemovableServiceEventRow(index: number) {
    // Service Event Row is removable if its
    // 1. Not the first row
    // 2. The last row within all rows
    const isFirstRow: boolean = index === 0;
    return !isFirstRow && isLastRow(index);
  }

  function getUnavailableServicesForRowIndex(index: number): ServiceEventRowVoucher['descriptiveServices'] {
    if (index === 0) return [];

    const hasCurrentPaidAbsencePeriodMaxedOut =
      (last(getPeriodsOfPaidAbences(rows.slice(0, index)))?.duration || 0) >= MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES;

    return [rows[index - 1].producedService].concat(
      hasCurrentPaidAbsencePeriodMaxedOut ? housingServicesPaidAbsenceService : [],
    );
  }

  return (
    <>
      <div role="list">
        {rows.map((_, index: number) => (
          <ServiceEventRowComponent
            isRowLocked={isServiceEventRowLocked(index)}
            key={index}
            onRemove={serviceEventRowRemover(index)}
            formNamePrefix={`serviceEventRows.${index}`}
            voucher={props.voucher}
            venueId={props.venueId}
            removable={isRemovableServiceEventRow(index)}
            unavailableServices={getUnavailableServicesForRowIndex(index)}
            calculateMaxEndDate={createCalculateMaxEndDateForRow(index, rows)}
          />
        ))}
      </div>
      <ButtonComponent theme="primary-outline" onClick={addServiceEventRow} disabled={isForbiddenToAddNewRows}>
        <i className="fi fi-add" />
        <FormattedMessage id="SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.ADD_SERVICE_EVENT_ROW" />
      </ButtonComponent>
    </>
  );
};

const GeneralErrors: FunctionComponent<{ errors: GeneralValidationError[] }> = ({ errors = [] }) => {
  const errorKeys = errors.map(error => ({
    id: errorCodeToTranslationKey('SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.ERROR', error),
  }));

  return <ErrorsComponent errors={errorKeys} />;
};

declare type ServiceEventSubmissionFormProps = FormikProps<ServiceEventFormValues> & {
  voucher: HousingServicesVoucher;
  venueId: string;
  onSubmitButtonClick: () => void;
};

interface FormResetConfirmationModalState {
  isOpen: boolean;
  newMonth?: SelectedMonthFieldValue;
  resetToPreviouslySelectedMonth?: ResetToPreviouslySelectedMonthCallback;
}

const intialFormResetConfirmationModalState: FormResetConfirmationModalState = {
  isOpen: false,
};

const ServiceEventSubmissionForm: FunctionComponent<ServiceEventSubmissionFormProps> = ({
  isValid,
  voucher,
  venueId,
  isSubmitting,
  setSubmitting,
  values,
  setValues,
  onSubmitButtonClick,
  errors: formikErrors,
}) => {
  const [formResetConfirmationModalState, setFormResetConfirmationModalState] = useState(
    intialFormResetConfirmationModalState,
  );

  const status = useSelector<V2RootState, ServiceEventStatus>(state => state[serviceEventSliceName].status);
  // We cast to the actual error type returned by the validateServiceEvent function because we need more than the
  // Formik error type provides
  const errors = formikErrors as ServiceEventValidationErrors;

  useEffect(() => {
    if (!isSubmitting && status === ServiceEventStatus.Submitting) {
      setSubmitting(true);
    } else if (isSubmitting && status !== ServiceEventStatus.Submitting) {
      setSubmitting(false);
    }
  }, [setSubmitting, isSubmitting, status]);

  const isSubmissionFailed: boolean = status === ServiceEventStatus.SubmissionFailed;

  const showFormResetConfirmation = (newMonth: SelectedMonthFieldValue, resetToPreviouslySelectedMonth: () => void) => {
    setFormResetConfirmationModalState({
      isOpen: true,
      resetToPreviouslySelectedMonth,
      newMonth,
    });
  };
  const resetServiceEventFormForNewMonth = () => {
    const newValues = computeInitialValues(voucher, formResetConfirmationModalState.newMonth);
    setValues({
      ...values,
      serviceEventRows: newValues.serviceEventRows,
    });
    setFormResetConfirmationModalState({ isOpen: false });
  };
  const undoChangeToNewMonth = (resetToPreviouslySelectedMonth: () => void) => {
    resetToPreviouslySelectedMonth();
    setFormResetConfirmationModalState({ isOpen: false });
  };

  const onMonthChanged: OnMonthChangedCallback = (newMonth, resetToPreviouslySelectedMonth) => {
    if (formResetConfirmationModalState.isOpen) {
      return;
    }
    if (isEqual(computeInitialValues(voucher, values.selectedMonth), values)) {
      setValues(computeInitialValues(voucher, newMonth));
      return;
    }
    showFormResetConfirmation(newMonth, resetToPreviouslySelectedMonth);
  };

  return (
    <>
      {isSubmitting ? (
        <LoadingComponent isLoading={isSubmitting} overflow />
      ) : (
        <Form>
          <div className={styles.selectedMonth}>
            <SelectedMonthComponent
              allowedDateRange={{
                fromDate: voucher.firstAllowedDateOfService,
                toDate: voucher.lastAllowedDateOfService,
              }}
              onMonthChanged={onMonthChanged}
            />
          </div>

          {/* FieldArray doesn't support passing random props to the child component so we use the `render` instead of
          `component` */}
          <FieldArray
            name="serviceEventRows"
            render={p => <ServiceEventRows {...p} voucher={voucher} venueId={venueId} />}
          />

          <CardFooterComponent>
            {errors.general && <GeneralErrors errors={errors.general} />}
            <SubmitButton disabled={!isValid} onClick={onSubmitButtonClick} />
          </CardFooterComponent>
        </Form>
      )}
      <Modal
        isOpen={formResetConfirmationModalState.isOpen}
        confirmOptions={[
          {
            label: <FormattedMessage id="CORE.BACK" />,
            onClick: () =>
              undoChangeToNewMonth(formResetConfirmationModalState.resetToPreviouslySelectedMonth ?? (() => null)),
            theme: 'primary-outline',
            arrowBack: true,
          },
          {
            label: <FormattedMessage id="CORE.CONFIRM" />,
            onClick: () => resetServiceEventFormForNewMonth(),
          },
        ]}
      >
        <FormattedMessage id="CORE.PROMPT" />
      </Modal>

      {isSubmissionFailed && <ServiceEventSubmissionFailedModal voucher={voucher} />}
    </>
  );
};

export const ServiceEventSubmissionComponent: FunctionComponent<ServiceEventSubmissionComponentProps> = props => {
  const dispatch = useDispatch();
  const [submissionConfirmationModalOpen, setSubmissionConfirmationModalState] = useState(false);
  const showSubmissionConfirmation = () => setSubmissionConfirmationModalState(true);
  const hideSubmissionConfirmation = () => setSubmissionConfirmationModalState(false);

  const onSubmit = (serviceEventFormValues: ServiceEventFormValues) => {
    dispatch(
      actions.serviceEventSubmissionRequested({
        venueId: props.venueId,
        serviceEvent: serviceEventFormValues,
        voucher: props.voucher,
      }),
    );

    hideSubmissionConfirmation();
  };

  return (
    <>
      <ServiceEventSubmissionForm {...props} onSubmitButtonClick={showSubmissionConfirmation} />
      <ServiceEventSubmissionConfirmationModal
        venueId={props.venueId}
        voucher={props.voucher}
        serviceEventFormValues={props.values}
        isOpen={submissionConfirmationModalOpen}
        onCancel={hideSubmissionConfirmation}
        onSubmit={onSubmit}
      />
    </>
  );
};
