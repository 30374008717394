// @flow
import * as Sentry from '@sentry/browser';
import pick from 'lodash/pick';

import { configuration } from 'configuration';

/*:: import type { ReportMessageExtra } from './sentry.types';*/
/*:: import type { HandleErrorInfo } from 'app/app.component.types';*/

const findEmptyConfigVariables = config =>
  Object.entries(config).reduce((empty, [key, value]) => {
    if (!value) {
      return (empty += ` ${key}`);
    }
    return empty;
  }, '');

export const registerSentry = (config /*: any*/) => {
  const emptyKeys = findEmptyConfigVariables(pick(config, ['dsn', 'release', 'environment']));

  if (!configuration.dsn) {
    return false;
  }

  if (!!emptyKeys.length) {
    console.warn(
      `Sentry is enabled but some configuration variables are not provided:%c${emptyKeys}. Not enabling Sentry`,
      'font-weight: bold',
    );
    return;
  }

  try {
    Sentry.init({
      dsn: config.dsn,
      release: config.release,
      environment: config.environment,
      normalizeDepth: 10,
    });
  } catch (e) {
    console.error(e);
  }
};

export const reportMessage = (message /*: string*/, extra /*: ReportMessageExtra*/ = {}) /*: void*/ => {
  Sentry.withScope(scope => {
    Object.entries(extra).forEach(([key, value]) => {
      scope.setExtra(key, value);
    });

    Sentry.captureMessage(message);
  });
};

export const reportException = (error /*: Error*/, extra /*: HandleErrorInfo*/ = {}) => {
  Sentry.captureException(error, extra);
};
