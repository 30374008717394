import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceEventListComponent } from 'v2/service-events/postpay/service-event-list.component';
import { PostpayServiceVoucher } from 'v2/types/postpay';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { Typography } from 'app/shared';
import styles from './service-voucher-service-event-list.component.module.scss';
import { actions, ServiceEventsStatus, VoucherServiceEventsState } from './service-voucher-service-events-slice';
import { V2RootState } from 'v2/v2.reducer';

export type ServiceVoucherServiceEventListComponentProps = {
  voucher: PostpayServiceVoucher;
};

export const ServiceVoucherServiceEventListComponent = ({ voucher }: ServiceVoucherServiceEventListComponentProps) => {
  const dispatch = useDispatch();

  const serviceEventsState = useSelector<V2RootState, VoucherServiceEventsState | undefined>(
    state => state['service-voucher-service-events'][voucher.id],
  );

  useEffect(() => {
    // reset state when voucher changes
    dispatch(actions.voucherServiceEventsRequested({ voucherId: voucher.id }));
  }, [dispatch, voucher.id]);

  const Content = (() => {
    switch (serviceEventsState?.status) {
      case ServiceEventsStatus.Loading:
        return null;
      case ServiceEventsStatus.Loaded:
      case ServiceEventsStatus.Refreshing:
        const { serviceEvents } = serviceEventsState;
        return serviceEvents.length === 0 ? (
          <Typography id="SERVICE_VOUCHERS.POSTPAY.SERVICE_EVENTS.NO_EVENTS_ON_THE_VOUCHER" color="greyDark" />
        ) : (
          <ServiceEventListComponent serviceEvents={serviceEventsState.serviceEvents} />
        );
    }
  })();

  return (
    <CardComponent>
      <CardHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.SERVICE_EVENTS.SERVICE_EVENTS_ON_THE_VOUCHER" />
      <CardBodyComponent className={styles.cardBody}>{Content}</CardBodyComponent>
    </CardComponent>
  );
};
