import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonComponent } from '../../../shared';
import styles from './coupons-list-displayed-voucher-data-filter.module.scss';
import { Field } from 'react-final-form';
import { CouponsListCustomizeContainer } from '../customize/coupons-list-customize.container';
import { ConnectedToggleComponent } from 'app/shared/form/connected';

export const CouponsListDisplayedVoucherDataFilter = (props /*: props*/) => {
  const { form } = props;
  return (
    <CardComponent className={styles.panel}>
      <CardBodyComponent>
        <div className={styles.toggleContainer}>
          <Field
            name="activeSwitch"
            type="checkbox"
            component={ConnectedToggleComponent}
            label="COUPONS.RENEW.LABEL.SHOW_ONLY_SELECTED"
            onChange={props.showOnlySelectedToggle}
          />
          <ButtonComponent theme="primary-outline" className="float-right" onClick={props.clickCallback}>
            <i className="fi fi-arrow-left" />
            <FormattedMessage id="CORE.CLOSE" />
          </ButtonComponent>
        </div>
        <CouponsListCustomizeContainer form={form} />
      </CardBodyComponent>
    </CardComponent>
  );
};
