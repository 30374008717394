import { SyntheticEvent, useState } from 'react';
import { ButtonComponent, TextareaComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';

export interface RequestChangeProps {
  submitting: boolean;
  onRequestChange: (message: string) => void;
  onCancel: () => void;
}

export const REQUEST_CHANGE_MAX_MESSAGE_LENGTH = 1000;
export const RequestChange: React.FunctionComponent<RequestChangeProps> = ({
  submitting,
  onRequestChange,
  onCancel,
}: RequestChangeProps) => {
  const [message, setMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const onMessageChanged = (message: string) => {
    setMessage(message);
    setShowError(false);
  };

  const onRequestChangeClick = () => {
    if (message && message.length > 0) {
      onRequestChange(message);
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="pt-4">
      <TextareaComponent
        label={<FormattedMessage id="PRICELISTS.DETAILS.REQUEST_CHANGE_INPUT" />}
        value={message}
        maxLength={REQUEST_CHANGE_MAX_MESSAGE_LENGTH}
        error="VALIDATION.ORGANIZER_MESSAGE_REQUIRED"
        invalid={showError}
        required
        onChange={(e: SyntheticEvent<HTMLInputElement>) => onMessageChanged(e.currentTarget.value)}
      />
      <div className="text-right pt-4">
        <ButtonComponent theme="primary-outline" disabled={submitting} onClick={onCancel}>
          <FormattedMessage id="CORE.CANCEL" />
        </ButtonComponent>
        <ButtonComponent className="ml-2" theme="primary" disabled={submitting} onClick={onRequestChangeClick}>
          <FormattedMessage id="CORE.SEND" />
        </ButtonComponent>
      </div>
    </div>
  );
};
