// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { isBusinessDifferent } from 'app/utils';
import { VenueUsersPlaceholder } from './placeholder/venue-users.placeholder';

/*:: import type { Props } from './venue-users.component.types';*/

export class VenueUsersComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.clearAndFetchUsers();
    this.props.fetchVenue();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.redirectToServiceProviderPage();
    }
  }

  render() {
    if (this.props.loading) {
      return <VenueUsersPlaceholder />;
    }

    const venue = this.props.venue.venue;

    return (
      <>
        <PageHeaderComponent title={{ id: 'VENUES.USERS_TITLE', values: { venue: venue.name } }} backLink="/venues" />
        <UsersListContainer
          context="venue"
          customer={venue.id}
          users={this.props.users}
          reloadUsers={this.props.reloadUsers}
        />
      </>
    );
  }
}
