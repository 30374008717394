import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';

export type ActionButtonProps = {
  action: (id: string) => void;
  label?: TranslationKey;
};

export const ActionButton: React.FC<ActionButtonProps> = ({ action, label }) => {
  return (
    <ButtonComponent theme="primary" onClick={action} disabled={false}>
      <FormattedMessage id={label ?? 'CORE.SELECT'} />
    </ButtonComponent>
  );
};
