import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';

import { fetchTerms } from 'app/redux/terms/terms.actions';

import { AgreementsAcceptanceComponent } from './agreements-acceptance.component';

const mapStateToProps = ({ agreements: { terms } }, { intl: { locale }, name }) => ({
  loading: !terms[name],
  terms: get(terms, [name, locale]),
});

const mapDispatchToProps = (dispatch, { name }) => ({
  fetchTerms: () => dispatch(fetchTerms(name)),
});

export const AgreementsAcceptanceContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(AgreementsAcceptanceComponent);
