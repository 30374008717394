import { FunctionComponent, useEffect } from 'react';
import styles from './service-event-row.component.module.scss';
import classNames from 'classnames';
import { FormikAdapterField } from './formik-adapter-field.component';
import { ConnectedInputComponent, ConnectedSelectComponent } from 'app/shared';
import { PeriodComponent } from './period.component';
import { useIntl } from 'app/hooks/useIntl/useIntl';
import { Amount } from './amount.component';
import { computeRowEndDate, isServiceEndDateFixed } from './service-event-values';
import { useField } from 'formik';
import { ServiceEventRow } from './types';
import { HousingServicesVoucher } from './types/housing-services-voucher';
import { HousingServicesProducedService } from './service-types';
import { calculateLastAllowedServiceEventRowEndDate } from './backend-services/validate-service-event';

interface RemoveButtonProps {
  onClick: () => void;
}

const RemoveButton: FunctionComponent<RemoveButtonProps> = ({ onClick }) => {
  const intl = useIntl();

  const deleteClassNames = classNames('col-1');

  return (
    <div className={deleteClassNames}>
      <i
        className={classNames(styles.remove, 'fi fi-remove-bold')}
        onClick={onClick}
        aria-label={intl.formatMessage({ id: 'SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.REMOVE_SERVICE_EVENT_ROW' })}
      />
    </div>
  );
};

interface ServiceEventRowComponentProps {
  isRowLocked: boolean;
  formNamePrefix: string;
  voucher: ServiceEventRowVoucher;
  venueId: string;
  onRemove: () => void;
  removable: boolean;
  unavailableServices?: ServiceEventRowVoucher['descriptiveServices'];
  calculateMaxEndDate?: (
    startDate: Date | undefined,
    voucher: ServiceEventRowVoucher,
    producedService?: HousingServicesProducedService,
  ) => Date;
}

export type ServiceEventRowVoucher = Pick<
  HousingServicesVoucher,
  'unitCost' | 'priceDetails' | 'descriptiveServices' | 'lastAllowedDateOfService' | 'serviceArea'
>;

export const ServiceEventRowComponent: FunctionComponent<ServiceEventRowComponentProps> = ({
  isRowLocked,
  onRemove,
  formNamePrefix,
  voucher,
  venueId,
  removable,
  unavailableServices,
  calculateMaxEndDate = calculateLastAllowedServiceEventRowEndDate,
}) => {
  const intl = useIntl();
  const [field, , { setValue }] = useField<ServiceEventRow>(formNamePrefix);
  const period = field.value.period;
  const maxEndDate = calculateMaxEndDate(period.startDate, voucher, field.value.producedService);

  const producedServiceClassNames = classNames('col-12 col-md-4 col-lg-2');
  const periodClassNames = classNames('col-12 col-md-8 col-lg-4');
  const referenceClassNames = classNames('col-11 col-md-5 col-lg-2');
  const amountClassNames = classNames('col-12 col-md-6 col-lg-3');

  useEffect(() => {
    const endDate = computeRowEndDate(voucher.serviceArea, field.value.producedService, period);
    const doUpdateEndDate = !!endDate && endDate.getTime() !== field.value.period.endDate?.getTime();
    if (doUpdateEndDate) {
      setValue({ ...field.value, period: { ...field.value.period, endDate } });
    }
  }, [field.value.producedService, field.value, setValue, period, voucher.serviceArea]);

  return (
    <div className={styles.wrapper} role="listitem">
      <div className={classNames('row', styles.row)}>
        <div className={producedServiceClassNames}>
          <FormikAdapterField
            disabled={isRowLocked}
            className={isRowLocked ? 'disabled-light' : ''}
            component={ConnectedSelectComponent}
            name={`${formNamePrefix}.producedService`}
            label="TRANSACTIONS.PRODUCED_SERVICES"
            options={voucher.descriptiveServices}
            disabledOptions={unavailableServices}
            // TODO: There is an issue with the current version (1.3.0) of react-select dependency.
            //  React-select does not set id attribute of input element unless the component is also searchable.
            //  Once the issue is fixed (for instance by upgrading react-select to a fixed version)
            //  this excess aria-label should be dropped
            aria-label={intl.formatMessage({ id: 'TRANSACTIONS.PRODUCED_SERVICES' })}
          />
        </div>

        <div className={periodClassNames}>
          <PeriodComponent
            name={`${formNamePrefix}.period`}
            endDateDisabled={isServiceEndDateFixed(voucher.serviceArea, field.value.producedService) || isRowLocked}
            maxEndDate={maxEndDate}
          />
        </div>

        <div className={amountClassNames}>
          <Amount name={formNamePrefix} voucher={voucher} venueId={venueId} />
        </div>

        <div className={referenceClassNames}>
          <FormikAdapterField
            type="text"
            name={`${formNamePrefix}.transactionReference`}
            label="TRANSACTIONS.REFERENCE"
            component={ConnectedInputComponent}
          />
        </div>

        {removable && <RemoveButton onClick={onRemove} />}
      </div>
    </div>
  );
};
