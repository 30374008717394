// @flow
import moment from 'moment';
import isNil from 'lodash/isNil';

/*:: import type { Coupon } from 'app/coupons/details/coupons-details.types';*/
/*:: import type { Transfer } from 'app/coupons/details/transfers/coupons-details-transfers.component.types';*/
/*:: import type { serviceValidatorConfig, Validator } from './service-produced.validator.types';*/

const isBetween = (date /*: moment*/, range /*: moment[]*/) /*: boolean*/ => {
  return date.isBetween(range[0], range[1], 'day', '[]');
};

const serviceDateValidator = (
  coupon /*: Coupon*/,
  serviceProducedStart /*: moment*/,
  serviceProducedEnd /*: moment*/,
  fixExpiryDate /*: boolean*/ = false,
) /*: boolean*/ => {
  return !coupon.transfers.some((transfer /*: Transfer*/) => {
    const transferStart /*: moment*/ = moment(transfer.starts);
    const transferExpire /*: moment*/ = moment(transfer.expires);

    // fixExpiryDate give information if transferExpire already have 30 days added (by core) or not.
    // If core already added 30 days fixExpiryDate should be true and we need to subtract 30 days to calculate end date correctness.
    // If expire date don't have 30 days added we need to add 30 days when calculating todayIsCorrect,
    // because debit can be done 30 days after original expires date.
    const transferExpireFixed /*: moment*/ = moment(transfer.expires).add(fixExpiryDate ? -30 : 30, 'days');

    const startDateIsCorrect = isBetween(serviceProducedStart, [transferStart, transferExpire]);
    const todayIsCorrect = isBetween(moment(), [transferStart, fixExpiryDate ? transferExpire : transferExpireFixed]);

    const endDateIsCorrect = isBetween(serviceProducedEnd, [
      transferStart,
      fixExpiryDate ? transferExpireFixed : transferExpire,
    ]);

    return startDateIsCorrect && endDateIsCorrect && todayIsCorrect;
  });
};

const isAfterToday = (date /*: moment*/) /*: boolean*/ => date.isAfter();

export const serviceProducedValidator =
  ({ coupon, fixExpiryDate = false } /*: serviceValidatorConfig*/) /*: Validator*/ =>
  value => {
    if (isNil(value)) {
      return 'VALIDATION.REQUIRED';
    }

    const serviceProducedStart = moment(value.startDate);
    const serviceProducedEnd = moment(value.endDate);

    if (serviceDateValidator(coupon, serviceProducedStart, serviceProducedEnd, fixExpiryDate)) {
      return 'VALIDATION.INVALID_SERVICE_DATE';
    }

    if (isAfterToday(serviceProducedStart) || isAfterToday(serviceProducedEnd)) {
      return 'VALIDATION.PAST_OR_TODAY_DATE_REQUIRED';
    }

    return undefined;
  };
