// @flow

/*:: import type { FieldArrayProps } from 'redux-form/es/index';*/

import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

/*:: type Props = FieldArrayProps;*/

function difference(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function shouldFieldArrayUpdate(nextProps /*: Props*/) {
  const nextFieldsArray = nextProps.fields.getAll();

  return (
    //$FlowFixMe
    !isEqual(nextProps.locale, this.props.locale) ||
    !isEqual(nextProps.meta, this.props.meta) ||
    nextProps.fields.length !== this.props.fields.length ||
    (isArray(nextFieldsArray) && nextFieldsArray.some((el, index) => difference(el, this.props.fields.get(index))))
  );
}
