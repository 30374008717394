// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './table.component.types';*/
import styles from './table.module.scss';

export class TableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    const table = (
      <table className={classNames('table', this.props.className, styles.table)}>{this.props.children}</table>
    );

    return this.props.scrollable === true ? <div className={styles.scrollable}>{table}</div> : table;
  }
}
