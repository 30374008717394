// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { VatArchiveDetailsContainer } from './details/vat-archive-details.container';
import { VatArchiveListContainer } from './list/vat-archive-list.container';

/*:: import type { Props } from './vat-archive.component.types';*/

export class VatArchiveComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/:accountId/:year/:month`} component={VatArchiveDetailsContainer} />
        <Route path={this.props.match.url} component={VatArchiveListContainer} exact />
      </Switch>
    );
  }
}
