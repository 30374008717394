// @flow

import React, { Component } from 'react';

import { ModalComponent, PlaceholderComponent } from 'app/shared';

/*:: import type { Props } from './agreements-details.placeholder.types';*/

export class AgreementsDetailsPlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <ModalComponent
        onClose={this.props.onClose}
        title={<PlaceholderComponent color="primary" width={200} height={28} />}
        submit
      >
        <PlaceholderComponent />
        <PlaceholderComponent width={300} />
      </ModalComponent>
    );
  }
}
