// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { FieldArray } from 'redux-form';

import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent } from 'app/shared';
import { VenueContractsPlaceholder } from './placeholder/venue-contracts.placeholder';
import { ContractsContainer } from './contracts/contracts.container';
import { PricelistsFiltersComponent } from './filters/pricelists-filters.component';
import { useServiceTypeGroups } from 'app/pricelists/approve/useServiceTypeGroups.hook';

/*:: import type { Props } from './venue-contracts.component.types';*/

export const VenueContractsComponent = (props /*: Props*/) => {
  const { isLoading, name, contracts, fetchVenueContracts, filterVenueContracts, prevLocation, refreshVenueContracts } =
    props;
  const [filters, setFilters] = useState({});

  const { serviceTypeGroups } = useServiceTypeGroups({
    context: 'organizer',
    contracts,
    onFiltersChanged: useCallback(
      ({ serviceTypeGroupId }) => {
        setFilters({
          serviceTypeGroupId: filters.serviceTypeGroupId || serviceTypeGroupId,
        });
      },

      [filters.serviceTypeGroupId],
    ),
  });

  useEffect(() => {
    if (isLoading) {
      setFilters({});
    }
  }, [isLoading]);

  useEffect(
    () => {
      fetchVenueContracts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    filterVenueContracts(filters.serviceTypeGroupId);
  }, [filters, filterVenueContracts]);

  if (isLoading) {
    return <VenueContractsPlaceholder prevLocation={prevLocation} />;
  }

  return (
    <>
      <PageHeaderComponent
        title={{ id: 'PRICELISTS.DETAILS.TITLE', values: { venue: name } }}
        isInitialRoute={props.isInitialRoute}
        backLink={props.prevLocation}
      />
      {serviceTypeGroups.length ? (
        <>
          <PricelistsFiltersComponent
            onFiltersChanged={setFilters}
            filters={filters}
            serviceTypeGroups={serviceTypeGroups}
            context="organizer"
          />
          <SeparatorComponent />
        </>
      ) : null}
      {/* 
        not sure why `rerenderOnEveryChange` is required, but without it redux-form
        gets confused about which contracts to display. The prop should be safe to use
        https://redux-form.com/7.1.1/docs/api/fieldarray.md/#-code-rerenderoneverychange-boolean-code-optional- 
      */}
      <FieldArray
        name="displayedContracts"
        component={ContractsContainer}
        rerenderOnEveryChange={true}
        props={{
          formName: 'pricelistDetailsContainer',
          fieldName: 'displayedContracts',
          onContractRefreshRequested: refreshVenueContracts,
          ...props,
        }}
      />
    </>
  );
};
