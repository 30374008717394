import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { changeBusiness } from 'app/redux/users/users.actions';
import { unlockBusinessChange, updateConfirmation } from 'app/redux/view/view.actions';

import { SidebarUserComponent } from './sidebar-user.component';

const mapStateToProps = ({
  users: {
    business,
    current: {
      businesses,
      personal: { firstName, lastName },
    },
    isApproved,
  },
  view: { businessChangeLocked },
}) => ({
  businessChangeLocked,
  businesses,
  firstName,
  isApproved,
  lastName,
  business,
});

const mapDispatchToProps = dispatch => ({
  onBusinessChange: (business, isLocked, callback) => {
    const onBusinessChange = () => {
      dispatch(changeBusiness(business));
      dispatch(unlockBusinessChange());
      callback();
    };

    if (isLocked) {
      dispatch(
        updateConfirmation({
          onConfirm: onBusinessChange,
          title: 'CORE.PROMPT',
        }),
      );
    } else {
      onBusinessChange();
    }
  },
});

export const SidebarUserContainer = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SidebarUserComponent);
