// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'connected-react-router';

import { withAuthorization } from 'app/common';

import { editUserRoles } from 'app/redux/users/users.actions';

import { UsersEditRolesComponent } from './users-edit-roles.component';

const mapStateToProps = ({ dictionaries, users: { entities: users } }, { context, customer, userId }) => {
  const user = users[customer].list.find(x => x.id === userId);
  return {
    initialValues: {
      roles: user ? user.roles : [],
    },
    roles: dictionaries[`user-roles/${context}`].map(role => ({
      id: role.name,
      text: `ROLES.${role.name}`,
      editable: role.editable,
    })),
    userDisplayName: user ? `${user.firstName} ${user.lastName}` : '',
  };
};

const mapDispatchToProps = (dispatch, { context, customer, pathname, userId, reloadUsers }) => ({
  onClose: () => dispatch(push(pathname)),
  onSubmit: (roles, selected) =>
    dispatch(
      editUserRoles(
        customer,
        context,
        userId,
        {
          roles: roles
            .filter(role => role.editable)
            .map(role => ({
              active: selected.includes(role.id),
              role: role.id,
            })),
        },
        selected,
      ),
    ).then(() => reloadUsers()),
  onSubmitSuccess: name => {
    dispatch(
      push({
        pathname,
        state: {
          success: {
            type: 'EDIT_USER_ROLES',
            payload: name,
          },
        },
      }),
    );
  },
});

export const UsersEditRolesContainer = compose(
  withAuthorization([({ context }) => `${context}Users`, 'update']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'edit-user-roles' }),
)(UsersEditRolesComponent);
