// @flow
import { FORM_ERROR } from 'final-form';

function handleNonValidationError(failureCallback) {
  const GENERAL_REACT_FINAL_FORM_ERROR = {
    [FORM_ERROR]: 'FORM.ERROR.GENERAL',
  };
  failureCallback && failureCallback();
  return GENERAL_REACT_FINAL_FORM_ERROR;
}

function handleSuccess(result, successCallback) {
  successCallback && successCallback(result);
  return undefined;
}

function handleValidationError(submissionError, failureCallback) {
  failureCallback && failureCallback();
  return submissionError.errors;
}

/**
 * withFormSubmissionErrorHandling can be used to handle failures in react final form submissions.
 * This function is compatible with onSubmit property of final form Form component.
 * Due to this this helper can be used directly as the callback of react final forms onSubmit.
 * For more information see: https://final-form.org/docs/react-final-form/types/FormProps#onsubmit
 * @param {*} action Form-submitting action
 * @param {*} successCallback Optional callback function to be executed in case of successful submission
 * @param {*} failureCallback Optional callback function to be executed in case of failed submission
 * @returns Promise<?Object> Resolves with undefined in case of success.
 * Resolves with an object with submission errors on failure.
 * Should never reject.
 */
// TODO: Handle validation errors here rather than assuming existence of validation.middleware.js
//       and relying on the exceptions thrown by it.
export const withFormSubmissionErrorHandling = async (
  action /*: Promise<{ error?: Boolean }>*/,
  successCallback /*::?: Function*/,
  failureCallback /*::?: Function*/,
) => {
  try {
    const result = await action;
    if (!result || result.error) {
      return handleNonValidationError(failureCallback);
    } else {
      return handleSuccess(result, successCallback);
    }
  } catch (submissionError) {
    return handleValidationError(submissionError, failureCallback);
  }
};
