import { FunctionComponent } from 'react';
import { Typography } from 'app/shared';
import { CardContentRowComponent } from 'app/shared/card-new/content-row/card-content-row.component';
import { dateFormatter } from 'app/utils';
import { ContractStatus } from 'app/constants';
import { Contract } from 'app/types/contract';

export interface ValidityPeriodComponentProps {
  contract: Pick<Contract, 'validFrom' | 'validTo' | 'status'>;
}

const ApprovedContractPeriod: FunctionComponent<{ validFrom: string; validTo?: string }> = ({ validFrom, validTo }) => {
  return (
    <CardContentRowComponent title="PRICELIST.VALIDITY_PERIOD">
      <Typography type="bodyText" color="grey">
        {dateFormatter(validFrom)} – {validTo ? dateFormatter(validTo) : ''}
      </Typography>
    </CardContentRowComponent>
  );
};

export const ValidityPeriodComponent: FunctionComponent<ValidityPeriodComponentProps> = ({ contract }) => {
  const { status, validFrom, validTo } = contract;
  switch (status) {
    case ContractStatus.Approved:
      return <ApprovedContractPeriod validFrom={validFrom} validTo={validTo} />;
    case ContractStatus.New:
      return <></>;
    default:
      return null;
  }
};
