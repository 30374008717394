// @flow
import React, { createContext } from 'react';
/*:: import type { ComponentType } from 'react';*/

/*:: type CoreContextType = {
  holidays: any[],
  sidebarCollapsed: boolean,
  toggleSidebar: Function,
};*/

export const CoreContext = createContext/*:: <CoreContextType> */(
  {
    holidays: [],
    sidebarCollapsed: false,
    toggleSidebar: () => {},
  },
);

export const withCoreContext = (WrappedComponent /*: ComponentType<any>*/) => (props /*: any*/) =>
  <CoreContext.Consumer>{context => <WrappedComponent {...props} {...context} />}</CoreContext.Consumer>;
