// @flow

import React from 'react';

import { ConnectControl, RadiosComponent } from 'app/shared';

/*:: import type { Props } from './connected-radios.component.types';*/

export const ConnectedRadiosComponent = (props /*: Props*/) => {
  const isChecked = option => (option.optionValue || option.id) === props.input.value;
  const onChange = option => props.input.onChange(option.optionValue || option.id);

  return (
    <ConnectControl {...props} component={RadiosComponent} itemProps={{ ...props.itemProps, isChecked, onChange }} />
  );
};
