// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AgreementsDetailsComponent } from 'app/agreements/details/agreements-details.component';
import { CheckboxComponent } from 'app/shared';

/*:: import type { Props, State } from './agreements-acceptance.component.types';*/
import styles from './agreements-acceptance.module.scss';

export class AgreementsAcceptanceComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isOpened: false,
    };
  }

  onChange = (isOpened /*: boolean*/) => this.setState({ isOpened });

  render() {
    const { loading, terms } = this.props;

    return (
      <>
        <div className={styles.terms}>
          <CheckboxComponent
            onChange={this.props.input.onChange}
            checked={this.props.input.value}
            className={styles.checkbox}
          />
          <FormattedMessage
            id="CORE.TERMS_AND_CONDITIONS"
            values={{
              link: (
                <span onClick={() => this.onChange(true)} className={styles.link}>
                  <FormattedMessage id="CORE.CONTRACT_TERMS" />
                </span>
              ),
            }}
          />
        </div>

        {this.state.isOpened && (
          <AgreementsDetailsComponent
            onClose={() => this.onChange(false)}
            fetchTerms={this.props.fetchTerms}
            loading={(loading /*: any*/)}
            terms={terms}
            approved
          />
        )}
      </>
    );
  }
}
