// @flow

import { FORM_ERROR } from 'final-form';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';

import { amountValidator, serviceProducedValidator } from '../../paymentsValidation';
import { numberOfPriceUnitsValidator } from '../../paymentsValidation/numberOfPriceUnits.validator';

/*:: import type { FormPayment, PaymentsFormValues } from '../form/coupons-mass-charge-form.component.types';*/
/*:: import type { Coupon } from 'app/coupons/details/coupons-details.types';*/

const isActive = (payment /*: FormPayment*/) /*: boolean*/ => payment.active && payment.visible;

const validatePayment = ({ payment, coupon }) => {
  if (!isActive(payment)) {
    return undefined;
  }

  const amountError = amountValidator(payment.amount, {
    coupon,
    serviceProduced: payment.serviceProduced,
    fixExpiryDate: true,
  });

  const numberOfPriceUnitsError = coupon.priceUnit
    ? numberOfPriceUnitsValidator(payment.numberOfPriceUnits)
    : undefined;

  const serviceProducedError = serviceProducedValidator({ coupon, fixExpiryDate: true })(payment.serviceProduced);

  const errors = pickBy(
    {
      amount: amountError,
      numberOfPriceUnits: numberOfPriceUnitsError,
      serviceProduced: serviceProducedError,
    },
    identity,
  );

  return isEmpty(errors) ? undefined : errors;
};

export const getPaymentsErrors = (coupons /*: Coupon[]*/, payments /*: FormPayment[]*/) =>
  payments.reduce((errors, payment, index) => {
    const paymentError = validatePayment({
      payment,
      coupon: coupons[index],
    });

    if (paymentError === undefined) {
      return errors;
    }

    return {
      ...errors,
      [index]: paymentError,
    };
  }, {});

export const validateMassDebitForm =
  (coupons /*: Coupon[]*/) =>
  ({ payments } /*: PaymentsFormValues*/) => {
    // Dropping reference due to bug VAEX-1138
    // This will be refactored in VAEX-1139

    const paymentsToSubmit = payments.filter(isActive);
    if (paymentsToSubmit.length > 200) {
      return {
        [FORM_ERROR]: 'COUPONS.MASS_CHARGE.LIMITATION',
      };
    }

    if (coupons.length !== payments.length) {
      return {
        [FORM_ERROR]: 'VALIDATION.FORM_ERROR',
      };
    }

    const errors = getPaymentsErrors(coupons, payments);

    return isEmpty(errors)
      ? undefined
      : {
          payments: errors,
          [FORM_ERROR]: 'VALIDATION.FORM_ERROR',
        };
  };
