// @flow
import React from 'react';
import ReactSelect, { Creatable } from 'react-select';
import classNames from 'classnames';
import isObject from 'lodash/isObject';

import { translate } from 'app/utils';
import { InputBaseControl } from 'app/shared';
import { useIntl } from 'app/hooks';

/*:: import type { Option, SelectComponentProps, SelectProps } from './select.component.types';*/
import 'react-select/dist/react-select.css';
import styles from './select.module.scss';

// TODO: There's an issue with the current version of react-select (1.3.0).
//  Id of the input element is not set unless the select component is also searchable.
//  This breaks linkage between label element and input element and reduces accessibility.
//  This could be fixed for instance by upgrading react-select to a newer version.
//  See this related issue report: https://github.com/JedWatson/react-select/issues/959
export const Select = (props /*: SelectProps*/) => {
  const intl = useIntl();

  const {
    clearOptionId,
    creatable,
    disabled,
    labelKey,
    options,
    disabledOptions = [],
    placeholder,
    theme,
    valueKey,
    withClearOption,
    ...otherProps
  } = props;

  const toGroupTitleOrItem = (option /*: Option*/) => {
    if (isGroupTitle(option, labelKey)) {
      return {
        // $FlowFixMe
        label: translate({ intl, translation: option[labelKey] }),
        value: option[valueKey],
        disabled: option.disabled ? true : undefined,
        status: option.status,
      };
    } else {
      // isItem
      const disabled = disabledOptions.includes(option);
      return {
        // $FlowFixMe
        label: translate({ intl, translation: option }),
        value: option,
        disabled,
        className: disabled ? styles['disabled-option'] : undefined,
      };
    }
  };

  const isGroupTitle = (option /*: Option*/, label /*: ?string*/) /*: boolean*/ =>
    isObject(option) && labelKey !== undefined;

  const isOsx = navigator && navigator.platform && /Mac/.test(navigator.platform);
  const selectClassName = classNames(styles.select, 'from-control', `theme-${theme}`, {
    [styles.loading]: !options,
    [styles.osx]: isOsx,
  });

  const clearOption = withClearOption && {
    label: translate({ intl, translation: clearOptionId }),
    value: '',
  };

  const optionsList = options ? [clearOption, ...options.map(toGroupTitleOrItem)].filter(opt => opt) : [];

  const Component = creatable ? Creatable : ReactSelect;

  const selectProps = {
    ...otherProps,
    options: optionsList,
    className: selectClassName,
    // $FlowFixMe
    placeholder: translate({ intl, translation: placeholder }) + '...',
    searchable: !!otherProps.multi || !!otherProps.searchable,
    disabled: disabled || !options,
  };

  return <Component {...selectProps} />;
};

const defaultProps = {
  clearable: false,
  clearOptionId: 'CORE.ALL',
  labelKey: 'name',
  placeholder: 'CORE.SELECT',
  theme: 'secondary',
  valueKey: 'id',
  autoBlur: true,
};

Select.defaultProps = defaultProps;

export const SelectComponent = (props /*: SelectComponentProps*/) => {
  const variant = props.multi ? { variant: 'auto' } : {};

  return <InputBaseControl component={Select} {...props} {...variant} />;
};

SelectComponent.defaultProps = defaultProps;
