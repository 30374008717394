import { Action, createBrowserHistory, History, Location } from 'history';
import { useHistory } from 'react-router-dom';

// from https://mafalda-lome.medium.com/how-to-prevent-historys-back-button-from-leaving-your-app-s-domain-ed3d670c8580
// this exposes a version of browser history that doesnt allow you to go back to the previous domain
// it is useful for eg. detecting whether the current page is the initial page where user entered into the app
class LocalHistory implements History {
  private readonly history;
  private localLength = 0;

  constructor(history: History) {
    this.history = history;
  }
  public get action(): Action {
    return this.history.action;
  }
  public get location(): Location {
    return this.history.location;
  }
  public readonly createHref = (...args: Parameters<History['createHref']>) => {
    return this.history.createHref(...args);
  };
  public readonly push = (...args: Parameters<History['push']>) => {
    this.localLength++;
    return this.history.push(...args);
  };
  public readonly replace = (...args: Parameters<History['replace']>) => {
    return this.history.replace(...args);
  };
  public readonly go = (delta: number) => {
    this.localLength += delta;
    return this.history.go(delta);
  };
  public readonly goBack = () => {
    if (this.localLength > 0) {
      this.localLength--;
      return this.history.goBack();
    } else {
      this.push('/');
    }
  };
  public readonly goForward = () => {
    this.localLength++;
    return this.history.goForward();
  };
  public readonly listen = (...args: Parameters<History['listen']>) => {
    return this.history.listen(...args);
  };
  public get length() {
    return this.localLength;
  }
  public readonly block = (...args: Parameters<History['block']>) => {
    return this.history.block(...args);
  };
}

export const createLocalBrowserHistory = (): History => {
  return new LocalHistory(createBrowserHistory());
};

export const useIsInitialRoute = () => {
  const history = useHistory();
  if (history instanceof LocalHistory) {
    return history.length === 0;
  }

  // test environment uses <MemoryRouter> which doesnt use history,
  // in test environment always return false
  if (process.env.NODE_ENV === 'test' || process.env.STORYBOOK) {
    return false;
  }

  throw new Error('useIsInitialRoute supports only LocalHistory');
};
