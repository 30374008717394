//@flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { TypographyConditional } from 'app/shared';

/*:: import type { Props } from './card-header.component.types';*/
import styles from './card-header.module.scss';

export class CardHeaderComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    typographyProps: {},
  };

  typographyProps = {
    tag: 'h4',
    color: 'secondary',
    type: 'largeText',
  };

  render() {
    const { className, direction, justifyContent, alignItems, title, typographyProps } = this.props;

    const style = { flexDirection: direction, justifyContent, alignItems };

    const headerClassNames = classNames(styles.header, className);

    return (
      <div className={headerClassNames} style={style}>
        {title && (
          <TypographyConditional content={title} typographyProps={{ ...this.typographyProps, ...typographyProps }} />
        )}
        {this.props.children}
      </div>
    );
  }
}
