import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import _ from 'lodash';
import { ReactElement, useMemo } from 'react';
import { Property } from 'v2/types/postpay';
import { ActionButtonProps } from './action-button';
import styles from './tabular-item-list.module.scss';

function getHeadersFromItemsProperties<TItem>(
  items: TItem[],
  getItemProperties: (item: TItem) => Property[],
): { content: string }[] {
  // get all unique header labels from provided service events,
  // sorted by order of columns where they have apppeared
  // eg [['Label1', 'Label2', 'Label3'], ['Label1', 'Label4', 'Label3']] -> ['Label1', 'Label2', 'Label4', 'Label3']
  return _.sortBy(
    _.uniqBy(
      items.flatMap(item => {
        return getItemProperties(item).map(({ label }, index) => ({ label: label, index }));
      }),
      header => header.label,
    ),
    ({ index }) => index,
  ).map(({ label }) => ({ content: label }));
}

function findPropertyValue(properties: Property[], headerName: string, defaultValue: string) {
  return properties.find(property => property.label === headerName)?.value ?? defaultValue;
}

type TabularItemListComponentProps<TItem> = {
  items: TItem[];
  getItemProperties: (item: TItem) => Property[];
  renderActions?: (item: TItem) => ReactElement<ActionButtonProps>;
};

export function TabularItemListComponent<TItem>({
  items,
  getItemProperties,
  renderActions,
}: TabularItemListComponentProps<TItem>) {
  const headers = useMemo(() => getHeadersFromItemsProperties(items, getItemProperties), [items, getItemProperties]);
  return (
    <TableComponent className="table-striped">
      <TableHeadComponent headers={headers} />
      <TableBodyComponent>
        {items.map((item, index) => (
          <TableRowComponent key={index} className={styles.row}>
            {headers.map((header, index) => (
              <TableCellComponent
                key={index}
                content={findPropertyValue(getItemProperties(item), header.content, '')}
              />
            ))}
            <TableCellComponent key={200} className="text-right">
              {renderActions && renderActions(item)}
            </TableCellComponent>
          </TableRowComponent>
        ))}
      </TableBodyComponent>
    </TableComponent>
  );
}
