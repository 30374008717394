// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './orders-history-table.component.types';*/
import styles from '../orders-history.module.scss';

export const headers = [
  { content: 'ORDERS.HISTORY.ORDER_NUMBER' },
  { content: 'ORDERS.HISTORY.ORDER_DATE' },
  { content: 'ORDERS.HISTORY.ORDER_STATUS' },
  { content: 'ORDERS.HISTORY.ORDER_AMOUNT', numerical: true },
];

export class OrdersHistoryTableComponent extends Component /*:: <Props>*/ {
  render() {
    const { list } = this.props;

    return (
      <div className="table-responsive">
        <TableComponent className={classNames('table-striped', styles.table)}>
          <TableHeadComponent headers={headers} />
          <TableBodyComponent>
            {list.map(({ id, orderDate, status, totalAmount }, index) => (
              <TableRowComponent key={index}>
                <TableCellComponent>{id}</TableCellComponent>
                <TableCellComponent>{dateFormatter(orderDate)}</TableCellComponent>
                <TableCellComponent content={`ORDERS.HISTORY.STATUS.${status}`} />
                <TableCellComponent className={styles.amount} align="right" typographyProps={{ type: 'bodyTextBold' }}>
                  {currencyFormatter.format(totalAmount)}
                </TableCellComponent>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TableComponent>
      </div>
    );
  }
}
