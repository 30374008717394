import { PageHeaderComponent } from 'app/core';
import { ButtonComponent, SeparatorComponent, Typography } from 'app/shared';
import { LoadingComponent } from 'app/shared/loading/loading.component';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { crashApplicationUsingHistory } from 'app/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import styles from 'v2/service-voucher/postpay/issuing/create-service-voucher.page.module.scss';
import {
  IssueVoucherFormState,
  actions as serviceVoucherActions,
  ServiceVoucherIssuingStatus,
  ServiceVoucherStatus,
} from 'v2/service-voucher/postpay/service-vouchers-slice';
import { Embedded, getEmbedded, getLink, hasLink, Links } from 'v2/utils/links';
import {
  actions as voucherTypeActions,
  VoucherTypeLoaded,
  VoucherTypeResult,
  sliceName as voucherTypeSliceName,
  VoucherTypeStatus,
} from 'v2/voucher-type/postpay/voucher-type-slice';
import { isServiceVoucherLoaded } from '../utils/is-service-voucher-loaded';
import { DistributerInformationComponent } from './distributer-information.component';
import { ServiceVoucherFormComponent } from './forms/service-voucher-form.component';
import { FormData } from 'v2/types/postpay';
import { combineFormState, isFormSynchronizedWithServerState } from 'v2/utils/forms';
import { voucherIssuingStateSelector, voucherStateSelector } from '../service-voucher-selectors';
import { useV2Selector } from 'v2/utils/redux-utils';
import { CancelIssuingWarningModal } from './cancel-issuing-warning-modal';

type CreateServiceVoucherPageProps = RouteComponentProps<{ id: string }>;

export const CreateServiceVoucherPage: React.FC<CreateServiceVoucherPageProps> = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const voucherId = props.match.params.id;
  useEffect(() => {
    dispatch(serviceVoucherActions.voucherRequested({ voucherId }));
  }, [dispatch, voucherId]);

  const serviceVoucherResult = useV2Selector(
    state => voucherStateSelector(voucherId)(state) ?? ({ status: ServiceVoucherStatus.Loading } as const),
  );

  const voucherTypeId = useMemo(
    () => (isServiceVoucherLoaded(serviceVoucherResult) ? serviceVoucherResult.voucher.type : undefined),
    [serviceVoucherResult],
  );
  const voucherTypeResult = useV2Selector(state => {
    if (isServiceVoucherLoaded(serviceVoucherResult)) {
      return state[voucherTypeSliceName][serviceVoucherResult.voucher.type];
    }
  });
  useEffect(() => {
    if (voucherTypeId) {
      dispatch(voucherTypeActions.voucherTypeRequested({ voucherTypeId: voucherTypeId }));
    }
  }, [dispatch, voucherTypeId]);

  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const onBack = () => {
    setIsCancelModalOpened(true);
  };

  const voucherIssuingState = useV2Selector(state => {
    const issuingState = voucherIssuingStateSelector(voucherId)(state);
    return issuingState?.status === ServiceVoucherIssuingStatus.NotSubmitted ? issuingState : undefined;
  });
  const reduxFormState = voucherIssuingState?.formState ? combineFormState(voucherIssuingState.formState) : undefined;
  const isFormSynchronizedWithServer = voucherIssuingState?.formState
    ? isFormSynchronizedWithServerState(voucherIssuingState.formState)
    : true;

  if (!isServiceVoucherLoaded(serviceVoucherResult) || !isVoucherTypeLoaded(voucherTypeResult)) {
    return (
      <Wrapper onBack={onBack}>
        <LoadingComponent isLoading size="large" />
      </Wrapper>
    );
  }

  if (isServiceVoucherLoaded(serviceVoucherResult) && !hasLink(serviceVoucherResult.voucher, 'usecase:issue-voucher:action:update-voucher')) {
    return (
      <Wrapper onBack={onBack}>
        <Typography color="greyDark" id="VAANA_SYSTEM.ERRORS.VOUCHER_ALREADY_EXISTS" />
      </Wrapper>
    );
  }

  const formTemplates = getEmbedded(serviceVoucherResult.voucher, Embedded.PostpayServiceVoucher.IssueVoucherTemplate);
  if (!formTemplates) {
    crashApplicationUsingHistory(history);
    return null;
  }

  const voucherDetailsFormData = reduxFormState?.voucherDetailsFormData ?? formTemplates.voucherDetailsForm.data;
  const citizenDetailsFormData = reduxFormState?.citizenDetailsFormData ?? formTemplates.citizenDetailsForm.data;

  const onFormChanged = (form: keyof IssueVoucherFormState, formData: FormData) =>
    dispatch(
      serviceVoucherActions.draftVoucherFormUpdated({
        voucherId,
        formState: {
          citizenDetailsFormData,
          voucherDetailsFormData,
          [form]: formData,
        },
      }),
    );



  return (
    <Wrapper onBack={onBack}>
      <DistributerInformationComponent distributerInformation={voucherTypeResult.voucherType.distributerInformation} />

      <SeparatorComponent height={40} />

      <ServiceVoucherFormComponent
        title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CUSTOMER_DETAILS_FORM_TITLE"
        formTemplate={formTemplates.citizenDetailsForm}
        formData={citizenDetailsFormData}
        validationErrors={voucherIssuingState?.citizenDetailsFormValidationErrors?.fieldErrors ?? []}
        onChange={formData => onFormChanged('citizenDetailsFormData', formData)}
      />

      <SeparatorComponent height={40} />

      <ServiceVoucherFormComponent
        title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.VOUCHER_DETAILS_FORM_TITLE"
        formTemplate={formTemplates.voucherDetailsForm}
        formData={voucherDetailsFormData}
        validationErrors={voucherIssuingState?.voucherDetailsFormValidationErrors?.fieldErrors ?? []}
        onChange={formData => onFormChanged('voucherDetailsFormData', formData)}
      />

      <SeparatorComponent height={40} />

      <div className={styles.actions}>
        <ButtonComponent theme="outline-primary" onClick={() => setIsCancelModalOpened(true)}>
          <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CANCEL" />
        </ButtonComponent>

        <ButtonComponent
          theme="primary"
          disabled={
            !isFormSynchronizedWithServer ||
            !getLink(serviceVoucherResult.voucher, Links.PostpayServiceVoucher.SubmitVoucher)
          }
          to={`/service-vouchers/${voucherId}/preview`}
        >
          <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.CONTINUE_TO_CONFIRM" />
        </ButtonComponent>
      </div>

      <CancelIssuingWarningModal
        isOpened={isCancelModalOpened}
        onCancel={() => setIsCancelModalOpened(false)}
        onConfirm={() => history.push('/service-vouchers/types')}
      />
    </Wrapper>
  );
};

const Wrapper: React.FC<{ children: React.ReactNode; onBack: () => void }> = ({ children, onBack }) => {
  return (
    <>
      <PageHeaderComponent
        title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.PAGE_TITLE"
        onBack={onBack}
        separatorHeight={40}
      />
      {children}
    </>
  );
};

function isVoucherTypeLoaded(voucherTypeResult: VoucherTypeResult | undefined): voucherTypeResult is VoucherTypeLoaded {
  return !!voucherTypeResult && voucherTypeResult.status === VoucherTypeStatus.Ok;
}
