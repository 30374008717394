import { TableBodyComponent, TableCellComponent, TableComponent, TableRowComponent } from 'app/shared/table-new';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { ActionButtonProps } from './action-button';
import styles from './item-list.module.scss';

type ItemListComponentProps<TItem> = {
  items: TItem[];
  renderItem: (item: TItem) => React.ReactElement;
  renderActions?: (item: TItem) => ReactElement<ActionButtonProps>;
  alignActionButtons?: 'top' | 'center' | 'bottom';

  // selectable?: boolean
  // onSelectToggle: (item: TItem) => void;
  // selectedItems: TItem[];
};

export function ItemListComponent<TItem>({
  items,
  renderItem,
  renderActions,
  alignActionButtons,
}: ItemListComponentProps<TItem>) {
  return (
    <TableComponent className="table-striped">
      <TableBodyComponent>
        {items.map((item, index) => (
          <TableRowComponent key={index} className={styles.row}>
            {/* make a cell for selection checkbox if items should be selectable */}
            <TableCellComponent>{renderItem(item)}</TableCellComponent>
            {renderActions && (
              <TableCellComponent key={200} className={classNames('text-right', styles[`align-${alignActionButtons}`])}>
                {renderActions(item)}
              </TableCellComponent>
            )}
          </TableRowComponent>
        ))}
      </TableBodyComponent>
    </TableComponent>
  );
}
