import { dateFormatter, timeFormatter } from 'app/utils';
import { FunctionComponent, useMemo } from 'react';
import _ from 'lodash';
import { TranslationKey } from 'v2/types/translations';
import { isNotNil } from 'v2/utils/is-nil';
import { Typography } from 'app/shared';
import { AccordionComponent } from 'app/shared/accordion/accordion.component';
import { CardContentRowComponent } from 'app/shared/card-new/content-row/card-content-row.component';
import { ContractStatusComponent } from 'app/shared/price-list/contract-status.component';
import { ValidityPeriodComponent } from 'app/venues/municipalities/list/municipality/municipality-contract/validity-period.component';
import moment from 'moment';
import { useIntl } from 'app/hooks/useIntl/useIntl';
import { Contract } from 'app/types/contract';

export interface ContractCardDetailsComponentProps {
  context: 'organizer' | 'provider';
  contract: Contract;
}

const FormattedDateTimeRow = ({ title, date }: { title: TranslationKey; date: Date }) => (
  <CardContentRowComponent title={title}>
    <Typography
      type="bodyText"
      color="greyDark"
      id="CORE.FORMATTED_DATE_AT_TIME"
      values={{ date: dateFormatter(date), time: timeFormatter(date) }}
    />
  </CardContentRowComponent>
);

const Counter = ({ date }: { date: Date }) => {
  const daysUntilDate = moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  const intl = useIntl();
  return (
    <Typography tag="span" color={daysUntilDate <= 2 ? 'primary' : 'gray'}>
      (
      {daysUntilDate < 0
        ? intl.formatMessage({ id: 'PRICELIST.CHANGES_CANNOT_BE_MADE_ANYMORE' })
        : intl.formatMessage({ id: 'CORE.DAYS_LEFT' }, { days: daysUntilDate })}
      )
    </Typography>
  );
};

const FormattedDateRow = ({
  title,
  date,
  counter = false,
}: {
  title: TranslationKey;
  date: Date;
  counter?: boolean;
}) => (
  <CardContentRowComponent title={title}>
    <Typography type="bodyText" color="greyDark">
      {dateFormatter(date)}
      {counter && (
        <>
          &nbsp; <Counter date={date} />
        </>
      )}
    </Typography>
  </CardContentRowComponent>
);

type DateContentRow = { date: Date; title: TranslationKey; format: 'date' | 'date-time' | 'date-counter' };

const contractMessageMaxCollapsedLength = 100;

export const ContractCardDetailsComponent: FunctionComponent<ContractCardDetailsComponentProps> = props => {
  const { context, contract } = props;
  const dateRowsToDisplay = useMemo(() => {
    const makeRow = (
      row: DateContentRow | (Omit<DateContentRow, 'date'> & { date: undefined }),
    ): DateContentRow | null => (row.date ? row : null);

    const rows = [
      makeRow({ date: contract.changesRequestedAt, title: 'PRICELIST.CHANGES_REQUESTED_AT', format: 'date-time' }),
      makeRow({
        date: contract.changeRequestDeadlineAt,
        title: 'PRICELIST.CHANGE_REQUEST_DEADLINE_AT',
        format: context === 'organizer' ? 'date' : 'date-counter',
      }),
      makeRow({
        date: contract.lastEditedByServiceProviderAt,
        title: 'PRICELIST.LAST_EDITED_BY_SERVICE_PROVIDER_AT',
        format: 'date-time',
      }),
    ].filter(isNotNil);

    // order date rows in descending chronological order
    return _.orderBy(rows, row => row.date, 'desc');
  }, [context, contract.changesRequestedAt, contract.changeRequestDeadlineAt, contract.lastEditedByServiceProviderAt]);

  return (
    <>
      <CardContentRowComponent title="PRICELIST.STATUS">
        <Typography type="bodyText" color="greyDark">
          <ContractStatusComponent
            isProvider={props.context === 'provider'}
            contractStatus={contract.subStatus ? contract.subStatus : contract.status}
          />
        </Typography>
      </CardContentRowComponent>

      {props.context === 'provider' && <ValidityPeriodComponent contract={contract} />}

      {dateRowsToDisplay.map(row => {
        switch (row.format) {
          case 'date-time':
            return <FormattedDateTimeRow key={row.title} title={row.title} date={row.date} />;
          case 'date':
            return <FormattedDateRow key={row.title} title={row.title} date={row.date} />;
          case 'date-counter':
            return <FormattedDateRow key={row.title} title={row.title} date={row.date} counter />;
          default:
            return null;
        }
      })}

      {contract.serviceTypeGroup && (
        <CardContentRowComponent title="PRICELISTS.DETAILS.FILTERS.SERVICE_TYPE_GROUP">
          <Typography type="bodyText" color="greyDark">
            {contract.serviceTypeGroup.name}
          </Typography>
        </CardContentRowComponent>
      )}

      <CardContentRowComponent title="PRICELISTS.DETAILS.SERVICES">
        <Typography type="bodyText" color="greyDark">
          {contract.services.map(service => service.name).join(', ')}
        </Typography>
      </CardContentRowComponent>

      {contract.message && (
        <CardContentRowComponent title="PRICELIST.ORGANIZER_MESSAGE">
          {contract.message.length >= contractMessageMaxCollapsedLength ? (
            <AccordionComponent text={contract.message} maxCollapsedLength={contractMessageMaxCollapsedLength} />
          ) : (
            <Typography type="bodyText" color="greyDark">
              {contract.message}
            </Typography>
          )}
        </CardContentRowComponent>
      )}
    </>
  );
};
