import { ReportMessageExtra } from 'app/sentry/sentry';
import { AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { IssueVoucherFormState } from 'v2/service-voucher/postpay/service-vouchers-slice';
import { SubmittablePostpayServiceVoucher } from 'v2/service-voucher/postpay/utils';
import {
  UpdateVoucherResponse as BackendUpdateVoucherResponse,
  ErrorResponse,
  FormData,
  FormValidationErrorResponse,
  OrganizationalUnitUseCases,
  PostpayAccountId,
  PostpayServiceVoucher,
  PostpayVoucherType,
  PostpayVoucherTypeId,
  RequestValidationErrorResponse,
  ServiceEvent,
  VoucherStatus,
} from 'v2/types/postpay';
import { getLink, Links } from 'v2/utils/links';
import { AuthenticationDataProvider, getAuthenticationHeaders } from './auth';
import { ApiResponse, createBaseApiClient } from './base-api-client';

export type GetVoucherResponse = ApiResponse<{
  [StatusCodes.OK]: PostpayServiceVoucher;
  [StatusCodes.FORBIDDEN]: ErrorResponse;
  [StatusCodes.NOT_FOUND]: ErrorResponse;
}>;

export type GetVouchersResponse = ApiResponse<{
  [StatusCodes.OK]: PostpayServiceVoucher[];
  [StatusCodes.FORBIDDEN]: ErrorResponse;
}>;

export type CreateVoucherResponse = ApiResponse<{
  [StatusCodes.CREATED]: PostpayServiceVoucher;
  [StatusCodes.FORBIDDEN]: ErrorResponse;
  [StatusCodes.NOT_FOUND]: ErrorResponse;
  [StatusCodes.BAD_REQUEST]: RequestValidationErrorResponse;
}>;

export type GetVoucherServiceEventsResponse = ApiResponse<{
  [StatusCodes.OK]: ServiceEvent[];
}>;

export type InitializeServiceEventResponse = ApiResponse<{
  [StatusCodes.CREATED]: ServiceEvent;
}>;

export type SubmitServiceEventResponse = ApiResponse<{
  [StatusCodes.OK]: unknown;
  [StatusCodes.BAD_REQUEST]: FormValidationErrorResponse;
}>;

export type GetVoucherTypesResponse = ApiResponse<{
  [StatusCodes.OK]: PostpayVoucherType[];
  [StatusCodes.FORBIDDEN]: ErrorResponse;
}>;

export type GetVoucherTypeResponse = ApiResponse<{
  [StatusCodes.OK]: PostpayVoucherType;
  [StatusCodes.NOT_FOUND]: void;
}>;

// All statuses are allowed
export type GetAvailableUsecasesResponse = ApiResponse<
  {
    [StatusCodes.OK]: OrganizationalUnitUseCases;
  } & Record<Exclude<StatusCodes, StatusCodes.OK>, unknown>
>;

export type UpdateVoucherResponse = ApiResponse<{
  [StatusCodes.OK]: BackendUpdateVoucherResponse;
  [StatusCodes.FORBIDDEN]: ErrorResponse;
  [StatusCodes.NOT_FOUND]: ErrorResponse;
  [StatusCodes.BAD_REQUEST]: RequestValidationErrorResponse;
}>;

export type UpdateVoucherStatusResponse = ApiResponse<{
  [StatusCodes.OK]: PostpayServiceVoucher;
}>;

export type GetVoucherPdfResponse = ApiResponse<{
  [StatusCodes.OK]: Blob;

  // do we want to handle these?
  // BAD_REQUEST
  // FORBIDDEN
}>;

export type GetVoucherApprovedServiceProvidersPdfResponse = ApiResponse<{
  [StatusCodes.OK]: Blob;

  // do we want to handle these?
  // BAD_REQUEST
  // FORBIDDEN
}>;

export type LanguageDataProvider = {
  getApplicationLanguage: () => 'fi' | 'se' | 'en';
};

function getLanguageHeaders(languageProvider: LanguageDataProvider) {
  return {
    'Accept-Language': languageProvider.getApplicationLanguage(),
  };
}

export interface PostpayApiClient {
  getVoucher: (voucherId: string) => Promise<GetVoucherResponse>;
  getVouchers: (accountId: PostpayAccountId) => Promise<GetVouchersResponse>;
  createVoucher: (voucherTypeId: string) => Promise<CreateVoucherResponse>;
  getVoucherServiceEvents: (voucherId: string) => Promise<GetVoucherResponse>;
  initializeServiceEvent: (voucher: SubmittablePostpayServiceVoucher) => Promise<InitializeServiceEventResponse>;
  submitServiceEvent: (serviceEvent: ServiceEvent, data: FormData) => Promise<SubmitServiceEventResponse>;
  getVoucherTypes: () => Promise<GetVoucherTypesResponse>;
  getAvailableUsecases: (organizationalUnitId: string) => Promise<GetAvailableUsecasesResponse>;
  getVoucherType: (voucherTypeid: PostpayVoucherTypeId) => Promise<GetVoucherTypeResponse>;
  updateVoucher: (voucherId: string, formData: IssueVoucherFormState) => Promise<UpdateVoucherResponse>;
  updateVoucherStatus: (voucherId: string, newStatus: VoucherStatus) => Promise<UpdateVoucherStatusResponse>;
  getVoucherPdf: (voucher: PostpayServiceVoucher) => Promise<GetVoucherPdfResponse>;
  getVoucherApprovedServiceProvidersPdf: (
    voucher: PostpayServiceVoucher,
  ) => Promise<GetVoucherApprovedServiceProvidersPdfResponse>;
}

export function createPostpayApiClient(
  baseUrl: string,
  authenticationProvider: AuthenticationDataProvider,
  languageProvider: LanguageDataProvider,
  crashApplication: () => void,
  reportError: (message: string, extra: ReportMessageExtra) => void,
): PostpayApiClient {
  const { makeApiRequest } = createBaseApiClient(baseUrl, crashApplication, reportError);
  const getHeaders = () => ({
    ...getAuthenticationHeaders(authenticationProvider),
    ...getLanguageHeaders(languageProvider),
  });

  async function getVoucher(voucherId: string): Promise<GetVoucherResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVoucherResponse['status'], true> = {
        [StatusCodes.OK]: true,
        [StatusCodes.FORBIDDEN]: true,
        [StatusCodes.NOT_FOUND]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/vouchers/${voucherId}`,
      params: {
        requestingEntityId: getRequestingEntityId(authenticationProvider),
      },
      validateStatus,
      headers: getHeaders(),
    };

    return await makeApiRequest(requestConfig);
  }

  /* TODO: In the future this endpoint will
    - support filters (will filters be hardcoded or will they come from a form template from vaana-system?)
    - pagination
  
    In addition, there will possibly be different search filters etc for service providers and organizers.
    We could decide to have to different endpoints (/vouchers/account/?accountId=.. and /vouchers/venue/?venueId=...)
    or have just one endpoint (/vouchers/?organizationUnitId=...) which can receive different filters depending on requester
  */
  async function getVouchers(accountId: PostpayAccountId): Promise<GetVouchersResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVouchersResponse['status'], true> = {
        [StatusCodes.OK]: true,
        [StatusCodes.FORBIDDEN]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/vouchers`,
      params: {
        accountId,
      },
      validateStatus,
      headers: getHeaders(),
    };

    return await makeApiRequest(requestConfig);
  }

  async function createVoucher(voucherTypeId: string): Promise<CreateVoucherResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<CreateVoucherResponse['status'], true> = {
        [StatusCodes.CREATED]: true,
        [StatusCodes.FORBIDDEN]: true,
        [StatusCodes.NOT_FOUND]: true,
        [StatusCodes.BAD_REQUEST]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'post',
      url: `/vouchers`,
      validateStatus,
      headers: getHeaders(),
      data: { accountId: getRequestingEntityId(authenticationProvider), voucherTypeId },
    };

    return await makeApiRequest(requestConfig);
  }

  async function getVoucherServiceEvents(voucherId: string): Promise<GetVoucherResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVoucherServiceEventsResponse['status'], true> = {
        [StatusCodes.OK]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/vouchers/${voucherId}/service-events`,
      params: {
        requestingEntityId: getRequestingEntityId(authenticationProvider),
      },
      validateStatus,
      headers: getHeaders(),
    };

    return await makeApiRequest(requestConfig);
  }

  async function initializeServiceEvent(
    voucher: SubmittablePostpayServiceVoucher,
  ): Promise<InitializeServiceEventResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<InitializeServiceEventResponse['status'], true> = {
        [StatusCodes.CREATED]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const { href, method } = getLink(voucher, Links.PostpayServiceVoucher.InitializeServiceEvent);
    const requestConfig: AxiosRequestConfig = {
      method: method,
      url: href,
      validateStatus,
      headers: getHeaders(),
      data: { venueId: getRequestingEntityId(authenticationProvider) },
    };

    return await makeApiRequest(requestConfig);
  }

  async function submitServiceEvent(
    serviceEvent: ServiceEvent,
    serviceEventData: FormData,
  ): Promise<SubmitServiceEventResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<SubmitServiceEventResponse['status'], true> = {
        [StatusCodes.OK]: true,
        [StatusCodes.BAD_REQUEST]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const submitServiceEventLink = getLink(serviceEvent, Links.ServiceEvent.SubmitServiceEvent);
    if (!submitServiceEventLink) {
      crashApplication();
      reportError('Was trying to submit a service event without a submit service event usecase link', {});
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { href, method } = getLink(serviceEvent, Links.ServiceEvent.SubmitServiceEvent)!;
    const requestConfig: AxiosRequestConfig = {
      method,
      url: href,
      validateStatus,
      headers: getHeaders(),
      data: { data: serviceEventData, venueId: getRequestingEntityId(authenticationProvider) },
    };

    const response = (await makeApiRequest(requestConfig)) as SubmitServiceEventResponse;
    if (response.status === StatusCodes.BAD_REQUEST) {
      if (response.data.errorType !== 'form-validation-failed') {
        crashApplication();
      }
    }

    return response;
  }

  async function getAvailableUsecases(organizationalUnitId: string): Promise<GetAvailableUsecasesResponse> {
    function validateStatus(_status: StatusCodes): boolean {
      // allow all responses
      return true;
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/`,
      validateStatus,
      headers: getHeaders(),
      params: {
        organizationalUnitId, // note: passed explicitly unlike in some other api methods
      },
    };

    return await makeApiRequest(requestConfig, { crashApplicationOnError: false, reportErrorToSentry: false });
  }

  async function getVoucherTypes(): Promise<GetVoucherTypesResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVoucherTypesResponse['status'], true> = {
        [StatusCodes.OK]: true,
        [StatusCodes.FORBIDDEN]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const accountId = getRequestingEntityId(authenticationProvider);

    const requestConfig: AxiosRequestConfig = {
      method: 'get',
      url: `/voucher-types/${accountId}`,
      validateStatus,
      headers: getHeaders(),
    };

    return await makeApiRequest(requestConfig);
  }

  // TODO: update this method when we have a proper api endpoint for getting a single voucher type
  async function getVoucherType(voucherTypeId: PostpayVoucherTypeId): Promise<GetVoucherTypeResponse> {
    const result = await getVoucherTypes();
    if (result.status === StatusCodes.FORBIDDEN) {
      return {
        status: StatusCodes.NOT_FOUND,
      };
    }
    const voucherType = result.data.find(voucherType => voucherType.id === voucherTypeId);

    if (!voucherType) {
      return {
        status: StatusCodes.NOT_FOUND,
      };
    }

    return {
      status: StatusCodes.OK,
      data: voucherType,
    };
  }

  async function updateVoucher(voucherId: string, formState: IssueVoucherFormState): Promise<UpdateVoucherResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<UpdateVoucherResponse['status'], true> = {
        [StatusCodes.OK]: true,
        [StatusCodes.FORBIDDEN]: true,
        [StatusCodes.NOT_FOUND]: true,
        [StatusCodes.BAD_REQUEST]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'put',
      url: `/vouchers/${voucherId}`,
      validateStatus,
      headers: getHeaders(),
      data: {
        accountId: getRequestingEntityId(authenticationProvider),
        voucherDetailsFormData: formState.voucherDetailsFormData,
        citizenDetailsFormData: formState.citizenDetailsFormData,
      },
    };

    return await makeApiRequest(requestConfig);
  }

  async function updateVoucherStatus(
    voucherId: string,
    newStatus: VoucherStatus,
  ): Promise<UpdateVoucherStatusResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<UpdateVoucherStatusResponse['status'], true> = {
        [StatusCodes.OK]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'patch',
      url: `/vouchers/${voucherId}`,
      validateStatus,
      headers: getHeaders(),
      data: {
        accountId: getRequestingEntityId(authenticationProvider),
        status: newStatus,
      },
    };

    return await makeApiRequest(requestConfig);
  }

  async function getVoucherPdf(voucher: PostpayServiceVoucher): Promise<GetVoucherPdfResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVoucherPdfResponse['status'], true> = {
        [StatusCodes.OK]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const link = getLink(voucher, Links.PostpayServiceVoucher.GetVoucherPdf);
    if (!link) {
      crashApplication();
      reportError('Was trying to get voucher pdf on a voucher without proper link', {});
    }

    const requestConfig: AxiosRequestConfig = {
      method: link?.method,
      url: link?.href,
      validateStatus,
      headers: getHeaders(),
      data: {
        accountId: getRequestingEntityId(authenticationProvider),
      },

      // TODO: if we want to gracefully handle error cases, then we need to
      // convert blob to json for non-200 responses
      responseType: 'blob',
    };
    return await makeApiRequest(requestConfig);
  }

  async function getVoucherApprovedServiceProvidersPdf(
    voucher: PostpayServiceVoucher,
  ): Promise<GetVoucherApprovedServiceProvidersPdfResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<GetVoucherApprovedServiceProvidersPdfResponse['status'], true> = {
        [StatusCodes.OK]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const link = getLink(voucher, Links.PostpayServiceVoucher.GetVoucherApprovedServiceEventsPdf);
    if (!link) {
      crashApplication();
      reportError('Was trying to get voucher approved service events pdf on a voucher without proper link', {});
    }

    const requestConfig: AxiosRequestConfig = {
      method: link?.method,
      url: link?.href,
      validateStatus,
      headers: getHeaders(),
      data: {
        accountId: getRequestingEntityId(authenticationProvider),
      },

      // TODO: if we want to gracefully handle error cases, then we need to
      // convert blob to json for non-200 responses
      responseType: 'blob',
    };

    return await makeApiRequest(requestConfig);
  }

  return {
    getVoucher,
    getVouchers,
    createVoucher,
    getAvailableUsecases,
    getVoucherServiceEvents,
    initializeServiceEvent,
    submitServiceEvent,
    getVoucherTypes,
    getVoucherType,
    updateVoucher,
    updateVoucherStatus,
    getVoucherPdf,
    getVoucherApprovedServiceProvidersPdf,
  };
}

function getRequestingEntityId(authenticationProvider: AuthenticationDataProvider): string | undefined {
  return authenticationProvider.getCurrentBusiness()?.id.toString();
}
