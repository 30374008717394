// @flow

import React, { Component } from 'react';

import { ConnectControl, SelectComponent } from 'app/shared';

/*:: import type { Props } from './connected-select.component.types';*/

export class ConnectedSelectComponent extends Component /*:: <Props>*/ {
  onBlur = () => this.props.input.onBlur(this.props.input.value);

  onChange = (option /*: any*/) => {
    const value = option.hasOwnProperty('value') ? option.value : option;
    this.props.input.onChange(this.props.multi ? value.map(({ value }) => value) : value);

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(option);
    }
  };

  render() {
    return (
      <ConnectControl
        {...this.props}
        component={SelectComponent}
        autoBlur
        options={this.props.options}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.props.input.value}
        searchable={!!this.props.multi}
      />
    );
  }
}
