import moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT = 'HH:mm';

type DateInput = Date | string;

export const dateFormatter = (date: DateInput, includeTime?: boolean) => {
  if (!date) {
    throw new Error('Missing date value');
  }

  return includeTime ? moment(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`) : moment(date).format(DATE_FORMAT);
};

export const timeFormatter = (date: DateInput) => {
  if (!date) {
    throw new Error('Missing date value');
  }

  return moment(date).format(TIME_FORMAT);
};
