import { FunctionComponent, useEffect } from 'react';
import { CardComponent } from 'app/shared/card-new';
import { NoResultsContainer } from 'app/shared/no-results/no-results.container';
import {
  actions as serviceVoucherActions,
  ServiceVoucherResult,
  name as serviceVoucherSliceName,
  ServiceVoucherStatus,
} from 'v2/service-voucher/postpay/service-vouchers-slice';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { V2RootState } from '../../v2.reducer';
import { PageHeaderComponent } from '../../../app/core';
import { ServiceVoucherDetailsComponent } from './voucher-details/service-voucher-details.component';
import { canSubmitServiceEvent } from './utils';
import { ButtonComponent, SeparatorComponent } from 'app/shared';
import { ServiceEventSubmissionComponent } from 'v2/service-events/postpay/service-event-submission.component';
import { ServiceVoucherServiceEventListComponent } from './service-voucher-service-event-list.component';
import { getBusiness } from 'app/redux/users/users.selectors';
import { hasLink } from 'v2/utils/links';
import { isNotNil } from 'v2/utils/is-nil';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { LoadingComponent } from 'app/shared/loading/loading.component';

export type ServiceVoucherPageLocationState = { showBackButton?: boolean } | undefined;

const ServiceVoucherNotFoundComponent = () => (
  <CardComponent>
    <NoResultsContainer title="NOT_FOUND.TITLE" desc="SERVICE_VOUCHERS.ERROR.SERVICE_VOUCHER_NOT_FOUND" />
  </CardComponent>
);

export const ServiceVoucherPageComponent: FunctionComponent<RouteComponentProps<{ id: string }>> = props => {
  const dispatch = useDispatch();
  const voucherId = props.match.params.id;

  const voucherResult: ServiceVoucherResult = useSelector<V2RootState, ServiceVoucherResult>(
    state => state[serviceVoucherSliceName].vouchers[voucherId] ?? { status: ServiceVoucherStatus.Loading },
  );

  const history = useHistory<ServiceVoucherPageLocationState>();
  const showBackButton = history.location.state?.showBackButton ?? false;

  const { business } = useSelector(getBusiness);

  useEffect(() => {
    dispatch(serviceVoucherActions.voucherRequested({ voucherId }));
  }, [dispatch, voucherId, business.id]);

  const Content: JSX.Element = (() => {
    switch (voucherResult.status) {
      case ServiceVoucherStatus.Loading:
        return <LoadingComponent isLoading size="large" />;
      case ServiceVoucherStatus.NotFound:
        return <ServiceVoucherNotFoundComponent />;
      case ServiceVoucherStatus.Refreshing:
      case ServiceVoucherStatus.Ok:
        const { voucher } = voucherResult;
        const actionButtons = [
          hasLink(voucher, 'usecase:print-documents:action:get-voucher-document') ? (
            <ButtonComponent
              theme="outline-primary"
              onClick={() => history.push(`/service-vouchers/${voucherId}/print`)}
            >
              <FormattedMessage id="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_DETAILS.PRINT_DOCUMENTS" />
            </ButtonComponent>
          ) : undefined,
        ].filter(isNotNil);

        return (
          <>
            <ServiceVoucherDetailsComponent
              title="SERVICE_VOUCHERS.POSTPAY.SERVICE_VOUCHER_DETAILS.PAGE_TITLE"
              voucher={voucher}
              actionButtonsRight={actionButtons}
            />
            {canSubmitServiceEvent(voucher) && (
              <>
                <SeparatorComponent />
                <ServiceEventSubmissionComponent voucher={voucher} />
              </>
            )}

            {/* assuming that every user can view service events of the voucher */}
            <SeparatorComponent />
            <ServiceVoucherServiceEventListComponent voucher={voucher} />
          </>
        );
    }
  })();

  return (
    <>
      <PageHeaderComponent title="COUPONS.DETAILS.TITLE" onBack={showBackButton ? () => history.goBack() : undefined} />
      {Content}
    </>
  );
};
