// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

/*:: import type { Props } from './no-results.component.types';*/
import styles from './no-results.module.scss';

export class NoResultsComponent extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    searchable: true,
    showDesc: true,
    title: 'CORE.NO_RESULTS',
  };

  determineDesc = () => {
    if (!this.props.showDesc) {
      return null;
    }

    if (this.props.desc) {
      return this.props.desc;
    } else if (this.props.searchable) {
      return 'CORE.NO_RESULTS_SEARCHABLE_DESC';
    } else {
      return 'CORE.NO_RESULTS_DESC';
    }
  };

  render() {
    const desc = this.determineDesc();

    const containerClassNames = classNames(styles.container, {
      [styles.withBackground]: this.props.withBackground,
    });

    return (
      <div className={containerClassNames}>
        <div className={styles.icon}>
          <i className="fi fi-computer" />
          <i className="fi fi-search" />
        </div>
        <FormattedMessage id={this.props.title} tagName="h4" />
        {desc && <FormattedMessage id={desc} tagName="p" />}
      </div>
    );
  }
}
