import { PayloadAction } from '@reduxjs/toolkit';
import { reportMessage } from 'app/sentry/sentry';
import { call, takeEvery } from 'redux-saga/effects';

import { UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE } from '../venues/venues.action-types';

type ContractSubmissionFailureAction = PayloadAction<unknown> & { meta: { contract: unknown } };
export function* reportContractSubmissionFailure(action: ContractSubmissionFailureAction) {
  yield call(reportMessage, 'Contract pricelist submission failed', {
    error: action.payload,
    ...action.meta,
  });
}

export function* errorReportingSaga() {
  yield takeEvery([UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE], reportContractSubmissionFailure);
}
