import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ArrayRowComponent } from './array-row.component';

/*:: type Dictionary = [{ name: string, rate: number | string }];*/

/*:: export type VatRateOption = {
  id: string,
  name: string,
};*/

const createVarRatesOptions = (dictionary /*: Dictionary*/) /*: VatRateOption[]*/ =>
  dictionary.map(vatRate => ({
    id: vatRate.rate.toString(),
    name: `COUPONS.CHARGE.VAT_RATES.${vatRate.name}`,
  }));

const mapStateToProps = ({ dictionaries }) => ({
  vatRatesOptions: createVarRatesOptions(get(dictionaries, ['municipal/services', 'vatRates'], [])),
});

export const ArrayRowContainer = compose(withRouter, connect(mapStateToProps))(ArrayRowComponent);
