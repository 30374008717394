import { IntlProvider } from 'react-intl';
import { defaultErrorHandler as reactIntlDefaultErrorHandler } from 'react-intl/dist/utils';
import { reportMessage } from 'app/sentry/sentry';

export const TranslationProviderComponent: React.FunctionComponent<Pick<IntlProvider['props'], 'locale' | 'messages'>> =
  (props) => {
    return (
      <IntlProvider
        {...props}
        onError={(err) => {
          reactIntlDefaultErrorHandler(err);
          if (/Missing message: "[\w.]+" for locale: "\w+"/.test(err)) {
            reportMessage(err);
          }
        }}
      >
        {props.children}
      </IntlProvider>
    );
  };
