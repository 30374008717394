import { PageHeaderComponent } from 'app/core/page-header/page-header.component';
import { NoResultsContainer } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new/body/card-body.component';
import { CardComponent } from 'app/shared/card-new/card.component';
import { CardHeaderComponent } from 'app/shared/card-new/header/card-header.component';
import { LoadingComponent } from 'app/shared/loading/loading.component';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { isNotNil } from 'v2/utils/is-nil';
import { useV1Selector, useV2Selector } from 'v2/utils/redux-utils';
import { issuedVouchersSelector } from '../service-voucher-selectors';
import { IssuedServiceVouchersListComponent } from './issued-service-vouchers-list';
import { actions, IssuedServiceVouchersStatus } from './issued-service-vouchers-slice';
import styles from './issued-service-vouchers.page.module.scss';

type IssuedServiceVouchersPageProps = RouteComponentProps<Record<never, string>>;

export const IssuedServiceVouchersPage: React.FC<IssuedServiceVouchersPageProps> = () => {
  const dispatch = useDispatch();
  const accountId = useV1Selector(state => state.users.business?.id);

  useEffect(() => {
    if (isNotNil(accountId)) {
      dispatch(actions.issuedServiceVouchersRequested({ accountId: accountId.toString() }));
    }
  }, [accountId, dispatch]);

  const issuedVouchersState = useV2Selector(state =>
    isNotNil(accountId) ? issuedVouchersSelector(accountId.toString())(state) : undefined,
  ) ?? { status: IssuedServiceVouchersStatus.Loading };

  const Content: React.ReactNode = (() => {
    switch (issuedVouchersState.status) {
      case IssuedServiceVouchersStatus.Loading:
        return <LoadingComponent isLoading size="large" />;
      case IssuedServiceVouchersStatus.Ok:
        const { vouchers } = issuedVouchersState;
        if (vouchers.length === 0) {
          return (
            <NoResultsContainer
              title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.EMPTY_VOUCHER_TYPES_LIST.TITLE"
              desc="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.EMPTY_VOUCHER_TYPES_LIST.DESCRIPTION"
            />
          );
        } else {
          return <IssuedServiceVouchersListComponent vouchers={vouchers} />;
        }
    }
  })();

  return (
    <>
      <PageHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.ISSUED_SERVICE_VOUCHERS.PAGE_TITLE" separatorHeight={40} />
      <CardComponent>
        <CardHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.ISSUED_SERVICE_VOUCHERS.SEARCH_ISSUED_SERVICE_VOUCHERS_CARD_TITLE" />
        <CardBodyComponent className={styles.cardBody}>{Content}</CardBodyComponent>
      </CardComponent>
    </>
  );
};
