// @flow
// $FlowFixMe
import { useContext } from 'react';

import { ReduxContext } from 'app/context';

export const useDispatch = () => {
  const { dispatch } = useContext(ReduxContext);

  return dispatch;
};
