import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';

import { fetchCurrentCustomer } from 'app/redux/customers/customers.actions';

import { CustomerInformationComponent } from './customer-information.component';

const mapStateToProps = ({
  customers: {
    entities: { current: customer },
  },
  users: { business },
}) => ({
  business,
  customer,
  loading: !customer,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomer: () => dispatch(fetchCurrentCustomer()),
});

export const CustomerInformationContainer = compose(
  withAuthorization(['currentBusinessDetails', 'read']),
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerInformationComponent);
