// @flow

import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './table-row.component.types';*/
import styles from './table-row.module.scss';

export const TableRowComponent = (props /*: Props*/) => {
  const rowClassName = classNames(styles.row, props.className);

  return <tr className={rowClassName}>{props.children}</tr>;
};
