// @flow

import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { SelectionControlBase } from 'app/shared';
import { INPUT_PROPS_OMIT_KEYS } from 'app/constants';

/*:: import type { Props } from './checkbox.component.types';*/
import styles from './checkbox.module.scss';

export const Checkbox = (props /*: Props*/) => {
  const { className, ...otherProps } = props;

  const inputProps = {
    ...otherProps,
    className: classNames(styles.input, className),
    type: 'checkbox',
  };

  return (
    <>
      <input {...omit(inputProps, INPUT_PROPS_OMIT_KEYS)} />
      <span className={styles.checkbox}>
        <i className="fi fi-correct-symbol" />
      </span>
    </>
  );
};

export const CheckboxComponent = (props /*: Props*/) => {
  const labelClassName = classNames({
    [styles.labelChecked]: props.checked,
  });

  return <SelectionControlBase {...props} component={Checkbox} labelClassName={labelClassName} />;
};

CheckboxComponent.defaultProps = {
  labelPosition: 'right',
};
