// Rules to compute service compensation based on SiunSote housing services debit check query (see data-integration
// repository, infrastructure/environment-specific/bigquery-routines/createSiunSoteInvalidDebitsReport.sql

import moment from 'moment';
import {
  CompensationLevel,
  getHousingServiceProducedServiceRule,
  HousingServicesProducedService,
  ServiceArea,
} from '../service-types';
import { ServiceEventRow } from '../types';
import { get } from 'lodash';
import { VenueId } from '../../../types/venue';
import { HousingServicesVoucher } from '../types/housing-services-voucher';

export type ComputeServicePriceResult = {
  amount: number;
  numberOfDays: number;
  compensationLevel: CompensationLevel | undefined;
  mealPriceInEurCent?: number;
};

export const ServicePriceNotComputable = 'ServicePriceNotComputable';
export type ServicePriceNotComputableType = typeof ServicePriceNotComputable;

export const computeServicePriceForRow = (
  row: ServiceEventRow,
  voucher: Pick<HousingServicesVoucher, 'unitCost' | 'priceDetails' | 'serviceArea'>,
  venueId: VenueId,
): ComputeServicePriceResult | ServicePriceNotComputableType => {
  const producedService = row.producedService;
  const startDate = row.period.startDate;
  const endDate = row.period.endDate;
  const dailyPriceInEur = voucher.unitCost;
  const serviceArea = voucher.serviceArea;
  const mealPrice = get(voucher, ['priceDetails', venueId, 'mealPrice']);

  if (!startDate || !endDate) {
    return ServicePriceNotComputable;
  }

  return computeServicePrice(serviceArea, producedService, startDate, endDate, dailyPriceInEur, mealPrice);
};

const computeServicePrice = (
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService,
  startDate: Date,
  endDate: Date,
  dailyPriceInEur: number,
  mealPriceInEurCent?: number,
): ComputeServicePriceResult | ServicePriceNotComputableType => {
  const numberOfDays = differenceInDays(startDate, endDate);
  const compensationLevel = getHousingServiceProducedServiceRule(serviceArea, producedService).compensationLevel;

  const dailyPriceInEurCent = dailyPriceInEur * 100;

  const dailyCompensation: number | ServicePriceNotComputableType =
    compensationLevel === CompensationLevel.FullCompensation
      ? dailyPriceInEurCent
      : compensationLevel === CompensationLevel.CompensationWithoutMeal
      ? mealPriceInEurCent
        ? dailyPriceInEurCent - mealPriceInEurCent
        : ServicePriceNotComputable
      : 0;

  const multiplier = producedService === HousingServicesProducedService.ExitusHousingServices ? 2 : 1;

  return dailyCompensation === ServicePriceNotComputable
    ? dailyCompensation
    : {
        amount: roundToTwoDigits((dailyCompensation / 100) * numberOfDays * multiplier),
        compensationLevel,
        numberOfDays: numberOfDays * multiplier,
        mealPriceInEurCent,
      };
};

const differenceInDays = (startDate: Date, endDate: Date): number => {
  return moment(endDate).startOf('day').diff(moment(startDate).startOf('day'), 'days') + 1;
};

const roundToTwoDigits = (value: number) => Math.round(value * 100) / 100;
