/*:: import type { Dispatch } from 'redux';*/
import { RSAA } from 'redux-api-middleware';
import { change, formValueSelector, getFormValues } from 'redux-form';
import { createAction } from 'redux-actions';

import pick from 'lodash/pick';

import { convertParams } from 'app/utils';

import * as types from './coupons.action-types';
import { fetchAccounts } from 'app/redux/accounts/accounts.actions';

export const fetchCoupons =
  (params, form = 'filter-coupons') =>
  (dispatch, getState) => {
    const filters = getFormValues(form)(getState());
    return dispatch({
      [RSAA]: {
        types: [
          {
            type: types.FETCH_COUPONS_REQUEST,
            meta: {
              params: {
                size: 10,
                ...convertParams(filters),
                ...params,
              },
            },
          },
          {
            type: types.FETCH_COUPONS_SUCCESS,
          },
          types.FETCH_COUPONS_FAILURE,
        ],
        endpoint: '/service-coupons',
        method: 'GET',
      },
    });
  };

export const renewCoupons = body => ({
  [RSAA]: {
    types: [types.RENEW_COUPONS_REQUEST, types.RENEW_COUPONS_SUCCESS, types.RENEW_COUPONS_FAILURE],
    endpoint: '/service-coupons/renew',
    method: 'POST',
    body,
  },
});

export const importCoupons = ({ file }) => {
  const body = new FormData();

  body.append('file', file);

  return {
    [RSAA]: {
      types: [types.IMPORT_COUPONS_REQUEST, types.IMPORT_COUPONS_SUCCESS, types.IMPORT_COUPONS_FAILURE],
      endpoint: '/service-coupons/import',
      method: 'POST',
      body,
    },
  };
};

export const importCouponsColumns = (token, body) => ({
  [RSAA]: {
    types: [
      types.IMPORT_COUPONS_COLUMNS_REQUEST,
      types.IMPORT_COUPONS_COLUMNS_SUCCESS,
      types.IMPORT_COUPONS_COLUMNS_FAILURE,
    ],
    endpoint: `/service-coupons/import/${token}`,
    method: 'POST',
    body,
  },
});

export const importCouponsConfirm = token => ({
  [RSAA]: {
    types: [
      types.IMPORT_COUPONS_CONFIRM_REQUEST,
      types.IMPORT_COUPONS_CONFIRM_SUCCESS,
      types.IMPORT_COUPONS_CONFIRM_FAILURE,
    ],
    endpoint: `/service-coupons/import/${token}/confirm`,
    method: 'POST',
  },
});

export const createCoupon = body => ({
  [RSAA]: {
    types: [types.CREATE_COUPON_REQUEST, types.CREATE_COUPON_SUCCESS, types.CREATE_COUPON_FAILURE],
    endpoint: '/service-coupons',
    method: 'POST',
    body,
  },
});

/*
 * This action will create a voucher and then refresh the municipal account info
 * which will update current balance information
 */
export const createVoucherAndRefreshMunicipal = body => async (dispatch /*: Dispatch*/) => {
  const createVoucherResponse = await dispatch(createCoupon(body));
  dispatch(fetchAccounts());

  return createVoucherResponse;
};

export const validateCoupon = body => ({
  [RSAA]: {
    types: [types.VALIDATE_COUPON_REQUEST, types.VALIDATE_COUPON_SUCCESS, types.VALIDATE_COUPON_FAILURE],
    endpoint: '/service-coupons/validate',
    method: 'POST',
    body,
  },
});

export const invalidateCoupon = id => ({
  [RSAA]: {
    types: [
      types.INVALIDATE_COUPON_REQUEST,
      { type: types.INVALIDATE_COUPON_SUCCESS, meta: { id } },
      types.INVALIDATE_COUPON_FAILURE,
    ],
    endpoint: `/service-coupons/${id}/invalidate`,
    method: 'POST',
  },
});

export const redeemTransfer = (coupon, id) => ({
  [RSAA]: {
    types: [
      types.REDEEM_COUPON_TRANSFER_REQUEST,
      {
        type: types.REDEEM_COUPON_TRANSFER_SUCCESS,
      },
      types.REDEEM_COUPON_TRANSFER_FAILURE,
    ],
    endpoint: `/service-coupons/${coupon}/transfers/${id}/redeem`,
    method: 'POST',
  },
});

export const fetchVenueCoupons = params => dispatch => {
  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_COUPONS_REQUEST,
          meta: {
            params: {
              ...convertParams(params),
            },
          },
        },
        types.FETCH_COUPONS_SUCCESS,
        types.FETCH_COUPONS_FAILURE,
      ],
      endpoint: '/service-coupons/venue',
      method: 'GET',
    },
  });
};

export const fetchVenueCouponsMetadata = () => ({
  [RSAA]: {
    types: [
      types.FETCH_COUPONS_METADATA_REQUEST,
      types.FETCH_COUPONS_METADATA_SUCCESS,
      types.FETCH_COUPONS_METADATA_FAILURE,
    ],
    endpoint: '/service-coupons/venue/metadata',
    method: 'GET',
  },
});

export const venueCouponsMetadataClear = createAction(types.CLEAR_COUPONS_METADATA);

export const updateCalculatedValue = (form, name, calculations) => (dispatch, getState) => {
  const value = formValueSelector(form)(getState(), calculations);

  return dispatch(
    change(
      form,
      name,
      value.reduce((prev, next) => {
        const amount = +`${next.amount || 0}`.replace(/,/g, '.');
        const price = +`${next.price || 0}`.replace(/,/g, '.');

        return Math.round((prev + amount * price) * 1e2) / 1e2;
      }, 0),
    ),
  );
};

export const checkBalance = (serviceVoucherId, decisionNumber) => ({
  [RSAA]: {
    types: [
      {
        type: types.CHECK_BALANCE_REQUEST,
        meta: {
          id: serviceVoucherId,
          decisionNumber,
        },
      },
      {
        type: types.CHECK_BALANCE_SUCCESS,
        meta: { serviceVoucherId },
      },
      types.CHECK_BALANCE_FAILURE,
    ],
    endpoint: `/public/service-coupons/${serviceVoucherId}/balance/${decisionNumber}`,
    method: 'GET',
    isPublic: true,
  },
});

export const saveCouponsDraft = form => (dispatch, getState) => {
  const couponsForm = getFormValues(form)(getState());
  const filters = getFormValues('filter-renew-coupons')(getState());
  const isAuthenticated = getState().users.isAuthenticated;

  if (isAuthenticated) {
    return dispatch({
      [RSAA]: {
        types: [
          types.UPDATE_COUPONS_DRAFT_REQUEST,
          types.UPDATE_COUPONS_DRAFT_SUCCESS,
          types.UPDATE_COUPONS_DRAFT_FAILURE,
        ],
        endpoint: '/service-coupons/renew/draft',
        method: 'PUT',
        body: {
          list: couponsForm.coupons.map(coupon =>
            pick(coupon, ['id', 'defaultValue', 'validFrom', 'validTo', 'maxTransaction', 'active']),
          ),
          filters,
        },
      },
    });
  }
};

export const clearCouponsDraft = () => ({
  [RSAA]: {
    types: [types.CLEAR_COUPONS_DRAFT_REQUEST, types.CLEAR_COUPONS_DRAFT_SUCCESS, types.CLEAR_COUPONS_DRAFT_FAILURE],
    endpoint: '/service-coupons/renew/draft',
    method: 'PUT',
  },
});

export const fetchCouponsDraft = () => ({
  [RSAA]: {
    types: [types.FETCH_COUPONS_DRAFT_REQUEST, types.FETCH_COUPONS_DRAFT_SUCCESS, types.FETCH_COUPONS_DRAFT_FAILURE],
    endpoint: '/service-coupons/renew/draft',
    method: 'GET',
  },
});

export const applyCouponsDraft = createAction(types.APPLY_COUPONS_DRAFT);

export const shareCouponSMS = ({ lang, ...body }) => ({
  [RSAA]: {
    types: [types.SHARE_COUPON_SMS_REQUEST, types.SHARE_COUPON_SMS_SUCCESS, types.SHARE_COUPON_SMS_FAILURE],
    endpoint: `/service-coupons/sms?lang=${lang}`,
    method: 'POST',
    body,
  },
});

export const setScrollToRow = createAction(types.SET_SCROLL_TO_ROW, index => ({ scrollToRow: index }));
