import { ReportMessageExtra } from 'app/sentry/sentry';
import axios, { AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';

// using conditional type forces TS to make union of each possible type
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types
type StatusCodeUnion<TType, TKey> = TKey extends keyof TType
  ? { status: TKey } & (void extends TType[TKey] ? unknown : { data: TType[TKey] })
  : never;
export type StatusCodeMap = Partial<Record<StatusCodes, unknown>>;

// This used to include the complete axios response, but it was simplified to only returns status and data
// usage: ApiResponse<{ [StatusCodes.OK]: SuccessResponse, [StatusCodes.BAD_REQUEST]: FailureResponse }>
export type ApiResponse<TStatusCodeMap extends StatusCodeMap> = StatusCodeUnion<TStatusCodeMap, keyof TStatusCodeMap>;

export type MakeApiRequestOpts = { crashApplicationOnError?: boolean; reportErrorToSentry?: boolean };

export interface BaseApiClient {
  makeApiRequest: <T extends StatusCodeMap>(
    config: AxiosRequestConfig,
    opts?: MakeApiRequestOpts,
  ) => Promise<ApiResponse<T>>;
}

export function createBaseApiClient(
  baseUrl: string,
  crashApplication: () => void,
  reportError: (message: string, extra: ReportMessageExtra) => void,
): BaseApiClient {
  function apiRequestError(params: {
    message: string;
    extra?: ReportMessageExtra;
    crashApplication: boolean;
    reportErrorToSentry: boolean;
  }) {
    params.crashApplication && crashApplication();
    params.reportErrorToSentry && reportError(params.message, params.extra ?? {});
  }

  async function makeApiRequest<T>(config: AxiosRequestConfig, opts?: MakeApiRequestOpts): Promise<ApiResponse<T>> {
    const requestConfig: AxiosRequestConfig = {
      baseURL: `${baseUrl}`,
      ...config,
    };
    const url = `${requestConfig.baseURL}${requestConfig.url}`;

    try {
      const { data, status } = await axios.request(requestConfig);
      return { data, status } as unknown as ApiResponse<T>;
    } catch (err) {
      const crashApplicationOnError = opts?.crashApplicationOnError ?? true;
      const reportErrorToSentry = opts?.reportErrorToSentry ?? true;
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const status = err.response.status;
          const data = err.response.data;
          const serverPayload = data ? JSON.stringify(data) : '';
          const message = `Unexpected response ${status} when making API call: ${requestConfig.method} ${url}`;
          const extra = {
            status,
            serverPayload,
          };
          apiRequestError({ message, extra, crashApplication: crashApplicationOnError, reportErrorToSentry });
        } else {
          const message = `Unexpected error: ${err.message} when making API call: ${requestConfig.method} ${url}`;
          apiRequestError({ message, crashApplication: crashApplicationOnError, reportErrorToSentry });
        }
      } else {
        const message = `Unknown error: ${err.message} when making API call: ${requestConfig.method} ${url}`;
        apiRequestError({ message, crashApplication: crashApplicationOnError, reportErrorToSentry });
      }
      return err;
    }
  }

  return { makeApiRequest };
}
