// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { confirmation } from 'redux-form-validators';

import { TitleComponent } from 'app/auth/title/title.component';
import { BackComponent } from 'app/auth/back/back.component';
import { ConnectedInputComponent, InfoComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';
import { required } from 'app/utils';

/*:: import type { Props } from './new-password.component.types.js.flow';*/
import { submit } from 'app/auth/login/login.module.scss';

export class NewPasswordComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <section>
        <MediaQuery maxWidth={991}>
          <BackComponent to="/login">
            <MediaQuery maxWidth={767}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
            </MediaQuery>
          </BackComponent>
        </MediaQuery>
        <TitleComponent withBackOption>
          <FormattedMessage id="AUTH.SET_NEW_PASSWORD" />
        </TitleComponent>

        {this.props.submitSucceeded && (
          <div>
            <InfoComponent type="success">
              <FormattedMessage id="AUTH.PASSWORD_CHANGED" />
            </InfoComponent>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}

        {this.props.submitFailed && this.props.error && (
          <div>
            <InfoComponent type="error">
              <FormattedMessage id={this.props.error} />
            </InfoComponent>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}
        {!this.props.submitSucceeded && !(this.props.submitFailed && this.props.error) && (
          <form onSubmit={this.props.handleSubmit}>
            <Field
              name="password"
              type="password"
              label="AUTH.PASSWORD"
              placeholder={this.props.intl.formatMessage({ id: 'AUTH.PASSWORD_PLACEHOLDER' })}
              component={ConnectedInputComponent}
              large
              validate={required({ message: 'VALIDATION.NEW_PASSWORD_REQUIRED' })}
              required
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label="AUTH.CONFIRM_PASSWORD"
              placeholder={this.props.intl.formatMessage({ id: 'AUTH.PASSWORD_PLACEHOLDER' })}
              component={ConnectedInputComponent}
              large
              validate={[
                required({ message: 'VALIDATION.PASSWORD_CONFIRMATION_REQUIRED' }),
                confirmation({
                  field: 'password',
                  message: {
                    id: 'VALIDATION.PASSWORD_MATCH',
                  },
                }),
              ]}
              required
            />
            <div className={submit}>
              <MediaQuery minWidth={992}>
                <BackComponent to="/login">
                  <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
                </BackComponent>
              </MediaQuery>
              <ButtonComponent theme="secondary" size="large" loading={this.props.submitting}>
                {this.props.intl.formatMessage({ id: 'AUTH.CHANGE_PASSWORD' })}
              </ButtonComponent>
            </div>
          </form>
        )}
      </section>
    );
  }
}
