import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import debounce from 'lodash/debounce';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';

import { VatArchiveDetailsFiltersComponent } from './vat-archive-details-filters.component';

const initialValuesSelector = query => {
  const searchParams = new URLSearchParams(query);

  return {
    id: searchParams.get('id') || '',
    buyerName: searchParams.get('buyerName') || '',
    sellerName: searchParams.get('sellerName') || '',
    type: searchParams.get('type') || '',
  };
};

const searchParamsSelector = params => {
  const searchParams = new URLSearchParams();

  // eslint-disable-next-line no-unused-vars
  for (const param in params) {
    if (params.hasOwnProperty(param) && !!params[param]) {
      searchParams.set(param, params[param]);
    }
  }

  return searchParams.toString();
};

const onChange = (dispatch, body) =>
  dispatch(
    push({
      search: searchParamsSelector(body),
      state: {
        keepScrollPosition: true,
      },
    }),
  );

const mapStateToProps = (
  {
    users: {
      business,
      current: { businesses },
    },
  },
  { location: { search } },
) => ({
  initialValues: initialValuesSelector(search),
  type: business.type,
});

const mapDispatchToProps = dispatch => ({
  onChange: debounce(onChange.bind(this, dispatch), 500),
});

export const VatArchiveDetailsFiltersContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'filter-vat-archive',
  }),
  withClear,
)(VatArchiveDetailsFiltersComponent);
