import { composePaths, ControlProps, UISchemaElement } from '@jsonforms/core';
// eslint-disable-next-line no-restricted-imports
import { JsonFormsStateContext, useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { isEmpty, isEqual, uniq } from 'lodash';
import { ComponentType, useState } from 'react';
import { JsonFormsExternalErrorsContext } from './jsonforms';
import { FormFieldValidationError } from 'v2/types/postpay';
import { isNotNil } from '../is-nil';

function formatErrors(errors: string[]) {
  return uniq(errors)
    .filter(err => !isEmpty(err))
    .join(', ');
}

function getJsonFormsErrorsMatchingPath(state: JsonFormsStateContext, elementPath: string): string[] {
  const allErrors = state?.core?.errors ?? [];
  return allErrors
    .filter(({ dataPath }) => dataPath === elementPath)
    .map(error => error.message)
    .filter(isNotNil);
}

const getExternalErrorsMatchingPath = (errors: FormFieldValidationError[], path: string) =>
  errors.filter(error => error.path === path).map(error => error.message);

type VaanaJsonFormsControlOptions = {
  relativeErrorPaths: string[];
};

const defaultOptions: VaanaJsonFormsControlOptions = {
  // '' matches the path of the element/data being rendered
  relativeErrorPaths: [''],
};

// wraps `withJsonFormsControlProps` and injects "external errors" into the control
export function vaanaJsonFormsControl(
  WrappedComponent: ComponentType<ControlProps>,
  makeOptions?: (schema: unknown, uischema: UISchemaElement) => VaanaJsonFormsControlOptions,
) {
  return withJsonFormsControlProps(props => {
    const jsonFormsState = useJsonForms();

    const options = makeOptions ? makeOptions(props.schema, props.uischema) : defaultOptions;
    const controlLocalValidationErrors = options.relativeErrorPaths.flatMap(relativePath =>
      getJsonFormsErrorsMatchingPath(jsonFormsState, composePaths(props.path, relativePath)),
    );

    const [currentExternalErrors, setCurrentExternalErrors] = useState<string[]>([]);
    const [shouldDisplayExternalErrors, setShouldDisplayExternalErrors] = useState(true);

    // show external errors when they are updated
    function showExternalErrorsIfChanged(externalErrors: string[]) {
      if (!isEqual(currentExternalErrors, externalErrors)) {
        setCurrentExternalErrors(externalErrors);
        setShouldDisplayExternalErrors(true);
      }
    }

    // hide external errors when the control value is changed
    function handleChange(path: string, value: unknown, opts: { isDynamic: boolean }) {
      props.handleChange(path, value);

      if (!opts.isDynamic) {
        // TODO: hiding of external errors is disabled for dynamic forms, because it doesnt work correctly.
        // If we hide external errors on change, we must be able to detect when the server has
        // responded with new external errors. This previous approach broke if the external
        // errors stayed the same even after change
        setShouldDisplayExternalErrors(false);
      }
    }

    return (
      <JsonFormsExternalErrorsContext.Consumer>
        {({ externalErrors, isDynamic }) => {
          const controlExternalValidationErrors = options.relativeErrorPaths.flatMap(relativePath =>
            getExternalErrorsMatchingPath(externalErrors, composePaths(props.path, relativePath)),
          );

          showExternalErrorsIfChanged(controlExternalValidationErrors);
          const displayedErrors = shouldDisplayExternalErrors
            ? [...controlExternalValidationErrors, ...controlLocalValidationErrors]
            : controlLocalValidationErrors;

          return (
            <WrappedComponent
              {...props}
              errors={formatErrors(displayedErrors)}
              handleChange={(path, value) => handleChange(path, value, { isDynamic })}
            />
          );
        }}
      </JsonFormsExternalErrorsContext.Consumer>
    );
  });
}
