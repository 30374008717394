import { useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { ButtonComponent, DatePickerComponent, Typography } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import styles from './approve.component.module.scss';

interface Props {
  submitting: boolean;
  displayHelpText: boolean;
  onApprove: (contractStartDate?: Date) => void;
  onCancel: () => void;
}

interface DatePickerOnChangeType {
  date: Moment;
  validationBounds: { maxDate: Moment; minDate: Moment };
}

export const ApproveConfirmation: React.FunctionComponent<Props> = ({
  submitting,
  displayHelpText,
  onApprove,
  onCancel,
}: Props) => {
  const [contractStartDate, setContractStartDate] = useState<Moment>(moment());

  const maxDate = moment().add(1, 'years').endOf('year');
  const minDate = moment();

  const onChangeDate = (date: DatePickerOnChangeType) => {
    setContractStartDate(date.date);
  };

  return (
    <div className="pt-4">
      {displayHelpText && (
        <Typography id="PRICELISTS.DETAILS.REVIEW_PROMPT" className={classNames(styles.review, 'mb-3 pb-2')} />
      )}
      <div className={styles.datepicker}>
        <DatePickerComponent
          label="PRICELISTS.DETAILS.CONTRACT_STARTDATE"
          onChange={(date: DatePickerOnChangeType) => onChangeDate(date)}
          selectedDate={contractStartDate}
          bounds={{ maxDate, minDate }}
          required
          preventOverFlow={true}
        />
      </div>
      <div className="text-right">
        <ButtonComponent theme="primary-outline" onClick={onCancel} disabled={submitting}>
          <FormattedMessage id="CORE.CANCEL" />
        </ButtonComponent>
        <ButtonComponent theme="primary" className="ml-2" onClick={() => onApprove(contractStartDate.toDate())}>
          <FormattedMessage id="CORE.CONFIRM" />
        </ButtonComponent>
      </div>
    </div>
  );
};
