import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { RegisterFormComponent } from './register-form.component';

const mapStateToProps = state => ({
  swiftCodes: state.dictionaries['municipal/services'].swiftCodes,
});

export const RegisterFormContainer = compose(injectIntl, connect(mapStateToProps))(RegisterFormComponent);
