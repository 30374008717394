// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { checkPermissions, currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

import { ButtonComponent, TileComponent, Typography } from 'app/shared';

/*:: import type { Props } from './account-transactions-tile.component.types';*/
import styles from 'app/transactions/municipal/table/transactions-municipal-table.module.scss';

export class AccountTransactionsTileComponent extends Component /*:: <Props>*/ {
  render() {
    const tileClassNames =
      !!this.props.tile.refundedBy || !!this.props.tile.refunds ? { className: 'filled-tile' } : {};

    return (
      <TileComponent {...tileClassNames}>
        <div className={classNames('row', 'align-items-center')}>
          <div className="col-sm-12 col-md-8">
            <Typography type="header" color="grey">
              {dateFormatter(this.props.tile.date, true)}
            </Typography>
          </div>
          {checkPermissions(this.props.permissions, ['transactionDetails', 'read']) && (
            <div className="col-sm-12 col-md-4 text-right">
              <ButtonComponent
                theme="secondary-outline"
                noWrap
                to={{
                  pathname: `${this.props.pathname}/${this.props.tile.id}`,
                  state: {
                    keepScrollPosition: true,
                  },
                }}
              >
                <i className="fi fi-open" />
                <FormattedMessage id="TRANSACTIONS.OPEN" />
              </ButtonComponent>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-8">
            <FormattedMessage id="TRANSACTIONS.PAYER_RECEIVER" tagName="small" />
            {this.props.tile.owner}
          </div>

          <div className="col-md-4">
            <FormattedMessage id="TRANSACTIONS.AMOUNT" tagName="small" />
            <strong
              className={classNames(styles.amount, {
                [styles.income]: this.props.tile.amount > 0,
                [styles.expense]: this.props.tile.amount < 0,
              })}
            >
              {currencyFormatter.format(this.props.tile.amount)}
            </strong>
          </div>
        </div>
      </TileComponent>
    );
  }
}
