// @flow

import React, { Fragment } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './table.component.types';*/
import styles from './table.module.scss';

export const TableComponent = (props /*: Props*/) => {
  const wrapper = props.scrollable
    ? { type: 'div', props: { className: styles.scrollable } }
    : { type: Fragment, props: {} };

  return (
    <wrapper.type {...wrapper.props}>
      <table className={classNames('table', props.className, styles.table)}>{props.children}</table>
    </wrapper.type>
  );
};
