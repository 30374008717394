import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { CardHeaderComponent } from 'app/shared/card-new/header/card-header.component';
import { debounce, isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import styles from 'v2/service-voucher/postpay/issuing/forms/service-voucher-form.module.scss';
import { FormData, FormFieldValidationError, FormTemplate } from 'v2/types/postpay';
import { TranslationKey } from 'v2/types/translations';
import { JsonForms, JsonFormsProps } from 'v2/utils/jsonforms/jsonforms';

type ServiceVoucherFormProps = {
  formTemplate: Pick<FormTemplate, 'schema' | 'uiSchema'>;
  formData: FormData;
  onChange: (formData: FormData) => void;
  title: TranslationKey;
  validationErrors: FormFieldValidationError[];
};

export const ServiceVoucherFormComponent: React.FC<ServiceVoucherFormProps> = ({
  formTemplate,
  formData,
  onChange: onChangeCallback,
  title,
  validationErrors,
}) => {
  const onChange: JsonFormsProps['onChange'] = useCallback(
    ({ data }) => {
      // if nothing actually changed, don't do anything
      if (isEqual(data, formData)) return;
      onChangeCallback(data);
    },
    [formData, onChangeCallback],
  );

  // 0ms debounce aka "do it on next tick". This might help with rendering / perceived performance
  // of the website, since state change doesnt happen on user input callback, not sure.
  // Having higher than 0ms debounce is problematic because at that point redux state and
  // control-level state will get out of sync, and thus redux-level state can override control state.
  const debouncedOnChange = useMemo(() => debounce(onChange), [onChange]);

  return (
    <CardComponent>
      <CardHeaderComponent title={title} />
      <CardBodyComponent className={styles.cardBody}>
        <JsonForms
          schema={formTemplate.schema}
          uischema={formTemplate.uiSchema}
          data={formData}
          onChange={debouncedOnChange}
          externalValidationErrors={validationErrors}
        />
      </CardBodyComponent>
    </CardComponent>
  );
};
