// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, SeparatorComponent, SummaryFieldComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';

import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

import { CouponsCreateConfirmTableComponent } from './table/coupons-create-confirm-table.component';
import { CouponsCreateConfirmTileComponent } from './tile/coupons-create-confirm-tile.component';

/*:: import type { Props } from './coupons-create-confirm.component.types';*/

export class CouponsCreateConfirmComponent extends Component /*:: <Props>*/ {
  getVenue = () => {
    const contract = this.props.contractsOptions.find(({ id }) => id === this.props.values.venueId);

    return contract ? contract.name : '-';
  };

  render() {
    return (
      <>
        {this.props.couponCreateError && (
          <AlertComponent type="failure">
            <FormattedMessage id="COUPONS.CREATE.COUPON_CREATE_ERROR" />
          </AlertComponent>
        )}
        <CardComponent>
          <CardHeaderComponent title="COUPONS.CREATE.CONFIRM" />
          <SeparatorComponent />
          <CardBodyComponent>
            <Typography color="grey" type="subtitle">
              <FormattedMessage id="COUPONS.CREATE.DETAILS" />
            </Typography>
            <SeparatorComponent />
            <div className="row">
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.CREATE.LABEL.COUPON_NAME" tagName="strong" />
                  {this.props.values.name}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.CREATE.LABEL.VALID" tagName="strong" />
                  {dateFormatter(this.props.values.valid.from)} - {dateFormatter(this.props.values.valid.to)}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.VALUE" tagName="strong" />
                  {currencyFormatter.format(this.props.values.defaultValue)}
                </SummaryFieldComponent>
              </div>
              {this.props.current.displayVenueInput && (
                <div className="col-lg-6">
                  <SummaryFieldComponent>
                    <FormattedMessage id="COUPONS.CREATE.LABEL.VENUE_CONFIRM" tagName="strong" />
                    {this.getVenue()}
                  </SummaryFieldComponent>
                </div>
              )}
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.PRICE_UNIT" tagName="strong" />
                  {this.props.values.priceUnit ? (
                    <FormattedMessage id={`COUPONS.TYPES.UNITS.${this.props.values.priceUnit}`} />
                  ) : (
                    '-'
                  )}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.SET_MAX_TRANSACTION" tagName="strong" />
                  {this.props.values.transactionMaxPriceEnabled ? (
                    <FormattedMessage id="CORE.YES" />
                  ) : (
                    <FormattedMessage id="CORE.NO" />
                  )}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.MAX_TRANSACTION" tagName="strong" />
                  {this.props.values.transactionMaxPriceEnabled
                    ? currencyFormatter.format(this.props.values.defaultTransactionMaxPrice)
                    : '-'}
                </SummaryFieldComponent>
              </div>
              <div className="col-lg-6">
                <SummaryFieldComponent>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.ADDITIONAL" tagName="strong" />
                  {this.props.values.additionalInfo || '-'}
                </SummaryFieldComponent>
              </div>
            </div>
            <SeparatorComponent />
            <Typography color="grey" type="subtitle">
              <FormattedMessage id="COUPONS.CREATE.CUSTOMERS" />
            </Typography>
            <SeparatorComponent />
            <ListComponent
              list={this.props.values.customerDecisions}
              table={CouponsCreateConfirmTableComponent}
              tile={CouponsCreateConfirmTileComponent}
              searchable={false}
              props={{
                displayNameInput: this.props.current.displayNameInput,
                priceUnit: this.props.values ? this.props.values.priceUnit : '',
              }}
            />
          </CardBodyComponent>
          <SeparatorComponent />
          <CardFooterComponent>
            <ButtonComponent theme="primary-outline" onClick={this.props.onBack}>
              <FormattedMessage id="CORE.EDIT" />
            </ButtonComponent>
            <ButtonComponent theme="primary" className="ml-auto" loading={this.props.submitting}>
              <FormattedMessage id="CORE.CONFIRM" />
            </ButtonComponent>
          </CardFooterComponent>
        </CardComponent>
      </>
    );
  }
}
