import { Channel } from 'redux-saga';
import { ActionMatchingPattern } from '@redux-saga/types';
import { actionChannel, ActionPattern, call, fork, ForkEffect, take } from 'redux-saga/effects';
import { NonEmptyArray } from './utils';
import { createSelectorHook } from 'react-redux';
import { V1RootState } from 'app/v1.types';
import { V2RootState } from 'v2/v2.reducer';

// note: this is similar to takeLeading. Both block the actions in such a way that
// there is only one instance of saga running at any given time. The difference is that
// takeLeading will ignore all actions while the saga is running, where as takeBatching
// will call the saga after previous one has finished with the actions that was emitted
// while saga was running.
// TODO: it could be interesting to be able to make "groups" from actions, for example
// that actions with a given id are grouped together
export function takeBatching<TPattern extends ActionPattern>(
  pattern: TPattern,
  saga: (actions: NonEmptyArray<ActionMatchingPattern<TPattern>>) => unknown,
): ForkEffect<unknown> {
  return fork(function* () {
    // open an action channel with buffer size of 1 (only the latest action is kept)
    const requestChannel: Channel<unknown> = yield actionChannel(pattern);
    while (true) {
      // yield from request channel - this will block until an action is received
      const action: ActionMatchingPattern<TPattern> = yield take(requestChannel);
      let rest: ActionMatchingPattern<TPattern>[] = [];
      requestChannel.flush(items => (rest = items as ActionMatchingPattern<TPattern>[]));

      const actions: NonEmptyArray<ActionMatchingPattern<TPattern>> = [action, ...rest];

      // call the saga. this is a blocking action
      yield call(saga, actions);
    }
  });
}

export const useV1Selector = createSelectorHook<V1RootState>();
export const useV2Selector = createSelectorHook<V2RootState>();
