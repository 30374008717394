// @flow

import React from 'react';
import ReactTextarea from 'react-textarea-autosize';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { InputBaseControl, PortalComponent } from 'app/shared';
import { INPUT_PROPS_OMIT_KEYS } from 'app/constants';
import { CharCounter } from './counter/counter.component';

/*:: import type { TextareaComponentProps, TextareaProps } from './textarea.component.types';*/
import styles from './textarea.module.scss';

export const Textarea = (props /*: TextareaProps*/) => {
  const { showCounter, errorRef, ...otherProps } = props;

  const textareaClassNames = classNames('form-control', styles.textarea, { [styles.disabled]: props.disabled });

  const counterProps = {
    showCounter,
    maxLength: props.maxLength,
    value: props.value,
  };

  return (
    <>
      {props.disabled && <i className={`fi fi-pen-disabled ${styles.disabledIcon}`} />}
      <ReactTextarea {...omit(otherProps, INPUT_PROPS_OMIT_KEYS)} className={textareaClassNames} />
      <PortalComponent component={errorRef}>
        <CharCounter {...counterProps} />
      </PortalComponent>
    </>
  );
};

Textarea.defaultProps = {
  maxLength: 500,
  minRows: 4,
  showCounter: true,
};

export const TextareaComponent = (props /*: TextareaComponentProps*/) => (
  <InputBaseControl
    {...props}
    component={Textarea}
    appendClassName={styles.append}
    variant="auto"
    iconClassName={styles.icon}
  />
);
