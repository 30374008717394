// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, GroupComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './orders-create.placeholder.types';*/

export class OrdersCreatePlaceholder extends Component /*:: <Props>*/ {
  render() {
    const invoiceColumnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <CardHeaderComponent title="ORDERS.ORDER_EMONEY_SUBTITLE" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className="col-lg-6 col-xl-4">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.SERVICE_COUPON_EMONEY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.INVOICING_METHOD' })} required>
            <PlaceholderComponent height={60} radius={8} color="light" />
          </GroupComponent>
          <SeparatorComponent />
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.OFFICIAL_NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.EMAIL' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_IDENTIFIER' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.DELIVERY_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={invoiceColumnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'ORDERS.ORDER_REFERENCE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>
        <CardFooterComponent className="text-right">
          <ButtonComponent theme="primary" disabled className="ml-auto">
            <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" />
          </ButtonComponent>
        </CardFooterComponent>
      </CardComponent>
    );
  }
}
