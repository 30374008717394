//@flow

import React from 'react';
import classNames from 'classnames';

import { Typography } from 'app/shared';
import { translate } from 'app/utils';

/*:: import type { Props } from './error.component.types';*/
import styles from './error.module.scss';

const typographySetting = {
  tag: 'span',
  type: 'smallText',
  color: 'grey',
};

export const Error = (props /*: Props*/) => {
  const {
    error,
    controlMeta: { isInvalid },
    hideValidation,
    label,
    setErrorRef,
  } = props;

  //TODO: improve this
  if (hideValidation || !label) {
    return null;
  }

  const showError = !!error && isInvalid;

  // $FlowFixMe
  const typographyErrorProps = {
    ...typographySetting,
    ...(showError ? translate({ translation: error }, true) : { children: <span>&nbsp;</span> }),
  };

  return (
    <div ref={setErrorRef} className={classNames('invalid-feedback', styles.errorMessage)}>
      <Typography {...typographyErrorProps} color="failure" />
    </div>
  );
};
