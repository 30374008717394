import { connect } from 'react-redux';

import { ConfirmComponent } from './confirm.component';

import { updateConfirmation } from 'app/redux/view/view.actions';

const mapStateToProps = ({ view: { confirmation } }) => ({
  show: !!confirmation,
  ...confirmation,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(updateConfirmation(null)),
});

export const ConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmComponent);
