// @flow

import React from 'react';

import { ConnectControl, TextareaComponent } from 'app/shared';

/*:: import type { Props } from './connected-textarea.component.types';*/

export const ConnectedTextareaComponent = (props /*: Props*/) => (
  <ConnectControl {...props} component={TextareaComponent} />
);
