// @flow

import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './table-body.component.types';*/
import styles from './table-body.module.scss';

export const TableBodyComponent = (props /*: Props*/) => (
  <tbody className={classNames(styles.tbody, props.className)}>{props.children}</tbody>
);
