//@flow

import React from 'react';
import classNames from 'classnames';

import { ControlBase } from 'app/shared';
import { Error, Label, Wrapper } from 'app/shared/form/controls-elements';

/*:: import type {
  SelectionControlBaseComponentProps,
  SelectionControlBaseProps,
} from './selection-control-base.component.types';*/

import styles from './selection-base.module.scss';

export const SelectionControlBaseComponent = (props /*: SelectionControlBaseComponentProps*/) => {
  const {
    component: Component,
    label,
    labelPosition,
    columnAlign,
    className,
    controlMeta,
    labelProps: defaultLabelProps,
    multi,
    wrapperProps,
    errorProps,
  } = props;

  const { isDisabled, isInvalid } = controlMeta;

  const wrapperClassNames = classNames(className, {
    invalid: isInvalid,
    disabled: isDisabled,
    [styles[`column-align-${columnAlign}`]]: !!multi && !!columnAlign,
  });

  const componentWrapperClassNames = classNames(styles.componentWrapper, {
    [styles.componentWrapperMultiple]: !!props.options,
  });

  const inputWrapperClassNames = classNames(styles.selectionBase, {
    [styles.selectionBaseMultiple]: !!props.options,
    [styles[`label-${labelPosition}`]]: !!label,
  });

  const componentProps = {
    ...props.componentProps,
    className: styles.control,
  };

  const labelProps = {
    ...defaultLabelProps,
    labelClassName: classNames(styles.label, defaultLabelProps.labelClassName),
  };

  return (
    <Wrapper className={wrapperClassNames} {...wrapperProps}>
      <div className={inputWrapperClassNames}>
        <Label {...labelProps} />
        {!!Component && (
          <div className={componentWrapperClassNames}>
            <Component {...componentProps} />
          </div>
        )}
      </div>
      <Error {...errorProps} />
      {props.children}
    </Wrapper>
  );
};

SelectionControlBaseComponent.defaultProps = {
  labelPosition: 'left',
  columnAlign: 'left',
};

export const SelectionControlBase = (props /*: SelectionControlBaseProps*/) => (
  <ControlBase control={SelectionControlBaseComponent} {...props} styles={styles} />
);
