import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { OrdersCreateFormComponent } from './orders-create-form.component';

const mapStateToProps = ({
  customers: { entities: customers },
  dictionaries,
  users: { business },
  orders: { orderHistory },
}) => ({
  loading: !customers[business.id] || !_.get(dictionaries, `['invoicing-options'][${business.id}]`) || !orderHistory,
  type: 'municipal',
});

export const OrdersCreateFormContainer = compose(withRouter, connect(mapStateToProps))(OrdersCreateFormComponent);
