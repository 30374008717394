import { AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ReportMessageExtra } from 'app/sentry/sentry';
import { Payment } from '../types/payments';
import { AuthenticationDataProvider, getAuthenticationHeaders } from './auth';
import { ApiResponse, createBaseApiClient } from './base-api-client';

export type MakeVenuePaymentResponse = ApiResponse<{
  [StatusCodes.NO_CONTENT]: MakeVenuePaymentSuccessResponse;
  [StatusCodes.BAD_REQUEST]: MakeVenuePaymentErrorResponse;
}>;

// Successful venue payment request has 204 No Content response
export type MakeVenuePaymentSuccessResponse = Record<string, never>;

// For fields that exist on the Payment type, the backend returns i18n tokens as error messages.
// See make_venue_payment.feature in the backend for example error responses.
export type MakeVenuePaymentErrorResponse = {
  message: string;
  errors: {
    _error?: string; // Overall error message in case the error is not for a specific field
    reference?: string;
    payments?: Record<number, VenuePaymentResponsePaymentError>;
  };
};

// This error type is not complete.
// Basically there's an optional error string field for every field on the Payment type.
type VenuePaymentResponsePaymentError = {
  amount?: string;
  serviceProduced?: {
    startDate?: string;
    endDate?: string;
  };
  numberOfPriceUnits?: string;
};

export interface HousingServicesApiClient {
  makeVenuePayment: (payments: Payment[]) => Promise<MakeVenuePaymentResponse>;
}

export function createHousingServicesApiClient(
  baseUrl: string,
  authentication: AuthenticationDataProvider,
  crashApplication: () => void,
  reportError: (message: string, extra: ReportMessageExtra) => void,
): HousingServicesApiClient {
  const { makeApiRequest } = createBaseApiClient(baseUrl, crashApplication, reportError);
  async function makeVenuePayment(payments: Payment[]): Promise<MakeVenuePaymentResponse> {
    function validateStatus(status: StatusCodes): boolean {
      const validStatusCodes: Record<MakeVenuePaymentResponse['status'], true> = {
        [StatusCodes.NO_CONTENT]: true,
        [StatusCodes.BAD_REQUEST]: true,
      };
      return validStatusCodes.hasOwnProperty(status);
    }

    const requestConfig: AxiosRequestConfig = {
      method: 'post',
      url: `/payments/venue`,
      data: { payments },
      validateStatus,
      headers: {
        Accept: 'application/json',
        ...getAuthenticationHeaders(authentication, { includeBusinessHeaders: true }),
      },
    };

    return await makeApiRequest(requestConfig);
  }

  return { makeVenuePayment };
}
