// @flow

import React, { Component } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { PlaceholderComponent } from 'app/shared';

import { CouponsTypesListTablePlaceholder } from './table/coupons-types-list-table.placeholder';
import { CouponsTypesListTilePlaceholder } from './tile/coupons-types-list-tile.placeholder';

/*:: import type { Props } from './coupons-types-list.placeholder.types';*/

export class CouponsTypesListPlaceholder extends Component /*:: <Props>*/ {
  static defaultProps = {
    types: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <CardHeaderComponent>
          <PlaceholderComponent width={120} height={18} />
        </CardHeaderComponent>
        <div className="card-body">
          <ListComponent
            list={this.props.types}
            table={CouponsTypesListTablePlaceholder}
            tile={CouponsTypesListTilePlaceholder}
          />
        </div>
      </CardComponent>
    );
  }
}
