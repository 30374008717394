// @flow

import React, { Component } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import get from 'lodash/get';

import { PageHeaderComponent } from 'app/core';
import { ButtonComponent, ListComponent, SelectComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { SummaryBox } from 'app/shared/summary-box/summary-box.component';
import { SummaryBoxPlaceholder } from 'app/shared/summary-box/placeholder/summary-box.placeholder';
import { currencyFormatter, isBusinessDifferent } from 'app/utils';

import { VatArchiveDetailsFiltersContainer } from './filters/vat-archive-details-filters.container';
import { VatArchiveDetailsPlaceholder } from './placeholder/vat-archive-details.placeholder';
import { VatArchiveDetailsTableComponent } from './table/vat-archive-details-table.component';
import { VatArchiveDetailsTileComponent } from './tile/vat-archive-details-tile.component';
import { VatArchiveModalComponent } from '../list/modal/vat-archive-modal.component';

/*:: import type { Props, State, Summary } from './vat-archive-details.component.types';*/
import componentStyles from './vat-archive-details.module.scss';
import commonStyles from 'app/vat-archive/vat-archive.module.scss';

const styles = { ...componentStyles, ...commonStyles };

export class VatArchiveDetailsComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.years) {
      this.props.fetchVatArchivesMonths();
    }

    if (!this.props.selectedArchive) {
      this.props.fetchVatArchives();
    }

    this.props.fetchVatArchive(this.props.year, this.props.month);
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.onBusinessChange();
      this.props.resetFilters();
    }

    if (prevProps.year !== this.props.year || prevProps.month !== this.props.month) {
      this.props.fetchVatArchive(this.props.year, this.props.month);
    }

    if (
      prevProps.location.search !== this.props.location.search ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.props.fetchVatArchive(this.props.year, this.props.month);
    }
  }

  getYears() /*: string[]*/ {
    if (!this.props.years) {
      return [];
    }

    return Object.keys(this.props.years);
  }

  getMonths(year /*: string*/) /*: any[]*/ {
    if (!this.props.years || !year) {
      return [];
    }

    return this.props.years[year].map(month => ({
      id: month,
      name: <FormattedDate value={new Date(Number(year), Number(month) - 1)} month="long" />,
    }));
  }

  onPeriodChange = (values /*: { month?: string, year?: string }*/) => {
    const { params } = this.props.match;
    const month = values.month || params.month;
    const year = values.year || params.year;

    this.props.changePeriod(month, year);
  };

  handleModalToggle = () => this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));

  prepareSummarySections = () /*: Array<any>*/ => {
    if (!this.props.meta) {
      return [];
    }

    const { meta: { summary } = {} } = this.props;
    return [this.prepareHeaderSection(summary), this.prepareRatesSections(summary)].filter(section => section);
  };

  prepareHeaderSection = (summary /*: Summary*/) => ({
    title: this.props.intl.formatMessage({ id: 'VAT_ARCHIVE.DETAILS.SUMMARY.TOTAL' }),
    data: [
      {
        values: [
          currencyFormatter.format(summary.total),
          this.props.intl.formatMessage(
            {
              id: 'VAT_ARCHIVE.DETAILS.SUMMARY.PAYMENTS',
            },
            { number: summary.quantity },
          ),
        ],
        classes: 'font-weight-bold',
      },
    ],
  });

  prepareRatesSections = (summary /*: Summary*/) =>
    summary.rates && {
      title: this.props.intl.formatMessage({ id: 'VAT_ARCHIVE.DETAILS.RATES.TITLE' }),
      // $FlowFixMe
      data: Object.keys(summary.rates).map(rate => ({
        values: [
          this.props.intl.formatMessage({ id: `VAT_ARCHIVE.DETAILS.RATES.${rate}` }),
          currencyFormatter.format(summary.rates[rate].total),
          this.props.intl.formatMessage(
            {
              id: 'VAT_ARCHIVE.DETAILS.SUMMARY.PAYMENTS',
            },
            { number: summary.rates[rate].quantity },
          ),
        ],
      })),
    };

  render() {
    const selectClassNames = classNames(styles.select, {
      [styles.loading]: !this.props.years,
    });

    const {
      selectedArchive,
      location: { state: { autoRedirected } = {} },
    } = this.props;

    const accountName = !!selectedArchive
      ? !!selectedArchive.nameExtra
        ? `${selectedArchive.name} - ${selectedArchive.nameExtra}`
        : selectedArchive.name
      : undefined;

    const canBack = this.props.business && !this.props.business.parentId && !autoRedirected;

    const tileHeader = () => (
      <ButtonComponent
        size="small"
        onClick={this.handleModalToggle}
        disabled={!get(this.props, ['archive', 'length'])}
        className="my-2"
      >
        <i className="fi fi-printer pl-1" />
        <FormattedMessage id="VAT_ARCHIVE.DETAILS.PRINT" />
      </ButtonComponent>
    );

    return (
      <>
        <PageHeaderComponent
          title={{ id: 'VAT_ARCHIVE.DETAILS.TITLE', values: { account: accountName } }}
          backLink={canBack ? '/vat-archive' : undefined}
        />

        <CardComponent>
          <CardBodyComponent>
            <div className="row">
              <div className="col-12 col-lg">
                <div className={styles.selectWrapper}>
                  <div className={styles.selectContainer}>
                    <SelectComponent
                      className={selectClassNames}
                      options={this.getMonths(this.props.match.params.year)}
                      value={this.props.match.params.month}
                      onChange={({ value: month }) => this.onPeriodChange({ month })}
                      disabled={!this.props.years}
                      theme="primary"
                    />
                  </div>
                  <div className={styles.selectContainer}>
                    <SelectComponent
                      className={selectClassNames}
                      options={this.getYears()}
                      value={this.props.match.params.year}
                      onChange={({ value: year }) => this.onPeriodChange({ year })}
                      disabled={!this.props.years}
                      theme="primary"
                    />
                  </div>
                </div>
                <SeparatorComponent />
                <div className={classNames(styles.inputContainer, 'd-flex flex-column')}>
                  <VatArchiveDetailsFiltersContainer meta={this.props.meta} />
                </div>
              </div>
              <div className="col-12 col-lg-auto">
                {this.props.loading && <SummaryBoxPlaceholder />}
                {this.props.loading || <SummaryBox sections={this.prepareSummarySections()} />}
              </div>
            </div>
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />

        <CardComponent>
          <CardBodyComponent>
            {this.props.loading && <VatArchiveDetailsPlaceholder type={this.props.type} />}
            {this.props.loading || (
              <ListComponent
                list={this.props.archive}
                table={VatArchiveDetailsTableComponent}
                tile={VatArchiveDetailsTileComponent}
                tileHeader={tileHeader}
                props={{
                  onDownload: this.props.onBillDownload,
                  onModalToggle: this.handleModalToggle,
                  type: this.props.type,
                }}
              />
            )}
          </CardBodyComponent>
        </CardComponent>

        <VatArchiveModalComponent
          isOpen={this.state.isModalOpen}
          onDownload={this.props.onDownload}
          title="VAT_ARCHIVE.DETAILS.PRINT"
          toggleModal={this.handleModalToggle}
        />
      </>
    );
  }
}
