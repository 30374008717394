export const FETCH_ACCOUNTS_REQUEST = 'accounts/FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_SUCCESS = 'accounts/FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'accounts/FETCH_ACCOUNTS_FAILURE';

// export const FETCH_ACCOUNT_REQUEST = 'accounts/FETCH_ACCOUNT_REQUEST';
// export const FETCH_ACCOUNT_SUCCESS = 'accounts/FETCH_ACCOUNT_SUCCESS';
// export const FETCH_ACCOUNT_FAILURE = 'accounts/FETCH_ACCOUNT_FAILURE';

export const FETCH_TRANSACTIONS_REQUEST = 'accounts/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'accounts/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'accounts/FETCH_TRANSACTIONS_FAILURE';

export const FETCH_BALANCE_REQUEST = 'accounts/BALANCE_REQUEST';
export const FETCH_BALANCE_SUCCESS = 'accounts/BALANCE_SUCCESS';
export const FETCH_BALANCE_FAILURE = 'accounts/BALANCE_FAILURE';

// export const RETURN_EMONEY_REQUEST = 'accounts/RETURN_EMONEY_REQUEST';
// export const RETURN_EMONEY_SUCCESS = 'accounts/RETURN_EMONEY_SUCCESS';
// export const RETURN_EMONEY_FAILURE = 'accounts/RETURN_EMONEY_FAILURE';
