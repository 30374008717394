import { Modal } from 'app/shared/modal-new/modal.container';
import { FunctionComponent } from 'react';
import { CouponsDetailsChargeModalComponent } from 'app/coupons/details/charge/form/modal/coupons-details-charge-modal.component';
import styles from 'app/coupons/details/charge/form/coupons-details-charge-form.module.scss';
import { ServiceEventFormValues } from './types';
import { transformToPayments } from './backend-services/transform-to-payments';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { HousingServicesVoucher } from './types/housing-services-voucher';

interface ServiceEventSubmissionConfirmationModalProps {
  venueId: string;
  voucher: HousingServicesVoucher;
  serviceEventFormValues: ServiceEventFormValues;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (value: ServiceEventFormValues) => void;
}

export const ServiceEventSubmissionConfirmationModal: FunctionComponent<ServiceEventSubmissionConfirmationModalProps> =
  ({ venueId, voucher, serviceEventFormValues, isOpen, onCancel, onSubmit }) => {
    if (isEmpty(serviceEventFormValues.serviceEventRows) || !isOpen) {
      return null;
    } else {
      const payments = transformToPayments(voucher, venueId, serviceEventFormValues);
      const paymentsForModal = payments.map(payment => ({
        ...payment,
        descriptiveService: { name: payment.descriptiveService },
      }));

      return (
        <Modal
          name={null}
          cardClassNames={styles.modal}
          component={CouponsDetailsChargeModalComponent}
          confirmOptions={[
            {
              label: <FormattedMessage id="CORE.BACK" />,
              onClick: () => onCancel(),
              theme: 'primary-outline',
              arrowBack: true,
            },
            {
              label: <FormattedMessage id="CORE.CONFIRM" />,
              onClick: () => {
                onSubmit(serviceEventFormValues);
              },
            },
          ]}
          componentProps={{
            coupon: voucher,
            payments: paymentsForModal,
          }}
          isOpen={isOpen}
          title="COUPONS.DETAILS.CHARGE.MODAL.HEADER"
        />
      );
    }
  };
