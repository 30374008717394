import { PricelistTypeEnum } from 'app/constants';
import { Change } from 'diff';

// InputXXX = what backend returns
// XXX = formatted for frontend use

export type TableColumn = {
  title: string;
  subtitle?: string;
  oldValue?: string;
};

export type PriceObject = {
  value: string;
  oldValue?: string;
};

export type TableRow = {
  name: string;
  prices: PriceObject[];
};

export type TextPriceList = { type: PricelistTypeEnum.Text; text: string; diff: Change[] };

export type TablePriceList = { type: PricelistTypeEnum.Table; columns: TableColumn[]; services: TableRow[] };

export type LinkPriceList = {
  type: PricelistTypeEnum.Link;
  link: string;
  oldValue?: string;
};

export type DiffedPriceList = TablePriceList | TextPriceList | LinkPriceList;

export type FormattedPriceList = TablePriceList | InputTextPriceList | InputLinkPriceList;

export type PriceList = DiffedPriceList | FormattedPriceList;

export type ContractWithPricelists = {
  priceLists: PriceList[];
};

type InputTableRow = {
  name: string;
  prices: string[];
};

export type InputTextPriceList = Omit<TextPriceList, 'diff'>;

export type InputLinkPriceList = Omit<LinkPriceList, 'oldValue'>;

export interface InputTablePriceList extends Omit<TablePriceList, 'services'> {
  services: InputTableRow[];
}

export type InputPriceList = InputTextPriceList | InputLinkPriceList | InputTablePriceList;

export type InputContractWithPricelists = {
  priceLists: InputPriceList[];
};

export enum PricelistSubmissionStatus {
  NotSubmitted = 'notSubmitted',
  Submitting = 'submitting',
  Submitted = 'submitted',
}