import { RSAA } from 'redux-api-middleware';

import { RESOURCE_TOKEN_FAILURE, RESOURCE_TOKEN_REQUEST, RESOURCE_TOKEN_SUCCESS } from './resource-toke.action-types';

export const getResourceToken = (endpoint, params) => ({
  [RSAA]: {
    types: [RESOURCE_TOKEN_REQUEST, RESOURCE_TOKEN_SUCCESS, RESOURCE_TOKEN_FAILURE],
    endpoint,
    method: 'POST',
    body: params,
  },
});
