// @flow
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { VirtualizedListComponent } from 'app/shared';
import { ArrayRowContainer } from './row/array-row.container';

import styles from './coupons-mass-charge-form-array.module.scss';
/*:: import type { PaymentField, Props } from './coupons-mass-charge-form-array.component.types';*/
/*:: import type { FieldArrayRenderProps } from 'react-final-form-arrays';*/

const preparePayments = (fields, payments) /*: PaymentField[]*/ => {
  const paymentsWithoutError /*: PaymentField[]*/ = [];
  const paymentsWithError /*: PaymentField[]*/ = [];

  // TODO refactor to usage with filter and map:

  fields.forEach((field /*: string*/, index /*: number*/) /*: void*/ => {
    if (!payments[index] || !payments[index].visible) {
      return;
    }

    if (fields.submitError && !!fields.submitError[index]) {
      paymentsWithError.push({ field, index });
    } else {
      paymentsWithoutError.push({ field, index });
    }
  });

  return paymentsWithError.concat(paymentsWithoutError);
};

const rowRenderer =
  (payments, coupons, newCouponsIds) =>
  ({ index }) => {
    const rowIndex = payments[index].index;
    const field = payments[index].field;
    const coupon = coupons[rowIndex];

    return <ArrayRowContainer coupon={coupon} isNew={newCouponsIds.includes(coupon.id)} field={field} />;
  };

export const MassChargeVirtualizedListComponent = (props /*: Props*/) => (
  <div className={styles.array}>
    <FieldArray
      name="payments"
      subscription={{
        submitError: true,
      }}
    >
      {({ fields } /*: FieldArrayRenderProps*/) => {
        const payments /*: PaymentField[]*/ = preparePayments(fields, props.payments);

        return (
          <VirtualizedListComponent
            rowCount={payments.length}
            rowRenderer={rowRenderer(payments, props.coupons, props.newCouponsIds)}
            defaultRowHeight={150}
            overscanRowCount={5}
            form={props.form}
          />
        );
      }}
    </FieldArray>
  </div>
);
