const toMilliSeconds = seconds => seconds * 1000;

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

export const DECISION_PERIOD_DURATION = toMilliSeconds(20);

export function calculateNextSessionExpirationTime(TTL /*: number*/) /*: number*/ {
  const now = Date.now();
  const requestDelay = toMilliSeconds(2);
  return now + toMilliSeconds(TTL) - requestDelay - DECISION_PERIOD_DURATION;
}
