import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VenueId } from '../../types/venue';
import { ServiceEventFormValues } from './types';
import { HousingServicesVoucher } from './types/housing-services-voucher';

export enum ServiceEventStatus {
  NotSubmitted = 'not-submitted',
  Submitting = 'submitting',
  Submitted = 'submitted', // When this state is reached, we show the success modal
  SubmissionFailed = 'submission-failed',
}

export type ServiceEventState = {
  status: ServiceEventStatus;
};

export interface StateSlice {
  'housing-services-service-event': ServiceEventState;
}

export type ServiceEventSubmissionRequest = {
  venueId: VenueId;
  serviceEvent: ServiceEventFormValues;
  voucher: HousingServicesVoucher;
};

export type ServiceEventSubmissionRequestCompletion = {
  status: ServiceEventStatus.Submitted | ServiceEventStatus.SubmissionFailed;
};

const initialState: ServiceEventState = { status: ServiceEventStatus.NotSubmitted };

const slice = createSlice({
  name: 'housing-services-service-event',
  initialState,
  reducers: {
    serviceEventSubmissionRequested(state, _action: PayloadAction<ServiceEventSubmissionRequest>) {
      switch (state.status) {
        case ServiceEventStatus.NotSubmitted:
        case ServiceEventStatus.SubmissionFailed:
          return {
            ...state,
            status: ServiceEventStatus.Submitting,
          };
        default:
          return state;
      }
    },
    serviceEventSubmissionCompleted(state, action: PayloadAction<ServiceEventSubmissionRequestCompletion>) {
      switch (state.status) {
        case ServiceEventStatus.Submitting:
          return {
            ...state,
            status: action.payload.status,
          };
        default:
          return state;
      }
    },
    newServiceEventInputStarted(state) {
      switch (state.status) {
        case ServiceEventStatus.Submitted:
        case ServiceEventStatus.SubmissionFailed:
          return {
            ...state,
            status: ServiceEventStatus.NotSubmitted,
          };
        default:
          return state;
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
