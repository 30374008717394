// this type replaces hyphens with underscore, so "this-is-test-token" -> "this_is_test_token"
type HyphenToUnderscoreString<T extends string, C extends string = ''> = T extends string
  ? string extends T
    ? string
    : T extends `${infer F}-${infer R}`
    ? HyphenToUnderscoreString<R, C extends '' ? `${F}` : `${C}_${F}`>
    : `${C}_${T}`
  : T;

// "this-is-test-token" -> "THIS_IS_TEST_TOKEN"
type TransformedErrorCode<ErrorCode extends string> = Uppercase<HyphenToUnderscoreString<ErrorCode>>;

// TODO: It would be nice if this made sure that `${Prefix}_${TransformedErrorCode<Code>}` was a TranslationToken
export const errorCodeToTranslationKey = <Prefix extends string, Code extends string>(
  keyPrefix: Prefix,
  errorCode: Code,
): `${Prefix}_${TransformedErrorCode<Code>}` => {
  const postFix = errorCode.toUpperCase().split('-').join('_') as TransformedErrorCode<Code>;
  return `${keyPrefix}_${postFix}`;
};
