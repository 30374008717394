//@flow

import moment from 'moment';

import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type Moment from 'moment';*/

export const convertDate = (dateValue /*: ?Moment | ?string*/) => {
  const date = dateValue || null;

  if (date !== null) {
    return moment(date, DATE_VALUE_FORMAT);
  }

  return date;
};

export const isFunction = (fn /*: Function*/) => typeof fn === 'function';
