import React, { useState } from 'react';
import { Field } from 'redux-form';
import moment from 'moment';
import { SeparatorComponent, Typography } from 'app/shared';
import { handleAnalyticsEvent } from 'app/utils';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { ContractAllowedAction, ReviewAction } from 'app/types/contract';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { PriceListComponentWrapper } from './price-list.component.wrapper';
import { Props } from './pricelists.component.types';
import { PricelistReview } from './review.component';
import { isNotNil } from 'v2/utils/is-nil';

export const PricelistsComponent: React.FC<Props> = (props: Props) => {
  const [previousVersionIsVisible, setPreviousVersionVisible] = useState<boolean>(false);

  const handleApprove = async (contractStartDate?: Date) => {
    if (contractStartDate && moment(contractStartDate).isAfter(moment())) {
      handleAnalyticsEvent('pricelists', 'scheduledApprove', moment(contractStartDate).format(DATE_VALUE_FORMAT));
    } else {
      handleAnalyticsEvent('pricelists', 'immediateApprove');
    }

    const reviewSubmitData = {
      contractId: props.contractId,
      action: ReviewAction.Approve,
      reviewContractBody: contractStartDate ? { contractStartDate } : undefined,
    };
    const success = await props.onSubmit(reviewSubmitData);
    if (success) {
      props.onContractRefreshRequested();
    }
  };

  const handleRequestChange = async (message?: string) => {
    handleAnalyticsEvent('pricelists', 'requestChange');
    const reviewSubmitData = {
      contractId: props.contractId,
      action: ReviewAction.RequestChanges,
      reviewContractBody: message ? { message } : undefined,
    };
    const success = await props.onSubmit(reviewSubmitData);
    if (success) {
      props.onContractRefreshRequested();
    }
  };

  const handleDelete = async () => {
    handleAnalyticsEvent('pricelists', 'delete');
    const reviewSubmitData = {
      contractId: props.contractId,
      action: ReviewAction.Reject,
    };
    const success = await props.onSubmit(reviewSubmitData);
    if (success) {
      props.onContractRefreshRequested();
    }
  };

  return (
    <form>
      {props.fields.map((field, index) => {
        return (
          <Field
            name={field}
            component={PriceListComponentWrapper}
            key={index}
            props={{ isLast: props.fields.length - 1 === index }}
          />
        );
      })}
      <SeparatorComponent />
      {props.showPreviousVersion && (
        <>
          <Typography color="greyDark" type="subtitle">
            <div role="button" onClick={() => setPreviousVersionVisible(!previousVersionIsVisible)}>
              <i className={`fi fi-${previousVersionIsVisible ? 'minus' : 'plus'} pr-2`} />
              <FormattedMessage
                id={`PRICELIST.${previousVersionIsVisible ? 'HIDE_PREVIOUS_VERSION' : 'SHOW_PREVIOUS_VERSION'}`}
              />
            </div>
          </Typography>
          {previousVersionIsVisible &&
            props.fields.map((field, index) => (
              <Field
                name={field}
                component={PriceListComponentWrapper}
                key={index}
                props={{
                  isLast: props.fields.length - 1 === index,
                  showDiff: true,
                }}
              />
            ))}
        </>
      )}
      <SeparatorComponent />
      <PricelistReview
        pending={props.pending}
        allowedActions={mapContractAllowedActionsToReviewActions(props.allowedActions)}
        submitting={props.isLoading}
        onApprove={date => handleApprove(date)}
        onRequestChange={message => handleRequestChange(message)}
        onDelete={() => handleDelete()}
      />
    </form>
  );
};

function mapContractAllowedActionsToReviewActions(actions: ContractAllowedAction[]): ReviewAction[] {
  return actions
    .map(action => {
      switch (action) {
        case ContractAllowedAction.Approve:
          return ReviewAction.Approve;
        case ContractAllowedAction.RequestChanges:
          return ReviewAction.RequestChanges;
        case ContractAllowedAction.Reject:
          return ReviewAction.Reject;
        default:
          return null;
      }
    })
    .filter(isNotNil);
}
