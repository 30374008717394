//@flow

import moment from 'moment';

import { DATE_VALUE_FORMAT } from 'app/constants';
import { calculateDates } from '../calculate-dates/connected-date-range-calculate-dates';

/*:: import type Moment from 'moment';*/
/*:: import type { OnChangeProps as Props } from './connected-date-range-date-change.types';*/

export const onDateChange = (key /*: string*/, date /*: ?Moment*/, props /*: Props*/) => {
  const { startDateKey, validationBounds } = props;
  const value = typeof props.input.value === 'string' ? {} : props.input.value;

  const validation = {
    minDate: validationBounds.minDate ? validationBounds.minDate.format(DATE_VALUE_FORMAT) : null,
    maxDate: validationBounds.maxDate ? validationBounds.maxDate.format(DATE_VALUE_FORMAT) : null,
  };

  if (key === startDateKey && date) {
    return props.input.onChange({
      ...value,
      ...calculateDates(date, props),
      validation,
    });
  }

  date = !!date ? moment(date).format(DATE_VALUE_FORMAT) : null;

  props.input.onChange({
    ...value,
    [key]: date,
    validation,
  });
};
