import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/context/core/core.context';

import { addVenue } from 'app/redux/venues/venues.actions';

import { VenueFormComponent } from 'app/venues/form/venue-form.component';

import { DEFAULT_EMPTY_CONTRACT } from '../../utils';

const mapStateToProps = ({ form, dictionaries, register: { venuesTypes } }, { intl }) => ({
  venuesTypes,
  venueId: formValueSelector('add-venue')({ form }, 'contracts.venueId'),
  services: dictionaries['municipal/services'].services.municipal,
  initialValues: {
    contracts: [DEFAULT_EMPTY_CONTRACT],
    admins: [{}],
  },
  intl,
  dictionary: dictionaries['municipal/services'].services,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: body => dispatch(addVenue(body)).then(() => body.venue.name),
  onSubmitFail: () => window.scrollTo(0, 0),
  onSubmitSuccess: name =>
    dispatch(
      push({
        pathname: '/venues',
        state: {
          success: {
            type: 'ADD_NEW_VENUE',
            payload: name,
          },
        },
      }),
    ),
});

export const VenueFormContainer = compose(
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'add-venue' }),
)(VenueFormComponent);
