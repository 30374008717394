// @flow

import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl';

import { ModalComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';
import { AgreementsDetailsPlaceholder } from './placeholder/agreements-details.placeholder';

/*:: import type { Props } from './agreements-details.component.types';*/
import styles from './agreements-details.module.scss';

export class AgreementsDetailsComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchTerms();
  }

  render() {
    if (this.props.loading) {
      return <AgreementsDetailsPlaceholder onClose={this.props.onClose} />;
    }

    const submitButton = this.props.approved || (
      <ButtonComponent theme="secondary" size="medium" loading={this.props.loading}>
        <FormattedMessage id="CORE.ACCEPT" />
      </ButtonComponent>
    );

    return (
      <ModalComponent
        onClose={this.props.onClose}
        title={this.props.terms.name}
        form={{ onSubmit: this.props.handleSubmit }}
        submit={submitButton}
      >
        <ReactMarkdown source={this.props.terms.content} className={styles.body} />
      </ModalComponent>
    );
  }
}
