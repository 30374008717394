import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { CouponsDownloadExcelFileComponent } from './coupons-downloads-excel-file.component';

import { download } from 'app/redux/downloads/downloads.actions';
import { handleAnalyticsEvent } from 'app/utils';

const form = 'coupons-download-excel-file';
const localStorageKey = 'sidebarSelectedColumnsCache';

const cacheColumns = columns => localStorage.setItem(localStorageKey, columns.toString());

const resolveColumns = (columnsCache, availableColumns, activeColumns) => {
  if (columnsCache) {
    const filteredCachedColumns = columnsCache.split(',').filter(column => availableColumns.includes(column));

    localStorage.setItem(localStorageKey, filteredCachedColumns.join(','));

    return filteredCachedColumns;
  }

  return activeColumns;
};

const mapsStateToProps = (_, { columns }) => {
  const resolvedColumns = resolveColumns(
    localStorage.getItem(localStorageKey),
    get(columns, 'list', []),
    get(columns, 'active', []),
  );

  return { initialValues: { columns: resolvedColumns } };
};

const mapDispatchToProps = (dispatch, { intl: { locale }, coupons }) => ({
  onChange: debounce(({ columns }) => cacheColumns(columns), 500),
  onSubmit: ({ columns }) => {
    const params = { columns: columns };

    if (coupons.length) {
      params.coupons = coupons;
    }

    handleAnalyticsEvent('vouchers', 'download', 'excel list');

    return dispatch(
      download({
        path: 'service-coupons',
        locale,
        filters: 'filter-coupons',
        params,
      }),
    );
  },
});

export const CouponsDownloadExcelFileContainer = compose(
  injectIntl,
  connect(mapsStateToProps, mapDispatchToProps),
  reduxForm({ form, enableReinitialize: true, keepDirtyOnReinitialize: true }),
)(CouponsDownloadExcelFileComponent);
