import { FieldArray, SubmitHandler } from 'redux-form';

import { SeparatorComponent, Typography } from 'app/shared';
import { ContractStatus } from 'app/constants';
import { Contract } from 'app/types/contract';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { PricelistsContainer } from '../pricelists/pricelists.container';
import { ContractCardDetailsComponent } from '../../contract-card-details.component';
import { ContractCardHeaderComponent } from './contract-card-header.component';

type Props = {
  contract: Contract;
  contractField: string;
  handleSubmit: SubmitHandler;
  onContractRefreshRequested: () => void;
};

export const ContractComponent = ({ contract, contractField, handleSubmit, onContractRefreshRequested }: Props) => {
  return (
    <div>
      <CardComponent>
        <ContractCardHeaderComponent contract={contract} />
        <SeparatorComponent />
        <CardBodyComponent>
          <ContractCardDetailsComponent context="organizer" contract={contract} />
          <SeparatorComponent />
          <Typography color="greyDark" id="PRICELIST.PRICES_HEADER" type="subtitle" />
          <FieldArray
            name={`${contractField}.priceLists`}
            component={PricelistsContainer}
            props={{
              pending: contract.status === ContractStatus.Pending,
              handleSubmit,
              contractId: contract.id,
              allowedActions: contract.allowedActions ?? [],
              onContractRefreshRequested: onContractRefreshRequested,
              showPreviousVersion: Boolean(
                contract.previousContracts?.length && [ContractStatus.Pending].includes(contract.status),
              ),
            }}
          />
        </CardBodyComponent>
      </CardComponent>
      <SeparatorComponent />
    </div>
  );
};
