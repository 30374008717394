import { useCallback } from 'react';
import { ServiceEvent } from 'v2/types/postpay';
import { TabularItemListComponent } from 'v2/utils/postpay/tabular-item-list';

export type ServiceEventListComponentProps = {
  serviceEvents: ServiceEvent[];
};

export function ServiceEventListComponent({ serviceEvents }: ServiceEventListComponentProps) {
  const getItemProperties = useCallback((item: ServiceEvent) => item.properties, []);
  return <TabularItemListComponent items={serviceEvents} getItemProperties={getItemProperties} />;
}
