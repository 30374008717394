import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { withCoreContext } from 'app/context/core/core.context';

import { CouponsCreateFormCustomersComponent } from './coupons-create-form-customers.component';

export const CouponsCreateFormCustomersContainer = compose(
  injectIntl,
  withCoreContext,
)(CouponsCreateFormCustomersComponent);
