import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withAuthorization, withPagination, withPermissions } from 'app/common';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { fetchCouponTypes } from 'app/redux/types/types.actions';

import { CouponsTypesListComponent } from './coupons-types-list.component';

const mapStateToProps = ({ types: { list: types, meta }, users: { business } }) => ({
  business,
  meta,
  types,
  loading: !types,
});

const mapDispatchToProps = dispatch => ({
  fetch: params => dispatch(singleConcurrentRequestAction('coupons-types-list', fetchCouponTypes(params))),
});

export const CouponsTypesListContainer = compose(
  withAuthorization(['serviceCouponTypes', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withPagination,
  withPermissions,
)(CouponsTypesListComponent);
