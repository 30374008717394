import React from 'react';

import { LanguageSwitcherContainer } from 'app/core/language-switcher/language-switcher.container';

import styles from './sidebarlessTheme.module.scss';

export const SidebarlessThemeComponent = ({ children }) => (
  <>
    <div className={styles.header}>
      <LanguageSwitcherContainer />
    </div>
    <div className="container">{children}</div>
  </>
);
