// @flow
import { memoize } from 'lodash';
import React, { PureComponent } from 'react';
import calculationsDecorator from 'final-form-calculate';
import { FormattedMessage } from 'react-intl';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { HtmlForm, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { Modal } from 'app/shared/modal-new/modal.container';
import { currencyFormatter, scrollToElement, shouldBlock } from 'app/utils';

import { ResultActionModalComponent } from 'app/shared/result-action-modal/result-action-modal.component';
import { LeaveConfirmationContainer } from 'app/core/confirm/leave/leave.confirmation.container';
/*:: import type { VatRate } from '../../details/charge/form/services/row/services-row.types';*/
/*:: import type { Coupon } from '../../details/coupons-details.types';*/
import { CouponsMassChargePlaceholder } from './placeholder/coupons-mass-charge.placeholder';
import { getIsPaymentVisible } from './utils/coupons-filter.utils';
import { MassChargeVirtualizedListComponent } from './virtualized/coupons-mass-charge-form-array.component';
import { CouponsMassChargeHeaderComponent } from './header/coupons-mass-charge-header';

/*:: import type { FormPayment, PaymentsFormValues, Props, State } from './coupons-mass-charge-form.component.types';*/
/*:: import type { FormApi } from 'final-form';*/
/*:: import type { FormRenderProps } from 'react-final-form';*/
import styles from './coupons-mass-charge-form.module.scss';

const INITIAL_PAYMENT_VISIBILITY_FILTERS = {
  showChargeable: true,
  showInvalidated: false,
  showValidInFuture: false,
};

export const getInitialPaymentValues = (
  coupons /*: Coupon[]*/,
  vatRatesDictionary /*: VatRate[]*/,
) /*: FormPayment[]*/ => {
  const hasDescriptiveServices = coupon => {
    return coupon.descriptiveServices && coupon.descriptiveServices.length > 0;
  };

  return coupons.map((coupon /*: Coupon*/) /*: FormPayment*/ => ({
    accountNumber: coupon.id,
    active: !hasDescriptiveServices(coupon),
    canMassDebit: !hasDescriptiveServices(coupon),
    amount: coupon.maxTransaction,
    amountCalculations: [{ amount: 1 }],
    serviceProduced: {},
    vatRate: coupon.vatRatesEnabled ? vatRatesDictionary[0].rate.toString() : undefined,
    numberOfPriceUnits: coupon.priceUnit ? '' : undefined,
    visible: getIsPaymentVisible(
      INITIAL_PAYMENT_VISIBILITY_FILTERS.showChargeable,
      INITIAL_PAYMENT_VISIBILITY_FILTERS.showValidInFuture,
      INITIAL_PAYMENT_VISIBILITY_FILTERS.showInvalidated,
      coupon,
    ),
  }));
};

export class CouponsMassChargeFormComponent extends PureComponent /*:: <Props, State>*/ {
  state = {
    isConfirmationModalOpen: false,
    isResultModalOpen: false,
  };

  // We memoize the initial payment form values because re-computing them causes an unwanted form reset (despite
  // the initial payment values staying the same, see https://stackoverflow.com/questions/54635276)
  initialPaymentsFormValues = memoize/*:: <[Coupon[], VatRate[]], FormPayment[]> */(
    (coupons, vatRatesDictionary) => coupons && getInitialPaymentValues(coupons, vatRatesDictionary),
  );

  componentDidMount() {
    this.props.venueCouponsMetadataClear();
  }

  handleSelectAll =
    ({ getState, change } /*: FormApi*/) =>
    ({ target: { checked } } /*: SyntheticInputEvent<HTMLInputElement>*/) => {
      const { values: { payments = {} } = {} } = getState();
      const newPayments = payments.map(item => ({ ...item, active: item.canMassDebit ? checked : false }));
      change('payments', newPayments);
    };

  handleSubmit = async (values /*: PaymentsFormValues*/) => {
    const submitErrors = this.props.validateForm(values);

    if (submitErrors !== undefined) {
      this.toggleResultModal();
      scrollToElement(['.alert.alert-danger', '.ReactVirtualized__Grid__innerScrollContainer'], 150);

      return submitErrors;
    }

    if (this.state.isConfirmationModalOpen) {
      this.toggleResultModal();
      this.toggleConfirmationModal();

      return await this.props.onSubmit({ values });
    }

    this.toggleConfirmationModal();
  };

  toggleConfirmationModal = () =>
    this.setState(prevState => ({ isConfirmationModalOpen: !prevState.isConfirmationModalOpen }));

  toggleResultModal = () => this.setState(prevState => ({ isResultModalOpen: !prevState.isResultModalOpen }));

  calculateChargingSum = (payments /*: FormPayment[]*/ = []) => {
    return payments.reduce((acc, payment /*: FormPayment*/) => {
      return payment.active && payment.visible && payment.amount
        ? // TODO: Use currency-formatter.util here
          acc + parseFloat(`${payment.amount}`.replace(/,/g, '.'))
        : acc;
    }, 0.0);
  };

  confirmOptions = ({ submitting, form } /*: { form: FormApi, submitting: ?boolean }*/) => [
    {
      label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
      onClick: this.toggleConfirmationModal,
      theme: 'primary-outline',
      arrowBack: true,
    },
    {
      label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
      loading: submitting,
      onClick: form.submit,
    },
  ];

  isAllChecked = (payments /*: FormPayment[]*/) /*: boolean*/ => {
    return !payments.some((payment /*: FormPayment*/, index /*: number*/) => {
      return !this.props.coupons[index].invalidated && !payment.active && payment.canMassDebit;
    });
  };

  getSelectedItems = (payments /*: FormPayment[]*/ = []) /*: FormPayment[]*/ =>
    payments.filter((payment /*: FormPayment*/) => payment.active && payment.visible);

  visiblePaymentRowsDecorator = calculationsDecorator({
    field: ['showChargeable', 'showInvalidated', 'showValidInFuture'],
    updates: (value /*: boolean*/, name /*: string*/, formValues) => {
      // $FlowFixMe
      const { showChargeable, showInvalidated, showValidInFuture, payments } = formValues;
      return {
        // prettier-ignore
        payments: payments.map/*:: <FormPayment>*/((payment/*: FormPayment*/, index) => ({
          ...payment,
          visible: getIsPaymentVisible(showChargeable, showValidInFuture, showInvalidated, this.props.coupons[index]),
        })),
      };
    },
  });

  getHeaderProps = (payments /*: FormPayment[]*/, form /*: FormApi*/) => ({
    total: this.props.coupons.length,
    selected: this.getSelectedItems(payments).length,
    coupons: this.props.coupons,
    onSelectAll: this.handleSelectAll(form),
    isAllChecked: this.isAllChecked(payments),
  });

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={arrayMutators}
        initialValues={{
          payments: this.initialPaymentsFormValues(this.props.coupons, this.props.vatRatesDictionary),
          reference: '',
          ...INITIAL_PAYMENT_VISIBILITY_FILTERS,
        }}
        subscription={{
          submitting: true,
          initialValues: true,
          submitFailed: true,
          submitError: true,
          submitSucceeded: true,
          values: true,
          error: true,
        }}
        decorators={[this.visiblePaymentRowsDecorator]}
      >
        {({ handleSubmit, form, submitError, submitFailed, submitting, error } /*: FormRenderProps*/) => {
          const formState = form.getState();
          const { payments } = formState.values || {};

          // TODO: Split confirmation modal to a separate component
          // TODO: Split result modal to a separate component
          return (
            <>
              <LeaveConfirmationContainer block={shouldBlock(formState)} />

              <CardComponent className="pt-0">
                <>
                  {this.props.loading && <CouponsMassChargePlaceholder />}
                  {!this.props.loading && !!payments && (
                    <>
                      {!!payments.length && (
                        <CouponsMassChargeHeaderComponent {...this.getHeaderProps(payments, form)} />
                      )}
                      <HtmlForm onSubmit={handleSubmit}>
                        <CardBodyComponent>
                          <div className={styles.container}>
                            <MassChargeVirtualizedListComponent
                              payments={payments}
                              coupons={this.props.coupons}
                              newCouponsIds={this.props.newCouponsIds}
                              name="payments"
                              form={form}
                            />
                          </div>
                        </CardBodyComponent>
                      </HtmlForm>
                    </>
                  )}

                  <Modal
                    confirmOptions={this.confirmOptions({
                      submitting,
                      form,
                    })}
                    isOpen={this.state.isConfirmationModalOpen}
                    title="COUPONS.MASS_CHARGE.CHARGE_CONFIRM_TITLE"
                    size="small"
                  >
                    <CardBodyComponent>
                      <FormattedMessage
                        id="COUPONS.MASS_CHARGE.CHARGE_SUMMARY"
                        tagName="span"
                        values={{
                          amount: this.getSelectedItems(payments).length,
                          sum: (
                            <Typography tag="strong" color="primary">
                              {currencyFormatter.format(this.calculateChargingSum(payments))}
                            </Typography>
                          ),
                        }}
                      />
                    </CardBodyComponent>
                  </Modal>
                </>
              </CardComponent>

              <Modal
                isOpen={this.state.isResultModalOpen}
                component={ResultActionModalComponent}
                componentProps={{
                  isLoading: submitting,
                  isSuccess: !submitFailed,
                  onClose: this.toggleResultModal,
                  messages: {
                    success: {
                      title: this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.CHARGE.MODAL.SUCCESS' }),
                    },
                    failure: {
                      title: this.props.intl.formatMessage({
                        id: error || submitError || 'COUPONS.DETAILS.CHARGE.MODAL.FAILURE',
                      }),
                    },
                  },
                }}
              />
            </>
          );
        }}
      </Form>
    );
  }
}
