import { compose } from 'redux';
import { connect } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { get } from 'lodash';

import { withAuthorization, withPermissions } from 'app/common';
import { CouponsDetailsComponent } from './coupons-details.component';
import { fetchCoupon } from 'app/redux/coupon/coupon.actions';
import { getCouponDetails } from 'app/redux/coupons/coupons.selectors';

const mapStateToProps = ({ users: { business }, ...state }) => {
  const { coupon, isLoading: loading, transactions } = getCouponDetails(state);
  const couponTransactions = get(transactions, ['transactions'], []);

  return {
    business,
    coupon,
    loading,
    transactions: couponTransactions,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    location: { state },
    match: {
      params: { id },
    },
  },
) => ({
  onBack: () => {
    if (state && state.previousPath) {
      dispatch(
        push({
          pathname: state.previousPath,
        }),
      );
    } else {
      dispatch(goBack());
    }
  },
  fetchCoupon: () => dispatch(fetchCoupon(id)),
});

export const CouponsDetailsContainer = compose(
  withAuthorization(['serviceCouponDetails', 'read']),
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDetailsComponent);
