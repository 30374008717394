// @flow
import { push } from 'connected-react-router';

import { reportMessage } from 'app/sentry/sentry';

// $FlowFixMe
export const notFoundMiddleware = store => next => action => {
  if (action && action.payload) {
    if (404 === action.payload.status) {
      const currentPath = store.getState().router.location.pathname;

      reportMessage('Redirection to 404 page', {
        currentPath,
        serverPayload: action.payload,
        status: action.payload.status,
      });

      store.dispatch(push('/not-found', { fromMiddleware: true }));
    }
  }

  return next(action);
};
