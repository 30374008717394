// @flow

import React, { PureComponent } from 'react';

import { ConfirmComponent } from '../confirm.component';

/*:: import type { Props, State } from './leave.confirmation.component.types';*/

export class LeaveConfirmationComponent extends PureComponent /*:: <Props, State>*/ {
  /*:: unblock: Function;*/

  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      show: false,
      nextLocation: {},
    };
  }

  componentDidMount() {
    this.unblock = this.props.history.block(nextLocation => {
      const pathIsDifferent = this.props.history.location.pathname !== nextLocation.pathname;
      const block = this.props.hasOwnProperty('block') ? this.props.block : true;

      if (!this.state.show && nextLocation.pathname !== '/login' && pathIsDifferent && block) {
        this.setState({
          show: true,
          nextLocation,
        });
        return false;
      }

      return true;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  toggleModal = () => {
    this.setState(prevState => ({
      show: !prevState.show,
    }));
  };

  onConfirm = () => {
    this.unblock();
    return {
      then: () => {
        this.props.onConfirm(this.state.nextLocation);
        return { catch: () => {} };
      },
    };
  };

  render() {
    return (
      <ConfirmComponent
        onConfirm={this.onConfirm}
        show={this.state.show}
        title="CORE.PROMPT"
        onCancel={this.toggleModal}
      />
    );
  }
}
