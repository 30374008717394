// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withAuthorization } from 'app/common';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchVatArchives, fetchVatArchivesMonths } from 'app/redux/archive/archive.actions';
import { VatArchiveListComponent } from './vat-archive-list.component';

const mapStateToProps = ({
  archive: { list, months: years },
  users: {
    business,
    current: { businesses },
  },
}) => ({
  business,
  loading: !list || !years,
  list,
  years, //TODO REFACTOR
  subBusinesses: businesses
    .filter(({ parentId }) => business.id === parentId)
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.parentId]: curr,
      }),
      {},
    ),
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchVatArchives: (year, month) => dispatch(fetchVatArchives(year, month)),
  fetchVatArchivesMonths: () => dispatch(fetchVatArchivesMonths()),
  onDownload: (year /*: string*/, month /*: string*/, accounts /*: number[]*/) => path =>
    dispatch(
      download({
        path: `vat-archive/${year}/${month}${path ? path : ''}`,
        locale,
        filters: 'filter-vat-archive',
        params: { accounts },
      }),
    ),
});

export const VatArchiveListContainer = compose(
  withAuthorization(['vatArchive', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(VatArchiveListComponent);
