import { Typography } from 'app/shared';
import classNames from 'classnames';
import styles from './details-field.component.module.scss';

type Label =
  | {
      id: TranslationKey;
    }
  | { value: string };

type DetailsFieldProperties = {
  label: Label;
  children: React.ReactNode;
  highlight?: boolean;
};

export const DetailsField = ({ label, children, highlight = false }: DetailsFieldProperties) => (
  <div className="row mb-2 mb-lg-1">
    {'id' in label ? (
      <Typography className={classNames('col-lg', styles.label)} color="greyDark" id={label.id}></Typography>
    ) : (
      <Typography className={classNames('col-lg', styles.label)} color="greyDark">
        {label.value}
      </Typography>
    )}

    {
      <Typography className={classNames('col-lg', { [styles.highlight]: highlight })} color="greyDark">
        {children}
      </Typography>
    }
  </div>
);
