import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { SidebarGeneralComponent } from './sidebar-general.component';

const mapStateToProps = ({
  users: { business },
  coupons: {
    meta: { newCouponsCounter },
  },
}) => ({
  business,
  newCouponsCounter: newCouponsCounter,
});

export const SidebarGeneralContainer = compose(
  injectIntl,
  withPermissions,
  connect(mapStateToProps),
)(SidebarGeneralComponent);
