import { handleActions } from 'redux-actions';

import * as types from './types.action-types';

const defaultState = {
  entities: {},
};

export const typesReducer = handleActions(
  {
    [types.FETCH_COUPON_TYPES_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_COUPON_TYPES_SUCCESS]: (state, { payload: { data: list, meta } }) => ({
      ...state,
      list,
      meta,
    }),
    [types.FETCH_COUPON_TYPE_REQUEST]: (state, { meta: { type } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [type]: undefined,
      },
    }),
    [types.FETCH_COUPON_TYPE_SUCCESS]: (state, { meta: { type }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [type]: entity,
      },
    }),
  },
  defaultState,
);
