// @flow

import React, { Component } from 'react';

import { ListComponent, SeparatorComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import { OrdersHistoryTableComponent } from './table/orders-history-table.component';
import { OrdersHistoryTileComponent } from './tile/orders-history-tile.component';
import { OrdersHistoryPlaceholder } from './placeholder/orders-history.placeholder';

/*:: import type { Props } from './orders-history.component.types';*/

export class OrdersHistoryComponent extends Component /*:: <Props>*/ {
  componentDidMount() /*: void*/ {
    this.props.fetchOrderHistory();
  }

  componentDidUpdate(prevProps /*: Props*/) /*: void*/ {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchOrderHistory();
    }
  }

  render() {
    const { orderHistory } = this.props;

    return (
      <>
        {this.props.loading && <OrdersHistoryPlaceholder />}
        {this.props.loading || (
          <CardComponent>
            <CardHeaderComponent title="ORDERS.HISTORY.TITLE" />
            <SeparatorComponent />
            <CardBodyComponent>
              <ListComponent
                list={orderHistory}
                isCollapsed={false}
                limit={6}
                table={OrdersHistoryTableComponent}
                tile={OrdersHistoryTileComponent}
                collapsible
              />
            </CardBodyComponent>
          </CardComponent>
        )}
      </>
    );
  }
}
