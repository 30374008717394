import React, { useMemo } from 'react';
import MediaQuery from 'react-responsive';
import get from 'lodash/get';

import { SeparatorComponent, Typography, TypographyConditional } from 'app/shared';
import { BusinessNameComponent } from '../business-name/business-name.component';
import { BackComponent } from 'app/auth/back/back.component';

import styles from './page-header.module.scss';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'app/hooks/useIntl/useIntl';
import { useIsInitialRoute } from 'local-browser-history';
import { TranslationKey } from 'v2/types/translations';

type Props = {
  showBusinessName?: boolean;
  title?: TranslationKey | { id: TranslationKey; values: Record<string, unknown> };
  subtitle?: TranslationKey;
  backLink?: string;
  onBack?: () => void;
  children?: React.ReactNode;
  button?: React.ReactNode;
  separatorHeight?: number;
};

export const PageHeaderComponent = ({
  showBusinessName = true,
  title,
  subtitle,
  backLink,
  onBack,
  children,
  button,
  separatorHeight,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();

  const isInitialRoute = useIsInitialRoute();
  const backAction = useMemo(() => {
    if (backLink) {
      return { to: backLink };
    }
    if (onBack) {
      return { onClick: onBack };
    }
  }, [backLink, onBack]);

  const showArrow = !isInitialRoute && backAction && get(location, ['state', 'showArrow'], true);

  return (
    <>
      <header>
        <div className={styles.top}>
          <MediaQuery minWidth={992}>{button}</MediaQuery>
        </div>
        <div className="d-flex align-items-start">
          {showArrow && <BackComponent {...backAction} className={styles.backButton} />}

          <div>
            {children}

            {title && (
              <TypographyConditional
                content={title}
                typographyProps={{
                  tag: 'h2',
                  type: 'header',
                }}
              />
            )}

            {subtitle && (
              <Typography type="smallText" color="greyDark">
                {intl.formatMessage({ id: subtitle })}
              </Typography>
            )}

            {showBusinessName && <BusinessNameComponent />}
            <MediaQuery maxWidth={991}>{button}</MediaQuery>
          </div>
        </div>
      </header>
      <SeparatorComponent height={separatorHeight} />
    </>
  );
};
