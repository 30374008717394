// @flow
import { normalize, replaceComma, replaceNoValueToEmptyString } from 'app/utils';
import isNil from 'lodash/isNil';

export const numberOfPriceUnitsValidator = (value /*: any*/) /*: ?string*/ => {
  if (isNil(value)) {
    return 'VALIDATION.REQUIRED';
  }

  const priceUnitsValue /*: number*/ = Number(normalize(value, [replaceNoValueToEmptyString, replaceComma]));

  if (priceUnitsValue === 0) {
    return 'VALIDATION.POSITIVE_NUMBER';
  }

  return undefined;
};
