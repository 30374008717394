import { checkPermissions } from 'app/utils';
import { V1Permissions, V1RootState } from 'app/v1.types';
import { useSelector } from 'react-redux';
import { AvailableUsecasesState, AvailableUsecasesStatus } from 'v2/organizational-unit/available-usecases-slice';
import { useV1Selector } from 'v2/utils/redux-utils';
import { V2RootState } from 'v2/v2.reducer';
import { SidebarAccountContainer } from './account/sidebar-account.container';
import { SidebarAdditionalContainer } from './additional/sidebar-additional.container';
import { SidebarBalanceContainer } from './balance/sidebar-balance.container';
import { SidebarCouponsContainer } from './coupons/sidebar-coupons.container';
import { SidebarCustomerServiceToolsContainer } from './customer-service-tools/sidebar-customer-service-tools.container';
import { SidebarGeneralContainer } from './general/sidebar-general.container';
import { SidebarSettingsContainer } from './settings/sidebar-settings.container';
import { VaanasystemSidebarSections } from './v2/vaanasystem-sidebar-sections.component';

type SidebarSectionsComponentProps = {
  isUserApproved: boolean | undefined;
  sidebarCollapsed: boolean | undefined;
  permissions: unknown;
  isPostPaymentAccount: boolean;
};

const SidebarSectionsComponent = (props: SidebarSectionsComponentProps) => (
  <>
    {props.isUserApproved && (
      <>
        {checkPermissions(props.permissions, ['municipalAccount', 'read']) && !props.isPostPaymentAccount && (
          <SidebarBalanceContainer collapsed={props.sidebarCollapsed} />
        )}
        <SidebarGeneralContainer />
        <SidebarCouponsContainer />
        <SidebarAccountContainer />
        <SidebarAdditionalContainer />
        <SidebarCustomerServiceToolsContainer />
      </>
    )}
    <SidebarSettingsContainer />
  </>
);

type SidebarSectionsProps = {
  sidebarCollapsed: boolean | undefined;
};

export const SidebarSections: React.FC<SidebarSectionsProps> = ({ sidebarCollapsed }) => {
  const isUserApproved = useV1Selector(state => state.users.isApproved);
  const business = useV1Selector(state => state.users.business);
  const isPostPaymentAccount = useV1Selector(state => state.accounts.isPostPaymentAccount);
  const v1Permissions = useSelector<V1RootState, V1Permissions>(state => {
    return (state.permissions && business ? state.permissions[business.id] : {}) ?? {};
  });

  const availableVaanasystemUsecasesState = useSelector<V2RootState, AvailableUsecasesState[string]>(state => {
    return business !== undefined ? state['organizational-unit-available-usecases'][business.id.toString()] : undefined;
  });

  if (!availableVaanasystemUsecasesState) {
    return null;
  }

  switch (availableVaanasystemUsecasesState.status) {
    case AvailableUsecasesStatus.Found: {
      const { availableUsecases } = availableVaanasystemUsecasesState;
      return (
        <VaanasystemSidebarSections availableVaanasystemUsecases={availableUsecases} v1Permissions={v1Permissions} />
      );
    }
    case AvailableUsecasesStatus.NotFound:
      return (
        <SidebarSectionsComponent
          permissions={v1Permissions}
          isUserApproved={isUserApproved}
          sidebarCollapsed={sidebarCollapsed}
          isPostPaymentAccount={isPostPaymentAccount}
        />
      );
    case AvailableUsecasesStatus.Reloading: {
      return availableVaanasystemUsecasesState.originalStatus === AvailableUsecasesStatus.Found ? (
        <VaanasystemSidebarSections
          availableVaanasystemUsecases={availableVaanasystemUsecasesState.availableUsecases}
          v1Permissions={v1Permissions}
        />
      ) : (
        <SidebarSectionsComponent
          permissions={v1Permissions}
          isUserApproved={isUserApproved}
          sidebarCollapsed={sidebarCollapsed}
          isPostPaymentAccount={isPostPaymentAccount}
        />
      );
    }
    case AvailableUsecasesStatus.Loading:
    default:
      // TODO: loading bar?
      return null;
  }
};
