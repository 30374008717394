// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, TileComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './vat-archive-details-tile.component.types';*/

export class VatArchiveDetailsTileComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <h3>{this.props.tile.id}</h3>

        <div className="row">
          <div className="col-md-4">
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.DATE" tagName="small" />
            {dateFormatter(this.props.tile.date)}
          </div>
          {this.props.type === 'TYPE_MUNICIPAL' && (
            <div className="col-md-8">
              <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.SELLER_NAME" tagName="small" />
              {this.props.tile.sellerName}
            </div>
          )}
          {this.props.type === 'TYPE_VENUE' && (
            <div className="col-md-8">
              <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.BUYER_NAME" tagName="small" />
              {this.props.tile.buyerName}
            </div>
          )}
          <div className="col-md-6">
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.HEADERS.AMOUNT" tagName="small" />
            {currencyFormatter.format(this.props.tile.amount)}
          </div>
        </div>
        <div className="buttons">
          <ButtonComponent size="small" onClick={() => this.props.onDownload(this.props.tile.id)}>
            <i className="fi fi-download-list" />
            <FormattedMessage id="VAT_ARCHIVE.DETAILS.DOWNLOAD" />
          </ButtonComponent>
        </div>
      </TileComponent>
    );
  }
}
