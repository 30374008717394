import { ContractStatus, ContractSubStatus } from 'app/constants';

import { StatusComponent, Typography } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import React from 'react';

interface Props {
  contractStatus: ContractStatus | ContractSubStatus;
  isProvider?: boolean;
}

export const ContractStatusComponent: React.FC<Props> = ({ contractStatus, isProvider }) => {
  return (
    <Typography type="bodyText" color="greyDark">
      <StatusComponent
        status={contractStatus}
        size="small"
        iconSet={isProvider ? 'provider' : 'organizer'}
        className="mr-1"
      />
      <FormattedMessage id={`PRICELIST.STATUS_VALUE.${contractStatus}`} />
    </Typography>
  );
};
