// @flow

import React, { PureComponent } from 'react';

import { ListComponent } from 'app/shared/list/list.component';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import { OrdersHistoryTablePlaceholder } from './table/orders-history-table.placeholder';
import { OrdersHistoryTilePlaceholder } from './tile/orders-history-tile.placeholder';

export class OrdersHistoryPlaceholder extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <CardComponent>
        <CardHeaderComponent title="ORDERS.HISTORY.TITLE" className="mb-5" />
        <CardBodyComponent>
          <ListComponent
            list={[...new Array(5)]}
            table={OrdersHistoryTablePlaceholder}
            tile={OrdersHistoryTilePlaceholder}
          />
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
