import { ReactNode, useEffect, useState } from 'react';
import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { TranslationKey } from 'v2/types/translations';
import { RejectConfirmation } from '../reject-action/reject.confirmation.component';
import { ApproveConfirmation } from '../approve-action/approve.component';
import { RequestChange } from '../request-change-action/request-change.component';
import { ReviewAction } from 'app/types/contract';

interface Props {
  submitting: boolean;
  pending: boolean;
  allowedActions: Array<ReviewAction>;
  onApprove: (contractStartDate?: Date) => void;
  onRequestChange: (message?: string) => void;
  onDelete: () => void;
}

type ActionObject = {
  action: ReviewAction;
  translation: TranslationKey;
  component: ReactNode;
};

export const PricelistReview: React.FunctionComponent<Props> = ({
  allowedActions,
  submitting,
  pending,
  onApprove,
  onRequestChange,
  onDelete,
}: Props) => {
  const [currentAction, setCurrentAction] = useState<ReviewAction | null>(null);

  useEffect(() => {
    if (!submitting) {
      setCurrentAction(null);
    }
  }, [submitting]);

  const onCancel = () => setCurrentAction(null);

  const actionList: Array<ActionObject> = [
    {
      action: ReviewAction.Reject,
      translation: 'PRICELISTS.DETAILS.DELETE',
      component: <RejectConfirmation onDelete={onDelete} onCancel={onCancel} submitting={submitting} />,
    },
    {
      action: ReviewAction.RequestChanges,
      translation: 'PRICELISTS.DETAILS.REQUEST_CHANGE',
      component: (
        <RequestChange
          onRequestChange={message => onRequestChange(message)}
          onCancel={onCancel}
          submitting={submitting}
        />
      ),
    },
    {
      action: ReviewAction.Approve,
      translation: 'PRICELISTS.DETAILS.APPROVE',
      component: (
        <ApproveConfirmation
          onApprove={onApprove}
          onCancel={onCancel}
          displayHelpText={pending && !allowedActions.includes(ReviewAction.RequestChanges)}
          submitting={submitting}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        {actionList.map(actionObject =>
          allowedActions.includes(actionObject.action) ? (
            <ButtonComponent
              grouped
              key={actionObject.action}
              disabled={submitting}
              theme={currentAction !== actionObject.action ? 'primary-outline' : 'primary'}
              className="m-2"
              onClick={() => setCurrentAction(actionObject.action)}
            >
              <FormattedMessage id={actionObject.translation} />
            </ButtonComponent>
          ) : null,
        )}
      </div>
      {actionList.map(
        actionObject =>
          currentAction === actionObject.action && <div key={actionObject.action}>{actionObject.component}</div>,
      )}
    </>
  );
};
