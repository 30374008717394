import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reset } from 'redux-form';
import { get } from 'lodash';

import { withAuthorization } from 'app/common';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { fetchTransactions } from 'app/redux/accounts/accounts.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { download } from 'app/redux/downloads/downloads.actions';

import { AccountTransactionsComponent } from 'app/accounts/transactions/account-transactions.component';

const mapStateToProps = ({ accounts, dictionaries, permissions, users: { business } }) => ({
  business,
  account: get(accounts, ['list'], {}),
  fetching: get(accounts, ['entities', 'municipal-account', 'transactions', 'fetching']),
  loading:
    !get(accounts, ['entities', 'municipal-account', 'transactions']) ||
    !dictionaries['transaction-types'] ||
    get(accounts, ['loading']),
  loadingAccount: get(accounts, ['loading']),
  meta: get(accounts, ['entities', 'municipal-account', 'transactions', 'meta'], {}),
  permissions: permissions[business.id] || {},
  transactions: get(accounts, ['entities', 'municipal-account', 'transactions', 'list'], []),
  types: get(dictionaries, ['transaction-types', 'municipal']),
});

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchDictionary: () => dispatch(fetchDictionary('transaction-types')),
  fetchTransactions: params =>
    dispatch(
      singleConcurrentRequestAction('account-transactions', fetchTransactions('municipal-account', params, true)),
    ),
  onDownload: () =>
    dispatch(
      download({
        path: `transactions/municipal-account`,
        locale,
        filters: 'filter-account-transactions',
      }),
    ),
  resetFilters: () => dispatch(reset('filter-account-transactions')),
});

export const AccountTransactionsContainer = compose(
  withAuthorization(['municipalAccount', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
)(AccountTransactionsComponent);
