import { call, ForkEffect, put, takeLeading } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions, ServiceEventStatus, ServiceEventSubmissionRequest } from './service-event-slice';
import { StatusCodes } from 'http-status-codes';
import { TransformServiceEventToPayments } from './backend-services/transform-to-payments';
import { Payment } from '../../types/payments';
import { fetchCoupon } from 'app/redux/coupon/coupon.actions';
import { notificationPush } from 'app/redux/notifications/notifications.actions';
import { notificationTypes } from 'dictionaries/notifications.dictionary';
import {
  handleAnalyticsEvent,
  HandleAnalyticsEvent,
} from 'app/utils/handle-analytics-event/handle-analytics-event.util';
import { HousingServicesApiClient, MakeVenuePaymentResponse } from 'v2/api/housing-services-api-client';

export function* housingServicesServiceEventSaga(
  apiClient: HousingServicesApiClient,
  convertServiceEventToPayments: TransformServiceEventToPayments,
): Generator<ForkEffect> {
  function* serviceEventSubmissionRequest(action: PayloadAction<ServiceEventSubmissionRequest>) {
    const payments: Payment[] = yield call(
      convertServiceEventToPayments,
      action.payload.voucher,
      action.payload.venueId,
      action.payload.serviceEvent,
    );

    yield call<HandleAnalyticsEvent<'serviceEventSubmission'>>(
      handleAnalyticsEvent,
      'serviceEventSubmission',
      'housingServicesSubmitServiceEvent',
      action.payload.voucher.serviceArea,
    );
    const response: MakeVenuePaymentResponse = yield call(apiClient.makeVenuePayment, payments);
    const status = response.status;
    switch (status) {
      case StatusCodes.NO_CONTENT:
        yield put(
          actions.serviceEventSubmissionCompleted({
            status: ServiceEventStatus.Submitted,
          }),
        );
        yield put(fetchCoupon(action.payload.voucher.id));
        yield put(
          notificationPush({
            title: 'NOTIFICATION.COUPON_DEBIT_SUCCESS.TITLE',
            message: {
              id: 'NOTIFICATION.COUPON_DEBIT_SUCCESS.MESSAGE',
            },
            type: notificationTypes.SUCCESS,
          }),
        );
        yield call<HandleAnalyticsEvent<'serviceEventSubmission'>>(
          handleAnalyticsEvent,
          'serviceEventSubmission',
          'housingServicesSubmitServiceEventSucceeded',
          action.payload.voucher.serviceArea,
        );
        yield put(actions.newServiceEventInputStarted());
        break;
      case StatusCodes.BAD_REQUEST:
        yield put(
          actions.serviceEventSubmissionCompleted({
            status: ServiceEventStatus.SubmissionFailed,
          }),
        );
        yield call<HandleAnalyticsEvent<'serviceEventSubmission'>>(
          handleAnalyticsEvent,
          'serviceEventSubmission',
          'housingServicesSubmitServiceEventFailed',
          action.payload.voucher.serviceArea,
        );
        yield put(fetchCoupon(action.payload.voucher.id));
        break;
    }
  }

  yield takeLeading(actions.serviceEventSubmissionRequested.match, serviceEventSubmissionRequest);
}
