//@flow
import React from 'react';
import classNames from 'classnames';

import { ControlBase } from 'app/shared';
import { Append, AppendTooltip, Error, Icon, Label, Wrapper } from 'app/shared/form/controls-elements';
import { useIntl } from 'app/hooks';

import { translate } from 'app/utils';

/*:: import type { InputBaseControlComponentProps, InputBaseControlProps } from './input-control-base.component.types';*/
import styles from 'app/shared/form/common/input-control-base/input-control-base.module.scss';

const InputBaseControlComponent = (props /*: InputBaseControlComponentProps*/) => {
  const intl = useIntl();

  const {
    appendClassName,
    appendComponent: AppendComponent = AppendTooltip,
    className,
    component: Component,
    controlMeta,
    errorProps,
    iconProps,
    labelProps,
    placeholder,
    type = 'text',
    variant,
    wrapperProps,
  } = props;

  const { withAppend, withIcon } = controlMeta;

  const wrapperClassNames = classNames(styles.inputBase, className);

  const inputWrapperClassNames = classNames(styles.inputWrapper, `size-${variant}`, type, {
    'input-group': withIcon || withAppend,
  });

  const componentProps = {
    ...props.componentProps,
    ...(placeholder ? { placeholder: translate({ intl, translation: placeholder }) } : {}),
  };

  const appendProps = {
    append: props.append,
    appendComponent: AppendComponent,
    className: appendClassName,
  };

  return (
    <Wrapper className={wrapperClassNames} {...wrapperProps}>
      <Label {...labelProps} />
      <div className={inputWrapperClassNames} ref={props.inputRef}>
        <Icon {...iconProps} />
        {!!Component && <Component {...componentProps} />}
        <Append {...appendProps} />
      </div>

      <Error {...errorProps} />

      {props.children}
    </Wrapper>
  );
};

export const InputBaseControl = (props /*: InputBaseControlProps*/) => (
  <ControlBase control={InputBaseControlComponent} {...props} styles={styles} />
);
