// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import Sticky from 'react-stickynode';
import classNames from 'classnames';

import { SM_BREAKPOINT } from 'app/constants';
import { SidebarUserContainer } from './user/sidebar-user.container';

import { SidebarContext } from './sidebar.context';

/*:: import type { MobileWrapperProps, Props } from './sidebar.component.types';*/

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import styles from './sidebar.module.scss';
import { SidebarSections } from './sidebar-sections';

const MobileWrapper = ({ children, sidebarCollapsed, toggleSidebar } /*: MobileWrapperProps*/) => {
  const iconClassName = `fi fi-${sidebarCollapsed ? 'menu' : 'remove'}`;
  const menuClassNames = classNames(styles.menu, {
    [styles.menuOpen]: !sidebarCollapsed,
  });

  return (
    <>
      <div className={styles.header}>
        <div className="row d-flex align-items-center">
          <div className="col d-flex align-items-center">
            <Logo />
          </div>
          <div className="col text-right">
            <button className={styles.switch} onClick={toggleSidebar}>
              <i className={iconClassName} />
            </button>
          </div>
        </div>
      </div>

      <div className={menuClassNames}>{children}</div>
    </>
  );
};
export class SidebarComponent extends Component /*:: <Props>*/ {
  /*:: element: ?HTMLDivElement;*/
  /*:: tooltipsContainer: ?HTMLDivElement;*/
  sticky /*: React.createRef<any>*/ = React.createRef();

  recalculatePosition = () => {
    setTimeout(() => {
      //$FlowFixMe
      if (this.sticky.current) {
        this.sticky.current.fix(this.props.topDistance);
        this.sticky.current.updateInitialDimension();
      }
    }, 0);
  };

  observer = new MutationObserver(this.recalculatePosition);

  componentDidMount() {
    const mutationObserverConfig = {
      attributes: true,
      attributeFilter: ['class'],
    };

    if (document.body) {
      this.observer.observe(document.body, mutationObserverConfig);
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleSidebarToggle = () => {
    this.props.toggleSidebar();

    // update Sticky width
    this.recalculatePosition();
  };

  render() {
    return (
      <SidebarContext.Provider value={this.tooltipsContainer}>
        <MediaQuery maxWidth={SM_BREAKPOINT}>
          {matchesBreakpoint => {
            const Wrapper = matchesBreakpoint ? MobileWrapper : ({ children }) => children;
            return (
              <Wrapper sidebarCollapsed={this.props.sidebarCollapsed} toggleSidebar={this.props.toggleSidebar}>
                <div className={classNames(styles.sidebar, { [styles.collapsed]: this.props.sidebarCollapsed })}>
                  <Sticky bottomBoundary={`.${styles.sidebar}`} innerZ={100} ref={this.sticky}>
                    <div className={styles.container}>
                      <div className={styles.tooltipsContainer} ref={c => (this.tooltipsContainer = c)} />

                      <nav>
                        {!matchesBreakpoint && (
                          <button className={styles.collapse} onClick={this.handleSidebarToggle}>
                            <i className="fi fi-expand" />
                          </button>
                        )}
                        <SidebarUserContainer />
                        <SidebarSections sidebarCollapsed={this.props.sidebarCollapsed} />
                      </nav>

                      <div id="portal-root" />
                    </div>
                  </Sticky>
                </div>
              </Wrapper>
            );
          }}
        </MediaQuery>
      </SidebarContext.Provider>
    );
  }
}
