import { isControl, rankWith } from '@jsonforms/core';
import { InputComponent, TextareaComponent } from 'app/shared';
import { InputHTMLAttributes, SyntheticEvent } from 'react';
import { vaanaJsonFormsControl } from './vaana-json-forms-control';

export const InputControlRenderer = vaanaJsonFormsControl(
  ({ data, handleChange, path, enabled, label, required, schema, errors, uischema, ...rest }) => {
    const monetaryCentProps: Partial<InputHTMLAttributes<HTMLInputElement>> = {
      value: Number.isInteger(data) ? data / 100 : undefined,
      onChange(e: SyntheticEvent<HTMLInputElement>) {
        const value = parseFloat(e.currentTarget.value);
        if (!isNaN(value)) {
          handleChange(path, Math.floor(value * 100));
        }
      },
    };

    if (uischema.options?.multi) {
      return (
        <TextareaComponent
          disabled={!enabled}
          label={label}
          required={required}
          type={schema.type}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            const value = schema.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
            handleChange(path, value);
          }}
          value={data ?? ''}
          error={errors}
          invalid={!!errors}
          maxLength={uischema.options?.maxLength ?? 500}
        />
      );
    }

    return (
      <InputComponent
        disabled={!enabled}
        label={label}
        required={required}
        type={schema.type}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          const value = schema.type === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value;
          handleChange(path, value);
        }}
        value={data ?? ''}
        error={errors}
        invalid={!!errors}
        icon={getIcon()}
        {...(uischema.options?.monetaryUnit === 'cent' ? monetaryCentProps : {})}
      />
    );

    function getIcon() {
      if (uischema.options?.controlType === 'monetary') {
        return 'euro-symbol';
      }
    }
  },
);

export const inputControlTester = rankWith(1000, isControl);
