// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'connected-react-router';

import { addUser } from 'app/redux/users/users.actions';

import { UsersAddComponent } from './users-add.component';

const mapStateToProps = ({ dictionaries }, { context }) => ({
  initialValues: {
    role: dictionaries[`user-roles/${context}`].filter(role => role.editable)[0].name,
  },
  roles: (dictionaries[`user-roles/${context}`] || [])
    .filter(role => role.editable)
    .map(role => ({
      id: role.name,
      name: `ROLES.${role.name}`,
    })),
});

const mapDispatchToProps = (dispatch, { context, customer, pathname, reloadUsers }) => ({
  onClose: () => dispatch(push(pathname)),
  onSubmit: body =>
    dispatch(addUser(customer, context, body))
      .then(() => reloadUsers())
      .then(() => `${body.firstName} ${body.lastName}`),
  onSubmitSuccess: name =>
    dispatch(
      push({
        pathname,
        state: {
          success: {
            type: 'ADD_USER',
            payload: name,
          },
        },
      }),
    ),
});

export const UsersAddContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'add-user' }),
)(UsersAddComponent);
