// @flow
import moment from 'moment';
import { getIn } from 'final-form';
import isNil from 'lodash/isNil';

import { getBalance, normalize, replaceComma, replaceNoValueToEmptyString } from 'app/utils';

/*:: import type { Coupon } from 'app/coupons/details/coupons-details.types';*/
/*:: import type { ServiceProduced } from 'app/coupons/mass-charge/coupons-mass-charge.component.types';*/
/*:: import type { PaymentsFormValues } from 'app/coupons/mass-charge/form/coupons-mass-charge-form.component.types';*/

const maxTransactionValidator = (maxTransaction /*: ?number*/, amount /*: number*/) /*: boolean*/ => {
  return !!maxTransaction && amount > maxTransaction;
};

const balanceValidator = (
  serviceProduced /*: $Shape<ServiceProduced>*/,
  coupon /*: Coupon*/,
  amount /*: number*/,
  fixExpiryDate = false,
) /*: boolean*/ => {
  if (!serviceProduced.startDate || !serviceProduced.endDate) {
    return false;
  }

  const serviceProducedStart = moment(serviceProduced.startDate);
  const serviceProducedEnd = moment(serviceProduced.endDate);
  const balance = getBalance(coupon.transfers, serviceProducedStart, serviceProducedEnd, fixExpiryDate);

  return amount > balance;
};

export const amountFieldValidator =
  (coupon /*: Coupon*/, fieldName /*: string*/, fixExpiryDate /*: boolean*/ = false) =>
  (value /*: number | string*/, formValues /*: PaymentsFormValues*/) => {
    const serviceProduced = getIn(formValues, `${fieldName}.serviceProduced`) || {};

    return amountValidator(value, { coupon, serviceProduced, fixExpiryDate });
  };

export const amountValidator = (
  amountValue /*: any*/,
  {
    coupon,
    serviceProduced,
    fixExpiryDate,
  } /*: { coupon: Coupon, fixExpiryDate?: boolean, serviceProduced: $Shape<ServiceProduced> }*/,
) => {
  if (isNil(amountValue)) {
    return 'VALIDATION.REQUIRED';
  }

  const amount /*: number*/ = Number(normalize(amountValue, [replaceNoValueToEmptyString, replaceComma]));

  if (maxTransactionValidator(coupon.maxTransaction, amount)) {
    return 'VALIDATION.MAX_PRICE';
  }

  if (balanceValidator(serviceProduced, coupon, amount, fixExpiryDate)) {
    return 'VALIDATION.BALANCE_EXCEEDED';
  }

  if (amount < 0) {
    return 'VALIDATION.ZERO_OR_POSITIVE_NUMBER';
  }

  return undefined;
};
