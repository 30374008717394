// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedInputComponent, SeparatorComponent } from 'app/shared';
import { CardHeaderComponent } from 'app/shared/card-new';
import { handleAnalyticsEvent } from 'app/utils';

/*:: import type { Props } from './vat-archive-details-filters.component.types';*/
import styles from './vat-archive-details-filters.module.scss';

export class VatArchiveDetailsFiltersComponent extends Component /*:: <Props>*/ {
  onFieldUsage = (label /*: string*/) => {
    handleAnalyticsEvent('vatArchive', 'filterUsage', label);
  };

  render() {
    const rowClassNames = classNames('row', styles.row);

    const columnClassNames = classNames('col-12', {
      'col-lg-8': this.props.sidebarCollapsed,
    });

    return (
      <>
        <CardHeaderComponent title="VAT_ARCHIVE.DETAILS.SEARCH" className="px-0" />
        <SeparatorComponent />

        <form onSubmit={this.props.handleSubmit}>
          <div className={rowClassNames}>
            <div className={columnClassNames}>
              <Field
                name="id"
                component={ConnectedInputComponent}
                label="VAT_ARCHIVE.DETAILS.HEADERS.BILL_ID"
                icon="search"
                onFieldUsage={this.onFieldUsage}
              />
              {this.props.type === 'TYPE_MUNICIPAL' && (
                <Field
                  name="sellerName"
                  component={ConnectedInputComponent}
                  label="VAT_ARCHIVE.DETAILS.HEADERS.SELLER_NAME"
                  icon="search"
                  onFieldUsage={this.onFieldUsage}
                />
              )}
              {this.props.type === 'TYPE_VENUE' && (
                <Field
                  name="buyerName"
                  component={ConnectedInputComponent}
                  label="VAT_ARCHIVE.DETAILS.HEADERS.BUYER_NAME"
                  icon="search"
                  onFieldUsage={this.onFieldUsage}
                />
              )}
              <Field
                name="type"
                component={ConnectedInputComponent}
                label="VAT_ARCHIVE.DETAILS.HEADERS.TYPE"
                icon="search"
                onFieldUsage={this.onFieldUsage}
              />

              <div className="d-flex">
                <button type="button" onClick={this.props.onClear} className={styles.button}>
                  {this.props.intl.formatMessage({ id: 'CORE.CLEAR_FILTERS' })}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
