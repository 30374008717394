// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { TileComponent } from 'app/shared';
import { VenueNavigationButtonsComponent } from '../shared/venue-navigation-buttons.component';
import { StatusComponent } from 'app/shared/status/status.component';

/*:: import type { Props } from './venues-list-tile.component.types';*/
import styles from './venues-list-tile.module.scss';

export class VenuesListTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent className={classNames({ [styles.pending]: this.props.tile.pending })}>
        <h3>{this.props.tile.name}</h3>

        <div className="row mb-0 pb-0">
          <div className="col mt-1">
            <div className="">
              <FormattedMessage id="VENUES.STATUS" tagName="span" />:
              <StatusComponent
                status={this.props.tile.venueContractStatus}
                size="small"
                iconSet={'provider'}
                className="mr-1 ml-1"
              />
              <FormattedMessage id={`PRICELIST.STATUS_VALUE.${this.props.tile.venueContractStatus}`} />
            </div>
          </div>
        </div>
        <div className="row mt-0 pt-0">
          <div className="col mt-1">
            <FormattedMessage id="VENUES.CITY" tagName="span" />: {this.props.tile.city}
          </div>
        </div>

        {this.props.tile.pending || (
          <div className="buttons">
            <VenueNavigationButtonsComponent venueId={this.props.tile.id} permissions={this.props.permissions} />
          </div>
        )}

        {this.props.tile.pending && (
          <div className="buttons">
            <FormattedMessage id="VENUES.PENDING" tagName="strong" />
          </div>
        )}
      </TileComponent>
    );
  }
}
