// @flow

import React, { Component } from 'react';
import detectHover from 'detect-hover';
import classNames from 'classnames';

import { PlaceholderComponent, TooltipComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';

import { SidebarGroupComponent, SidebarTitleComponent } from 'app/core/sidebar/shared';

/*:: import type { Props } from './sidebar-balance.component.types';*/
import styles from './sidebar-balance.module.scss';

export class SidebarBalanceComponent extends Component /*:: <Props>*/ {
  render() {
    const balanceClassNames = classNames(styles.balance, {
      [styles.collapsed]: this.props.sidebarCollapsed,
    });

    const balance = this.props.loading ? (
      <PlaceholderComponent width={100} />
    ) : (
      currencyFormatter.format(this.props.balance)
    );

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'SIDEBAR.BALANCE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {this.props.sidebarCollapsed ? (
            <TooltipComponent
              overlay={balance}
              placement="right"
              trigger={[detectHover.anyHover ? 'hover' : 'click']}
              getTooltipContainer={this.props.container ? () => this.props.container : undefined}
            >
              <div className={balanceClassNames}>
                <i className="fi fi-euro-symbol" />
              </div>
            </TooltipComponent>
          ) : (
            <div className={balanceClassNames}>{balance}</div>
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
