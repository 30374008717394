//@flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import {
  AlertComponent,
  ConnectedDatePickerComponent,
  PlaceholderComponent,
  SeparatorComponent,
  Typography,
} from 'app/shared';
import { TableBodyComponent, TableCellComponent, TableComponent, TableRowComponent } from 'app/shared/table-new';
import { PageHeaderComponent } from 'app/core';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { currencyFormatter, isBusinessDifferent, required } from 'app/utils';

/*:: import type { Props, State } from './accounts-balance.types';*/
import styles from './balance.module.scss';

export class AccountsBalanceComponent extends PureComponent /*:: <Props, State>*/ {
  formRef = React
    .createRef /*:: <any>*/
    ();
  state = {};

  componentDidMount() {
    this.submitForm();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.submitForm();
    }
  }

  submitForm = () => {
    const {
      form: { submit },
    } = this.formRef.current || {};
    submit && submit();
  };

  toggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const {
      balance: { loading, dailyMunicipalBalance, dailyBeneficiaryBalance, error },
      creditCap,
      isPostPaymentAccount,
    } = this.props;

    // if due to incorrect money transfer etc the postpayment balance would be a positive number, let's rather show zero as used credit
    const postpaymentUsedCredit = Math.abs(Math.min(0, dailyMunicipalBalance));
    const postpaymentAvailableCredit = creditCap ? creditCap - postpaymentUsedCredit : null;

    const prepaymentAvailableBalance = dailyMunicipalBalance;
    const prepaymentSum = dailyBeneficiaryBalance + dailyMunicipalBalance;

    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const tableDataClassNames = classNames(styles.column, styles.label);
    return (
      <>
        <PageHeaderComponent title="ORDERS.BALANCE.TITLE" />

        <Form onSubmit={this.props.onSubmit} ref={this.formRef} initialValues={this.props.initialValues}>
          {({ invalid, handleSubmit, submitting, submitError }) => (
            <>
              {submitError && (
                <AlertComponent type="failure">
                  <FormattedMessage id={submitError} />
                </AlertComponent>
              )}
              <CardComponent>
                <CardHeaderComponent
                  title={isPostPaymentAccount ? 'ORDERS.BALANCE.POST_PAYMENT_BALANCE' : 'ORDERS.BALANCE.BALANCE'}
                />
                <SeparatorComponent />
                <CardBodyComponent>
                  <form onSubmit={handleSubmit} className="col-4 p-0 pb-2">
                    <Field
                      component={ConnectedDatePickerComponent}
                      label="ORDERS.BALANCE.SELECT_DATE"
                      name="date"
                      onFocusChange={this.toggle}
                      onChange={date => {
                        date && handleSubmit();
                      }}
                      disabled={submitting}
                      validate={required({ message: 'VALIDATION.DATE_IS_REQUIRED' })}
                      bounds={{
                        maxDate: moment().subtract(1, 'day').endOf('day'),
                      }}
                    />
                  </form>
                </CardBodyComponent>

                <SeparatorComponent />
                <CardBodyComponent>
                  <TableComponent className="table-striped">
                    <TableBodyComponent>
                      <TableRowComponent>
                        <TableCellComponent className={tableDataClassNames}>
                          <Typography
                            type="bodyTextBold"
                            color="greyDark"
                            id={isPostPaymentAccount ? 'ORDERS.BALANCE.USED_CREDIT' : 'ORDERS.BALANCE.AVAILABLE'}
                          />
                        </TableCellComponent>
                        <TableCellComponent className={styles.column}>
                          <Typography type="bodyTextBold" color="greyDark">
                            {loading ? (
                              <PlaceholderComponent />
                            ) : (
                              !error &&
                              currencyFormatter.format(
                                isPostPaymentAccount ? postpaymentUsedCredit : prepaymentAvailableBalance,
                              )
                            )}
                          </Typography>
                        </TableCellComponent>
                      </TableRowComponent>
                      <TableRowComponent>
                        <TableCellComponent
                          className={classNames(tableDataClassNames, styles.innerRow)}
                          content="ORDERS.BALANCE.OPEN_COUPONS"
                        />
                        <TableCellComponent className={styles.column}>
                          <Typography type="bodyText" color="grey">
                            {loading ? (
                              <PlaceholderComponent />
                            ) : (
                              !error && currencyFormatter.format(dailyBeneficiaryBalance)
                            )}
                          </Typography>
                        </TableCellComponent>
                      </TableRowComponent>

                      {!isPostPaymentAccount ? (
                        <TableRowComponent>
                          <TableCellComponent className={tableDataClassNames}>
                            <Typography type="bodyTextBold" color="greyDark" id={'ORDERS.BALANCE.SUM'} />
                          </TableCellComponent>
                          <TableCellComponent className={styles.column}>
                            {loading ? (
                              <PlaceholderComponent />
                            ) : (
                              !error && (
                                <Typography type="bodyTextBold" color="greyDark">
                                  {currencyFormatter.format(prepaymentSum)}
                                </Typography>
                              )
                            )}
                          </TableCellComponent>
                        </TableRowComponent>
                      ) : creditCap ? (
                        <TableRowComponent>
                          <TableCellComponent className={tableDataClassNames}>
                            <Typography type="bodyTextBold" color="greyDark" id={'ORDERS.BALANCE.AVAILABLE_CREDIT'} />
                          </TableCellComponent>
                          <TableCellComponent className={styles.column}>
                            {loading ? (
                              <PlaceholderComponent />
                            ) : (
                              !error && (
                                <Typography type="bodyTextBold" color="greyDark">
                                  {currencyFormatter.format(postpaymentAvailableCredit)}
                                </Typography>
                              )
                            )}
                          </TableCellComponent>
                        </TableRowComponent>
                      ) : null}
                      {creditCap === 0 && (
                        <Typography
                          type="bodyTextBold"
                          color="greyDark"
                          className="p-4"
                          id={'ORDERS.BALANCE.NO_CREDIT_GIVEN'}
                        />
                      )}
                    </TableBodyComponent>
                  </TableComponent>
                </CardBodyComponent>
                <div className={backdropClassNames} />
              </CardComponent>
            </>
          )}
        </Form>
      </>
    );
  }
}
