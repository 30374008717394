// @flow

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';

import { ConnectedInputComponent, ConnectedTextareaComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './venue-section.component.types';*/

export class VenueSectionComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <FormSection name={this.props.name}>
        <CardHeaderComponent direction="column" alignItems="flex-start">
          {this.props.children}
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className="col-lg-6">
              <Field
                name="name"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.NAME"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.NAME' })}
                maxLength={50}
                required
              />
            </div>
            <FormSection name="address">
              <div className="col-lg-6">
                <Field
                  name="address"
                  component={ConnectedInputComponent}
                  label="REGISTER.VENUE.STREET_ADDRESS"
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.STREET_ADDRESS' })}
                  required
                />
              </div>
            </FormSection>
            <FormSection name="address">
              <div className="col-lg-6">
                <Field
                  name="zipCode"
                  component={ConnectedInputComponent}
                  label="REGISTER.VENUE.ZIP_CODE"
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.ZIP_CODE' })}
                  required
                />
              </div>
            </FormSection>
            <FormSection name="address">
              <div className="col-lg-6">
                <Field
                  name="city"
                  component={ConnectedInputComponent}
                  label="REGISTER.VENUE.CITY"
                  placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.CITY' })}
                  required
                />
              </div>
            </FormSection>
            <div className="col-lg-6">
              <Field
                name="contactName"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.CONTACT_NAME"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.CONTACT_NAME' })}
                required
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="contactPhone"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.CONTACT_PHONE"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.CONTACT_PHONE' })}
                append={this.props.intl.formatMessage({ id: 'REGISTER.PHONE_FORMAT' })}
                required
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <Field
                name="website"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.WEBSITE_ADDRESS"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.WEBSITE_ADDRESS' })}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="email"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.EMAIL"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.EMAIL' })}
              />
            </div>
            <div className="col-lg-6">
              <Field
                name="phone"
                component={ConnectedInputComponent}
                label="REGISTER.VENUE.PHONE"
                placeholder={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.PHONE' })}
                append={this.props.intl.formatMessage({ id: 'REGISTER.PHONE_FORMAT' })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Field
                name="description"
                component={ConnectedTextareaComponent}
                label="REGISTER.VENUE.DESCRIPTION"
                append={this.props.intl.formatMessage({ id: 'REGISTER.VENUE.DESCRIPTION_ADDON' })}
              />
            </div>
          </div>
        </CardBodyComponent>
      </FormSection>
    );
  }
}
