// @flow

import React, { PureComponent } from 'react';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { currencyFormatter, getDatesString } from 'app/utils';

/*:: import type { Props } from './coupons-charge-modal-table.types';*/

export class CouponsChargeModalTable extends PureComponent /*:: <Props>*/ {
  render() {
    const headers = [
      { content: 'COUPONS.DETAILS.CHARGE.SERVICE_PRODUCED_DATE' },
      { content: 'COUPONS.DETAILS.CHARGE.PRICE_UNITS' },
      { content: 'COUPONS.DETAILS.CHARGE.AMOUNT' },
      { content: 'COUPONS.DETAILS.CHARGE.REFERENCE' },
      this.props.extended && { content: 'COUPONS.DETAILS.CHARGE.DESCRIPTIVE_SERVICE' },
      this.props.extended && { content: 'COUPONS.DETAILS.CHARGE.VAT_RATES' },
    ];

    return (
      <div className="table-responsive">
        <TableComponent className="table-striped">
          <TableHeadComponent headers={headers} />

          <TableBodyComponent>
            {this.props.list.map((payment, index) => (
              <TableRowComponent key={index}>
                <TableCellComponent>
                  {getDatesString(payment.serviceProduced.startDate, payment.serviceProduced.endDate)}
                </TableCellComponent>
                <TableCellComponent>{payment.numberOfPriceUnits}</TableCellComponent>
                <TableCellComponent>{currencyFormatter.format(payment.amount)}</TableCellComponent>
                <TableCellComponent>{payment.reference}</TableCellComponent>

                {this.props.extended && (
                  <>
                    <TableCellComponent>{payment.descriptiveService.name}</TableCellComponent>
                    <TableCellComponent>{payment.vatRate}</TableCellComponent>
                  </>
                )}
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TableComponent>
      </div>
    );
  }
}
