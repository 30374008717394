export const FETCH_VAT_ARCHIVES_REQUEST = 'archive/FETCH_VAT_ARCHIVES_REQUEST';
export const FETCH_VAT_ARCHIVES_SUCCESS = 'archive/FETCH_VAT_ARCHIVES_SUCCESS';
export const FETCH_VAT_ARCHIVES_FAILURE = 'archive/FETCH_VAT_ARCHIVES_FAILURE';

export const FETCH_VAT_ARCHIVE_REQUEST = 'archive/FETCH_VAT_ARCHIVE_REQUEST';
export const FETCH_VAT_ARCHIVE_SUCCESS = 'archive/FETCH_VAT_ARCHIVE_SUCCESS';
export const FETCH_VAT_ARCHIVE_FAILURE = 'archive/FETCH_VAT_ARCHIVE_FAILURE';

export const FETCH_VAT_ARCHIVES_MONTHS_REQUEST = 'archive/FETCH_VAT_ARCHIVES_MONTHS_REQUEST';
export const FETCH_VAT_ARCHIVES_MONTHS_SUCCESS = 'archive/FETCH_VAT_ARCHIVES_MONTHS_SUCCESS';
export const FETCH_VAT_ARCHIVES_MONTHS_FAILURE = 'archive/FETCH_VAT_ARCHIVES_MONTHS_FAILURE';

export const FETCH_VAT_ARCHIVES_REPORT_STATUS_REQUEST = 'archive/FETCH_VAT_ARCHIVE_REPORT_STATUS_REQUEST';
export const FETCH_VAT_ARCHIVES_REPORT_STATUS_SUCCESS = 'archive/FETCH_VAT_ARCHIVE_REPORT_STATUS_SUCCESS';
export const FETCH_VAT_ARCHIVES_REPORT_STATUS_FAILURE = 'archive/FETCH_VAT_ARCHIVE_REPORT_STATUS_FAILURE';
