import React, { /*:: FunctionComponent, */ useEffect } from 'react';
import { useSelector } from 'app/hooks/redux';

import { SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import { CouponsDetailsChargeFormContainer } from './form/coupons-details-charge-form.container';
import { CouponsDetailsChargeServicesPlaceholder } from './placeholder/coupons-details-charge-services.placeholder';

/*:: import type { Props } from './coupons-details-charge.component.types';*/
/*:: import type { Coupon } from '../coupons-details.types';*/
import { ServiceVoucherDebitComponent } from 'v2/service-voucher/housing-service/service-voucher-debit.component';
import { isHousingServicesServiceAreas } from 'v2/service-events/housing-service/service-types';
import { useLocation } from 'react-router-dom';

export const EXCEPTIONAL_DEBIT_FLAG = 'exceptional-debit';

function isHousingServicesServiceVoucher(coupon /*: Coupon*/) {
  return coupon && coupon.serviceArea && isHousingServicesServiceAreas(coupon.serviceArea);
}

const businessSelector = state => state.users.business.id;

export const CouponsDetailsChargeComponent /*: FunctionComponent*/ = (props /*: Props*/) => {
  const { fetchDictionary } = props;

  useEffect(() => {
    fetchDictionary();
  }, [fetchDictionary]);

  const location = useLocation();

  const currentBusinessId /*: number*/ = useSelector(businessSelector);

  if (props.loading) {
    return (
      <CardComponent>
        <CardHeaderComponent title="COUPONS.DETAILS.CHARGE.TITLE" />
        <SeparatorComponent />
        <CardBodyComponent>
          <CouponsDetailsChargeServicesPlaceholder intl={props.intl} sidebarCollapsed={props.sidebarCollapsed} />
        </CardBodyComponent>
      </CardComponent>
    );
  }

  const isExceptionalDebit = new URLSearchParams(location.search).get(EXCEPTIONAL_DEBIT_FLAG) === 'true';

  if (isHousingServicesServiceVoucher(props.coupon) && !isExceptionalDebit) {
    return (
      <ServiceVoucherDebitComponent
        voucher={props.coupon}
        venueId={currentBusinessId}
        transactions={props.transactions}
      />
    );
  } else {
    return <CouponsDetailsChargeFormContainer coupon={props.coupon} />;
  }
};
