import { handleActions } from 'redux-actions';
import * as types from './transactions.action-types';

const defaultState = {
  entities: {},
};

export const transactionsReducer = handleActions(
  {
    [types.FETCH_TRANSACTIONS_REQUEST]: (state, { meta: { shouldReset, type } }) => ({
      ...state,
      ...(shouldReset ? { [type]: undefined } : { [type]: { ...state[type], fetching: true } }),
    }),
    [types.FETCH_TRANSACTIONS_SUCCESS]: (state, { meta: { shouldMerge, type }, payload: { data: list, meta } }) => ({
      ...state,
      [type]: {
        ...state[type],
        meta,
        fetching: false,
        list: shouldMerge ? [...state[type].list, ...list] : list,
      },
    }),
    [types.FETCH_TRANSACTIONS_FAILURE]: (state, { meta: { type } }) => ({
      ...state,
      [type]: {
        ...state[type],
        fetching: false,
      },
    }),
    [types.FETCH_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: undefined,
      },
    }),
    [types.FETCH_TRANSACTION_SUCCESS]: (state, { meta: { transaction }, payload: { data: entity } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: entity,
      },
    }),
    [types.REFUND_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: true,
        },
      },
    }),
    [types.REFUND_TRANSACTION_REQUEST]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: true,
        },
      },
    }),
    [types.REFUND_TRANSACTION_SUCCESS]: (state, { meta: { transaction }, payload: { data } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: data,
      },
    }),
    [types.REFUND_TRANSACTION_FAILURE]: (state, { meta: { transaction } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [transaction]: {
          ...state.entities[transaction],
          refunding: false,
        },
      },
    }),
  },
  defaultState,
);
