import { NO_SERVICE_TYPE_GROUP_ID } from 'app/pricelists/approve/constants';
import { getContractPricelistsWithDiff } from 'app/utils/pricelist/get-pricelist-diff';
import { getFormattedContractPricelists } from 'app/utils/pricelist/get-formatted-pricelist';
import { InputVenue, ReviewAction, Venue } from 'app/types/contract';
import { PricelistSubmissionStatus } from 'app/types/pricelist';
import { typedHandleActions } from 'app/utils/redux/handle-actions';
import * as types from './pricelists.action-types';

export type PricelistState = {
  venues?: unknown[];
  isLoading: boolean;
  details: {
    isLoading: boolean;
    venue?: Venue;
  };
  reviewSubmissionState: ReviewSubmissionState;
};

type ReviewSubmissionState =
  | { status: PricelistSubmissionStatus.NotSubmitted }
  | { status: PricelistSubmissionStatus.Submitting; action: ReviewAction }
  | { status: PricelistSubmissionStatus.Submitted; action: ReviewAction; success: boolean };

const defaultState: PricelistState = {
  venues: undefined,
  isLoading: false,
  details: {
    isLoading: false,
    venue: undefined,
  },
  reviewSubmissionState: { status: PricelistSubmissionStatus.NotSubmitted },
};

type PricelistAction =
  | { type: typeof types.REVIEW_CONTRACT_REQUEST; meta: { action: ReviewAction } }
  | { type: typeof types.REVIEW_CONTRACT_SUCCESS }
  | { type: typeof types.REVIEW_CONTRACT_FAILURE }
  | { type: typeof types.FETCH_CONTRACTED_VENUES_REQUEST }
  | { type: typeof types.FETCH_CONTRACTED_VENUES_SUCCESS; payload: { data: unknown[] } }
  | { type: typeof types.FETCH_CONTRACTED_VENUES_FAILURE }
  | { type: typeof types.FETCH_CONTRACTED_VENUES_REQUEST }
  | { type: typeof types.FETCH_VENUE_CONTRACTS_REQUEST }
  | { type: typeof types.FETCH_VENUE_CONTRACTS_SUCCESS; payload: { data: InputVenue } }
  | { type: typeof types.FETCH_VENUE_CONTRACTS_FAILURE }
  | { type: typeof types.FILTER_VENUE_CONTRACTS; payload: string };

export const pricelistsReducer = typedHandleActions<PricelistState, PricelistAction>(
  {
    [types.REVIEW_CONTRACT_REQUEST]: (state, { meta: { action } }): PricelistState => ({
      ...state,
      isLoading: true,
      reviewSubmissionState: {
        status: PricelistSubmissionStatus.Submitting,
        action,
      },
    }),
    [types.REVIEW_CONTRACT_SUCCESS]: (state): PricelistState => ({
      ...state,
      isLoading: false,
      reviewSubmissionState:
        state.reviewSubmissionState.status === PricelistSubmissionStatus.Submitting
          ? {
              status: PricelistSubmissionStatus.Submitted,
              action: state.reviewSubmissionState.action,
              success: true,
            }
          : { status: PricelistSubmissionStatus.NotSubmitted },
    }),
    [types.REVIEW_CONTRACT_FAILURE]: (state): PricelistState => ({
      ...state,
      isLoading: false,
      reviewSubmissionState:
        state.reviewSubmissionState.status === PricelistSubmissionStatus.Submitting
          ? {
              status: PricelistSubmissionStatus.Submitted,
              action: state.reviewSubmissionState.action,
              success: false,
            }
          : { status: PricelistSubmissionStatus.NotSubmitted },
    }),
    [types.FETCH_CONTRACTED_VENUES_REQUEST]: (state): PricelistState => ({
      ...state,
      venues: undefined,
      isLoading: true,
    }),
    [types.FETCH_CONTRACTED_VENUES_SUCCESS]: (state, { payload: { data: venues } }): PricelistState => ({
      ...state,
      venues,
      isLoading: false,
    }),
    [types.FETCH_CONTRACTED_VENUES_FAILURE]: (state): PricelistState => ({
      ...state,
      venues: undefined,
      isLoading: false,
    }),

    [types.FETCH_VENUE_CONTRACTS_REQUEST]: (state): PricelistState => ({
      ...state,
      details: {
        isLoading: true,
      },
      reviewSubmissionState: defaultState.reviewSubmissionState,
    }),
    [types.FETCH_VENUE_CONTRACTS_SUCCESS]: (state, { payload: { data: venue } }): PricelistState => {
      const venueContracts =
        venue.contracts?.map(contract => {
          if (contract.previousContracts?.length) {
            const pricelistsWithDiff = getContractPricelistsWithDiff(contract, contract.previousContracts[0]);
            return { ...contract, priceLists: pricelistsWithDiff };
          }
          return { ...contract, priceLists: getFormattedContractPricelists(contract) };
        }) || [];
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          venue: { ...venue, contracts: venueContracts, filteredContracts: venueContracts },
        },
      };
    },

    [types.FETCH_VENUE_CONTRACTS_FAILURE]: (state): PricelistState => ({
      ...state,
      details: {
        ...state.details,
        isLoading: false,
      },
    }),
    [types.FILTER_VENUE_CONTRACTS]: (state, { payload: serviceTypeGroupId }): PricelistState => {
      const { venue } = state.details;
      const filteredContracts = venue?.contracts?.filter(c =>
        serviceTypeGroupId === NO_SERVICE_TYPE_GROUP_ID
          ? !c.serviceTypeGroup
          : c.serviceTypeGroup?.id === serviceTypeGroupId,
      );
      return {
        ...state,
        details: {
          ...state.details,
          venue: { ...venue, filteredContracts },
        },
      };
    },
  },
  defaultState,
);
