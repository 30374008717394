// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';

import { ConnectedCheckboxesComponent, SeparatorComponent, Typography } from 'app/shared';
import { required } from 'app/utils';

/*:: import type { Props } from './venue-price-list.component.types';*/
import styles from './venue-price-list.module.scss';

export class VenuePriceListComponent extends Component /*:: <Props>*/ {
  afterCheckboxChange = (remove /*: boolean*/, service /*: Object*/) => {
    const formService = { id: service.id, name: service.text };

    return remove ? this.props.removeService(formService) : this.props.pushService(formService);
  };

  render() {
    const { fieldName, services } = this.props;

    return (
      <>
        <Typography className="pl-4 pb-2" id="REGISTER.SERVICES_SELECT" color="greyDark" />
        <div className={styles.container}>
          <Field
            theme="columns"
            name={`${fieldName}.services`}
            component={ConnectedCheckboxesComponent}
            afterChange={(remove, service) => this.afterCheckboxChange(remove, service)}
            options={services}
            translate={false}
            required
            validate={required()}
          />
        </div>

        <SeparatorComponent />
      </>
    );
  }
}
