import { AlertComponent } from 'app/shared';
import { ContractComponent } from '../contract/contract.component';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { SubmitHandler, WrappedFieldArrayProps } from 'redux-form';
import { useV1Selector } from 'v2/utils/redux-utils';
import { PricelistSubmissionStatus } from 'app/types/pricelist';

type Props = {
  handleSubmit: SubmitHandler;
  onContractRefreshRequested: () => void;
} & WrappedFieldArrayProps;

export const ContractsComponent = ({ fields, handleSubmit, onContractRefreshRequested }: Props) => {
  const reviewSubmissionState = useV1Selector(state => state.pricelists.reviewSubmissionState);
  const { status } = reviewSubmissionState;

  return (
    <>
      {status === PricelistSubmissionStatus.Submitted && (
        <AlertComponent type={reviewSubmissionState.success ? 'success' : 'failure'}>
          {reviewSubmissionState.success ? (
            <FormattedMessage id={`PRICELISTS.DETAILS.REVIEW.${_.toUpper(reviewSubmissionState.action)}`} />
          ) : (
            <FormattedMessage id="ERROR.TITLE" />
          )}
        </AlertComponent>
      )}
      {fields.map((contractField, index) => {
        const contract = fields.get(index);
        return (
          <ContractComponent
            contractField={contractField}
            contract={contract}
            handleSubmit={handleSubmit}
            onContractRefreshRequested={onContractRefreshRequested}
            key={contract.id}
          />
        );
      })}
    </>
  );
};
