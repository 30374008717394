// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PageHeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { AlertComponent, ButtonComponent, SeparatorComponent } from 'app/shared';

import { checkPermissions, isBusinessDifferent } from 'app/utils';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import { CouponsTypesListFiltersContainer } from './filters/coupons-types-list-filters.container';
import { CouponsTypesListPlaceholder } from './placeholder/coupons-types-list.placeholder';
import { CouponsTypesListTableComponent } from './table/coupons-types-list-table.component';
import { CouponsTypesListTileComponent } from './tile/coupons-types-list-tile.component';

/*:: import type { Props } from './coupons-types-list.component.types';*/

export class CouponsTypesListComponent extends Component /*:: <Props>*/ {
  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetch();
    }
  }

  render() {
    // Prevent the refinement from invalidating
    const types = this.props.loading || this.props.types;

    return (
      <>
        <PageHeaderComponent title="NAV.COUPONS.TYPES" />

        <CouponsTypesListFiltersContainer />

        <SeparatorComponent />

        {this.props.loading && <CouponsTypesListPlaceholder />}

        {this.props.loading || (
          <>
            {!!this.props.location && !!this.props.location.state && !!this.props.location.state.success && (
              <AlertComponent type="success">
                {this.props.location.state.success === 'COUPON_TYPE_ADDED' && (
                  <FormattedMessage id="COUPONS.TYPES.ADD_SUCCESS" />
                )}
                {this.props.location.state.success === 'COUPON_TYPE_EDITED' && (
                  <FormattedMessage id="COUPONS.TYPES.EDIT_SUCCESS" />
                )}
              </AlertComponent>
            )}

            <CardComponent>
              <CardHeaderComponent
                title={{ id: 'COUPONS.TYPES.COUNTER', values: { total: (this.props.meta || {}).totalItems } }}
              >
                {checkPermissions(this.props.permissions, ['serviceCouponTypes', 'create']) && (
                  <ButtonComponent theme="secondary" to="/coupons/types/add">
                    <FormattedMessage id="COUPONS.TYPES.ADD_NEW_TYPE" />
                  </ButtonComponent>
                )}
              </CardHeaderComponent>
              <SeparatorComponent />
              <CardBodyComponent>
                <ListComponent
                  list={types}
                  table={CouponsTypesListTableComponent}
                  tile={CouponsTypesListTileComponent}
                  searchable={false}
                  props={{
                    permissions: this.props.permissions,
                  }}
                  noResultsProps={{ searchable: false }}
                />

                {this.props.pagination}
              </CardBodyComponent>
            </CardComponent>
          </>
        )}
      </>
    );
  }
}
