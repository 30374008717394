// @flow
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { DefaultThemeComponent } from './defaultTheme.component';

/*:: import type { DefaultThemeProps } from './defaultTheme.types';*/

export const mapStateToProps = (state /*: any*/) /*: DefaultThemeProps*/ => {
  return {
    impersonating: get(state, 'users.meta.session.impersonating', false),
  };
};

export const DefaultThemeContainer = compose(connect(mapStateToProps))(DefaultThemeComponent);
