// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { GroupComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './venue-form.placeholder.types';*/

export class VenueFormPlaceholder extends PureComponent /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <CardComponent>
        <CardHeaderComponent title="VENUES.GENERAL_INFORMATION" />
        <SeparatorComponent />
        <CardBodyComponent>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.OFFICIAL_NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.NAME' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.STREET_ADDRESS' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.ZIP_CODE' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.CITY' })} required>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.WEBSITE' })}>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.GENERAL_EMAIL_ADDRESS' })}>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
            <div className={columnClassNames}>
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.PHONE' })}>
                <PlaceholderComponent height={40} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <GroupComponent label={this.props.intl.formatMessage({ id: 'VENUES.DESCRIPTION' })}>
                <PlaceholderComponent height={100} radius={8} color="light" />
              </GroupComponent>
            </div>
          </div>
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
