// @flow

import React, { PureComponent } from 'react';

import { PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './coupons-mass-charge.placeholder.types';*/
import styles from '../virtualized/coupons-mass-charge-form-array.module.scss';

export class CouponsMassChargePlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    coupons: [...new Array(5)],
  };

  render() {
    return (
      <CardComponent>
        <CardHeaderComponent>
          <PlaceholderComponent width={250} />
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <div className={styles.array}>
            {this.props.coupons.map((_, index) => (
              <div key={index} className={styles.wrapper} style={{ height: 192 }} />
            ))}
          </div>
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
