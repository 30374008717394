import { ContractStatus } from 'app/constants';
import { InputContract } from 'app/types/contract';
import { PricelistSubmissionStatus } from 'app/types/pricelist';
import { InputVenueMunicipality, ListVenue, Venue, VenueMunicipality } from 'app/types/venue';
import { typedHandleActions } from 'app/utils/redux/handle-actions';

import { getFormattedContractPricelists } from 'app/utils/pricelist/get-formatted-pricelist';

import * as types from './venues.action-types';

type PricelistSubmissionState =
  | { status: PricelistSubmissionStatus.NotSubmitted }
  | {
      status: PricelistSubmissionStatus.Submitted;
      updatedContractOldStatus?: ContractStatus;
      updatedContractId: number;
      success: boolean;
    };

type VenueEntity = Record<'venue', Venue>;

export type VenueState = {
  entities: Record<Venue['id'], VenueEntity> | Record<string, unknown>;
  municipalities?: VenueMunicipality[];
  list?: ListVenue[];
  updatingContract: boolean;
  pricelistSubmissionState: PricelistSubmissionState;
};

const defaultState: VenueState = {
  entities: {},
  list: undefined,
  municipalities: undefined,
  updatingContract: false,
  pricelistSubmissionState: { status: PricelistSubmissionStatus.NotSubmitted },
};

type VenueAction =
  | { type: typeof types.FETCH_MUNICIPALITIES_REQUEST; meta: { persistNotification: boolean } }
  | { type: typeof types.FETCH_MUNICIPALITIES_SUCCESS; payload: { data: InputVenueMunicipality[] } }
  | { type: typeof types.UPDATE_MUNICIPALITIES_CONTRACTS_REQUEST }
  | {
      type: typeof types.UPDATE_MUNICIPALITIES_CONTRACTS_SUCCESS;
      payload: { data: InputContract };
      meta: { contractOldStatus: ContractStatus };
    }
  | { type: typeof types.UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE; meta: { contract: InputContract } }
  | { type: typeof types.FETCH_VENUES_REQUEST }
  | { type: typeof types.FETCH_VENUES_SUCCESS; payload: { data: ListVenue[] } }
  | { type: typeof types.FETCH_VENUE_REQUEST; meta: { venueId: number } }
  | { type: typeof types.FETCH_VENUE_SUCCESS; payload: { data: VenueEntity } }
  | { type: typeof types.EDIT_VENUE_SUCCESS; meta: { id: number; venue: VenueEntity } };

export const venuesReducer = typedHandleActions<VenueState, VenueAction>(
  {
    [types.FETCH_MUNICIPALITIES_REQUEST]: (state, { meta: { persistNotification } }): VenueState => {
      return {
        ...state,
        municipalities: undefined,
        pricelistSubmissionState: persistNotification
          ? state.pricelistSubmissionState
          : defaultState.pricelistSubmissionState,
      };
    },
    [types.FETCH_MUNICIPALITIES_SUCCESS]: (state, { payload: { data: municipalities } }): VenueState => {
      return {
        ...state,
        municipalities: municipalities.map(municipality => ({
          ...municipality,
          contracts: municipality.contracts.map(contract => ({
            ...contract,
            priceLists: getFormattedContractPricelists(contract),
          })),
        })),
        updatingContract: false,
      };
    },
    [types.UPDATE_MUNICIPALITIES_CONTRACTS_REQUEST]: (state): VenueState => {
      return {
        ...state,
        updatingContract: true,
        pricelistSubmissionState: { status: PricelistSubmissionStatus.NotSubmitted },
      };
    },
    [types.UPDATE_MUNICIPALITIES_CONTRACTS_SUCCESS]: (
      state,
      { payload: { data: contract }, meta: { contractOldStatus } },
    ): VenueState => {
      const { id } = contract;
      return {
        ...state,
        updatingContract: true,
        pricelistSubmissionState: {
          status: PricelistSubmissionStatus.Submitted,
          updatedContractOldStatus: contractOldStatus,
          updatedContractId: id,
          success: true,
        },
      };
    },
    [types.UPDATE_MUNICIPALITIES_CONTRACTS_FAILURE]: (state, { meta: { contract } }): VenueState => {
      return {
        ...state,
        pricelistSubmissionState: {
          status: PricelistSubmissionStatus.Submitted,
          updatedContractId: contract.id,
          success: false,
        },
      };
    },
    [types.FETCH_VENUES_REQUEST]: (state): VenueState => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_VENUES_SUCCESS]: (state, { payload: { data: list } }): VenueState => ({
      ...state,
      list,
    }),
    [types.FETCH_VENUE_REQUEST]: (state, { meta: { venueId } }): VenueState => ({
      ...state,
      entities: {
        ...state.entities,
        [venueId]: undefined,
      },
    }),
    [types.FETCH_VENUE_SUCCESS]: (state, { payload: { data: entity } }): VenueState => ({
      ...state,
      entities: {
        ...state.entities,
        [entity.venue.id]: entity,
      },
    }),
    [types.EDIT_VENUE_SUCCESS]: (state, { meta: { venue, id } }): VenueState => ({
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...venue,
        },
      },
    }),
  },
  defaultState,
);