// @flow

import React from 'react';
import classNames from 'classnames';
import { FieldArray } from 'redux-form';

import { TableBodyComponent, TableComponent, TableHeadComponent, TableRowComponent } from 'app/shared/table-new';
import { PriceListTableRowComponent } from './row/price-list-table-row.component';
import { FieldArrayRenderer } from 'app/shared/form';
import { PriceListTableHeaderComponent } from './header/price-list-table-header.component';
import { isNotNil } from 'v2/utils/is-nil';

/*:: import type { Props } from './price-list-table.component.types';*/
import styles from './price-list-table.module.scss';

export const PriceListTableComponent = (props /*: Props*/) => {
  const {
    editable = false,
    editMode = false,
    input: { name },
    rowRenderer: RowRendererComponent = PriceListTableRowComponent,
    showDiff
  } = props;

  const hasSubtitles = props.input.value.columns
    ? props.input.value.columns.some(column => isNotNil(column.subtitle))
    : false;

  return (
    <TableComponent className={classNames('table-striped', styles.table)}>
      <TableHeadComponent>
        <TableRowComponent>
          <FieldArray
            name={`${name}.columns`}
            fieldComponent={PriceListTableHeaderComponent}
            component={FieldArrayRenderer}
            props={{ hasSubtitles, showDiff }}
            editMode={editMode}
            editable={editable}
          />
        </TableRowComponent>
      </TableHeadComponent>
      <TableBodyComponent>
        <FieldArray
          name={`${name}.services`}
          fieldComponent={RowRendererComponent}
          props={{ showDiff }}
          component={FieldArrayRenderer}
          editMode={editMode}
          editable={editable}
        />
      </TableBodyComponent>
    </TableComponent>
  );
};
