// @flow

import React, { PureComponent } from 'react';

import { UsersListContainer } from 'app/common/users';

/*:: import type { Props } from './users.placeholder.types';*/

export class UsersPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return <UsersListContainer loading={true} />;
  }
}
