// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

import { LanguageSwitcherContainer } from 'app/core/sidebar/language-switcher/language-switcher.container';

/*:: import type { Props, State } from './sidebar-settings.component.types';*/
import styles from './sidebar-settings.module.scss';

export class SidebarSettingsComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.SETTINGS.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {this.props.isApproved && checkPermissions(this.props.permissions, ['customerUsers', 'read']) && (
            <SidebarItemContainer
              icon="multiple-users-silhouette"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.USER_MANAGEMENT' })}
              to="/users"
            />
          )}

          {this.props.isApproved && checkPermissions(this.props.permissions, ['currentBusinessDetails', 'read']) && (
            <SidebarItemContainer
              icon="pencil-edit-button"
              name={this.props.intl.formatMessage({ id: 'NAV.GENERAL.CUSTOMER_INFORMATION' })}
              to="/customer-information"
            />
          )}

          <SidebarItemContainer
            icon="info-sign"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.USER_GUIDE' })}
            extTo={
              `https://guide.oma.vaana.fi/` + this.props.intl.formatMessage({ id: 'NAV.SETTINGS.USER_GUIDE_URI' }) + `/`
            }
          />

          <SidebarItemContainer
            icon="language"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.CHANGE_LANGUAGE' })}
            onClick={() => this.setState({ languageSwitcherOpened: true })}
          />

          {this.state.languageSwitcherOpened && (
            <LanguageSwitcherContainer onClose={() => this.setState({ languageSwitcherOpened: false })} />
          )}

          <SidebarItemContainer
            icon="power-button-off"
            name={this.props.intl.formatMessage({ id: 'NAV.SETTINGS.LOGOUT' })}
            onClick={this.props.onLogout}
            className={styles.logout}
          />
        </SidebarGroupComponent>
      </>
    );
  }
}
