// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { isBusinessDifferent } from 'app/utils';

import { UsersPlaceholder } from './placeholder/users.placeholder';

/*:: import type { Props } from './users.component.types';*/

export class UsersComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.clearAndFetchUsers(this.props.business.id);
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.clearAndFetchUsers(this.props.business.id);
    }
  }

  render() {
    const { business, loading } = this.props;

    return (
      <>
        <PageHeaderComponent title={{ id: 'USERS.USERS_TITLE', values: { business: business.name } }} />
        {loading && <UsersPlaceholder />}
        {loading || (
          <UsersListContainer
            context="customer"
            customer={this.props.business.id}
            users={this.props.users}
            reloadUsers={() => this.props.reloadUsers(this.props.business.id)}
          />
        )}
      </>
    );
  }
}
