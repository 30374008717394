import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceEvent } from 'v2/types/postpay';

export enum ServiceEventsStatus {
  Loaded = 'loaded',
  Refreshing = 'refreshing',
  Loading = 'loading',
}

export type VoucherServiceEventsState =
  | { status: ServiceEventsStatus.Loading }
  | { status: ServiceEventsStatus.Loaded | ServiceEventsStatus.Refreshing; serviceEvents: ServiceEvent[] };

export type ServiceVoucherServiceEventsState = {
  [voucherId: string]: VoucherServiceEventsState | undefined;
};

export type VoucherServiceEventsRequestedPayload = { voucherId: string };
export type VoucherServiceEventsRefreshRequestedPayload = { voucherId: string };

export type VoucherServiceEventsRequestCompletion = {
  voucherId: string;
  serviceEvents: ServiceEvent[];
};

const sliceName = 'service-voucher-service-events';
export interface StateSlice {
  [sliceName]: ServiceVoucherServiceEventsState;
}

const initialState: ServiceVoucherServiceEventsState = {};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    voucherServiceEventsRequested(state, action: PayloadAction<VoucherServiceEventsRequestedPayload>) {
      return {
        ...state,
        [action.payload.voucherId]: { status: ServiceEventsStatus.Loading },
      };
    },
    voucherServiceEventsRefreshRequested(state, action: PayloadAction<VoucherServiceEventsRefreshRequestedPayload>) {
      const { voucherId } = action.payload;
      const voucherState = state[voucherId];
      switch (voucherState?.status) {
        case ServiceEventsStatus.Loaded:
          return {
            ...state,
            [action.payload.voucherId]: {
              status: ServiceEventsStatus.Refreshing,
              serviceEvents: voucherState.serviceEvents,
            },
          };

        default:
          return state;
      }
    },
    voucherServiceEventsRequestCompleted(state, action: PayloadAction<VoucherServiceEventsRequestCompletion>) {
      const { voucherId } = action.payload;
      switch (state[voucherId]?.status) {
        case ServiceEventsStatus.Loading:
        case ServiceEventsStatus.Refreshing:
          const { voucherId, ...value } = action.payload;
          return {
            ...state,
            [voucherId]: {
              ...value,
              status: ServiceEventsStatus.Loaded,
            },
          };
        default:
          return state;
      }
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
