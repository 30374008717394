// @flow

import { findDOMNode } from 'react-dom';

/*:: export type Measurement = {
  height: number,
  width: number,
};*/

/*:: export type Cache = {
  getHeight: (number, ?number) => number,
  getWidth: (number, ?number) => number,
  has: (number, ?number) => boolean,
  set: (number, number, number, number) => void,
};*/

const getMeasurements = (instance, cache /*: Cache*/) /*: Measurement*/ => {
  const { parent, index } = instance;

  // prettier-ignore
  const node = (((((findDOMNode(instance)/*: any*/))/*: Element*/)));

  if (!node) {
    return { height: cache.getHeight(index, 0), width: cache.getWidth(index, 0) };
  }

  const child = node.getElementsByTagName('div')[0];
  const styles = window.getComputedStyle(child);
  const margin = parseInt(styles['marginTop'] || 0, 10) + parseInt(styles['marginBottom'] || 0, 10);
  const height = child.clientHeight + margin;
  const width = child.clientWidth;

  if (!cache.has(index, 0)) {
    cache.set(index, 0, width, height);

    // If size has changed, let Grid know to re-render.
    if (parent && typeof parent.invalidateCellSizeAfterRender === 'function') {
      parent.invalidateCellSizeAfterRender({
        columnIndex: 0,
        index,
      });
    }
  }

  return { height, width };
};

export const getMeasure = (cache /*: any*/) => (instance /*: any*/) => {
  const { parent, index } = instance.props;
  const { height, width } = getMeasurements(instance, cache);

  if (height !== cache.getHeight(index, 0) || width !== cache.getWidth(index, 0)) {
    cache.set(index, 0, width, height);

    if (parent && typeof parent.recomputeGridSize === 'function') {
      parent.recomputeGridSize({ columnIndex: 0, index });
    }
  }
};
