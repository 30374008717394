import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reset } from 'redux-form';
import { get } from 'lodash';

import { withAuthorization, withPermissions } from 'app/common';
import { getDescriptiveService } from 'app/utils';
import { withPagination } from 'app/transactions/with-pagination/with-pagination.hoc';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { download } from 'app/redux/downloads/downloads.actions';
import { fetchTransactions } from 'app/redux/transactions/transactions.actions';

import { TransactionsMunicipalBeneficiaryComponent } from './transactions-municipal-beneficiary.component';

export const mapStateToProps = (
  { dictionaries, transactions: { 'municipal-beneficiary': municipalBeneficiary }, permissions, users: { business } },
  { intl },
) => {
  const descriptiveServices = get(dictionaries, ['municipal/services', 'descriptiveServices'], []);
  const getDescriptiveServiceValue = service => getDescriptiveService({ intl, descriptiveServices }, service);

  const transactions = get(municipalBeneficiary, ['list'], []).map(transaction => ({
    ...transaction,
    descriptiveService: getDescriptiveServiceValue(transaction.descriptiveService),
  }));

  return {
    business,
    fetching: municipalBeneficiary && municipalBeneficiary.fetching,
    loading:
      !municipalBeneficiary ||
      !municipalBeneficiary.meta ||
      !dictionaries['transaction-types'] ||
      !dictionaries['municipal/services'],
    meta: municipalBeneficiary && municipalBeneficiary.meta,
    transactions,
    types: get(dictionaries, ['transaction-types', 'municipalBeneficiary'], []),
  };
};

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchDictionary: () => {
    dispatch(fetchDictionary('transaction-types'));
    dispatch(fetchDictionary('municipal/services', true));
  },
  fetchTransactions: params =>
    dispatch(
      singleConcurrentRequestAction(
        'transactions-municipal-beneficiary',
        fetchTransactions('municipal-beneficiary', params),
      ),
    ),
  onDownload: () =>
    dispatch(
      download({
        path: 'transactions/municipal-beneficiary',
        locale,
        filters: 'filter-municipal-beneficiary-transactions',
      }),
    ),
  resetFilters: () => dispatch(reset('filter-municipal-beneficiary-transactions')),
});

export const TransactionsMunicipalBeneficiaryContainer = compose(
  injectIntl,
  withPermissions,
  withAuthorization(['municipalBeneficiaryTransactions', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
)(TransactionsMunicipalBeneficiaryComponent);
