// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { isString } from 'lodash';

import { TileComponent } from 'app/shared';
import { currencyFormatter, getUnitTranslationId } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-create-confirm-tile.component.types';*/

function DateOfBirthComponent({ birthDate }) {
  return (
    <div className="col">
      <FormattedMessage id="COUPONS.CREATE.LABEL.DATE_OF_BIRTH" tagName="small" />
      {dateFormatter(birthDate)}
    </div>
  );
}

function GenderComponent({ gender }) {
  return (
    <div className="col">
      <FormattedMessage id="COUPONS.CREATE.LABEL.GENDER" tagName="small" />
      {gender === 'm' && <FormattedMessage id="CORE.GENDER.MALE" />}
      {gender === 'f' && <FormattedMessage id="CORE.GENDER.FEMALE" />}
      {gender === '' && '-'}
    </div>
  );
}

function PersonalIdentificationCodeComponent({ personalIdentificationCode }) {
  return (
    <div className="col">
      <FormattedMessage id="COUPONS.CREATE.LABEL.PERSONAL_IDENTIFICATION_CODE" tagName="small" />
      {personalIdentificationCode}
    </div>
  );
}

function DecisionNumberComponent({ decisionId }) {
  return (
    <div className="col">
      <FormattedMessage id="COUPONS.CREATE.LABEL.DECISION_NUMBER" tagName="small" />
      {decisionId}
    </div>
  );
}

function PriceUnitComponent({ priceUnit, individualValue }) {
  return (
    <div className="col">
      <FormattedMessage id={getUnitTranslationId(priceUnit)} tagName="small" />
      {currencyFormatter.format(individualValue)}
    </div>
  );
}

function NameComponent({ name }) {
  return (
    <div className="col">
      <FormattedMessage id="COUPONS.CREATE.LABEL.NAME" tagName="small" />
      {name}
    </div>
  );
}

export class CouponsCreateConfirmTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          {this.props.tile.birthDate && <DateOfBirthComponent birthDate={this.props.tile.birthDate} />}

          {isString(this.props.tile.gender) && <GenderComponent gender={this.props.tile.gender} />}

          {this.props.tile.personalIdentificationCode && (
            <PersonalIdentificationCodeComponent
              personalIdentificationCode={this.props.tile.personalIdentificationCode}
            />
          )}
        </div>

        <div className="row">
          <DecisionNumberComponent decisionId={this.props.tile.decisionId} />
          <PriceUnitComponent priceUnit={this.props.priceUnit} individualValue={this.props.tile.individualValue} />
        </div>

        <div className="row">{this.props.displayNameInput && <NameComponent name={this.props.tile.name} />}</div>
      </TileComponent>
    );
  }
}
