// @flow

import GoogleAnalytics from 'react-ga';
import get from 'lodash/get';

import { reportMessage } from 'app/sentry/sentry';
import { analyticsEventsDictionary } from './analytics-events.dictionary';

/*:: import type { AnalyticsEventsArea, AnalyticsEventsAreaEvents } from './handle-analytics-event.util.types.js.flow'; */

const eventRecorder = args => {
  const [category, action, label] = args;

  GoogleAnalytics.event({
    category,
    action,
    label,
  });
};

export const handleAnalyticsEvent = /*:: <Area: AnalyticsEventsArea> */ (
  area /*: Area*/,
  eventKey /*: AnalyticsEventsAreaEvents<Area>*/,
  label /*::?: string*/,
) => {
  const areaObject = analyticsEventsDictionary[area] || {};
  const eventValues = Object.values(get(areaObject, ['events', eventKey], {}));

  if (!areaObject || !eventValues.length) {
    reportMessage('Unknown Google Analytics event triggered:', { area, eventKey });
  } else {
    const eventArray = [areaObject.category, ...eventValues];
    eventRecorder(eventArray.length === 3 ? eventArray : [...eventArray, label]);
  }
};
