//@flow

import moment from 'moment';
import range from 'lodash/range';

import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type Moment from 'moment';*/
/*:: type Config = { max?: Moment | string, maxOffset?: number, min?: Moment | string, minOffset?: number };*/

export const generateYearRange = ({
  min = moment(),
  max = moment(),
  minOffset = 0,
  maxOffset = 0,
} /*: Config*/ = {}) /*: number[]*/ =>
  range(
    moment(max, DATE_VALUE_FORMAT).add(maxOffset, 'y').year(),
    moment(min, DATE_VALUE_FORMAT).subtract(minOffset, 'y').year() - 1,
  );
