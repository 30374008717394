import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { withClear } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';

import { CouponsTypesListFiltersComponent } from './coupons-types-list-filters.component';

const initialValuesSelector = query => {
  const searchParams = new URLSearchParams(query);

  return {
    active: searchParams.get('active') || '',
    name: searchParams.get('name') || '',
  };
};

const searchParamsSelector = params => {
  const searchParams = new URLSearchParams();

  // eslint-disable-next-line no-unused-vars
  for (const param in params) {
    if (params.hasOwnProperty(param) && !!params[param]) {
      searchParams.set(param, params[param]);
    }
  }

  return searchParams.toString();
};

const onChange = (dispatch, body) =>
  dispatch(
    push({
      search: searchParamsSelector(body),
      state: {
        keepScrollPosition: true,
      },
    }),
  );

const mapStateToProps = ({ accounts }, { location: { search } }) => ({
  balance: get(accounts, ['municipal', 'list', 'balance']),
  initialValues: initialValuesSelector(search),
});

const mapDispatchToProps = dispatch => ({
  onChange: debounce(onChange.bind(this, dispatch), 500),
});

export const CouponsTypesListFiltersContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'filter-coupon-types',
  }),
  withClear,
)(CouponsTypesListFiltersComponent);
