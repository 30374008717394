// @flow

import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import moment from 'moment';

import 'moment/locale/fi';
import 'moment/locale/sv';

import { handleAnalyticsEvent, reportApplicationCrashed } from 'app/utils';
import { ErrorComponent, NotFoundComponent, SplashComponent } from 'app/core';

import { AuthComponent } from 'app/auth/auth.component';
import { CoreContainer } from 'app/core/core.container';
import { CouponsCheckBalanceContainer } from 'app/coupons/check-balance/coupons-check-balance.container';
import { reportException } from 'app/sentry/sentry';
import { TermsContainer } from './coupons/terms/terms.container';
import { RegisterContainer } from './register/register.container';
import { TokenRefreshTimerContainer } from './core/token-refresh-timer/token-refresh-timer.container';
import { UserInactivityTimerContainer } from './core/user-inactivity-timer/user-inactivity-timer.container';
import { IntlContextProvider } from 'app/context';
import { TranslationProviderComponent } from 'app/translations/translation-provider.component';

import { AppContext } from './app.context';

/*:: import type { HandleErrorInfo, Props, State } from './app.component.types';*/

export class AppComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      crashed: false,
      locale: {
        // $FlowFixMe
        value: localStorage.getItem('language') || 'fi',
        update: this.updateLocale,
      },
    };

    document.getElementsByTagName('html')[0].lang = this.state.locale.value;
    moment.locale(this.state.locale.value);
  }

  componentDidMount() {
    if (this.props.accessToken) {
      this.props.fetchCurrentUser();
    }

    this.props.fetchTranslations('en');
    this.props.fetchTranslations('fi');
    this.props.fetchTranslations('sv');
  }

  componentDidUpdate(prevProps /*: Props*/) {
    const { location } = this.props;

    if (location !== prevProps.location && !(location.state && location.state.keepScrollPosition)) {
      setTimeout(() => {
        // $FlowFixMe
        if (!document.body.classList.contains('modal-open')) {
          window.scrollTo(0, 0);
        }
      });
    }

    if (location.state && location.state.hasOwnProperty('crashed') && this.state.crashed !== location.state.crashed) {
      this.setState({ crashed: location.state.crashed });
    }
  }

  componentDidCatch(error /*: Error*/, extra /*: HandleErrorInfo*/) {
    this.setState({ crashed: true });
    reportApplicationCrashed();
    reportException(error, extra);
  }

  updateLocale = (locale /*: 'en' | 'fi' | 'sv'*/) =>
    this.setState(
      (prev /*: State*/) => ({
        ...prev,
        locale: {
          ...prev.locale,
          value: locale,
        },
      }),
      () => {
        try {
          localStorage.setItem('language', locale);
        } catch (err) {}

        moment.locale(locale);
        window.scrollTo(0, 0);

        handleAnalyticsEvent('settings', 'languageChange', locale);

        document.getElementsByTagName('html')[0].lang = locale;
      },
    );

  render() {
    if (this.props.loading) {
      return <SplashComponent />;
    }

    const messages = this.props.translations[this.state.locale.value];
    const crashed = this.state.crashed || this.props.error;

    return (
      <AppContext.Provider value={this.state.locale}>
        <TranslationProviderComponent locale={this.state.locale.value} messages={messages}>
          <IntlContextProvider>
            <TokenRefreshTimerContainer />
            <UserInactivityTimerContainer />
            {crashed && <ErrorComponent />}
            {crashed || (
              <Switch>
                <Route path="/register/service-provider" component={RegisterContainer} />
                <Redirect from="/register" to="/register/service-provider" />
                <Route path="/(login|request-password|new-password)" component={AuthComponent} />
                <Route path="/coupons/check-balance" component={CouponsCheckBalanceContainer} exact />
                <Route path="/coupons/terms" component={TermsContainer} exact />
                <Route path="/not-found" component={NotFoundComponent} />
                <Route path="/" component={CoreContainer} />
              </Switch>
            )}
          </IntlContextProvider>
        </TranslationProviderComponent>
      </AppContext.Provider>
    );
  }
}
