// @flow

import React, { Component } from 'react';

import get from 'lodash/get';

import { convertParams } from 'app/utils';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { FiltersType, Props, State } from './with-pagination.hoc.types';*/

export const withPagination = (WrappedComponent /*: ComponentType<any>*/) =>
  class Pagination extends Component /*:: <Props, State>*/ {
    initialState /*: State*/ = {
      params: {
        limit: 10,
      },
    };

    constructor(props /*: Props*/) {
      super(props);

      this.state = this.initialState;
    }

    componentDidMount() {
      this.props.fetchTransactions(this.state.params);
    }

    componentDidUpdate(prevProps /*: Props*/) {
      if (
        prevProps.business !== this.props.business ||
        (get(prevProps.location, ['state', 'success', 'type']) !== 'TRANSACTION_REFUNDED' &&
          get(this.props.location, ['state', 'success', 'type']) === 'TRANSACTION_REFUNDED')
      ) {
        this.setState({ ...this.initialState }, () => this.props.fetchTransactions(this.state.params));
      }
    }

    onLoadMore = () =>
      this.setState(
        (prev /*: State*/) => ({
          params: {
            ...prev.params,
            offset: (prev.params.offset || 0) + prev.params.limit,
          },
        }),
        () => this.props.fetchTransactions(this.state.params),
      );

    onFiltersChange = (params /*: FiltersType*/) =>
      this.setState(
        prev => ({
          ...prev,
          params: {
            ...convertParams(params),
            limit: 10,
            offset: 0,
          },
        }),
        () => this.props.fetchTransactions(this.state.params),
      );

    render() {
      return <WrappedComponent {...this.props} onLoadMore={this.onLoadMore} onFiltersChange={this.onFiltersChange} />;
    }
  };
