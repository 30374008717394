//@flow
import React, { useState } from 'react';
import Sticky from 'react-stickynode';
import { FormattedMessage } from 'react-intl';
import { combine, format } from 'redux-form-validators';
import { Field, FormSpy } from 'react-final-form';

import { ButtonComponent, ConnectedInputComponent, Typography } from 'app/shared';

import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { length } from 'app/utils';

import styles from './coupons-mass-charge-header-sticky.module.scss';
/*:: import type { Props } from './coupons-mass-charge-header-sticky.types';*/

export const CouponsMassChargeStickyHeader = (props /*: Props*/) => {
  const [sticky, setSticky] = useState(false);

  const { total, selected } = props;

  const handleStateChange = ({ status }) => {
    if (status === Sticky.STATUS_FIXED) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  return (
    <Sticky innerZ="9" onStateChange={handleStateChange}>
      <CardComponent withShadow={sticky}>
        <CardBodyComponent>
          <div className={styles.wrapper}>
            <Typography tag="h4" color="secondary" type="largeTextBold" className={styles.header}>
              <FormattedMessage id="COUPONS.LIST.COUNTER" values={{ total }} />

              {!!selected && (
                <>
                  {' - '}
                  <FormattedMessage id="COUPONS.LIST.NEW_COUNTER" values={{ total: selected }} />
                </>
              )}
            </Typography>
            <FormSpy subscription={{ submitting: true }}>
              {({ submitting, form: { submit } }) => (
                <div className={styles.fields}>
                  <Field
                    className={styles.reference}
                    name="reference"
                    component={ConnectedInputComponent}
                    label="COUPONS.MASS_CHARGE.LABEL.REFERENCE"
                    validate={combine(
                      format({ without: /\s/i, message: 'VALIDATION.SPACES_NOT_ALLOWED' }),
                      length({ max: 255 }),
                    )}
                  />
                  <ButtonComponent
                    className="ml-3"
                    disabled={!selected}
                    loading={submitting}
                    onClick={submit}
                    testId="confirm"
                  >
                    <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" />
                  </ButtonComponent>
                </div>
              )}
            </FormSpy>
          </div>
        </CardBodyComponent>
      </CardComponent>
    </Sticky>
  );
};
