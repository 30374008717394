import { JsonFormsCore, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonForms as BaseJsonForms } from '@jsonforms/react';
import {
  JsonFormsStyleContext,
  StyleContext,
  vanillaCells,
  vanillaRenderers,
  vanillaStyles,
} from '@jsonforms/vanilla-renderers';
import AJV from 'ajv';
import ajvErrors from 'ajv-errors';
import ajvKeywords from 'ajv-keywords';
import Draft4 from 'ajv/lib/refs/json-schema-draft-04.json';
import React, { useMemo } from 'react';
import { FormData, FormFieldValidationError } from 'v2/types/postpay';
import { DateControlRenderer, dateControlTester } from './date-control.renderer';
import { DateRangeRenderer, dateRangeTester } from './date-range.renderer';
import { HorizontalLayoutRenderer, horizontalLayoutTester } from './horizontal-layout.renderer';
import horizontalLayoutStyles from './horizontal-layout.renderer.module.scss';
import { InputControlRenderer, inputControlTester } from './input-control.renderer';
import styles from './jsonforms.module.scss';
import { ListControlRenderer, listControlTester } from './list-control.renderer';
import { ReadonlyControlRenderer, readonlyControlTester } from './readonly-control.renderer';
import readonlyControlStyles from './readonly-control.renderer.module.scss';

export type JsonFormsProps = {
  schema: JsonSchema;
  uischema?: UISchemaElement;
  onChange?: ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => void;
  data: FormData | undefined;
  externalValidationErrors?: FormFieldValidationError[];
  readonly?: boolean;

  // isDynamic affects external errors visibility, whether external error should be
  // hidden after field is changed or not. Dynamic forms, where we are constantly
  // sending data to the server and receiving new errors, should not hide external errors.
  isDynamic?: boolean;
};

export const JsonFormsExternalErrorsContext = React.createContext<{
  isDynamic: boolean;
  externalErrors: FormFieldValidationError[];
}>({ isDynamic: false, externalErrors: [] });

export const JsonForms = ({
  schema,
  uischema,
  onChange,
  data,
  readonly = false,
  externalValidationErrors = [],
  isDynamic = true,
}: JsonFormsProps) => {
  const renderers = [
    ...vanillaRenderers,
    { renderer: ListControlRenderer, tester: listControlTester },
    { renderer: InputControlRenderer, tester: inputControlTester },
    { renderer: DateRangeRenderer, tester: dateRangeTester },
    { renderer: HorizontalLayoutRenderer, tester: horizontalLayoutTester },
    { renderer: DateControlRenderer, tester: dateControlTester },
    { renderer: ReadonlyControlRenderer, tester: readonlyControlTester },
  ];

  const ajv = useMemo(() => {
    // See https://jsonforms.io/docs/validation/
    // for the AJV constructor options
    //
    // We use ajv-errors to handle localization of the error messages.
    // Localized labels and errors messages are defined in the schema,
    // so that all localization related logic is done in the API.
    const ajv = new AJV({
      schemaId: 'auto',
      allErrors: true,
      jsonPointers: true,
      errorDataPath: 'property',
      verbose: true,
      $data: true,
    });
    ajv.addFormat('time', '^([0-1][0-9]|2[0-3]):[0-5][0-9]$');
    ajv.addMetaSchema(Draft4);
    ajvErrors(ajv);
    ajvKeywords(ajv);
    return ajv;
  }, []);

  const styleContextValue: StyleContext = {
    styles: [
      ...vanillaStyles,
      {
        name: 'vertical.layout',
        classNames: [styles.layout, styles.verticalLayout],
      },
      {
        name: 'vertical.layout.item',
        classNames: [styles.verticalLayoutItem],
      },
      {
        name: 'horizontal.layout',
        classNames: ['row', horizontalLayoutStyles.row, styles.layout],
      },
      {
        name: 'horizontal.layout.item',
        classNames: [
          'col',
          horizontalLayoutStyles.col,
          horizontalLayoutStyles.horizontalLayoutItem,
          readonlyControlStyles.horizontalLayoutItem,
        ],
      },
    ],
  };

  return (
    <JsonFormsStyleContext.Provider value={styleContextValue}>
      <JsonFormsExternalErrorsContext.Provider value={{ isDynamic, externalErrors: externalValidationErrors }}>
        <BaseJsonForms
          ajv={ajv}
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={renderers}
          cells={vanillaCells}
          onChange={({ data, errors }) => {
            if (onChange) {
              onChange({ data, errors });
            }
          }}
          readonly={readonly}
        />
      </JsonFormsExternalErrorsContext.Provider>
    </JsonFormsStyleContext.Provider>
  );
};
