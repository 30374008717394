// @flow

import React from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-input-mask';
import omit from 'lodash/omit';

import { InputBaseControl } from 'app/shared';
import { INPUT_PROPS_OMIT_KEYS } from 'app/constants';

/*:: import type { InputComponentProps, InputProps } from './input.component.types';*/
import styles from './input.module.scss';

export const Input = (props /*: InputProps*/) => {
  const { inputRef, disabled, className, mask, type = 'text', ...otherProps } = props;

  const inputClassNames = classNames('form-control', styles.input, className);

  return (
    <>
      {disabled && (
        <span className="input-group-prepend">
          <span className="input-group-text">
            <i className="fi fi-pen-disabled" />
          </span>
        </span>
      )}
      <MaskedInput
        {...omit(otherProps, INPUT_PROPS_OMIT_KEYS)}
        {...mask}
        className={inputClassNames}
        disabled={disabled}
        inputRef={inputRef}
        type={type}
      />
    </>
  );
};

Input.defaultProps = {
  mask: {},
};

export const InputComponent = (props /*: InputComponentProps*/) => <InputBaseControl component={Input} {...props} />;
