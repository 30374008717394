// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { Route, Switch } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { Modal } from 'app/shared/modal-new/modal.container';
import { SidebarContainer } from 'app/shared/sidebar/sidebar.container';
import { TransactionDetailsModalContainer } from 'app/transactions/details/transaction-details-modal.container';
import { AccountTransactionsFiltersContainer } from './filters/account-transactions-filters.container';
import { AccountTransactionsPlaceholder } from './placeholder/account-transactions.placeholder';
import { AccountTransactionsTableComponent } from './table/account-transactions-table.component';
import { AccountTransactionsTileComponent } from './tile/account-transactions-tile.component';

import { PageHeaderComponent } from 'app/core';
import { ListComponent, LoadMoreComponent, SeparatorComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './account-transactions.component.types';*/

export class AccountTransactionsComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchTransactions({ limit: 10 });
      this.props.resetFilters();
    }
  }

  render() {
    const noResults = this.props.loading || !this.props.transactions.length;

    return (
      <>
        <PageHeaderComponent title="ACCOUNTS.ACCOUNT_TRANSACTIONS" />
        <CardComponent>
          <AccountTransactionsFiltersContainer
            onChange={debounce(this.props.onFiltersChange, 500)}
            types={this.props.types}
          />
        </CardComponent>
        <SeparatorComponent />
        <CardComponent>
          {this.props.loading && <AccountTransactionsPlaceholder />}

          {this.props.loading || (
            <>
              <CardHeaderComponent
                title={{
                  id: 'TRANSACTIONS.COUNTER',
                  values: { count: this.props.transactions.length, total: this.props.meta.total },
                }}
              />
              <SeparatorComponent />
              <CardBodyComponent>
                <ListComponent
                  list={this.props.transactions}
                  table={AccountTransactionsTableComponent}
                  tile={AccountTransactionsTileComponent}
                  props={{
                    pathname: this.props.location.pathname,
                    permissions: this.props.permissions,
                  }}
                />

                {this.props.transactions.length < this.props.meta.total && (
                  <>
                    <SeparatorComponent />
                    <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
                  </>
                )}
              </CardBodyComponent>
            </>
          )}
        </CardComponent>

        <SidebarContainer
          name="accounts-municipal"
          tabs={[
            {
              label: <FormattedMessage id="COUPONS.SIDEBAR.EXCEL.TITLE" />,
              icon: 'fi-download-excel',
              onClick: this.props.onDownload,
              disabled: noResults,
            },
          ]}
        />

        {this.props.account.accountNumber && (
          <Switch>
            <Route
              path={`${this.props.match.url}/:transaction`}
              render={props => (
                <Modal
                  component={TransactionDetailsModalContainer}
                  componentProps={{
                    ...props,
                    pathname: this.props.match.url,
                    number: this.props.account.accountNumber,
                  }}
                  isOpen={true}
                />
              )}
            />
          </Switch>
        )}
      </>
    );
  }
}
