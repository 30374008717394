// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedInputComponent, ConnectedSelectComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

import { ButtonComponent } from 'app/shared';

/*:: import type { Props } from './coupons-types-list-filters.component.types';*/

export class CouponsTypesListFiltersComponent extends Component /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const options = [
      {
        id: '1',
        name: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.ACTIVE' }),
      },
      {
        id: '0',
        name: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.PASSIVE' }),
      },
    ];

    return (
      <CardComponent>
        <CardHeaderComponent title="COUPONS.TYPES.TITLE">
          <ButtonComponent size="small" theme="secondary-outline" onClick={this.props.onClear}>
            <FormattedMessage id="CORE.CLEAR_FILTERS" />
          </ButtonComponent>
        </CardHeaderComponent>

        <SeparatorComponent />

        <CardBodyComponent>
          <form onSubmit={this.props.handleSubmit(this.props.onChange)}>
            <div className="row">
              <div className={columnClassNames}>
                <Field name="name" component={ConnectedInputComponent} label="COUPONS.TYPES.TYPE" />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="active"
                  component={ConnectedSelectComponent}
                  label="COUPONS.TYPES.LABEL.STATUS"
                  options={options}
                  withClearOption
                />
              </div>
            </div>
          </form>
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
