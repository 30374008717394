import React from 'react';

import { PlaceholderComponent, TileComponent } from 'app/shared/index';
import { FormattedMessage } from 'react-intl';
import { ButtonComponent } from 'app/shared';

export const PricelistsTilePlaceholder = () => {
  return (
    <TileComponent>
      <div className="row">
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.STATUS" tagName="small" />
          <PlaceholderComponent />
        </div>
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.VENUE_NAME" tagName="small" />
          <PlaceholderComponent />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormattedMessage id="PRICELISTS.MANAGE.LIST.SERVICES" tagName="small" />
          <PlaceholderComponent />
        </div>
        <div className="col-auto">
          <ButtonComponent theme="secondary-outline" disabled>
            <i className="fi fi-open" />
            <FormattedMessage id="PRICELISTS.MANAGE.LIST.OPEN" />
          </ButtonComponent>
        </div>
      </div>
    </TileComponent>
  );
};
