// @flow

import { RSAA } from 'redux-api-middleware';

import * as types from './transactions.action-types';

export const fetchTransactions = (type /*: 'company' | 'municipal' | 'visa'*/, params /*::?: Object*/) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_TRANSACTIONS_REQUEST,
        meta: {
          params,
          type,
          shouldReset: !params || !params.offset,
        },
      },
      {
        type: types.FETCH_TRANSACTIONS_SUCCESS,
        meta: {
          type,
          shouldMerge: params && params.offset,
        },
      },
      {
        type: types.FETCH_TRANSACTIONS_FAILURE,
        meta: {
          type,
        },
      },
    ],
    endpoint: `/transactions/${type}`,
    method: 'GET',
  },
});

export const fetchTransaction = (account /*: number | string*/, transaction /*: number | string*/) => ({
  [RSAA]: {
    types: [
      {
        type: types.FETCH_TRANSACTION_REQUEST,
        meta: {
          transaction,
        },
      },
      {
        type: types.FETCH_TRANSACTION_SUCCESS,
        meta: {
          transaction,
        },
      },
      types.FETCH_TRANSACTION_FAILURE,
    ],
    endpoint: `/transactions/account/${account}/${transaction}`,
    method: 'GET',
  },
});

export const refundTransaction = (account /*: number | string*/, transaction /*: number | string*/) => ({
  [RSAA]: {
    types: [
      {
        type: types.REFUND_TRANSACTION_REQUEST,
        meta: {
          transaction,
        },
      },
      {
        type: types.REFUND_TRANSACTION_SUCCESS,
        meta: {
          transaction,
        },
      },
      {
        type: types.REFUND_TRANSACTION_FAILURE,
        meta: {
          transaction,
        },
      },
    ],
    endpoint: `/transactions/account/${account}/${transaction}/refund`,
    method: 'POST',
  },
});
