// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { isBusinessDifferent } from 'app/utils';
import { ROUTES } from 'app/constants';

import { CouponsCreateConfirmContainer } from './confirm/coupons-create-confirm.container';
import { CouponsCreateFormContainer } from './form/coupons-create-form.container';
import { CouponsCreatePlaceholderContainer } from './placeholder/coupons-create-placeholder.container';

/*:: import type { BodyType, Props, State, Step } from './coupons-create.component.types';*/

export class CouponsCreateComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      step: 'FORM',
    };
  }

  componentDidMount() {
    this.props.fetchCouponType();
    this.props.fetchCurrentCustomer();
    this.props.fetchDictionary();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.onBusinessChange();
    }
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  changeStep = (step /*: Step*/) => {
    this.setState({ step });
    window.scrollTo(0, 0);
  };

  onValidate = (body /*: BodyType*/) => this.props.onValidate(body).then(() => this.changeStep('CONFIRM'));

  onBack = () => this.state.step === 'CONFIRM' && this.changeStep('FORM');

  render() {
    return (
      <>
        {this.props.loading && <CouponsCreatePlaceholderContainer />}
        {this.props.loading || (
          <>
            {this.state.step === 'FORM' && (
              <>
                <PageHeaderComponent title="COUPONS.CREATE.TITLE" backLink={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT} />
                <form onSubmit={this.props.handleSubmit(this.onValidate)}>
                  <CouponsCreateFormContainer submitting={this.props.submitting} />
                </form>
              </>
            )}
            {this.state.step === 'CONFIRM' && (
              <>
                <PageHeaderComponent title="COUPONS.CREATE.TITLE" />
                <form onSubmit={this.props.handleSubmit}>
                  <CouponsCreateConfirmContainer onBack={this.onBack} submitting={this.props.submitting} />
                </form>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
