//@flow

import React from 'react';

import { CheckboxComponent, MultiControlsBase } from 'app/shared';

/*:: import type { Props } from './checkboxes.component.types';*/

export const CheckboxesComponent = (props /*: Props*/) => (
  <MultiControlsBase {...props} component={CheckboxComponent} />
);
