// @flow

import { logoutUser, refreshToken } from 'app/redux/users/users.actions';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { TokenRefreshTimerComponent } from './token-refresh-timer.component';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const mapStateToProps = ({ users: { nextSessionExpirationTime } }) => ({
  nextSessionExpirationTime,
});
const mapDispatchToProps = dispatch => ({
  refreshToken: () => dispatch(refreshToken()),
  logout: () => dispatch(logoutUser(true, 'INACTIVITY')),
});

export const TokenRefreshTimerContainer = compose(connect(mapStateToProps, mapDispatchToProps))(
  TokenRefreshTimerComponent,
);
