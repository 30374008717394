// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { TileComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-check-balance-transactions-tile.component.types';*/

export class CouponsCheckBalanceTransactionsTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CHARGED" tagName="small" />
            {dateFormatter(this.props.tile.date)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.TYPE" tagName="small" />
            <FormattedMessage id={`TRANSACTION_TYPE.${this.props.tile.type}`} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.AMOUNT" tagName="small" />
            {currencyFormatter.format(this.props.tile.amount)}
          </div>
        </div>
      </TileComponent>
    );
  }
}
