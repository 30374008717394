//@flow

import React from 'react';

import { SelectionControlBase } from 'app/shared';
import { MultiControlsBaseGroup } from './group/multi-controls-base-group';

/*:: import type { Props } from './multi-controls-base.types';*/
import styles from './multi-controls-base.module.scss';

export const MultiControlsBase = (props /*: Props*/) => (
  <SelectionControlBase
    {...props}
    component={MultiControlsBaseGroup}
    className={styles.wrapper}
    itemComponent={props.component}
    multi
  />
);

MultiControlsBase.defaultProps = {
  labelKey: 'name',
  labelPosition: 'top',
  variant: 'auto',
};
