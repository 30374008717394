// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FieldArray, FormSection } from 'redux-form';
import { required } from 'app/utils';
import classNames from 'classnames';

import {
  ButtonComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ConnectedTextareaComponent,
  SeparatorComponent,
} from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ContractsListsComponent, VenueAdministratorContainer } from 'app/register/shared';

/*:: import type { Props, Settlement } from './venue-form.component.types';*/
import styles from './venue-form.component.module.scss';

export class VenueFormComponent extends PureComponent /*:: <Props>*/ {
  getSettlementsOptions(settlements /*: Settlement[]*/) {
    if (!settlements) {
      return [];
    }

    // prettier-ignore
    return settlements.map/*:: <Settlement>*/(({ id, name }) => ({
      id,
      name: this.props.intl.formatMessage({ id: `SETTLEMENT.${name}` }),
    }));
  }

  render() {
    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    const isEditVenueMode = !!this.props.venue;

    return (
      <CardComponent>
        <form onSubmit={this.props.handleSubmit}>
          <CardHeaderComponent title="VENUES.GENERAL_INFORMATION" />
          <SeparatorComponent />
          <CardBodyComponent>
            <FormSection name="venue">
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="officialName"
                    component={ConnectedInputComponent}
                    label="VENUES.OFFICIAL_NAME"
                    placeholder="VENUES.OFFICIAL_NAME"
                    disabled={isEditVenueMode}
                    required
                    validate={required()}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="name"
                    component={ConnectedInputComponent}
                    label="VENUES.NAME"
                    placeholder="VENUES.NAME"
                    disabled={isEditVenueMode}
                    required
                    validate={required()}
                  />
                </div>
              </div>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="address.address"
                    component={ConnectedInputComponent}
                    label="VENUES.STREET_ADDRESS"
                    placeholder="VENUES.STREET_ADDRESS"
                    required
                    validate={required()}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="address.zipCode"
                    component={ConnectedInputComponent}
                    label="VENUES.ZIP_CODE"
                    placeholder="VENUES.ZIP_CODE"
                    required
                    validate={required()}
                  />
                </div>
              </div>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="address.city"
                    component={ConnectedInputComponent}
                    label="VENUES.CITY"
                    placeholder="VENUES.CITY"
                    required
                    validate={required()}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="website"
                    component={ConnectedInputComponent}
                    label="VENUES.WEBSITE"
                    placeholder="VENUES.WEBSITE"
                  />
                </div>
              </div>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="email"
                    component={ConnectedInputComponent}
                    label="VENUES.GENERAL_EMAIL_ADDRESS"
                    placeholder="VENUES.GENERAL_EMAIL_ADDRESS"
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="phone"
                    component={ConnectedInputComponent}
                    label="VENUES.PHONE"
                    placeholder="VENUES.PHONE"
                    append="PROFILE.PHONE_NUMBER_FORMAT"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Field
                    name="description"
                    className={styles.description}
                    component={ConnectedTextareaComponent}
                    label="VENUES.DESCRIPTION"
                    append="VENUES.DESCRIPTION_ADDON"
                    disabled
                    showCounter={false}
                    maxLength={5000}
                  />
                </div>
              </div>
            </FormSection>
          </CardBodyComponent>
          <FormSection name="venue">
            <SeparatorComponent />
            <CardHeaderComponent title="VENUES.CONTACT_PERSON" />
            <SeparatorComponent />
            <CardBodyComponent>
              <div className="row">
                <div className={columnClassNames}>
                  <Field
                    name="contactName"
                    component={ConnectedInputComponent}
                    label="VENUES.CONTACT_PERSON_NAME"
                    placeholder="VENUES.CONTACT_PERSON_NAME"
                    required
                    validate={required()}
                  />
                </div>
                <div className={columnClassNames}>
                  <Field
                    name="contactPhone"
                    component={ConnectedInputComponent}
                    label="VENUES.CONTACT_PERSON_PHONE"
                    placeholder="VENUES.CONTACT_PERSON_PHONE"
                    required
                    validate={required()}
                  />
                </div>
              </div>
            </CardBodyComponent>
          </FormSection>

          {isEditVenueMode && (
            <FormSection name="venue">
              <SeparatorComponent />
              <CardHeaderComponent title="VENUES.SETTLEMENT_INFORMATION" />
              <SeparatorComponent />
              <CardBodyComponent>
                <div className="row">
                  <div className={columnClassNames}>
                    <Field
                      name="settlement"
                      component={ConnectedSelectComponent}
                      options={this.getSettlementsOptions(this.props.dictionary.settlements)}
                      label="VENUES.SETTLEMENT_FREQUENCY"
                      required
                    />
                  </div>
                  <div className={columnClassNames}>
                    <Field
                      name="settlementAmount"
                      component={ConnectedInputComponent}
                      label="VENUES.SETTLEMENT_MINIMUM_SUM"
                      placeholder="VENUES.SETTLEMENT_MINIMUM_SUM"
                      required
                      validate={required()}
                    />
                  </div>
                </div>
              </CardBodyComponent>
            </FormSection>
          )}

          {isEditVenueMode || (
            <>
              <FieldArray name="admins" component={VenueAdministratorContainer} />
              <SeparatorComponent height={48} />
              <FieldArray name="contracts" component={ContractsListsComponent} intl={this.props.intl} />
            </>
          )}
          <CardFooterComponent justifyContent="flex-end">
            <ButtonComponent theme="primary" loading={this.props.submitting}>
              <FormattedMessage id="CORE.SAVE" />
            </ButtonComponent>
          </CardFooterComponent>
        </form>
      </CardComponent>
    );
  }
}
