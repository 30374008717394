//@flow
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import classNames from 'classnames';

import { CheckboxComponent, ConnectedCheckboxComponent } from 'app/shared';
import { MassEditContainer } from '../mass-edit/mass-edit.container';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { CouponsMassChargeStickyHeader } from './sticky/coupons-mass-charge-header-sticky';

import styles from './coupons-mass-charge-header.module.scss';
/*:: import type { Props } from './coupons-mass-charge-header.types';*/

export const CouponsMassChargeHeaderComponent = (props /*: Props*/) => {
  const { onSelectAll, isAllChecked, ...otherProps } = props;

  return (
    <>
      <CardComponent withShadow={false}>
        <CouponsMassChargeStickyHeader {...otherProps} />
        <CardBodyComponent>
          <FormSpy subscription={{}} coupons={props.coupons} component={MassEditContainer} />

          <div className="d-flex mt-4">
            <div className={classNames('d-flex flex-column flex-sm-row mr-auto')}>
              <Field
                name="showChargeable"
                component={ConnectedCheckboxComponent}
                label="COUPONS.MASS_CHARGE.LIST_FILTERS.CHARGEABLE"
                hideValidation
              />
              <Field
                className="ml-4"
                name="showInvalidated"
                component={ConnectedCheckboxComponent}
                label="COUPONS.MASS_CHARGE.LIST_FILTERS.INVALIDATED"
                hideValidation
              />
              <Field
                className="ml-4"
                name="showValidInFuture"
                component={ConnectedCheckboxComponent}
                label="COUPONS.MASS_CHARGE.LIST_FILTERS.VALID_IN_FUTURE"
                hideValidation
              />
            </div>
            <div className={classNames(styles.selectAll)}>
              <CheckboxComponent
                checked={isAllChecked}
                component={ConnectedCheckboxComponent}
                onChange={onSelectAll}
                label={{
                  id: 'CORE.SELECT_ALL',
                  additionalValues: { checked: isAllChecked },
                }}
                labelPosition="left"
                hideValidation
              />
            </div>
          </div>
        </CardBodyComponent>
      </CardComponent>
    </>
  );
};
