// @flow

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';
// $FlowFixMe
import { ReactComponent as Loading } from 'assets/images/loading.svg';

import styles from './splash.module.scss';

export class SplashComponent extends PureComponent /*:: <any>*/ {
  /*:: portal: ?HTMLElement;*/
  /*:: element: HTMLDivElement;*/

  constructor(props /*: any*/) {
    super(props);

    this.portal = document.getElementById('modal-root');
    this.element = document.createElement('div');
  }

  componentDidMount() {
    if (this.portal) {
      this.portal.appendChild(this.element);
    }
  }

  componentWillUnmount() {
    if (this.portal) {
      this.portal.removeChild(this.element);
    }
  }

  render() {
    return ReactDOM.createPortal(
      <div className={styles.splash}>
        <div className={styles.loading}>
          <Loading />
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>,
      this.element,
    );
  }
}
