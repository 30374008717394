import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppContainer } from './app/app.container';
import { withTracker } from './app/common';
import { ReduxContext } from './app/context';

export function indexComponent(store, history) {
  return <Provider store={store}>
    <ReduxContext.Provider value={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path='/coupons/check-balance' component={AppContainer} />
          <Route component={withTracker(AppContainer)} />
        </Switch>
      </ConnectedRouter>
    </ReduxContext.Provider>
  </Provider>;
}
