import { Route, Switch } from 'react-router-dom';
import { CreateServiceVoucherPage } from './issuing/create-service-voucher.page';
import { SelectServiceVoucherTypePage } from './issuing/select-service-voucher-type.page';
import { PreviewVoucherPage } from './issuing/preview-voucher.page';
import { ServiceVoucherPageComponent } from './service-voucher.page';
import { PrintServiceVoucherPage } from './print-service-voucher.page';
import { IssuedServiceVouchersPage } from './search/issued-service-vouchers.page';

export type ServiceVouchersRouter = {
  match: {
    url: string;
  };
};

export const ServiceVouchersRouterComponent = (props: ServiceVouchersRouter): JSX.Element => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}/types`} component={SelectServiceVoucherTypePage} />
      <Route exact path={`${props.match.url}/:id/create`} component={CreateServiceVoucherPage} />
      <Route exact path={`${props.match.url}/:id`} component={ServiceVoucherPageComponent} />
      <Route exact path={`${props.match.url}`} component={IssuedServiceVouchersPage} />
      <Route exact path={`${props.match.url}/:id/preview`} component={PreviewVoucherPage} />
      <Route exact path={`${props.match.url}/:id/print`} component={PrintServiceVoucherPage} />
    </Switch>
  );
};
