// @flow

import React, { PureComponent } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import { VirtualizedListComponent } from 'app/shared/index';
import { CouponsListRowComponent } from './coupons-list-row/coupons-list-row.component';

/*:: import type { Coupon } from '../coupons-list.component.types';*/
/*:: import type { Props, State } from './coupons-list-virtualized.types';*/

import 'react-virtualized/styles.css';

export class CouponsVirtualizedListComponent extends PureComponent /*:: <Props, State>*/ {
  rowRenderer = (coupons /*: Coupon[]*/) => (params /*: Object*/) => {
    const rowProps = {
      ...params,
      columns: this.props.columns,
      coupon: coupons[params.index],
      length: coupons.length,
      renewMode: this.props.renewMode,
    };

    return <CouponsListRowComponent {...rowProps} />;
  };

  render() {
    return (
      <FieldArray name="coupons">
        {({ fields }) => {
          //Prepare coupons data for react-virtualized
          const coupons = fields.value
            .map((field, index) => ({
              ...field,
              formIndex: index,
              errors: fields.submitError && !!fields.submitError[index],
              initialValue: {
                ...fields.initial[index],
              },
            }))
            //hide unselected coupons while in showOnlySelected mode
            .filter(coupon => !this.props.showOnlySelected || coupon.active)
            //hide invalidated buttons while in renewMode
            .filter(coupon => !this.props.renewMode || !coupon.invalidated)
            .sort((a, b) => {
              return a.errors === b.errors ? 0 : a.errors ? -1 : 1;
            });

          return (
            <>
              <VirtualizedListComponent
                rowCount={coupons.length}
                rowRenderer={this.rowRenderer(coupons)}
                data={coupons}
                columns={this.props.columns}
                defaultRowHeight={250}
                measurementTriggers={['columns']}
              />
            </>
          );
        }}
      </FieldArray>
    );
  }
}
