import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import { accountsReducer as accounts } from 'app/redux/accounts/accounts.reducer';
import { agreementsReducer as agreements } from 'app/redux/agreements/agreements.reducer';
import { archiveReducer as archive } from 'app/redux/archive/archive.reducer';
import { couponReducer as coupon } from 'app/redux/coupon/coupon.reducer';
import { couponsReducer as coupons } from 'app/redux/coupons/coupons.reducer';
import { customersReducer as customers } from 'app/redux/customers/customers.reducer';
import { dictionariesReducer as dictionaries } from 'app/redux/dictionaries/dictionaries.reducer';
import { errorReducer as error } from 'app/redux/error/error.reducer';
import { ordersReducer as orders } from 'app/redux/orders/orders.reducer';
import { permissionsReducer as permissions } from 'app/redux/permissions/permissions.reducer';
import { featuresReducer as features } from 'app/redux/features/features.reducer';
import { pricelistsReducer as pricelists } from 'app/redux/pricelists/pricelists.reducer';
import { registerReducer as register } from 'app/redux/register/register.reducer';
import { settlementsReducer as settlements } from 'app/redux/settlements/settlements.reducer';
import { transactionsReducer as transactions } from 'app/redux/transactions/transactions.reducer';
import { translationsReducer as translations } from 'app/redux/translations/translations.reducer';
import { typesReducer as types } from 'app/redux/types/types.reducer';
import { usersReducer as users } from 'app/redux/users/users.reducer';
import { venuesReducer as venues } from 'app/redux/venues/venues.reducer';
import { viewReducer as view } from 'app/redux/view/view.reducer';
import { sidebarReducer as sidebars } from 'app/redux/sidebar/sidebar.reducer';
import { modalReducer as modal } from 'app/redux/modal/modal.reducer';
import { notificationsReducer as notifications } from 'app/redux/notifications/notifications.reducer';
import { connectRouter } from 'connected-react-router';
import { v2Reducers } from 'v2/v2.reducer';
import { withEnrichedRouterSlice } from './redux/router/with-enriched-router-slice.reducer';
import { CLEAR_APPLICATION_STATE } from './redux/session/session.action-types';

const createRootReducer = history =>
  combineReducers({
    ...v2Reducers,
    // For more information see the documentation in ./redux/router/with-enriched-router-slice.reducer.js
    router: withEnrichedRouterSlice(connectRouter(history)),
    accounts,
    agreements,
    archive,
    coupon,
    coupons,
    customers,
    dictionaries,
    error,
    form,
    orders,
    permissions,
    features,
    pricelists,
    register,
    settlements,
    sidebars,
    transactions,
    translations,
    types,
    users,
    venues,
    view,
    modal,
    notifications,
  });

function clearStateOnSessionEnd(state, action) {
  switch (action.type) {
    case CLEAR_APPLICATION_STATE:
      const { translations } = state;
      return { translations };
    default:
      return state;
  }
}
export const createAppReducer = history => (state, action) => {
  // clearStateOnSessionEnd cannot be included in combineReducers
  // as it modifies the entire state, not only a single slice
  const stateAfterClearing = clearStateOnSessionEnd(state, action);
  return createRootReducer(history)(stateAfterClearing, action);
};
