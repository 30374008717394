// @flow

export class LocalStorage {
  /*:: key: string;*/

  constructor(name /*: string*/) {
    this.key = name;
  }

  set = (value /*: string*/) => {
    try {
      localStorage.setItem(this.key, value);
    } catch (e) {
      console.error(e);
    }
  };

  get = () => {
    try {
      return localStorage.getItem(this.key);
    } catch (e) {
      console.error(e);
    }

    return undefined;
  };

  destroy = () => {
    try {
      localStorage.removeItem(this.key);
    } catch (e) {
      console.error(e);
    }
  };
}
