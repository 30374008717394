// @flow
import { InternalError } from 'redux-api-middleware';
import get from 'lodash/get';

import { reportException, reportMessage } from 'app/sentry/sentry';

/*:: import type { Middleware, Store } from 'redux';*/
/*:: import { RouterState } from 'connected-react-router'; */
import { crashApplicationUsingConnectedRouter } from '../../utils';

// We will not report 500 errors if the backend sends json data with a specific key.
// This happens in order confirmation when the backend is unable to send the email.
// In this case we want the modal to be successful and no error to be thrown.
const getCustomErrorKey = payload => get(payload, ['response', 'errors', 'errorKey']);

const isServerError = payload => get(payload, ['status']) >= 500;

const isInternalError = payload => payload instanceof Error && payload.name === new InternalError().name;

export const internalErrorMiddleware /*: Middleware*/ =
  (store /*: Store<{ router: RouterState }>*/) => next => action => {
    const currentPath = store.getState().router.location.pathname;

    if (!getCustomErrorKey(action.payload) && isServerError(action.payload)) {
      reportMessage('Internal server error: ' + currentPath, {
        serverPayload: action.payload,
        status: action.payload.status,
        currentPath: currentPath,
      });

      return crashApplicationUsingConnectedRouter(store, currentPath);
    }

    if (isInternalError(action.payload)) {
      reportException(action.payload, { componentStack: 'RSAA' });

      return crashApplicationUsingConnectedRouter(store, currentPath);
    }

    return next(action);
  };
