// @flow

import React, { PureComponent } from 'react';

import { PageHeaderComponent } from 'app/core';
import { UsersListContainer } from 'app/common/users';
import { PlaceholderComponent } from 'app/shared';

/*:: import type { Props } from './venue-users.placeholder.types';*/

export class VenueUsersPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    users: [...new Array(5)],
  };

  render() {
    return (
      <>
        <PageHeaderComponent backLink="/venues">
          <PlaceholderComponent width={200} height={31} color="purple" />
        </PageHeaderComponent>

        <UsersListContainer context="venue" loading={true} />
      </>
    );
  }
}
