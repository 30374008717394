// @flow

export const analyticsEventsDictionary = {
  pricelists: {
    category: 'Pricelists',
    events: {
      //serviceProvider:
      discard: {
        action: 'Discarding changes while editing pricelist',
      },
      send: {
        action: 'Sending pricelist for approval',
      },

      //municipality:
      scheduledApprove: {
        action: 'Pricelist scheduled approval',
      },
      immediateApprove: {
        action: 'Pricelist immediate approval',
      },
      requestChange: {
        action: 'Pricelist change request',
      },
      delete: {
        action: 'Pricelist deletion',
      },
      cancelDelete: {
        action: 'Pricelist deletion cancel',
      },
    },
  },

  settings: {
    category: 'Settings',
    events: {
      languageChange: {
        action: 'Changed language',
      },
    },
  },

  vatArchive: {
    category: 'Vat archive',
    events: {
      download: {
        action: 'Download bills',
      },
      filterUsage: {
        action: 'Invoice filter usage',
      },
    },
  },

  vouchers: {
    category: 'Service vouchers',
    events: {
      download: {
        action: 'Download',
      },
      filterUsage: {
        action: 'Use vouchers filters',
      },
      displayFilterUsage: {
        action: 'Use vouchers display filter',
      },
      print: {
        action: 'Print vouchers',
      },
      send: {
        action: 'Send voucher',
        label: 'text message',
      },
    },
  },

  session: {
    category: 'session',
    events: {
      logoutDueMissedRefresh: {
        action: 'logout',
        label: 'token-refresh-missed-due-to-unexecuted-timer',
      },
      refresh: {
        action: 'refresh',
      },
      errorDueToUnanticipatedSessionExpiration: {
        action: 'Backend unexpectedly reports session token is missing or has expired, redirecting user to error page',
      },
      unexpected403Response: {
        action: 'Backend unexpectedly returned a status 403 response unrelated to session handling',
      },
      backendReportedMissingOrUnknownSession: {
        action: 'Backend returned a status 403 response indicating session token missing or unknown',
      },
    },
  },

  userInactivity: {
    category: 'User inactivity',
    events: {
      showModal: {
        action: 'Show modal',
      },
      continueFromModal: {
        action: 'Continue usage from modal',
      },
      logoutCancelingModal: {
        action: 'Logout by canceling modal',
      },
      logoutClosingModal: {
        action: 'Logout by closing modal',
      },
      autoLogout: {
        action: 'Logout due to user inactivity',
      },
    },
  },

  serviceEventSubmission: {
    category: 'Service event submission',
    events: {
      housingServicesSubmitServiceEvent: {
        action: 'Submit housing services service event',
      },
      housingServicesSubmitServiceEventSucceeded: {
        action: 'Submitting housing services service event succeeded',
      },
      housingServicesSubmitServiceEventFailed: {
        action: 'Submitting housing services service event failed',
      },
      housingServicesNavigateFromHousingServicesToGenericDebitView: {
        action: 'User chose to use generic debit view for housing services voucher',
      },
      housingServicesNavigateFromGenericToHousingServicesDebitView: {
        action: 'User chose to switch back from generic debit view to housing services service event view',
      },
      housingServicesSubmitServiceEventFromGenericDebitView: {
        action: 'Service event submission using the generic debit view',
      },
    },
  },
};
