import {
  EndDateError,
  GeneralValidationError,
  ProducedServiceValidationError,
  SelectedMonthError,
  SelectedMonthFieldValue,
  ServiceEventFormValues,
  ServiceEventRow,
  ServiceEventRowValidationErrors,
  ServiceEventValidator,
  ValidateServiceEventVoucher,
} from '../types';
import moment, { Moment } from 'moment';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { isEmpty, last, mergeWith, some, sum } from 'lodash';
import { computeServicePriceForRow, ServicePriceNotComputable } from './compute-service-price';
import { VenueId } from '../../../types/venue';
import {
  getHousingServiceProducedServiceRule,
  housingServicesPaidAbsenceService,
  HousingServicesProducedService,
  MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES,
  ProducedServiceDuration,
  REQUIRED_AMOUNT_OF_PRESENCE_BETWEEN_PAID_ABSENCE_SERVICES,
  ServiceArea,
} from '../service-types';
import { Transaction } from 'v2/types/transaction';

function calculateSelectedMonth(periodStartDate: Date | undefined): SelectedMonthFieldValue {
  const defaultStartDate = new Date();
  const startDate = moment(periodStartDate || defaultStartDate).startOf('day');
  return { month: startDate.month(), year: startDate.year() };
}

function getLastValidityDateOfService(
  periodStartDate: Date | undefined,
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService,
): Moment | undefined {
  const producedServiceRule = getHousingServiceProducedServiceRule(serviceArea, producedService);
  if (producedServiceRule.serviceDuration === ProducedServiceDuration.MaxAmountOfDays) {
    return moment(periodStartDate || new Date()).add(producedServiceRule.maxAmountOfDays - 1, 'days');
  } else {
    return undefined;
  }
}

function calculateVoucherLastAllowedEndDate(
  selectedMonth: SelectedMonthFieldValue,
  voucher: { lastAllowedDateOfService: string },
) {
  const lastDateOfSelectedMonth = moment()
    .month(selectedMonth.month)
    .year(selectedMonth.year)
    .endOf('month')
    .endOf('day');
  const lastValidityDateOfVoucher = moment(voucher.lastAllowedDateOfService, DATE_VALUE_FORMAT).endOf('day');
  return lastValidityDateOfVoucher.isBefore(lastDateOfSelectedMonth)
    ? lastValidityDateOfVoucher.toDate()
    : lastDateOfSelectedMonth.toDate();
}

export const calculateLastAllowedServiceEventRowEndDate: (
  periodStartDate: Date | undefined,
  voucher: { lastAllowedDateOfService: string; serviceArea: ServiceArea },
  producedService?: HousingServicesProducedService,
) => Date = (periodStartDate, voucher, producedService) => {
  const lastValidityDateOfVoucher = calculateVoucherLastAllowedEndDate(
    calculateSelectedMonth(periodStartDate),
    voucher,
  );
  const lastValidityDateOfService = producedService
    ? getLastValidityDateOfService(periodStartDate, voucher.serviceArea, producedService)
    : undefined;

  return moment
    .min(...[moment(lastValidityDateOfVoucher), lastValidityDateOfService].filter((date): date is Moment => !!date))
    .toDate();
};

export const validateSelectedMonth = (
  selectedMonth: SelectedMonthFieldValue,
  voucher: {
    firstAllowedDateOfService: string;
    lastAllowedDateOfService: string;
  },
  transactions: Transaction[] = [],
) => {
  const isMonthWithinValidityPeriod: (
    month: Moment,
    firstAllowedDateOfService: Moment,
    lastAllowedDateOfService: Moment,
  ) => boolean = (month, firstAllowedDateOfService, lastAllowedDateOfService) => {
    return month.isBetween(
      moment(firstAllowedDateOfService).startOf('month'),
      moment(lastAllowedDateOfService).endOf('month'),
    );
  };

  const isMonthAlreadyDebited: (month: Moment, transactions: Transaction[]) => boolean = (
    selectedMonth,
    transactions,
  ) => {
    const transactionsInSelectedMonth = transactions.filter(
      t =>
        t.type === 'TRANSACTION_TYPE_PAYMENT' &&
        !t.refundedBy &&
        ((t.serviceProducedStart ? moment(t.serviceProducedStart).isSame(selectedMonth, 'month') : false) ||
          (t.serviceProducedEnd ? moment(t.serviceProducedEnd).isSame(selectedMonth, 'month') : false)),
    );
    return transactionsInSelectedMonth.length > 0;
  };

  if (selectedMonth) {
    const month = moment().month(selectedMonth.month).year(selectedMonth.year);
    const firstAllowedDateOfService = moment(voucher.firstAllowedDateOfService, DATE_VALUE_FORMAT);
    const lastAllowedDateOfService = moment(voucher.lastAllowedDateOfService, DATE_VALUE_FORMAT);

    const selectedMonthErrors = [];
    if (isMonthAlreadyDebited(month, transactions)) {
      selectedMonthErrors.push(SelectedMonthError.MonthAlreadyDebited);
    }
    if (!isMonthWithinValidityPeriod(month, firstAllowedDateOfService, lastAllowedDateOfService)) {
      selectedMonthErrors.push(SelectedMonthError.OutsideVoucherValidityPeriod);
    }

    return selectedMonthErrors.length > 0
      ? {
          selectedMonth: selectedMonthErrors,
        }
      : undefined;
  }
};

export const validateServiceEventRow = (
  row: ServiceEventRow,
  voucher: ValidateServiceEventVoucher,
): ServiceEventRowValidationErrors => {
  const endDate = row.period.endDate;
  if (!endDate) {
    return {
      period: {
        endDate: [EndDateError.UndefinedValue],
      },
    };
  }
  const lastAllowedEndDate = calculateLastAllowedServiceEventRowEndDate(
    row.period.startDate,
    voucher,
    row.producedService,
  );
  if (row.period.endDate && row.period.endDate > lastAllowedEndDate) {
    return {
      period: {
        endDate: [EndDateError.OutsideServiceValidityPeriod],
      },
    };
  }
  return {};
};

function validateIndividualRows(
  rows: ServiceEventRow[],
  voucher: ValidateServiceEventVoucher,
): ServiceEventRowValidationErrors[] {
  const serviceEventRowValidationErrors: ServiceEventRowValidationErrors[] = rows.map(row =>
    validateServiceEventRow(row, voucher),
  );
  if (some(serviceEventRowValidationErrors, error => !isEmpty(error))) {
    return serviceEventRowValidationErrors;
  }
  return [];
}

function validateNoConsecutiveRowsOfSameProducedService(rows: ServiceEventRow[]): ServiceEventRowValidationErrors[] {
  const rowsIndexesOfSameProducedServiceAsPrevious = rows.reduce<number[]>((indexes, row, index) => {
    if (index > 0) {
      const previousRow = rows[index - 1];
      if (row.producedService === previousRow.producedService) {
        return [...indexes, index];
      }
    }
    return indexes;
  }, []);

  if (rowsIndexesOfSameProducedServiceAsPrevious.length > 0) {
    return rows.map((_, index) => {
      if (rowsIndexesOfSameProducedServiceAsPrevious.includes(index)) {
        return {
          producedService: [ProducedServiceValidationError.NoConsecutiveRowsWithSameService],
        };
      }
      return {};
    });
  }

  return [];
}

export function getPeriodsOfPaidAbences(rows: ServiceEventRow[]): Array<{ rowsIndexes: number[]; duration: number }> {
  const getRowDuration = (row: ServiceEventRow): number =>
    moment(row.period.endDate).startOf('day').diff(moment(row.period.startDate).startOf('day'), 'days') + 1;

  return rows.reduce<ReturnType<typeof getPeriodsOfPaidAbences>>((periods, row, index) => {
    if (index === 0) {
      if (housingServicesPaidAbsenceService.includes(row.producedService)) {
        return [{ rowsIndexes: [index], duration: getRowDuration(row) }];
      }
      return [];
    }

    // If the current row is a presence and has the required amount of days to reset the paid absence period
    // end the current period by creating a "dummy" period
    if (
      row.producedService === HousingServicesProducedService.Present &&
      getRowDuration(row) >= REQUIRED_AMOUNT_OF_PRESENCE_BETWEEN_PAID_ABSENCE_SERVICES
    ) {
      return [...periods, { rowsIndexes: [], duration: 0 }];
    }

    if (housingServicesPaidAbsenceService.includes(row.producedService)) {
      const currentPeriod = periods.splice(-1)[0] || { rowsIndexes: [], duration: 0 };
      return [
        ...periods,
        {
          rowsIndexes: [...currentPeriod.rowsIndexes, index],
          duration: currentPeriod.duration + getRowDuration(row),
        },
      ];
    }

    return periods;
  }, []);
}

function valideMaxAmountOfConsecutivePaidAbsences(rows: ServiceEventRow[]): ServiceEventRowValidationErrors[] {
  const periodsWithMoreThanMaxAllowedDays = getPeriodsOfPaidAbences(rows).filter(
    ({ duration }) => duration > MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES,
  );

  if (periodsWithMoreThanMaxAllowedDays.length > 0) {
    return rows.map((_, index) => {
      if (periodsWithMoreThanMaxAllowedDays.some(period => period.rowsIndexes.includes(index))) {
        return {
          producedService: [ProducedServiceValidationError.ExceededMaxDaysOfPaidAbsence],
        };
      }
      return {};
    });
  }

  return [];
}

export const validateServiceEventRows: (
  rows: ServiceEventRow[],
  voucher: ValidateServiceEventVoucher,
) => { serviceEventRows: ServiceEventRowValidationErrors[] } | void = (rows, voucher) => {
  const validationErrors = mergeWith(
    [] as ServiceEventRowValidationErrors[],
    validateIndividualRows(rows, voucher),
    validateNoConsecutiveRowsOfSameProducedService(rows),
    valideMaxAmountOfConsecutivePaidAbsences(rows),
    (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    },
  );

  if (!isEmpty(validationErrors)) {
    return {
      serviceEventRows: validationErrors,
    };
  }
};

const convertToEurocents: (amountInEur: number) => number = (amountInEur) =>  {
  return Math.round(amountInEur * 100);
}

export const validateVoucherBalanceIsSufficient: (
  rows: ServiceEventRow[],
  voucher: ValidateServiceEventVoucher,
  venueId: string,
) => GeneralValidationError[] = (rows, voucher, venueId) => {
  const amountsInEuro: number[] = rows.map((row: ServiceEventRow) => {
    const priceResult = computeServicePriceForRow(row, voucher, venueId);
    return priceResult === ServicePriceNotComputable ? 0 : priceResult.amount;
  });

  return convertToEurocents(voucher.balance) < convertToEurocents(sum(amountsInEuro)) ? [GeneralValidationError.InsufficientBalance] : [];
};

export const validateServiceEndDate: (
  values: ServiceEventFormValues,
  voucher: ValidateServiceEventVoucher,
) => GeneralValidationError[] = (values, voucher) => {
  const error = [GeneralValidationError.TargetPeriodNotCovered];
  const lastRow = last(values.serviceEventRows);
  const serviceEventEndDate = lastRow?.period.endDate;
  const expectedEndDate = calculateVoucherLastAllowedEndDate(values.selectedMonth, voucher);

  if (!lastRow) {
    return [];
  }

  if (!serviceEventEndDate) {
    return error;
  }

  if (serviceEventEndDate > expectedEndDate) {
    return error;
  }

  if (isSameDay(expectedEndDate, serviceEventEndDate)) {
    return [];
  }

  const lastProducedService = lastRow.producedService;
  if (getHousingServiceProducedServiceRule(voucher.serviceArea, lastProducedService).isTerminatingService) {
    return [];
  }

  return [GeneralValidationError.TargetPeriodNotCovered];
};

const validateGeneralErrors = (
  values: ServiceEventFormValues,
  voucher: ValidateServiceEventVoucher,
  venueId: VenueId,
) => {
  const generalErrors: GeneralValidationError[] = [
    ...validateVoucherBalanceIsSufficient(values.serviceEventRows, voucher, venueId),
    ...validateServiceEndDate(values, voucher),
  ];
  return generalErrors.length ? { general: generalErrors } : {};
};

export const validateServiceEvent: ServiceEventValidator = async (
  values: ServiceEventFormValues,
  voucher: ValidateServiceEventVoucher,
  venueId: VenueId,
  transactions?: Transaction[],
) => {
  return {
    ...validateSelectedMonth(values.selectedMonth, voucher, transactions),
    ...validateServiceEventRows(values.serviceEventRows, voucher),
    ...validateGeneralErrors(values, voucher, venueId),
  };
};

const isSameDay = (dateA: Date | undefined, dateB: Date | undefined) => {
  if (!dateA || !dateB) {
    return false;
  }

  return moment(dateA).isSame(moment(dateB), 'day');
};
