import { Typography } from 'app/shared';
import { Modal } from 'app/shared/modal-new/modal.container';
import { FunctionComponent } from 'react';
import styles from './service-event-submit-success-modal.module.scss';

type ServiceEventSubmitSuccessModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const ServiceEventSubmitSuccessModalComponent: FunctionComponent<ServiceEventSubmitSuccessModalProps> = ({
  isOpen,
  onDismiss,
}) => (
  <Modal
    isOpen={isOpen}
    cardClassNames={styles.modalCard}
    cardFooterProps={{ justifyContent: 'center' }}
    confirmOptions={[
      {
        label: (
          <Typography
            className={styles.button}
            id="SERVICE_EVENTS.POSTPAY.SUBMISSION.SUBMIT_MODAL_CLOSE"
            color="white"
          />
        ),
        onClick: onDismiss,
      },
    ]}
  >
    <Typography
      className={styles.modalBody}
      id="SERVICE_EVENTS.POSTPAY.SUBMISSION.SUBMIT_SUCCESS"
      type="subtitle"
      color="greyDark"
    />
  </Modal>
);
