// @flow

/*:: import type { FormState } from './should-block.util.types';*/

/**
 * @param formState {FormState}
 * @returns {boolean}
 *
 * compute if leave confirmation modal should appear before leaving the page
 */
export const shouldBlock = (formState /*: FormState*/ = {}) /*: boolean*/ => {
  const { dirty, dirtySinceLastSubmit, submitSucceeded, submitting } = formState;

  if (dirtySinceLastSubmit && !submitting) {
    return true;
  }

  if (dirty && !submitSucceeded && !submitting) {
    return true;
  }

  return false;
};
