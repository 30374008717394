import { useEffect, useState } from 'react';
import { FormData } from 'v2/types/postpay';
import { SubmittablePostpayServiceVoucher } from 'v2/service-voucher/postpay/utils';
import { useDispatch, useSelector } from 'react-redux';
import { V2RootState } from 'v2/v2.reducer';
import {
  actions as serviceEventActions,
  name as serviceEventSliceName,
  ServiceEventState,
  ServiceEventStatus,
} from 'v2/service-events/postpay/service-event-slice';
import { ServiceEventSubmitSuccessModalComponent } from './service-event-submit-success-modal.component';
import { ServiceEventFormComponent } from './service-event-form.component';

type ServiceEventSubmissionComponentProps = {
  voucher: SubmittablePostpayServiceVoucher;
};

export function ServiceEventSubmissionComponent({ voucher }: ServiceEventSubmissionComponentProps) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<FormData>();
  const [showServiceEventSubmittedModal, setShowServiceEventSubmittedModal] = useState(false);
  const serviceEventSubmissionState = useSelector<V2RootState, ServiceEventState>(
    state => state[serviceEventSliceName],
  );

  useEffect(() => {
    // reset state when voucher changes
    dispatch(serviceEventActions.newServiceEventInputStarted());
    setFormData(undefined);
  }, [dispatch, voucher.id]);

  useEffect(() => {
    if (serviceEventSubmissionState.status === ServiceEventStatus.Submitted) {
      setShowServiceEventSubmittedModal(true);
    }
  }, [dispatch, serviceEventSubmissionState.status]);

  return (
    <>
      <ServiceEventFormComponent
        voucher={voucher}
        data={formData}
        submissionState={serviceEventSubmissionState}
        onChange={setFormData}
        onSubmit={() =>
          dispatch(
            serviceEventActions.serviceEventSubmissionRequested({
              voucher,
              data: formData || {},
            }),
          )
        }
      />
      <ServiceEventSubmitSuccessModalComponent
        isOpen={showServiceEventSubmittedModal}
        onDismiss={() => {
          dispatch(serviceEventActions.newServiceEventInputStarted());
          setFormData(undefined);
          setShowServiceEventSubmittedModal(false);
        }}
      />
    </>
  );
}
