import { combineActions, handleActions } from 'redux-actions';
import { configuration } from 'configuration';
import { calculateNextSessionExpirationTime } from '../../core/token-refresh-timer/refresh-timer-calculator';

import * as types from './users.action-types';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const getBusinessId = () => Number(localStorage.getItem('currentBusiness'));

const getSelectedBusiness = businesses => {
  const selectedBusiness = getBusinessId();

  if (!selectedBusiness) return null;

  return businesses.find(business => business.id === getBusinessId());
};

const sortedBusinesses = businesses => {
  // wellbeing county accounts are ordered before old accounts
  const { replacedAccountIds } = configuration;
  const prefixedBusinesses = businesses.map(b => ({
    ...b,
    isReplacedByAnotherAccount: [b.id, b.parentId].some(id => replacedAccountIds.indexOf(id) >= 0),
  }));
  const sortedBusinesses = prefixedBusinesses
    .filter(bu => {
      const isParentAccount = !bu.parentId;
      // include subaccount when user does not have access to subaccount's parent account
      const isSubAccountWithoutParentAccount = !isParentAccount && !prefixedBusinesses.find(b => b.id === bu.parentId);
      return isParentAccount || isSubAccountWithoutParentAccount;
    })
    .sort((b1, b2) => {
      if (b1.isReplacedByAnotherAccount === b2.isReplacedByAnotherAccount) {
        return b1.name < b2.name ? -1 : 1;
      }
      return !b1.isReplacedByAnotherAccount ? -1 : 1;
    });
  return sortedBusinesses.flatMap(business => [
    business,
    ...prefixedBusinesses.filter(b => b.parentId === business.id),
  ]);
};

const defaultState = {
  entities: {},
};

export const usersReducer = handleActions(
  {
    [combineActions(types.LOGIN_SUCCESS, types.LOGIN_WITH_TOKEN_SUCCESS)]: (
      state,
      {
        payload: {
          data: { accessToken, expiresIn },
        },
      },
    ) => {
      return {
        accessToken,
        nextSessionExpirationTime: calculateNextSessionExpirationTime(expiresIn),
        entities: {},
      };
    },
    [types.REFRESH_TOKEN_SUCCESS]: (
      state,
      {
        payload: {
          data: { accessToken, expiresIn },
        },
      },
    ) => ({
      ...state,
      accessToken,
      nextSessionExpirationTime: calculateNextSessionExpirationTime(expiresIn),
    }),
    [types.REFRESH_TOKEN_FAILURE]: state => ({
      ...state,
      accessToken: undefined,
      nextSessionExpirationTime: undefined,
    }),
    [types.FETCH_CURRENT_USER_REQUEST]: (state, { meta: { isInitialFetchUserRequest } }) => ({
      ...state,
      isAuthenticating: isInitialFetchUserRequest,
    }),
    [types.FETCH_CURRENT_USER_SUCCESS]: (state, { payload: { data: current, meta } }) => {
      const businesses = sortedBusinesses(current.businesses);
      const business = getSelectedBusiness(businesses) || businesses[0];
      return {
        ...state,
        meta,
        current: {
          ...current,
          businesses,
          terms: current.terms || [],
        },
        business,
        isApproved: !current.terms || current.terms.every(term => !term.required || term.approved),
        isAuthenticated: true,
        isAuthenticating: false,
      };
    },
    [types.FETCH_CURRENT_USER_FAILURE]: state => ({
      ...state,
      isAuthenticated: false,
      isAuthenticating: false,
    }),
    [types.UPDATE_PROFILE_SUCCESS]: (state, { payload }) => ({
      ...state,
      current: {
        ...state.current,
        ...(payload ? payload.data : {}),
      },
    }),
    [types.CHANGE_BUSINESS]: (state, { payload: business }) => ({
      ...state,
      business,
    }),
    [types.FETCH_USERS_REQUEST]: (state, { meta: { customer, clearUsers } }) => ({
      ...state,
      entities: clearUsers
        ? {
            ...state.entities,
            [customer]: undefined,
          }
        : {
            ...state.entities,
          },
    }),
    [types.FETCH_USERS_SUCCESS]: (state, { meta: { customer }, payload: { data: list } }) => ({
      ...state,
      entities: {
        ...state.entities,
        [customer]: {
          list,
        },
      },
    }),
    [types.APPROVE_TERMS_SUCCESS]: (state, { meta: { key } }) => ({
      ...state,
      current: {
        ...state.current,
        terms: state.current.terms.map(term =>
          term.key === key
            ? {
                ...term,
                approved: true,
              }
            : term,
        ),
      },
      isApproved: state.current.terms.every(term => term.approved || term.key === key),
    }),
    [types.LOGIN_WITH_TOKEN_FAILURE]: (
      state,
      {
        payload: {
          response: { message },
        },
      },
    ) => ({
      ...state,
      loginWithoutPassword: { message },
    }),
  },
  defaultState,
);
