// @flow

import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { TitleComponent } from 'app/auth/title/title.component';
import { BackComponent } from 'app/auth/back/back.component';
import { ButtonComponent, ConnectedInputComponent, InfoComponent } from 'app/shared';

/*:: import type { Props, State } from './request-password.component.types';*/

export class RequestPasswordComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = ({ username } /*: { username: string }*/) =>
    this.props.onSubmit(username).then(() => this.setState({ submitted: true, username }));

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <section>
        <MediaQuery maxWidth={991}>
          <BackComponent to="/login">
            <MediaQuery maxWidth={767}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN_SHORT" />
            </MediaQuery>
            <MediaQuery minWidth={768}>
              <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
            </MediaQuery>
          </BackComponent>
        </MediaQuery>
        <TitleComponent withBackOption>
          <FormattedMessage id="AUTH.REQUEST_PASSWORD" />
        </TitleComponent>
        {!this.state.submitted ? (
          <form onSubmit={handleSubmit(this.onSubmit)} noValidate>
            <Field
              name="username"
              type="email"
              label="AUTH.EMAIL"
              placeholder="AUTH.EMAIL_PLACEHOLDER"
              component={ConnectedInputComponent}
            />
            <div className="d-flex justify-content-between align-items-center">
              <MediaQuery minWidth={992}>
                <BackComponent to="/login">
                  <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
                </BackComponent>
              </MediaQuery>
              <ButtonComponent loading={submitting}>
                {this.props.intl.formatMessage({ id: 'AUTH.REQUEST' })}
              </ButtonComponent>
            </div>
          </form>
        ) : (
          <div>
            <InfoComponent type="success">
              <FormattedMessage
                id="AUTH.SENT_REQUEST_PASSWORD"
                values={{ email: <strong>{this.state.username}</strong> }}
              />
            </InfoComponent>
            <MediaQuery minWidth={992}>
              <BackComponent to="/login">
                <FormattedMessage id="AUTH.BACK_TO_LOGIN" />
              </BackComponent>
            </MediaQuery>
          </div>
        )}
      </section>
    );
  }
}
