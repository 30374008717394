import { put, takeEvery } from 'redux-saga/effects';

import { fetchCouponType } from 'app/redux/types/types.actions';

import { EDIT_COUPON_TYPE_SUCCESS } from './types.action-types';

export function* onEditCouponType({ meta: { id } }) {
  yield put(fetchCouponType(id));
}

export function* typesSaga() {
  yield takeEvery(EDIT_COUPON_TYPE_SUCCESS, onEditCouponType);
}
