//@flow

import moment from 'moment/moment';

import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type { Config, Value } from './required-date-picker.types';*/

const START_DATE_IS_REQUIRED = 'VALIDATION.START_DATE_IS_REQUIRED';
const END_DATE_IS_REQUIRED = 'VALIDATION.END_DATE_IS_REQUIRED';
const REQUIRED = 'VALIDATION.REQUIRED';

export const requiredDateRange = (config /*: Config*/) => (value /*::?: Value*/) => {
  const { startDateKey, endDateKey, if: condition } = config;

  const runValidation = config.hasOwnProperty('if')
    ? condition && typeof condition === 'function' && condition()
    : true;

  if (runValidation) {
    if (value) {
      const startDate = value[startDateKey] ? moment(value[startDateKey], DATE_VALUE_FORMAT) : null;
      const endDate = value[endDateKey] ? moment(value[endDateKey], DATE_VALUE_FORMAT) : null;

      if (!startDate) {
        return START_DATE_IS_REQUIRED;
      }

      if (!endDate) {
        return END_DATE_IS_REQUIRED;
      }
    } else {
      return REQUIRED;
    }
  }

  return undefined;
};
