import { compose } from 'redux';

import { withAuthorization } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';
import { injectIntl } from 'react-intl';

import { CouponsShareComponent } from './coupons-share.component';

export const CouponsShareContainer = compose(
  withAuthorization(['serviceCoupons', 'read']),
  withCoreContext,
  injectIntl,
)(CouponsShareComponent);
