import {
  actions,
  VoucherServiceEventsRefreshRequestedPayload,
  VoucherServiceEventsRequestCompletion,
  VoucherServiceEventsRequestedPayload,
} from './service-voucher-service-events-slice';
import {
  actions as serviceEventsActions,
  ServiceEventStatus,
  ServiceEventSubmissionRequestCompletion,
} from 'v2/service-events/postpay/service-event-slice';

import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { ServiceEvent } from 'v2/types/postpay';
import { GetVoucherServiceEventsResponse, PostpayApiClient } from 'v2/api/postpay-api-client';

export function* serviceVoucherServiceEventsSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function voucherServiceEventsFound(voucherId: string, serviceEvents: ServiceEvent[]) {
    const result: VoucherServiceEventsRequestCompletion = {
      voucherId: voucherId,
      serviceEvents,
    };
    return actions.voucherServiceEventsRequestCompleted(result);
  }

  function* fetchAndUpdateVoucherServiceEvents(voucherId: string) {
    const response: GetVoucherServiceEventsResponse = yield call(apiClient.getVoucherServiceEvents, voucherId);
    switch (response.status) {
      case StatusCodes.OK:
        yield put(voucherServiceEventsFound(voucherId, response.data));
        break;
      default:
        break;
    }
  }

  function* voucherServiceEventsRequested(action: PayloadAction<VoucherServiceEventsRequestedPayload>) {
    yield fetchAndUpdateVoucherServiceEvents(action.payload.voucherId);
  }

  function* voucherServiceEventsRefreshRequested(action: PayloadAction<VoucherServiceEventsRefreshRequestedPayload>) {
    yield fetchAndUpdateVoucherServiceEvents(action.payload.voucherId);
  }

  function* serviceEventSubmissionCompleted(action: PayloadAction<ServiceEventSubmissionRequestCompletion>) {
    const { payload } = action;
    if (payload.status === ServiceEventStatus.Submitted) {
      yield put(actions.voucherServiceEventsRefreshRequested({ voucherId: payload.voucherId }));
    }
  }

  yield takeEvery(actions.voucherServiceEventsRequested.match, voucherServiceEventsRequested);
  yield takeEvery(actions.voucherServiceEventsRefreshRequested.match, voucherServiceEventsRefreshRequested);
  yield takeEvery(serviceEventsActions.serviceEventSubmissionCompleted.match, serviceEventSubmissionCompleted);
}
