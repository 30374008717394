// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { TableComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-check-balance-transactions-table.component.types';*/

export class CouponsCheckBalanceTransactionsTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <div className="table-responsive">
        <TableComponent className="table-striped">
          <thead>
            <tr>
              <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CHARGED" tagName="th" />
              <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.TYPE" tagName="th" />
              <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.AMOUNT" tagName="th" />
            </tr>
          </thead>
          <tbody>
            {this.props.list.map((transaction, index) => (
              <tr key={index}>
                <td>{dateFormatter(transaction.date)}</td>
                <td>
                  <FormattedMessage id={`TRANSACTION_TYPE.${transaction.type}`} />
                </td>
                <td>{currencyFormatter.format(transaction.amount)}</td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
    );
  }
}
