//@flow

import React, { useRef } from 'react';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';
import { CellMeasurerCache } from 'react-virtualized';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

import { getMeasure } from 'app/shared/virtualized/utils/get-measurements.helper';
import { NoResultsContainer } from 'app/shared';
import { VirtualizedListRowComponent } from '../row/virtualized-list-row.component';

/*:: import type { List as ListProps } from 'react-virtualized';*/
/*:: import type { Props } from './virtualized-list-component.types';*/

const render =
  ({ measure, rowRenderer, ...otherProps }) =>
  (params /*: ListProps*/) => {
    const { index, key, style, parent } = params;

    return (
      <VirtualizedListRowComponent
        measure={measure}
        key={key}
        index={index}
        parent={parent}
        style={style}
        {...otherProps}
      >
        {rowRenderer(params)}
      </VirtualizedListRowComponent>
    );
  };

export const VirtualizedListComponent = (props /*: Props*/) => {
  const {
    defaultListHeight = 1000,
    overscanRowCount = 0,
    rowCount,
    defaultListWidth = 500,
    children,
    rowRenderer,
    defaultRowHeight,
    form,
    noRowsRenderer = NoResultsContainer,
    ...otherProps
  } = props;

  const cache = useRef/*:: <Cache> */(new CellMeasurerCache({ fixedWidth: true, defaultHeight: defaultRowHeight }));
  const measure = getMeasure(cache.current);
  const timeout = useRef();

  const { pauseValidation = noop, resumeValidation = noop } = props.form || {};

  //disable form validation on scroll and enable it when scroll is not moving
  const handleScroll = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    pauseValidation();

    timeout.current = setTimeout(debounce(resumeValidation), 300);
  };

  return (
    <WindowScroller>
      {({ height, onChildScroll, isScrolling, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              <List
                autoHeight
                height={height || defaultListHeight}
                isScrolling={isScrolling}
                onScroll={(...args) => {
                  onChildScroll(...args);
                  handleScroll();
                }}
                overscanRowCount={overscanRowCount}
                rowCount={rowCount}
                //$FlowFixMe
                rowHeight={cache.current.rowHeight}
                rowRenderer={render({ measure, rowRenderer, ...otherProps })}
                scrollTop={scrollTop}
                width={width || defaultListWidth}
                deferredMeasurementCache={cache.current}
                scrollToAlignment="start"
                noRowsRenderer={noRowsRenderer}
                {...otherProps}
              />
              {children}
            </>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};
