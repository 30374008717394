// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { VenueNavigationButtonsComponent } from '../shared/venue-navigation-buttons.component';

/*:: import type { Props } from './venues-list-table.component.types';*/
import styles from './venues-list-table.module.scss';
import { StatusComponent } from 'app/shared/status/status.component';

export const headers = [
  { content: 'VENUES.STATUS', colSpan: 2 },
  { content: 'VENUES.NAME' },
  { content: 'VENUES.CITY' },
  {},
];

export class VenuesListTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(venue => (
            <TableRowComponent key={venue.id} className={classNames({ [styles.pending]: venue.pending })}>
              <TableCellComponent className="mr-0 pr-0">
                {venue.venueContractStatus && (
                  <StatusComponent status={venue.venueContractStatus} size="medium" iconSet={'provider'} />
                )}
              </TableCellComponent>
              <TableCellComponent className="ml-0 pl-0">
                {venue.venueContractStatus && (
                  <FormattedMessage id={`PRICELIST.STATUS_VALUE.${venue.venueContractStatus}`} />
                )}
              </TableCellComponent>

              <TableCellComponent>{venue.name}</TableCellComponent>
              <TableCellComponent>{venue.city}</TableCellComponent>

              {venue.pending && <TableCellComponent align="right" content="VENUES.PENDING" />}

              {venue.pending || (
                <TableCellComponent className={styles.icons}>
                  <VenueNavigationButtonsComponent venueId={venue.id} permissions={this.props.permissions} />
                </TableCellComponent>
              )}
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
