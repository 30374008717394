// date formats
export const DATE_VALUE_FORMAT /*: string*/ = 'YYYY-MM-DD';

// app redirects
export const REDIRECTS = {
  ROLE_MUNICIPAL_SERVICE_PROVIDER_MANAGER: '/venues',
  ROLE_MUNICIPAL_VENUE_MANAGER: '/coupons/charge',
  ROLE_MUNICIPAL_CONTRACT_MANAGER: '/pricelists',
  ROLE_MUNICIPAL_FINANCIAL_MANAGER: '/vat-archive',
  ROLE_MUNICIPAL_BUYER: '/coupons',
  ROLE_MUNICIPAL_DISTRIBUTOR: '/coupons',
};

export enum ROUTES {
  SERVICE_VOUCHER_TYPES_DEFAULT = '/coupons/types?active=1',
}

// input props omit filter
export const INPUT_PROPS_OMIT_KEYS = ['controlMeta', 'errorRef', 'labelRef', 'wrapperRef', 'append']; // todo: remove append

// small size breakpoint
export const SM_BREAKPOINT = 576;
export const XL_BREAKPOINT = 1200;

//priceLists
export const defaultColumns = [
  { title: 'REGISTER.CONTRACT.WEEKDAY_DAYTIME', subtitle: '' },
  { title: 'REGISTER.CONTRACT.WEEKDAY_EVENINGS', subtitle: '' },
  { title: 'REGISTER.CONTRACT.WEEKDAY_NIGHTTIME', subtitle: '' },
  { title: 'REGISTER.CONTRACT.SATURDAY_DAYTIME', subtitle: '' },
  { title: 'REGISTER.CONTRACT.SATURDAY_EVENINGS', subtitle: '' },
  { title: 'REGISTER.CONTRACT.SUNDAY_DAYTIME', subtitle: '' },
  { title: 'REGISTER.CONTRACT.SUNDAY_EVENINGS', subtitle: '' },
];

// Generic status strings returned by the backend, used for different entities
export enum StatusEnum {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  New = 'NEW',
}
export enum ContractStatus {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  New = 'NEW',
  ChangesRequested = 'CHANGES_REQUESTED',
  Locked = 'LOCKED',
}

export enum ContractSubStatus {
  ChangesNotMadeInTime = 'CHANGES_NOT_MADE_IN_TIME',
  PendingAfterChangesRequested = 'PENDING_AFTER_CHANGES_REQUESTED',
}

export enum VenueStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  ChangesRequested = 'CHANGES_REQUESTED',
  ChangesNotMadeInTime = 'CHANGES_NOT_MADE_IN_TIME',
  PendingAfterChangesRequested = 'PENDING_AFTER_CHANGES_REQUESTED',
}

export const SUPPORTED_LOCALES = ['fi', 'sv', 'en'];

export enum PricelistTypeEnum {
  Text = 'TEXT',
  Link = 'LINK',
  Table = 'TABLE',
}
