// @flow

import React from 'react';

import { ConnectControl } from 'app/shared';

import { CheckboxesComponent } from 'app/shared/form/controls';

/*:: import type { Option, Props } from './connected-checkboxes.component.types';*/

export const ConnectedCheckboxesComponent = (props /*: Props*/) => {
  const isChecked = (option /*: Option*/) => props.input.value.indexOf(option.id) > -1;
  const isDisabled = (option /*: Option*/) => option.alwaysEnabled || (props.disableEdit && !option.editable);

  const onChange = (option /*: Option*/) => {
    if (!option.alwaysEnabled) {
      if (isChecked(option)) {
        props.input.onChange(props.input.value.filter(x => x !== option.id));
      } else {
        props.input.onChange([...props.input.value, option.id]);
      }
    }

    if (!!props.afterChange && typeof props.afterChange === 'function') {
      props.afterChange(isChecked(option), option);
    }
  };

  return (
    <ConnectControl
      {...props}
      component={CheckboxesComponent}
      itemProps={{ ...props.itemProps, isChecked, onChange, isDisabled }}
      labelKey={props.labelKey}
    />
  );
};

ConnectedCheckboxesComponent.defaultProps = {
  labelKey: 'text',
};
