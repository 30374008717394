// @flow

import React, { PureComponent } from 'react';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { currencyFormatter, getDatesString } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-details-transactions-table.component.types';*/
import styles from './coupons-details-transactions-table.module.scss';

export const headers = [
  { content: 'COUPONS.TRANSACTIONS.HEADERS.CHARGED' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.TYPE' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.CREDITOR' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.PRODUCED' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.PRICE_UNITS' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.DESCRIPTIVE_SERVICE' },
  { content: 'COUPONS.TRANSACTIONS.HEADERS.AMOUNT', numerical: true },
];

export class CouponsDetailsTransactionsTableComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <div className="table-responsive">
        <TableComponent className="table-striped">
          <TableHeadComponent headers={headers} />
          <TableBodyComponent>
            {this.props.list.map(transaction => (
              <TableRowComponent key={transaction.id}>
                <TableCellComponent>{dateFormatter(transaction.date)}</TableCellComponent>
                <TableCellComponent content={`TRANSACTION_TYPE.${transaction.type}`} />
                <TableCellComponent className={styles.creditor}>{transaction.creditor}</TableCellComponent>
                <TableCellComponent>
                  {getDatesString(transaction.serviceProducedStart, transaction.serviceProducedEnd)}
                </TableCellComponent>
                <TableCellComponent>{transaction.numberOfPriceUnits}</TableCellComponent>
                <TableCellComponent>{transaction.descriptiveService || '-'}</TableCellComponent>
                <TableCellComponent align="right">
                  {currencyFormatter.formatWithSign(transaction.amount)}
                </TableCellComponent>
              </TableRowComponent>
            ))}
          </TableBodyComponent>
        </TableComponent>
      </div>
    );
  }
}
