// @flow

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import max from 'lodash/max';
import debounce from 'lodash/debounce';

import { PageHeaderComponent } from 'app/core';
import {
  InputComponent,
  ListComponent,
  SelectComponent,
  SeparatorComponent,
  ToggleComponent,
  Typography,
} from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { currencyFormatter, isBusinessDifferent } from 'app/utils';
import { VatArchiveListTable } from './table/vat-archive-list-table.component';
import { VatArchiveListPlaceholder } from './placeholder/vat-archive-list.placeholder';
import { VatArchiveTileComponent } from './tile/vat-archive-tile.component';
import { SummaryBox } from 'app/shared/summary-box/summary-box.component';
import { SummaryBoxPlaceholder } from 'app/shared/summary-box/placeholder/summary-box.placeholder';
import { VatArchiveModalComponent } from './modal/vat-archive-modal.component';

/*:: import type { archiveRow, Props, State } from './vat-archive-list.component.types';*/
import styles from 'app/vat-archive/vat-archive.module.scss';

export class VatArchiveListComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      year: '',
      month: '',
      filters: {
        name: '',
      },
      selectedItems: [],
      showModal: false,
      showOnlySelected: false,
    };
  }

  componentDidMount() {
    this.props.fetchVatArchivesMonths();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchVatArchivesMonths();
    }

    if (!!this.props.years && !this.state.year) {
      this.setState(
        {
          year: this.getLatestYear(this.props.years),
          month: this.getLatestMonth(this.getLatestYear(this.props.years), this.props.years),
        },
        () => {
          this.props.fetchVatArchives(this.state.year, this.state.month);
        },
      );
    }
  }

  getLatestYear(data /*: { [string]: number[] }*/) /*: string*/ {
    return max(Object.keys(data)).toString();
  }

  getLatestMonth(year /*: string*/, months /*: { [string]: number[] }*/) /*: string*/ {
    return max(months[year]).toString();
  }

  getYears() /*: string[]*/ {
    if (!this.props.years) {
      return [];
    }

    return Object.keys(this.props.years);
  }

  getMonths(year /*: string*/) /*: any[]*/ {
    if (!this.props.years || !year) {
      return [];
    }

    return this.props.years[year].map(month => ({
      id: month,
      name: <FormattedDate value={new Date(Number(year), Number(month) - 1)} month="long" />,
    }));
  }

  getSummary() {
    if (!this.props.list) {
      return [];
    }

    const list = this.getList();

    return [
      {
        title: this.props.intl.formatMessage({ id: 'VAT_ARCHIVE.LIST.ALL_ACCOUNTS_TOTAL' }),
        data: [
          {
            values: [
              currencyFormatter.format(list.reduce((sum, curr) => sum + Number(curr.sum), 0)),
              this.props.intl.formatMessage(
                {
                  id: 'VAT_ARCHIVE.DETAILS.SUMMARY.PAYMENTS',
                },
                {
                  number: list.reduce((sum, curr) => sum + Number(curr.totalNumber), 0),
                },
              ),
            ],
            classes: 'font-weight-bold',
          },
        ],
      },
    ];
  }

  getList() /*: archiveRow[]*/ {
    if (!this.props.subBusinesses || !this.props.list) {
      return [];
    }

    return this.props.list.filter(({ name, id }) => {
      if (this.state.filters.name) {
        return name.toLowerCase().includes(this.state.filters.name.toLowerCase());
      }

      if (this.state.showOnlySelected) {
        return this.state.selectedItems.includes(id);
      }

      return true;
    });
  }

  onYearChange = ({ value: year } /*: { value: string }*/) => {
    this.setState({
      year,
      month: '',
    });
  };

  onMonthChange = ({ value: month } /*: { value: string }*/) => {
    this.setState(
      {
        month,
      },
      () => {
        this.props.fetchVatArchives(this.state.year, this.state.month);
      },
    );
  };

  onFilterChange = debounce((value /*: string*/) => {
    this.setState({
      filters: {
        name: value,
      },
    });
  }, 500);

  onSelect = (id /*: number*/, isSelected /*: boolean*/) => {
    this.setState(prev => ({
      selectedItems: isSelected ? [id, ...prev.selectedItems] : prev.selectedItems.filter(_id => _id !== id),
    }));
  };

  onSelectAll = ({ target: { checked } } /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    this.setState({
      selectedItems: checked ? this.props.list.filter(item => item.active).map(item => item.id) : [],
    });
  };

  toggleModal = () => {
    this.setState(prev => ({
      showModal: !prev.showModal,
    }));
  };

  toggleShowOnlySelected = () => {
    this.setState(prevState => ({
      showOnlySelected: !prevState.showOnlySelected,
    }));
  };

  shouldRedirect = () => {
    const { business, list, years } = this.props;
    const shouldRedirect = business.parentId || (list.length === 1 && list[0].id === business.id);
    if (!shouldRedirect) return null;

    const year = this.getLatestYear(years);
    const month = this.getLatestMonth(this.getLatestYear(years), years);

    return (
      <Redirect
        to={{ pathname: `${this.props.match.url}/${business.id}/${year}/${month}`, state: { autoRedirected: true } }}
      />
    );
  };

  render() {
    const list = this.getList();

    return (
      <>
        <PageHeaderComponent title="NAV.ADDITIONAL.VAT_ARCHIVE" />
        <CardComponent>
          <CardBodyComponent>
            <div className="row">
              <div className="col-12 col-lg">
                <div className={styles.selectWrapper}>
                  <div className={styles.selectContainer}>
                    <SelectComponent
                      disabled={!this.state.year}
                      options={this.getMonths(this.state.year)}
                      value={this.state.month}
                      onChange={this.onMonthChange}
                      theme="primary"
                    />
                  </div>
                  <div className={styles.selectContainer}>
                    <SelectComponent
                      disabled={!this.state.year}
                      options={this.getYears()}
                      value={this.state.year}
                      onChange={this.onYearChange}
                      theme="primary"
                    />
                  </div>
                </div>
                <SeparatorComponent />
                <div className={classNames(styles.inputContainer, 'd-flex flex-column')}>
                  <Typography tag="h4" color="secondary" type="largeText">
                    <FormattedMessage id="VAT_ARCHIVE.LIST.FIND_ACCOUNT" />
                  </Typography>
                  <SeparatorComponent />
                  <InputComponent onChange={e => this.onFilterChange(e.target.value)} icon="search" />
                </div>
              </div>
              <div className="col-12 col-lg-auto">
                {this.props.loading && <SummaryBoxPlaceholder />}
                {this.props.loading || <SummaryBox sections={this.getSummary()} />}
              </div>
            </div>
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />

        {this.props.loading && <VatArchiveListPlaceholder />}

        {!this.props.loading && this.shouldRedirect()}

        {this.props.loading || (
          <CardComponent>
            <CardHeaderComponent direction="column" alignItems="normal">
              <Typography color="secondary" tag="h4" type="largeText">
                <FormattedMessage id="VAT_ARCHIVE.LIST.COUNTER" values={{ total: list.length }} />
                {!!list.length && !!this.state.selectedItems.length && (
                  <>
                    {' - '}
                    <FormattedMessage
                      id="VAT_ARCHIVE.LIST.SELECTED_COUNTER"
                      values={{ total: this.state.selectedItems.length }}
                    />
                  </>
                )}
              </Typography>
              <ToggleComponent
                checked={this.state.showOnlySelected}
                label={this.props.intl.formatMessage({ id: 'COUPONS.RENEW.LABEL.SHOW_ONLY_SELECTED' })}
                onChange={this.toggleShowOnlySelected}
              />
            </CardHeaderComponent>
            <CardBodyComponent>
              <ListComponent
                list={list}
                table={VatArchiveListTable}
                tile={VatArchiveTileComponent}
                noResultsProps={{
                  desc: 'VAT_ARCHIVE.NO_RESULTS_DESC',
                }}
                props={{
                  selectable: true,
                  business: this.props.business,
                  year: this.state.year,
                  month: this.state.month,
                  onSelect: this.onSelect,
                  onSelectAll: this.onSelectAll,
                  disablePrint: !list.length || !this.state.selectedItems.length,
                  selectedItems: this.state.selectedItems,
                  showModal: this.toggleModal,
                }}
              />
            </CardBodyComponent>
          </CardComponent>
        )}

        <VatArchiveModalComponent
          isOpen={this.state.showModal}
          onDownload={this.props.onDownload(this.state.year, this.state.month, this.state.selectedItems)}
          title="VAT_ARCHIVE.LIST.PRINT_SELECTED"
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}
