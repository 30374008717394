//@flow

import React from 'react';
import { injectIntl } from 'react-intl';

import { PlaceholderComponent, Typography } from 'app/shared';

export const TypographyConditional = injectIntl(
  ({ content, typographyProps, placeholderProps = { width: 320 }, intl }) => {
    if (typeof content === 'string') {
      if (/^[A-Z._]+$/.test(content)) {
        return <Typography {...typographyProps}>{intl.formatMessage({ id: content })}</Typography>;
      } else {
        return <Typography {...typographyProps}>{content}</Typography>;
      }
    }

    const { id, values } = content;

    if (Object.values(values).some(item => item === undefined || null)) {
      return <PlaceholderComponent {...placeholderProps} />;
    }

    return <Typography {...typographyProps}>{intl.formatMessage({ id }, values)}</Typography>;
  },
);
