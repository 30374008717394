// @flow
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { fetchTerms } from 'app/redux/terms/terms.actions';
import { TermsComponent } from './terms.component';

const TERMS_FILENAME = 'check_balance';

const mapStateToProps = ({ agreements: { terms } }, { intl: { locale } }) => ({
  content: get(terms, [TERMS_FILENAME, locale, 'content'], null),
});

const mapDispatchToProps = dispatch => ({
  fetchTerms: () => dispatch(fetchTerms(TERMS_FILENAME)),
});

export const TermsContainer = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(TermsComponent);
