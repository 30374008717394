// @flow

import React, { Component } from 'react';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { useSelector } from 'app/hooks/redux';

import { ConnectedInputComponent, FieldArrayRenderer, FieldValueRenderer } from 'app/shared/form';
import { TableCellComponent, TableRowComponent } from 'app/shared/table-new';
import { PriceListTableCellComponent } from '../price-list-table-cell.component';

/*:: import type { Props } from './price-list-table-row.component.types';*/

const RenderPrice = props => {
  const tableCellField = formValueSelector(props.form)(
    useSelector(state => state),
    props.name,
  );

  return (
    <TableCellComponent className="text-center" key={props.name}>
      <Field
        //$FlowFixMe
        component={props.editable ? ConnectedInputComponent : FieldValueRenderer}
        disabled={!props.editMode}
        name={`${props.name}.value`}
        {...(!props.editable
          ? {
              wrapper: () => <PriceListTableCellComponent tableCellField={tableCellField} showDiff={props.showDiff} />,
            }
          : {})}
      />
    </TableCellComponent>
  );
};

export class PriceListTableRowComponent extends Component /*:: <Props>*/ {
  render() {
    const { editMode, editable, showDiff, meta } = this.props;

    return (
      <TableRowComponent>
        <TableCellComponent>
          <Field name={`${this.props.name}.name`} component={FieldValueRenderer} />
        </TableCellComponent>
        <FieldArray
          name={`${this.props.name}.prices`}
          component={FieldArrayRenderer}
          fieldComponent={RenderPrice}
          props={{ showDiff, form: meta.form }}
          editMode={editMode}
          editable={editable}
        />
      </TableRowComponent>
    );
  }
}
