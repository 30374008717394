import { OrganizationalUnitUseCases, PostpayServiceVoucher, ServiceEvent } from 'v2/types/postpay';
import { isNotNil } from './is-nil';

function createKeyValidator<TType>(key: keyof TType) {
  return key;
}

// enforces typing
function createLinkValidator<TType extends { _links: Record<string, unknown> }>() {
  return function <TLink extends keyof TType['_links']>(link: TLink): TLink {
    return link;
  };
}

// enforces typing
function createEmbeddedValidator<TType extends { _embedded: Record<string, unknown> }>() {
  return function <TLink extends keyof TType['_embedded']>(link: TLink): TLink {
    return link;
  };
}

export const Links = {
  OrganizationalUnit: {
    IssueVoucherFromAvailableVoucherTypes: createKeyValidator<OrganizationalUnitUseCases>(
      'usecase:issue-voucher-from-available-voucher-types',
    ),
    ViewIssuedVouchers: createKeyValidator<OrganizationalUnitUseCases>('usecase:view-issued-vouchers'),
    SearchVoucher: createKeyValidator<OrganizationalUnitUseCases>('usecase:search-voucher'),
  },
  PostpayServiceVoucher: {
    InitializeServiceEvent: createLinkValidator<PostpayServiceVoucher>()(
      'usecase:submit-service-event:action:initialize-event',
    ),
    SubmitVoucher: createLinkValidator<PostpayServiceVoucher>()('usecase:issue-voucher:action:submit-voucher'),
    GetVoucherPdf: createLinkValidator<PostpayServiceVoucher>()('usecase:print-documents:action:get-voucher-document'),
    GetVoucherApprovedServiceEventsPdf: createLinkValidator<PostpayServiceVoucher>()(
      'usecase:print-documents:action:get-approved-service-providers-document',
    ),
  },
  ServiceEvent: {
    SubmitServiceEvent: createLinkValidator<ServiceEvent>()('usecase:submit-service-event:action:submit-event'),
  },
} as const;

export const Embedded = {
  PostpayServiceVoucher: {
    IssueVoucherTemplate: createEmbeddedValidator<PostpayServiceVoucher>()('usecase:issue-voucher:voucher-template'),
    ServiceEventTemplate: createEmbeddedValidator<PostpayServiceVoucher>()('usecase:submit-service-event:template'),
  },
} as const;

export function getLink<
  TType extends { _links: Record<string | number | symbol, unknown> },
  TLink extends keyof TType['_links'],
>(object: TType, link: TLink): TType['_links'][TLink] {
  return object._links[link];
}

export function hasLink<
  TType extends { _links: Record<string | number | symbol, unknown> },
  TLink extends keyof TType['_links'],
>(object: TType, link: TLink): object is TType & { _links: { [K in TLink]: Required<TType['_links'][TLink]> } } {
  return isNotNil(getLink(object, link));
}

export function getEmbedded<
  TType extends { _embedded: Record<string | number | symbol, unknown> },
  TLink extends keyof TType['_embedded'] & string,
>(object: TType, link: TLink): TType['_embedded'][TLink] {
  return object._embedded[link];
}

export function hasEmbedded<
  TType extends { _embedded: Record<string | number | symbol, unknown> },
  TLink extends keyof TType['_embedded'] & string,
>(object: TType, link: TLink): object is TType & { _links: { [K in TLink]: Required<TType['_embedded'][TLink]> } } {
  return isNotNil(getEmbedded(object, link));
}
