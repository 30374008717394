// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { ButtonComponent } from 'app/shared';

import { SortableTableHeader } from 'app/shared/list/sortable-header/sortable-table-header.component';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './vat-archive-details-table.component.types';*/
import styles from './vat-archive-details-table.module.scss';

export class VatArchiveDetailsTableComponent extends Component /*:: <Props>*/ {
  render() {
    const { orderBy, currentOrder } = this.props;

    return (
      <TableComponent className="table-striped">
        <TableHeadComponent className={styles.thead}>
          <TableRowComponent>
            <SortableTableHeader
              column="id"
              orderBy={orderBy}
              translationId="VAT_ARCHIVE.DETAILS.HEADERS.BILL_ID"
              currentOrder={currentOrder}
            />

            <SortableTableHeader
              column="date"
              orderBy={orderBy}
              translationId="VAT_ARCHIVE.DETAILS.HEADERS.DATE"
              currentOrder={currentOrder}
            />
            {this.props.type === 'TYPE_MUNICIPAL' && (
              <SortableTableHeader
                column="sellerName"
                orderBy={orderBy}
                translationId="VAT_ARCHIVE.DETAILS.HEADERS.SELLER_NAME"
                currentOrder={currentOrder}
              />
            )}
            {this.props.type === 'TYPE_VENUE' && (
              <SortableTableHeader
                column="buyerName"
                orderBy={orderBy}
                translationId="VAT_ARCHIVE.DETAILS.HEADERS.BUYER_NAME"
                currentOrder={currentOrder}
              />
            )}
            <SortableTableHeader
              align="right"
              column="amount"
              orderBy={orderBy}
              translationId="VAT_ARCHIVE.DETAILS.HEADERS.AMOUNT"
              currentOrder={currentOrder}
            />

            <TableCellComponent variant="header">
              <ButtonComponent
                size="small"
                className={styles.button}
                onClick={this.props.onModalToggle}
                disabled={!this.props.list.length}
              >
                <i className="fi fi-printer pl-1" />
                <FormattedMessage id="VAT_ARCHIVE.DETAILS.PRINT" />
              </ButtonComponent>
            </TableCellComponent>
          </TableRowComponent>
        </TableHeadComponent>
        <TableBodyComponent>
          {this.props.list.map(invoice => (
            <TableRowComponent key={invoice.id}>
              <TableCellComponent>{invoice.id}</TableCellComponent>
              <TableCellComponent>{dateFormatter(invoice.date)}</TableCellComponent>
              <TableCellComponent>
                {this.props.type === 'TYPE_MUNICIPAL' && invoice.sellerName}
                {this.props.type === 'TYPE_VENUE' && invoice.buyerName}
              </TableCellComponent>
              <TableCellComponent align="right">{currencyFormatter.format(invoice.amount)}</TableCellComponent>
              <TableCellComponent align="right">
                <ButtonComponent size="small" onClick={() => this.props.onDownload(invoice.id)}>
                  <i className="fi fi-download-list" />
                  <FormattedMessage id="VAT_ARCHIVE.DETAILS.DOWNLOAD" />
                </ButtonComponent>
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
