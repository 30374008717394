//@flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { calculateDateDiff } from 'app/utils';

/*:: import type { Props } from './date-range-days-counter.component.types';*/
import styles from './date-range-days-counter.module.scss';

export const DateRangeDaysCounter = (props /*: Props*/) => {
  if (!props.daysCounter) {
    return null;
  }

  const startDate = props.startDate || (props.startDateProps || {}).selectedDate;
  const endDate = props.endDate || (props.endDateProps || {}).selectedDate;

  if (!startDate || !endDate) {
    return null;
  }

  const days = calculateDateDiff(startDate, endDate) + 1;

  return (
    <strong className={styles.daysCounter}>
      <FormattedMessage id="CORE.DAYS_SELECTED" values={{ days }} />
    </strong>
  );
};
