import { connect } from 'react-redux';
import { UserInactivityTimerComponent } from './user-inactivity-timer.component';
import { calculateInactivityTimeout } from './inactivity-timer-calculator';
import { logoutUser } from 'app/redux/users/users.actions';

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const mapStateToProps = ({ users }) => ({
  calculateTimeout: calculateInactivityTimeout,
  isUserAuthenticated: users.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser(true, 'INACTIVITY')),
});

export const UserInactivityTimerContainer = connect(mapStateToProps, mapDispatchToProps)(UserInactivityTimerComponent);
