// @flow

import React, { Component } from 'react';
import styles from './user-guide.module.scss';
import { ExternalLink } from '../../shared';
export class UserGuideComponent extends Component /*:: <any>*/ {
  render() {
    return (
      <div className={styles.container}>
        <span className={styles.label}>
          <ExternalLink
            className={styles.label}
            href={
              `https://guide.oma.vaana.fi/` + this.props.intl.formatMessage({ id: 'NAV.SETTINGS.USER_GUIDE_URI' }) + `/`
            }
          >
            <i className={`fi fi-info-sign`} />
            {this.props.intl.formatMessage({ id: 'NAV.SETTINGS.USER_GUIDE' })}
          </ExternalLink>
        </span>
      </div>
    );
  }
}
