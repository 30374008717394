// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { TileComponent } from 'app/shared';
import { currencyFormatter, getDatesString } from 'app/utils';

/*:: import type { Props } from './coupons-charge-modal-tile.types';*/

export class CouponsChargeModalTile extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.DETAILS.CHARGE.SERVICE_PRODUCED_DATE" tagName="small" />
            {getDatesString(this.props.tile.serviceProduced.startDate, this.props.tile.serviceProduced.endDate)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.DETAILS.CHARGE.PRICE_UNITS" tagName="small" />
            {this.props.tile.numberOfPriceUnits}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.DETAILS.CHARGE.AMOUNT" tagName="small" />
            {currencyFormatter.format(this.props.tile.amount)}
          </div>
          <div className="col">
            <FormattedMessage id="COUPONS.DETAILS.CHARGE.REFERENCE" tagName="small" />
            {this.props.tile.reference}
          </div>
        </div>

        {this.props.extended && (
          <div className="row">
            <div className="col">
              <FormattedMessage id="COUPONS.DETAILS.CHARGE.DESCRIPTIVE_SERVICE" tagName="small" />
              {this.props.tile.descriptiveService.name}
            </div>
            <div className="col">
              <FormattedMessage id="COUPONS.DETAILS.CHARGE.VAT_RATES" tagName="small" />
              {this.props.tile.vatRate}
            </div>
          </div>
        )}
      </TileComponent>
    );
  }
}
