// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { checkPermissions } from 'app/utils';
import { ButtonComponent } from 'app/shared';

/*:: import type { Props } from './venue-navigation-buttons.component.types';*/

export const VenueNavigationButtonsComponent = (props /*: Props*/) => (
  <>
    {(checkPermissions(props.permissions, ['venues', 'read']) ||
      checkPermissions(props.permissions, ['venue', 'read'])) && (
      <ButtonComponent theme="primary-outline" to={`/venues/${props.venueId}/municipalities`} size="small">
        <FormattedMessage id="VENUES.PRICES" /> <i className="fi fi-euro-symbol" />
      </ButtonComponent>
    )}

    {checkPermissions(props.permissions, ['venueUsers', 'read']) && (
      <ButtonComponent theme="primary-outline" to={`/venues/${props.venueId}/users`} size="small">
        <FormattedMessage id="VENUES.USERS" /> <i className="fi fi-man-user" />
      </ButtonComponent>
    )}

    {checkPermissions(props.permissions, ['venues', 'update']) && (
      <ButtonComponent theme="primary-outline" to={`/venues/${props.venueId}/edit`} size="small">
        <i className="fi fi-pencil-edit-button" />
      </ButtonComponent>
    )}
  </>
);
