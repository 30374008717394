import { ComponentProps } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import { TranslationKey } from 'v2/types/translations';

type FormattedMessageProps = ComponentProps<typeof ReactIntlFormattedMessage> & {
  id: TranslationKey;
};

export function FormattedMessage(props: FormattedMessageProps) {
  return <ReactIntlFormattedMessage {...props} />;
}
