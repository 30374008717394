import { fork } from 'redux-saga/effects';

import { agreementsSaga } from 'app/redux/agreements/agreements.saga';
// import { archiveSaga } from './redux/archive/archive.saga';  FIXME: this is temporarily disabled due to VAEX-1186
import { couponsSaga } from 'app/redux/coupons/coupons.saga';
import { registerSaga } from 'app/redux/register/register.saga';
import { typesSaga } from 'app/redux/types/types.saga';
import { usersSaga } from 'app/redux/users/users.saga';
import { notificationsSaga } from 'app/redux/notifications/notifications.saga';
import { errorReportingSaga } from 'app/redux/error/error-reporting.saga';
import { couponSaga } from 'app/redux/coupon/coupon.saga';

export function* appSaga() {
  yield fork(agreementsSaga);
  // yield fork(archiveSaga); FIXME: this is temporarily disabled due to VAEX-1186
  yield fork(couponsSaga);
  yield fork(registerSaga);
  yield fork(typesSaga);
  yield fork(usersSaga);
  yield fork(notificationsSaga);
  yield fork(errorReportingSaga);
  yield fork(couponSaga);
}
