import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { fetchAccounts } from 'app/redux/accounts/accounts.actions';
import { APPROVE_TERMS_SUCCESS, FETCH_CURRENT_USER_SUCCESS } from 'app/redux/users/users.action-types';
import { REDIRECTS } from 'app/constants';

export function* afterFetchCurrentUserSuccess({
  payload: {
    data: { terms = [] },
  },
}) {
  const unapproved = terms.find(term => !term.approved && term.required);

  if (unapproved) {
    yield put(push(`/terms/${unapproved.key}`));
  }
}

export function* afterApproveTermsSuccess() {
  const { terms, businesses } = yield select(({ users: { current } }) => current);

  const unapproved = terms.find(term => !term.approved && term.required);
  const currentBusinessRole = businesses[0].defaultRole;

  yield put(fetchAccounts());
  yield put(push(unapproved ? `/terms/${unapproved.key}` : REDIRECTS[currentBusinessRole]));
}

export function* agreementsSaga() {
  yield takeEvery(FETCH_CURRENT_USER_SUCCESS, afterFetchCurrentUserSuccess);
  yield takeEvery(APPROVE_TERMS_SUCCESS, afterApproveTermsSuccess);
}
