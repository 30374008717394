import { RSAA } from 'redux-api-middleware';
import * as types from './coupon.action-types';

export const fetchCoupon = couponId => ({
  [RSAA]: {
    types: [types.FETCH_COUPON_REQUEST, types.FETCH_COUPON_SUCCESS, types.FETCH_COUPON_FAILURE],
    endpoint: `/service-coupons/${couponId}`,
    method: 'GET',
  },
});
