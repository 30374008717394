// @flow

import React, { Component } from 'react';

import { AccountTransactionsTablePlaceholder } from './table/account-transactions-table.placeholder';
import { AccountTransactionsTilePlaceholder } from './tile/account-transactions-tile.placeholder';

import { ListComponent } from 'app/shared/list/list.component';
import { PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './account-transactions.placeholder.types';*/

export class AccountTransactionsPlaceholder extends Component /*:: <Props>*/ {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <>
        <CardHeaderComponent>
          <PlaceholderComponent width={150} />
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <ListComponent
            list={this.props.transactions}
            table={AccountTransactionsTablePlaceholder}
            tile={AccountTransactionsTilePlaceholder}
          />
        </CardBodyComponent>
      </>
    );
  }
}
