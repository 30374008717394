// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { TileComponent } from 'app/shared';
import { currencyFormatter, getDatesString } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './coupons-details-transactions-tile.component.types';*/

export class CouponsDetailsTransactionsTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CHARGED" tagName="small" />
            {dateFormatter(this.props.tile.date)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.TYPE" tagName="small" />
            <FormattedMessage id={`TRANSACTION_TYPE.${this.props.tile.type}`} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CREDITOR" tagName="small" />
            {this.props.tile.creditor}
          </div>
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.PRODUCED" tagName="small" />
            {getDatesString(this.props.tile.serviceProducedStart, this.props.tile.serviceProducedEnd, '-')}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.AMOUNT" tagName="small" />
            {currencyFormatter.formatWithSign(this.props.tile.amount)}
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.DESCRIPTIVE_SERVICE" tagName="small" />
            {this.props.tile.descriptiveService || '-'}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.PRICE_UNITS" tagName="small" />
            {this.props.tile.numberOfPriceUnits}
          </div>
        </div>
      </TileComponent>
    );
  }
}
