// @flow

import React, { useState } from 'react';
import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';
import { ImpersonationModalContainer } from './impersonation-modal/impersonation-modal.container';

/*:: import type { Props } from './sidebar-customer-service-tools.component.types';*/

export const SidebarCustomerServiceToolsComponent = (props /*: Props*/) => {
  const [isImpersonationPopupOpened, toggleImpersonationPopup] = useState(false);

  if (!checkPermissions(props.permissions, ['impersonate', 'update'])) {
    return null;
  }

  return (
    <>
      <SidebarTitleComponent>
        {props.intl.formatMessage({ id: 'NAV.CUSTOMER_SERVICE_TOOLS.TITLE' })}
      </SidebarTitleComponent>
      <SidebarGroupComponent>
        <SidebarItemContainer
          icon="business"
          onClick={() => toggleImpersonationPopup(true)}
          name={props.intl.formatMessage({ id: 'NAV.CUSTOMER_SERVICE_TOOLS.IMPERSONATE' })}
        />
      </SidebarGroupComponent>
      <ImpersonationModalContainer
        isOpen={isImpersonationPopupOpened}
        onCancel={() => toggleImpersonationPopup(false)}
      />
    </>
  );
};
