// @flow

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import get from 'lodash/get';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';

import { REDIRECTS } from 'app/constants';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Props } from './with-authorization.hoc.types';*/

import { checkPermissions } from 'app/utils';

const getPermissionPath = (requiredPermission, ...args) => {
  if (isFunction(requiredPermission)) {
    return requiredPermission(...args);
  }

  return requiredPermission.map(element => (isFunction(element) ? element(...args) : element));
};

export const withAuthorization =
  (...requiredPermissions /*: (string | Function)[][]*/) =>
  (WrappedComponent /*: ComponentType<any>*/) =>
    connect(
      (
        {
          permissions,
          users: { business },
          accounts: { isPostPaymentAccount },
          router: { location: { pathname: path } = {} } = {},
        },
        ownProps,
      ) => {
        return {
          business,
          authorized: requiredPermissions.find(
            requiredPermission =>
              !!get(
                permissions[business.id],
                getPermissionPath(requiredPermission, ownProps, {
                  permissions: permissions[business.id],
                  businessId: business.id,
                }),
              ),
          ),
          path,
          businessPermissions: permissions[business.id],
          isPostPaymentAccount,
        };
      },
    )(
      class Authorize extends Component /*:: <Props>*/ {
        getRedirectPath = () => {
          const { business, path, businessPermissions } = this.props;

          const pathPermissions = {
            '/coupons': ['serviceCoupons', 'read'],
            '/coupons/details': ['serviceCouponDetails', 'read'],
            '/coupons/charge': ['venuePayment', 'create'],
            '/vat-archive': ['vatArchive', 'read'],
            '/accounts/balance': ['municipalAccount', 'read'],
            '/venues': ['venues', 'read'],
          };

          const pathRequiredPermissions = pathPermissions[path];

          if (pathRequiredPermissions && !checkPermissions(businessPermissions, pathRequiredPermissions)) {
            return REDIRECTS[business.defaultRole];
          }
        };

        render() {
          const { business, authorized, isPostPaymentAccount, path } = this.props;
          const pathsNotAllowedForPostPaymentAccount = ['/orders/create/emoney'];
          const notAuthorized =
            !authorized || (isPostPaymentAccount && pathsNotAllowedForPostPaymentAccount.includes(path));

          if (notAuthorized) {
            const redirectPath = this.getRedirectPath();
            const pathname = (() => {
              if (business) {
                if (redirectPath) {
                  return redirectPath;
                } else {
                  return REDIRECTS[business.defaultRole] ? REDIRECTS[business.defaultRole] : '/';
                }
              }
            })();

            return (
              <Redirect
                to={{
                  pathname,
                }}
              />
            );
          }

          return <WrappedComponent {...omit(this.props, ['businessPermissions'])} module={authorized[0]} />;
        }
      },
    );
