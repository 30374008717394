// @flow
import React, { Component } from 'react';

import { PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { MunicipalityContractPlaceholder } from '../list/municipality/municipality-contract/placeholder/venue-municipality-contract.placeholder';

export class VenueMunicipalitiesPlaceholder extends Component /*:: <any>*/ {
  render() {
    return (
      <>
        <CardComponent>
          <CardHeaderComponent>
            <PlaceholderComponent width={160} height={24} color="green" />
          </CardHeaderComponent>
          <SeparatorComponent height={48} />
          <MunicipalityContractPlaceholder />
        </CardComponent>
        <SeparatorComponent height={48} />
      </>
    );
  }
}
