import React from 'react';
import classNames from 'classnames';

import { CardComponent } from 'app/shared/card-new';
import { LanguageSwitcherContainer } from 'app/core/language-switcher/language-switcher.container';

import vaanaLogo from 'assets/images/vaana-logo.svg';
import styles from './vaanaTheme.module.scss';

export const VaanaThemeComponent = ({ children, intl }) => (
  <>
    <nav className={styles.navWrapper}>
      <CardComponent className={styles.header}>
        <a href={intl.formatMessage({ id: 'VAANA_THEME.HEADER.VAANAFI_ROUTE' })} className={styles.link}>
          <img
            src={vaanaLogo}
            alt={intl.formatMessage({ id: 'VAANA_THEME.HEADER.NAVIGATION_TO_VAANAFI' })}
            className={styles.logo}
          />
        </a>
        <LanguageSwitcherContainer />
      </CardComponent>
    </nav>
    <div className={classNames(styles.wrapper, styles.body)}>{children}</div>
  </>
);
