// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

/*:: import type { Props } from './sidebar-account.component.types';*/

export class SidebarAccountComponent extends Component /*:: <Props>*/ {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['municipalOrders', 'read'],
        ['municipalBeneficiaryTransactions', 'read'],
        ['municipalTransactions', 'read'],
        ['municipalAccount', 'read'],
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {checkPermissions(this.props.permissions, ['municipalOrders', 'read']) &&
            !this.props.isPostPaymentAccount && (
              <SidebarItemContainer
                icon="account-balance"
                name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.ORDER_EMONEY' })}
                to="/orders/create/emoney"
              />
            )}

          {checkPermissions(this.props.permissions, ['municipalTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.HISTORY.MUNICIPAL_TRANSACTIONS' })}
              to="/transactions/municipal"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalAccount', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.CUSTOMER_ACCOUNTS' })}
              to="/accounts/municipal"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalBeneficiaryTransactions', 'read']) && (
            <SidebarItemContainer
              icon="history"
              name={this.props.intl.formatMessage({ id: 'NAV.MANAGEMENT.MUNICIPAL_BENEFICIARY_TRANSACTIONS' })}
              to="/transactions/municipal-beneficiary"
            />
          )}

          {checkPermissions(this.props.permissions, ['municipalAccount', 'read']) && (
            <SidebarItemContainer
              icon="account-balance"
              name={this.props.intl.formatMessage({ id: 'NAV.ACCOUNT.BALANCE' })}
              to="/accounts/balance"
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
