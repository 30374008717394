// @flow

import React, { Component } from 'react';

import { SettlementsListPlaceholder } from './placeholder/settlements-list.placeholder';
import { SettlementsListTableComponent } from './table/settlements-list-table.component';
import { SettlementsListTileComponent } from './tile/settlements-list-tile.component';

import { PageHeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { isBusinessDifferent } from 'app/utils';

/*:: import type { Props } from './settlements-list.component.types';*/

export class SettlementsListComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchSettlements();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchSettlements();
    }
  }

  render() {
    return (
      <>
        <PageHeaderComponent title="NAV.ADDITIONAL.SETTLEMENTS" />

        <CardComponent>
          <CardBodyComponent>
            {this.props.loading && <SettlementsListPlaceholder />}
            {this.props.loading || (
              <ListComponent
                list={this.props.settlements}
                table={SettlementsListTableComponent}
                tile={SettlementsListTileComponent}
                searchable={false}
                props={{
                  onDownload: this.props.onDownload,
                }}
                noResultsProps={{ searchable: false }}
              />
            )}
          </CardBodyComponent>
        </CardComponent>
      </>
    );
  }
}
