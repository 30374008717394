// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertComponent, ButtonComponent, SeparatorComponent, Typography } from 'app/shared';

import { businessName, checkPermissions, currencyFormatter, getUnitTranslationId } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';

import { CouponsDetailsInfoPlaceholder } from './placeholder/coupons-details-info.placeholder';
import { CouponsSingleRenewFormContainer } from './single-renew-form/coupons-single-renew-form.container';
import { InfoSectionComponent } from './info-section/info-section.component';

/*:: import type { Props } from './coupons-details-info.component.types';*/
import styles from './coupons-details-info.module.scss';

export const basicInfoTypographyProps = {
  color: 'secondary',
  type: 'largeTextBold',
};

export class CouponsDetailsInfoComponent extends Component /*:: <Props>*/ {
  render() {
    const canRenew = checkPermissions(this.props.permissions, ['serviceCoupons', 'update']);

    if (this.props.loading || !this.props.coupon) {
      return (
        <CardComponent>
          <CouponsDetailsInfoPlaceholder />
          <SeparatorComponent />
        </CardComponent>
      );
    }

    const {
      additionalInfo = '-',
      balance,
      createdAt,
      dateOfBirth,
      decisionNumber,
      unitCost,
      id,
      invalidated,
      invalidatedBy,
      issuer,
      maxTransaction,
      name,
      nameExtra,
      personalIdentificationCode,
      priceUnit,
      reference,
      type,
      venue,
      replacementCouponId,
    } = this.props.coupon;

    const municipalName = businessName({ name, nameExtra });

    const isServiceProvider = checkPermissions(this.props.permissions, ['venueServiceCoupons', 'read']);

    return (
      <>
        {invalidated && (
          <AlertComponent type="failure">
            <FormattedMessage
              id="COUPONS.DETAILS.INVALIDATED"
              values={{
                date: <strong>{dateFormatter(invalidated)}</strong>,
                user: <strong>{invalidatedBy || this.props.username}</strong>,
              }}
            />
          </AlertComponent>
        )}
        {this.props.redirectedFrom && (
          <AlertComponent type="info">
            <FormattedMessage id="COUPONS.DETAILS.REDIRECTED_TO_REPLACEMENT" />
            <ButtonComponent theme="link" size="regular" to={`/coupons/${this.props.redirectedFrom}`}>
              <FormattedMessage id="COUPONS.DETAILS.NAVIGATE_TO_REPLACED_VOUCHER" />
            </ButtonComponent>
          </AlertComponent>
        )}
        {replacementCouponId && (
          <AlertComponent type="info">
            <FormattedMessage id="COUPONS.DETAILS.REPLACEMENT_ACCOUNT_EXISTS" />
            <ButtonComponent theme="link" size="regular" to={`/coupons/${replacementCouponId}`}>
              <FormattedMessage id="COUPONS.DETAILS.NAVIGATE_TO_REPLACEMENT_VOUCHER" />
            </ButtonComponent>
          </AlertComponent>
        )}
        <CardComponent>
          <CardBodyComponent>
            <div className={styles.infoWrapper}>
              <div className={styles.basicInfo}>
                <Typography type="subtitle" color="greyDark" className={styles.equal}>
                  {id}
                </Typography>

                <InfoSectionComponent label="COUPONS.DETAILS.LABEL.TOTAL" childrenTypography={basicInfoTypographyProps}>
                  {currencyFormatter.format(balance)}
                </InfoSectionComponent>

                <InfoSectionComponent
                  label={getUnitTranslationId(priceUnit)}
                  childrenTypography={basicInfoTypographyProps}
                >
                  {currencyFormatter.format(unitCost)}
                </InfoSectionComponent>

                <InfoSectionComponent
                  label="COUPONS.DETAILS.LABEL.MAX_TRANSACTION"
                  childrenTypography={basicInfoTypographyProps}
                >
                  {currencyFormatter.format(maxTransaction)}
                </InfoSectionComponent>
              </div>

              <div className={styles.additionalInfo}>
                <div className={styles.equal}>
                  {!!reference && (
                    <Typography type="subtitle" color="greyDark">
                      {reference}
                    </Typography>
                  )}
                  {!!personalIdentificationCode
                    ? personalIdentificationCode
                    : !!dateOfBirth && dateFormatter(dateOfBirth)}
                </div>

                <div className={styles.row}>
                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.DECISION_VALUE" className={styles.col}>
                    {decisionNumber}
                  </InfoSectionComponent>

                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.TYPE" className={styles.col}>
                    {type}
                  </InfoSectionComponent>
                </div>

                <div className={styles.row}>
                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.VENUE" className={styles.col}>
                    {venue || '-'}
                  </InfoSectionComponent>

                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.PRICE_UNIT" className={styles.col}>
                    {priceUnit ? <FormattedMessage id={`COUPONS.TYPES.UNITS.${priceUnit}`} /> : '-'}
                  </InfoSectionComponent>
                </div>

                <div className={styles.row}>
                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.ISSUED" className={styles.col}>
                    {dateFormatter(createdAt)}
                  </InfoSectionComponent>

                  {isServiceProvider ? (
                    <InfoSectionComponent label="COUPONS.DETAILS.LABEL.MUNICIPAL" className={styles.col}>
                      {municipalName}
                    </InfoSectionComponent>
                  ) : (
                    <InfoSectionComponent label="COUPONS.DETAILS.LABEL.ISSUER_NAME" className={styles.col}>
                      {issuer}
                    </InfoSectionComponent>
                  )}
                </div>

                <div className={styles.row}>
                  <InfoSectionComponent label="COUPONS.DETAILS.LABEL.ADDITIONAL_INFO">
                    {additionalInfo}
                  </InfoSectionComponent>
                </div>
              </div>
            </div>
          </CardBodyComponent>
          <SeparatorComponent />

          {canRenew && (
            <CardBodyComponent>
              {!!invalidated || (
                <div className="row">
                  <div className="col-12 col-md-3 ml-auto">
                    <ButtonComponent className={styles.invalidate} onClick={this.props.onInvalidate} theme="failure">
                      <i className="fi fi-garbage" />
                      <FormattedMessage id="COUPONS.DETAILS.INVALIDATE" />
                    </ButtonComponent>
                  </div>
                </div>
              )}

              {!!invalidated || (
                <>
                  <SeparatorComponent />
                  <CouponsSingleRenewFormContainer coupon={this.props.coupon} />
                </>
              )}
            </CardBodyComponent>
          )}
        </CardComponent>
      </>
    );
  }
}
