// @flow

import React, { PureComponent } from 'react';

// $FlowFixMe
import { ReactComponent as Logo } from 'assets/images/logo.svg';

import styles from './aside.module.scss';

export class AsideComponent extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <aside className={styles.container}>
        <div className={styles.polygon}>
          <Logo />
        </div>
      </aside>
    );
  }
}
