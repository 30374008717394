// @flow

import { PDF, SUMMARY } from 'app/vat-archive/vat-archive.dictionary';

export const getDownloadLabel = (path /*::?: string*/) => {
  switch (path) {
    case PDF:
      return 'pdf';

    case SUMMARY:
      return 'summary';

    default:
      return 'excel';
  }
};
