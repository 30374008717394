//@flow
import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { Typography } from 'app/shared';
import { translate } from 'app/utils';

/*:: import type { Props } from './label.component.types';*/
import styles from './label.module.scss';

const typographySetting = {
  tag: 'span',
  type: 'smallText',
  color: 'grey',
};

export const Label = (props /*: Props*/) => {
  const {
    label,
    labelClassName,
    labelTextClassName,
    controlMeta: { isDisabled, isRequired } = {},
    setLabelRef = noop,
    setInputFocus,
  } = props;

  if (!label) {
    return null;
  }

  const labelClassNames = classNames(styles.label, labelClassName, {
    [styles.labelDisabled]: isDisabled,
    [styles.labelRequired]: isRequired,
  });

  // $FlowFixMe
  const labelTextProps = {
    className: classNames(styles.labelText, labelTextClassName),
    ...typographySetting,
    ...(!!label ? translate({ translation: label }, true) : { children: <span key={0}>&nbsp;</span> }),
  };

  const labelProps = {
    ...(props.id ? { htmlFor: props.id } : {}),
    ...(setInputFocus ? { onClick: setInputFocus } : {}),
  };

  return (
    <label ref={setLabelRef} className={labelClassNames} {...labelProps}>
      {!!label && <Typography {...labelTextProps} />}
    </label>
  );
};
