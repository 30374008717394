// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

/*:: import type { Props } from './back.component.types';*/
import styles from './back.module.scss';

export class BackComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    theme: 'primary',
  };

  render() {
    const { to, theme, onClick } = this.props;

    const linkClassNames = classNames(styles.back, styles[theme], this.props.className);

    if (to) {
      return (
        <Link to={to} className={linkClassNames}>
          <i className="fi fi-go-back-left-arrow" /> {this.props.children}
        </Link>
      );
    }

    if (onClick) {
      return (
        <button onClick={onClick} className={classNames(linkClassNames, styles.button)}>
          <i className="fi fi-go-back-left-arrow" /> {this.props.children}
        </button>
      );
    }

    return null;
  }
}
