// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { ButtonComponent } from 'app/shared';

import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './settlements-list-table.component.types';*/

export const headers = [
  { content: 'SETTLEMENTS.LIST.HEADERS.SETTLEMENT_ID' },
  { content: 'SETTLEMENTS.LIST.HEADERS.DATE' },
  { content: 'SETTLEMENTS.LIST.HEADERS.INTERVAL' },
  { content: 'SETTLEMENTS.LIST.HEADERS.GROSS_AMOUNT', numerical: true },
  {},
];

export class SettlementsListTableComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(settlement => (
            <TableRowComponent key={settlement.id}>
              <TableCellComponent>{settlement.settlementId || '-'}</TableCellComponent>
              <TableCellComponent>
                {settlement.processedAt ? dateFormatter(settlement.processedAt) : '-'}
              </TableCellComponent>
              <TableCellComponent>
                {settlement.startDate && settlement.endDate
                  ? `${dateFormatter(settlement.startDate)} - ${dateFormatter(settlement.endDate)}`
                  : '-'}
              </TableCellComponent>
              <TableCellComponent align="right">{currencyFormatter.format(settlement.amount)}</TableCellComponent>
              <TableCellComponent align="right">
                <ButtonComponent onClick={() => this.props.onDownload(settlement.id)} size="small">
                  <FormattedMessage id="SETTLEMENTS.BREAKDOWN" />
                </ButtonComponent>
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
