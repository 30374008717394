//@flow

import React, { Component } from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import { LoadingComponent } from 'app/shared/loading/loading.component';
import { ButtonComponent } from 'app/shared';

import { CardBodyComponent } from 'app/shared/card-new/body/card-body.component';
import { Typography } from 'app/shared/typography/typography.component';
import { FormattedMessage } from 'react-intl';

/*:: import type { Props } from './result-action-modal.component.types';*/
import styles from './result-action-modal.module.scss';

export class ResultActionModalComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    messages: {},
  };

  componentDidUpdate(prevProps /*: Props*/) {
    const { isLoading, isSuccess, messages, modalClose, onClose } = this.props;
    const messageKey = isSuccess ? 'success' : 'failure';

    if (isSuccess !== undefined && !messages[messageKey] && prevProps.isLoading === true && isLoading === false) {
      if (onClose instanceof Function) {
        onClose();
      }

      modalClose();
    }
  }

  render() {
    const { isLoading, isSuccess, modalClose, onClose, messages } = this.props;
    const messageKey = isSuccess ? 'success' : 'failure';
    const icon = isSuccess ? 'fi-check' : 'fi-error';
    const { title, body } = messages[messageKey] || {};

    const handleButtonClick = () => {
      if (onClose instanceof Function) {
        onClose();
      }

      modalClose();
    };

    const iconClassNames = classNames('fi', icon, styles.icon, styles[`icon${capitalize(messageKey)}`]);

    return (
      <CardBodyComponent className={classNames(styles.content, { [styles.minHeight]: isLoading })}>
        {isLoading && messageKey && <LoadingComponent isLoading={isLoading} overflow />}
        {isLoading || (
          <>
            <i className={iconClassNames} />
            {title && (
              <Typography type="subtitle" color="grey" className="text-center" id={title.id}>
                {title.id ? undefined : title}
              </Typography>
            )}
            {body && (
              <Typography type="bodyText" color="grey" className="mt-4 text-center">
                {body}
              </Typography>
            )}
            <ButtonComponent theme={messageKey} onClick={handleButtonClick} className="mt-4">
              <FormattedMessage id="CORE.CLOSE" />
            </ButtonComponent>
          </>
        )}
      </CardBodyComponent>
    );
  }
}
