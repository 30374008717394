import { useIntl } from 'app/hooks/useIntl/useIntl';
import { checkPermissions } from 'app/utils';
import { V1Permissions, V1RootState } from 'app/v1.types';
import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrganizationalUnitUseCases } from 'v2/types/postpay';
import { TranslationKey } from 'v2/types/translations';
import { Links } from 'v2/utils/links';
import { SidebarCustomerServiceToolsContainer } from '../customer-service-tools/sidebar-customer-service-tools.container';
import { SidebarGeneralContainer } from '../general/sidebar-general.container';
import { SidebarSettingsContainer } from '../settings/sidebar-settings.container';
import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from '../shared';

type SectionItemDefinition = {
  itemTitleKey: TranslationKey;
  sidebarItemProps: {
    icon: string;
    to: string;
    exact?: boolean;
    badge?: React.ReactNode;
    badgeRight?: boolean;
  };
};

type SectionDefinition = {
  sectionTitleKey: TranslationKey;
  items: SectionItemDefinition[];
};

function createSidebarSections(
  permissions: V1Permissions,
  availableVaanasystemUsecases: OrganizationalUnitUseCases,
  newVatArchiveReport: boolean | undefined,
) {
  function hasUsecase(usecase: keyof OrganizationalUnitUseCases) {
    return Boolean(availableVaanasystemUsecases[usecase]);
  }

  // show debit tools only for service provider users
  const showDebitTools = checkPermissions(permissions, ['municipalTransactions', 'read']);
  const sections: (Omit<SectionDefinition, 'items'> & { items: (SectionItemDefinition | false)[] })[] = [
    {
      sectionTitleKey: 'NAV.COUPONS.TITLE', // Organizer service voucher tools
      items: [
        hasUsecase(Links.OrganizationalUnit.IssueVoucherFromAvailableVoucherTypes) && {
          itemTitleKey: 'NAV.COUPONS.TYPES',
          sidebarItemProps: {
            icon: 'shareservicecoupon',
            to: '/service-vouchers/types',
            exact: true,
          },
        },
        hasUsecase(Links.OrganizationalUnit.ViewIssuedVouchers) && {
          itemTitleKey: 'NAV.COUPONS.COUPONS',
          sidebarItemProps: {
            icon: 'sharedservicecoupon',
            to: '/service-vouchers',
            exact: true,
          },
        },
        // TODO: "Add service voucher type"
      ],
    },
    {
      sectionTitleKey: 'NAV.MONITORING_DEBITS.TITLE', // "Old prepayment debit tools"
      items: showDebitTools
        ? [
            checkPermissions(permissions, ['municipalTransactions', 'read']) && {
              itemTitleKey: 'NAV.HISTORY.MUNICIPAL_TRANSACTIONS',
              sidebarItemProps: {
                icon: 'history',
                to: '/transactions/municipal',
              },
            },
            checkPermissions(permissions, ['settlements', 'read']) && {
              itemTitleKey: 'NAV.ADDITIONAL.SETTLEMENTS',
              sidebarItemProps: {
                icon: 'settlements',
                to: '/settlements',
              },
            },
            checkPermissions(permissions, ['vatArchive', 'read'], ['municipalTransactions', 'read']) && {
              itemTitleKey: 'NAV.ADDITIONAL.VAT_ARCHIVE',
              sidebarItemProps: {
                icon: 'commerce',
                to: '/vat-archive',
                badge: newVatArchiveReport && <i className={`fi fi-time text-white m-0`} />,
                badgeRight: true,
              },
            },
          ]
        : [],
    },
    {
      sectionTitleKey: 'NAV.MONITORING_SERVICE_EVENTS.TITLE', // Postpayment debit / service provider tools
      items: [
        // TODO: "Service events"
        // TODO: "Settlements"
        // TODO: "Bill archive"
      ],
    },
    {
      sectionTitleKey: 'NAV.ACCOUNT.TITLE', // Organizer
      items: [
        // TODO: "All service events"
      ],
    },
    {
      sectionTitleKey: 'NAV.ADDITIONAL.TITLE', // Organizer
      items: [
        // TODO: "Bill archive"
      ],
    },
  ];

  const nonEmptySections: SectionDefinition[] = sections
    .map(section => ({
      sectionTitleKey: section.sectionTitleKey,
      items: section.items.filter((item): item is SectionItemDefinition => item !== false),
    }))
    .filter(section => section.items.length > 0);

  return nonEmptySections;
}

type VaanasystemSidebarSectionsProps = {
  availableVaanasystemUsecases: OrganizationalUnitUseCases;
  v1Permissions: V1Permissions;
};

export function VaanasystemSidebarSections({ availableVaanasystemUsecases, v1Permissions }: VaanasystemSidebarSectionsProps) {
  const intl = useIntl();

  const newVatArchiveReport = useSelector((state: V1RootState) => state.archive.newVatArchiveReport);
  const isUserApproved = useSelector((state: V1RootState) => state.users.isApproved);

  const sections = useMemo(
    () => (isUserApproved ? createSidebarSections(v1Permissions, availableVaanasystemUsecases, newVatArchiveReport) : []),
    [v1Permissions, availableVaanasystemUsecases, isUserApproved, newVatArchiveReport],
  );

  return (
    <>
      {isUserApproved && (
        <>
          <SidebarGeneralContainer />
          {sections.map(section => (
            <React.Fragment key={section.sectionTitleKey}>
              <SidebarTitleComponent>{intl.formatMessage({ id: section.sectionTitleKey })}</SidebarTitleComponent>
              <SidebarGroupComponent>
                {section.items.map(item => {
                  const { itemTitleKey, sidebarItemProps } = item;
                  return (
                    <SidebarItemContainer
                      key={itemTitleKey}
                      name={intl.formatMessage({ id: itemTitleKey })}
                      {...sidebarItemProps}
                    />
                  );
                })}
              </SidebarGroupComponent>
            </React.Fragment>
          ))}
          <SidebarCustomerServiceToolsContainer />
        </>
      )}
      <SidebarSettingsContainer />
    </>
  );
}
