// @flow

import React, { PureComponent } from 'react';

import { PlaceholderComponent } from 'app/shared';

import { CouponsListTablePlaceholder } from './table/coupons-list-table.placeholder';

/*:: import type { Props } from './coupons-list.placeholder.types';*/

export class CouponsListPlaceholder extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    coupons: [...new Array(5)],
  };

  render() {
    return (
      <>
        <div className="card-header card-header--between">
          <h4 className="card-title">
            <PlaceholderComponent width={150} />
          </h4>
        </div>
        <div className="card-body">
          <CouponsListTablePlaceholder list={this.props.coupons} />
        </div>
      </>
    );
  }
}
