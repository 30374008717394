// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Fields } from 'redux-form';
import classNames from 'classnames';

import {
  ConnectedDateRangeComponent,
  ConnectedInputComponent,
  ConnectedSelectComponent,
  ConnectedTextareaComponent,
  ConnectedToggleComponent,
  SeparatorComponent,
  SummaryFieldComponent,
  Typography,
} from 'app/shared';
import { voucherValidityBounds } from 'app/utils/dates';
import { CouponsCalculatorContainer } from 'app/coupons/shared';
import { ButtonComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { CustomersCardComponent } from './customers-card.component';

import { ROUTES } from 'app/constants';
/*:: import type { Props, State } from './coupons-create-form.component.types';*/
import styles from './coupons-create-form.module.scss';

export class CouponsCreateFormComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isCalendarOpened: false,
    };
  }

  onCalendarToggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <CustomersCardComponent
          displayNameInput={this.props.current.displayNameInput}
          onCalendarToggle={this.onCalendarToggle}
          priceUnit={this.props.type.priceUnit}
          isPersonalIdentificationCodeEnabled={this.props.isPersonalIdentificationCodeEnabled}
        />
        <SeparatorComponent />
        <CardComponent>
          <CardHeaderComponent direction="column" alignItems="flex-start" title={this.props.type.name}>
            {this.props.type.description && (
              <>
                <SeparatorComponent height={8} />
                <Typography tag="h4" color="grey" type="subtitle">
                  {this.props.type.description}
                </Typography>
              </>
            )}
          </CardHeaderComponent>
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <div className="col-12">
                <ConnectedDateRangeComponent
                  field={Field}
                  name="valid"
                  startDateKey="from"
                  endDateKey="to"
                  required
                  onFocusChange={this.onCalendarToggle}
                  label="COUPONS.CREATE.LABEL.VALID"
                  bounds={voucherValidityBounds}
                  daysCounter
                />
              </div>
              <div className={columnClassNames}>
                <CouponsCalculatorContainer
                  value={{
                    name: 'defaultValue',
                    label: this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.VALUE' }),
                    required: true,
                  }}
                  calculations="defaultValueCalculations"
                  form="coupon-create"
                />
              </div>
              <div className="col-12 col-lg-6">
                <SummaryFieldComponent equalFormHeights>
                  <FormattedMessage id="COUPONS.TYPES.LABEL.PRICE_UNIT" tagName="strong" />
                  {this.props.type.priceUnit ? (
                    <FormattedMessage id={`COUPONS.TYPES.UNITS.${this.props.type.priceUnit}`} />
                  ) : (
                    '-'
                  )}
                </SummaryFieldComponent>
              </div>
            </div>
            <div className="row">
              <div className={classNames(columnClassNames, 'mb-4')}>
                <Field
                  name="transactionMaxPriceEnabled"
                  component={ConnectedToggleComponent}
                  format={v => !!v}
                  label={this.props.intl.formatMessage({ id: 'COUPONS.CREATE.LABEL.SET_MAX_TRANSACTION' })}
                  theme="full"
                  className={styles.toggle}
                />
              </div>
            </div>
            <div className="row">
              <div className={columnClassNames}>
                <Fields
                  names={['transactionMaxPriceEnabled', 'defaultTransactionMaxPrice']}
                  component={({ defaultTransactionMaxPrice, transactionMaxPriceEnabled }) =>
                    transactionMaxPriceEnabled.input.value && (
                      <ConnectedInputComponent
                        {...defaultTransactionMaxPrice}
                        label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.MAX_TRANSACTION' })}
                        icon="euro-symbol"
                      />
                    )
                  }
                />
              </div>
            </div>
            <div className="row">
              {this.props.current.displayVenueInput && (
                <div className={columnClassNames}>
                  <Field
                    name="venueId"
                    component={ConnectedSelectComponent}
                    options={this.props.contractsOptions}
                    label="COUPONS.CREATE.LABEL.VENUE_CREATE"
                    withClearOption
                  />
                </div>
              )}
              <div className="col-lg-12">
                <Field
                  name="additionalInfo"
                  component={ConnectedTextareaComponent}
                  label="COUPONS.TYPES.LABEL.ADDITIONAL"
                  maxLength={250}
                />
              </div>
            </div>
          </CardBodyComponent>
          <CardFooterComponent>
            <ButtonComponent theme="primary-outline" to={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.CANCEL" />
            </ButtonComponent>
            <ButtonComponent loading={this.props.submitting} className="ml-auto">
              <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" />
            </ButtonComponent>
          </CardFooterComponent>
          <div className={backdropClassNames} />
        </CardComponent>
      </>
    );
  }
}
