// @flow

import React, { PureComponent } from 'react';
import { CalculationsRowComponent } from './calculations-row/calculations-row.component';

/*:: import type { Props } from './coupons-calculator-calculations.component.types';*/
import styles from './coupons-calculator-calculations.module.scss';

export class CouponsCalculatorCalculationsComponent extends PureComponent /*:: <Props>*/ {
  onAdd = () => this.props.fields.push({ amount: 1 });

  render() {
    const { intl, onChange } = this.props;

    return (
      <>
        {this.props.fields.map((field, index) => {
          const props = {
            field,
            index,
            intl,
            key: index,
            onChange,
            disabled: this.props.fields.length === 1,
            onRemove: () => {
              this.props.fields.remove(index);
              onChange();
            },
          };

          return <CalculationsRowComponent {...props} />;
        })}
        <button className={styles.button} type="button" onClick={this.onAdd}>
          {this.props.intl.formatMessage({ id: 'CALCULATOR.ADD_ROW' })}
        </button>
      </>
    );
  }
}
