// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { TransactionsMunicipalBeneficiaryContainer } from './municipal-beneficiary/transactions-municipal-beneficiary.container';
import { TransactionsMunicipalContainer } from './municipal/transactions-municipal.container';

/*:: import type { Props } from './transactions.component.types';*/

export class TransactionsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/municipal`} component={TransactionsMunicipalContainer} />
        <Route
          path={`${this.props.match.url}/municipal-beneficiary`}
          component={TransactionsMunicipalBeneficiaryContainer}
        />
      </Switch>
    );
  }
}
