//@flow

import React from 'react';
import { Field, formValueSelector } from 'redux-form';

import { useSelector } from 'app/hooks/redux';
import { ConnectedTextareaComponent, FieldValueRenderer } from 'app/shared';

import styles from '../price-list.common.module.scss';

/*:: import type { Props } from './price-list-text.component.types';*/

export const PriceListTextComponent = (props /*: Props*/) => {
  const {
    name,
    editable,
    editMode,
    meta: { form },
    showDiff,
  } = props;
  const value = formValueSelector(form)(
    useSelector(state => state),
    `${name}.text`,
  );

  const diff = formValueSelector(form)(
    useSelector(state => state),
    `${name}.diff`,
  );

  const hasContent = showDiff ? diff.length > 0 : (value && value.length > 0);
  const shouldRender = editable || hasContent;
  if (!shouldRender) {
    return null;
  }

  const Wrapper = () => (
    <span className={styles.text}>
      {showDiff && diff
        ? diff.map((word, i) => (
          <span key={`${word.value}-${i}`} className={word.added ? styles.textAdded : word.removed ? styles.textRemoved : ''}>
            {word.value}
          </span>
        ))
        : value}
    </span>
  );

  return (
    <Field
      component={editable ? ConnectedTextareaComponent : FieldValueRenderer}
      name={`${name}.text`}
      label="PRICELIST.ADDITIONAL_INFO"
      disabled={!editMode}
      maxLength={1000}
      {...(!editable ? { wrapper: Wrapper } : {})}
    />
  );
};
