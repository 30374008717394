import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import get from 'lodash/get';
import once from 'lodash/once';

import { withAuthorization } from 'app/common';

import { fetchCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { cancelOrder, confirmOrder, createOrder } from 'app/redux/orders/orders.actions';
import { lockBusinessChange, unlockBusinessChange } from 'app/redux/view/view.actions';

import { OrdersCreateComponent } from './orders-create.component';

const formName = 'create-order';

const getInitialValues = (business, customers, current, form) => {
  const formValues = getFormValues(formName)({ form }) || {};
  const invoicingMethod = get(formValues, ['invoicing', 'invoicingMethod'], 'EINVOICE');

  const commonInitialValues = {
    invoicing: {
      invoiceName: get(formValues, ['invoicing', 'invoiceName'], customers[business.id].name),
      invoicingMethod,
      invoiceEmail: get(formValues, ['invoicing', 'invoiceEmail'], current.personal.email),
      invoiceReference: get(
        formValues,
        ['invoicing', 'invoiceReference'],
        customers[business.id].invoicing.defaultInvoicingReference,
      ),
    },
    amount: formValues.amount,
  };

  const eInvoiceValues = {
    eInvoiceAddress: customers[business.id].invoicing.eInvoiceAddress,
    eInvoiceIdentifier: customers[business.id].invoicing.eInvoiceDeliveryIdentifier,
  };

  return {
    ...commonInitialValues,
    invoicing: { ...commonInitialValues.invoicing, ...(invoicingMethod === 'EMAIL' ? {} : eInvoiceValues) },
  };
};

const mapStateToProps = ({
  customers: { entities: customers },
  orders: { newOrderId: id },
  users: { business, current },
  form,
}) => {
  return {
    orderId: id,
    business,
    initialValues: !customers[business.id] ? undefined : getInitialValues(business, customers, current, form),
  };
};

const mapDispatchToProps = (dispatch, { intl: { locale } }) => ({
  fetchCustomer: customer => dispatch(fetchCustomer(customer)),
  fetchInvoicingOptions: () => dispatch(fetchDictionary('invoicing-options')),
  onCancel: orderId => dispatch(cancelOrder(orderId)),
  onChange: once(() => dispatch(lockBusinessChange())),
  onConfirm: orderId => dispatch(confirmOrder(orderId, 'order_coupon_emoney', locale)),
  onSubmit: body => dispatch(createOrder(body)),
  onSubmitSuccess: () => window.scrollTo(0, 0),
  onUnmount: () => dispatch(unlockBusinessChange()),
});

export const OrdersCreateContainer = compose(
  withAuthorization(['municipalOrders', 'create']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    form: formName,
  }),
)(OrdersCreateComponent);
