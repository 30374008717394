import { Payment } from 'v2/types/payments';
import _ from 'lodash';
import { computeServicePriceForRow, ServicePriceNotComputable } from './compute-service-price';
import moment from 'moment';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { VenueId } from '../../../types/venue';
import { ServiceEventFormValues, ServiceEventRow } from '../types';
import { HousingServicesVoucher } from '../types/housing-services-voucher';

export type TransformServiceEventToPayments = (
  voucher: HousingServicesVoucher,
  venueId: VenueId,
  event: ServiceEventFormValues,
) => Payment[];

export const transformToPayments: TransformServiceEventToPayments = (
  voucher: HousingServicesVoucher,
  venueId: VenueId,
  event: ServiceEventFormValues,
): Payment[] => {
  return event.serviceEventRows.map(_.curry(transformToPayment)(voucher)(venueId));
};

const transformToPayment = (voucher: HousingServicesVoucher, venueId: VenueId, row: ServiceEventRow): Payment => {
  const priceResult = computeServicePriceForRow(row, voucher, venueId);

  if (priceResult === ServicePriceNotComputable) {
    throw new Error('Service price for row cannot be computed');
  }

  return {
    accountNumber: voucher.id,
    active: true,
    numberOfPriceUnits: priceResult.numberOfDays.toString(),
    serviceProduced: {
      startDate: formatDate(row.period.startDate),
      endDate: formatDate(row.period.endDate),
    },
    amount: priceResult.amount,
    reference: row.transactionReference,
    descriptiveService: row.producedService,
  };
};

const formatDate = (date?: Date): string => {
  return moment(date).format(DATE_VALUE_FORMAT);
};
