// @flow

import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import { PageHeaderComponent } from 'app/core';
import { TableBodyComponent, TableCellComponent, TableComponent, TableRowComponent } from 'app/shared/table-new';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';

import { AgreementsDetailsContainer } from '../details/agreements-details.container';

/*:: import type { Props } from './agreements-list.component.types';*/

export class AgreementsListComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    terms: [],
  };

  render() {
    return (
      <>
        <PageHeaderComponent title="NAV.SETTINGS.TERMS" />

        <CardComponent>
          <CardBodyComponent>
            <TableComponent>
              <TableBodyComponent>
                {this.props.terms.map((term, index) => (
                  <TableRowComponent key={index}>
                    <TableCellComponent>
                      <Link to={`/terms/${term.key}`}>{term.name}</Link>
                    </TableCellComponent>
                  </TableRowComponent>
                ))}
              </TableBodyComponent>
            </TableComponent>
          </CardBodyComponent>
        </CardComponent>

        <Switch>
          <Route path={`${this.props.match.url}/:key`} component={AgreementsDetailsContainer} />
        </Switch>
      </>
    );
  }
}
