// @flow

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Field, FieldArray } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { ConnectedMoneyInputComponent } from 'app/shared';

import { CouponsCalculatorCalculationsComponent } from './calculations/coupons-calculator-calculations.component';

/*:: import type { Props, State } from './coupons-calculator.component.types';*/
/*:: import type { RefType } from 'app/flow-types';*/
import styles from '../final-calculator/coupons-calculator.final.module.scss';

const LEFT = 'left';
const RIGHT = 'right';

export class CouponsCalculatorComponent extends PureComponent /*:: <Props, State>*/ {
  fieldRef /*: RefType*/ = React.createRef();
  calculatorRef /*: RefType*/ = React.createRef();

  state = {
    isOpened: false,
    calculatorSide: LEFT,
  };

  componentDidMount() {
    window.addEventListener('resize', debounce(this.determineCalculatorPosition, 100));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.determineCalculatorPosition, 100));
  }

  componentDidUpdate(prevProps /*: Props*/, prevState /*: State*/) {
    if (prevState.isOpened !== this.state.isOpened) {
      this.determineCalculatorPosition();
    }
  }

  determineCalculatorPosition = () => {
    const calculatorNode = this.calculatorRef.current;
    const fieldNode = this.fieldRef.current;

    if (calculatorNode && fieldNode) {
      const { width } = calculatorNode.getBoundingClientRect();
      const { right } = fieldNode.getBoundingClientRect();
      const calculatorSide = right - width < 0 ? RIGHT : LEFT;

      if (this.state.calculatorSide !== calculatorSide) {
        this.setState({ calculatorSide });
      }
    }
  };

  handleClose = () => this.setState({ isOpened: false });

  handleClickOutside = (evt /*: SyntheticMouseEvent<MouseEvent> | SyntheticTouchEvent<TouchEvent>*/) =>
    this.handleClose();

  handleKeyPress = (e /*: KeyboardEvent*/) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleClose();
    }
  };

  onFocus = () => this.setState({ isOpened: true });
  handleOnChange = () => this.props.onChange();
  handleOnClear = () => this.props.onClear();

  renderCalculator = () => {
    const calculatorClassNames = classNames(styles.calculator, this.props.className, {
      [styles.rightSideCalculator]: this.state.calculatorSide === RIGHT,
    });

    return (
      <div className={calculatorClassNames} onKeyPress={this.handleKeyPress} ref={this.calculatorRef}>
        <p className={styles.desc}>
          <FormattedMessage id="CALCULATOR.DESC" />
        </p>
        <FieldArray
          name={this.props.calculations}
          component={CouponsCalculatorCalculationsComponent}
          onChange={this.handleOnChange}
          intl={this.props.intl}
        />
      </div>
    );
  };

  render() {
    const inputGroupRef = get(this.fieldRef, ['current']);

    return (
      <div className={styles.calculatorWrapper}>
        <ConnectedMoneyInputComponent
          field={Field}
          inputRef={this.fieldRef}
          append={
            <>
              <i className="fi fi-calculator" />
              {this.state.isOpened && <div className={styles.inputArrow} />}
            </>
          }
          appendComponent={({ append }) => append}
          onFocus={this.onFocus}
          onChange={this.handleOnClear}
          {...this.props.value}
        />
        {this.state.isOpened && !!inputGroupRef && ReactDOM.createPortal(this.renderCalculator(), inputGroupRef)}
      </div>
    );
  }
}
