// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class VenuesListTilePlaceholder extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={150} height={27} />
        </h3>

        <div className="row mb-0 pb-0">
          <div className="col mt-1">
            <div>
              <FormattedMessage id="VENUES.STATUS" />:
              <PlaceholderComponent width={100} height={18} />
            </div>
          </div>
        </div>
        <div className="row mt-0 pt-0">
          <div className="col mt-1">
            <FormattedMessage id="VENUES.CITY" />:
            <PlaceholderComponent width={100} height={18} />
          </div>
        </div>

        <div className="buttons">
          <PlaceholderComponent width={150} height={32} color="purple" />
        </div>
      </TileComponent>
    );
  }
}
