// @flow

/*:: import type { Config } from './decimal-formatter.util.types';*/

export const decimalFormatter = ({ value, min = 2, max = 2, grouping = true } /*: Config*/) =>
  new Intl.NumberFormat('fi-FI', {
    style: 'decimal',
    maximumFractionDigits: max,
    minimumFractionDigits: min,
    useGrouping: grouping,
  }).format(value);
