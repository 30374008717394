// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { checkPermissions, currencyFormatter, getAmountColor } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

import { ButtonComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

/*:: import type { Props } from './account-transactions-table.component.types';*/
import styles from 'app/transactions/municipal/table/transactions-municipal-table.module.scss';

export class AccountTransactionsTableComponent extends Component /*:: <Props>*/ {
  render() {
    const hasPermissionsForDetails = checkPermissions(this.props.permissions, ['transactionDetails', 'read']);

    const headers = [
      { content: 'TRANSACTIONS.DATE' },
      { content: 'TRANSACTIONS.PAYER_RECEIVER' },
      { content: 'TRANSACTIONS.AMOUNT', numerical: true },
      !!hasPermissionsForDetails && {},
    ];

    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(transaction => {
            const rowClassNames = !!transaction.refundedBy || !!transaction.refunds ? { className: 'filled-row' } : {};
            return (
              <TableRowComponent key={transaction.id} {...rowClassNames}>
                <TableCellComponent>{dateFormatter(transaction.date, true)}</TableCellComponent>
                <TableCellComponent>{transaction.owner}</TableCellComponent>
                <TableCellComponent
                  align="right"
                  typographyProps={getAmountColor(transaction.amount)}
                  className={styles.amount}
                >
                  {currencyFormatter.format(transaction.amount)}
                </TableCellComponent>

                {hasPermissionsForDetails && (
                  <TableCellComponent align="right">
                    <ButtonComponent
                      theme="secondary-outline"
                      noWrap
                      to={{
                        pathname: `${this.props.pathname}/${transaction.id}`,
                        state: {
                          keepScrollPosition: true,
                        },
                      }}
                    >
                      <i className="fi fi-open" />
                      <FormattedMessage id="COUPONS.TYPES.LABEL.OPEN" />
                    </ButtonComponent>
                  </TableCellComponent>
                )}
              </TableRowComponent>
            );
          })}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
