// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';

import { ButtonComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { Modal } from 'app/shared/modal-new/modal.container';
import { ResultActionModalComponent } from 'app/shared/result-action-modal/result-action-modal.component';
import { CouponsDetailsChargeModalContainer } from './modal/coupons-details-charge-modal.container';
import { CouponsDetailsChargeServicesContainer } from './services/coupons-details-charge-services.container';

/*:: import type { Props, State } from './coupons-details-charge-form.types';*/
import styles from './coupons-details-charge-form.module.scss';

export class CouponsDetailsChargeFormComponent extends PureComponent /*:: <Props, State>*/ {
  state = {
    confirmationModalOpen: false,
    resultModalOpen: false,
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      confirmationModalOpen: !prevState.confirmationModalOpen,
    }));
  };

  toggleResultModal = () => {
    this.setState((prevState) => ({
      resultModalOpen: !prevState.resultModalOpen,
    }));
  };

  handleSubmit = () => {
    this.toggleConfirmationModal();
    return Promise.reject();
  };

  renderModal = () => {
    if (this.state.resultModalOpen) {
      return (
        <Modal
          component={ResultActionModalComponent}
          componentProps={{
            isLoading: this.props.submitting,
            isSuccess: this.props.submitSucceeded,
            onClose: () => {
              this.toggleResultModal();

              if (this.props.submitSucceeded) {
                this.props.reset();
              }
            },
            messages: {
              success: {
                title: this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.CHARGE.MODAL.SUCCESS' }),
              },
              failure: {
                title: this.props.intl.formatMessage({
                  id: this.props.error || 'COUPONS.DETAILS.CHARGE.MODAL.FAILURE',
                }),
              },
            },
          }}
          isOpen
        />
      );
    }

    return (
      <Modal
        cardClassNames={styles.modal}
        component={CouponsDetailsChargeModalContainer}
        confirmOptions={[
          {
            label: this.props.intl.formatMessage({ id: 'CORE.BACK' }),
            onClick: this.toggleConfirmationModal,
            theme: 'primary-outline',
            arrowBack: true,
          },
          {
            label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
            onClick: () => {
              if (this.props.beforeSubmit) {
                this.props.beforeSubmit();
              }
              this.props.handleSubmit();
              this.toggleConfirmationModal();
              this.toggleResultModal();
            },
          },
        ]}
        componentProps={{
          coupon: this.props.coupon,
        }}
        isOpen={this.state.confirmationModalOpen}
        title="COUPONS.DETAILS.CHARGE.MODAL.HEADER"
      />
    );
  };

  render() {
    return (
      <>
        <CardComponent>
          <CardHeaderComponent title="COUPONS.DETAILS.CHARGE.TITLE">{this.props.cardHeaderButton}</CardHeaderComponent>
          <SeparatorComponent />
          <CardBodyComponent>
            <form autoComplete="off">
              <FieldArray
                name="payments"
                component={CouponsDetailsChargeServicesContainer}
                coupon={this.props.coupon}
              />

              <CardFooterComponent className="text-right">
                <ButtonComponent
                  onClick={this.props.handleSubmit(this.handleSubmit)}
                  theme="primary"
                  loading={this.props.submitting}
                  className="ml-auto"
                >
                  <FormattedMessage id="COUPONS.DETAILS.CHARGE.BUTTON" />
                </ButtonComponent>
              </CardFooterComponent>

              {this.renderModal()}
            </form>
          </CardBodyComponent>
        </CardComponent>
      </>
    );
  }
}
