// @flow
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';

import { withClear, withPermissions } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchCouponTypes } from 'app/redux/types/types.actions';

import { CouponsListFiltersComponent } from './coupons-list-filters.component';
import { withCoreContext } from 'app/context/core/core.context';
import { convertParams } from 'app/utils';
/*:: import type { CouponSearchType } from './coupons-list-filters.component.types';*/

const NOT_ALLOWED_QUERY_PARAMS /*: string[]*/ = [
  'customers',
  'dateOfBirth',
  'decisionNumber',
  'personalIdentificationCode',
];

export const initialValuesSelector = (query /*: string*/) /*: CouponSearchType*/ => {
  const searchParams = new URLSearchParams(query);

  const issuersParameter = searchParams.get('issuers');
  const issuers = issuersParameter ? issuersParameter.split(',') : '';

  return {
    codes: searchParams.get('codes') || '',
    customers: '',
    decisionNumber: '',
    dateOfBirth: '',
    issuers,
    created: {
      createdMin: searchParams.get('createdMin') || '',
      createdMax: searchParams.get('createdMax') || '',
    },
    personalIdentificationCode: '',
    status: searchParams.get('status') || '',
    transaction: {
      transactionMin: searchParams.get('transactionMin') || '',
      transactionMax: searchParams.get('transactionMax') || '',
    },
    transfer: {
      transferMin: searchParams.get('transferMin') || '',
      transferMax: searchParams.get('transferMax') || '',
    },
    type: searchParams.get('type') ? Number(searchParams.get('type')) : '',
    venueId: searchParams.get('venueId') || '',
    endMonth: {
      validMin: searchParams.get('validMin') || '',
      validMax: searchParams.get('validMax') || '',
    },
  };
};

export const searchParamsSelector = (params /*::?: CouponSearchType*/) /*: string*/ => {
  const searchParams = new URLSearchParams();
  const allowedParams = omit(convertParams(params), NOT_ALLOWED_QUERY_PARAMS);

  // eslint-disable-next-line no-unused-vars
  for (const param in allowedParams) {
    if (allowedParams.hasOwnProperty(param) && !!allowedParams[param]) {
      searchParams.set(param, allowedParams[param]);
    }
  }

  return searchParams.toString();
};

const onChange = async ({ dispatch, fetch }, body /*: CouponSearchType*/) => {
  await dispatch(
    push({
      search: searchParamsSelector(body),
      state: {
        keepScrollPosition: true,
      },
    }),
  );
  fetch && fetch(); //TODO Waiting for removal on renew page usage
};

const mapStateToProps = (
  { dictionaries, types: { list: types }, coupons: { meta } = {} },
  { intl, location: { search } },
) => ({
  columnsList: get(meta, ['columns', 'list'], []),
  types,
  initialValues: initialValuesSelector(search),
  issuers: dictionaries.issuers
    ? dictionaries.issuers.map(issuer => ({
        id: issuer.id,
        name: `${issuer.firstName} ${issuer.lastName}`,
      }))
    : undefined,
  loading: !types,
  statuses: dictionaries['coupon-statuses']
    ? Object.keys(dictionaries['coupon-statuses']).map(key => ({
        id: dictionaries['coupon-statuses'][key],
        name: intl.formatMessage({ id: `COUPONS.STATUSES.${key}` }),
      }))
    : undefined,
  venues: isArray(dictionaries['contracted-venues'])
    ? dictionaries['contracted-venues'].map(venue => ({
        id: venue.id,
        name: venue.name,
      }))
    : undefined,
});

const mapDispatchToProps = (dispatch, { fetch }) => ({
  fetchContractedVenues: () => dispatch(fetchDictionary('contracted-venues', undefined, true)),
  fetchIssuers: () => dispatch(fetchDictionary('issuers', undefined, true)),
  fetchStatuses: () => dispatch(fetchDictionary('coupon-statuses', true)),
  fetchTypes: () => dispatch(fetchCouponTypes({ size: 1000 })),
  onChange: debounce(onChange.bind(this, { dispatch, fetch }), 500),
});

export const CouponsListFiltersContainer = compose(
  withRouter,
  injectIntl,
  withCoreContext,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
  withClear,
)(CouponsListFiltersComponent);
