// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { CardComponent } from 'app/shared/card-new';
import { isBusinessDifferent } from 'app/utils';

import { CustomerInformationFormContainer } from './form/customer-information-form.container';
import { CustomerInformationPlaceholder } from './placeholder/customer-information.placeholder';

/*:: import type { Props } from './customer-information.component.types';*/

export class CustomerInformationComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchCustomer();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchCustomer();
    }
  }

  render() {
    return (
      <>
        <PageHeaderComponent title="NAV.GENERAL.CUSTOMER_INFORMATION" />
        <CardComponent>
          {this.props.loading && (
            <CustomerInformationPlaceholder intl={this.props.intl} sidebarCollapsed={this.props.sidebarCollapsed} />
          )}
          {this.props.loading || <CustomerInformationFormContainer customer={this.props.customer} />}
        </CardComponent>
      </>
    );
  }
}
