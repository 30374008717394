import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import { updateMunicipalitiesContracts } from 'app/redux/venues/venues.actions';
import { fetchMunicipalities } from 'app/redux/venues/venues.actions';

import { MunicipalityContractComponent } from './venue-municipality-contract.component';

import { handleAnalyticsEvent } from 'app/utils';
import { getOriginalContractPricelists } from 'app/utils/pricelist/get-formatted-pricelist';

const mapStateToProps = (state, { contract }) => {
  const priceLists = get(contract, ['priceLists'], []);

  return {
    initialValues: {
      ...contract,
    },
    editMode: !priceLists.length,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  onSubmit: async values => {
    handleAnalyticsEvent('pricelists', 'send');
    const formattedValues = { ...values, priceLists: getOriginalContractPricelists(values) };
    await dispatch(updateMunicipalitiesContracts(formattedValues, id, formattedValues.id));
  },
  onSubmitSuccess: () => {
    dispatch(fetchMunicipalities(id, true));
  },
});

export const VenueMunicipalityContractContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
)(MunicipalityContractComponent);
