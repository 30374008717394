import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';

import { OrdersConfirmationModalComponent } from './orders-confirmation-modal.component';

const mapStateToProps = ({ form }) => ({
  values: getFormValues('create-order')({ form }),
});

export const OrdersConfirmationModalContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(OrdersConfirmationModalComponent);
