//@flow

import moment from 'moment';

import { calculateDateDiff } from 'app/utils';
import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type Moment from 'moment';*/
/*:: import type { Props } from './connected-date-range-calculate-dates.types';*/
import { convertDate } from 'app/shared/form/controls/datepicker/datepicker-helpers';

export const calculateDates = (date /*: Moment*/, props /*: Props*/) => {
  const {
    autofillEndDate,
    startDateKey,
    endDateKey,
    input: { value: values },
  } = props;

  const startDate = convertDate(values[props.startDateKey]);
  const endDate = convertDate(values[props.endDateKey]);

  if (date && endDate && startDate) {
    const isEndDateEndMonth = moment(endDate.clone().endOf('month').format(DATE_VALUE_FORMAT)).isSame(endDate);

    const isStartDateStartMonth = moment(date.clone().startOf('month').format(DATE_VALUE_FORMAT)).isSame(date);

    const isOldStartDateStartMonth = moment(startDate.clone().startOf('month').format(DATE_VALUE_FORMAT)).isSame(
      startDate,
    );

    const newEndDate = (() => {
      if (isOldStartDateStartMonth && isStartDateStartMonth && isEndDateEndMonth) {
        const diff = calculateDateDiff(startDate, date, { unit: 'months' });

        return endDate.clone().add(diff, 'months').endOf('month');
      }

      const diff = calculateDateDiff(startDate, endDate);

      return date.clone().add(diff, 'days');
    })();

    return {
      [startDateKey]: date.format(DATE_VALUE_FORMAT),
      [endDateKey]: newEndDate.format(DATE_VALUE_FORMAT),
    };
  }

  if (autofillEndDate && date && !endDate) {
    return {
      [startDateKey]: date.format(DATE_VALUE_FORMAT),
      [endDateKey]: date.format(DATE_VALUE_FORMAT),
    };
  }

  return {
    [startDateKey]: date.format(DATE_VALUE_FORMAT),
    [endDateKey]: !!endDate ? endDate.format(DATE_VALUE_FORMAT) : '',
  };
};
