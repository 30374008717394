import { FunctionComponent, useEffect, useState } from 'react';
import { ButtonComponent, TooltipComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import { Voucher } from 'v2/types/voucher';
import { VenueId } from 'v2/types/venue';
import { CouponsDetailsChargeFormContainer } from 'app/coupons/details/charge/form/coupons-details-charge-form.container';
import { useIntl } from 'app/hooks/useIntl/useIntl';
import { isHousingServicesVoucher } from 'v2/service-events/housing-service/types/housing-services-voucher';
import { reportMessage } from 'app/sentry/sentry';
import { ServiceEventSubmissionCardComponent } from 'v2/service-events/housing-service/service-event-submission-card.component';
import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { handleAnalyticsEvent } from 'app/utils/handle-analytics-event/handle-analytics-event.util';
import { configuration } from 'configuration';
import { isSwitchBetweenHousingServiceDebitViewEnabled } from 'app/features/switch-between-housing-service-debit-views-enabled.feature';
import { Transaction } from 'v2/types/transaction';

interface SwitchDebitingToolButtonProperties {
  onClick: () => void;
  label: TranslationKey;
  tooltip: TranslationKey;
}

const SwitchDebitingToolButton: FunctionComponent<SwitchDebitingToolButtonProperties> = ({
  label,
  tooltip,
  onClick,
}) => {
  const intl = useIntl();

  return (
    <ButtonComponent theme="secondary-outline" size="small" onClick={onClick}>
      <TooltipComponent overlay={intl.formatMessage({ id: tooltip })} placement="left">
        <span>
          <FormattedMessage id={label} />
        </span>
      </TooltipComponent>
    </ButtonComponent>
  );
};

enum DebitingTool {
  EventSubmission,
  Generic,
}

interface ServiceVoucherDebitComponentProperties {
  voucher: Voucher;
  venueId: VenueId;
  transactions?: Transaction[];
}

export const ServiceVoucherDebitComponent: FunctionComponent<ServiceVoucherDebitComponentProperties> = ({
  voucher,
  venueId,
  transactions,
}) => {
  const [debitingTool, setDebitingTool] = useState<DebitingTool>(
    isHousingServicesVoucher(voucher) ? DebitingTool.EventSubmission : DebitingTool.Generic,
  );
  useEffect(() => {
    if (!isHousingServicesVoucher(voucher)) {
      reportMessage('Voucher in housing services account is not suitable for housing services debit view', {
        voucherId: voucher.id,
        venueId,
      });
    }
  }, [voucher, venueId]);

  const switchToGenericDebitingTool = () => {
    handleAnalyticsEvent('serviceEventSubmission', 'housingServicesNavigateFromHousingServicesToGenericDebitView', voucher.serviceArea);
    setDebitingTool(DebitingTool.Generic);
  };

  const switchToEventSubmissionTool = () => {
    handleAnalyticsEvent('serviceEventSubmission', 'housingServicesNavigateFromGenericToHousingServicesDebitView', voucher.serviceArea);
    setDebitingTool(DebitingTool.EventSubmission);
  };

  const switchToGenericDebitingToolButton = (
    <SwitchDebitingToolButton
      label="SERVICE_VOUCHERS.HOUSING_SERVICE.DEBIT.SWITCH_TO_GENERIC_DEBIT_TOOL"
      tooltip="SERVICE_VOUCHERS.HOUSING_SERVICE.DEBIT.SWITCH_TO_GENERIC_DEBIT_TOOL_TOOLTIP"
      onClick={switchToGenericDebitingTool}
    />
  );

  switch (debitingTool) {
    case DebitingTool.EventSubmission:
      if (isHousingServicesVoucher(voucher)) {
        return (
          <ServiceEventSubmissionCardComponent
            venueId={venueId}
            voucher={voucher}
            transactions={transactions}
            cardHeaderButton={isSwitchBetweenHousingServiceDebitViewEnabled(configuration.globalFeatures) ? switchToGenericDebitingToolButton : undefined}
          />
        );
      } else {
        if (isSwitchBetweenHousingServiceDebitViewEnabled(configuration.globalFeatures)) {
          return (
            <CardComponent>
              <CardBodyComponent>
                <FormattedMessage id="SERVICE_EVENTS.HOUSING_SERVICE.VOUCHER_NOT_SUPPORTED" />
                {switchToGenericDebitingToolButton}
              </CardBodyComponent>
            </CardComponent>);
        } else {
          return null;
        }
      }
    case DebitingTool.Generic:
      return (
        <CouponsDetailsChargeFormContainer
          coupon={voucher}
          cardHeaderButton={isSwitchBetweenHousingServiceDebitViewEnabled(configuration.globalFeatures) ? (
            <SwitchDebitingToolButton
              label="SERVICE_VOUCHERS.HOUSING_SERVICE.DEBIT.SWITCH_TO_EVENT_SUBMISSION_TOOL"
              tooltip="SERVICE_VOUCHERS.HOUSING_SERVICE.DEBIT.SWITCH_TO_EVENT_SUBMISSION_TOOL_TOOLTIP"
              onClick={switchToEventSubmissionTool}
            />) : undefined
          }
          beforeSubmit={() => {
            if (isHousingServicesVoucher(voucher)) {
              handleAnalyticsEvent(
                'serviceEventSubmission',
                'housingServicesSubmitServiceEventFromGenericDebitView',
                `Voucher ID: ${voucher.id} - Venue: ${voucher.venue ?? 'unknown venue'}`,
              );
            }
          }}
        />
      );
    default:
      return null;
  }
};
