// @flow

import React, { Component } from 'react';

import { SidebarGroupComponent, SidebarItemContainer, SidebarTitleComponent } from 'app/core/sidebar/shared';
import { checkPermissions } from 'app/utils';

/*:: import type { Props } from './sidebar-additional.component.types';*/

export class SidebarAdditionalComponent extends Component /*:: <Props>*/ {
  render() {
    if (
      !checkPermissions(
        this.props.permissions,
        ['customerUsers', 'read'],
        ['settlements', 'read'],
        ['vatArchive', 'read'],
        ['contracts', 'update'],
      )
    ) {
      return null;
    }

    return (
      <>
        <SidebarTitleComponent>{this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.TITLE' })}</SidebarTitleComponent>
        <SidebarGroupComponent>
          {checkPermissions(this.props.permissions, ['settlements', 'read']) && (
            <SidebarItemContainer
              icon="settlements"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.SETTLEMENTS' })}
              to="/settlements"
            />
          )}

          {checkPermissions(this.props.permissions, ['vatArchive', 'read']) && (
            <SidebarItemContainer
              icon="commerce"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.VAT_ARCHIVE' })}
              to="/vat-archive"
              badge={this.props.newVatArchiveReport && <i className={`fi fi-time text-white m-0`} />}
              badgeRight
            />
          )}
          {checkPermissions(this.props.permissions, ['contracts', 'update']) && (
            <SidebarItemContainer
              icon="business"
              to="/pricelists"
              name={this.props.intl.formatMessage({ id: 'NAV.ADDITIONAL.CONTRACTS' })}
            />
          )}
        </SidebarGroupComponent>
      </>
    );
  }
}
