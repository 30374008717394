// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, SelectComponent } from 'app/shared';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Props, State } from './with-pagination.hoc.types';*/
import styles from './with-pagination.module.scss';

export const withPagination = (WrappedComponent /*: ComponentType<any>*/) =>
  class PaginatedComponent extends Component /*:: <Props, State>*/ {
    constructor(props /*: Props*/) {
      super(props);

      const searchParams = new URLSearchParams(props.location.search);

      this.state = {
        value: searchParams.get('page') || '1',
        size: searchParams.get('size') || '10',
      };
    }

    static getDerivedStateFromProps(nextProps /*: Props*/, prevState /*: State*/) {
      if (nextProps.location.search !== prevState.prevSearch) {
        const searchParams = new URLSearchParams(nextProps.location.search);
        const value = searchParams.get('page') || '1';
        const size = searchParams.get('size') || '10';

        return {
          value,
          size,
          prevSearch: nextProps.location.search,
        };
      }

      return null;
    }

    componentDidMount() {
      this.fetch();
    }

    componentDidUpdate(prevProps /*: Props*/, prevState /*: State*/) {
      if (prevProps.location.search !== this.props.location.search) {
        this.fetch();
      }
    }

    fetch = () => {
      const searchParams = new URLSearchParams(this.props.location.search);
      const page = searchParams.get('page') || '1';

      if (!!this.props.withLimit) {
        const size = searchParams.get('size') || '10';
        this.props.fetch({ page, size });
      } else {
        this.props.fetch({ page });
      }
    };

    onChange = (e /*: SyntheticInputEvent<HTMLInputElement>*/) => this.setState({ value: e.target.value });

    onSizeChange = ({ value } /*: { value: number }*/) =>
      this.setState({ size: String(value) }, () => this.props.history.push(this.locationCreator(1)));

    onKeyDown = (e /*: KeyboardEvent*/) => {
      if (e.keyCode === 13) {
        let value = Number(this.state.value);

        if (isNaN(value) || value < 1) {
          value = 1;
        } else if (value > this.props.meta.total) {
          value = this.props.meta.total;
        }

        this.props.history.push(this.locationCreator(value));
      }
    };

    locationCreator = (page /*: string | number*/) => {
      const searchParams = new URLSearchParams(this.props.location.search);

      searchParams.set('page', String(page));

      if (!!this.props.withLimit) {
        searchParams.set('size', String(this.state.size));
      }

      return {
        search: `?${searchParams.toString()}`,
      };
    };

    paginationRenderer = () => {
      if (!this.props.meta.total) {
        return null;
      }

      return (
        <div className={styles.pagination}>
          {!!this.props.withLimit && (
            <SelectComponent
              className={styles.limit}
              onChange={this.onSizeChange}
              options={this.props.withLimit}
              value={this.state.size}
              label={<FormattedMessage id="CORE.PAGINATION.ITEMS" />}
            />
          )}

          {this.props.meta.current > 1 && (
            <ButtonComponent className="ml-2" size="small" to={this.locationCreator(this.props.meta.current - 1)}>
              <FormattedMessage id="CORE.PAGINATION.PREV" />
            </ButtonComponent>
          )}

          <input
            value={this.state.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            className={styles.input}
          />

          <span className={styles.total}>
            <FormattedMessage id="CORE.PAGINATION.TOTAL" values={{ total: this.props.meta.total || 1 }} />
          </span>

          {this.props.meta.current < this.props.meta.total && (
            <ButtonComponent size="small" to={this.locationCreator(this.props.meta.current + 1)}>
              <FormattedMessage id="CORE.PAGINATION.NEXT" />
            </ButtonComponent>
          )}
        </div>
      );
    };

    render() {
      return <WrappedComponent {...this.props} pagination={this.props.loading || this.paginationRenderer()} />;
    }
  };
