// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import isEqual from 'lodash/isEqual';

import { PageHeaderComponent } from 'app/core';
import { AlertComponent } from 'app/shared';
import { isBusinessDifferent } from 'app/utils';

import { EditVenuePlaceholder } from './placeholder/edit-venue.placeholder';
import { VenueFormContainer } from './venue-form.container';

/*:: import type { Props } from './edit-venue.component.types';*/

export class EditVenueComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchVenue();
    this.props.onBusinessChange();
  }

  shouldComponentUpdate(nextProps /*: Props*/) {
    return (
      isBusinessDifferent(this.props, nextProps) ||
      !isEqual(this.props.location.state, nextProps.location.state) ||
      !isEqual(this.props.venue, nextProps.venue)
    );
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.changeVenue(this.props.business.id);
    }

    if (prevProps.location.pathname !== this.props.location.pathname && !this.props.venue) {
      this.props.fetchDictionary();
      this.props.fetchVenue();
    }
  }

  render() {
    if (this.props.loading) {
      return <EditVenuePlaceholder />;
    }

    return (
      <>
        <PageHeaderComponent
          title={{ id: 'VENUES.EDIT_VENUE', values: { name: this.props.venue.venue.name } }}
          backLink="/venues"
        />

        {!!this.props.location.state && !!this.props.location.state.success && (
          <AlertComponent type="success">
            {this.props.location.state.success.type === 'EDIT_VENUE' && (
              <FormattedMessage
                id="VENUES.EDIT_VENUE_SUCCESS"
                values={{ name: <strong>{this.props.location.state.success.payload}</strong> }}
              />
            )}
          </AlertComponent>
        )}

        <VenueFormContainer venue={this.props.venue} module={this.props.module} />
      </>
    );
  }
}
