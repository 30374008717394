// @flow

import React, { useEffect } from 'react';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { ConnectedDateRangeComponent, ConnectedDecimalInputComponent, ConnectedSelectComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';
import { CouponsCalculatorFinalContainer } from 'app/coupons/shared';
import { voucherChargeBounds } from 'app/utils/dates';
import { useIntl } from 'app/hooks';

/*:: import type { Props } from './mass-edit.component.types';*/
import styles from './mass-edit.module.scss';

export const MassEditComponent = (props /*: Props*/) => {
  const intl = useIntl();

  useEffect(
    () => {
      if (!props.vatRatesDictionary) {
        props.fetchDictionaries();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const vatRatesOptions = () =>
    props.vatRatesDictionary.map(vatRate => ({
      id: vatRate.rate.toString(),
      name: intl.formatMessage({ id: `COUPONS.CHARGE.VAT_RATES.${vatRate.name}` }),
    }));

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{intl.formatMessage({ id: 'COUPONS.RENEW.EDIT_ALL' })}</span>

      <Form
        mutators={arrayMutators}
        onSubmit={props.onSubmit}
        initialValues={{
          amountCalculations: [{ amount: 1 }],
        }}
        render={({ handleSubmit, form }) => (
          <form
            onSubmit={data => {
              handleSubmit(data);
              form.reset();
            }}
          >
            <div className={classNames('row', styles['custom-gutter'])}>
              <div className="col-12 col-xl-4">
                <ConnectedDateRangeComponent
                  field={Field}
                  name="serviceProduced"
                  startDateKey="startDate"
                  endDateKey="endDate"
                  label="COUPONS.MASS_CHARGE.LABEL.SERVICE_PRODUCED"
                  daysCounter
                  autofillEndDate
                  bounds={voucherChargeBounds}
                />
              </div>

              <div className={classNames('col-12 col-md-4 col-lg col-xl-2', styles.spacing)}>
                <ConnectedDecimalInputComponent
                  field={Field}
                  name="numberOfPriceUnits"
                  label="COUPONS.MASS_CHARGE.LABEL.NUMBER_OF_PRICE_UNITS"
                />
              </div>
              <div className={classNames('col-12 col-md-4 col-lg col-xl-2', styles.spacing)}>
                <CouponsCalculatorFinalContainer
                  value={{
                    name: 'amount',
                    label: intl.formatMessage({ id: 'COUPONS.MASS_CHARGE.LABEL.AMOUNT' }),
                  }}
                  form={form}
                  calculations="amountCalculations"
                />
              </div>
              <div className={classNames('col-12 col-md-4 col-lg col-xl-2', styles.spacing)}>
                <Field
                  name="vatRate"
                  component={ConnectedSelectComponent}
                  options={vatRatesOptions()}
                  label="COUPONS.CHARGE.VAT_RATES.LABEL"
                />
              </div>

              <div className={classNames('col-12 col-xl-1 d-flex align-items-center', styles.spacing)}>
                <ButtonComponent theme="primary" className={classNames(styles.submit)}>
                  <FormattedMessage id="CORE.SET" />
                </ButtonComponent>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};
