export const FETCH_CUSTOMER_REQUEST = 'customers/FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'customers/FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'customers/FETCH_CUSTOMER_FAILURE';

export const FETCH_CURRENT_CUSTOMER_REQUEST = 'customers/FETCH_CURRENT_CUSTOMER_REQUEST';
export const FETCH_CURRENT_CUSTOMER_SUCCESS = 'customers/FETCH_CURRENT_CUSTOMER_SUCCESS';
export const FETCH_CURRENT_CUSTOMER_FAILURE = 'customers/FETCH_CURRENT_CUSTOMER_FAILURE';

export const UPDATE_CURRENT_CUSTOMER_REQUEST = 'customers/UPDATE_CURRENT_CUSTOMER_REQUEST';
export const UPDATE_CURRENT_CUSTOMER_SUCCESS = 'customers/UPDATE_CURRENT_CUSTOMER_SUCCESS';
export const UPDATE_CURRENT_CUSTOMER_FAILURE = 'customers/UPDATE_CURRENT_CUSTOMER_FAILURE';
