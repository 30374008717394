// @flow

import React from 'react';

/*:: import type { Props } from './connected-toggle.component.types';*/
import { ToggleComponent } from 'app/shared';

export const ConnectedToggleComponent = (props /*: Props*/) => {
  const { input } = props;

  const onChange = (e /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    const isChecked = e.target.checked;
    input.onChange(isChecked);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return <ToggleComponent {...props} name={input.name} checked={input.value} onChange={onChange} />;
};
