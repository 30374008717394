//@flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { isBusinessDifferent } from 'app/utils';

/*:: import type { Props, State } from './filters-base.component.types';*/
/*:: import type { Node } from 'react';*/

import styles from './filters-base.module.scss';

export class FiltersBaseComponent extends Component /*:: <Props, State>*/ {
  static defaultProps = {
    resetOnBusinessChange: true,
  };

  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isAdvancedOpened: !!Object.values(props.initialValues || {}).find(item => !!item),
    };
  }

  componentDidMount() {
    this.props.submit();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props) && this.props.resetOnBusinessChange) {
      this.props.onClear();
    }
  }

  onFiltersToggle = () => this.setState((prev /*: State*/) => ({ isAdvancedOpened: !prev.isAdvancedOpened }));

  renderField = (field /*: Node*/) => {
    const { columnClassName } = this.props;

    return <div className={columnClassName}>{field}</div>;
  };

  render() {
    const { title = 'Filters', children } = this.props;

    const filtersClassNames = classNames(styles.advanced, {
      'd-block': this.state.isAdvancedOpened,
    });

    return (
      <CardComponent>
        <CardHeaderComponent title={title}>
          <ButtonComponent className={styles.clear} theme="secondary-outline" size="small" onClick={this.props.onClear}>
            <FormattedMessage id="CORE.CLEAR_FILTERS" />
          </ButtonComponent>
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <form onSubmit={this.props.handleSubmit}>
            <div className="row">{React.Children.map(this.props.baseFilters, this.renderField)}</div>

            <div className={filtersClassNames}>
              <div className="row">{React.Children.map(this.props.advancedFilters, this.renderField)}</div>
            </div>

            <button className={styles.toggler} type="button" onClick={this.onFiltersToggle}>
              <div className={styles.icon}>
                <i className={`fi fi-${this.state.isAdvancedOpened ? 'minus' : 'plus'}`} />
              </div>
              <FormattedMessage
                id={this.state.isAdvancedOpened ? 'CORE.HIDE_ADVANCED_SEARCH' : 'CORE.ADVANCED_SEARCH'}
              />
            </button>
          </form>
          {children}
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
