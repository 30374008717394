// @flow

import React, { Component } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

/*:: import type { Props } from './vat-archive-details-table.placeholder.types';*/

export class VatArchiveDetailsTablePlaceholder extends Component /*:: <Props>*/ {
  render() {
    const headers = [
      { content: 'VAT_ARCHIVE.DETAILS.HEADERS.BILL_ID' },
      { content: 'VAT_ARCHIVE.DETAILS.HEADERS.DATE' },
      this.props.type === 'TYPE_MUNICIPAL' && { content: 'VAT_ARCHIVE.DETAILS.HEADERS.SELLER_NAME' },
      { content: 'VAT_ARCHIVE.DETAILS.HEADERS.AMOUNT', numerical: true },
    ];

    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />

        <TableBodyComponent>
          {this.props.list.map((_, invoice) => (
            <TableRowComponent key={invoice}>
              <TableCellComponent>
                <PlaceholderComponent width={120} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={150} />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={80} />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={60} />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
