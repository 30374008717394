import { handleActions } from 'redux-actions';
import { configuration } from 'configuration';
import { PERSONAL_IDENTIFICATION_CODE_DISABLED_FLAG } from 'app/features';

const picDisabledSubAccountsDevelopment = [
 // Credit Sub Municipal (created with make setup-dev in vaana-core, see LoadMunicipalData.php under DevelopmentFixtures)
 9
];

const picDisabledSubAccountsStaging = [
  // Currently all staging accounts are enabled to use PIC
];

const picDisabledSubAccountsProduction = [
  //Janakkalan kunta / Sivistystoimi, Varhaiskasvatus - Kerhotoiminta
  159492,
  //Janakkalan kunta/ Sivistystoimi, varhaiskasvatus - Yksityinen päiväkotihoito
  180420,
  //Janakkalan kunta/ Sivistystoimi, varhaiskasvatus - Yksityinen perhepäivähoito
  180423,
  //Joensuu - Joensuun kaupunki / Varhaiskasvatus
  184214,
  //Porvoo - Porvoon kaupunki, Varhaiskasvatus
  184907,
  //Sipoo - Sipoon kunta / Varhaiskasvatus
  171828,
  //Laihia - Laihian kunta / Varhaiskasvatus
  185975,
  //Hämeenlinna - Hämeenlinnan kaupunki / Varhaiskasvatus, Kerhotoiminta
  111771,
  //Hämeenlinnan kaupunki / Varhaiskasvatus, Päiväkotihoito
  136781,
  //Tornio - Tornion kaupunki / varhaiskasvatus
  179823,
  //Mikkeli - Mikkelin kaupunki/ Sivistystoimi, varhaiskasvatus
  161484,
  //Laitila - Laitila/ Varhaiskasvatus
  185546,
  //Pukkila- Pukkilan kunta / Varhaiskasvatus, Yksityisen perhepäivähoito
  186239,
  //Pukkilan kunta / Varhaiskasvatus, Yksityisen päiväkotihoito
  186284,
  //Rovaniemi - Yrityspalvelusetelitili
  188489,
  //Huittinen - Huittisten kaupunki/ varhaiskasvatus
  170493,
];

const hardcodedPICDisabledSubAccounts = {
  development: picDisabledSubAccountsDevelopment,
  staging: picDisabledSubAccountsStaging,
  production: picDisabledSubAccountsProduction,
};

function setFeatureFlagForSubAccountsWithoutPIC(subAccountIds /*: Array*/) {
  return subAccountIds.reduce((current, account) => {
    return {
      ...current,
      [account]: [PERSONAL_IDENTIFICATION_CODE_DISABLED_FLAG],
    };
  }, {});
}

export function getHardcodedPICFeatureStateForEnvironment(environment) {
  return hardcodedPICDisabledSubAccounts[environment]
    ? setFeatureFlagForSubAccountsWithoutPIC(hardcodedPICDisabledSubAccounts[environment])
    : {};
}

// TODO: (When enabling PIC feature in a central place) Define actions that are triggered when features are requested and response is received.
export const featuresReducer = handleActions({}, getHardcodedPICFeatureStateForEnvironment(configuration.environment));
