// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, ConnectedDateRangeComponent, ConnectedMoneyInputComponent } from 'app/shared';

import { CouponsCalculatorFinalContainer } from 'app/coupons/shared';
import { voucherValidityBounds } from 'app/utils/dates';

/*:: import type { Props, RenewHeaderFormValues } from './renew-header.component.types';*/
import styles from './renew-header.module.scss';

export class RenewHeaderComponent extends PureComponent /*:: <Props>*/ {
  onSet = (values /*: RenewHeaderFormValues*/) => {
    const newCoupons = this.props.coupons.map(coupon => {
      if (!coupon.active) {
        return coupon;
      }
      return {
        ...coupon,
        maxTransaction: values.maxTransaction ? values.maxTransaction : coupon.maxTransaction,
        defaultValue: values.defaultValue ? values.defaultValue : coupon.defaultValue,
        valid: {
          ...coupon.valid,
          from: values.valid.from ? values.valid.from : coupon.valid.from,
          to: values.valid.to ? values.valid.to : coupon.valid.to,
        },
      };
    });
    this.props.change('coupons', newCoupons);
  };

  render() {
    return (
      <div className={classNames(styles.wrapper)}>
        <span className={styles.title}>{this.props.intl.formatMessage({ id: 'COUPONS.RENEW.EDIT_ALL' })}</span>

        <Form
          mutators={arrayMutators}
          onSubmit={this.onSet}
          initialValues={{
            valid: {},
            defaultValueCalculations: [{ amount: 1 }],
          }}
          render={({ handleSubmit, form, invalid }) => (
            <form
              onSubmit={data => {
                handleSubmit(data);
                form.reset();
              }}
            >
              <div className={styles.columns}>
                <div className={styles.column}>
                  <ConnectedDateRangeComponent
                    field={Field}
                    name="valid"
                    startDateKey="from"
                    endDateKey="to"
                    label="COUPONS.RENEW.LABEL.VALID"
                    daysCounter
                    bounds={voucherValidityBounds}
                  />
                </div>
                <div className={styles.column}>
                  <ConnectedMoneyInputComponent
                    field={Field}
                    name="maxTransaction"
                    label="COUPONS.RENEW.LABEL.MAX_TRANSACTION"
                  />
                </div>
                <div className={styles.column}>
                  <CouponsCalculatorFinalContainer
                    value={{
                      name: 'defaultValue',
                      label: this.props.intl.formatMessage({ id: 'COUPONS.RENEW.LABEL.VALUE_AMOUNT' }),
                      theme: 'table',
                    }}
                    form={form}
                    calculations="defaultValueCalculations"
                  />
                </div>
                <div className={styles.column}>
                  <ButtonComponent
                    disabled={invalid}
                    className={classNames(styles.submit)}
                    loading={this.props.submitting}
                    theme="secondary"
                    type="submit"
                  >
                    <FormattedMessage id="CORE.SET" />
                  </ButtonComponent>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
