// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { TableCellComponent, TableRowComponent } from 'app/shared/table-new';

/*:: import type { Props } from './table-head.component.types';*/
import styles from './table-head.module.scss';

export class TableHeadComponent extends Component /*:: <Props>*/ {
  render() {
    const { children, headers } = this.props;

    if (headers) {
      return (
        <thead className={styles.thead}>
          <TableRowComponent>
            {headers.map((header, index) => (
              <TableCellComponent
                key={index}
                align={header.numerical && 'right'}
                className={classNames(styles.cell, header.className)}
                variant="header"
                content={header.content}
                colSpan={header.colSpan}
              />
            ))}
          </TableRowComponent>
        </thead>
      );
    }

    if (children) {
      return <thead className={styles.thead}>{children}</thead>;
    }

    throw new Error('Missing component elements - provide headers or children');
  }
}
