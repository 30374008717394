//@flow

import moment from 'moment/moment';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type { Config, Value } from './range-date-picker.types';*/

const START_DATE_IS_BEFORE_MIN_DATE = 'VALIDATION.START_DATE_IS_BEFORE_MIN_DATE';
const START_DATE_IS_AFTER_MAX_DATE = 'VALIDATION.START_DATE_IS_AFTER_MAX_DATE';
const END_DATE_IS_BEFORE_MIN_DATE = 'VALIDATION.END_DATE_IS_BEFORE_MIN_DATE';
const END_DATE_IS_AFTER_MAX_DATE = 'VALIDATION.END_DATE_IS_AFTER_MAX_DATE';

export const dateRangeValidator = (config /*: Config*/) => (value /*: Value*/) => {
  const { startDateKey, endDateKey, if: condition } = config;

  const runValidation = config.hasOwnProperty('if')
    ? condition && typeof condition === 'function' && condition()
    : true;

  if (runValidation) {
    if (!value) return undefined;

    // $FlowFixMe
    const { minDate, maxDate } = value.validation || {};

    const startDate = value[startDateKey] ? moment(value[startDateKey], DATE_VALUE_FORMAT) : null;
    const endDate = value[endDateKey] ? moment(value[endDateKey], DATE_VALUE_FORMAT) : null;

    if (startDate) {
      if (minDate && startDate.isBefore(minDate)) {
        return { id: START_DATE_IS_BEFORE_MIN_DATE, values: { minDate: dateFormatter(minDate) } };
      }

      if (endDate && startDate.isAfter(endDate)) {
        return START_DATE_IS_AFTER_MAX_DATE;
      }
    }

    if (endDate) {
      if (startDate && endDate.isBefore(startDate)) {
        return END_DATE_IS_BEFORE_MIN_DATE;
      }

      if (maxDate && endDate.isAfter(maxDate)) {
        return { id: END_DATE_IS_AFTER_MAX_DATE, values: { maxDate: dateFormatter(maxDate) } };
      }
    }
  }

  return undefined;
};
