// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import once from 'lodash/once';
import moment from 'moment';
import { stringify } from 'qs';

import { withAuthorization, withConfirmation } from 'app/common';
import { DATE_VALUE_FORMAT, ROUTES } from 'app/constants';

import { fetchCurrentCustomer } from 'app/redux/customers/customers.actions';
import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { lockBusinessChange, unlockBusinessChange } from 'app/redux/view/view.actions';
import { createVoucherAndRefreshMunicipal, validateCoupon } from 'app/redux/coupons/coupons.actions';

import { fetchCouponType } from 'app/redux/types/types.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { CouponsCreateComponent } from './coupons-create.component';

const mapStateToProps = (
  {
    customers: {
      entities: { current },
    },
    dictionaries,
    types: { entities: types },
    users: { business },
  },
  {
    match: {
      params: { type },
    },
  },
) => ({
  business,
  initialValues: !types[type]
    ? undefined
    : {
        ...types[type],
        couponTypeId: types[type].id,
        customerDecisions: [{}],
        defaultValueCalculations: [
          {
            amount: 1,
          },
        ],
        valid: {
          from: moment().format(DATE_VALUE_FORMAT),
        },
      },
  loading: !dictionaries['contracted-venues'] || !types[type] || !current,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { type },
    },
  },
) => ({
  fetchCouponType: () => dispatch(fetchCouponType(type)),
  fetchCurrentCustomer: () => dispatch(fetchCurrentCustomer()),
  fetchDictionary: () =>
    Promise.all([
      dispatch(fetchDictionary('municipal/services', true)),
      dispatch(
        fetchDictionary(
          `contracted-venues${stringify({ couponType: type }, { skipNulls: true, addQueryPrefix: true })}`,
          undefined,
          true,
        ),
      ),
    ]),
  onBusinessChange: () => dispatch(push(ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT)),
  onChange: once(() => dispatch(lockBusinessChange())),
  onSubmit: body => {
    const successCallback = ({
      payload: {
        data: { beneficiaries },
      },
    }) =>
      dispatch(
        push({
          pathname: `/coupons/share/${beneficiaries.map(beneficiary => beneficiary.accountNumber).join(';')}`,
        }),
      );

    return withFormSubmissionErrorHandling(dispatch(createVoucherAndRefreshMunicipal(body)), successCallback);
  },
  onSubmitFail: () => window.scrollTo(0, 0),
  onUnmount: () => dispatch(unlockBusinessChange()),
  onValidate: body => dispatch(validateCoupon(body)),
});

export const CouponsCreateContainer = compose(
  withAuthorization(['serviceCoupons', 'create']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'coupon-create' }),
  withConfirmation,
)(CouponsCreateComponent);
