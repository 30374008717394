import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';

import { withAuthorization, withPermissions } from 'app/common';

import { fetchTransaction, refundTransaction } from 'app/redux/transactions/transactions.actions';

import { TransactionDetailsModalComponent } from './transaction-details-modal.component';

const mapStateToProps = (
  { transactions: { entities: transactions } },
  {
    match: {
      params: { transaction },
    },
  },
) => ({
  loading: !transactions[transaction],
  transaction: transactions[transaction],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { account, transaction },
    },
    number,
    pathname,
  },
) => ({
  fetchTransaction: transaction => dispatch(fetchTransaction(number || account, transaction)),
  onClose: () =>
    dispatch(
      push({
        pathname,
        state: {
          keepScrollPosition: true,
        },
      }),
    ),
  onRefund: () =>
    dispatch(refundTransaction(number || account, transaction)).then(action => {
      dispatch(
        push({
          state: {
            ...(action.error ? { error: { type: 'REFUND_ERROR' } } : { success: { type: 'TRANSACTION_REFUNDED' } }),
          },
        }),
      );
    }),
});

export const TransactionDetailsModalContainer = compose(
  withPermissions,
  withAuthorization(['transactionDetails', 'read']),
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(TransactionDetailsModalComponent);
