//@flow

import React from 'react';

/*:: import type { Props } from './flag.component.types';*/
import styles from './flag.module.scss';

export const Flag = ({ flag, alt = 'flag' } /*: Props*/) => {
  return <img src={flag} alt={alt} className={styles.flag} />;
};
