// @flow

import React, { Component } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

/*:: import type { Props } from './account-transactions-table.placeholder.types';*/

const headers = [
  { content: 'TRANSACTIONS.DATE' },
  { content: 'TRANSACTIONS.PAYER_RECEIVER' },
  { content: 'TRANSACTIONS.AMOUNT', numerical: true },
  {},
];

export class AccountTransactionsTablePlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />

        <TableBodyComponent>
          {this.props.list.map((_, transaction) => (
            <TableRowComponent key={transaction}>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 260 : 150} />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={100} color="purple" />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={80} />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
