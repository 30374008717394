import { RSAA } from 'redux-api-middleware';
import { v1AuthenticationHeaders } from 'v2/api/auth';

export const authMiddleware = store => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  const { isPublic, ...rest } = apiMiddleware;

  if (isPublic) {
    return next({
      [RSAA]: rest,
    });
  }

  const { accessToken, business = {} } = store.getState().users;
  const { id, parentId } = business;

  return next({
    [RSAA]: {
      ...rest,
      headers: {
        ...apiMiddleware.headers,
        ...v1AuthenticationHeaders(accessToken, id, parentId),
      },
    },
  });
};
