// @flow
import React from 'react';

import { PlaceholderComponent, SeparatorComponent, Typography } from 'app/shared';
import { PageHeaderComponent } from 'app/core';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './venue-contracts.placeholder.types';*/

export const VenueContractsPlaceholder = (props /*: Props*/) => (
  <>
    <PageHeaderComponent backLink={props.prevLocation}>
      <PlaceholderComponent width={200} height={31} color="purple" />
    </PageHeaderComponent>
    <CardComponent>
      <CardHeaderComponent>
        <PlaceholderComponent width={250} />
        <PlaceholderComponent width={150} />
      </CardHeaderComponent>
      <SeparatorComponent />
      <CardBodyComponent>
        <Typography type="subtitle" color="greyDark" id="PRICELISTS.DETAILS.SERVICES" />
        <Typography type="bodyText" color="grey">
          <PlaceholderComponent width={250} />
        </Typography>
        <SeparatorComponent />
        <Typography color="greyDark" id="PRICELIST.PRICES_HEADER" type="subtitle" />
        <SeparatorComponent />
        <PlaceholderComponent height={250} color="light" />
      </CardBodyComponent>
    </CardComponent>
  </>
);
