import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';

import get from 'lodash/get';

import { withAuthorization } from 'app/common';
import { singleConcurrentRequestAction } from 'app/middlewares';

import { download } from 'app/redux/downloads/downloads.actions';
import { fetchVatArchive, fetchVatArchives, fetchVatArchivesMonths } from 'app/redux/archive/archive.actions';

import { VatArchiveDetailsComponent } from './vat-archive-details.component';

const mapStateToProps = (
  {
    archive: { entities: vatArchive, months: years, list: archiveList },
    users: {
      business,
      current: { businesses },
    },
  },
  {
    match: {
      params: { month, year, accountId },
    },
  },
) => {
  const selectedArchive = !!archiveList ? archiveList.find(archive => archive.id === Number(accountId)) : undefined;

  return {
    archive: get(vatArchive, [`${month}/${year}`, 'list']),
    business,
    businesses,
    loading: !vatArchive[`${month}/${year}`] || !selectedArchive,
    meta: get(vatArchive, [`${month}/${year}`, 'meta']),
    month,
    year,
    years,
    selectedArchive,
    type: business.type,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    intl: { locale },
    match: {
      params: { accountId, year, month },
    },
  },
) => ({
  fetchVatArchive: (year, month) =>
    dispatch(singleConcurrentRequestAction('vat-archive-details', fetchVatArchive(year, month, accountId))),
  fetchVatArchives: () => dispatch(fetchVatArchives(year, month)),
  fetchVatArchivesMonths: () => dispatch(fetchVatArchivesMonths()),
  changePeriod: (month, year) => dispatch(push(`/vat-archive/${accountId}/${year}/${month}`)),
  onBillDownload: id => dispatch(download({ path: `vat-archive/${accountId}/${year}/${month}/${id}/pdf`, locale })),
  onDownload: path =>
    dispatch(
      download({
        path: `vat-archive/${year}/${month}${path ? path : ''}`,
        locale,
        filters: 'filter-vat-archive',
        params: { accounts: [Number(accountId)] },
      }),
    ),
  resetFilters: () => dispatch(reset('filter-vat-archive')),
  onBusinessChange: () => dispatch(push('/vat-archive')),
});

export const VatArchiveDetailsContainer = compose(
  withAuthorization(['vatArchive', 'read']),
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(VatArchiveDetailsComponent);
