// @flow

import React from 'react';

import { ConnectControl, DecimalInputComponent } from 'app/shared';
import { decimalValidator } from 'app/utils';
import { FieldWrapper } from '../utils/field-wrapper/field-wrapper.component';

/*:: import type { Props, WrapperProps } from './connected-decimal-input.component.types';*/

const WrappedDecimalInputComponent /*: Function*/ = (props /*: WrapperProps*/) => {
  const onChange = (event /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    const value = event.target.value;

    props.input.onChange(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  return <ConnectControl {...props} component={DecimalInputComponent} inputRef={props.inputRef} onChange={onChange} />;
};

export const ConnectedDecimalInputComponent = (props /*: Props*/) => {
  const { decimalValidatorConfig, validate = [], ...otherProps } = props;

  return (
    <FieldWrapper
      {...otherProps}
      component={WrappedDecimalInputComponent}
      validate={[decimalValidator(decimalValidatorConfig), ...validate]}
    />
  );
};
