//@flow

import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

export const getDatesString = (
  startDate /*: ?string*/,
  endDate /*: ?string*/,
  placeholder /*::?: string*/,
) /*: ?string*/ => {
  if (startDate && endDate && startDate !== endDate) {
    return `${dateFormatter(startDate)} - ${dateFormatter(endDate)}`;
  } else if (startDate) {
    return dateFormatter(startDate);
  }
  return placeholder ? placeholder : null;
};
