import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostpayServiceVoucher } from 'v2/types/postpay';

export enum ServiceVoucherPdfStatus {
  Ok = 'ok',
  Loading = 'loading',
}

export type PrintServiceVouchersState = {
  [voucherId: string]:
    | {
        voucherPdfStatus?: ServiceVoucherPdfStatus;
        voucherApprovedServiceProvidersPdfStatus?: ServiceVoucherPdfStatus;
      }
    | undefined;
};

export type PdfRequestedPayload = { voucher: PostpayServiceVoucher };
export type PdfRequestCompletedPayload = { voucherId: string };

export interface StateSlice {
  'print-service-vouchers': PrintServiceVouchersState;
}

const initialState: PrintServiceVouchersState = {};
const slice = createSlice({
  name: 'print-service-vouchers',
  initialState,
  reducers: {
    printVoucherPdfRequested: (state, action: PayloadAction<PdfRequestedPayload>) => {
      state[action.payload.voucher.id] = {
        ...state[action.payload.voucher.id],
        voucherPdfStatus: ServiceVoucherPdfStatus.Loading,
      };
    },
    printVoucherPdfRequestCompleted: (state, action: PayloadAction<PdfRequestCompletedPayload>) => {
      state[action.payload.voucherId] = {
        ...state[action.payload.voucherId],
        voucherPdfStatus: ServiceVoucherPdfStatus.Ok,
      };
    },
    printVoucherApprovedServiceProvidersPdfRequested: (state, action: PayloadAction<PdfRequestedPayload>) => {
      state[action.payload.voucher.id] = {
        ...state[action.payload.voucher.id],
        voucherApprovedServiceProvidersPdfStatus: ServiceVoucherPdfStatus.Loading,
      };
    },
    printVoucherApprovedServiceProvidersPdfRequestCompleted: (
      state,
      action: PayloadAction<PdfRequestCompletedPayload>,
    ) => {
      state[action.payload.voucherId] = {
        ...state[action.payload.voucherId],
        voucherApprovedServiceProvidersPdfStatus: ServiceVoucherPdfStatus.Ok,
      };
    },
  },
});

export const actions = slice.actions;
export const reducer = {
  [slice.name]: slice.reducer,
};
export const name = slice.name;
