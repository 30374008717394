//@flow

import React from 'react';
import moment from 'moment';

import { SelectComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';

/*:: import type { Option, Props } from './datepicker-header.types.js.flow';*/
import styles from './datepicker-header.module.scss';

export const DatePickerHeader = (props /*: Props*/) => {
  const handleMonthChange = (month /*: Option*/) => props.changeMonth(month.value);

  const handleYearChange = (year /*: Option*/) => props.changeYear(year.value);

  const handleDecreaseMonth = () => {
    props.decreaseMonth();

    const newDate = props.date.subtract(1, 'months');
    handleMonthChange({ value: newDate.month() });
    handleYearChange({ value: newDate.year() });
  };

  const handleIncreaseMonth = () => {
    props.increaseMonth();

    const newDate = props.date.add(1, 'months');
    handleMonthChange({ value: newDate.month() });
    handleYearChange({ value: newDate.year() });
  };

  const months = moment.months().map((label, value) => ({ value, label }));
  const years = props.years.map(year => ({ value: year, label: year.toString() }));

  return (
    <div className={styles.customHeader}>
      <ButtonComponent
        theme="white"
        className={styles.navigationButton}
        onClick={handleDecreaseMonth}
        disabled={props.prevMonthButtonDisabled}
      >
        <span className="fi fi-go-back-left-arrow" />
      </ButtonComponent>

      <SelectComponent
        className={styles.monthSelect}
        value={props.date.month()}
        onChange={handleMonthChange}
        labelKey="label"
        valueKey="value"
        options={months}
      />

      <SelectComponent
        className={styles.yearSelect}
        value={props.date.year()}
        onChange={handleYearChange}
        options={years}
        labelKey="label"
        valueKey="value"
      />

      <ButtonComponent
        theme="white"
        className={styles.navigationButton}
        onClick={handleIncreaseMonth}
        disabled={props.nextMonthButtonDisabled}
      >
        <span className="fi fi-right-arrow-forward" />
      </ButtonComponent>
    </div>
  );
};
