import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withCoreContext } from 'app/context/core/core.context';

import { ServicesRowComponent } from './services-row.component';

const mapStateToProps = (state, { field }) => {
  const { dictionaries } = state;

  return {
    dictionary: dictionaries['municipal/services'],
    serviceProduced: formValueSelector('charge-service-coupon')(state, field).serviceProduced,
  };
};

export const ServicesRowContainer = compose(
  withCoreContext,
  injectIntl,
  connect(mapStateToProps, null),
)(ServicesRowComponent);
