import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { submit } from 'redux-form';

import { CouponsListFiltersComponent } from './coupons-list-filters.component';

const mapStateToProps = ({ users: { business } }) => ({ business });

const mapDispatchToProps = (dispatch, { fetch }) => ({
  fetch: params => fetch(params),
  submit: name => dispatch(submit(name)),
});

export const CouponsListFiltersContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsListFiltersComponent);
