import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import { loginUser } from 'app/redux/users/users.actions';

import { LoginComponent } from './login.component';

export const mapStateToProps = (_, { location }) => {
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('user');

  return {
    initialValues: {
      username,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: body => dispatch(loginUser(body)),
});

export const LoginContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'login',
  }),
)(LoginComponent);
