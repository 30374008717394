import { compose } from 'redux';
import { connect } from 'react-redux';

import { checkBalance } from 'app/redux/coupons/coupons.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { withAppContext } from 'app/app.context';

import { CouponsCheckBalanceComponent } from './coupons-check-balance.component';

const mapStateToProps = ({ coupons: { checked } }, { location }) => {
  const params = new URLSearchParams(location.search);

  return {
    checked,
    queryLocale: params.get('lang'),
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: ({ code, decisionNumber }) => withFormSubmissionErrorHandling(dispatch(checkBalance(code, decisionNumber))),
});

export const CouponsCheckBalanceContainer = compose(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsCheckBalanceComponent);
