// @flow
import React from 'react';

/*:: import type { Props } from './external-link.component.types.js.flow';*/

export const ExternalLink = ({ href, children, ...otherProps } /*: Props*/) => (
  <a href={href} {...otherProps} rel="noopener noreferrer" target="_blank">
    {children}
  </a>
);
