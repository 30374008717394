// @flow

import React from 'react';

import { CheckboxComponent, ConnectControl } from 'app/shared';

/*:: import type { Props } from './connected-checkbox.component.types';*/

export const ConnectedCheckboxComponent = (props /*: Props*/) => {
  const { input } = props;

  const onChange = (e /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    const isChecked = e.target.checked;

    props.input.onChange(isChecked);

    if (props.onChange) {
      props.onChange(isChecked);
    }
  };

  return <ConnectControl component={CheckboxComponent} {...props} checked={input.value} onChange={onChange} />;
};
