import { PayloadAction } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { GetVoucherTypeResponse, PostpayApiClient } from 'v2/api/postpay-api-client';
import { actions, VoucherTypeRequestedPayload } from './voucher-type-slice';

export function* voucherTypeSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function* voucherTypeRequested(action: PayloadAction<VoucherTypeRequestedPayload>) {
    const { voucherTypeId } = action.payload;
    const response: GetVoucherTypeResponse = yield call(apiClient.getVoucherType, voucherTypeId);
    switch (response.status) {
      case StatusCodes.OK:
        yield put(actions.voucherTypeRequestCompleted(response.data));
        break;
      case StatusCodes.NOT_FOUND:
        // TODO: handle this correctly?
        break;
    }
  }

  yield takeEvery(actions.voucherTypeRequested.match, voucherTypeRequested);
}
