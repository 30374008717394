const configurations = {
  'oma.vaana.fi': {
    dsn: 'https://b3e4d12e712b4ff48321bd5cf3bfa02a@sentry.vaana.fi/2',
    environment: 'production',
    gaTrackingId: 'UA-120507243-1',
    apiEndpoint: 'https://api.oma.vaana.fi/api',
    apiV2Endpoint: 'https://api.oma.vaana.fi/api/v2',
    venueSearchHost: 'https://www.palveluseteli.fi',
    globalFeatures: [],
    replacedAccountIds: [173253],
  },
  'oma.development.vaana.fi': {
    dsn: 'https://c0c4f12c4a6d47f686588712d785a610@sentry.staging.vaana.fi/2',
    environment: 'development',
    gaTrackingId: 'UA-120507243-2',
    apiEndpoint: 'https://api.oma.development.vaana.fi/api',
    apiV2Endpoint: 'https://api.oma.development.vaana.fi/api/v2',
    venueSearchHost: 'http://palvelutili.test',
    globalFeatures: [],
    replacedAccountIds: [],
  },
  'oma.staging.vaana.fi': {
    dsn: 'https://c0c4f12c4a6d47f686588712d785a610@sentry.staging.vaana.fi/2',
    environment: 'st.eaging',
    gaTrackingId: 'UA-120507243-2',
    apiEndpoint: 'https://api.oma.staging.vaana.fi/api',
    apiV2Endpoint: 'https://api.oma.staging.vaana.fi/api/v2',
    venueSearchHost: 'http://palvelutili.test',
    globalFeatures: [],
    replacedAccountIds: [174453],
  },
};

const findConfigOrEnvVariables = (envConfig = {}) => ({
  dsn: envConfig.dsn || process.env.REACT_APP_SENTRY_DSN,
  environment: envConfig.environment || process.env.ENV || process.env.NODE_ENV,
  release: envConfig.release || process.env.REACT_APP_RELEASE_VERSION || process.env.REACT_APP_RELEASE_HASH,
  gaTrackingId: envConfig.gaTrackingId || process.env.REACT_APP_GA,
  enableApiDebugMiddleware: envConfig.enableApiDebugMiddleware || process.env.REACT_APP_API_DEBUG_MIDDLEWARE || false,
  apiEndpoint: envConfig.apiEndpoint || process.env.REACT_APP_API_URL,
  apiV2Endpoint: envConfig.apiV2Endpoint || process.env.REACT_APP_API_V2_URL,
  venueSearchHost: envConfig.venueSearchHost || process.env.REACT_APP_VENUE_SEARCH_HOST,
  globalFeatures: envConfig.globalFeatures || [],
  replacedAccountIds: envConfig.replacedAccountIds || (process.env.REACT_APP_REPLACED_ACCOUNT_IDS ? JSON.parse(process.env.REACT_APP_REPLACED_ACCOUNT_IDS) : []),
});

export const generateConfig = hostname => {
  return findConfigOrEnvVariables(configurations[hostname]);
};
