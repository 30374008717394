import { RSAA } from 'redux-api-middleware';
import { getFormValues } from 'redux-form';

import * as types from './archive.action-types';

export const fetchVatArchives = (year, month) => ({
  [RSAA]: {
    types: [types.FETCH_VAT_ARCHIVES_REQUEST, types.FETCH_VAT_ARCHIVES_SUCCESS, types.FETCH_VAT_ARCHIVES_FAILURE],
    endpoint: `/vat-archive/${year}/${month}`,
    method: 'GET',
  },
});

export const fetchVatArchivesMonths = () => ({
  [RSAA]: {
    types: [
      types.FETCH_VAT_ARCHIVES_MONTHS_REQUEST,
      types.FETCH_VAT_ARCHIVES_MONTHS_SUCCESS,
      types.FETCH_VAT_ARCHIVES_MONTHS_FAILURE,
    ],
    endpoint: '/vat-archive/months',
    method: 'GET',
  },
});

export const fetchVatArchive = (year, month, accountId) => (dispatch, getState) => {
  const filters = getFormValues('filter-vat-archive')(getState());

  return dispatch({
    [RSAA]: {
      types: [
        {
          type: types.FETCH_VAT_ARCHIVE_REQUEST,
          meta: {
            month,
            year,
            params: {
              size: 10,
              ...filters,
            },
          },
        },
        {
          type: types.FETCH_VAT_ARCHIVE_SUCCESS,
          meta: {
            month,
            year,
          },
        },
        types.FETCH_VAT_ARCHIVE_FAILURE,
      ],
      endpoint: `/vat-archive/${accountId}/${year}/${month}`,
      method: 'GET',
    },
  });
};

export const fetchVatArchiveReportStatus = () => ({
  [RSAA]: {
    types: [
      types.FETCH_VAT_ARCHIVES_REPORT_STATUS_REQUEST,
      types.FETCH_VAT_ARCHIVES_REPORT_STATUS_SUCCESS,
      types.FETCH_VAT_ARCHIVES_REPORT_STATUS_FAILURE,
    ],
    endpoint: '/vat-archive/report-status',
    method: 'GET',
  },
});
