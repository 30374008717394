// @flow

import moment from 'moment';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import get from 'lodash/get';

import { checkPermissions, isBusinessDifferent } from 'app/utils';
import { REDIRECTS, SM_BREAKPOINT } from 'app/constants';

import { AccountsComponent } from 'app/accounts/accounts.component';
import { AgreementsComponent } from 'app/agreements/agreements.component';
import { CouponsComponent } from 'app/coupons/coupons.component';
import { CustomerInformationContainer } from 'app/customer-information/customer-information.container';
import { OrdersComponent } from 'app/orders/orders.component';
import { PricelistsComponent } from 'app/pricelists/pricelists.component';
import { ProfileComponent } from 'app/profile/profile.component';
import { SettlementsComponent } from 'app/settlements/settlements.component';
import { TransactionsComponent } from 'app/transactions/transactions.component';
import { UsersContainer } from 'app/users/users.container';
import { VatArchiveComponent } from 'app/vat-archive/vat-archive.component';
import { VenuesComponent } from 'app/venues/venues.component';
import { Notifications } from 'app/notifications/notifications.component';
import { ServiceVouchersRouterComponent } from '../../v2/service-voucher/postpay/service-vouchers.router.component';
import { ConfirmContainer } from './confirm/confirm.container';

import { SplashComponent, ThemeComponent } from 'app/core';
import { BusinessContextProvider, CoreContext } from 'app/context';
import { getDefaultPostpayUrl } from 'v2/utils/redirects';
import { AvailableUsecasesStatus } from 'v2/organizational-unit/available-usecases-slice';

/*:: import type { Props, State } from './core.component.types';*/

export class CoreComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      holidays: [],
      sidebarCollapsed: localStorage.getItem('sidebarCollapsed') === 'true',
      toggleSidebar: this.toggleSidebar,
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.fetchPermissions().then(() => {
        if (checkPermissions(this.props.permissions, ['municipalAccount', 'read'])) this.props.fetchAccounts();
      });

      this.props.fetchHolidays().then(response => {
        const data = get(response, ['payload', 'data'], []);

        if (data.length) {
          this.setState({
            holidays: (data || []).map(data => moment(data)),
          });
        }
      });
    }

    if (!this.state.sidebarCollapsed) {
      if (document.body) {
        document.body.classList.add('sidebar-open');
      }
    }
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      if (checkPermissions(this.props.permissions, ['municipalAccount', 'read'])) this.props.fetchAccounts();
    }

    if (window.innerWidth <= SM_BREAKPOINT) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.setState({
          sidebarCollapsed: true,
        });

        if (document.body) {
          document.body.classList.remove('sidebar-open');
        }
      }
    }
  }

  toggleSidebar = () =>
    this.setState(
      state => ({
        sidebarCollapsed: !state.sidebarCollapsed,
      }),
      () => {
        if (document.body) {
          document.body.classList.toggle('sidebar-open');
        }

        try {
          localStorage.setItem('sidebarCollapsed', this.state.sidebarCollapsed.toString());
        } catch (err) {}
      },
    );

  render() {
    if (!this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    const currentBusinessUsecasesState = this.props.useCases[this.props.business.id];
    const isLoadingPostpayUsecases =
      !currentBusinessUsecasesState ||
      !currentBusinessUsecasesState.status ||
      currentBusinessUsecasesState.status === AvailableUsecasesStatus.Loading;

    if (this.props.loading || isLoadingPostpayUsecases) {
      return <SplashComponent />;
    }

    // Redirect deprecated contracts route to new pricelists implementation
    // This accommodates deep links to /contracts that have been stored on the client-side
    // This also intercepts sub-routes under /contracts such as /contracts/123
    const redirectDeprecatedContractsRoute = <Redirect from="/contracts" to="/pricelists" />;

    const rootRedirectUrl =
      currentBusinessUsecasesState.status === AvailableUsecasesStatus.Found
        ? getDefaultPostpayUrl(this.props.useCases[this.props.business.id].availableUsecases) ?? '/profile'
        : REDIRECTS[this.props.business.defaultRole] ?? '/profile';

    return (
      <CoreContext.Provider value={this.state}>
        <BusinessContextProvider>
          <ThemeComponent termsTo="/terms">
            <Switch>
              <Route path="/accounts" component={AccountsComponent} />
              {redirectDeprecatedContractsRoute}
              <Route path="/coupons" component={CouponsComponent} />
              <Route path="/customer-information" component={CustomerInformationContainer} />
              <Route path="/orders" component={OrdersComponent} />
              <Route path="/pricelists" component={PricelistsComponent} />
              <Route path="/profile" component={ProfileComponent} />
              <Route path="/service-vouchers" component={ServiceVouchersRouterComponent} />
              <Route path="/settlements" component={SettlementsComponent} />
              <Route path="/terms" component={AgreementsComponent} />
              <Route path="/transactions" component={TransactionsComponent} />
              <Route path="/users" component={UsersContainer} />
              <Route path="/vat-archive" component={VatArchiveComponent} />
              <Route path="/venues" component={VenuesComponent} />
              <Redirect exact from="/" to={rootRedirectUrl} />
              <Redirect to="/not-found" />
            </Switch>
          </ThemeComponent>
          <ConfirmContainer />
          <Notifications />
        </BusinessContextProvider>
      </CoreContext.Provider>
    );
  }
}
