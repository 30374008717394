// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CouponsCreateContainer } from './create/coupons-create.container';
import { CouponsChargeContainer } from './charge/coupons-charge.container';
import { CouponsDetailsContainer } from './details/coupons-details.container';
import { CouponsListContainer } from './list/coupons-list.container';
import { CouponsMassChargeContainer } from './mass-charge/coupons-mass-charge.container';
import { CouponsShareContainer } from './share/coupons-share.container';
import { CouponsTypesComponent } from './types/coupons-types.component';

/*:: import type { Props } from './coupons.component.types';*/

export class CouponsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/:type/create`} component={CouponsCreateContainer} />
        <Route path={`${this.props.match.url}/charge`} component={CouponsChargeContainer} />
        <Route path={`${this.props.match.url}/mass-charge`} component={CouponsMassChargeContainer} />
        <Route path={`${this.props.match.url}/types`} component={CouponsTypesComponent} />
        <Route path={`${this.props.match.url}/share/:coupons`} component={CouponsShareContainer} />
        <Route path={`${this.props.match.url}/:id(\\d+)`} component={CouponsDetailsContainer} />
        <Route path={this.props.match.url} component={CouponsListContainer} />
      </Switch>
    );
  }
}
