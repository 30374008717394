import { useEffect, useMemo } from 'react';
import _ from 'lodash';

import { useIntl } from 'app/hooks/useIntl/useIntl';
import { NO_SERVICE_TYPE_GROUP_ID } from './constants';
import { Filters, ServiceTypeGroupWithStatus } from './filters/pricelists-filters.component';
import { ContractStatus, ContractSubStatus } from 'app/constants';
import { IntlShape } from 'react-intl';
import { Contract } from 'app/types/contract';

const OrganizerStatusSortOrder: Record<ContractStatus | ContractSubStatus, number> = {
  [ContractStatus.Pending]: 0,
  [ContractSubStatus.PendingAfterChangesRequested]: 0,

  [ContractSubStatus.ChangesNotMadeInTime]: 1,

  [ContractStatus.ChangesRequested]: 2,
  [ContractStatus.New]: 2,
  [ContractStatus.Locked]: 2,

  [ContractStatus.Approved]: 3,
  [ContractStatus.Deleted]: 4,
} as const;

const ProviderStatusSortOrder: Record<ContractStatus | ContractSubStatus, number> = {
  [ContractStatus.ChangesRequested]: 0,
  [ContractStatus.New]: 0,

  [ContractStatus.Pending]: 1,
  [ContractSubStatus.PendingAfterChangesRequested]: 1,

  [ContractSubStatus.ChangesNotMadeInTime]: 2,
  [ContractStatus.Locked]: 2,

  [ContractStatus.Approved]: 3,
  [ContractStatus.Deleted]: 4,
} as const;

// this is exported for tests. Ideally we would test the
// hook itself, however that is a bit more complicated.
// We might want to change the tests to actually test the hook
// input and output
export const calculateServiceTypeGroups = (
  contracts: readonly Contract[],
  context: UseServiceTypeGroupsProps['context'],
  intl: IntlShape,
) => {
  if (!contracts || !contracts.some(c => c.serviceTypeGroup)) {
    return [];
  }

  const contextStatusSortOrder = context === 'organizer' ? OrganizerStatusSortOrder : ProviderStatusSortOrder;

  const serviceTypeGroupsMap = contracts.reduce<Record<ServiceTypeGroupWithStatus['id'], ServiceTypeGroupWithStatus>>(
    (groups, contract) => {
      const group = contract.serviceTypeGroup
        ? { ...contract.serviceTypeGroup, status: contract.subStatus ?? contract.status }
        : {
            id: NO_SERVICE_TYPE_GROUP_ID,
            name: intl.formatMessage({ id: 'PRICELISTS.DETAILS.FILTERS.NO_SERVICE_TYPE_GROUP' }),
            status: contract.subStatus ?? contract.status,
          };

      const existingGroup = groups[group.id];
      if (!existingGroup) {
        return { ...groups, [group.id]: group };
      }

      if (contextStatusSortOrder[group.status] < contextStatusSortOrder[existingGroup.status]) {
        return { ...groups, [group.id]: group };
      }

      return groups;
    },
    {},
  );

  const sortedGroups = _.sortBy(Object.values(serviceTypeGroupsMap), [
    group => contextStatusSortOrder[group.status],
    group => group.id === NO_SERVICE_TYPE_GROUP_ID,
    group => group.name.toLowerCase(),
  ]);

  return sortedGroups;
};

export type UseServiceTypeGroupsProps = {
  context: 'organizer' | 'provider';
  contracts: Contract[];
  onFiltersChanged: (f: Filters) => void;
};

export const useServiceTypeGroups = ({ context, contracts, onFiltersChanged }: UseServiceTypeGroupsProps) => {
  const intl = useIntl();

  const serviceTypeGroups = useMemo(
    () => calculateServiceTypeGroups(contracts, context, intl),
    [contracts, context, intl],
  );

  useEffect(() => {
    if (serviceTypeGroups.length) {
      onFiltersChanged({
        serviceTypeGroupId: serviceTypeGroups[0].id,
      });
    }
  }, [serviceTypeGroups, onFiltersChanged]);

  return { serviceTypeGroups };
};
