//@flow

/*:: import type { Config } from './get-description-service.util.types';*/

export const getDescriptiveService = (
  { intl, descriptiveServices = [] } /*: Config*/,
  descriptiveService /*: string*/,
) => {
  if (intl && intl.formatMessage && typeof intl.formatMessage !== 'function') {
    throw new Error('Intl object is incorrect!');
  }

  const matchedService = descriptiveServices.find(sourceService => sourceService.id === descriptiveService);

  if (typeof matchedService !== 'undefined' && matchedService.custom === false) {
    return intl.formatMessage({ id: `COUPONS.DESCRIPTIVE_SERVICES.${descriptiveService}` });
  }

  return descriptiveService;
};
