// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { PageHeaderComponent } from 'app/core';
import { SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { SummaryButtonsComponent, SummaryFieldComponent } from 'app/register/shared';
import { AgreementsAcceptanceContainer } from 'app/agreements/acceptance/agreements-acceptance.container';

/*:: import type { Props, State } from './register-summary.component.types';*/

export class RegisterSummaryComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      terms: {
        onChange: () => this.setState(prev => ({ terms: { ...prev.terms, value: !prev.terms.value } })),
        value: false,
      },
    };
  }

  getVenueType = (id /*: number*/) => {
    const types = this.props.venuesTypes;
    const venueType = types && types.find(el => el.id === id);
    return venueType ? venueType.name : '';
  };

  getServices = (services /*: number[]*/) => {
    if (!services) return;

    return services
      .reduce((prev, next) => {
        const service = this.props.services.find(el => el.id === next);
        return service ? [...prev, service.name] : prev;
      }, [])
      .join(', ');
  };

  getNumberOfServices = (services /*: number[]*/) => services.length || 0;

  render() {
    const { bankAccount, company, changeStep, responsiblePerson, submitting, venues } = this.props;

    return (
      <>
        <PageHeaderComponent showBusinessName={false} title="REGISTER.SUMMARY_TITLE" />
        <CardComponent>
          <CardHeaderComponent title="REGISTER.SECTION_TITLES.GENERAL_COMPANY" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.OFFICIAL_NAME" tagName="h3" />
                <h4>{company.name}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.BUSINESS_ID" tagName="h3" />
                <h4>{company.businessId}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.ADDRESS" tagName="h3" />
                <h4>{`${company.address.city}, ${company.address.address}, ${company.address.zipCode}`}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.PHONE" tagName="h3" />
                <h4>{company.phone}</h4>
              </SummaryFieldComponent>
            </div>
          </CardBodyComponent>
          <SeparatorComponent />
          <CardHeaderComponent title="REGISTER.SECTION_TITLES.BANK_ACCOUNT" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.ACCOUNT_NUMBER" tagName="h3" />
                <h4>{bankAccount.iban}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.SWIFT" tagName="h3" />
                <h4>{bankAccount.swift}</h4>
              </SummaryFieldComponent>
            </div>
          </CardBodyComponent>
          <SeparatorComponent />
          <CardHeaderComponent title="REGISTER.SECTION_TITLES.PERSON_RESPONSIBLE" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.FIRST_NAME" tagName="h3" />
                <h4>{responsiblePerson.firstName}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.LAST_NAME" tagName="h3" />
                <h4>{responsiblePerson.lastName}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="h3" />
                <h4>{responsiblePerson.mobilePhone}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.EMAIL" tagName="h3" />
                <h4>{responsiblePerson.email}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="PROFILE.WORK_PHONE" tagName="h3" />
                <h4>{responsiblePerson.workPhone || '-'}</h4>
              </SummaryFieldComponent>
              <SummaryFieldComponent className="col-lg-6">
                <FormattedMessage id="REGISTER.MARKETING" tagName="h3" />
                <h4>
                  {responsiblePerson.allowMarketing ? (
                    <FormattedMessage id="REGISTER.SELECTED_MARKETING" />
                  ) : (
                    <FormattedMessage id="REGISTER.NOT_SELECTED_MARKETING" />
                  )}
                </h4>
              </SummaryFieldComponent>
            </div>
          </CardBodyComponent>
          {venues.map((venue, index) => (
            <div key={index}>
              <CardHeaderComponent>
                <Typography tag="h4" color="secondary" type="largeText">
                  {`#${index + 1} `}
                  <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_GENERAL" />
                </Typography>
              </CardHeaderComponent>
              <SeparatorComponent />
              <CardBodyComponent>
                <div className="row">
                  <div className="col-lg-12" />
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.NAME" tagName="h3" />
                    <h4>{venue.venue.name}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.STREET_ADDRESS" tagName="h3" />
                    <h4>{venue.venue.address.address}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.ZIP_CODE" tagName="h3" />
                    <h4>{venue.venue.address.zipCode}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.CITY" tagName="h3" />
                    <h4>{venue.venue.address.city}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.CONTACT_NAME" tagName="h3" />
                    <h4>{venue.venue.contactName}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.CONTACT_PHONE" tagName="h3" />
                    <h4>{venue.venue.contactPhone}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.WEBSITE_ADDRESS" tagName="h3" />
                    <h4>{venue.venue.website || '-'}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.EMAIL" tagName="h3" />
                    <h4>{venue.venue.email || '-'}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-6">
                    <FormattedMessage id="REGISTER.VENUE.PHONE" tagName="h3" />
                    <h4>{venue.venue.phone || '-'}</h4>
                  </SummaryFieldComponent>
                  <SummaryFieldComponent className="col-lg-12">
                    <FormattedMessage id="REGISTER.VENUE.DESCRIPTION" tagName="h3" />
                    <h4>{venue.venue.description || '-'}</h4>
                  </SummaryFieldComponent>
                </div>
              </CardBodyComponent>
              <SeparatorComponent />

              {venue.admins.map((admin, adminIndex) => (
                <div key={adminIndex}>
                  <CardHeaderComponent>
                    <Typography tag="h4" color="secondary" type="largeText">
                      {`#${adminIndex + 1} `}
                      <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_ADMIN" />
                    </Typography>
                  </CardHeaderComponent>
                  <SeparatorComponent />
                  <CardBodyComponent>
                    <div className="row">
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="PROFILE.FIRST_NAME" tagName="h3" />
                        <h4>{admin.firstName}</h4>
                      </SummaryFieldComponent>
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="PROFILE.LAST_NAME" tagName="h3" />
                        <h4>{admin.lastName}</h4>
                      </SummaryFieldComponent>
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="PROFILE.MOBILE_PHONE" tagName="h3" />
                        <h4>{admin.mobilePhone}</h4>
                      </SummaryFieldComponent>
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="PROFILE.EMAIL" tagName="h3" />
                        <h4>{admin.email}</h4>
                      </SummaryFieldComponent>
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="PROFILE.WORK_PHONE" tagName="h3" />
                        <h4>{admin.workPhone || '-'}</h4>
                      </SummaryFieldComponent>
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="REGISTER.MARKETING" tagName="h3" />
                        <h4>
                          {admin.allowMarketing ? (
                            <FormattedMessage id="REGISTER.SELECTED_MARKETING" />
                          ) : (
                            <FormattedMessage id="REGISTER.NOT_SELECTED_MARKETING" />
                          )}
                        </h4>
                      </SummaryFieldComponent>
                    </div>
                  </CardBodyComponent>
                </div>
              ))}

              {venue.contracts.map((contract, innerIndex) => (
                <div key={innerIndex}>
                  <CardHeaderComponent>
                    <Typography tag="h4" color="secondary" type="largeText">
                      {`#${innerIndex + 1} `}
                      <FormattedMessage id="REGISTER.SERVICES" />
                    </Typography>
                  </CardHeaderComponent>
                  <SeparatorComponent />
                  <CardBodyComponent>
                    <div className="row">
                      <SummaryFieldComponent className="col-lg-6">
                        <FormattedMessage id="REGISTER.VENUE.VENUE_ID" tagName="h3" />
                        <h4>{this.getVenueType(contract.venueId)}</h4>
                      </SummaryFieldComponent>
                    </div>
                  </CardBodyComponent>
                  <SeparatorComponent />
                  <CardHeaderComponent title="REGISTER.SELECTED_SERVICES" />
                  <SeparatorComponent />
                  <CardBodyComponent>
                    <div className="row">
                      <SummaryFieldComponent className="col-lg-12">
                        <FormattedMessage
                          id="REGISTER.SELECTED"
                          tagName="h3"
                          values={{ amount: this.getNumberOfServices(contract.services) }}
                        />
                        <h4>{this.getServices(contract.services)}</h4>
                      </SummaryFieldComponent>
                    </div>
                  </CardBodyComponent>
                </div>
              ))}
            </div>
          ))}

          <CardBodyComponent>
            <AgreementsAcceptanceContainer input={this.state.terms} name="general_vaana" />
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />
        <SummaryButtonsComponent changeStep={changeStep} disabled={!this.state.terms.value} submitting={submitting} />
        <SeparatorComponent />
      </>
    );
  }
}
