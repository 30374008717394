import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, compose, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';

import { routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { get } from 'lodash';

import {
  apiDebugMiddleware,
  authMiddleware,
  bodyMiddleware,
  endpointMiddleware,
  internalErrorMiddleware,
  notFoundMiddleware,
  paramsMiddleware,
  sessionMiddleware,
  singleConcurrentRequestMiddleware,
  validationMiddleware,
  watchRouteChange,
} from 'app/middlewares';

import 'assets/styles/main.scss';

import { createAppReducer } from 'app/app.reducer';
import { appSaga } from 'app/app.saga';

import { unregister } from 'registerServiceWorker';
import { configuration } from 'configuration';

import { registerSentry } from './app/sentry/sentry';

import { indexComponent } from './index.component';
import { v2Saga } from './v2/v2.saga';
import { fork } from 'redux-saga/effects';
import { crashApplicationUsingHistory, reportError } from './app/utils';
import { createLocalBrowserHistory } from './local-browser-history';

export const history = createLocalBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  bodyMiddleware,
  authMiddleware,
  endpointMiddleware,
  paramsMiddleware,
  singleConcurrentRequestMiddleware,
  apiMiddleware,
  validationMiddleware,
  sessionMiddleware,
  sagaMiddleware,
  watchRouteChange,
  routerMiddleware(history),
  notFoundMiddleware,
  thunkMiddleware,
  internalErrorMiddleware,
];

if (configuration.enableApiDebugMiddleware) {
  middlewares.unshift(apiDebugMiddleware);
}

function retrieveNextSessionExpirationTime() {
  const value /*: string*/ = localStorage.getItem('nextSessionExpirationTime');
  return value && parseInt(value, 10);
}

const store = createStore(
  createAppReducer(history),
  {
    users: {
      accessToken: localStorage.getItem('accessToken'),
      nextSessionExpirationTime: retrieveNextSessionExpirationTime(),
      entities: {},
    },
  },
  compose(applyMiddleware(...middlewares), devToolsEnhancer({})),
);

if (module.hot) {
  module.hot.accept('app/app.reducer', () => {
    store.replaceReducer(createAppReducer(history));
  });
}

function* rootSaga() {
  yield fork(appSaga);
  yield fork(
    v2Saga,
    () => crashApplicationUsingHistory(history),
    reportError,
    configuration.apiEndpoint,
    configuration.apiV2Endpoint,
    {
      getToken: () => get(store.getState(), ['users', 'accessToken']),
      getCurrentBusiness: () => get(store.getState(), ['users', 'business']),
    },
    {
      // NOTE: application language is currently stored in AppComponent
      // and exposed as a React context to rest of the application.
      // We cannot access that from here. AppComponent also reads&stores
      // the language to localStorage 'language' key, so lets read it from there.
      // In the future we might want to move this to Redux store or
      // store the language in this root file and pass it down
      getApplicationLanguage: () => localStorage.getItem('language') ?? 'fi',
    },
  );
}

sagaMiddleware.run(rootSaga);

const render = () => {
  ReactDOM.render(indexComponent(store, history), document.getElementById('app-root'));
};

if (process.env.REACT_APP_DEBUG) {
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React);
}

registerSentry(configuration);

render();

unregister();
