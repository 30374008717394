import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';
import classNames from 'classnames';
import { PropsWithChildren, useEffect } from 'react';
import { TranslationKey } from 'v2/types/translations';
import styles from './modal.component.module.scss';

type Button = {
  label: TranslationKey;
  onClick: () => void;
};
type ModalComponentProps = PropsWithChildren<{
  opened?: boolean;
  cancelButton?: Button;
  confirmButton?: Button;
}>;

export const ModalComponent: React.FC<ModalComponentProps> = ({
  children,
  opened = false,
  cancelButton,
  confirmButton,
}) => {
  useEffect(() => {
    if (opened) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [opened]);

  if (!opened) return null;

  return (
    <div className="modal-backdrop">
      <div className={classNames('modal', styles.modal)}>
        <div className="modal-dialog">
          <div className={classNames('modal-content', styles.modalContent)}>
            <div className={classNames('modal-body', styles.modalBody)}>{children}</div>
            {(cancelButton || confirmButton) && (
              <div className={classNames('modal-footer', styles.modalFooter)}>
                {cancelButton && (
                  <ButtonComponent className={styles.button} theme="outline-primary" onClick={cancelButton.onClick}>
                    <FormattedMessage id={cancelButton.label} />
                  </ButtonComponent>
                )}
                {confirmButton && (
                  <ButtonComponent className={styles.button} theme="primary" onClick={confirmButton.onClick}>
                    <FormattedMessage id={confirmButton.label} />
                  </ButtonComponent>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
