//@flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { ButtonComponent, ConnectedCheckboxesComponent, SeparatorComponent, Typography } from 'app/shared';

import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './coupons-downloads-excel-file.component.types';*/
import styles from './coupons-downloads-excel-file.component.module.scss';

export class CouponsDownloadExcelFileComponent extends Component /*:: <Props>*/ {
  render() {
    const { columns, coupons, handleSubmit, submitting } = this.props;

    const options = get(columns, ['list'], []).map(option => ({
      id: option,
      text: `COUPONS.LIST.HEADERS.${option.replace(/([A-Z])/g, x => `_${x}`).toUpperCase()}`,
      alwaysEnabled: option === 'decisionNumber',
    }));

    return (
      <CardComponent>
        <CardHeaderComponent title="COUPONS.SIDEBAR.EXCEL.TITLE" />
        <CardBodyComponent>
          <Typography color="grey" type="subtitle" id="COUPONS.SIDEBAR.EXCEL.CARD_TITLE" />
          <SeparatorComponent height={8} />
          <form onSubmit={handleSubmit}>
            <Field
              name="columns"
              theme="columns"
              component={ConnectedCheckboxesComponent}
              options={options}
              columns={2}
            />
            <SeparatorComponent height={8} />
            <ButtonComponent
              disabled={!coupons.length}
              theme="primary-outline"
              className={styles.button}
              loading={submitting}
            >
              <i className="fi fi-download-excel" />
              <FormattedMessage id="COUPONS.SIDEBAR.EXCEL.DOWNLOAD" />
            </ButtonComponent>
          </form>
        </CardBodyComponent>
      </CardComponent>
    );
  }
}
