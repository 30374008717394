// @flow
// TODO: Flow is enabled for this file as otherwise the functions defined here are not
// visible for Flow and cause Flow errors in files that use Flow checking. The arguments
// are all any for now, as we don't have good types for these.

import { reportMessage } from 'app/sentry/sentry';
import { push } from 'connected-react-router';

// Side-effect that will change the state of the application to crashed.
// This state will be interpreted by the top-level application component (app.component.js)

export function crashApplicationUsingHistory(history /*: any*/) {
  const state = { crashed: true };
  history.push({ state });
  reportApplicationCrashed();
}

export function crashApplicationUsingConnectedRouter(store /*: any*/, currentPath /*: any*/) {
  reportApplicationCrashed();
  return store.dispatch(push(currentPath, { crashed: true }));
}

export function reportApplicationCrashed() {
  reportMessage('OmaVaana SPA has crashed');
}

// Side-effect that will report an error to sentry.
export function reportError(message /*: any*/, extra /*: any*/) {
  reportMessage(message, extra);
}
