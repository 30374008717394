import { RSAA } from 'redux-api-middleware';

export const apiDebugMiddleware = () => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  return next({
    [RSAA]: {
      ...apiMiddleware,
      credentials: 'include',
    },
  });
};
