// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { initialize } from 'redux-form';

import { withAuthorization } from 'app/common';
import { withCoreContext } from 'app/context/core/core.context';
import { withLocale } from 'app/app.context';
import { scrollToElement } from 'app/utils';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';
import { getCoupons, getCouponsMeta } from 'app/redux/coupons/coupons.selectors';

import { fetchVenueCoupons, venueCouponsMetadataClear } from 'app/redux/coupons/coupons.actions';
import { chargeCoupon } from 'app/redux/payments/payments.actions';
import { validateMassDebitForm } from '../validation/validateMassDebitForm';
import { CouponsMassChargeFormComponent } from './coupons-mass-charge-form.component';

/*:: import type { Payment, PaymentsRequest } from '../coupons-mass-charge.component.types';*/
/*:: import type { FormPayment, PaymentsFormValues } from './coupons-mass-charge-form.component.types';*/

const mapStateToProps = ({ dictionaries, ...state }) => {
  const vatRatesDictionary = get(dictionaries, ['municipal/services', 'vatRates'], []);

  const coupons = getCoupons(state);
  const meta = getCouponsMeta(state);

  return {
    coupons,
    vatRatesDictionary,
    validateForm: validateMassDebitForm(coupons),
    newCouponsIds: get(meta, ['newCouponsIds'], []),
    loading: !coupons,
  };
};

const createPaymentsRequestBody = (formValues /*: PaymentsFormValues*/) /*: PaymentsRequest*/ => {
  return {
    reference: formValues.reference,
    payments: formValues.payments.map((payment /*: FormPayment*/) /*: Payment*/ => ({
      accountNumber: payment.accountNumber,
      active: payment.active && payment.visible,
      numberOfPriceUnits: payment.numberOfPriceUnits,
      serviceProduced: {
        startDate: payment.serviceProduced.startDate,
        endDate: payment.serviceProduced.endDate,
      },
      vatRate: payment.vatRate,
      amount: payment.amount,
    })),
  };
};

const mapDispatchToProps = dispatch => ({
  // TODO: Remove fetchVenueCoupons. It doesn't seem to be used by the CouponsMassChargeFormComponent
  fetchVenueCoupons: (...params) => dispatch(fetchVenueCoupons(...params)),
  onSubmit: async ({ values }) => {
    const paymentsRequestBody = createPaymentsRequestBody(values);
    const successHandler = () => {
      dispatch(initialize('filter-coupons', {}));

      scrollToElement('body');
    };

    const failureHandler = () => {
      scrollToElement(['.alert.alert-danger', '.ReactVirtualized__Grid__innerScrollContainer'], 150);
    };

    return await withFormSubmissionErrorHandling(
      dispatch(chargeCoupon(paymentsRequestBody)),
      successHandler,
      failureHandler,
    );
  },
  venueCouponsMetadataClear: () => dispatch(venueCouponsMetadataClear()),
});

export const CouponsMassChargeFormContainer = compose(
  withLocale,
  withAuthorization(['venuePayment', 'create'], ['venueServiceCoupons', 'read']),
  injectIntl,
  withCoreContext,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsMassChargeFormComponent);
