// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get } from 'lodash';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Props } from './with-invoicing-options.hoc.types';*/

export const withInvoicingOptions = (WrappedComponent /*: ComponentType<any>*/) =>
  connect(({ dictionaries, users: { business } }, { type }) => ({
    invoicingOptions: get(dictionaries, ['invoicing-options', business.id, type]),
  }))(
    class extends Component /*:: <Props>*/ {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
  );
