import { Action } from 'redux';
import { handleActions } from 'redux-actions';

type ReducerFn<TState, TRootAction extends Action, TType extends TRootAction['type']> = (
  state: TState,
  action: TRootAction & { type: TType },
) => TState;

type Reducers<TState, TRootAction extends Action> = {
  [TType in TRootAction['type']]?: ReducerFn<TState, TRootAction, TType>;
};

export function typedHandleActions<TState, TRootAction extends Action>(
  reducers: Reducers<TState, TRootAction>,
  defaultState: TState,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return handleActions(reducers as any, defaultState);
}