// @flow
import React, { Component } from 'react';

import { ListComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

import { CouponsChargeModalTable } from './table/coupons-charge-modal-table.component';
import { CouponsChargeModalTile } from './tile/coupons-charge-modal-tile.component';

/*:: import type { Props } from './coupons-details-charge-modal.types';*/

export class CouponsDetailsChargeModalComponent extends Component /*:: <Props>*/ {
  render() {
    const isExtended = this.props.payments.reduce(
      (isExtended, payment) => (payment.descriptiveService ? true : isExtended),
      false,
    );

    const commonTypographyProps = {
      type: 'smallText',
      color: 'grey',
    };

    return (
      <>
        <CardBodyComponent>
          <Typography type="subtitle" color="grey" id="COUPONS.DETAILS.CHARGE.MODAL.DETAILS" />
          <SeparatorComponent />

          <div className="row">
            <div className="col-12 col-sm-6">
              <Typography {...commonTypographyProps} id="COUPONS.DETAILS.LABEL.NAME" />
              {this.props.coupon.reference}
              <SeparatorComponent />
            </div>
            <div className="col-12 col-sm-6">
              <Typography {...commonTypographyProps} id="COUPONS.DETAILS.LABEL.DATE_OF_BIRTH" />
              {dateFormatter(this.props.coupon.dateOfBirth)}
              <SeparatorComponent />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6">
              <Typography {...commonTypographyProps} id="COUPONS.DETAILS.LABEL.ACCOUNT_NUMBER" />
              {this.props.coupon.id}
              <SeparatorComponent />
            </div>
            <div className="col-12 col-sm-6">
              <Typography {...commonTypographyProps} id="COUPONS.DETAILS.LABEL.TYPE" />
              {this.props.coupon.type}
              <SeparatorComponent />
            </div>
          </div>

          <Typography type="subtitle" color="grey" id="COUPONS.DETAILS.CHARGE.MODAL.CHARGE" />
          <SeparatorComponent />

          <div className="row">
            <div className="col">
              <ListComponent
                list={this.props.payments}
                props={{
                  extended: isExtended,
                }}
                table={CouponsChargeModalTable}
                tile={CouponsChargeModalTile}
                noResultsProps={{ showDesc: false }}
              />
            </div>
          </div>
          <SeparatorComponent />
        </CardBodyComponent>
      </>
    );
  }
}
