// @flow

import React, { PureComponent } from 'react';
import chunk from 'lodash/chunk';

import { PlaceholderComponent } from 'app/shared';
import { InfoSectionComponent } from '../info-section/info-section.component';
import { CardBodyComponent } from 'app/shared/card-new';
import { basicInfoTypographyProps } from '../coupons-details-info.component';

import styles from '../coupons-details-info.module.scss';

const elements = {
  basicInfo: [
    { label: null, width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.TOTAL', width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.UNIT_COST.DEFAULT', width: 60 },
    { label: 'COUPONS.DETAILS.LABEL.MAX_TRANSACTION', width: 60 },
  ],
  additionalInfo: [
    { label: null, width: 60 },
    { label: null, width: 60 },
    { label: 'COUPONS.DETAILS.LABEL.DECISION_VALUE', width: 60 },
    { label: 'COUPONS.DETAILS.LABEL.TYPE', width: 60 },
    { label: 'COUPONS.DETAILS.LABEL.VENUE', width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.PRICE_UNIT', width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.ISSUED', width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.ISSUER_NAME', width: 120 },
    { label: 'COUPONS.DETAILS.LABEL.ADDITIONAL_INFO', width: 180 },
  ],
};

export class CouponsDetailsInfoPlaceholder extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <CardBodyComponent>
        <div className={styles.infoWrapper}>
          <div className={styles.basicInfo}>
            {elements.basicInfo.map(({ width, label }, index) => (
              <InfoSectionComponent
                key={index}
                {...(label ? { label } : {})}
                childrenTypography={basicInfoTypographyProps}
              >
                <PlaceholderComponent width={width} />
              </InfoSectionComponent>
            ))}
          </div>
          <div className={styles.additionalInfo}>
            {chunk(elements.additionalInfo, 2).map((row, index) => {
              return (
                <div key={index} className={styles.row}>
                  {row.map(({ width, label }, index) => (
                    <InfoSectionComponent key={index} {...(label ? { label } : {})} className={styles.col}>
                      <PlaceholderComponent width={width} />
                    </InfoSectionComponent>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </CardBodyComponent>
    );
  }
}
