// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import { CardBodyComponent, CardComponent } from 'app/shared/card-new';
import { Typography } from 'app/shared/typography/typography.component';
import { SeparatorComponent } from 'app/shared/separator/separator.component';

/*:: import type { Props } from './alert.component.types';*/
import styles from './alert.module.scss';

export class AlertComponent extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    withShadow: true,
  };

  render() {
    const { withShadow } = this.props;

    const iconStatus = (() => {
      const { type } = this.props;
      switch (type) {
        case 'success':
          return 'check';
        case 'failure':
          return 'error';
        default:
          return false;
      }
    })();

    const iconClassNames = classNames(
      `fi fi-${iconStatus ? iconStatus : 'info'}`,
      styles.icon,
      styles[`icon${capitalize(this.props.type)}`],
    );

    return (
      <>
        <CardComponent className={this.props.className} withShadow={withShadow}>
          <CardBodyComponent className={classNames(styles.content)}>
            {iconStatus && <i className={iconClassNames} />}
            <Typography type="subtitle" color="grey">
              {this.props.children}
            </Typography>
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />
      </>
    );
  }
}
