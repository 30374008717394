import { ButtonComponent } from 'app/shared';
import { FormattedMessage } from 'app/translations/formatted-message.component';

interface Props {
  submitting: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export const RejectConfirmation: React.FunctionComponent<Props> = ({ submitting, onCancel, onDelete }: Props) => {
  return (
    <>
      <div className="pt-4 font-weight-bold">
        <FormattedMessage id="PRICELISTS.DETAILS.DELETE_CONFIRM" />
      </div>
      <div className="text-right">
        <ButtonComponent theme="primary-outline" onClick={onCancel} disabled={submitting}>
          <FormattedMessage id="CORE.CANCEL" />
        </ButtonComponent>
        <ButtonComponent className="ml-2" theme="primary" onClick={onDelete}>
          <FormattedMessage id="CORE.CONFIRM" />
        </ButtonComponent>
      </div>
    </>
  );
};
