import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { injectIntl } from 'react-intl';

import { ContractComponent } from './contract.component';

const mapStateToProps = ({ form, register: { venuesTypes } }, { field, form: formName }) => ({
  venuesTypes,
  venueId: formValueSelector(formName)({ form }, field).venueId,
});

export const ContractContainer = compose(injectIntl, connect(mapStateToProps))(ContractComponent);
