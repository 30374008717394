// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedDateRangeComponent, ConnectedSelectComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';

/*:: import type { Props, State } from './account-transactions-filters.component.types';*/
import styles from 'app/transactions/municipal/filters/transactions-municipal-filters.module.scss';

export class AccountTransactionsFiltersComponent extends Component /*:: <Props, State>*/ {
  static defaultProps = {
    types: [],
  };

  constructor(props /*: Props*/) {
    super(props);

    this.state = {};
  }

  toggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const rowClassNames = classNames('row', styles.row);

    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <CardHeaderComponent title="TRANSACTIONS.SEARCH">
          <ButtonComponent size="small" theme="secondary-outline" onClick={this.props.onClear}>
            <FormattedMessage id="CORE.CLEAR_FILTERS" />
          </ButtonComponent>
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <form onSubmit={this.props.handleSubmit}>
            <div className={rowClassNames}>
              <div className={columnClassNames}>
                <ConnectedDateRangeComponent
                  field={Field}
                  name="range"
                  startDateKey="minDate"
                  endDateKey="maxDate"
                  onFocusChange={this.toggle}
                  label="TRANSACTIONS.TRANSACTION_DATE"
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name="type"
                  component={ConnectedSelectComponent}
                  label="TRANSACTIONS.TRANSACTION_TYPE"
                  options={this.props.types.map(type => ({
                    id: type,
                    name: `TRANSACTION_TYPE.${type}`,
                  }))}
                  withClearOption
                />
              </div>
            </div>
            <div className={backdropClassNames} />
          </form>
        </CardBodyComponent>
      </>
    );
  }
}
