import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { withPermissions } from 'app/common';

import { SidebarAdditionalComponent } from './sidebar-additional.component';

const mapStateToProps = ({ archive: { newVatArchiveReport } }) => ({
  newVatArchiveReport,
});

export const SidebarAdditionalContainer = compose(
  injectIntl,
  withPermissions,
  connect(mapStateToProps),
)(SidebarAdditionalComponent);
