//@flow

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import { PortalComponent } from 'app/shared';
import { ButtonComponent } from 'app/shared/button-old/button.component';

import { SidebarTabButton } from './sidebar-tab/sidebar-tab.component';

/*:: import type { Props, State, WrapperProps } from './sidebar.component.types';*/
import styles from './sidebar.module.scss';

class DefaultContentWrapper extends Component /*:: <WrapperProps>*/ {
  render() {
    return this.props.children || null;
  }
}

const nullTabIndex = -1;

export class Sidebar extends Component /*:: <Props, State>*/ {
  static defaultProps = {
    element: document.getElementById('body'),
    isOpen: false,
  };

  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      tab: props.defaultTab !== undefined ? props.defaultTab : nullTabIndex,
    };
  }

  componentDidMount() /*: void*/ {
    const { isOpen } = this.props;

    this.props.register(this.state.tab !== nullTabIndex || isOpen);
  }

  componentWillUnmount() /*: void*/ {
    this.props.destroy();
  }

  componentDidUpdate(prevProps /*: Props*/, prevState /*: State*/) {
    const { isOpen } = this.props.sidebar;
    const { isOpen: prevIsOpen } = prevProps.sidebar;

    if (!isOpen && prevIsOpen) {
      this.setState({ tab: nullTabIndex });
    }
  }

  handleClickOutside = () => {
    const { sidebarClose, allowOutsideClick } = this.props;

    if (allowOutsideClick) {
      sidebarClose();
    }
  };

  renderContent = () => {
    const { renderer } = this.props;
    const Component /*: any*/ = this.props.component || DefaultContentWrapper;
    const { isOpen } = this.props.sidebar;

    if (!isOpen) {
      return null;
    }

    const { sidebarOpen, sidebarClose, sidebarToggle, tabs, ...rest } = this.props;
    const { tab: tabIndex } = this.state;

    const activeTab = tabs && tabIndex !== nullTabIndex ? tabs[tabIndex] : null;
    const TabContent /*: any*/ = activeTab ? activeTab.component : null;

    const sidebarActions = {
      sidebarOpen,
      sidebarClose,
      sidebarToggle,
    };

    const rendererProps = {
      ...rest.props,
      ...(activeTab && renderer ? { ActiveTabComponent: TabContent } : {}),
      ...(activeTab ? activeTab.props : {}),
    };

    if (renderer && typeof renderer === 'function') {
      return renderer(rendererProps);
    } else {
      if (activeTab && TabContent) {
        return (
          <Component {...sidebarActions}>
            <TabContent {...rendererProps} />
          </Component>
        );
      }

      return <Component {...sidebarActions} />;
    }
  };

  setActiveTab = (index /*: number*/) => this.setState({ tab: index });

  render() {
    const { side, tabs, closeLabel, animation, className } = this.props;
    const { isOpen } = this.props.sidebar;

    const sideClassName = side === 'left' ? styles.sidebarLeft : styles.sidebarRight;
    const sidebarClassNames = classNames(styles.sidebar, sideClassName, className, {
      [styles.sidebarOpen]: isOpen,
      [styles.sidebarWithAnimation]: animation,
    });

    const buttonsMode = tabs && !!tabs.filter(item => !!item.onClick).length;

    const tabProps = {
      activeTab: this.state.tab,
      setActiveTab: this.setActiveTab,
      ...this.props,
    };

    /*:: type WrapperType = {
          props?: { [$key: string]: any },
          type: React$ComponentType<any>,
        };*/

    const wrapper /*: WrapperType*/ = this.props.element
      ? { type: PortalComponent, props: { node: this.props.element } }
      : { type: Fragment };

    return (
      <wrapper.type {...wrapper.props}>
        <div className={sidebarClassNames}>
          {tabs && (
            <div className={styles.tabs}>
              {tabs.map((tab, index) => (
                <SidebarTabButton {...tabProps} key={index} tab={tab} index={index} />
              ))}
            </div>
          )}
          <div className={styles.container}>
            {!buttonsMode && (
              <div className={styles.content}>
                <div className={styles.header}>
                  <ButtonComponent
                    theme="outline-secondary"
                    size="tiny"
                    onClick={this.props.sidebarClose}
                    testId="close-sidebar"
                  >
                    {closeLabel}
                    <i className="fi fi-arrow-right" />
                  </ButtonComponent>
                </div>
                {this.renderContent()}
              </div>
            )}
          </div>
        </div>
      </wrapper.type>
    );
  }
}

export const SidebarComponent = onClickOutside(Sidebar);
