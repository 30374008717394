//@flow
import { FinnishSSN } from 'finnish-ssn';

export const personalIdentificationCodeValidator = (personalIdentificationCode /*: string*/) => {
  if (!FinnishSSN.validate(personalIdentificationCode)) {
    return { message: 'VALIDATION.INVALID_PIC' };
  }
  return undefined;
};

export const emptyOrPersonalIdentificationCodeValidator = (personalIdentificationCode /*: string*/) => {
  return personalIdentificationCode ? personalIdentificationCodeValidator(personalIdentificationCode) : undefined;
};
