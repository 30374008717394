import { compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { get } from 'lodash';

import { withAuthorization, withPermissions } from 'app/common';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';
import { fetchUsers } from 'app/redux/users/users.actions';
import { fetchVenue } from 'app/redux/venues/venues.actions';

import { VenueUsersComponent } from './venue-users.component';

const mapStateToProps = (
  { dictionaries, users: { entities: users }, venues: { entities: venues } },
  {
    match: {
      params: { id },
    },
  },
) => ({
  loading: !venues[id] || !users[id] || !dictionaries['user-roles/venue'],
  users: get(users, [id, 'list']),
  venue: venues[id],
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id },
    },
  },
) => ({
  changeVenue: venue => dispatch(replace(`/venues/${venue}/users`)),
  redirectToServiceProviderPage: () => dispatch(replace(`/venues`)),
  fetchDictionary: () => dispatch(fetchDictionary('user-roles/venue')),
  clearAndFetchUsers: () => dispatch(fetchUsers(id, 'venue', true)),
  reloadUsers: () => dispatch(fetchUsers(id, 'venue', false)),
  fetchVenue: () => dispatch(fetchVenue(id)),
});

export const VenueUsersContainer = compose(
  withAuthorization(['venueUsers', 'read']),
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
)(VenueUsersComponent);
