export const FETCH_TRANSACTIONS_REQUEST = 'transactions/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'transactions/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'transactions/FETCH_TRANSACTIONS_FAILURE';

export const FETCH_TRANSACTION_REQUEST = 'transactions/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'transactions/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'transactions/FETCH_TRANSACTION_FAILURE';

export const REFUND_TRANSACTION_REQUEST = 'transactions/REFUND_TRANSACTION_REQUEST';
export const REFUND_TRANSACTION_SUCCESS = 'transactions/REFUND_TRANSACTION_SUCCESS';
export const REFUND_TRANSACTION_FAILURE = 'transactions/REFUND_TRANSACTION_FAILURE';
