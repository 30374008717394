// @flow

import React, { Component } from 'react';

import { PlaceholderComponent, TileComponent, Typography } from 'app/shared';

export class TransactionsMunicipalTilePlaceholder extends Component /*:: <any>*/ {
  render() {
    return (
      <TileComponent>
        <h3>
          <PlaceholderComponent width={60} height={27} />
        </h3>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.ISSUER" />
            <PlaceholderComponent width={160} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.SERVICE_COUPON_TYPE" />
            <PlaceholderComponent width={180} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.SERVICE_PRODUCED" />
            <PlaceholderComponent width={160} height={15} />
          </div>

          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.DATE" />
            <PlaceholderComponent width={80} height={15} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Typography type="bodyText" tag="small" color="greyDark" id="TRANSACTIONS.AMOUNT" />
            <PlaceholderComponent width={50} height={18} color="green" />
          </div>
        </div>
      </TileComponent>
    );
  }
}
