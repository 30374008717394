import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ROUTES } from 'app/constants';
import { PageHeaderComponent } from 'app/core';
import { ButtonComponent, GroupComponent, PlaceholderComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';

/*:: import type { Props } from './coupons-create-placeholder.component.types';*/
import styles from 'app/coupons/create/form/customers/coupons-create-form-customers.module.scss';

export class CouponsCreatePlaceholderComponent extends PureComponent /*:: <Props>*/ {
  render() {
    const columnCustomerClassNames = classNames('col-12', 'col-md-6', 'col-xl', 'pr-xl-0', {
      'col-lg-3': !this.props.displayNameInput && this.props.sidebarCollapsed,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <>
        <PageHeaderComponent title="COUPONS.CREATE.TITLE" backLink={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT} />
        <CardComponent>
          <CardHeaderComponent title="COUPONS.CREATE.CUSTOMERS" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className={styles.container}>
              <div className={columnCustomerClassNames}>
                <GroupComponent
                  label={this.props.intl.formatMessage({
                    id: 'COUPONS.CREATE.LABEL.DATE_OF_BIRTH',
                  })}
                  required
                >
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className={columnCustomerClassNames}>
                <GroupComponent
                  label={this.props.intl.formatMessage({
                    id: 'COUPONS.CREATE.LABEL.GENDER',
                  })}
                  required
                >
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className={columnCustomerClassNames}>
                <GroupComponent
                  label={this.props.intl.formatMessage({
                    id: 'COUPONS.CREATE.LABEL.DECISION_NUMBER',
                  })}
                  required
                >
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className={columnCustomerClassNames}>
                <GroupComponent
                  label={this.props.intl.formatMessage({
                    id: 'COUPONS.CREATE.LABEL.INDIVIDUAL_VALUE',
                  })}
                >
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
            </div>
            <SeparatorComponent />
            <div>
              <ButtonComponent theme="secondary-outline" disabled>
                <FormattedMessage id="COUPONS.CREATE.ADD_ROW" />
              </ButtonComponent>
            </div>
          </CardBodyComponent>
        </CardComponent>
        <SeparatorComponent />
        <CardComponent>
          <CardHeaderComponent title="COUPONS.CREATE.DETAILS" />
          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <div className="col-12">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.CREATE.LABEL.VALID' })}>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className={columnClassNames}>
                <GroupComponent required label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.VALUE' })}>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
              <div className="col-lg-6">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.PRICE_UNIT' })}>
                  <PlaceholderComponent height={40} radius={8} color="light" />
                </GroupComponent>
              </div>
            </div>
            <div className="row">
              <div className="col-6 my-4">
                <PlaceholderComponent height={40} radius={8} color="light" />
              </div>
              <div className="col-lg-12">
                <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.TYPES.LABEL.ADDITIONAL' })}>
                  <PlaceholderComponent height={100} radius={8} color="light" />
                </GroupComponent>
              </div>
            </div>
          </CardBodyComponent>
          <CardFooterComponent>
            <ButtonComponent theme="primary-outline" to={ROUTES.SERVICE_VOUCHER_TYPES_DEFAULT}>
              <i className="fi fi-go-back-left-arrow" /> <FormattedMessage id="CORE.CANCEL" />
            </ButtonComponent>
            <ButtonComponent disabled className="ml-auto">
              <FormattedMessage id="CORE.CONTINUE_TO_CONFIRMATION" />
            </ButtonComponent>
          </CardFooterComponent>
        </CardComponent>
      </>
    );
  }
}
