// @flow
import isEqual from 'lodash/isEqual';

/*:: import type { Business } from 'app/types/business.types';*/

export function isBusinessDifferent(
  { business: prevBusiness } /*: { business: Business }*/,
  { business: nextBusiness } /*: { business: Business }*/,
) {
  return !isEqual(prevBusiness, nextBusiness);
}
