// @flow

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PricelistsManageContainer } from './manage/pricelists-manage.container';
import { VenueContractsContainer } from './approve/venue-contracts.container';

/*:: import type { Props } from './pricelists.component.types';*/

export const PricelistsComponent = (props /*: Props*/) => {
  return (
    <Switch>
      <Route exact path={`${props.match.url}/:id`} component={VenueContractsContainer} />
      <Route path={props.match.url} component={PricelistsManageContainer} />
    </Switch>
  );
};
