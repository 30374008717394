// @flow

import React, { Component } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

/*:: import type { Props } from './transactions-municipal-beneficiary-table.placeholder.types';*/

const headers = [
  { content: 'TRANSACTIONS.DATE' },
  { content: 'TRANSACTIONS.TRANSACTION_TYPE' },
  { content: 'TRANSACTIONS.SERVICE_COUPON' },
  { content: 'TRANSACTIONS.PRODUCED_SERVICES' },
  { content: 'TRANSACTIONS.DEBTOR_CREDITOR' },
  { content: 'TRANSACTIONS.AMOUNT', numerical: true },
];

export class TransactionsMunicipalBeneficiaryTablePlaceholder extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map((_, transaction) => (
            <TableRowComponent key={transaction}>
              <TableCellComponent>
                <PlaceholderComponent width={90} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 160 : 100} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 100 : 160} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={75} color="success" />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
