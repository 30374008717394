// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { TransactionDetailsModalPlaceholder } from './placeholder/transaction-details-modal.placeholder';

import { AlertComponent, ButtonComponent, Typography } from 'app/shared';

import { checkPermissions, currencyFormatter, getAmountColor } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './transaction-details-modal.component.types';*/
import styles from './transaction-details-modal.module.scss';

export class TransactionDetailsModalComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchTransaction(this.props.match.params.transaction);
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (prevProps.match.params.transaction !== this.props.match.params.transaction) {
      this.props.fetchTransaction(this.props.match.params.transaction);
    }
  }

  modalEntry = (label /*: string*/, value /*: any*/, link /*: ?string*/) =>
    !!value && (
      <div className={classNames('col-6', styles.entry)}>
        <div className={styles.label}>{this.props.intl.formatMessage({ id: label })}</div>
        <div>{!!link ? <Link to={link}>{value}</Link> : value}</div>
      </div>
    );

  render() {
    const state = this.props.location.state || {};

    if (this.props.loading) {
      return <TransactionDetailsModalPlaceholder styles={styles} />;
    }

    const amountClassNames = classNames(styles.amount, {
      [styles.income]: this.props.transaction.amount >= 0,
      [styles.expense]: this.props.transaction.amount < 0,
    });

    const typeClassNames = classNames(styles.type, {
      [styles.positive]: this.props.transaction.amount > 0,
      [styles.negative]: this.props.transaction.amount < 0,
    });

    return (
      <div className={styles.container}>
        {(!!state.success || !!state.error) && (
          <AlertComponent type={state.success ? 'success' : 'failure'}>
            <FormattedMessage id={state.success ? 'TRANSACTIONS.REFUND_SUCCESS' : 'TRANSACTIONS.REFUND_ERROR'} />
          </AlertComponent>
        )}
        <div className={styles.header}>
          <Typography type="largeText">
            {this.props.intl.formatMessage({ id: 'TRANSACTIONS.NUMBER' })} #{this.props.transaction.id}
          </Typography>
          <span className={typeClassNames}>
            {this.props.intl.formatMessage({ id: `TRANSACTION_TYPE.${this.props.transaction.type}` })}
          </span>
        </div>
        <div className={styles.date}>
          <Typography type="subtitle" color="grey">
            {dateFormatter(this.props.transaction.date)}
          </Typography>
        </div>
        <div className={styles.body}>
          <div className="row">
            {this.modalEntry('TRANSACTIONS.DEBTOR', this.props.transaction.debitor)}
            {this.modalEntry('TRANSACTIONS.CREDITOR', this.props.transaction.creditor)}
            {this.modalEntry(
              'TRANSACTIONS.ITEM',
              this.props.intl.formatMessage({ id: `PAYMENT.ITEM.${this.props.transaction.item}` }),
            )}
            {this.modalEntry('TRANSACTIONS.MUNICIPAL_ACCOUNT', this.props.transaction.municipalAccount)}

            {this.modalEntry('TRANSACTIONS.SERVICE_COUPON_TYPE', this.props.transaction.couponType)}
            {this.modalEntry('TRANSACTIONS.SERVICE_DECISION_VALUE', this.props.transaction.decision)}

            {this.modalEntry('TRANSACTIONS.REFERENCE', this.props.transaction.referenceId)}
            {this.props.transaction.serviceProducedStart &&
              this.props.transaction.serviceProducedEnd &&
              this.modalEntry(
                'TRANSACTIONS.SERVICE_PRODUCED',
                // prettier-ignore
                `${dateFormatter((((((this.props.transaction.serviceProducedStart/*: any*/))/*: string*/))))} - ${dateFormatter(
                  (((((this.props.transaction.serviceProducedEnd/*: any*/))/*: string*/))),
                )}`,
              )}
            {this.props.transaction.refunds &&
              this.modalEntry(
                'TRANSACTIONS.REFUNDS',
                (!!this.props.match.params.account && (
                  <Link
                    to={`${this.props.pathname}/${this.props.match.params.account}/${this.props.transaction.refunds}`}
                  >
                    {this.props.transaction.refunds}
                  </Link>
                )) || (
                  <Link to={`${this.props.pathname}/${this.props.transaction.refunds}`}>
                    {this.props.transaction.refunds}
                  </Link>
                ),
              )}

            {this.props.transaction.priceUnit &&
              this.modalEntry(
                'TRANSACTIONS.PRICE_UNIT',
                this.props.intl.formatMessage({ id: `COUPONS.TYPES.UNITS.${this.props.transaction.priceUnit}` }),
              )}
            {this.modalEntry('TRANSACTIONS.NUMBER_OF_PRICE_UNITS', this.props.transaction.numberOfPriceUnits)}
            {this.props.transaction.refundedBy &&
              this.modalEntry(
                'TRANSACTIONS.REFUNDED_BY',
                (this.props.match.params.account && (
                  <Link
                    to={`${this.props.pathname}/${this.props.match.params.account}/${this.props.transaction.refundedBy}`}
                  >
                    {this.props.transaction.refundedBy}
                  </Link>
                )) || (
                  <Link to={`${this.props.pathname}/${this.props.transaction.refundedBy}`}>
                    {this.props.transaction.refundedBy}
                  </Link>
                ),
              )}

            {this.props.transaction.settlement &&
              this.modalEntry('TRANSACTIONS.SETTLEMENT_DATE', dateFormatter(this.props.transaction.settlement))}
            {this.props.transaction.vatRate &&
              this.modalEntry(
                'TRANSACTIONS.VAT',
                this.props.intl.formatMessage({ id: `COUPONS.CHARGE.VAT_RATES.${this.props.transaction.vatRate}` }),
              )}
          </div>
          <div className={classNames('py-3 mt-2', styles.amountWrapper)}>
            <Typography color="greyDark" id="TRANSACTIONS.AMOUNT" />
            <Typography
              className={amountClassNames}
              {...getAmountColor(this.props.transaction.amount)}
              type="largeText"
            >
              {currencyFormatter.format(Math.abs(this.props.transaction.amount))}
            </Typography>
          </div>
          <div className="row">
            <div className="col-12 col-md d-flex">
              {this.props.transaction.municipalAccount &&
                checkPermissions(this.props.permissions, ['serviceCouponDetails', 'read']) && (
                  <ButtonComponent theme="primary-outline" to={`/coupons/${this.props.transaction.municipalAccount}`}>
                    <i className="fi fi-open" />
                    <FormattedMessage id="TRANSACTIONS.OPEN_VOUCHER" />
                  </ButtonComponent>
                )}
              {checkPermissions(this.props.permissions, ['municipalTransactions', 'update']) &&
                this.props.transaction.refundable &&
                !this.props.transaction.refundedBy && (
                  <ButtonComponent
                    theme="failure"
                    className="mx-3"
                    onClick={() => this.props.onRefund()}
                    loading={this.props.transaction.refunding}
                  >
                    <FormattedMessage id="TRANSACTIONS.REFUND" />
                  </ButtonComponent>
                )}
              <ButtonComponent className="ml-auto" onClick={() => this.props.onClose()}>
                <FormattedMessage id="CORE.CLOSE" />
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
