import { connect } from 'react-redux';

import { AgreementsListComponent } from './agreements-list.component';

const mapStateToProps = ({
  users: {
    current: { terms },
  },
}) => ({
  terms,
});

export const AgreementsListContainer = connect(mapStateToProps)(AgreementsListComponent);
