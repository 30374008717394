import { get, isNumber } from 'lodash';
import { PriceDetails, Voucher } from '../../../types/voucher';
import {
  elderlyAndVeteranHousingServiceAreas,
  hasHousingServiceProducedServiceRule,
  HousingServicesProducedService,
  HousingServicesServiceAreas,
  isHousingServicesServiceAreas,
  ServiceArea,
} from '../service-types';
import { isEnum } from './type-helpers';

export type HousingServicesVoucher = Omit<
  Voucher,
  'descriptiveServices' | 'priceDetails' | 'unitCost' | 'serviceArea' | 'venueId'
> & {
  descriptiveServices: HousingServicesProducedService[];
  serviceArea: HousingServicesServiceAreas;
  unitCost: number;
  venueId: number;
  priceDetails: PriceDetails;
  firstAllowedDateOfService: string;
  lastAllowedDateOfService: string;
};

function validateHousingServicesVoucherFieldTypes(voucher: Voucher): voucher is HousingServicesVoucher {
  const { descriptiveServices, serviceArea } = voucher;
  // This part of the validation only narrows field types and could be replaced by io-ts
  return (
    !!descriptiveServices &&
    descriptiveServices.every(isEnum(HousingServicesProducedService)) &&
    !!serviceArea &&
    isHousingServicesServiceAreas(serviceArea) &&
    isNumber(voucher.unitCost) &&
    isNumber(voucher.venueId) &&
    !!voucher.priceDetails &&
    !!voucher.firstAllowedDateOfService &&
    !!voucher.lastAllowedDateOfService
  );
}

function validateHousingServicesVoucherBusinessRules(voucher: HousingServicesVoucher) {
  const { venueId, unitCost } = voucher;

  if (!venueId || !unitCost) {
    throw new Error('Voucher is missing required fields');
  }

  const mealPriceForTargetedVenueInEuroCents = get(voucher, ['priceDetails', venueId, 'mealPrice']);
  const dailyPriceInEuroCents = unitCost * 100;

  // FIXME: how to avoid this unknown?
  const hasValidMealPrice = (elderlyAndVeteranHousingServiceAreas as unknown as ServiceArea[]).includes(
    voucher.serviceArea,
  )
    ? !!mealPriceForTargetedVenueInEuroCents && mealPriceForTargetedVenueInEuroCents <= dailyPriceInEuroCents
    : true;

  const rulesExistsForAllProducedServices = voucher.descriptiveServices
    .map((producedService) => hasHousingServiceProducedServiceRule(voucher.serviceArea, producedService))
    .every(Boolean);

  return !!voucher.descriptiveServices?.length && rulesExistsForAllProducedServices && hasValidMealPrice;
}

export function isHousingServicesVoucher(voucher: Voucher): voucher is HousingServicesVoucher {
  return validateHousingServicesVoucherFieldTypes(voucher) && validateHousingServicesVoucherBusinessRules(voucher);
}
