// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  fetchVenueContracts,
  filterVenueContracts,
  refreshVenueContracts,
} from 'app/redux/pricelists/pricelists.actions';

import { VenueContractsComponent } from './venue-contracts.component';
import { reduxForm } from 'redux-form';
import { withAuthorization } from 'app/common';
/*:: import type { dispatchProps, mapStateProps, Props } from './venue-contracts.component.types';*/

const mapStateToProps = ({
  router: {
    prevLocation: { pathname },
    isInitialRoute,
  },
  pricelists: {
    details: { isLoading, venue },
  },
}) /*: mapStateProps*/ => {
  return {
    prevLocation: pathname,
    name: venue?.name,
    contracts: venue?.contracts,
    initialValues: { displayedContracts: venue?.filteredContracts ?? venue?.contracts },
    isLoading: isLoading || !venue,
    isInitialRoute,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id: venueId },
    },
  },
) /*: dispatchProps*/ => ({
  fetchVenueContracts: () => dispatch(fetchVenueContracts(venueId)),
  refreshVenueContracts: () => dispatch(refreshVenueContracts(venueId)),
  filterVenueContracts: serviceTypeGroup => dispatch(filterVenueContracts(serviceTypeGroup)),
});

export const VenueContractsContainer /*: React$StatelessFunctionalComponent<Props>*/ = compose(
  withAuthorization(['contracts', 'update']),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'venueContracts', enableReinitialize: true }),
)(VenueContractsComponent);
