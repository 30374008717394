import { RSAA } from 'redux-api-middleware';
import { configuration } from 'configuration';
const prefixesExcludedFromAPI = ['/i18n/', 'http://', 'https://'];

const prepareEndpoint = endpoint => {
  if (prefixesExcludedFromAPI.some(prefix => endpoint.startsWith(prefix))) {
    return endpoint;
  } else {
    return configuration.apiEndpoint + endpoint;
  }
};

export const endpointMiddleware = () => next => action => {
  const apiMiddleware = action[RSAA];

  if (!apiMiddleware) {
    return next(action);
  }

  return next({
    [RSAA]: {
      ...apiMiddleware,
      endpoint: prepareEndpoint(apiMiddleware.endpoint),
    },
  });
};
