//@flow

import React from 'react';
import { Field } from 'redux-form';
import isObject from 'lodash/isObject';

import { SeparatorComponent } from 'app/shared';
import { PricelistTypeEnum } from 'app/constants';
import { PriceListLinkComponent } from './price-list-link/price-list-link.component';
import { PriceListTableComponent } from './price-list-table/price-list-table.component';
import { PriceListTextComponent } from './price-list-text/price-list-text.component';

/*:: import type { Props, RenderPriceListType } from './price-list.component.type';*/
/*:: import type { ComponentType } from 'react';*/

const getEditable = (editable, key) => {
  if (isObject(editable)) {
    return editable[key] || false;
  }

  return editable;
};

const RenderPriceList /*: ComponentType<RenderPriceListType>*/ = field => {
  const {
    input: { value },
    fieldName,
    editable,
    ...props
  } = field;

  switch (value) {
    case PricelistTypeEnum.Table:
      return (
        <Field
          component={PriceListTableComponent}
          {...props}
          name={fieldName}
          editable={getEditable(editable, 'table')}
        />
      );
    case PricelistTypeEnum.Text:
      return <PriceListTextComponent {...props} name={fieldName} editable={getEditable(editable, 'text')} />;
    case PricelistTypeEnum.Link:
      return <PriceListLinkComponent {...props} name={fieldName} editable={getEditable(editable, 'link')} />;
    default:
      return null;
  }
};

export const PriceListComponent = (props /*: Props*/) => {
  const { name, isLast, ...otherProps } = props;

  return (
    <>
      <Field name={`${name}.type`} component={RenderPriceList} {...otherProps} fieldName={name} />
      {!isLast && <SeparatorComponent />}
    </>
  );
};
