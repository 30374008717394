// @flow

import React from 'react';
import classNames from 'classnames';
import { Typography } from 'app/shared';

/*:: import type { Props } from './summary-field.component.types';*/
import styles from './summary-field.module.scss';

export const SummaryFieldComponent = (props /*: Props*/) => {
  const summaryFieldClassNames = classNames(styles.field, props.className, {
    [styles.equalFormHeights]: props.equalFormHeights,
  });

  return (
    <Typography color="greyDark" className={summaryFieldClassNames}>
      {props.children}
    </Typography>
  );
};
