// @flow

import moment from 'moment';

import { DATE_VALUE_FORMAT } from 'app/constants';

/*:: import type { Transfer } from 'app/coupons/details/transfers/coupons-details-transfers.component.types';*/

// TODO On mass/charge page backend returns values in
// coupon.transfers[].expires with 30 days added.
// this need to be investigated and fixed if possible
// because on single charge expires dont have that 30 days added to it.
// 30 days are added because of business logic:
// User can charge up to 30 days after coupon is expired

export const getBalance = (
  transfers /*: Transfer[]*/,
  serviceProducedStart /*: string*/,
  serviceProducedEnd /*: string*/,
  fixExpiryDate /*: boolean*/ = false,
) =>
  transfers.reduce((current, next) => {
    const today = moment();
    const starts = moment(next.starts);
    const expires = moment(next.expires).subtract(fixExpiryDate ? 30 : 0, 'days');
    //TODO: refactor - extract this conditions into separate function (similar logic in mass-charge-form and coupons-details-charge-form)
    const isServiceStartCorrect = moment(serviceProducedStart).isBetween(starts, expires, 'day', '[]');
    const isServiceEndCorrect = moment(serviceProducedEnd).isBetween(starts, expires, 'day', '[]');
    const isBeforeDeadline = today.isBetween(starts, expires.add(30, 'days').format(DATE_VALUE_FORMAT), 'day', '[]');
    return current + (isServiceStartCorrect && isServiceEndCorrect && isBeforeDeadline ? next.balance : 0);
  }, 0);
