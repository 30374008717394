// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { Typography } from 'app/shared';

/*:: import type { Props } from './table-cell.component.types';*/
import styles from './table-cell.module.scss';

const defaultTypographyProps = {
  body: {
    type: 'bodyText',
    color: 'greyDark',
    tag: 'td',
  },

  header: {
    type: 'smallText',
    color: 'grey',
    tag: 'th',
  },

  footer: {
    type: 'bodyTextBold',
    color: 'grey',
    tag: 'th',
  },
};

export class TableCellComponent extends Component /*:: <Props>*/ {
  static defaultProps = {
    variant: 'body',
    typographyProps: {},
  };

  render() {
    const { align, className, colSpan, content, variant } = this.props;

    const cellClassName = classNames(styles.cell, styles[variant], className, align && `text-${align}`);

    const typographyProps = { ...defaultTypographyProps[variant], ...this.props.typographyProps };

    return (
      <Typography colSpan={colSpan} id={content && content} {...typographyProps} className={cellClassName}>
        {this.props.children}
      </Typography>
    );
  }
}
