//@flow
import React, { useState } from 'react';
import classNames from 'classnames';

import { LoadingComponent } from 'app/shared/loading/loading.component';
import { useIsMounted } from 'app/hooks';

import styles from './sidebar-tab.module.scss';

/*:: import type { Props } from './sidebar-tab.component.types';*/

export const SidebarTabButton = (props /*: Props*/) => {
  const isMounted = useIsMounted();

  const { index, tab, sidebar, activeTab, sidebarOpen, sidebarClose, setActiveTab } = props;
  const [loading, setLoading] = useState(false);

  const { isOpen } = sidebar;
  const { label, icon, onClick: tabOnClick } = tab;

  const disabled = tab.disabled || loading;

  const tabClassNames = classNames(styles.tab, {
    [styles.cursorPointer]: !disabled,
    [styles.tabDisabled]: disabled,
    [styles.tabActive]: !disabled && activeTab === index,
  });

  const handleOnTabClick = index => {
    if (disabled) {
      return null;
    }

    if (typeof tabOnClick === 'function') {
      const result = tabOnClick();

      if (result instanceof Promise) {
        setLoading(true);
        result.finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
      }

      return null;
    } else if (!isOpen) {
      sidebarOpen();
    } else if (activeTab === index) {
      sidebarClose();
    }

    setActiveTab(index);
  };

  const iconClassNames = classNames('fi', icon);

  // TODO: onClick should be raised on the parent component level since the same behavior occurs whether label or icon is clicked.
  // TODO: It should be the parent component that is marked as a button and not the child elements.
  return (
    <div className={tabClassNames} key={index} role="button" onClick={() => handleOnTabClick(index)}>
      <div className={styles.tabLabel}>
        {label}
      </div>
      {icon && (
        <div className={styles.tabIcon}>
          {loading ? (
            <div className={icon}>
              <LoadingComponent isLoading={loading} overflow />
            </div>
          ) : (
            <i className={iconClassNames} />
          )}
        </div>
      )}
    </div>
  );
};
