// @flow

export const columnsName = {
  balance: 'balance',
  createdAt: 'createdAt',
  dateOfBirth: 'dateOfBirth',
  decisionNumber: 'decisionNumber',
  gender: 'gender',
  id: 'id',
  issuer: 'issuer',
  lastDeposit: 'lastDeposit',
  maxTransaction: 'maxTransaction',
  oneTimeLimitationInput: 'oneTimeLimitationInput',
  reference: 'reference',
  totalDeposit: 'totalDeposit',
  type: 'type',
  unitCost: 'unitCost',
  validity: 'validity',
  valueAmountInput: 'valueAmountInput',
  personalIdentificationCode: 'personalIdentificationCode',
};
