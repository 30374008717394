// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import isObject from 'lodash/isObject';

import { useIsMounted } from 'app/hooks';

/*:: import type { Props } from './button.component.types';*/
import styles from './button.module.scss';

export const ButtonComponent = (props /*: Props*/) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  const children = React.Children.toArray(props.children);
  const hasLeftIcon = !!children[0] && children[0]['type'] === 'i';
  const hasRightIcon = !!children[children.length - 1] && children[children.length - 1]['type'] === 'i';

  const { theme, disabled, size, noWrap, onClick, id, className, grouped } = props;

  const loading = props.loading || isLoading;

  const buttonClassNames = classNames('btn', `btn-${theme}`, styles.button, styles[theme], className, {
    [styles.loading]: loading,
    [styles.disabled]: disabled,
    [styles[size]]: !!size,
    [styles.iconLeft]: hasLeftIcon && !hasRightIcon,
    [styles.iconRight]: hasRightIcon && !hasLeftIcon,
    [styles.textWrap]: !noWrap,
    [styles.grouped]: grouped,
  });

  const testId = props.testId ? { 'data-testid': props.testId } : {};

  if (props.to) {
    const onClick = props.disabled ? { onClick: e => e.preventDefault() } : {};

    return (
      <Link to={props.to} className={buttonClassNames} {...testId} disabled={disabled} {...onClick}>
        <div className={styles.wrapper}>{props.children}</div>
      </Link>
    );
  }

  //detect if any child is a element with `component` prop like Field
  //if yes the tag is div in otherwise button
  const Tag = children.some(element => isObject(element) && element.props.hasOwnProperty('component'))
    ? 'div'
    : 'button';

  const type = Tag === 'div' ? {} : { type: onClick ? 'button' : 'submit' };

  //if onClick is promise the button will display loading out of the box
  const executeOnClick = () => {
    if (disabled || !onClick) {
      return;
    }

    const result = onClick();

    if (result instanceof Promise) {
      setIsLoading(true);
      result.finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <Tag
      {...type}
      {...(onClick ? { onClick: executeOnClick } : {})}
      disabled={loading || disabled}
      id={id}
      className={buttonClassNames}
      {...testId}
    >
      <div className={styles.wrapper}>{props.children}</div>
    </Tag>
  );
};

ButtonComponent.defaultProps = {
  theme: 'primary',
  size: 'regular',
};
