// @flow

import React, { Component } from 'react';

import { TransactionsMunicipalBeneficiaryTablePlaceholder } from './table/transactions-municipal-beneficiary-table.placeholder';
import { TransactionsMunicipalBeneficiaryTilePlaceholder } from './tile/transactions-municipal-beneficiary-tile.placeholder';

import { PlaceholderComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ListComponent } from 'app/shared/list/list.component';

/*:: import type { Props } from './transactions-municipal-beneficiary.placeholder.types';*/

export class TransactionsMunicipalBeneficiaryPlaceholder extends Component /*:: <Props>*/ {
  static defaultProps = {
    transactions: [...new Array(5)],
  };

  render() {
    return (
      <>
        <CardHeaderComponent>
          <PlaceholderComponent width={150} />
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <ListComponent
            list={this.props.transactions}
            table={TransactionsMunicipalBeneficiaryTablePlaceholder}
            tile={TransactionsMunicipalBeneficiaryTilePlaceholder}
          />
        </CardBodyComponent>
      </>
    );
  }
}
