//@flow

import React from 'react';
import { connect } from 'react-redux';

import { businessName } from 'app/utils';
import { Typography } from 'app/shared';

// $FlowFixMe
import { ReactComponent as Hourglass } from 'assets/images/hourglass.svg';

import styles from './business-name.module.scss';

/*:: import type { Business } from 'app/types/business.types';*/

const mapStateToProps = ({ users: { business } }) => ({
  business,
});

const NameComponent = ({ business } /*: { business: Business }*/) => {
  return (
    <Typography type="smallText" color="grey" className={styles.businessName}>
      {business.isReplacedByAnotherAccount && <Hourglass className={styles.hourglassIcon} />}
      {businessName(business)}
    </Typography>
  );
};

export const BusinessNameComponent = connect(mapStateToProps)(NameComponent);
