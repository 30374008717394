import { handleActions } from 'redux-actions';
import * as types from './settlements.action-types';

const defaultState = {};

export const settlementsReducer = handleActions(
  {
    [types.FETCH_SETTLEMENTS_REQUEST]: state => ({
      ...state,
      list: undefined,
    }),
    [types.FETCH_SETTLEMENTS_SUCCESS]: (state, { payload: { data: list } }) => ({
      ...state,
      list,
    }),
  },
  defaultState,
);
