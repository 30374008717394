import { Typography } from 'app/shared';
import classNames from 'classnames';
import React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PostpayServiceVoucher } from 'v2/types/postpay';
import { ActionButton } from 'v2/utils/postpay/action-button';
import { ItemListComponent } from 'v2/utils/postpay/item-list';
import { ServiceVoucherPageLocationState } from '../service-voucher.page';
import styles from './/issued-service-vouchers-list.module.scss';

type IssuedServiceVoucherListProps = {
  vouchers: PostpayServiceVoucher[];
};

export const IssuedServiceVouchersListComponent: React.FC<IssuedServiceVoucherListProps> = ({ vouchers }) => {
  const renderVoucher = useCallback((voucher: PostpayServiceVoucher) => {
    const fields = voucher.groupedProperties.flatMap(group => group.properties);
    return (
      <div className={styles.fields}>
        {fields.map(({ label, value }, index) => (
          <React.Fragment key={label}>
            <Typography type="smallText" color="black" className={styles.fieldLabel}>
              {label}
            </Typography>
            <Typography
              type="smallText"
              color="black"
              className={classNames(styles.fieldValue, { [styles.bold]: index === 0 })}
            >
              {value}
            </Typography>
          </React.Fragment>
        ))}
      </div>
    );
  }, []);

  const history = useHistory();
  const navigateToVoucherDetails = useCallback(
    (voucher: PostpayServiceVoucher) => {
      const locationState: ServiceVoucherPageLocationState = { showBackButton: true };
      history.push(`/service-vouchers/${voucher.id}`, locationState);
    },
    [history],
  );

  const renderActions = useCallback(
    (voucher: PostpayServiceVoucher) => {
      return (
        <ActionButton
          label="SERVICE_VOUCHERS.POSTPAY.ISSUED_SERVICE_VOUCHERS.OPEN_VOUCHER"
          action={() => navigateToVoucherDetails(voucher)}
        ></ActionButton>
      );
    },
    [navigateToVoucherDetails],
  );

  return (
    <ItemListComponent
      items={vouchers}
      renderItem={renderVoucher}
      renderActions={renderActions}
      alignActionButtons="bottom"
    />
  );
};
