// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonComponent, SeparatorComponent } from 'app/shared';

import { ServicesRowContainer } from './row/services-row.container';

/*:: import type { Props, State } from './coupons-details-charge-services.types';*/

export class CouponsDetailsChargeServicesComponent extends PureComponent /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {};
  }

  toggle = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });
  onClone = (index /*: number*/) => this.props.fields.push(this.props.fields.get(index));
  onRemove = (index /*: number*/) => this.props.fields.remove(index);

  add = () => {
    this.props.fields.push({
      accountNumber: this.props.coupon.id,
      active: true,
      amountCalculations: [{ amount: 1 }],
      serviceProduced: {},
    });
  };

  rowRenderer = (field /*: string*/, index /*: number*/) => {
    const rowProps = {
      canRemove: this.props.fields.length > 1,
      coupon: this.props.coupon,
      field,
      index,
      onClone: this.onClone,
      onRemove: this.onRemove,
      toggle: this.toggle,
    };

    return <ServicesRowContainer {...rowProps} key={index} />;
  };

  render() {
    return (
      <>
        {this.props.fields.map(this.rowRenderer)}
        <SeparatorComponent />
        <ButtonComponent theme="primary-outline" onClick={this.add}>
          <i className="fi fi-add" />
          <FormattedMessage id="COUPONS.DETAILS.CHARGE.ADD_ANOTHER" />
        </ButtonComponent>
      </>
    );
  }
}
