// @flow

import { useEffect, useState } from 'react';

import { handleAnalyticsEvent } from '../../utils';

/*:: import type { Props } from './token-refresh-timer.component.types';*/

/**
 * This module is involved in application session handling. For more information see README.session-handling.md
 */

const TIMER_ERROR_DELTA = 1000;

const logoutWithAnalytics = (logout /*: Function*/) => {
  handleAnalyticsEvent('session', 'logoutDueMissedRefresh');
  return logout();
};

const refreshOrLogout = (
  now /*: number*/,
  nextSessionExpirationTime /*: number*/,
  refresh /*: Function*/,
  logout /*: Function*/,
) => {
  if (now <= nextSessionExpirationTime) {
    handleAnalyticsEvent('session', 'refresh');
    return refresh();
  } else if (now > nextSessionExpirationTime) {
    return logoutWithAnalytics(logout);
  }
};

export function TokenRefreshTimerComponent(props /*: Props*/) {
  const { nextSessionExpirationTime, refreshToken, logout } = props;
  const [refreshTimer, setRefreshTimer] = useState(null);

  // Whenever new refresh timer is set make sure that the old one is cancelled.
  useEffect(
    () => {
      return () => {
        cancelTimer();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshTimer],
  );

  // Whenever session expiration time is updated make sure that timer is set correctly.
  // If next session expiration time is not defined then there is no session and thus nothing to refresh.
  useEffect(
    () => {
      if (nextSessionExpirationTime) {
        if (hasAdequateTimeToRefresh(nextSessionExpirationTime)) {
          setTimer();
        } else {
          logoutWithAnalytics(logout);
        }
      } else {
        cancelTimer();
        setRefreshTimer(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextSessionExpirationTime],
  );

  function calculateRefreshTimeout(nextSessionExpirationTime) {
    return nextSessionExpirationTime - Date.now() - TIMER_ERROR_DELTA;
  }

  function hasAdequateTimeToRefresh(nextSessionExpirationTime) {
    return calculateRefreshTimeout(nextSessionExpirationTime) > 0;
  }

  function setTimer() {
    const refreshTimeout = calculateRefreshTimeout(nextSessionExpirationTime);
    const timerId = setTimeout(() => {
      const callbackTime = Date.now();
      refreshOrLogout(callbackTime, nextSessionExpirationTime, refreshToken, logout);
    }, refreshTimeout);

    setRefreshTimer(timerId);
  }

  function cancelTimer() {
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
  }

  return null;
}
