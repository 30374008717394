// @flow

import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import get from 'lodash/get';

import { fetchDictionary } from 'app/redux/dictionaries/dictionaries.actions';

import { CouponsDescriptiveServicesComponent } from './coupons-descriptive-service.component';

const mapStateToProps = ({ dictionaries }, { intl: { locale } }) => ({
  loading: !dictionaries['municipal/services'],
  descriptiveServices: get(dictionaries, ['municipal/services', 'descriptiveServices', locale], []),
});

const mapDispatchToProps = dispatch => ({
  fetchDictionary: () => dispatch(fetchDictionary('municipal/services', true)),
});

export const CouponsDescriptiveServiceContainer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(CouponsDescriptiveServicesComponent);
