//@flow

import {
  NOTIFICATION_POP,
  NOTIFICATION_PUSH,
  NOTIFICATION_REMOVE,
  NOTIFICATIONS_CLEAR,
} from './notifications.action-types';

//import type { NotificationsActionsType } from '../context/notifications.context.types';
/*:: import type { Notification } from 'app/notifications/notification/notification.component.types';*/

export const notificationPush = (notification /*: Notification*/) => ({
  type: NOTIFICATION_PUSH,
  payload: notification,
});
export const notificationPop = () => ({ type: NOTIFICATION_POP });
export const notificationRemove = (id /*: string*/) => ({ type: NOTIFICATION_REMOVE, payload: id });
export const notificationsClear = () => ({ type: NOTIFICATIONS_CLEAR });
