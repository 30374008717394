// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { ConnectedDateRangeComponent, ConnectedInputComponent, Typography } from 'app/shared';
import { CardBodyComponent } from 'app/shared/card-new';
import { Modal } from 'app/shared/modal-new/modal.container';
import { ButtonComponent } from 'app/shared';

import { CouponsCalculatorFinalContainer } from 'app/coupons/shared';
import { currencyFormatter, voucherValidityBounds } from 'app/utils';

/*:: import type { Props, State } from './coupons-single-renew-form.component.types';*/
/*:: import type { Coupon } from 'app/coupons/list/coupons-list.component.types';*/
import styles from './coupons-single-renew-form.module.scss';

export class CouponsSingleRenewFormComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isCalendarOpened: false,
      isConfirmationModalOpen: false,
      initialValues: this.props.initialValues,
    };
  }

  toggleCalendar = (state /*: any*/) => this.setState({ isCalendarOpened: !!state });

  toggleConfirmationModal = () => {
    this.setState(prevState => ({
      isConfirmationModalOpen: !prevState.isConfirmationModalOpen,
    }));
  };

  handleOnSubmit = async (values /*: { coupons: Coupon[] }*/) => {
    if (!this.state.isConfirmationModalOpen) {
      return this.toggleConfirmationModal();
    }
    return await this.props.onSubmit(values, null, this.toggleConfirmationModal);
  };

  render() {
    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    return (
      <Form onSubmit={this.handleOnSubmit} initialValues={this.state.initialValues} mutators={arrayMutators}>
        {({ handleSubmit, form, submitting }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-4">
                  <ConnectedDateRangeComponent
                    field={Field}
                    name="coupons[0].valid"
                    startDateKey="from"
                    endDateKey="to"
                    label="COUPONS.DETAILS.RENEW.LABEL.VALID"
                    onFocusChange={this.toggleCalendar}
                    required
                    daysCounter
                    className={styles.datesField}
                    bounds={voucherValidityBounds}
                  />
                </div>

                <div className="col-12 col-lg-6 col-xl-2">
                  <Field
                    name="coupons[0].maxTransaction"
                    component={ConnectedInputComponent}
                    label="COUPONS.DETAILS.RENEW.LABEL.MAX_TRANSACTION"
                    icon="euro-symbol"
                  />
                </div>
                <div className="col-12 col-xl-3">
                  <CouponsCalculatorFinalContainer
                    value={{
                      name: 'coupons[0].defaultValue',
                      label: this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.RENEW.LABEL.VALUE_AMOUNT' }),
                      required: true,
                      values: {
                        maxPrice: this.props.coupon.maxTransaction,
                      },
                    }}
                    calculations="coupons[0].defaultValueCalculations"
                    form={form}
                  />
                </div>

                <div className="col-12 col-xl-3">
                  <ButtonComponent theme="secondary" className={styles.submit} loading={submitting}>
                    {this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.RENEW.SUBMIT' })}
                  </ButtonComponent>
                </div>
              </div>
              <div className={backdropClassNames} />
            </form>

            <FormSpy
              subscribtion={{
                submitting: true,
                values: true,
              }}
            >
              {({ values = {}, submitting = false }) => {
                const defaultValue = values.coupons[0].defaultValue;
                const amount = !!defaultValue ? defaultValue.toString().replace(',', '.') : 0;

                const confirmOptions = [
                  {
                    label: this.props.intl.formatMessage({ id: 'CORE.CLOSE' }),
                    onClick: this.toggleConfirmationModal,
                    theme: 'secondary-outline',
                    arrowBack: true,
                  },
                  {
                    label: this.props.intl.formatMessage({ id: 'CORE.CONFIRM' }),
                    loading: submitting,
                    onClick: form.submit,
                  },
                ];

                return (
                  this.state.isConfirmationModalOpen && (
                    <Modal confirmOptions={confirmOptions} isOpen title="COUPONS.RENEW.CONFIRM">
                      <CardBodyComponent>
                        <FormattedMessage
                          id="COUPONS.RENEW.CONFIRM_BODY.MESSAGE"
                          tagName="span"
                          values={{
                            vouchers: (
                              <Typography tag="strong" color="primary">
                                {this.props.intl.formatMessage(
                                  { id: 'COUPONS.RENEW.CONFIRM_BODY.SERVICE_VOUCHERS' },
                                  { amount: 1 },
                                )}
                              </Typography>
                            ),
                            sum: (
                              <Typography tag="strong" color="primary">
                                {currencyFormatter.format(amount)}
                              </Typography>
                            ),
                          }}
                        />
                      </CardBodyComponent>
                    </Modal>
                  )
                );
              }}
            </FormSpy>
          </>
        )}
      </Form>
    );
  }
}
