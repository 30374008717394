// @flow
import get from 'lodash/get';
import includes from 'lodash/includes';

import { sessionExpired } from 'app/redux/users/users.actions';
import { reportMessage } from 'app/sentry/sentry';
import { crashApplicationUsingConnectedRouter, handleAnalyticsEvent } from 'app/utils';

/*:: import type { Middleware, Store } from 'redux';*/
/*:: import { RouterState } from 'connected-react-router'; */

const MISSING_OR_UNKNOWN_TOKEN_MESSAGE = 'Authentication Required';
const EXPIRED_TOKEN_MESSAGE = 'Token has expired';

export const sessionMiddleware /*: Middleware*/ = store => next => action => {
  const isError = action.error && action.payload instanceof Error;
  if (!isError) {
    return next(action);
  }

  const is403Response = 403 === action.payload.status;
  if (!is403Response) {
    return next(action);
  }

  // When the app starts, it does an initial request to fetch user data. This
  // request also serves to check if we still have a valid session.
  // A status 403 response to this initial request does not need to be handled by this middleware.
  if (isMissingOrExpiredTokenResponseToInitialFetchUserRequest(action)) {
    return next(action);
  }

  const errorMessage = get(action, 'payload.response.message', undefined);
  switch (errorMessage) {
    case MISSING_OR_UNKNOWN_TOKEN_MESSAGE:
      handleMissingTokenResponse(action, store);
      break;
    case EXPIRED_TOKEN_MESSAGE:
      handleTokenExpiredResponse(action, store);
      break;
    default:
      handleUnexpected403Response(action, store);
  }

  return next(action);
};

function handleMissingTokenResponse(action, store /*: Store<{ router: RouterState }>*/) {
  const currentPath = store.getState().router.location.pathname;
  handleAnalyticsEvent('session', 'backendReportedMissingOrUnknownSession');
  reportMessage('Session handling: backend reported missing or unknown session token', {
    currentPath,
  });
  store.dispatch(sessionExpired(true));
}

function handleTokenExpiredResponse(action, store /*: Store<{ router: RouterState }>*/) {
  const currentPath = store.getState().router.location.pathname;
  handleAnalyticsEvent('session', 'errorDueToUnanticipatedSessionExpiration');
  reportMessage('Session handling failure: backend reported expired token', {
    currentPath,
  });
  store.dispatch(sessionExpired(false));
  crashApplicationUsingConnectedRouter(store, currentPath);
}

function handleUnexpected403Response(action, store /*: Store<{ router: RouterState }>*/) {
  const currentPath = store.getState().router.location.pathname;
  const serverPayload = action.payload;
  handleAnalyticsEvent('session', 'unexpected403Response');
  reportMessage('Unexpected status 403 response', {
    currentPath,
    serverPayload,
  });
}

function isMissingOrExpiredTokenResponseToInitialFetchUserRequest(action) {
  const errorMessage = get(action, 'payload.response.message', undefined);
  const isResponseToInitialFetchUserRequest = get(action, 'meta.isInitialFetchUserRequest', false);
  const isMissingOrExpiredTokenResponse = includes(
    [MISSING_OR_UNKNOWN_TOKEN_MESSAGE, EXPIRED_TOKEN_MESSAGE],
    errorMessage,
  );
  return isMissingOrExpiredTokenResponse && isResponseToInitialFetchUserRequest;
}
