// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AgreementsListContainer } from './list/agreements-list.container';

/*:: import type { Props } from './agreements.component.types';*/

export class AgreementsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={this.props.match.url} component={AgreementsListContainer} />
      </Switch>
    );
  }
}
