import { put, select, take, takeEvery } from 'redux-saga/effects';
import { actionTypes, getFormValues, initialize } from 'redux-form';

import isEqual from 'lodash/isEqual';

import { checkPermissions } from 'app/utils';

import { updateConfirmation } from 'app/redux/view/view.actions';

import { CHANGE_BUSINESS, FETCH_CURRENT_USER_SUCCESS } from '../users/users.action-types';
import { FETCH_PERMISSIONS_SUCCESS } from '../permissions/permissions.action-types';

import {
  APPLY_COUPONS_DRAFT,
  FETCH_COUPONS_DRAFT_SUCCESS,
  FETCH_COUPONS_SUCCESS,
  RENEW_COUPONS_SUCCESS,
} from './coupons.action-types';

import { applyCouponsDraft, clearCouponsDraft, fetchVenueCouponsMetadata } from './coupons.actions';
import { fetchAccounts } from '../accounts/accounts.actions';

export const couponsSelector = state => state.coupons;
export const permissionsSelector = state => state.permissions;
export const businessSelector = state => state.users.business.id;

export function* onFetchDraft() {
  const coupons = yield select(couponsSelector);

  //TODO add on this back to app
  if (coupons.draft) {
    yield put(
      updateConfirmation({
        onConfirm: () => Promise.resolve(put(applyCouponsDraft())),
        onCancel: () => Promise.resolve(put(clearCouponsDraft())),
        title: 'COUPONS.RENEW.DRAFT',
        shouldDispatch: true,
      }),
    );
  }
}

export function* onApplyDraftConfirmation() {
  const coupons = yield select(couponsSelector);
  const draftFilters = coupons.draft.filters;
  const filters = yield select(getFormValues('filter-renew-coupons'));

  if (isEqual(draftFilters, filters)) {
    yield* applyDraft();
  } else {
    yield put(initialize('filter-renew-coupons', draftFilters));
  }
}

export function* applyDraft() {
  let value = yield select(getFormValues('renew-coupons'));

  while (!value) {
    yield take(actionTypes.INITIALIZE);
    value = yield select(getFormValues('renew-coupons'));
  }

  const coupons = yield select(couponsSelector);
  const mergedCoupons = value.coupons.map(coupon => {
    const found = coupons.draft && coupons.draft.list.find(draftValue => draftValue.id === coupon.id);
    return {
      ...coupon,
      ...(found || {}),
    };
  });
  yield put(
    initialize(
      'renew-coupons',
      { coupons: mergedCoupons, selectAll: mergedCoupons.every(coupon => coupon.active) },
      { updateUnregistredFields: true },
    ),
  );
}

export function* onFetchCoupons() {
  const coupons = yield select(couponsSelector);
  if (coupons.draft && coupons.draft.apply) {
    yield* applyDraft();
  }
}

export function* onRenewCouponsSuccess() {
  yield put(clearCouponsDraft());
  yield put(fetchAccounts());
}

export function* getVenueCouponsMetadata() {
  const business = yield select(businessSelector);

  while (!(yield select(permissionsSelector))) {
    yield take(FETCH_PERMISSIONS_SUCCESS);
  }

  const permissions = yield select(permissionsSelector);

  if (checkPermissions(permissions[business], ['venueServiceCoupons', 'read'])) {
    yield put(fetchVenueCouponsMetadata());
  }
}

export function* couponsSaga() {
  yield takeEvery(FETCH_COUPONS_DRAFT_SUCCESS, onFetchDraft);
  yield takeEvery(FETCH_COUPONS_SUCCESS, onFetchCoupons);
  yield takeEvery(APPLY_COUPONS_DRAFT, onApplyDraftConfirmation);
  yield takeEvery(RENEW_COUPONS_SUCCESS, onRenewCouponsSuccess);
  yield takeEvery([FETCH_CURRENT_USER_SUCCESS, CHANGE_BUSINESS], getVenueCouponsMetadata);
}
