// @flow
import { useContext, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { ReduxContext } from 'app/context';
import { useIsMounted } from 'app/hooks';

export const useSelector = (selector /*: Function*/, compare /*::?: Function*/ = isEqual) => {
  const isMounted = useIsMounted();
  const { subscribe, getState } = useContext(ReduxContext);
  const [value, setValue] = useState(selector(getState()));
  const currentValue = useRef(value);

  useEffect(() => {
    currentValue.current = value;
  }, [value]);

  useEffect(
    () => {
      if (isMounted) {
        return subscribe(() => {
          const state = getState();

          const nextValue = selector(state);

          if (!compare(currentValue.current, nextValue)) {
            setValue(nextValue);
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted],
  );

  return value;
};
