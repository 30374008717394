// @flow

import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';

import { configuration } from 'configuration';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Options, Props } from './with-tracker.types';*/

let isGoogleAnalyticsInitialized = false;

function ensureGoogleAnalyticsIsInitialized() {
  if (!configuration.gaTrackingId || isGoogleAnalyticsInitialized) {
    return;
  }

  GoogleAnalytics.initialize(configuration.gaTrackingId);
  // Instruct Google Analytics to anonymize IP address
  GoogleAnalytics.set({ anonymizeIp: true });
  // Don't use existing Google Advertising cookies
  GoogleAnalytics.set({ allowAdFeatures: false });

  isGoogleAnalyticsInitialized = true;
}

export const withTracker = (WrappedComponent /*: ComponentType<any>*/, options /*: Options*/ = {}) =>
  class Tracker extends Component /*:: <Props>*/ {
    componentDidMount() {
      this.trackPage(this.props.location.pathname);
    }

    componentDidUpdate(prevProps /*: Props*/) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.props.location.pathname;

      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }

    trackPage = (page /*: string*/) => {
      ensureGoogleAnalyticsIsInitialized();
      if (!isGoogleAnalyticsInitialized) {
        return;
      }

      const { ...otherOptions } = options;

      GoogleAnalytics.set({ page, ...otherOptions });
      GoogleAnalytics.pageview(page);
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
