import React, { useEffect, useMemo, useState } from 'react';
import { FieldArray, formValueSelector, InjectedFormProps, WrappedFieldArrayProps } from 'redux-form';

import { FormattedMessage } from 'app/translations/formatted-message.component';
import { AlertComponent, ButtonComponent, NoResultsContainer, SeparatorComponent, Typography } from 'app/shared';
import { FieldArrayRenderer } from 'app/shared/form';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { PriceListComponent } from 'app/shared/price-list/price-list.component';
import { handleAnalyticsEvent } from 'app/utils';
import { useDispatch, useSelector } from 'app/hooks/redux';
import { ContractCardDetailsComponent } from 'app/pricelists/contract-card-details.component';
import { useV1Selector } from 'v2/utils/redux-utils';
import { TranslationKey } from 'v2/types/translations';
import { ContractStatus } from 'app/constants';
import { VenueContract } from 'app/types/venue';
import { PricelistSubmissionStatus } from 'app/types/pricelist';

type PriceListsRendererProps = WrappedFieldArrayProps;

const PriceListsRenderer = (props: PriceListsRendererProps) => {
  const { fields } = props;

  if (!fields.length) {
    return <NoResultsContainer title="VENUES.PRICE_LIST.NOT_EXISTS" showDesc={false} />;
  }

  return <FieldArrayRenderer {...props} />;
};

type MunicipalityContractProps = {
  contract: VenueContract;
  editMode: boolean;
  municipalityName: string;
} & InjectedFormProps;

const priceListsSelector =
  (selector: (state: Record<'string', unknown>, ...field: string[]) => unknown) => (state: Record<'string', unknown>) =>
    selector(state, 'priceLists');

export const MunicipalityContractComponent = (props: MunicipalityContractProps) => {
  const dispatch = useDispatch();

  const { pristine, handleSubmit, submitting, municipalityName, contract, form } = props;
  const { status } = contract;

  const isNewContract = status === ContractStatus.New;
  const allowDiscardChanges = !isNewContract;
  const requireEditBeforeAllowingSubmission = !isNewContract;
  const startWithContractEditable = isNewContract ? true : !!props.editMode;

  const [editMode, setEditMode] = useState(startWithContractEditable);

  const handleDiscard = () => {
    handleAnalyticsEvent('pricelists', 'discard');
    setEditMode(false);
  };

  const selector = useMemo(() => formValueSelector(form), [form]);
  const priceLists = useSelector(priceListsSelector(selector));
  const pricelistSubmissionState = useV1Selector(state => state.venues.pricelistSubmissionState);
  const { status: submissionStatus } = pricelistSubmissionState;

  useEffect(
    () => {
      //reset form to initial data after discard changes
      if (editMode === false && pristine === false) {
        dispatch(props.reset());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode],
  );

  const { editable, startEditingButtonLabelId } = useMemo<{
    editable: boolean;
    startEditingButtonLabelId: TranslationKey;
  }>(() => {
    const getStartEditingButtonLabelId = () => {
      switch (contract.allowedAction) {
        case 'EDIT_PENDING':
          return contract.status === ContractStatus.ChangesRequested
            ? 'PRICELIST.MAKE_REQUESTED_CHANGES'
            : 'PRICELIST.EDIT_PENDING';
        case 'SUBMIT_NEW':
          return 'PRICELIST.EDIT_PENDING';
        case 'PROPOSE_NEW':
          return 'PRICELIST.PROPOSE_NEW';
        default:
          return 'PRICELIST.EDIT';
      }
    };

    return {
      editable: !!contract.allowedAction,
      startEditingButtonLabelId: getStartEditingButtonLabelId(),
    };
  }, [contract]);

  return (
    <>
      <CardComponent>
        <CardHeaderComponent>
          <Typography type="largeTextBold" color="secondary">
            {municipalityName}
          </Typography>
        </CardHeaderComponent>
        <SeparatorComponent />
        <CardBodyComponent>
          <ContractCardDetailsComponent context="provider" contract={contract} />

          <SeparatorComponent />

          <Typography color="greyDark" id="PRICELIST.PRICES_HEADER" type="subtitle" />
          <SeparatorComponent />
          {submissionStatus === PricelistSubmissionStatus.Submitted &&
            pricelistSubmissionState.updatedContractId === contract.id && (
              <AlertComponent type={pricelistSubmissionState.success ? 'success' : 'failure'}>
                {pricelistSubmissionState.success ? (
                  <FormattedMessage
                    id={
                      pricelistSubmissionState.updatedContractOldStatus === ContractStatus.New
                        ? 'VENUES.PRICE_LIST.SUBMIT_SUCCESS'
                        : 'VENUES.PRICE_LIST.UPDATE_SUCCESS'
                    }
                  />
                ) : (
                  <FormattedMessage id="VENUES.PRICE_LIST.UPDATE_FAILURE" />
                )}
              </AlertComponent>
            )}
          <form onSubmit={handleSubmit} data-testid={form}>
            <FieldArray
              name="priceLists"
              component={PriceListsRenderer}
              fieldComponent={PriceListComponent}
              editMode={editMode}
              editable={editable}
            />

            {editable && !!priceLists.length && (
              <>
                <SeparatorComponent />
                <div className="text-right">
                  {contract.status === ContractStatus.ChangesRequested && (
                    <Typography
                      type="bodyText"
                      color="red"
                      id="PRICELIST.MAKE_REQUESTED_CHANGES_WARNING"
                      className="pb-4"
                    />
                  )}
                  {!editMode ? (
                    <ButtonComponent testId={`${form}.edit`} onClick={() => setEditMode(true)}>
                      <FormattedMessage id={startEditingButtonLabelId} />
                    </ButtonComponent>
                  ) : (
                    <div>
                      {allowDiscardChanges && (
                        <ButtonComponent
                          testId={`${form}.discard`}
                          className="mr-md-2"
                          theme="primary-outline"
                          onClick={handleDiscard}
                          disabled={submitting}
                        >
                          <FormattedMessage id="PRICELIST.CANCEL" />
                        </ButtonComponent>
                      )}

                      <ButtonComponent
                        disabled={pristine && requireEditBeforeAllowingSubmission}
                        loading={submitting}
                        testId={`${form}.save`}
                      >
                        <FormattedMessage id="PRICELIST.SEND" />
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              </>
            )}
          </form>
        </CardBodyComponent>
      </CardComponent>
      <SeparatorComponent />
    </>
  );
};
