export const FETCH_COUPONS_REQUEST = 'coupons/coupons/FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'coupons/coupons/FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILURE = 'coupons/coupons/FETCH_COUPONS_FAILURE';

export const SET_SCROLL_TO_ROW = 'coupons/coupons/SET_SCROLL_TO_ROW';

export const FETCH_COUPONS_METADATA_REQUEST = 'coupons/coupons/FETCH_COUPONS_METADATA_REQUEST';
export const FETCH_COUPONS_METADATA_SUCCESS = 'coupons/coupons/FETCH_COUPONS_METADATA_SUCCESS';
export const FETCH_COUPONS_METADATA_FAILURE = 'coupons/coupons/FETCH_COUPONS_METADATA_FAILURE';

export const CLEAR_COUPONS_METADATA = 'coupons/coupons/CLEAR_COUPONS_METADATA';

export const VALIDATE_COUPON_REQUEST = 'coupons/coupons/VALIDATE_COUPON_REQUEST';
export const VALIDATE_COUPON_SUCCESS = 'coupons/coupons/VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_FAILURE = 'coupons/coupons/VALIDATE_COUPON_FAILURE';

export const CREATE_COUPON_REQUEST = 'coupons/coupons/CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'coupons/coupons/CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'coupons/coupons/CREATE_COUPON_FAILURE';

export const INVALIDATE_COUPON_REQUEST = 'coupons/coupons/INVALIDATE_COUPON_REQUEST';
export const INVALIDATE_COUPON_SUCCESS = 'coupons/coupons/INVALIDATE_COUPON_SUCCESS';
export const INVALIDATE_COUPON_FAILURE = 'coupons/coupons/INVALIDATE_COUPON_FAILURE';

export const RENEW_COUPONS_REQUEST = 'coupons/coupons/RENEW_COUPONS_REQUEST';
export const RENEW_COUPONS_SUCCESS = 'coupons/coupons/RENEW_COUPONS_SUCCESS';
export const RENEW_COUPONS_FAILURE = 'coupons/coupons/RENEW_COUPONS_FAILURE';

export const REDEEM_COUPON_TRANSFER_REQUEST = 'coupons/coupons/REDEEM_COUPON_TRANSFER_REQUEST';
export const REDEEM_COUPON_TRANSFER_SUCCESS = 'coupons/coupons/REDEEM_COUPON_TRANSFER_SUCCESS';
export const REDEEM_COUPON_TRANSFER_FAILURE = 'coupons/coupons/REDEEM_COUPON_TRANSFER_FAILURE';

export const IMPORT_COUPONS_REQUEST = 'coupons/coupons/IMPORT_COUPONS_REQUEST';
export const IMPORT_COUPONS_SUCCESS = 'coupons/coupons/IMPORT_COUPONS_SUCCESS';
export const IMPORT_COUPONS_FAILURE = 'coupons/coupons/IMPORT_COUPONS_FAILURE';

export const IMPORT_COUPONS_COLUMNS_REQUEST = 'coupons/coupons/IMPORT_COUPONS_COLUMNS_REQUEST';
export const IMPORT_COUPONS_COLUMNS_SUCCESS = 'coupons/coupons/IMPORT_COUPONS_COLUMNS_SUCCESS';
export const IMPORT_COUPONS_COLUMNS_FAILURE = 'coupons/coupons/IMPORT_COUPONS_COLUMNS_FAILURE';

export const IMPORT_COUPONS_CONFIRM_REQUEST = 'coupons/coupons/IMPORT_COUPONS_CONFIRM_REQUEST';
export const IMPORT_COUPONS_CONFIRM_SUCCESS = 'coupons/coupons/IMPORT_COUPONS_CONFIRM_SUCCESS';
export const IMPORT_COUPONS_CONFIRM_FAILURE = 'coupons/coupons/IMPORT_COUPONS_CONFIRM_FAILURE';

export const CHECK_BALANCE_REQUEST = 'coupons/coupons/CHECK_BALANCE_REQUEST';
export const CHECK_BALANCE_SUCCESS = 'coupons/coupons/CHECK_BALANCE_SUCCESS';
export const CHECK_BALANCE_FAILURE = 'coupons/coupons/CHECK_BALANCE_FAILURE';

export const FETCH_COUPONS_DRAFT_REQUEST = 'coupons/coupons/FETCH_COUPONS_DRAFT_REQUEST';
export const FETCH_COUPONS_DRAFT_SUCCESS = 'coupons/coupons/FETCH_COUPONS_DRAFT_SUCCESS';
export const FETCH_COUPONS_DRAFT_FAILURE = 'coupons/coupons/FETCH_COUPONS_DRAFT_FAILURE';

export const UPDATE_COUPONS_DRAFT_REQUEST = 'coupons/coupons/UPDATE_COUPONS_DRAFT_REQUEST';
export const UPDATE_COUPONS_DRAFT_SUCCESS = 'coupons/coupons/UPDATE_COUPONS_DRAFT_SUCCESS';
export const UPDATE_COUPONS_DRAFT_FAILURE = 'coupons/coupons/UPDATE_COUPONS_DRAFT_FAILURE';

export const CLEAR_COUPONS_DRAFT_REQUEST = 'coupons/coupons/CLEAR_COUPONS_DRAFT_REQUEST';
export const CLEAR_COUPONS_DRAFT_SUCCESS = 'coupons/coupons/CLEAR_COUPONS_DRAFT_SUCCESS';
export const CLEAR_COUPONS_DRAFT_FAILURE = 'coupons/coupons/CLEAR_COUPONS_DRAFT_FAILURE';

export const APPLY_COUPONS_DRAFT = 'coupons/coupons/APPLY_COUPONS_DRAFT';

export const SHARE_COUPON_SMS_REQUEST = 'coupons/coupons/SHARE_COUPON_SMS_REQUEST';
export const SHARE_COUPON_SMS_SUCCESS = 'coupons/coupons/SHARE_COUPON_SMS_SUCCESS';
export const SHARE_COUPON_SMS_FAILURE = 'coupons/coupons/SHARE_COUPON_SMS_FAILURE';
