// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlaceholderComponent, TileComponent } from 'app/shared';

export class CouponsDetailsTransactionsTilePlaceholder extends PureComponent /*:: <any>*/ {
  render() {
    return (
      <TileComponent>
        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CHARGED" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.TYPE" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.CREDITOR" tagName="small" />
            <PlaceholderComponent width={140} height={18} />
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.PRODUCED" tagName="small" />
            <PlaceholderComponent width={120} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.AMOUNT" tagName="small" />
            <PlaceholderComponent width={80} height={18} />
          </div>

          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.DESCRIPTIVE_SERVICE" tagName="small" />
            <PlaceholderComponent width={100} height={18} />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="COUPONS.TRANSACTIONS.HEADERS.PRICE_UNITS" tagName="small" />
            <PlaceholderComponent width={60} height={18} />
          </div>
        </div>
      </TileComponent>
    );
  }
}
