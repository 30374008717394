import React from 'react';
import classNames from 'classnames';

import { ContractStatus, ContractSubStatus, StatusEnum, VenueStatus } from 'app/constants';

/*:: import type { Props } from './status.component.types';*/
import styles from './status.module.scss';

export type StatusProps = {
  status: ContractStatus | ContractSubStatus | VenueStatus;
  size?: 'small' | 'medium';
  className?: string;
  icons?: Record<string, string>;
  iconSet?: 'provider' | 'organizer';
};

export function StatusComponent({ status, className, size = 'medium', icons = {}, iconSet }: StatusProps) {
  const defaultIcons = {
    [StatusEnum.New]: 'check',
    [ContractStatus.Locked]: 'alert',
    [StatusEnum.Approved]: 'check',
    [StatusEnum.Deleted]: 'declined',
    [StatusEnum.Pending]: 'alert',
    [VenueStatus.ChangesRequested]: 'alert',
    [VenueStatus.PendingAfterChangesRequested]: 'alert',
    [VenueStatus.ChangesNotMadeInTime]: 'alert',
  };

  const icon = { ...defaultIcons, ...icons }[status];

  const iconClassNames = classNames(
    styles[status.toLowerCase()],
    iconSet ? styles[iconSet] : undefined,
    styles[size],
    'fi',
    className,
    `fi-${icon}`,
  );

  return <i className={iconClassNames} />;
}
