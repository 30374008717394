// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Field } from 'redux-form';

import { ConnectedInputComponent, ConnectedToggleComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardBodyComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { ButtonComponent } from 'app/shared';

import { useIntl } from 'app/hooks';

/*:: import type { Props } from './venue-administrator.component.types';*/
import styles from './venue-administrator.module.scss';

export const VenueAdministratorComponent = (props /*: Props*/) => {
  const intl = useIntl();

  const columnClassNames = classNames('col-12', 'col-xl-6', {
    'col-lg-6': props.sidebarCollapsed,
  });

  return (
    <>
      {props.fields.map((field, index) => (
        <div key={index}>
          {props.fillable && (
            <div className={styles.info}>
              {index === 0 && (
                <>
                  <Typography tag="h4" color="secondary" type="largeText">
                    <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_ADMIN" />
                  </Typography>
                  <SeparatorComponent />
                  <Typography type="subtitle" color="grey">
                    <FormattedMessage id="REGISTER.INSTRUCTION_BOX.VENUE_ADMIN_INFO" />
                  </Typography>
                </>
              )}
              <SeparatorComponent />
              <ButtonComponent onClick={() => props.onFill(field)} theme="secondary-outline">
                <FormattedMessage id="REGISTER.FILL_VALUES" />
              </ButtonComponent>
            </div>
          )}

          <SeparatorComponent />
          <CardHeaderComponent justifyContent="space-between" className={styles.header}>
            <Typography tag="h4" color="secondary" type="largeText">
              {`#${index + 1} `}
              <FormattedMessage id="VENUES.ADMINISTRATOR_USER" />
            </Typography>

            {props.fields.length > 1 && (
              <ButtonComponent onClick={() => props.fields.remove(index)} theme="secondary-outline" className="ml-4">
                <FormattedMessage id="VENUES.REMOVE_ADMINISTRATOR" />
              </ButtonComponent>
            )}
          </CardHeaderComponent>

          <SeparatorComponent />
          <CardBodyComponent>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name={`${field}.firstName`}
                  component={ConnectedInputComponent}
                  label="PROFILE.FIRST_NAME"
                  placeholder="PROFILE.FIRST_NAME"
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name={`${field}.lastName`}
                  component={ConnectedInputComponent}
                  label="PROFILE.LAST_NAME"
                  placeholder="PROFILE.LAST_NAME"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name={`${field}.email`}
                  component={ConnectedInputComponent}
                  label="PROFILE.EMAIL"
                  placeholder="PROFILE.EMAIL"
                  required
                />
              </div>
              <div className={columnClassNames}>
                <Field
                  name={`${field}.mobilePhone`}
                  component={ConnectedInputComponent}
                  label="PROFILE.MOBILE_PHONE"
                  placeholder="PROFILE.MOBILE_PHONE"
                  append="PROFILE.PHONE_NUMBER_FORMAT"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className={columnClassNames}>
                <Field
                  name={`${field}.workPhone`}
                  component={ConnectedInputComponent}
                  label="PROFILE.WORK_PHONE"
                  placeholder="PROFILE.WORK_PHONE"
                  append="PROFILE.PHONE_NUMBER_FORMAT"
                />
              </div>
            </div>
            <div>
              <Field
                name={`${field}.allowMarketing`}
                component={ConnectedToggleComponent}
                label={
                  <>
                    <div>{intl.formatMessage({ id: 'PROFILE.MARKETING' })}</div>
                    <div>{intl.formatMessage({ id: 'PROFILE.MARKETING_INFO' })}</div>
                  </>
                }
              />
            </div>
          </CardBodyComponent>
        </div>
      ))}
      <SeparatorComponent />
      <CardFooterComponent>
        <ButtonComponent onClick={() => props.fields.push({})} theme="secondary-outline">
          <FormattedMessage id="VENUES.ADD_ADMINISTRATOR" />
        </ButtonComponent>
      </CardFooterComponent>
    </>
  );
};
