import get from 'lodash/get';

export const errorReducer = (state = false, action) => {
  // TODO: this error handling code is redundant with the internal-error.middleware.
  // Both bring the application in to "crashed" state when receiving a status >500 response
  // from the backend. This code here (and the code that uses the error Redux state
  // in app.component.js / app.container.js) can be removed.

  if (!!action.error && get(action, ['payload', 'response', 'code']) >= 500) {
    return true;
  }

  return state;
};
