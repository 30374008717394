import { FunctionComponent, useState } from 'react';
import { isEqual, range } from 'lodash';
import { FormattedDate } from 'react-intl';
import { FormikAdapterField } from './formik-adapter-field.component';
import { ConnectedSelectComponent } from 'app/shared/form/connected/select/connected-select.component';
import { SelectionControlBase } from 'app/shared/form/common/selection-control-base/selection-control-base.component';
import moment from 'moment';
import { DATE_VALUE_FORMAT } from 'app/constants';
import { useField } from 'formik';
import { SelectedMonthError, SelectedMonthFieldValue } from './types';
import styles from './selected-month.component.module.scss';
import { errorCodeToTranslationKey } from './errors';

export type ResetToPreviouslySelectedMonthCallback = () => void;
export type OnMonthChangedCallback = (
  value: SelectedMonthFieldValue,
  resetToPreviouslySelectedMonth: ResetToPreviouslySelectedMonthCallback,
) => void;

export interface SelectedMonthComponentProps {
  allowedDateRange: {
    fromDate: string;
    toDate: string;
  };
  onMonthChanged?: OnMonthChangedCallback;
}

const MonthSelectors: FunctionComponent<{
  allowedDateRange: {
    fromDate: string;
    toDate: string;
  };
  onPartialChange: (newPartialValue: { year?: number; month?: number }) => void;
}> = ({ allowedDateRange, onPartialChange }) => {
  interface MonthOption {
    id: number;
    name: JSX.Element;
  }

  interface YearOption {
    id: number;
    name: string;
  }

  const validFrom = moment(allowedDateRange.fromDate, DATE_VALUE_FORMAT);
  const validTo = moment(allowedDateRange.toDate, DATE_VALUE_FORMAT);

  const months: MonthOption[] = range(12).map(index => ({
    id: index,
    name: <FormattedDate value={new Date(new Date().getFullYear(), index)} month="long" />,
  }));

  const years: YearOption[] = range(validFrom.year(), validTo.year() + 1).map(year => ({
    id: year,
    name: year.toString(10),
  }));

  return (
    <div className={styles.wrapper}>
      <div className={styles.month}>
        <FormikAdapterField
          component={ConnectedSelectComponent}
          name={'selectedMonth.month'}
          options={months}
          onChange={(event: { value: number }) => {
            onPartialChange({ month: event.value });
          }}
        />
      </div>
      <div className={styles.year}>
        <FormikAdapterField
          component={ConnectedSelectComponent}
          name={'selectedMonth.year'}
          options={years}
          onChange={(event: { value: number }) => {
            onPartialChange({ year: event.value });
          }}
        />
      </div>
    </div>
  );
};

export const SelectedMonthComponent: FunctionComponent<SelectedMonthComponentProps> = props => {
  // MonthSelectors is wrapped in InputBaseControl to create one virtual input component that supports setting
  // shared label and error message on month and year selector.

  const [selectedMonthField, meta, { setValue }] = useField<SelectedMonthFieldValue>('selectedMonth');
  const [selectedValue, setSelectedValue] = useState(selectedMonthField.value);

  const onPartialChange = (newPartialValue: { year?: number; month?: number }) => {
    const newValue = {
      ...selectedMonthField.value,
      ...newPartialValue,
    };

    if (!isEqual(newValue, selectedValue)) {
      const previouslySelectedValue = selectedValue;
      setSelectedValue(newValue);
      const resetToPreviouslySelectedMonth = () => {
        setSelectedValue(previouslySelectedValue);
        setValue(previouslySelectedValue);
      };
      props.onMonthChanged && props.onMonthChanged(newValue, resetToPreviouslySelectedMonth);
    }
  };

  const errorId: TranslationKey | undefined = meta.error
    ? errorCodeToTranslationKey(
        'SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.SELECTED_MONTH.ERROR',
        meta.error[0] as SelectedMonthError,
      )
    : undefined;
  const invalid = !!meta.error;

  return (
    <SelectionControlBase
      component={MonthSelectors}
      {...props}
      error={{ id: errorId }}
      invalid={invalid}
      required={true}
      label={{ id: 'SERVICE_EVENTS.HOUSING_SERVICE.SUBMISSION.SELECTED_MONTH.LABEL' }}
      labelPosition="top"
      onPartialChange={onPartialChange}
    />
  );
};
