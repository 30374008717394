export enum ServiceArea {
  // TODO: VAEX-2675 - Remove SericeArea.HousingServices
  HousingServices = 'HOUSING_SERVICES',
  ElderlyHousingServices = 'ELDERLY_HOUSING_SERVICES',
  VeteranHousingServices = 'VETERAN_HOUSING_SERVICES',
  WarInvalidsHousingServices = 'WAR_INVALIDS_HOUSING_SERVICES',
  DisabilityHousingServices = 'DISABILITY_HOUSING_SERVICES',
  MentalDisabilityHousingServices = 'MENTAL_DISABILITY_HOUSING_SERVICES',
}

export const elderlyAndVeteranHousingServiceAreas = [
  // TODO: VAEX-2675 - Remove ServiceArea.HousingServices
  ServiceArea.HousingServices,
  ServiceArea.ElderlyHousingServices,
  ServiceArea.VeteranHousingServices,
  ServiceArea.WarInvalidsHousingServices,
] as const;

export const disabilityHousingServiceAreas = [
  ServiceArea.DisabilityHousingServices,
  ServiceArea.MentalDisabilityHousingServices,
] as const;

const housingServicesServiceAreas = [
  ...elderlyAndVeteranHousingServiceAreas,
  ...disabilityHousingServiceAreas,
] as const;

export type HousingServicesServiceAreas = typeof housingServicesServiceAreas[number];

type HousingServiceProducedServiceRulesKey = `${ServiceArea}|${HousingServicesProducedService}`;

export function isHousingServicesServiceAreas(value: unknown): value is HousingServicesServiceAreas {
  return housingServicesServiceAreas.includes(value as HousingServicesServiceAreas);
}

export enum HousingServicesProducedService {
  // Applicable to all housing services service areas
  Present = 'Läsnä',
  HospitalisedNoCompensation = 'Sairaala (ei korvausta)',
  OtherAbsenceNoCompensation = 'Muu poissaolo (ei korvausta)',
  ExitusHousingServices = 'Exitus (asumispalvelussa)',
  ExitusOtherIn5DaysOfAbsence = 'Exitus muualla (5 poissaolopäivän sisällä)',
  ExitusOtherAfter5DaysOfAbsence = 'Exitus muualla (5 poissaolopäivän jälkeen)',
  ServiceTerminatedOtherReason = 'Palvelu päättynyt (muu syy)',

  // Applicable to only elderly & veteran housing
  HospitalisedCompensationWithoutMeal = 'Sairaala (korvaus ilman ateriaa)',
  OtherAbsenceCompensationWithoutMeal = 'Muu poissaolo (korvaus ilman ateriaa)',

  // Applicable to only disability housing
  HospitalisedFullCompensation = 'Sairaala (täyskorvaus)',
  OtherAbsenceFullCompensation = 'Muu poissaolo (täyskorvaus)',
}

export const MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES = 5;
export const REQUIRED_AMOUNT_OF_PRESENCE_BETWEEN_PAID_ABSENCE_SERVICES = 2;

export const housingServicesPaidAbsenceService = [
  HousingServicesProducedService.HospitalisedCompensationWithoutMeal,
  HousingServicesProducedService.OtherAbsenceCompensationWithoutMeal,
  HousingServicesProducedService.HospitalisedFullCompensation,
  HousingServicesProducedService.OtherAbsenceFullCompensation,
];

export enum CompensationLevel {
  NoCompensation,
  FullCompensation,
  CompensationWithoutMeal,
}

export enum ProducedServiceDuration {
  UserDefined,
  SingleDay,
  MaxAmountOfDays,
}

type ProducedServiceBase = {
  serviceAreas: readonly ServiceArea[];
  type: HousingServicesProducedService;
  compensationLevel: CompensationLevel;
  isTerminatingService: boolean;
};
type ProducedServiceWithMaxAmountOfDays = ProducedServiceBase & {
  serviceDuration: ProducedServiceDuration.MaxAmountOfDays;
  maxAmountOfDays: number;
};
type ProducedServiceWithOtherDuration = ProducedServiceBase & {
  serviceDuration: Exclude<ProducedServiceDuration, ProducedServiceDuration.MaxAmountOfDays>;
};
export type ProducedServiceRule = ProducedServiceWithMaxAmountOfDays | ProducedServiceWithOtherDuration;

// We cannot pass the contents of the map to the constructor as this is not supported by IE11, so we fill in the entries
// using .set() below. This might be solved better by using a polyfill in the future.
const HousingServiceProducedServiceRules = new Map<HousingServiceProducedServiceRulesKey, ProducedServiceRule>();

const HousingServiceProducedServiceRulesEntries: ProducedServiceRule[] = [
  {
    serviceAreas: housingServicesServiceAreas,
    type: HousingServicesProducedService.HospitalisedNoCompensation,
    compensationLevel: CompensationLevel.NoCompensation,
    serviceDuration: ProducedServiceDuration.UserDefined,
    isTerminatingService: false,
  },
  {
    serviceAreas: housingServicesServiceAreas,
    type: HousingServicesProducedService.OtherAbsenceNoCompensation,
    compensationLevel: CompensationLevel.NoCompensation,
    serviceDuration: ProducedServiceDuration.UserDefined,
    isTerminatingService: false,
  },
  {
    serviceAreas: housingServicesServiceAreas,
    type: HousingServicesProducedService.Present,
    compensationLevel: CompensationLevel.FullCompensation,
    serviceDuration: ProducedServiceDuration.UserDefined,
    isTerminatingService: false,
  },
  {
    serviceAreas: disabilityHousingServiceAreas,
    type: HousingServicesProducedService.HospitalisedFullCompensation,
    compensationLevel: CompensationLevel.FullCompensation,
    serviceDuration: ProducedServiceDuration.MaxAmountOfDays,
    isTerminatingService: false,
    maxAmountOfDays: MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES,
  },
  {
    serviceAreas: disabilityHousingServiceAreas,
    type: HousingServicesProducedService.OtherAbsenceFullCompensation,
    compensationLevel: CompensationLevel.FullCompensation,
    serviceDuration: ProducedServiceDuration.MaxAmountOfDays,
    isTerminatingService: false,
    maxAmountOfDays: MAX_CONSECUTIVE_DAYS_OF_PAID_ABSENCES,
  },
  {
    serviceAreas: elderlyAndVeteranHousingServiceAreas,
    type: HousingServicesProducedService.HospitalisedCompensationWithoutMeal,
    compensationLevel: CompensationLevel.CompensationWithoutMeal,
    serviceDuration: ProducedServiceDuration.MaxAmountOfDays,
    isTerminatingService: false,
    maxAmountOfDays: 5,
  },
  {
    serviceAreas: elderlyAndVeteranHousingServiceAreas,
    type: HousingServicesProducedService.OtherAbsenceCompensationWithoutMeal,
    compensationLevel: CompensationLevel.CompensationWithoutMeal,
    serviceDuration: ProducedServiceDuration.MaxAmountOfDays,
    isTerminatingService: false,
    maxAmountOfDays: 5,
  },
  {
    serviceAreas: housingServicesServiceAreas,
    type: HousingServicesProducedService.ServiceTerminatedOtherReason,
    compensationLevel: CompensationLevel.NoCompensation,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: elderlyAndVeteranHousingServiceAreas,
    type: HousingServicesProducedService.ExitusHousingServices,
    compensationLevel: CompensationLevel.CompensationWithoutMeal,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: elderlyAndVeteranHousingServiceAreas,
    type: HousingServicesProducedService.ExitusOtherIn5DaysOfAbsence,
    compensationLevel: CompensationLevel.CompensationWithoutMeal,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: elderlyAndVeteranHousingServiceAreas,
    type: HousingServicesProducedService.ExitusOtherAfter5DaysOfAbsence,
    compensationLevel: CompensationLevel.NoCompensation,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: disabilityHousingServiceAreas,
    type: HousingServicesProducedService.ExitusHousingServices,
    compensationLevel: CompensationLevel.FullCompensation,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: disabilityHousingServiceAreas,
    type: HousingServicesProducedService.ExitusOtherIn5DaysOfAbsence,
    compensationLevel: CompensationLevel.FullCompensation,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
  {
    serviceAreas: disabilityHousingServiceAreas,
    type: HousingServicesProducedService.ExitusOtherAfter5DaysOfAbsence,
    compensationLevel: CompensationLevel.NoCompensation,
    serviceDuration: ProducedServiceDuration.SingleDay,
    isTerminatingService: true,
  },
];

const generateMapKey = (
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService,
): HousingServiceProducedServiceRulesKey => `${serviceArea}|${producedService}`;

HousingServiceProducedServiceRulesEntries.forEach(rule => {
  rule.serviceAreas.forEach(serviceArea => {
    HousingServiceProducedServiceRules.set(generateMapKey(serviceArea, rule.type), rule as ProducedServiceRule);
  });
});

export function getHousingServiceProducedServiceRule(
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService,
): ProducedServiceRule {
  const rules = HousingServiceProducedServiceRules.get(generateMapKey(serviceArea, producedService));
  if (!rules) {
    throw new Error(`No rules found for service area: ${serviceArea} and produced service: ${producedService}`);
  }
  return rules;
}

export function hasHousingServiceProducedServiceRule(
  serviceArea: ServiceArea,
  producedService: HousingServicesProducedService,
): boolean {
  return !!HousingServiceProducedServiceRules.get(generateMapKey(serviceArea, producedService));
}
