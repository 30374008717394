// @flow

import React from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './group.component.types';*/
import styles from './group.module.scss';

export const GroupComponent = ({ children, groupClassName } /*: Props*/) => (
  <div className={classNames('form-group', styles.group, groupClassName)}>{children}</div>
);
