// @flow

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { CouponsTypeAddContainer } from './add/coupons-type-add.container';
import { CouponsTypeEditContainer } from './edit/coupons-type-edit.container';
import { CouponsTypesListContainer } from './list/coupons-types-list.container';

/*:: import type { Props } from './coupons-types.component.types';*/

export class CouponsTypesComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <Switch>
        <Route path={`${this.props.match.url}/:couponType/edit`} component={CouponsTypeEditContainer} />
        <Route path={`${this.props.match.url}/add`} component={CouponsTypeAddContainer} />
        <Route path={this.props.match.url} component={CouponsTypesListContainer} />
      </Switch>
    );
  }
}
