// @flow

import noop from 'lodash/noop';
import { stringify } from 'qs';
import { getFormValues } from 'redux-form';

import { configuration } from 'configuration';
import { DownloadFileException } from 'app/exceptions';
import { convertParams } from 'app/utils';

import { getResourceToken } from 'app/redux/resource-token/resource-token.actions';
import { reportException } from 'app/sentry/sentry';
import { DOWNLOAD_FILE_FAILURE, DOWNLOAD_FILE_REQUEST, DOWNLOAD_FILE_SUCCESS } from './downloads.action-types';
import { saveFile } from './utils/save-file';

/*:: import type { Dispatch as ReduxDispatch } from 'redux';*/
/*:: import type { Config } from './downloads.actions.types';*/

export const downloadFileRequest = () => ({ type: DOWNLOAD_FILE_REQUEST });
export const downloadFileSuccess = () => ({ type: DOWNLOAD_FILE_SUCCESS });
export const downloadFileFailure = (error /*: typeof Error*/) => ({ type: DOWNLOAD_FILE_FAILURE, payload: error });

export const download =
  (
    {
      endpoint = '/downloads/token',
      failureCallback = noop,
      filters,
      locale: lang,
      params,
      path,
      successCallback = noop,
    } /*: Config*/,
  ) =>
  (dispatch /*: ReduxDispatch*/, getState /*: Function*/) /*: Promise<any>*/ => {
    const handleFailure = (resolve, exception) =>
      Promise.resolve(resolve()).then(() => {
        if (exception.name !== 'AbortError') {
          dispatch(downloadFileFailure(exception));

          reportException(exception);

          failureCallback();
        }
      });

    const handleSuccess = resolve =>
      Promise.resolve(resolve()).then(() => {
        dispatch(downloadFileSuccess());
        successCallback();
      });

    return new Promise(async resolve => {
      try {
        await dispatch(getResourceToken(endpoint, params)).then(
          async ({
            payload: {
              data: { token },
            },
          }) => {
            const queryObject = filters
              ? convertParams({
                  token,
                  lang,
                  ...getFormValues(filters)(getState()),
                })
              : { token, lang };

            const searchParams = stringify(queryObject, {
              skipNulls: true,
              addQueryPrefix: true,
              arrayFormat: 'brackets',
            });

            dispatch(downloadFileRequest());
            // $FlowFixMe
            const response = await fetch(`${configuration.apiEndpoint}/downloads/${path}${searchParams}`);

            if (response.status !== 200) {
              const body = await response.json();

              throw new DownloadFileException(body.message);
            }

            await saveFile(response);

            await handleSuccess(resolve);
          },
        );
      } catch (exception) {
        await handleFailure(resolve, exception);
      }
    });
  };
