// @flow

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ButtonComponent, TileComponent } from 'app/shared';

import { checkPermissions } from 'app/utils';

/*:: import type { Props } from './users-list-tile.component.types';*/
import styles from './users-list-tile.module.scss';

export class UsersListTileComponent extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TileComponent className={classNames({ [styles.pending]: !this.props.tile.active })}>
        <h3>
          {this.props.tile.firstName} {this.props.tile.lastName}
        </h3>

        <div className="row">
          <div className="col">
            <FormattedMessage id="USERS.EMAIL" tagName="small" />
            {this.props.tile.email}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormattedMessage id="USERS.ACTIVE_ROLES" tagName="small" />
            {this.props.tile.roles.map(role => this.props.intl.formatMessage({ id: `ROLES.${role}` })).join(', ')}
          </div>
        </div>

        {this.props.tile.active && (
          <div className="buttons">
            {checkPermissions(this.props.permissions, [`${this.props.context}Users`, 'update']) && (
              <ButtonComponent
                theme="secondary-outline"
                to={`${this.props.match.url}/${this.props.tile.id}/roles`}
                size="small"
              >
                <FormattedMessage id="USERS.EDIT_ROLES" /> <i className="fi fi-pencil-edit-button" />
              </ButtonComponent>
            )}
          </div>
        )}

        {this.props.tile.active || (
          <div className="buttons">
            <FormattedMessage id="USERS.PENDING" tagName="strong" />
          </div>
        )}
      </TileComponent>
    );
  }
}
