// @flow

import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';
import { columnsName } from './columns-names';

/*:: import type { CellData, CouponWithIndex as Coupon } from './coupons-list-virtualized.types';*/
/*:: import type { Element } from 'react';*/

export const cellDataGetter = ({ dataKey, rowData } /*: CellData*/) /*: ?string | Element<any>*/ => {
  switch (dataKey) {
    case columnsName.dateOfBirth:
    case columnsName.createdAt:
      return dateFormatter(rowData[dataKey]);
    case columnsName.validity:
      return `${dateFormatter(get(rowData, ['valid', 'from']))} - ${dateFormatter(get(rowData, ['valid', 'to']))}`;
    case columnsName.balance:
    case columnsName.lastDeposit:
    case columnsName.totalDeposit:
    case columnsName.unitCost:
    case columnsName.maxTransaction:
      return currencyFormatter.format(rowData[dataKey]);
    case columnsName.oneTimeLimitationInput:
    case columnsName.valueAmountInput:
      return '';
    case columnsName.gender:
      return <FormattedMessage id={`CORE.GENDER.${rowData[dataKey] === 'm' ? 'MALE' : 'FEMALE'}`} />;
    default:
      return rowData[dataKey];
  }
};

export const cellMessageIdGetter = (columnName /*: string*/, renewMode /*: boolean*/ = false) /*: string*/ => {
  switch (columnName) {
    case columnsName.dateOfBirth:
      return 'COUPONS.LIST.HEADERS.DATE_OF_BIRTH';
    case columnsName.createdAt:
      return 'COUPONS.LIST.HEADERS.CREATED_AT';
    case columnsName.validity:
      return renewMode ? 'COUPONS.RENEW.LABEL.VALID' : 'COUPONS.LIST.HEADERS.VALIDITY';
    case columnsName.balance:
      return 'COUPONS.LIST.HEADERS.BALANCE';
    case columnsName.lastDeposit:
      return 'COUPONS.LIST.HEADERS.LAST_DEPOSIT';
    case columnsName.totalDeposit:
      return 'COUPONS.LIST.HEADERS.TOTAL_DEPOSIT';
    case columnsName.unitCost:
      return 'COUPONS.LIST.HEADERS.UNIT_COST';
    case columnsName.maxTransaction:
      return 'COUPONS.LIST.HEADERS.MAX_TRANSACTION';
    case columnsName.oneTimeLimitationInput:
      return renewMode ? 'COUPONS.RENEW.LABEL.MAX_TRANSACTION' : 'COUPONS.LIST.HEADERS.MAX_TRANSACTION';
    case columnsName.valueAmountInput:
      return 'COUPONS.RENEW.LABEL.VALUE_AMOUNT';
    case columnsName.reference:
      return 'COUPONS.LIST.HEADERS.REFERENCE';
    case columnsName.decisionNumber:
      return 'COUPONS.LIST.HEADERS.DECISION_NUMBER';
    case columnsName.type:
      return 'COUPONS.LIST.HEADERS.TYPE';
    case columnsName.id:
      return 'COUPONS.LIST.HEADERS.ID';
    case columnsName.issuer:
      return 'COUPONS.LIST.HEADERS.ISSUER';
    case columnsName.gender:
      return 'COUPONS.LIST.HEADERS.GENDER';
    case columnsName.personalIdentificationCode:
      return 'COUPONS.LIST.HEADERS.PERSONAL_IDENTIFICATION_CODE';
    default:
      return '';
  }
};

export const onSelectAll = (coupons /*: Coupon[]*/, change /*: (string, Coupon[]) => void*/) => {
  return ({ target: { checked } } /*: SyntheticInputEvent<HTMLInputElement>*/) => {
    const newCoupons = coupons.map(coupon => ({
      ...coupon,
      active: checked,
    }));

    change('coupons', newCoupons);
  };
};

export const allSelected = (coupons /*: Coupon[]*/, renewMode /*: boolean*/) /*: boolean*/ => {
  return !coupons.filter(coupon => !(coupon.invalidated && renewMode) && !coupon.active).length;
};
