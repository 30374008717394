import { ComponentType, PropsWithChildren, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { get, identity } from 'lodash';
import { ConnectedSelectComponentProps } from 'app/shared';
import { TranslationKey } from 'v2/types/translations';

export interface ValueTransformation<InputValue, FieldValue> {
  inputValueToFieldValue: (inputValue: InputValue) => FieldValue;
  fieldValueToInputValue: (fieldValue: FieldValue) => InputValue;
}

export type FormikAdapterFieldProps<P, InputValue, FieldValue> = {
  name: string;
  component: ComponentType;
  valueTransformation?: ValueTransformation<InputValue, FieldValue>;
  label?: TranslationKey;
} & PropsWithChildren<P>;

// This component adapts the existing inputs designed for use with redux-form/final-form to formik.
// See https://final-form.org/docs/react-final-form/migration/formik for the relevant differences.
// See https://codesandbox.io/s/jRzE53pqR?file=/index.js for an example how to adapt custom inputs.

export function FormikAdapterField<WrappedComponentProps, InputValue = unknown, FieldValue = unknown>(
  props: FormikAdapterFieldProps<WrappedComponentProps, InputValue, FieldValue>,
) {
  const { component: WrappedComponent, valueTransformation, ...propsRest } = props;

  const { setFieldValue, initialValues } = useFormikContext();

  const [field] = useField(props);

  const defaultTransformation: ValueTransformation<InputValue, FieldValue> = {
    inputValueToFieldValue: identity,
    fieldValueToInputValue: identity,
  };
  const transformation = valueTransformation || defaultTransformation;

  useEffect(() => {
    const { options, disabledOptions } = props as unknown as ConnectedSelectComponentProps;
    if (!!options && !!disabledOptions && !get(initialValues as Record<string, unknown>, field.name)) {
      const firstAvailableOption = options.find(option => !disabledOptions.includes(option));
      setFieldValue(field.name, transformation.inputValueToFieldValue(firstAvailableOption as InputValue));
    }
    // The `touched` meta field doesn't seem to be working with the current implementation (it's always `false`
    // even after the user changed the field value) so we provide an empty array for the effect's dependencies.
    // This way we are sure that the effect is executed only once (otherwise the user changing the field value
    // would tigger this effect, setting it back to the default value).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // These properties are expected by inputs designed for use with redux-form or final-form
  const input = {
    ...field,
    value: transformation.fieldValueToInputValue(field.value),
    onChange: (val: InputValue) => {
      setFieldValue(field.name, transformation.inputValueToFieldValue(val));
    },
    onBlur: () => {
      // TODO: This is currently a stub to avoid the app throwing errors in the background.
      //  Wire this to formik as needed.
      //  Current empty implementation here might lead into some Formik features not working on the form fields where
      //  this adapter is used (for instance the touched property of a field).
    },
    onFocus: () => {
      // TODO: This is currently a stub to avoid the app throwing errors in the background.
      //  Wire this to formik as needed.
      //  Current empty implementation here might lead into some Formik features not working on the form fields where
      //  this adapter is used (for instance the touched property of a field).
    },
  };
  const meta = {
    // TODO: Wire this to formik as needed.
    //  Current empty implementation here might lead into some Formik features not working on the form fields where
    //  this adapter is used. Current empty implementation here might lead into some Formik features not working on
    //  the form fields where this adapter is used.
  };

  return <WrappedComponent input={input} meta={meta} {...propsRest} />;
}
