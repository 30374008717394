import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormValues } from 'redux-form';

import { CouponsCreateConfirmComponent } from './coupons-create-confirm.component';

const mapStateToProps = ({
  customers: {
    entities: { current },
  },
  dictionaries,
  form,
  coupons: { couponCreateError },
}) => ({
  current,
  contractsOptions: dictionaries['contracted-venues'],
  couponCreateError,
  values: getFormValues('coupon-create')({ form }),
});

export const CouponsCreateConfirmContainer = compose(
  injectIntl,
  connect(mapStateToProps),
)(CouponsCreateConfirmComponent);
