import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { GetVoucherPdfResponse, PostpayApiClient } from 'v2/api/postpay-api-client';
import { actions, PdfRequestedPayload } from './print-service-vouchers-slice';

export function openPdfAndPrint(pdfBlob: Blob) {
  const url = URL.createObjectURL(pdfBlob);
  const pdfWindow = window.open(url, '_blank'); // opens in a new tab
  URL.revokeObjectURL(url);

  // opens a print dialog
  pdfWindow?.print();
}

export function* printServiceVouchersSaga(apiClient: PostpayApiClient): Generator<ForkEffect> {
  function* printVoucherPdfRequested(action: PayloadAction<PdfRequestedPayload>) {
    const { voucher } = action.payload;

    const pdf: GetVoucherPdfResponse = yield call(apiClient.getVoucherPdf, voucher);
    yield call(openPdfAndPrint, pdf.data);

    yield put(actions.printVoucherPdfRequestCompleted({ voucherId: voucher.id }));
  }

  function* printVoucherApprovedServiceProvidersPdfRequested(action: PayloadAction<PdfRequestedPayload>) {
    const { voucher } = action.payload;

    const pdf: GetVoucherPdfResponse = yield call(apiClient.getVoucherApprovedServiceProvidersPdf, voucher);
    yield call(openPdfAndPrint, pdf.data);

    yield put(actions.printVoucherApprovedServiceProvidersPdfRequestCompleted({ voucherId: voucher.id }));
  }

  yield takeLatest(actions.printVoucherPdfRequested, printVoucherPdfRequested);
  yield takeLatest(
    actions.printVoucherApprovedServiceProvidersPdfRequested,
    printVoucherApprovedServiceProvidersPdfRequested,
  );
}
