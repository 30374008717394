export const CREATE_ORDER_REQUEST = 'orders/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'orders/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'orders/CREATE_ORDER_FAILURE';

export const CONFIRM_ORDER_REQUEST = 'orders/CONFIRM_ORDER_REQUEST';
export const CONFIRM_ORDER_SUCCESS = 'orders/CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILURE = 'orders/CONFIRM_ORDER_FAILURE';

export const CANCEL_ORDER_REQUEST = 'orders/CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'orders/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'orders/CANCEL_ORDER_FAILURE';

export const FETCH_ORDER_HISTORY_REQUEST = 'orders/FETCH_ORDER_HISTORY_REQUEST';
export const FETCH_ORDER_HISTORY_SUCCESS = 'orders/FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_FAILURE = 'orders/FETCH_ORDER_HISTORY_FAILURE';
