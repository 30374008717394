// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListComponent } from 'app/shared/list/list.component';
import { CardComponent } from 'app/shared';

import { CouponsCheckBalanceTransactionsTableComponent } from './table/coupons-check-balance-transactions-table.component';
import { CouponsCheckBalanceTransactionsTileComponent } from './tile/coupons-check-balance-transactions-tile.component';

/*:: import type { Props } from './coupons-check-balance-transactions.component.types';*/

export class CouponsCheckBalanceTransactionsComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <CardComponent>
        <div className="card-header">
          <h4 className="card-title">
            <FormattedMessage id="MODULES.CPS.CHECK_BALANCE.TRANSACTIONS" />
          </h4>
        </div>
        <div className="card-body">
          <ListComponent
            list={this.props.transactions}
            table={CouponsCheckBalanceTransactionsTableComponent}
            tile={CouponsCheckBalanceTransactionsTileComponent}
          />
        </div>
      </CardComponent>
    );
  }
}
