import { Change, diffWords } from 'diff';

import { PricelistTypeEnum } from 'app/constants';
import {
  DiffedPriceList,
  InputContractWithPricelists,
  InputLinkPriceList,
  InputTablePriceList,
  InputTextPriceList,
  LinkPriceList,
  PriceObject,
  TableColumn,
  TablePriceList,
  TableRow,
  TextPriceList,
} from 'app/types/pricelist';

export const getContractPricelistsWithDiff = (
  currentContract: InputContractWithPricelists,
  oldContract: InputContractWithPricelists,
): DiffedPriceList[] => {
  return currentContract.priceLists.map((pricelist, index): DiffedPriceList => {
    const oldPricelist = oldContract.priceLists[index];
    switch (pricelist.type) {
      case PricelistTypeEnum.Text:
        const textPriceListWithDiff = getTextPriceListWithDiff(
          oldPricelist?.type === PricelistTypeEnum.Text ? oldPricelist : null,
          pricelist,
        );
        return textPriceListWithDiff;
      case PricelistTypeEnum.Table:
        const headerColumnsWithDiff = getTableHeaderPriceDiffs(
          oldPricelist?.type === PricelistTypeEnum.Table ? oldPricelist.columns : [],
          pricelist.columns,
        );
        const priceCellsWithDiff = getTableCellPriceDiffs(
          oldPricelist?.type === PricelistTypeEnum.Table ? oldPricelist.services : [],
          pricelist.services,
        );
        return { ...pricelist, columns: headerColumnsWithDiff, services: priceCellsWithDiff };
      case PricelistTypeEnum.Link:
        const linkPriceWithDiff = getLinkPriceWithDiff(
          oldPricelist?.type === PricelistTypeEnum.Link ? oldPricelist : null,
          pricelist,
        );
        return linkPriceWithDiff;
      default:
        return pricelist;
    }
  });
};

const getDiffByWords = (oldPriceValue: string | undefined, currentPriceValue: string): Change[] => {
  return diffWords(oldPriceValue || '', currentPriceValue);
};

const getLinkPriceWithDiff = (oldPrice: InputLinkPriceList | null, currentPrice: InputLinkPriceList): LinkPriceList => {
  return { ...currentPrice, oldValue: oldPrice?.link };
};

const getTextPriceListWithDiff = (
  oldPrice: InputTextPriceList | null,
  currentPrice: InputTextPriceList,
): TextPriceList => {
  const diff = getDiffByWords(oldPrice?.text, currentPrice.text);
  return { ...currentPrice, diff: sanitizeDiff(diff) };
};

const sanitizeDiff = (diff: Change[]): Change[] => {
  const isDiffEmpty = diff.length === 0 || diff.every(d => d.value === '' && d.count === 0);
  return isDiffEmpty ? [] : diff;
};

const getTableHeaderPriceDiffs = (
  oldColumns: InputTablePriceList['columns'],
  currentColumns: InputTablePriceList['columns'],
): TablePriceList['columns'] => {
  return currentColumns.map((column, index): TableColumn => {
    // handle subtitle.to and from cases separately
    let columnDiffFields: Partial<TableColumn> = {};
    if ('subtitle' in column) {
      const oldColumn = oldColumns[index];
      columnDiffFields = { oldValue: oldColumn?.subtitle };
    }
    return { ...column, ...columnDiffFields };
  });
};

const getTableCellPriceDiffs = (
  oldRows: InputTablePriceList['services'],
  currentRows: InputTablePriceList['services'],
): TablePriceList['services'] => {
  return currentRows.map((priceRow, rowIndex): TableRow => {
    const oldRow = oldRows[rowIndex];
    const prices = priceRow.prices.map((price, priceIndex): PriceObject => {
      const oldRowPrices = oldRow?.prices || [];
      const oldPrice = oldRowPrices[priceIndex];
      return { value: price, oldValue: oldPrice };
    });
    return { ...priceRow, prices };
  });
};
