// @flow

import React, { Component } from 'react';

import { PageHeaderComponent } from 'app/core';
import { CardComponent } from 'app/shared';

import { VenueFormPlaceholderContainer } from 'app/venues/form/placeholder/venue-form.placeholder.container';
import { VenueFormContainer } from '../form/venue-form.container';

/*:: import type { Props } from './add-venue.component.types';*/

export class AddVenueComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
    this.props.fetchVenuesTypes();
  }

  render() {
    return (
      <>
        <PageHeaderComponent title="VENUES.ADD_NEW_VENUE" backLink="/venues" />

        <CardComponent>
          {this.props.loading && <VenueFormPlaceholderContainer />}
          {this.props.loading || <VenueFormContainer />}
        </CardComponent>
      </>
    );
  }
}
