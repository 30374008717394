// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { GroupComponent, PlaceholderComponent } from 'app/shared';

/*:: import type { Props } from './coupons-details-charge-services.placeholder.types';*/
import styles from '../form/services/row/services-row.component.module.scss';

export class CouponsDetailsChargeServicesPlaceholder extends PureComponent /*:: <Props>*/ {
  render() {
    const columnClassNames = classNames('col-6', 'col-lg-4', 'col-xl-2', {
      'col-lg-3': this.props.sidebarCollapsed,
    });

    const balanceClassNames = classNames('col-6', 'col-lg-4', 'col-xl-auto', {
      'col-lg-3': this.props.sidebarCollapsed,
    });

    const datesClassNames = classNames('col-12', 'col-lg-6', 'col-xl-3', styles.dates, {
      'col-lg-3': this.props.sidebarCollapsed,
    });

    return (
      <div className={styles.wrapper}>
        <div className={classNames('row', styles.row)}>
          <div className={datesClassNames}>
            <GroupComponent
              label={this.props.intl.formatMessage({ id: 'COUPONS.MASS_CHARGE.LABEL.SERVICE_PRODUCED' })}
              required
            >
              <PlaceholderComponent height={80} radius={8} color="light" />
            </GroupComponent>
          </div>

          <div className={balanceClassNames}>
            <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.CHARGE.BALANCE_LEFT' })} />
          </div>
          <div className={columnClassNames}>
            <GroupComponent
              label={this.props.intl.formatMessage({ id: 'COUPONS.MASS_CHARGE.LABEL.NUMBER_OF_PRICE_UNITS' })}
              required
            >
              <PlaceholderComponent height={80} radius={8} color="light" />
            </GroupComponent>
          </div>
          <div className={columnClassNames}>
            <GroupComponent label={this.props.intl.formatMessage({ id: 'COUPONS.DETAILS.CHARGE.AMOUNT' })} required>
              <PlaceholderComponent height={80} radius={8} color="light" />
            </GroupComponent>
          </div>

          <div className={columnClassNames}>
            <GroupComponent label={this.props.intl.formatMessage({ id: 'TRANSACTIONS.REFERENCE' })}>
              <PlaceholderComponent height={80} radius={8} color="light" />
            </GroupComponent>
          </div>
        </div>
      </div>
    );
  }
}
