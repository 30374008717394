// @flow

import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldArray } from 'redux-form';

import { ButtonComponent, SeparatorComponent, Typography } from 'app/shared';
import { CardComponent, CardFooterComponent } from 'app/shared/card-new';
import { ContractsListsComponent, VenueAdministratorContainer, VenueSectionContainer } from 'app/register/shared';
import { DEFAULT_EMPTY_CONTRACT } from 'app/utils';

/*:: import type { Props } from './venues-list.component.types';*/

export class VenuesListComponent extends Component /*:: <Props>*/ {
  isMoreThanOneVenue = () => this.props.fields.length > 1;

  handleFill = (field /*: string*/) => {
    this.props.fillValues('responsiblePerson', field);
  };

  render() {
    return (
      <>
        {this.props.fields.map((field, index) => (
          <Fragment key={index}>
            <CardComponent>
              <VenueSectionContainer name={`${field}.venue`}>
                <Typography tag="h4" color="secondary" type="largeText">
                  {`#${index + 1} `}
                  <FormattedMessage id="REGISTER.SECTION_TITLES.VENUE_GENERAL" />
                  {this.isMoreThanOneVenue() && (
                    <ButtonComponent
                      onClick={() => this.props.fields.remove(index)}
                      theme="secondary-outline"
                      className="ml-4"
                    >
                      <FormattedMessage id="REGISTER.VENUE.REMOVE" />
                    </ButtonComponent>
                  )}
                </Typography>
                <SeparatorComponent />
                <Typography type="subtitle" color="grey">
                  <FormattedMessage id="REGISTER.INSTRUCTION_BOX.VENUE_GENERAL_INFO" />
                  <SeparatorComponent />
                  <ButtonComponent
                    onClick={() => this.props.fillValues('company', `${field}.venue`)}
                    theme="secondary-outline"
                  >
                    <FormattedMessage id="REGISTER.FILL_VALUES" />
                  </ButtonComponent>
                </Typography>
              </VenueSectionContainer>

              <FieldArray
                name={`${field}.admins`}
                component={VenueAdministratorContainer}
                onFill={this.handleFill}
                fillable
              />

              <SeparatorComponent />

              <FieldArray name={`${field}.contracts`} component={ContractsListsComponent} intl={this.props.intl} />
            </CardComponent>
            <SeparatorComponent />
          </Fragment>
        ))}
        <CardFooterComponent justifyContent="center">
          <ButtonComponent
            onClick={() =>
              this.props.fields.push({
                contracts: [DEFAULT_EMPTY_CONTRACT],
              })
            }
            theme="secondary-outline"
          >
            <FormattedMessage id="REGISTER.VENUE.ADD" />
          </ButtonComponent>
        </CardFooterComponent>
      </>
    );
  }
}
