//@flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import { parse } from 'qs';
import isEmpty from 'lodash/isEmpty';

import { convertParams } from 'app/utils';
import { withClear } from 'app/common';

import { FiltersBaseComponent } from './filters-base.component';

const mapStateToProps = ({ users: { business }, form: reduxForms }, { location, advancedFilters, baseFilters }) => {
  const defaultInitialValues = [...advancedFilters, ...baseFilters].reduce(
    (prev, { props: { name } }) => ({
      ...prev,
      [name]: '',
    }),
    {},
  );

  const parsedURL = parse(location.search, { ignoreQueryPrefix: true });
  const initialValues = isEmpty(parsedURL) ? { ...defaultInitialValues } : { ...defaultInitialValues, ...parsedURL };

  return {
    initialValues,
    business,
    defaultInitialValues,
  };
};

const searchParamsSelector = params => {
  const searchParams = new URLSearchParams();

  params = convertParams(params);

  // eslint-disable-next-line no-unused-vars
  for (const param in params) {
    if (params.hasOwnProperty(param) && !!params[param]) {
      searchParams.set(param, params[param]);
    }
  }

  return searchParams.toString();
};

const onChange = ({ dispatch, props: { fetch } }, body = {}) => {
  const search = searchParamsSelector(body);

  dispatch(
    push({
      search,
    }),
  );

  if (!!fetch) fetch(body);
};

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: debounce(onChange.bind(this, { dispatch, props }), 500),
  onChange: debounce(onChange.bind(this, { dispatch, props }), 500),
});

export const FiltersBaseContainer = compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(),
  withClear,
)(FiltersBaseComponent);
