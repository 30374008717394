export function unregister() {
  if ('serviceWorker' in navigator) {
    // Firefox throws a SecurityException if "Delete cookies and site data when Firefox is closed" is enabled
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(console.log);
  }
}
