// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push } from 'connected-react-router';

import { withAuthorization } from 'app/common';

import { validateCouponCharge } from 'app/redux/payments/payments.actions';

import { CouponsChargeComponent } from './coupons-charge.component';
import { isPostpayVoucher } from 'v2/service-voucher/postpay/utils/is-postpay-voucher';

const onSubmit = dispatch => body => {
  const voucher = body.coupons;
  if (isPostpayVoucher(voucher)) {
    return dispatch(push(`/service-vouchers/${voucher}`, { showBackButton: true }));
  } else {
    return dispatch(validateCouponCharge({ coupons: [body.coupons] }));
  }
};
const mapDispatchToProps = dispatch => ({
  onSubmit: onSubmit(dispatch),
});

export const CouponsChargeContainer = compose(
  withAuthorization(['venuePayment', 'create']),
  connect(null, mapDispatchToProps),
  reduxForm({ form: 'charge-services' }),
)(CouponsChargeComponent);
