// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';

/*:: import type { Props } from './card.component.types';*/
import styles from './card.module.scss';

export class CardComponent extends PureComponent /*:: <Props>*/ {
  static defaultProps = {
    withShadow: true,
  };

  render() {
    const { withShadow, wrapperRef, style } = this.props;
    const cardClassNames = classNames(styles.card, this.props.className, {
      [styles.cardWithShadow]: withShadow,
    });

    return (
      <div className={cardClassNames} ref={wrapperRef ? wrapperRef : null} style={style}>
        {this.props.children}
      </div>
    );
  }
}
