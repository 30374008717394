// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ButtonComponent } from 'app/shared';
import { StatusComponent } from 'app/shared/status/status.component';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

import { checkPermissions, currencyFormatter, limit } from 'app/utils';
import { StatusEnum } from 'app/constants';

/*:: import type { Props } from './coupons-types-list-table.component.types';*/
import styles from './coupons-types-list-table.module.scss';

export const headers = [
  { content: 'COUPONS.TYPES.LABEL.STATUS', className: styles.type },
  { content: 'COUPONS.TYPES.LABEL.TITLE', className: styles.title },
  { content: 'COUPONS.TYPES.LABEL.DESCRIPTION', classNames: styles.description },
  { content: 'COUPONS.TYPES.LABEL.MAX_TRANSACTION', numerical: true },
  { content: 'COUPONS.TYPES.LABEL.VALUE', numerical: true },
  { content: 'COUPONS.TYPES.LABEL.PRICE_UNIT' },
  {},
];

export class CouponsTypesListTableComponent extends Component /*:: <Props>*/ {
  render() {
    return (
      <TableComponent scrollable className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map(type => (
            <TableRowComponent key={type.id}>
              <TableCellComponent>
                <StatusComponent status={type.active ? StatusEnum.Approved : StatusEnum.Deleted} />
              </TableCellComponent>
              <TableCellComponent>{type.name}</TableCellComponent>
              <TableCellComponent>{limit(type.description || '')}</TableCellComponent>
              <TableCellComponent align="right">
                {type.transactionMaxPriceEnabled ? currencyFormatter.format(type.defaultTransactionMaxPrice) : '-'}
              </TableCellComponent>
              <TableCellComponent align="right" className="text-nowrap">
                {/* added for backwards compatibility - for already created vouchers without defined value, defaultValue is 0 */}
                {currencyFormatter.format(type.defaultValue, {
                  placeholderObject: { condition: () => !type.defaultValue },
                })}
              </TableCellComponent>
              <TableCellComponent className="text-nowrap">
                {type.priceUnit ? <FormattedMessage id={`COUPONS.TYPES.UNITS.${type.priceUnit}`} /> : '-'}
              </TableCellComponent>

              <TableCellComponent className={classNames('text-nowrap', styles.buttons)}>
                {checkPermissions(this.props.permissions, ['serviceCouponTypes', 'update']) && (
                  <ButtonComponent theme="primary-outline" size="small" to={`/coupons/types/${type.id}/edit`}>
                    <FormattedMessage id="CORE.EDIT" />
                  </ButtonComponent>
                )}

                {checkPermissions(this.props.permissions, ['serviceCoupons', 'create']) && type.active && (
                  <ButtonComponent size="small" to={`/coupons/${type.id}/create`}>
                    <FormattedMessage id="CORE.CREATE" />
                  </ButtonComponent>
                )}
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
