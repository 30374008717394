import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// These core-js polyfills should be redundant due to stable language
// feature polyfill above. They should be removed in the future.
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';

import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/array/fill';

import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/object/keys';

import 'core-js/features/promise';
import 'core-js/features/promise/finally';

//AbortController is not supported by IE11
require('abortcontroller-polyfill/dist/polyfill-patch-fetch');

// polyfills for native Intl API support in IE, Edge, Safari
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/fi');
  require('@formatjs/intl-pluralrules/dist/locale-data/sv');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fi');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/sv');
}

// URLSearchParams for working with query strings
window.URLSearchParams = require('url-search-params');

// Number.isInteger is part of ES6 which is not supported by IE11
Number.isInteger =
  Number.isInteger ||
  function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };
