import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withAuthorization } from 'app/common';

import { fetchBalance } from 'app/redux/accounts/accounts.actions';
import { withFormSubmissionErrorHandling } from 'app/utils/form/with-form-submission-error-handling/with-form-submission-error-handling.util';

import { AccountsBalanceComponent } from './accounts-balance.component';

const mapStateToProps = ({ accounts: { balance, isPostPaymentAccount, creditCap }, users: { business } }) =>
  (currentDate => ({
    loading: !business,
    balance,
    business,
    initialValues: {
      date: currentDate,
    },
    isPostPaymentAccount,
    creditCap
  }))(moment().subtract(1, 'd').toISOString().slice(0, 10));

const mapDispatchToProps = dispatch => ({
  onSubmit: ({ date }) => withFormSubmissionErrorHandling(dispatch(fetchBalance(date))),
});

export const AccountsBalanceContainer = compose(
  withAuthorization(['municipalAccount', 'read']),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountsBalanceComponent);
