import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { handleAnalyticsEvent } from 'app/utils';

import { CouponsShareSmsComponent } from './coupons-share-sms.component';
import { shareCouponSMS } from 'app/redux/coupons/coupons.actions';

const mapDispatchToProps = (dispatch, { coupon, lang }) => ({
  onSubmit: data => {
    handleAnalyticsEvent('vouchers', 'send');

    return dispatch(shareCouponSMS({ ...data, lang, coupons: [coupon] }));
  },
});

export const CouponsShareSmsContainer = compose(
  injectIntl,
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'share-coupons-sms',
  }),
)(CouponsShareSmsComponent);
