import { SubmissionError } from 'redux-form';
import { FORM_ERROR } from 'final-form';
import { get, isEmpty } from 'lodash';

// DEPRECATED! REMOVE DEPENDENCY FROM THIS MIDDLEWARE AND REMOVE IT WHEN POSSIBLE.
// TODO: Move handling of 503 closer to order-create.component.js which is the only usage.
// TODO: Instead of communicating validation errors as exceptions handle them as return values.
//  - For more information see with-form-submission-error-handling.util.js
// TODO: Remove this middleware once all validation failures are handled by the components themselves
//       or using helper function like the one in with-form-submission-error-handling.util.js

// these codes are considered to be errors which should be displayed in the form
// Introduced additional check for response because otherwise this match all api failures
const isValidationError = ({ status, response = {} }) => {
  return [400, 503].includes(status) && (response.hasOwnProperty('errors') || response.hasOwnProperty('message'));
};

const isErrorAction = action =>
  action.error && action.payload instanceof Error && !get(action, ['meta', 'noValidation']);

const getErrors = response => {
  const { errors, message } = response;

  if (!isEmpty(errors)) {
    if (errors.hasOwnProperty('_error')) {
      errors[FORM_ERROR] = errors._error;
    }

    // backend returns some errors which we pass to redux-form/final-form
    return errors;
  } else {
    // build the redux-form/final-form error object
    return {
      _error: message,
      [FORM_ERROR]: message,
    };
  }
};

export const validationMiddleware = () => next => action => {
  if (isErrorAction(action) && isValidationError(action.payload)) {
    next(action);
    throw new SubmissionError(getErrors(action.payload.response));
  }

  return next(action);
};
