// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { ConnectedDatePickerComponent, ConnectedInputComponent } from 'app/shared';
import { birthdayValidator, isBusinessDifferent } from 'app/utils';
import { birthdayBounds } from 'app/utils/dates';

import { FiltersBaseContainer } from 'app/shared/filters/filters-base.container';

/*:: import type { Props, State } from './coupons-list-filters.component.types';*/

export class CouponsListFiltersComponent extends Component /*:: <Props, State>*/ {
  form /*: string*/ = 'filter-coupons';

  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      isCalendarOpened: false,
    };
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.submit(this.form);
    }
  }

  toggle = (state /*: State*/) => this.setState({ isCalendarOpened: !!state });

  render() {
    const backdropClassNames = classNames('DateRangeBackdrop', {
      'DateRangeBackdrop--active': this.state.isCalendarOpened,
    });

    const columnClassNames = classNames('col-12', 'col-xl-6', {
      'col-lg-6': this.props.sidebarCollapsed,
    });

    return (
      <FiltersBaseContainer
        form={this.form}
        title={this.props.intl.formatMessage({ id: 'COUPONS.MASS_CHARGE.FILTERS_TITLE' })}
        columnClassName={columnClassNames}
        fetch={this.props.fetch}
        baseFilters={[
          <Field name="name" component={ConnectedInputComponent} label="COUPONS.CREATE.LABEL.NAME" />,
          <Field
            name="dateOfBirth"
            component={ConnectedDatePickerComponent}
            onFocusChange={this.toggle}
            label="COUPONS.FILTERS.DATE_OF_BIRTH"
            intl={this.props.intl}
            bounds={birthdayBounds}
            validate={birthdayValidator}
          />,
        ]}
        advancedFilters={[<Field name="id" component={ConnectedInputComponent} label="COUPONS.FILTERS.CODE" />]}
      >
        <div className={backdropClassNames} />
      </FiltersBaseContainer>
    );
  }
}
