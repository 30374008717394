// @flow

import { connect } from 'react-redux';

/*:: import type { ComponentType } from 'react';*/
/*:: import type { Permission } from '../../core/sidebar/sidebar.component.types';*/
/*:: import type { Business } from '../../types/business.types';*/

/*:: type WithPermissionsState = {
  permissions?: {
    [string]: {
      [string]: Permission,
    },
  },
  users: { business?: Business },
};*/

export const mapStateToProps = ({ permissions, users: { business } } /*: WithPermissionsState*/) => {
  return {
    business: business || {},
    permissions: permissions && business ? permissions[business.id] || {} : {},
  };
};

export const withPermissions = (WrappedComponent /*: ComponentType<any>*/) =>
  connect(mapStateToProps)(WrappedComponent);
