// @flow

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { confirmation } from 'redux-form-validators';
import classNames from 'classnames';

import { ButtonComponent, ConnectedInputComponent, SeparatorComponent } from 'app/shared';

import { CardBodyComponent, CardComponent, CardFooterComponent, CardHeaderComponent } from 'app/shared/card-new';
import { required } from 'app/utils';

/*:: import type { BodyType, Props, State } from './password.component.types';*/
import styles from '../personal/personal.module.scss';

export class PasswordComponent extends Component /*:: <Props, State>*/ {
  constructor(props /*: Props*/) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  onSubmit = (body /*: BodyType*/) =>
    this.props
      .onSubmit(body)
      .then(() => this.setState({ submitted: true }))
      .then(() => this.props.reset())
      .then(() => setTimeout(() => this.setState({ submitted: false }), 5000));

  render() {
    return (
      <CardComponent>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <CardHeaderComponent title="PROFILE.CHANGE_PASSWORD" />
          <SeparatorComponent />
          <CardBodyComponent>
            <Field
              name="password"
              component={ConnectedInputComponent}
              label="PROFILE.CURRENT_PASSWORD"
              type="password"
              validate={required({ message: 'VALIDATION.CURRENT_PASSWORD_REQUIRED' })}
              required
            />
            <Field
              name="newPassword"
              component={ConnectedInputComponent}
              label="PROFILE.NEW_PASSWORD"
              type="password"
              validate={required({ message: 'VALIDATION.NEW_PASSWORD_REQUIRED' })}
              required
            />
            <Field
              name="newPasswordConfirmation"
              component={ConnectedInputComponent}
              label="PROFILE.CONFIRM_PASSWORD"
              type="password"
              validate={[
                required({ message: 'VALIDATION.PASSWORD_CONFIRMATION_REQUIRED' }),
                confirmation({
                  field: 'newPassword',
                  message: {
                    id: 'VALIDATION.PASSWORD_MATCH',
                  },
                }),
              ]}
              required
            />
          </CardBodyComponent>
          <CardFooterComponent justifyContent="flex-end">
            {this.state.submitted && (
              <>
                <span className={classNames('fi fi-check', styles.check)} />
                <p className={styles.message}>{this.props.intl.formatMessage({ id: 'PROFILE.UPDATE_SUCCESS' })}</p>
              </>
            )}

            <ButtonComponent loading={this.props.submitting}>
              {this.props.intl.formatMessage({ id: 'CORE.SAVE' })}
            </ButtonComponent>
          </CardFooterComponent>
        </form>
      </CardComponent>
    );
  }
}
