import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { withPermissions } from 'app/common';

import { SidebarAccountComponent } from './sidebar-account.component';

const mapStateToProps = ({ accounts: { isPostPaymentAccount } }) => ({
  isPostPaymentAccount,
});

export const SidebarAccountContainer = compose(
  injectIntl,
  withPermissions,
  connect(mapStateToProps),
)(SidebarAccountComponent);
