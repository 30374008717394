import get from 'lodash/get';
/*:: import type { Coupon } from 'app/coupons/details/coupons-details.types';*/

export function getIsPaymentVisible(
  showActiveNotInvalidated /*: boolean*/,
  showValidInFuture /*: boolean*/,
  showInvalidated /*: boolean*/,
  coupon /*: Coupon*/,
) {
  const isFutureChargeable = coupon.isFutureChargeable;
  const isInvalidated = !!get(coupon, 'invalidated', false);

  if (isInvalidated) {
    return showInvalidated;
  }

  if (isFutureChargeable) {
    return showValidInFuture;
  }

  return showActiveNotInvalidated;
}
