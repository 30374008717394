// @flow
/*:: import type { InputFormMetaType } from 'app/flow-types';*/

export function isFieldInvalid(meta /*: InputFormMetaType*/) /*: boolean*/ {
  if ((meta.touched || !meta.pristine) && meta.error) {
    return true;
  }

  if (meta.dirtySinceLastSubmit === false && meta.submitError) {
    return true;
  }

  if (meta.submitFailed && (meta.submitError || meta.error) && !meta.dirtySinceLastSubmit) {
    return true;
  }

  return false;
}
