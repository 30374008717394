import { ReportMessageExtra } from 'app/sentry/sentry';
import { fork, ForkEffect } from 'redux-saga/effects';
import { AuthenticationDataProvider } from './api/auth';
import { createHousingServicesApiClient } from './api/housing-services-api-client';
import { createPostpayApiClient, LanguageDataProvider } from './api/postpay-api-client';
import { availableUseCasesForOrganizationalUnitSaga } from './organizational-unit/available-usecases.saga';
import { transformToPayments } from './service-events/housing-service/backend-services/transform-to-payments';
import { housingServicesServiceEventSaga } from './service-events/housing-service/service-event-submission.saga';
import { postpayServiceEventSubmissionSaga } from './service-events/postpay/service-event-submission.saga';
import { serviceVoucherServiceEventsSaga } from './service-voucher/postpay/service-voucher-service-events.saga';
import { serviceVouchersSaga } from './service-voucher/postpay/service-vouchers.saga';
import { voucherTypeSaga } from './voucher-type/postpay/voucher-type.saga';
import { voucherTypesSaga } from './voucher-type/postpay/voucher-types.saga';
import { printServiceVouchersSaga } from './service-voucher/postpay/print-service-vouchers.saga';
import { issuedServiceVouchersSaga } from './service-voucher/postpay/search/issued-service-vouchers.saga';

export function* v2Saga(
  crashApplication: () => void,
  reportError: (message: string, extra: ReportMessageExtra) => void,
  apiBaseUrl: string,
  apiV2BaseUrl: string,
  authenticationProvider: AuthenticationDataProvider,
  languageProvider: LanguageDataProvider,
): Generator<ForkEffect> {
  const housingServiceApiClient = createHousingServicesApiClient(
    apiBaseUrl,
    authenticationProvider,
    crashApplication,
    reportError,
  );

  const postpayApiClient = createPostpayApiClient(
    apiV2BaseUrl,
    authenticationProvider,
    languageProvider,
    crashApplication,
    reportError,
  );

  yield fork(serviceVouchersSaga, postpayApiClient);
  yield fork(housingServicesServiceEventSaga, housingServiceApiClient, transformToPayments);
  yield fork(postpayServiceEventSubmissionSaga, postpayApiClient);
  yield fork(serviceVoucherServiceEventsSaga, postpayApiClient);
  yield fork(voucherTypesSaga, postpayApiClient);
  yield fork(availableUseCasesForOrganizationalUnitSaga, postpayApiClient);
  yield fork(voucherTypeSaga, postpayApiClient);
  yield fork(printServiceVouchersSaga, postpayApiClient);
  yield fork(issuedServiceVouchersSaga, postpayApiClient);
}
