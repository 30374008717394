// @flow

import React, { PureComponent } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';

import { headers } from '../../table/coupons-details-transactions-table.component';

/*:: import type { Props } from './coupons-details-transactions-table.placeholder.types';*/

export class CouponsDetailsTransactionsTablePlaceholder extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />
        <TableBodyComponent>
          {this.props.list.map((_, transaction) => (
            <TableRowComponent key={transaction}>
              <TableCellComponent>
                <PlaceholderComponent width={80} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 100 : 120} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 100 : 60} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={60} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={transaction % 2 === 0 ? 100 : 60} />
              </TableCellComponent>
              <TableCellComponent align="right">
                <PlaceholderComponent width={transaction % 2 === 0 ? 80 : 60} />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
