import { PageHeaderComponent } from 'app/core';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { LoadingComponent } from 'app/shared/loading/loading.component';
import { NoResultsContainer } from 'app/shared/no-results/no-results.container';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { V2RootState } from 'v2/v2.reducer';
import { VoucherTypeListComponent } from 'v2/voucher-type/postpay/voucher-type-list.component';
import { actions, sliceName, StateStatus, VoucherTypesState } from 'v2/voucher-type/postpay/voucher-types-slice';
import styles from './select-service-voucher-type.page.module.scss';

type SelectServiceVoucherTypePageProps = Record<string, never>;

export const SelectServiceVoucherTypePage: React.FC<SelectServiceVoucherTypePageProps> = () => {
  const serviceVoucherTypesState = useSelector<V2RootState, VoucherTypesState>(state => state[sliceName]);

  const content = (() => {
    switch (serviceVoucherTypesState.status) {
      case StateStatus.Loaded:
        if (serviceVoucherTypesState.voucherTypes.length === 0) {
          return (
            <NoResultsContainer
              title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.EMPTY_VOUCHER_TYPES_LIST.TITLE"
              desc="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.EMPTY_VOUCHER_TYPES_LIST.DESCRIPTION"
            />
          );
        } else {
          return <VoucherTypeListComponent voucherTypes={serviceVoucherTypesState.voucherTypes} />;
        }

      case StateStatus.Uninitialised:
      case StateStatus.Loading:
        return <LoadingComponent isLoading size="large" />;
    }
  })();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.voucherTypesRequested());
  }, [dispatch]);

  return (
    <>
      <PageHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.PAGE_TITLE" separatorHeight={40} />
      <CardComponent>
        <CardHeaderComponent title="SERVICE_VOUCHERS.POSTPAY.ISSUE_SERVICE_VOUCHER.VOUCHER_TYPES_TITLE" />
        <CardBodyComponent className={styles.cardBody}>{content}</CardBodyComponent>
      </CardComponent>
    </>
  );
};
