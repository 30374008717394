import { and, ControlElement, isControl, JsonSchema7, rankWith, resolveSchema } from '@jsonforms/core';
import { Label } from 'app/shared/form/controls-elements';
import cn from 'classnames';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import styles from './readonly-control.renderer.module.scss';
import { vaanaJsonFormsControl } from './vaana-json-forms-control';

export const ReadonlyControlRenderer = vaanaJsonFormsControl(({ label, data, schema, uischema }) => {
  const isDate = useCallback(() => schema.format === 'date', [schema]);

  const labelComponent = useMemo(() => {
    if (isDate()) {
      return <Label label={label} />;
    } else {
      return <label className={cn('col-sm-3 col-form-label', styles.readOnly, styles.label)}>{label}:</label>;
    }
  }, [isDate, label]);

  const value = useMemo(() => {
    if (isDate() && uischema.options?.displayFormat) {
      return moment(data).format(uischema.options.displayFormat);
    } else {
      return data;
    }
  }, [data, isDate, uischema.options?.displayFormat]);

  return (
    <div className={cn('row', styles.wrapper)}>
      {labelComponent}
      <div className={cn('col-sm-9', styles.inputWrapper)}>
        <input type="text" readOnly className={cn('form-control-plaintext', styles.readOnly)} value={value} />
      </div>
    </div>
  );
});

export const readonlyControlTester = rankWith(
  1002,
  and(isControl, (uischema, schema) => {
    const controlSchema = resolveSchema(schema, (uischema as ControlElement).scope);
    return (controlSchema as JsonSchema7).readOnly ?? false;
  }),
);
