// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { Modal } from 'app/shared/modal-new/modal.container';
import { TransactionDetailsModalContainer } from '../details/transaction-details-modal.container';
import { TransactionsMunicipalBeneficiaryFiltersContainer } from './filters/transactions-municipal-beneficiary-filters.container';
import { TransactionsMunicipalBeneficiaryPlaceholder } from './placeholder/transactions-municipal-beneficiary.placeholder';
import { TransactionsMunicipalBeneficiaryTableComponent } from './table/transactions-municipal-beneficiary-table.component';
import { TransactionsMunicipalBeneficiaryTileComponent } from './tile/transactions-municipal-beneficiary-tile.component';

import { PageHeaderComponent } from 'app/core';
import { ListComponent } from 'app/shared/list/list.component';
import { LoadMoreComponent, SeparatorComponent } from 'app/shared';
import { CardBodyComponent, CardComponent, CardHeaderComponent } from 'app/shared/card-new';
import { isBusinessDifferent } from 'app/utils';
import { SidebarContainer } from 'app/shared/sidebar/sidebar.container';

/*:: import type { Props } from './transactions-municipal-beneficiary.component.types';*/

export class TransactionsMunicipalBeneficiaryComponent extends Component /*:: <Props>*/ {
  componentDidMount() {
    this.props.fetchDictionary();
  }

  componentDidUpdate(prevProps /*: Props*/) {
    if (isBusinessDifferent(prevProps, this.props)) {
      this.props.fetchTransactions({ limit: 10 });
      this.props.resetFilters();
    }
  }

  render() {
    const noResults = this.props.loading || !this.props.transactions.length;

    return (
      <>
        <PageHeaderComponent title="NAV.MANAGEMENT.MUNICIPAL_BENEFICIARY_TRANSACTIONS" />
        <TransactionsMunicipalBeneficiaryFiltersContainer
          onChange={debounce(this.props.onFiltersChange, 500)}
          types={this.props.types}
        />
        <SeparatorComponent />
        <CardComponent>
          {this.props.loading && <TransactionsMunicipalBeneficiaryPlaceholder />}

          {this.props.loading || (
            <>
              <CardHeaderComponent
                title={{
                  id: 'TRANSACTIONS.COUNTER',
                  values: { count: this.props.transactions.length, total: this.props.meta.total },
                }}
              />
              <SeparatorComponent />
              <CardBodyComponent>
                <ListComponent
                  list={this.props.transactions}
                  table={TransactionsMunicipalBeneficiaryTableComponent}
                  tile={TransactionsMunicipalBeneficiaryTileComponent}
                  props={{
                    permissions: this.props.permissions,
                  }}
                />

                {this.props.transactions.length < this.props.meta.total && (
                  <>
                    <SeparatorComponent />
                    <LoadMoreComponent onClick={this.props.onLoadMore} fetching={this.props.fetching} />
                  </>
                )}
              </CardBodyComponent>
            </>
          )}
        </CardComponent>

        <SidebarContainer
          name="transactions-municipal-beneficiary"
          tabs={[
            {
              label: <FormattedMessage id="COUPONS.SIDEBAR.EXCEL.TITLE" />,
              icon: 'fi-download-excel',
              onClick: () => this.props.onDownload(),
              disabled: noResults,
            },
          ]}
        />

        <Switch>
          <Route
            path={`${this.props.match.url}/:account/:transaction`}
            render={props => (
              <Modal
                component={TransactionDetailsModalContainer}
                componentProps={{ ...props, pathname: this.props.match.url }}
                isOpen={true}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}
