// @flow

import React, { PureComponent } from 'react';

import { PlaceholderComponent } from 'app/shared';
import {
  TableBodyComponent,
  TableCellComponent,
  TableComponent,
  TableHeadComponent,
  TableRowComponent,
} from 'app/shared/table-new';
import { headers } from '../../table/users-list-table.component';

/*:: import type { Props } from './users-list-table.placeholder.types';*/

export class UsersListTablePlaceholder extends PureComponent /*:: <Props>*/ {
  render() {
    return (
      <TableComponent className="table-striped">
        <TableHeadComponent headers={headers} />

        <TableBodyComponent>
          {this.props.list.map((_, user) => (
            <TableRowComponent key={user}>
              <TableCellComponent>
                <PlaceholderComponent width={user % 2 === 0 ? 150 : 200} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={user % 2 === 0 ? 260 : 150} />
              </TableCellComponent>
              <TableCellComponent>
                <PlaceholderComponent width={100} />
              </TableCellComponent>
              <TableCellComponent className="text-right">
                <PlaceholderComponent width={100} color="green" />
              </TableCellComponent>
            </TableRowComponent>
          ))}
        </TableBodyComponent>
      </TableComponent>
    );
  }
}
