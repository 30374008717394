// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { TileComponent } from 'app/shared';
import { currencyFormatter } from 'app/utils';
import { dateFormatter } from 'app/utils/dates/date-formatter/date-formatter.util';

/*:: import type { Props } from './orders-history-tile.component.types';*/
import styles from './../orders-history.module.scss';

export class OrdersHistoryTileComponent extends Component /*:: <Props>*/ {
  render() {
    const {
      tile: { id, status, orderDate, totalAmount },
    } = this.props;

    return (
      <TileComponent>
        <h3>#{id}</h3>
        <div className="row">
          <div className="col-md-3">
            <FormattedMessage id="ORDERS.HISTORY.ORDER_AMOUNT" tagName="small" />
            <div className={styles.amount}>{currencyFormatter.format(totalAmount)}</div>
          </div>

          <div className="col-md-4">
            <FormattedMessage id="ORDERS.HISTORY.ORDER_DATE" tagName="small" />
            {dateFormatter(orderDate)}
          </div>

          <div className="col-md-5">
            <FormattedMessage id="ORDERS.HISTORY.ORDER_STATUS" tagName="small" />
            <FormattedMessage id={`ORDERS.HISTORY.STATUS.${status}`} className={styles.amount} tagName="small" />
          </div>
        </div>
      </TileComponent>
    );
  }
}
