// @flow
import { useState } from 'react';
/*:: import type { Status } from 'app/shared/status/status.component.types';*/
/*:: import type { VenuesListFilters } from './pricelists-manage.component.types';*/
/*:: import type { VenueRow } from '../pricelists.component.types';*/
import { VenueStatus } from 'app/constants';

export const initialFilters /*: VenuesListFilters*/ = {
  showApproved: true,
  showPending: true,
  showChangesRequested: true,
  showPendingAfterChangesRequested: true,
  showChangesNotMadeInTime: true,
  name: '',
};

const nameFilter = (name /*: string*/, filters /*: VenuesListFilters*/) /*: boolean*/ => {
  return !filters.name || name.toLowerCase().includes(filters.name.toLowerCase());
};

const statusFilter = (status /*: Status*/, filters /*: VenuesListFilters*/) /*: boolean*/ => {
  // Venues with a status for which there is no filter are currently not shown in the venue list.
  // This is the case for e.g. venues with status "NEW", i.e. venues which only have contracts in status "NEW".
  return (
    (filters.showApproved && status === VenueStatus.Approved) ||
    (filters.showPending && status === VenueStatus.Pending) ||
    (filters.showChangesRequested && status === VenueStatus.ChangesRequested) ||
    (filters.showPendingAfterChangesRequested && status === VenueStatus.PendingAfterChangesRequested) ||
    (filters.showChangesNotMadeInTime && status === VenueStatus.ChangesNotMadeInTime)
  );
};

export const applyFilters = (venuesList /*: VenueRow[]*/ = [], filters /*: VenuesListFilters*/) /*: VenueRow[]*/ => {
  return venuesList.filter((venue /*: VenueRow*/) /*: boolean*/ => {
    return nameFilter(venue.name, filters) && statusFilter(venue.status, filters);
  });
};

export const useVenueFilter = () => {
  const [value, set] = useState/*:: <VenuesListFilters> */(initialFilters);

  return [value, set];
};
